import React, { useContext, useEffect, useState } from "react";
import { ISummary } from "../../store/actions/summariesActions";
import Table from "../../components/ui/Table/Table";
import { IInputField, EInputType } from "../ui/Input/Input";
import { useCreateInput } from "../../hooks/useCreateInput";
import SummaryRow from "./SummaryRow";
import ModalContext, { EModalSize } from "../ui/Modal/ModalContext";
import SummaryCheckedOptionsModal from "./SummaryCheckedOptionsModal";
import { getInputData } from "../ui/Input/input-utils";
import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";

export enum EInputs {
	checkAll = "checkAll",
	checkedOptions = "checkedOptions",
}

interface IProps {
	summaries: ISummary[] | null;
	error: string | null;
	openSummary: (id: string) => void;
}

const SummariesList: React.FC<IProps> = ({ summaries, error, openSummary }) => {
	const { t } = useTranslation();
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const { setModal } = useContext(ModalContext);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.checkedOptions]: {
			type: EInputType.reactSelect,
			isClearable: true,
			labelTranslation: ETranslation.COMMON_FUNCTIONS,
			value: "",
			options: [
				{
					value: "PUBLISHED",
					labelTranslation: ETranslation.SUMMARY_PUBLISHED,
				},
				{
					value: "DRAFT",
					labelTranslation: ETranslation.SUMMARY_DRAFT,
				},
				{
					value: "DELETE",
					labelTranslation: ETranslation.COMMON_DELETE,
				},
			],
		},

	});

	const [checkAllInput, setCheckAllInputs] = useState<IInputField>({
		[EInputs.checkAll]: {
			type: EInputType.boolean,
			label: "Valitse kaikki",
			value: false
		}
	})

	const createInput = useCreateInput(inputs, setInputs);
	const createCheckAllInput = useCreateInput(checkAllInput, setCheckAllInputs);

	const toggleSelectRow = (id: string) => {
		const present = selectedRows.find((item) => id === item);
		const newArr = present ? selectedRows.filter((item) => id !== item) : [...selectedRows, id];
		setSelectedRows(newArr)
	}

	useEffect(() => {
		if (!summaries) return;
		const data = getInputData<{ checkAll: boolean }>(checkAllInput);
		if (data.checkAll) {
			setSelectedRows(summaries.map((item) => {
				return item.id
			}))
		} else {
			setSelectedRows([]);
		}
	}, [checkAllInput, summaries])

	useEffect(() => {
		const data = getInputData<{ checkedOptions: "PUBLISHED" | "DRAFT" | "DELETE" | "" }>(inputs)
		if (data.checkedOptions === "" || selectedRows.length < 1) return;
		setModal({
			title: t(ETranslation.SUMMARY_CONFIRM_ACTION),
			content: (
				<SummaryCheckedOptionsModal
					selectedRows={selectedRows}
					selectedValue={data.checkedOptions}
					closeModal={() => {
						window.location.reload();
					}}
				/>
			),
			isOpen: true,
			size: EModalSize.MEDIUM,
			onModalClose: () => {
				window.location.reload();
			},
		});
	}, [inputs, selectedRows, setModal, t])

	return error ? (
		<>{error}</>
	) : (
		<>
			<div>
				{selectedRows.length > 0 ? (
					<div>
						{createInput(EInputs.checkedOptions)}
					</div>
				) : null}
			</div>
			<Table>
				<thead>
					<tr>
						<th>{createCheckAllInput(EInputs.checkAll)}</th>
						<th>{t(ETranslation.COMMON_STATUS)}</th>
						<th>{t(ETranslation.COMMON_CREATED)}</th>
						<th>{t(ETranslation.SUMMARIES_TIME_INTERVAL)}</th>
						<th>{t(ETranslation.COMMON_USER)}</th>
						<th>{t(ETranslation.SUMMARIES_GRADE)}</th>
						<th>{t(ETranslation.COMMON_INTERPRETATIONS)}</th>
						<th>{t(ETranslation.COMMON_DURATION)}</th>
						<th></th>
						<th>{t(ETranslation.COMMON_NOTES)}</th>

					</tr>
				</thead>
				<tbody>
					{summaries &&
						summaries.map((summary, index) => (
							<SummaryRow
								key={index}
								summary={summary}
								openSummary={openSummary}
								toggleSelectRow={toggleSelectRow}
								selectedRows={selectedRows}
							/>
						))}
				</tbody>
			</Table>
		</>
	);
};
export default SummariesList;
