import * as dateFns from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useConfirmModal } from "../../hooks/useConfirmModal";
import { IUserShiftDateItem } from "../../interfaces/IUserShiftDateItem";
import { IUserShiftMonth } from "../../interfaces/IUserShiftMonth";
import IAppState from "../../interfaces/store/IAppState";
import IUserShiftState from "../../interfaces/store/IUserShiftState";
import * as actions from "../../store/actions";
import { formatDate } from "../../utils/date-utils";
import Alert from "../ui/Alert/Alert";
import Button, { EButtonColor, EButtonSize } from "../ui/Button/Button";
import Input, { EInputType } from "../ui/Input/Input";
import Spinner, { ESpinnerSize } from "../ui/Spinner/Spinner";
import UserShiftDateItem from "./UserShiftDateItem";
import classes from "./UserShiftsDatesEdit.module.scss";
import { useUserShiftDateCreateModal } from "./useUserShiftDateCreateModal";
import InputContext from "../ui/Input/InputContext";

interface IProps {
	shift: IUserShiftMonth;
}

interface IShiftDate {
	[key: string]: IUserShiftDateItem[];
}

const UserShiftsDatesEdit: React.FC<IProps> = ({ shift }) => {
	const { year, month } = shift;
	const [shifts, setShifts] = useState<IShiftDate>({});
	const [days, setDays] = useState<string[]>([]);
	const openCreateModal = useUserShiftDateCreateModal();

	const dispatch = useDispatch();

	const { deleteDatesError } = useSelector<IAppState, IUserShiftState>((state) => state.userShifts);

	const dates = shift.dates;

	useEffect(() => {
		if (dates) {
			setShifts(
				dates.reduce((prev, item) => {
					return {
						...prev,
						[item.date]: item.items,
					};
				}, {})
			);
		}
	}, [dates]);

	const deleteOneHandler = (date: string, id: string) => {
		dispatch(actions.deleteUserShiftDates(shift.id, date, id));
	};

	useEffect(() => {
		if (year && month) {
			const eachWeek = dateFns.eachWeekOfInterval(
				{ start: new Date(year, month - 1, 1), end: dateFns.lastDayOfMonth(new Date(year, month - 1, 1)) },
				{ weekStartsOn: 1 }
			);

			const days: string[] = [];
			eachWeek.forEach((startDate) => {
				days.push(dateFns.formatISO(startDate, { representation: "date" }));
				for (let i = 1; i <= 6; i++) {
					const day = dateFns.addDays(startDate, i);
					days.push(dateFns.formatISO(day, { representation: "date" }));
				}
			});
			setDays(days);
		}
	}, [year, month]);

	const isSameMonth = (date: string) => {
		const date1 = new Date(year, month - 1, 1);
		const date2 = dateFns.parseISO(date);
		return dateFns.isSameYear(date1, date2) && dateFns.isSameMonth(date1, date2);
	};

	const openHandler = (date: string) => {
		openCreateModal(shift, date);
	};

	return (
		<>
			<Actions shift={shift} />
			{deleteDatesError && <Alert>{deleteDatesError}</Alert>}
			<div className={classes.Grid}>
				{["MA", "TI", "KE", "TO", "PE", "LA", "SU"].map((str) => (
					<div key={str}>{str}</div>
				))}
				{days.map((date) => {
					const sameMonth = isSameMonth(date);
					return (
						<div
							className={[classes.GridItem, sameMonth ? "" : classes.GridItemOtherMonth].join(
								" "
							)}
							onClick={sameMonth ? () => openHandler(date) : undefined}
							style={sameMonth ? { cursor: 'pointer' } : undefined}
							key={date}
						>
							{formatDate(date)}
							{shifts[date]?.map((item) => (
								<UserShiftDateItem
									key={item.id}
									item={item}
									onDelete={(id) => deleteOneHandler(date, id)}
								/>
							))}
						</div>
					);
				})}
			</div>
		</>
	);
};

interface IActionProps {
	shift: IUserShiftMonth;
}

const Actions: React.FC<IActionProps> = ({ shift }) => {
	const { deleteDatesLoading, updating } = useSelector<IAppState, IUserShiftState>((state) => state.userShifts);
	const dispatch = useDispatch();
	const openUserShiftDateCreateModal = useUserShiftDateCreateModal();
	const openConfirmModal = useConfirmModal();

	const deleteAllHandler = async () => {
		const success = await openConfirmModal("Haluatko varmati poistaa kaikki tämän kuukauden työvuorot?");
		if (success) {
			dispatch(actions.deleteUserShiftDates(shift.id, "ALL", ""));
		}
	};

	return (
		<div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
			<Button onClick={() => openUserShiftDateCreateModal(shift)} size={EButtonSize.SMALL}>
				Luo työvuorot
			</Button>
			{shift.dates.length > 0 && (
				<>
					<Button
						onClick={deleteAllHandler}
						loading={deleteDatesLoading}
						size={EButtonSize.SMALL}
						color={EButtonColor.DANGER}
					>
						Poista kaikki työvuorot
					</Button>
					<InputContext.Provider
						value={{
							initDone: true,
							onAutoUpdate: async (inputName, value, action) => {
								dispatch(
									actions.updateUserShiftMonth({
										id: shift.id,
										isPublished: value as boolean,
									})
								);
								return true;
							},
						}}
					>
						<Input
							type={EInputType.boolean}
							inputName="isPublished"
							confirmValueChange={async (value) => {
								if (value) {
									return openConfirmModal("Haluatko varmasti julkaista työvuorot?");
								}
								return true;
							}}
							onChange={(value) => {}}
							value={shift.isPublished}
							label="Julkaistu"
							containerStyles={{ marginBottom: 0 }}
							updateAction="EMPTY"
						/>
					</InputContext.Provider>
				</>
			)}
			{updating && <Spinner size={ESpinnerSize.SMALL} />}
		</div>
	);
};

export default UserShiftsDatesEdit;
