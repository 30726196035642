import { i18n } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IWorkingHoursMonthlySummary } from '../../interfaces/IWorkingHoursMonthlySummary';
import Table from '../ui/Table/Table';

interface IProps {
	summaries: IWorkingHoursMonthlySummary[];
}

const WorkingHoursSummariesList: React.FC<IProps> = ({ summaries }) => {
	const { i18n } = useTranslation();

	if (summaries.length === 0) return <p>Ei merkintöjä.</p>;

	return (
		<Table>
			<thead>
				<tr>
					<th style={{ width: "1px", whiteSpace: 'nowrap' }}>Kuukausi</th>
					<th style={{ width: "1px" }}>Käyttäjä</th>
					<th style={{ width: "1px" }}>Työ</th>
					<th style={{ width: "1px" }}>Sairasloma</th>
					<th style={{ width: "1px" }}>Poissa (liukuma)</th>
					<th style={{ width: "1px" }}>Poissa (palkaton)</th>
					<th style={{ width: "1px" }}>Lomakertymä (kk)</th>
					<th style={{ width: "1px" }}>Lomakertymä (yhteensä)</th>
					<th style={{ width: "1px" }}>Liukuma (yhteensä)</th>
				</tr>
			</thead>
			<tbody>
				{summaries.map((summary, i) => {
					return <WorkingHoursSummariesListItem key={summary.id} summary={summary} i18n={i18n}  />;
				})
				}
			</tbody>
		</Table>
	);
};

interface IWorkingHoursListItemProps {
	summary: IWorkingHoursMonthlySummary;
	i18n: i18n;
}

const WorkingHoursSummariesListItem: React.FC<IWorkingHoursListItemProps> = ({ summary, i18n }) => {
	const { date, userName, flexTotalMinutes, holidayTotal, holidayAmount, amounts } = summary;
	const { work, sickLeave, absentFlex, absentUnpaid  } = amounts;
	const dateObj = new Date(date);

	const createMinuteValue = (value: number) =>{
		const hour = Math.floor(value / 60);
    const minutes = value % 60;
		return `${hour}t ${minutes}min`
	}

	return (
		<tr>
			<td style={{ whiteSpace: "nowrap" }}>{dateObj.toLocaleDateString(i18n.language, { year: 'numeric', month: "long" })}</td>
			<td style={{ whiteSpace: "nowrap" }}>{userName}</td>
			<td style={{ whiteSpace: "nowrap" }}>{createMinuteValue(work.totalMinutes)}</td>
			<td style={{ whiteSpace: "nowrap" }}>{createMinuteValue(sickLeave.totalMinutes)}</td>
			<td style={{ whiteSpace: "nowrap" }}>{createMinuteValue(absentFlex.totalMinutes)}</td>
			<td style={{ whiteSpace: "nowrap" }}>{createMinuteValue(absentUnpaid.totalMinutes)}</td>
			<td style={{ whiteSpace: "nowrap" }}>{holidayAmount} pv</td>
			<td style={{ whiteSpace: "nowrap" }}>{holidayTotal} pv</td>
			<td style={{ whiteSpace: "nowrap" }}>{createMinuteValue(flexTotalMinutes)}</td>
			{/*
			<td>
				{startTimeValid && !isHoursType
					? startTimeObj.toLocaleTimeString(i18n.language, { hour: "2-digit", minute: "2-digit" })
					: ""}
			</td>
			<td>
				{endTimeValid && !isHoursType
					? endTimeObj.toLocaleTimeString(i18n.language, { hour: "2-digit", minute: "2-digit" })
					: ""}
			</td>
			<td style={{ textAlign: "center" }}>
				{isHoursType
					? event.hours
					: startTimeValid && endTimeValid
					? (dateFns.differenceInMinutes(endTimeObj, startTimeObj) / 60).toFixed(2)
					: ""}
			</td>
			<td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{event.isBreakIncluded ? event.breakMinutes : '0'}</td>
			<td>{event.description}</td>	
		
	*/}
		</tr>
	);
};

export default WorkingHoursSummariesList;
