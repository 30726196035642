import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import IAppState from '../interfaces/store/IAppState';
import ICommonState from '../interfaces/store/ICommonState';


export const useLanguageName = (langCode?: string) => {
	const [languageName, setLanguageName] = useState("");

	const { languages } = useSelector<IAppState, ICommonState>(state => state.common);

	const findLangNameWithCode = useCallback((langCodeName: string) => {
		if (languages) return languages.find(lang => lang.id === langCodeName)?.nameFi || ""
	}, [languages])

	useEffect(() => {
		if (languages && langCode) {
			setLanguageName(findLangNameWithCode(langCode) ?? "")
		}
	}, [languages, langCode, findLangNameWithCode]);

	return {languageName, findLangNameWithCode};
};
