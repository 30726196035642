import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ICategory } from '../../../interfaces/ICategory';
import Alert from '../../ui/Alert/Alert';
import Spinner from '../../ui/Spinner/Spinner';
import Input, { EInputType, TInputValue } from '../../ui/Input/Input';
import { ETranslation } from '../../../translations/translation-keys';
import Table from '../../ui/Table/Table';

interface IProps {
  categories: ICategory[] | null;
  loading: boolean;
  error: string | null;
  onClick: (id: string) => void;
}

const CategoryList: React.FC<IProps> = ({
  categories,
  loading,
  error,
  onClick,
}) => {
  const [filter, setFilter] = useState("");
  const { t } = useTranslation();

  if (error) {
    return <Alert>{error}</Alert>;
  }

  if (!categories || loading) {
    return <Spinner />;
  }

  if (categories.length === 0) {
    return <p>{t(ETranslation.ATTRIBUTES_NO_ATTRIBUTES)}</p>;
  }

  const filterCategorys = (item: ICategory): boolean => {
    return (
      filter.length === 0 ||
      item.name?.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  };

  return (
    <>
      <Input
        containerStyles={{ margin: "1rem 0" }}
        type={EInputType.text}
        inputName="filter"
        value={filter}
        onChange={(value: TInputValue) => setFilter(value as string)}
        placeholderTranslation={ETranslation.COMMON_FILTER_BY_NAME}
      />

      <Table hover>
        <thead>
          <tr>
            <th>{t(ETranslation.COMMON_NAME)}</th>
          </tr>
        </thead>
        <tbody>
          {categories
            .filter(filterCategorys)
            .map((category, index) => (
              <tr
                key={category.id}
                onClick={
                  () => onClick(category.id)
                }
              >
                <td>{category.name}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default CategoryList;
