import { useDispatch, useSelector } from "react-redux";
import { IOption } from "../components/ui/Input/Input";
import IAppState from "../interfaces/store/IAppState";
import IUserContractState from "../interfaces/store/IUserContractState";
import { createOptions } from "../utils/option-utils";
import { useEffect, useState } from "react";
import * as actions from '../store/actions'

export const useUserContractSettingOptions = () => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<IOption[]>([]);

  const { settings, loading } = useSelector<IAppState, IUserContractState>(state => state.userContracts);

  useEffect(() => {
    if (settings) {
      setOptions(createOptions(settings));
    } else {
      dispatch(actions.findUserContractSettings());
    }
  }, [settings, dispatch]);

  return { contractSettingsOptions: options, contractSettingsLoading: loading };
}