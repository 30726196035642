import { customFetch, EFetchMethod } from '../custom-fetch';
import { IInterpretation } from '../interfaces/IInterpretation';



export const findInterpretations = async (
	startDate: string,
	endDate: string,
	userId: string,
) => {
	return await customFetch<IInterpretation[]>("/v2/interpretations/find", EFetchMethod.POST, JSON.stringify({ startDate, endDate, userId}));
};

