import EActionTypes from '../../interfaces/store/EActionTypes';
import IMessageAction from '../../interfaces/store/IMessageAction';
import IMessageState from '../../interfaces/store/IMessageState';
import { ETranslation } from '../../translations/translation-keys';

type ReducerAction = IMessageAction;
type ReducerState = IMessageState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  messageGroup: null,
  messageGroupLoading: false,
  messageGroupError: null,

  messageGroups: null,
  messageGroupsLoading: false,
  messageGroupsError: null,

  messageGroupDeleteError: null,
  messageGroupDeleteLoading: false,
  messageGroupDeleteOk: false,

  message: null,
  messageLoading: false,
  messageError: null,

  saveOrUpdateError: null,
  saveOrUpdateLoading: false,
  saveOrUpdateMessage: null,
  saveOrUpdateMessageGroup: null,
  saveOrUpdateOk: false,

  messageDeleteError: null,
  messageDeleteLoading: false,
  messageDeleteOk: false,

};

const findMessageGroupsStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, messageGroupsError: null, messageGroupsLoading: true };
};

const findMessageGroupsSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    messageGroups: action.messageGroups || null,
    messageGroupsError: null,
    messageGroupsLoading: false,
  };
};

const findMessageGroupsFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, messageGroups: null, messageGroupsLoading: false, messageGroupsError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getMessageGroupStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, messageGroupError: null, messageGroupLoading: true };
};

const getMessageGroupSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    messageGroup: action.messageGroup || null,
    messageGroupError: null,
    messageGroupLoading: false,
  };
};

const getMessageGroupFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, messageGroup: null, messageGroupLoading: false, messageGroupError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};

const getMessageGroupClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, messageGroup: null, messageGroupLoading: false, messageGroupError: null};
};

const deleteMessageGroupStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, messageGroupDeleteError: null, messageGroupDeleteLoading: true, messageGroupDeleteOk: false };
};

const deleteMessageGroupSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    messageGroupDeleteError: null,
    messageGroupDeleteLoading: false,
    messageGroupDeleteOk: true
  };
};

const deleteMessageGroupFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, messageGroupDeleteLoading: false, messageGroupDeleteError: action.error as string, messageGroupDeleteOk: false };
};

const deleteMessageGroupClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, messageGroupDeleteError: null, messageGroupDeleteLoading: false, messageGroupDeleteOk: false };
};

const saveOrUpdateMessageGroupStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, saveOrUpdateError: null, saveOrUpdateLoading: true };
};

const saveOrUpdateMessageGroupSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    saveOrUpdateMessageGroup: action.messageGroup || null,
    saveOrUpdateError: null,
    saveOrUpdateLoading: false,
    saveOrUpdateOk: true
  };
};

const saveOrUpdateMessageGroupFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, saveOrUpdateLoading: false, saveOrUpdateError: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const saveOrUpdateClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, 
    saveOrUpdateLoading: false, 
    saveOrUpdateError: null, 
    saveOrUpdateOk: false, 
    saveOrUpdateMessageGroup: null, 
    saveOrUpdateMessage: null 
  }
}


const getMessageStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, messageError: null, messageLoading: true };
};

const getMessageSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    message: action.message || null,
    messageError: null,
    messageLoading: false,
  };
};

const getMessageFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, message: null, messageLoading: false, messageError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};

const getMessageClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, message: null, messageLoading: false, messageError: null};
};

const saveOrUpdateMessageStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, saveOrUpdateMessage: null, saveOrUpdateLoading: true, saveOrUpdateError: null };
};

const saveOrUpdateMessageSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    saveOrUpdateMessage: action.message || null,
    saveOrUpdateError: null,
    saveOrUpdateLoading: false,
  };
};

const saveOrUpdateMessageFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, saveOrUpdateLoading: false, saveOrUpdateError: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const deleteMessageStart: ReducerSignature = (state, action): ReducerState => {
  return { 
    ...state, 
    messageDeleteError: null, 
    messageDeleteLoading: true, 
    messageDeleteOk: false 
  };
};

const deleteMessageSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    messageDeleteError: null,
    messageDeleteLoading: false,
    messageDeleteOk: true
  };
};

const deleteMessageFail: ReducerSignature = (state, action): ReducerState => {
  return { 
    ...state, 
    messageDeleteLoading: false, 
    messageDeleteError: action.error as string, 
    messageDeleteOk: false 
  };
};

const deleteMessageClear: ReducerSignature = (state, action): ReducerState => {
  return { 
    ...state, 
    messageDeleteError: null, 
    messageDeleteLoading: false, 
    messageDeleteOk: false 
  };
};


const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.MESSAGE_GROUP_FIND_START:
      return findMessageGroupsStart(state, action);
    case EActionTypes.MESSAGE_GROUP_FIND_SUCCESS:
      return findMessageGroupsSuccess(state, action);
    case EActionTypes.MESSAGE_GROUP_FIND_FAIL:
      return findMessageGroupsFail(state, action);
    case EActionTypes.MESSAGE_GROUP_GET_START:
      return getMessageGroupStart(state, action);
    case EActionTypes.MESSAGE_GROUP_GET_SUCCESS:
      return getMessageGroupSuccess(state, action);
    case EActionTypes.MESSAGE_GROUP_GET_FAIL:
      return getMessageGroupFail(state, action);
    case EActionTypes.MESSAGE_GROUP_GET_CLEAR:
      return getMessageGroupClear(state, action);
    case EActionTypes.MESSAGE_GROUP_DELETE_START:
      return deleteMessageGroupStart(state, action);
    case EActionTypes.MESSAGE_GROUP_DELETE_SUCCESS:
      return deleteMessageGroupSuccess(state, action);
    case EActionTypes.MESSAGE_GROUP_DELETE_FAIL:
      return deleteMessageGroupFail(state, action);
    case EActionTypes.MESSAGE_GROUP_DELETE_CLEAR:
      return deleteMessageGroupClear(state, action);
    case EActionTypes.MESSAGE_GROUP_SAVE_OR_UPDATE_START:
      return saveOrUpdateMessageGroupStart(state, action);
    case EActionTypes.MESSAGE_GROUP_SAVE_OR_UPDATE_SUCCESS:
      return saveOrUpdateMessageGroupSuccess(state, action);
    case EActionTypes.MESSAGE_GROUP_SAVE_OR_UPDATE_FAIL:
      return saveOrUpdateMessageGroupFail(state, action);
    case EActionTypes.MESSAGE_GET_START:
      return getMessageStart(state, action);
    case EActionTypes.MESSAGE_GET_SUCCESS:
      return getMessageSuccess(state, action);
    case EActionTypes.MESSAGE_GET_FAIL:
      return getMessageFail(state, action);
    case EActionTypes.MESSAGE_GET_CLEAR:
      return getMessageClear(state, action);
    case EActionTypes.MESSAGE_SAVE_OR_UPDATE_START:
      return saveOrUpdateMessageStart(state, action);
    case EActionTypes.MESSAGE_SAVE_OR_UPDATE_SUCCESS:
      return saveOrUpdateMessageSuccess(state, action);
    case EActionTypes.MESSAGE_SAVE_OR_UPDATE_FAIL:
      return saveOrUpdateMessageFail(state, action);
    case EActionTypes.MESSAGE_SAVE_OR_UPDATE_CLEAR:
      return saveOrUpdateClear(state, action)
    case EActionTypes.MESSAGE_DELETE_START:
      return deleteMessageStart(state, action);
    case EActionTypes.MESSAGE_DELETE_SUCCESS:
      return deleteMessageSuccess(state, action);
    case EActionTypes.MESSAGE_DELETE_FAIL:
      return deleteMessageFail(state, action);
    case EActionTypes.MESSAGE_DELETE_CLEAR:
      return deleteMessageClear(state, action);
    default:
      return state;
  }
};

export default reducer;
