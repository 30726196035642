import React, { useEffect, useState } from "react";
import { EUserRole, IUser } from "../../../../classes/User";
import EditableField from "../../../EditableField/EditableField";
import { EInterpretation } from "../InterpretationDetails";
import { EInputType, IOption, TInputValue } from "../../../ui/Input/Input";
import { ILocationDetail } from "../../../../interfaces/IInterpretation";
import { ETranslation } from "../../../../translations/translation-keys";
import { searchCustomers, searchInterpreters } from "../../../../services/userServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import classNames from "./InterpretationUserDetails.module.scss"
import { useTranslation } from "react-i18next";

interface IProps {
	user: IUser | undefined;
	editable: boolean;
	editHandler: (propertyName: EInterpretation, value: TInputValue | ILocationDetail, secondaryValue?: TInputValue) => void;
	updateLoadingProperty: string | null;
	userRole: EUserRole;
}

const InterpretationUserDetails: React.FC<IProps> = ({ user, editable, editHandler, updateLoadingProperty, userRole }) => {
	const [initValue, setInitValue] = useState<string | IOption | null>(null);

	const { t } = useTranslation();
	const property = userRole === EUserRole.CUSTOMER ? EInterpretation.customer : EInterpretation.interpreter;
	const fetchOptions =  userRole === EUserRole.CUSTOMER  ? searchCustomers : searchInterpreters;
	const label = userRole === EUserRole.CUSTOMER  ? ETranslation.COMMON_CUSTOMER : ETranslation.COMMON_INTERPRETER;

	const openUserInformation = () => {
		if (!user) return;
		const url = `/users/edit/${user.id}`;
		window.open(url, "_blank", "noopener, noreferrer");
	};

	useEffect(() => {
		if (user) {
			setInitValue({ value: user.id, label: `${user.name} (${user.email})`});
			return;
		}
		setInitValue("");
	}, [user]);

	return <div className={classNames.detailsContainer}>
		{initValue !== null && (
			<EditableField
				propertyName={property}
				initialValue={initValue}
				onEdit={editHandler}
				labelTranslation={label}
				type={EInputType.reactSelectSearch}
				fetchOptions={fetchOptions}
				loading={updateLoadingProperty === property}
			/>
		)}

		<span className={classNames.userName} onClick={openUserInformation}><b>{user?.firstName} {user?.lastName}</b></span>
		<span><FontAwesomeIcon icon={faPhone} /> {user?.phoneNumber}</span>
		<span><FontAwesomeIcon icon={faEnvelope} />  {user?.email}</span>
		{user?.organizations?.map((organization, key) => {
			return <span key={key}><FontAwesomeIcon icon={faBuilding} />
				{organization.name} {organization.organizationGroup ? " | " + organization.organizationGroup.name : ""}
				{organization.onlySecurityCleared &&
					<><br /><b> {t(ETranslation.ORGANIZATION_COMPANY_ONLY_SECURITY_CLEARED_INTERPRETERS)}</b> </>}</span>
		})}
	</div>
}

export default InterpretationUserDetails;