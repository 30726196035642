import React, { useEffect, useState } from "react";
import { IConnectionDetail, IInterpretation, ILocationDetail } from "../../../interfaces/IInterpretation";
import InterpretationOfferedUsers from "../InterpretationDetails/InterpretationOfferedList";
import { EInputType, IInputField, TInputValue } from "../../ui/Input/Input";
import { ETranslation } from "../../../translations/translation-keys";
import { useSelector } from "react-redux";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { searchInterpreters } from "../../../services/userServices";
import Button, { EButtonColor } from "../../ui/Button/Button";
import { getInputData } from "../../ui/Input/input-utils";
import { EInterpretation } from "../InterpretationDetails/InterpretationDetails";
import Spinner from "../../ui/Spinner/Spinner";
import classes from "./interpretersOffered.module.scss"
import { useTranslation } from "react-i18next";
import InputGroup from "../../ui/InputGroup/InputGroup";
import IAppState from "../../../interfaces/store/IAppState";

interface IProps {
	interpretation: IInterpretation;
	editHandler: (propertyName: EInterpretation, value: TInputValue | ILocationDetail | IConnectionDetail, secondaryValue?: TInputValue) => Promise<void>
}

const InterpretersOffered: React.FC<IProps> = ({ interpretation, editHandler }) => {
	const addInterpreter = (id: string) => {
		editHandler(EInterpretation.interpreterForInterpretationUserRows, id);
	}
	const { t } = useTranslation();

	const { updateLoading } = useSelector((state: IAppState) => ({
		updateLoading: state.interpretation.updateLoading,
	}));

	const automaticallyAddInterpretationUsers = () => {
/* 		setuod(true); */
		editHandler(EInterpretation.addInterpretationUserRows, "")
	}

	const removeAllInterpreters = () =>{
/* 		setuod(true); */
		editHandler(EInterpretation.deleteInterpretersInterpretationUserRow, "")
	}


	const [edit, setEdit] = useState(false);
	return <div className={classes.interpretersOfferedCont}>
		<p><b>{t(ETranslation.INTERPRETERS_OFFERED)}</b></p>
		<div className={classes.offeredList}>
			<InterpretationOfferedUsers users={interpretation.interpretationUserRows ?? []} editable={true} interpretationId={interpretation.id} />
		</div>
		<InputGroup>
			<Button onClick={() => { setEdit(!edit) }}>{t(ETranslation.INTERPRETATION_ADD_INTERPRETER)}</Button>
			<Button onClick={automaticallyAddInterpretationUsers} >{t(ETranslation.INTERPRETATION_SEND_AUTOMATIC_OFFERS)}</Button>
			<Button onClick={removeAllInterpreters} color={EButtonColor.DANGER} >{t(ETranslation.INTERPRETATION_REMOVE_OPEN_OFFERS)}</Button>
		</InputGroup>
		{edit && <InterpretersOfferedAdd loading={updateLoading} error={null} addInterpreter={addInterpreter} />}
	</div>
}

export enum EInputs {
	interpreter = "interpreter",
}

interface IAddProps {
	loading: boolean;
	addInterpreter: (id: string) => void;
	error: string | null;
}

const InterpretersOfferedAdd: React.FC<IAddProps> = ({ loading, addInterpreter, error }) => {

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.interpreter]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_INTERPRETER,
			value: null,
			multiple: false,
			isClearable: true,
			disabled: loading,
		},
	});

	const [interpreter, setInterpreter] = useState<null | string>(null);

	const onAdd = () => {
		if (!interpreter) return;
		addInterpreter(interpreter);
	}

	const { t } = useTranslation();

	useEffect(() => {
		const data = getInputData<{ interpreter: string }>(inputs);
		if (data.interpreter !== null) {
			setInterpreter(data.interpreter);
		}
	}, [inputs])

	const createInput = useCreateInput(inputs, setInputs);

	return <div>
		{
			loading ?
				<Spinner />
				:
				<>{createInput(EInputs.interpreter, {
					fetchOptions: searchInterpreters
				})}
					<Button disabled={interpreter === null || loading} onClick={onAdd}>{t(ETranslation.COMMON_ADD_NEW)}</Button>
				</>
		}
	</div>
}

export default InterpretersOffered;