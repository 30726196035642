import EActionTypes from "../../interfaces/store/EActionTypes";
import IModalAction from "../../interfaces/store/IModalAction";
import IModalState from "../../interfaces/store/IModalState";

type ReducerAction = IModalAction;
type ReducerState = IModalState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
	deletingError: null,
	deleting: false,
	deletingOk: false,
	updating: false,
	updatingError: null,
	updatingOk: false,
};

const updating: ReducerSignature = (state, action): ReducerState => {
	return { ...state, updating: action.isValue ?? false, updatingError: null, updatingOk: false };
};

const updatingOk: ReducerSignature = (state, action): ReducerState => {
	return { ...state, updatingOk: action.isValue ?? false, updating: false };
};

const updatingError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, updatingError: action.error || null, updating: false };
};

const deleting: ReducerSignature = (state, action): ReducerState => {
	return { ...state, deleting: action.isValue ?? false, deletingError: null, deletingOk: false };
};

const deletingOk: ReducerSignature = (state, action): ReducerState => {
	return { ...state, deletingOk: action.isValue ?? false, deleting: false };
};

const deletingError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, deletingError: action.error || null, deleting: false };
};

const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
	switch (action.type) {
		case EActionTypes.MODAL_UPDATING:
			return updating(state, action);
		case EActionTypes.MODAL_UPDATING_ERROR:
			return updatingError(state, action);
		case EActionTypes.MODAL_UPDATING_OK:
			return updatingOk(state, action);
		case EActionTypes.MODAL_DELETING:
			return deleting(state, action);
		case EActionTypes.MODAL_DELETING_ERROR:
			return deletingError(state, action);
		case EActionTypes.MODAL_DELETING_OK:
			return deletingOk(state, action);
		default:
			return state;
	}
};

export default reducer;
