import { IUser } from "../classes/User";
import { customFetch, EFetchMethod } from "../custom-fetch";
import { createOptions, createOptionsForUserLog } from "../utils/option-utils";

export const searchInterpreters = async (term: string, signal: AbortSignal) => {
	const users = search(term, signal, "&role=INTERPRETER");
	return users;
};

export const searchCustomers = async (term: string, signal: AbortSignal) => {
	const users = search(term, signal, "&role=CUSTOMER");
	return users;
};

export const searchUsers = async (term: string, signal: AbortSignal) => {
	const users = search(term, signal, "");
	return users;
};

export const searchAdmins = async (term: string, signal: AbortSignal) => {
	const users = search(term, signal, "&role=ADMIN");
	return users;
};

export const getUserOptions = async (
	ids: string[]
) => {
	const users = await Promise.all(ids.map(id => {
		return customFetch<IUser>("/users?id=" + id);
	}))
	return createOptions(users);
};


export const getUser = (id: string): Promise<IUser> => {
	return customFetch<IUser>(`/users?id=${id}`);
}

const search = async (
	term: string,
	signal: AbortSignal,
	searchRole: string
) => {
	if (term.includes("+")) {
		term = term.replace("+", "%2B");
	}
	const users = await customFetch<IUser[] | string>(
		"/users/search?q=" + term + searchRole,
		EFetchMethod.GET,
		undefined,
		signal
	);
	if (users === "NO_RESULTS") {
		return [];
	}
	return createOptionsForUserLog(users as IUser[]);
};
