import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateInput } from '../../../hooks/useCreateInput';

import { IOrganizationGroup } from '../../../interfaces/IOrganizationGroup';
import IAppState from '../../../interfaces/store/IAppState';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';
import { createOptions } from '../../../utils/option-utils';
import { EInputType, IInputField, IOption } from '../../ui/Input/Input';
import { getInputData, initForm, validateInputs } from '../../ui/Input/input-utils';
import Spinner, { ESpinnerSize } from '../../ui/Spinner/Spinner';
import { EUserRole } from '../../../classes/User';
import { useSalaryInfoAttach } from '../../SalaryInfo/useSalaryInfoAttach';
import SiteOnlyContent from '../../ui/SiteOnlyContent/SiteOnlyContent';
import { ESitePrefix } from '../../../interfaces/ISite';
import SalaryInfoAttach from '../../SalaryInfo/SalaryInfoAttach';
import Accordion from '../../ui/Accordion/Accordion';

enum EInputs {
	name = "name",
	description = "description",
	orderId = "orderId",
	priceSeasonIds = "priceSeasonIds",
}

interface IProps {
	onChange: (organizationGroup: IOrganizationGroup, isValid: boolean) => void;
	organizationGroup: IOrganizationGroup | null;
	loading?: boolean;
	showValidation?: boolean;
}

const OrganizationGroup: React.FC<IProps> = ({ onChange, loading, organizationGroup, showValidation }) => {
	const { salaryInfos, setSalaryInfos, salaryInfosAddHandler, salaryInfosDeleteHandler, salaryInfosEditHandler } = useSalaryInfoAttach();
	const [priceSeasonOptions, setPriceSeasonOptions] = useState<IOption[]>();
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.name]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_NAME,
			value: "",
			validation: {
				required: true
			}
		},
		[EInputs.description]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_DESCRIPTION,
			value: "",
		},
		[EInputs.orderId]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORGANIZATION_GROUP_ORDER_ID,
			value: "",
		},
		[EInputs.priceSeasonIds]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.ORGANIZATION_GROUP_PRICE_SEASONS_IDS_LABEL,
			placeholderTranslation: ETranslation.ORGANIZATION_GROUP_PRICE_SEASONS_IDS_PLACEHOLDER,
			multiple: true,
			value: [],
		},
	});

	const dispatch = useDispatch();

	const {
		priceSeasons,
		priceSeasonsLoading,
	} = useSelector((state: IAppState) => ({
		priceSeasons: state.priceSeason.priceSeasons,
		priceSeasonsLoading: state.priceSeason.priceSeasonsLoading
	}));


	useEffect(() => {
		if (!priceSeasons) {
			dispatch(actions.findPriceSeasons());
		} else {
			setPriceSeasonOptions(createOptions(priceSeasons));
		}
	}, [priceSeasons, dispatch]);
	const { currentUser } = useSelector((state: IAppState) => ({
		currentUser: state.auth.user
	}));

	useEffect(() => {
		if (organizationGroup) {
			initForm(setInputs, organizationGroup);
			setSalaryInfos(organizationGroup.salaryInfos ?? []);
		}
	}, [organizationGroup, setSalaryInfos]);

	useEffect(() => {
		const organizationGroup = getInputData<IOrganizationGroup>(inputs);
		organizationGroup.salaryInfos = salaryInfos;
		const isValid = validateInputs(inputs);
		onChange(organizationGroup, isValid);
	}, [inputs, onChange, salaryInfos]);

	const createInput = useCreateInput(inputs, setInputs, {
		showValidation,
		disabled: loading
	});

	return (
		<>
			{createInput(EInputs.name)}
			{createInput(EInputs.description)}
			{createInput(EInputs.orderId)}
			{(currentUser?.hasRole(EUserRole.ADMIN) && priceSeasonsLoading) ? <Spinner size={ESpinnerSize.SMALL} /> : currentUser?.hasRole(EUserRole.ADMIN) && createInput(EInputs.priceSeasonIds, {
				options: priceSeasonOptions,
			})}
			<br />
			<SiteOnlyContent sites={[ESitePrefix.valitysklinikka]}>
				<Accordion title={"Palkka"}>
					<SalaryInfoAttach salaryInfos={salaryInfos} onAdd={salaryInfosAddHandler} onDelete={salaryInfosDeleteHandler} onEdit={salaryInfosEditHandler} />
				</Accordion>
			</SiteOnlyContent>
		</>
	);
};

export default OrganizationGroup;
