import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { ERoute } from "../../classes/Routes";
import Heading from "../../components/Heading/Heading";
import Alert from "../../components/ui/Alert/Alert";
import Container from "../../components/ui/Container/Container";
import EditButtons from "../../components/ui/EditButtons/EditButtons";
import Spinner from "../../components/ui/Spinner/Spinner";
import UserGroupEdit from "../../components/UserGroups/UserGroupEdit/UserGroupEdit";
import { IUserGroup } from "../../interfaces/IUserGroup";
import IAppState from "../../interfaces/store/IAppState";
import IUserGroupState from "../../interfaces/store/IUserGroupState";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";

interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const UserGroupEditPage: React.FC<IProps> = ({ history, match }) => {
	const [showValidation, setShowValidation] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [editUserGroup, setEditUserGroup] = useState<IUserGroup | null>(null);

	const dispatch = useDispatch();

	const { id } = match.params;
	const isAdd = id === "add";

	const { t } = useTranslation();

	useEffect(() => {
		if (!isAdd) {
			dispatch(actions.getUserGroup(id));
		}
	}, [isAdd, id, dispatch]);

	const { error, loading, saveOrUpdateOk, userGroup, deleting, deletingError, deletingOk } = useSelector<
		IAppState,
		IUserGroupState
	>((state) => state.userGroups);

	useEffect(() => {
		if (saveOrUpdateOk) {
			history.push(ERoute.USER_GROUPS_LIST);
		}
		return () => {
			dispatch(actions.saveOrUpdateUserGroupClear());
			dispatch(actions.getUserGroupClear());
		};
	}, [dispatch, saveOrUpdateOk, history]);

	const updateHandler = useCallback((userGroup: IUserGroup, isValid: boolean) => {
		setEditUserGroup(userGroup);
		setIsValid(isValid);
	}, []);

	const submitHandler = () => {
		if (editUserGroup) {
			if (isAdd) {
				dispatch(actions.saveUserGroup(editUserGroup));
			} else {
				editUserGroup.id = id;
				dispatch(actions.updateUserGroup(editUserGroup));
			}
		}
	};

	const deleteHandler = () => {
		if (userGroup) {
			dispatch(actions.deleteUserGroup(userGroup.id));
		}
	};

  return (
		<Container>
			{error && <Alert>{error}</Alert>}
			<Heading>{t(ETranslation.USER_GROUP_EDIT_TITLE)}</Heading>
			{loading ? (
				<Spinner />
			) : (
				<>
					<UserGroupEdit
						onChange={updateHandler}
						userGroup={userGroup}
						loading={loading}
						showValidation={showValidation}
					/>
					{error && <Alert>{error}</Alert>}
					<EditButtons
						onSave={isValid ? submitHandler : () => setShowValidation(true)}
						disabled={showValidation && !isValid}
						loading={loading}
						onCancel={() => history.goBack()}
						onDelete={deleteHandler}
						deleteText={t(ETranslation.USER_GROUP_EDIT_DELETE)}
						onDeleteClose={() => dispatch(actions.deleteUserGroupClear())}
						onDeleteDone={() => history.push(ERoute.USER_GROUPS_LIST)}
						deleteError={deletingError}
						deleteLoading={deleting}
						deleteOk={deletingOk}
						isAdd={isAdd}
					/>
				</>
			)}
		</Container>
	);
};

export default UserGroupEditPage;
