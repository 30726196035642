import React from "react";
import { useTranslation } from "react-i18next";
import {
	EInterpreterOfferStatus,
} from "../../../shared/interpretation-data";
import Badge, { EBadgeType } from "../../ui/Badge/Badge";
import InputGroup from "../../ui/InputGroup/InputGroup";
import { useInterpretation } from "../../../hooks/useInterpretation";

interface IProps {
	status: EInterpreterOfferStatus;
}

const InterpreterStatus: React.FC<IProps> = ({ status }) => {
	const { t } = useTranslation();

	const { getInterpreterStatusString } = useInterpretation();
	let text = getInterpreterStatusString(t, status);
	let badgeType = EBadgeType.SUCCESS;
	switch (status) {
		case EInterpreterOfferStatus.DRAFT:
		case EInterpreterOfferStatus.BUSY:
		case EInterpreterOfferStatus.CANCELLED:
		case EInterpreterOfferStatus.REJECTED:
			badgeType = EBadgeType.DANGER;
			break;

		case EInterpreterOfferStatus.OPEN:
			badgeType = EBadgeType.WARNING;
			break;

		case EInterpreterOfferStatus.CLOSED:
		case EInterpreterOfferStatus.CONFIRMED:
		case EInterpreterOfferStatus.ACCEPTED:
		case EInterpreterOfferStatus.CALLED:
		case EInterpreterOfferStatus.COMPLETED:
			badgeType = EBadgeType.SUCCESS;
			break;
	}

	return (
		<>
			<InputGroup>
				<Badge type={badgeType}>{text}</Badge>
			</InputGroup>
		</>
	);
};

export default InterpreterStatus;
