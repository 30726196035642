import React, { useEffect, useState } from "react";
import Container from "../../components/ui/Container/Container";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import IAppState from "../../interfaces/store/IAppState";
import Spinner from "../../components/ui/Spinner/Spinner";
import IWorkingHoursState from "../../interfaces/store/IWorkingHoursState";
import { findWorkingHoursMonthlySummary } from "../../store/actions/workingHoursActions";
import WorkingHoursSummariesSearch, { IWorkingHoursSummariesSearchParams } from "../../components/WorkingHoursSummaries/WorkingHoursSummariesSearch";
import WorkingHoursSummariesList from "../../components/WorkingHoursSummaries/WorkingHoursSummariesList";
import { IWorkingHoursMonthlySummary } from "../../interfaces/IWorkingHoursMonthlySummary";
import Fieldset from "../../components/ui/Fieldset/Fieldset";

interface IProps extends RouteComponentProps {}

interface ISummaryGroup {
	[key: string]: IWorkingHoursMonthlySummary[];
}

const WorkingHoursSummariesListPage: React.FC<IProps> = () => {
	const [summaryGroup, setSummaryGroup] = useState<ISummaryGroup | null>();

	const dispatch = useDispatch();

	const { summaries, loading, error } = useSelector<IAppState, IWorkingHoursState>((state) => state.workingHours);

	const onSearch = (params: IWorkingHoursSummariesSearchParams) =>{
		dispatch(findWorkingHoursMonthlySummary(params));
	}

	useEffect(() => {
		if (summaries) {
			const summaryGroup = summaries.reduce<ISummaryGroup>((value, summary) => {
				const arr = value[summary.userId] ?? [];
				arr.push(summary);
				value[summary.userId] = arr;
				return value;
			}, {});
			setSummaryGroup(summaryGroup);
		}

	}, [summaries]);

	return (
		<Container>
			<WorkingHoursSummariesSearch onSearch={onSearch} />
			{loading && <Spinner />}
			{error && <p>{error}</p>}
			{summaryGroup && Object.keys(summaryGroup).map(key => (
				<Fieldset key={key} label={summaryGroup[key][0].userName} transparent>
					 <WorkingHoursSummariesList summaries={summaryGroup[key]} />
				</Fieldset>
			))}
		</Container>
	)
}

export default WorkingHoursSummariesListPage;