import { EBadgeType } from "../components/ui/Badge/Badge";
import { EWorkingHoursDateEventType } from "./../interfaces/IWorkingHoursDate";



export const WORKING_HOURS_EVENT_HOURS_TYPES = [
	EWorkingHoursDateEventType.ANNUAL_LEAVE,
	EWorkingHoursDateEventType.ANNUAL_LEAVE_FIX,
	EWorkingHoursDateEventType.FLEX_FIX,
];

export const isWorkingHoursEventHoursType = (type: EWorkingHoursDateEventType | null) => {
	return (
		type && WORKING_HOURS_EVENT_HOURS_TYPES.includes(type)
	);
};

export const getWorkingHoursEventBadgeType = (type: EWorkingHoursDateEventType | null) => {
	let badgeType = EBadgeType.DEFAULT;
	if (type) {
		switch (type) {
			case EWorkingHoursDateEventType.WORK:
				badgeType = EBadgeType.SUCCESS;
				break;
			case EWorkingHoursDateEventType.FLEX_FIX:
			case EWorkingHoursDateEventType.ANNUAL_LEAVE_FIX:
				badgeType = EBadgeType.WARNING;
				break;
			case EWorkingHoursDateEventType.ANNUAL_LEAVE:
			case EWorkingHoursDateEventType.ABSENT_FLEX:
			case EWorkingHoursDateEventType.ABSENT_UNPAID:
			case EWorkingHoursDateEventType.SICK_LEAVE:
			case EWorkingHoursDateEventType.NO_SHIFT:
				badgeType = EBadgeType.DANGER;
				break;
		}
	}

	return badgeType;
};
