import { TFunction } from "i18next";
import { ETranslation } from "../translations/translation-keys";
import { IMessage, Message } from "./Message";
import { IOrganization } from "../interfaces/IOrganization";

export enum EMessageGroupType {
  MESSAGE_GROUP = "MESSAGE_GROUP",
  BANK = "BANK",
  ATTACHMENT = "ATTACHMENT"
}

export interface IMessageGroup {
  id: string;
  created: string;
  name: string;
  type: EMessageGroupType;
  amountInterpretationSource: number;
  amountInterpretationTarget: number;
  amountMessages: number;
  amountOther: number;
  amountTranslationSource: number;
  amountTranslationTarget: number;
  messages?: IMessage[];
  organizations?: IOrganization[];
}

export class MessageGroup implements IMessageGroup {
  id: string;
  created: string;
  name: string;
  type: EMessageGroupType;
  amountInterpretationSource: number;
  amountInterpretationTarget: number;
  amountMessages: number;
  amountOther: number;
  amountTranslationSource: number;
  amountTranslationTarget: number;
  messages: Message[] | undefined;
  organizations?: IOrganization[];

  constructor(messageGroup: IMessageGroup) {
    this.id = messageGroup.id;
    this.created = messageGroup.created;
    this.name = messageGroup.name || "";
    this.type = messageGroup.type || "";
    this.amountInterpretationSource = messageGroup.amountInterpretationSource;
    this.amountInterpretationTarget = messageGroup.amountInterpretationTarget;
    this.amountMessages = messageGroup.amountMessages;
    this.amountOther = messageGroup.amountOther;
    this.amountTranslationSource = messageGroup.amountTranslationSource;
    this.amountTranslationTarget = messageGroup.amountTranslationTarget;
    this.messages = Message.fromArray(messageGroup.messages);
    this.organizations = messageGroup.organizations;
  }

  public static fromArray(
    messageGroups?: IMessageGroup[]
  ): MessageGroup[] | undefined {
    if (!messageGroups || !messageGroups.length) return undefined;
    return messageGroups.map((messageGroup) => new MessageGroup(messageGroup));
  }

  public getTypeString(t: TFunction) {
    if (!this.type) return "";
    let translation;
    switch (this.type) {
      case EMessageGroupType.BANK:
        translation = ETranslation.MESSAGE_RECORDINGBANK;
        break;
      case EMessageGroupType.MESSAGE_GROUP:
        translation = ETranslation.MESSAGE_MESSAGE_TRANSLATION;
        break;
      case EMessageGroupType.ATTACHMENT:
        translation = ETranslation.COMMON_ATTACHMENT;
        break;
    }
    return t(translation);
  }
}
