import React, { useEffect, useState } from "react";
import * as dateFns from "date-fns";
import { useTranslation } from "react-i18next";

import { ILogItem } from "../../../interfaces/ILogItem";
import { ETranslation } from "../../../translations/translation-keys";
import Alert from "../../ui/Alert/Alert";
import Input, { EInputType, TInputValue } from "../../ui/Input/Input";
import Spinner from "../../ui/Spinner/Spinner";
import Table from "../../ui/Table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./LogItemList.module.scss";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Button from "../../ui/Button/Button";
import { User } from "../../../classes/User";
import { ELogItemUserRole } from "../../../shared/log-items-data";
import { adminUiUrl } from "../../../config";

interface IProps {
  logItems: ILogItem[] | null;
  loading: boolean;
  error: string | null;
  onClick: (id: string) => void;
  user: User;
  inputless?: boolean;
}

const LogItemList: React.FC<IProps> = ({
  logItems,
  loading,
  error,
  onClick,
  user,
  inputless
}) => {
  const [filter, setFilter] = useState("");

  const { t } = useTranslation();

  const setFilterText = (text: string) => {
    if (text != null) {
      setFilter(text as string);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams != null) {
      const searchParams = queryParams.get("search");
      if (searchParams !== "" && searchParams != null) {
        setFilterText(searchParams);
      }
    }
  }, []);

  if (error) {
    return <Alert>{error}</Alert>;
  }

  if (!logItems || loading) {
    return <Spinner />;
  }

  if (logItems.length === 0) {
    return <p>{t(ETranslation.LOG_ITEM_LIST_NO_LOGS)}</p>;
  }

  const logFilter = (item: string) => {
    if (item != null) {
      return !!item && item.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
    }
  };

  const filterLogItem = (item: ILogItem): boolean => {
    return (
      filter.length === 0 ||
      logFilter(item.summary) ||
      logFilter(item.name) ||
      logFilter(item.message) ||
      logFilter(item.adminNotes) ||
      logFilter(item.event) ||
      logFilter(item.interpretationKeyString) ||
      (!!item.userKeyString &&
        item.userKeyString.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
    );
  };

  const formatDate = (date: string) => {
    return `${dateFns.format(new Date(date), "dd.MM.yyyy HH:mm")}`;
  };

  const linkToUser = (userRole: ELogItemUserRole, item: ILogItem) => {
    let link = undefined;
    if (userRole === ELogItemUserRole.CUSTOMER) {
      link = adminUiUrl + `/users/customers/${item.userKeyString}`;
    } else if (userRole === ELogItemUserRole.INTERPRETER) {
      link = adminUiUrl + `/users/interpreters/${item.userKeyString}`;
    } else if (userRole === ELogItemUserRole.ADMIN) {
      link = adminUiUrl + `/users/admins/${item.userKeyString}`;
    } else {
      link = adminUiUrl + `/dashboard`;
    }
    return (
      <Link
        to={{ pathname: link }}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        <FontAwesomeIcon
          className={classes.LinkIcon}
          icon={faExternalLinkAlt}
        ></FontAwesomeIcon>
      </Link>
    );
  };

  const linkTo = (link: string) => {
    return (
      <Link
        to={{ pathname: link }}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        <FontAwesomeIcon
          className={classes.LinkIcon}
          icon={faExternalLinkAlt}
        ></FontAwesomeIcon>
      </Link>
    );
  };

  return (
    <>
	{!inputless &&
	<>
      <Input
        containerStyles={{ margin: "1rem 0" }}
        type={EInputType.text}
        inputName="filter"
        value={filter}
        onChange={(value: TInputValue) => setFilter(value as string)}
        placeholderTranslation={ETranslation.LOG_ITEM_LIST_FILTER}
      />
      <Button onClick={() => setFilterText("")}>
        {t(ETranslation.COMMON_CLEAR)}
      </Button>
	</>
	}
      <Table hover={true}>
        <thead>
          <tr>
            <th>{t(ETranslation.COMMON_CREATED)}</th>
            <th>{t(ETranslation.COMMON_USER)}</th>
            {(user.isYoupretAdmin && !inputless) && <th></th>}
            <th>{t(ETranslation.LOG_ITEM_LIST_SUMMARY)}</th>
            {(user.isYoupretAdmin && !inputless) && <th></th>}
            <th>{t(ETranslation.COMMON_MESSAGE)}</th>
            {(user.isYoupretAdmin && !inputless) && (
              <th>{t(ETranslation.LOG_ITEM_LIST_EVENT)}</th>
            )}
            {user.isYoupretAdmin && (
              <th>{t(ETranslation.COMMON_ADMIN_NOTES)}</th>
            )}
            {(user.isYoupretAdmin && !inputless) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {logItems.filter(filterLogItem).map((logItem, index) => (
            <tr
              key={logItem.id}
              // onClick={() => onClick(logItem.id)}
            >
              <td>{formatDate(logItem.created)}</td>
              <td onClick={() => !inputless && setFilterText(logItem.name)}>
                {logItem.name}
                <br /> {logItem.userOrganizationName}
              </td>
              {(user.isYoupretAdmin && !inputless) && (
                <td>
                  {logItem.name && linkToUser(logItem.userRole, logItem)}

                  <br />
                  {logItem.userOrganizationName &&
                    linkTo(
                      adminUiUrl + `/organizations/${logItem.userOrganizationKeyString}`
                    )}
                </td>
              )}

              <td onClick={() => !inputless && setFilterText(logItem.summary)}>
                {logItem.summary}
              </td>
              {(user.isYoupretAdmin && !inputless) && (
                <td>
                  {logItem.summary &&
                    linkTo(
                      adminUiUrl + `/interpretations/${logItem.interpretationKeyString}`
                    )}
                </td>
              )}

              <td onClick={() => !inputless && setFilterText(logItem.message)}>
                {logItem.message}
              </td>
              {(user.isYoupretAdmin && !inputless) && <td onClick={() => setFilterText(logItem.event)}>{logItem.event}</td>}
              {user.isYoupretAdmin && (
                <td onClick={() => !inputless && setFilterText(logItem.adminNotes)}>
                  {logItem.adminNotes}
                </td>
              )}
              {(user.isYoupretAdmin && !inputless) && (
                <td onClick={() => onClick(logItem.id)}>
                  {t(ETranslation.COMMON_OPEN_INFO)}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default LogItemList;
