import React, {  useState } from "react";
import classes from "./Accessibility.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

interface IProps { 
	el: React.RefObject<HTMLDivElement>;
}

const BackToTop: React.FC<IProps> = ({el}) => {
	const [visible, setVisible] = useState(false)
	// 200 offset to show back to top button
	const toggleVisible = () => {
		el.current?.scrollTop ?? 0 > 200 ? setVisible(true) : setVisible(false)
	};
	// Actual scroll to top event
	const scrollToTop = () => {
		el.current?.scrollTo({
			top: 0,
			behavior: "smooth",
		})
	}

	// Event listener. No useEffect used. I Believe the only reason would be to save mem on cleancycle
	// but this element should always be available(?)
	el.current?.addEventListener('scroll', toggleVisible);

	return (
		<div onClick={scrollToTop} className={classes.scrollToTop} style={{display: visible ? "inline" : "none"}}>
			<FontAwesomeIcon style={{width: "40px", height: "40px"}} icon={faArrowUp} />
		</div>
	)
};

export default BackToTop;
