import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLanguages } from "../../../hooks/useLanguages";
import { IInterpretation } from "../../../interfaces/IInterpretation";
import IAppState from "../../../interfaces/store/IAppState";
import IAuthState from "../../../interfaces/store/IAuthState";
import { searchCustomers } from "../../../services/userServices";
import * as actions from "../../../store/actions";
import {
  CUSTOMER_ESTIMATES,
  EConnectionDetail,
  ECustomerEstimates,
  EInterpretationType,
  INTERPRETATION_CONNECTION_DETAIL_ALL,
  INTERPRETATION_GENDER,
  INTERPRETATION_TAGS,
} from "../../../shared/interpretation-data";
import { ETranslation } from "../../../translations/translation-keys";
import { EInputType, IInputField, IOption } from "../../ui/Input/Input";
import {
  getInputData,
  initForm,
  updateInputHandler,
  validateInputs,
} from "../../ui/Input/input-utils";
import IUserState from "../../../interfaces/store/IUserState";
import Alert, { EAlertColor } from "../../ui/Alert/Alert";
import { ICostCenter, IOrganization } from "../../../interfaces/IOrganization";
import {
  createLanguageOptions,
  createOptionsLabelAsValue,
} from "../../../utils/option-utils";
import { InterpretationContext } from "../../../pages/Interpretations/OrderInterpretation/order-interpretation-context";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { ILanguageStatusSearchOptions } from "../../../store/actions/commonActions";
import { ILanguageStatus } from "../../../interfaces/ILanguage";
import InputGroupOrder from "../../ui/InputGroup/InputGroupOrder/InputGroupOrder";
import { IUser } from "../../../classes/User";
import { useInterpretation } from "../../../hooks/useInterpretation";
import { useTranslation } from "react-i18next";

enum EInputs {
  startDate = "startDate",
  startTime = "startTime",
  interpreter = "interpreter",
  customer = "customer",
  fromLanguage = "fromLanguage",
  toLanguage = "toLanguage",
  type = "type",
  status = "status",
  gender = "gender",
  adminNotes = "adminNotes",
  customerReference = "customerReference",
  customerReference2 = "customerReference2",
  customerEstimate = "customerEstimate",
  tags = "tags",
  connectionType = "connectionType",
  phoneNumber = "phoneNumber",
  costCenter = "costCenter",
  videoConferenceInfo = "videoConferenceInfo",
  videoConferenceUrl = "videoConferenceUrl"
}

const OrderInterpretationInstant: React.FC = () => {
  const { interpretation, onChange, loading, organization, showValidation } =
    useContext(InterpretationContext);
  const dispatch = useDispatch();

  const { languagesStatus } = useSelector((state: IAppState) => ({
    languagesStatus: state.common.languagesStatus,
  }));

  const { languageOptions } = useLanguages();
  const [toLanguageOptions, setToLanguageOptions] = useState<IOption[]>();
  const [costCenterOptions, setCostCenterOptions] = useState<IOption[]>([]);
  const { user } = useSelector<IAppState, IAuthState>((state) => state.auth);
  const { user: customerUser } = useSelector<IAppState, IUserState>((state) => state.user);
  const [customerOrganization, setCustomerOrganization] = useState<IOrganization | null | undefined>(organization);
  const { getUserConnectionDetails } = useInterpretation(customerUser ? customerUser : user as IUser,customerOrganization as IOrganization)
  const [connectionDetailOptions, setConnectionDetailOptions] = useState<IOption[]>(getUserConnectionDetails);
  const {setConnectionDetailInformation} = useInterpretation();
  const [connectionDetailFound, setConnectionDetailFound] = useState(false);
  const { t } = useTranslation();
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.customer]: {
      type: EInputType.reactSelectSearch,
      labelTranslation: ETranslation.COMMON_CUSTOMER,
      validation: {
        required: user?.isYoupretAdmin,
      },
      value: "",
    },
    [EInputs.fromLanguage]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.COMMON_FROM_LANGUAGE,
      validation: {
        required: true
      },
      value: "fi",
    },
    [EInputs.toLanguage]: {
      type: EInputType.toLanguageSelect,
      labelTranslation: ETranslation.COMMON_TO_LANGUAGE,
      value: []
    },
    [EInputs.customerReference]: {
      type: EInputType.textarea,
      customLabel: customerOrganization?.customerReferenceTitle,
      labelTranslation: ETranslation.COMMON_CUSTOMER_REFERENCE,
      value: "",
      validation: {
        required: customerOrganization?.customerReferenceMandatory,
      },
    },
    [EInputs.customerReference2]: {
      type: EInputType.textarea,
      customLabel: customerOrganization?.customerReference2Title,
      labelTranslation: ETranslation.COMMON_CUSTOMER_REFERENCE_2,
      value: "",
      validation: {
        required: customerOrganization?.customerReference2Mandatory,
      },
    },
    [EInputs.costCenter]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.INTERPRETATION_CUSTOMER_COST_CENTER,
      value: [],
      validation: {
        required: customerOrganization?.costCenterMandatory,
      },
    },
    [EInputs.tags]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.INTERPRETATION_TAGS,
      value: [],
      multiple: true,
      options: INTERPRETATION_TAGS,
    },
    [EInputs.gender]: {
      type: EInputType.radio,
      labelTranslation: ETranslation.INTERPRETATION_GENDER,
      value: [],
      uncheckable: true,
      options: INTERPRETATION_GENDER,
    },
    [EInputs.connectionType]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.COMMON_CONNECTION_DETAIL,
      value: EConnectionDetail.CUSTOMER_CALL_TO_INTERPRETER,
    },
    [EInputs.videoConferenceUrl]: {
      type: EInputType.text,
      labelTranslation: ETranslation.INTERPRETATION_VIDEO_URL,
      value: "",
    },
    [EInputs.videoConferenceInfo]: {
      type: EInputType.text,
      labelTranslation: ETranslation.INTERPRETATION_VIDEO_INFO,
      value: "",
    },
    [EInputs.phoneNumber]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_PHONENUMBER,
      value: "",
    },
    [EInputs.type]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_PHONENUMBER,
      value: EInterpretationType.INSTANT,
    },
    [EInputs.customerEstimate]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.INTERPRETATION_CUSTOMER_ESTIMATE,
      value: ECustomerEstimates.CUSTOMER_ESTIMATE_LT15MIN,
      options: CUSTOMER_ESTIMATES,
    },
  });

  const customerValue = inputs[EInputs.customer].value as IOption;
  const connectionTypeValue = inputs[EInputs.connectionType].value;

  const createInput = useCreateInput(inputs, setInputs, {
    showValidation,
    disabled: loading,
  });

  useEffect(() => {
    if (!connectionTypeValue || !connectionDetailOptions) return;
    const connectionDetail = connectionDetailOptions.find((i) => i.value === connectionTypeValue);
    if(connectionDetail) {
      setConnectionDetailFound(true)
    } else {
      setConnectionDetailFound(false)
    }
  }, [connectionTypeValue, connectionDetailOptions]);

  useEffect(() => {
    return () => {
      dispatch(actions.getInterpretationClear());
      dispatch(actions.getUserClear());
    };
  }, [dispatch]);

  useEffect(() => {
    if (interpretation) {
      initForm(setInputs, null);
    }
  }, [dispatch, interpretation]);

  useEffect(() => {
    if (!customerValue && user?.isYoupretAdmin) {
      setCustomerOrganization(null);
      dispatch(actions.getUserClear());
    }
  }, [customerValue, dispatch, user]);

  useEffect(() => {
	if(!customerValue) return;
    if (customerValue.value) {
      dispatch(actions.getUser(customerValue.value));
    }
  }, [customerValue, dispatch]);

  useEffect(() => {
    if (!customerUser || !customerUser.organizations) return;
    dispatch(actions.getOrganization(customerUser.organizations[0].id));
  }, [customerUser, dispatch]);

  useEffect(() => {
    if (!customerUser) return;
    setConnectionDetailOptions(getUserConnectionDetails())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerUser, customerOrganization]);

  useEffect(() => {
    if (!customerUser || !customerUser.organizations) {
      setCustomerOrganization(null);
    } else if (user && user.isYoupretAdmin){
      setCustomerOrganization(organization);
    }
    if (user && user.organizations && !user.isYoupretAdmin) {
      setCustomerOrganization(organization);
    }
  }, [customerUser, organization, user]);

  useEffect(() => {
    if (customerUser) {
      updateInputHandler(
        EInputs.phoneNumber,
        customerUser.phoneNumber,
        setInputs
      );
    } else if (user) {
      updateInputHandler(EInputs.phoneNumber, user.phoneNumber, setInputs);
    }
  }, [customerUser, user]);

  useEffect(() => {
    const interpretation = getInputData<IInterpretation>(inputs);
    if(!interpretation) return;
    const interpretationDetails = setConnectionDetailInformation(interpretation)
    if(!interpretationDetails) return;
    const isValid = validateInputs(inputs);
    onChange(interpretation, isValid);
  }, [inputs, onChange, setConnectionDetailInformation]);

  useEffect(() => {
    if (!customerOrganization || !customerOrganization?.costCenters) return;
    const costCenters = customerOrganization.costCenters;
    setCostCenterOptions(
      createOptionsLabelAsValue(costCenters as ICostCenter[])
    );
  }, [customerOrganization]);

  useEffect(() => {
    const interpretation = getInputData<IInterpretation>(inputs);
    const { fromLanguage, gender, tags } = interpretation;
    const toLanguages = [];
    if (languageOptions) {
      for (var i = 0; i < languageOptions.length; i++) {
        toLanguages.push(languageOptions[i].value);
      }
    }

    let inputsData: ILanguageStatusSearchOptions = {
      fromLanguage,
      toLanguages,
      tags,
      gender,
      clientConsole: true,
    };

    dispatch(actions.getLanguagesStatus(inputsData));

    const interval = setInterval(() => {
      dispatch(actions.getLanguagesStatus(inputsData));
    }, 5000);
    return () => clearInterval(interval);
  }, [dispatch, inputs, languageOptions]);

  useEffect(() => {
    if (!languagesStatus) return;
    setToLanguageOptions(
      createLanguageOptions(languagesStatus as ILanguageStatus[])
    );
  }, [languagesStatus]);

  return (
    <>
      {user?.isYoupretAdmin && (
        <>
          <InputGroupOrder>
            {createInput(EInputs.customer, {
              fetchOptions: async (term, signal) => {
                const users = searchCustomers(term, signal);
                return users;
              },
            })}
          </InputGroupOrder>
          {customerUser?.description && (
            <InputGroupOrder>
              <Alert color={EAlertColor.INFO}>
                {customerUser?.description}
              </Alert>
            </InputGroupOrder>
          )}
        </>
      )}
      <InputGroupOrder>
        {createInput(EInputs.fromLanguage, {
          options: languageOptions,
        })}
      </InputGroupOrder>
      <InputGroupOrder>
        {createInput(EInputs.toLanguage, {
          options: toLanguageOptions,
        })}
      </InputGroupOrder>
      {customerOrganization?.costCenters && (
        <InputGroupOrder>
          {createInput(EInputs.costCenter, {
            options: costCenterOptions,
          })}
        </InputGroupOrder>
      )}
      <InputGroupOrder>{createInput(EInputs.connectionType, {
        options: user?.isYoupretAdmin ? INTERPRETATION_CONNECTION_DETAIL_ALL : connectionDetailOptions
      })}</InputGroupOrder>
      {!connectionDetailFound && customerUser &&
        <InputGroupOrder>
          <Alert color={EAlertColor.DANGER}>
            {t(ETranslation.INTERPRETATION_NO_MATCH_CONNECTION_DETAIL)}
          </Alert>
        </InputGroupOrder>
      }
      {inputs[EInputs.connectionType].value ===
      EConnectionDetail.INTERPRETER_CALL_TO_CUSTOMER ? (
        <InputGroupOrder>{createInput(EInputs.phoneNumber)}</InputGroupOrder>
      ) : null}
      {inputs[EInputs.connectionType].value ===
      EConnectionDetail.VIDEO_CONFERENCE ? (
        <>
          <InputGroupOrder>{createInput(EInputs.videoConferenceUrl)}</InputGroupOrder>
          <InputGroupOrder>{createInput(EInputs.videoConferenceInfo)}</InputGroupOrder>
        </>
      ) : null}
      {/* <InputGroupOrder>{createInput(EInputs.tags)}</InputGroupOrder> */}
      <InputGroupOrder>{createInput(EInputs.gender)}</InputGroupOrder>
      <InputGroupOrder>{createInput(EInputs.customerEstimate)}</InputGroupOrder>
      <InputGroupOrder>{createInput(EInputs.customerReference)}</InputGroupOrder>
      {customerOrganization?.customerReference2Mandatory && (
        <InputGroupOrder>{createInput(EInputs.customerReference2)}</InputGroupOrder>
      )}
    </>
  );
};

export default OrderInterpretationInstant;
