import React from "react";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";
import Badge, { EBadgeType } from "../Badge/Badge";

interface IProps {
  active: boolean;
  trueValueTitle?: String;
  falseValueTitle?: String;
}

const ActiveBadge: React.FC<IProps> = ({ active, falseValueTitle, trueValueTitle }) => {
  const { t } = useTranslation();
  return active ? (
    <Badge type={EBadgeType.SUCCESS} block>{trueValueTitle || t(ETranslation.UI_ACTIVEBADGE_ACTIVE)}</Badge>
  ) : (
    <Badge type={EBadgeType.DANGER} block>{falseValueTitle || t(ETranslation.UI_ACTIVEBADGE_PASSIVE)}</Badge>
  );
};

export default ActiveBadge;
