import React from "react";
import { ISummary } from "../../../store/actions/summariesActions";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";

interface IProps {
	summary: ISummary;
}

const SummaryPaymentInfo: React.FC<IProps> = ({ summary }) => {
	const { t } = useTranslation();
	return (<table>
		<tbody>
			<tr>
				<td>
					{t(ETranslation.SUMMARY_COMPENSATION_TO_ACCOUNT)}{" "}
					{summary.user.iban}
				</td>
			</tr>
			<tr>
				<td>
					{t(ETranslation.SUMMARY_PAYMENT_DATE)}{" "}
					{summary.paymentDate}
				</td>
			</tr>
			<tr>
				<td>
					{t(ETranslation.SUMMARY_NET_AMOUNT_AFTER_DEDUCTIONS)}
				</td>
			</tr>
		</tbody>
	</table>);
};

export default SummaryPaymentInfo;
