import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute } from '../../../classes/Routes';
import PriceSeasonRule from '../../../components/PriceSeason/PriceSeasonRule/PriceSeasonRule';
import Alert, { EAlertColor } from '../../../components/ui/Alert/Alert';
import Container from '../../../components/ui/Container/Container';
import EditButtons from '../../../components/ui/EditButtons/EditButtons';
import Spinner from '../../../components/ui/Spinner/Spinner';
import { IPriceSeasonRule } from '../../../interfaces/IPriceSeasonRule';
import IAppState from '../../../interfaces/store/IAppState';
import * as actions from '../../../store/actions';
import Heading from '../../../components/Heading/Heading';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const PriceSeasonRuleEditPage: React.FC<IProps> = ({ history, match }) => {
  const [showValidation, setShowValidation] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [
    editPriceSeasonRule,
    setEditPriceSeasonRule,
  ] = useState<IPriceSeasonRule | null>(null);

  const dispatch = useDispatch();

  const { id } = match.params;
  const isAdd = id === "add";

  const { t } = useTranslation();

  useEffect(() => {
    if (!isAdd) {
      dispatch(actions.getPriceSeasonRule(id));
    }
  }, [isAdd, id, dispatch]);

  const { error, loading, saveOrUpdateOk, priceSeasonRule, deleteError, deleteLoading, deleteOk } = useSelector(
    (state: IAppState) => ({
      error: state.priceSeason.priceSeasonRulesError,
      loading: state.priceSeason.priceSeasonRulesLoading,
      saveOrUpdateOk: state.priceSeason.priceSeasonRuleSaveOrUpdateOk,
      priceSeasonRule: state.priceSeason.priceSeasonRule,
      deleteError: state.priceSeason.priceSeasonRuleDeleteError,
      deleteLoading: state.priceSeason.priceSeasonRuleDeleteLoading,
      deleteOk: state.priceSeason.priceSeasonRuleDeleteOk
    })
  );

  useEffect(() => {
    if (saveOrUpdateOk) {
      history.push(ERoute.PRICE_SEASON_RULES_LIST);
    }
    return () => {
      dispatch(actions.saveOrUpdatePriceSeasonRuleClear());
      dispatch(actions.getPriceSeasonRuleClear());
    };
  }, [dispatch, saveOrUpdateOk, history]);

  const updateHandler = useCallback(
    (priceSeasonRule: IPriceSeasonRule, isValid: boolean) => {
      setEditPriceSeasonRule(priceSeasonRule);
      setIsValid(isValid);
    },
    []
  );

  const submitHandler = () => {
    if (editPriceSeasonRule) {
      if (isAdd) {
        dispatch(actions.savePriceSeasonRule(editPriceSeasonRule));
      } else {
        editPriceSeasonRule.id = id;
        dispatch(actions.updatePriceSeasonRule(editPriceSeasonRule));
      }
    }
  };

  const deleteHandler = () => {
    if(priceSeasonRule) {
      dispatch(actions.deletePriceSeasonRule(priceSeasonRule.id));
    }
  }

  return (
    <Container>
      {error && <Alert>{error}</Alert>}
      {loading && <Spinner centerAbsolute />}
      <Heading>{t(ETranslation.PAGES_PRICE_RULES_TITLE)}</Heading>
      <Alert color={EAlertColor.INFO}>
      {t(ETranslation.PAGES_PRICE_RULES_INFO_1)}
      </Alert>
      <Alert color={EAlertColor.INFO}>
      {t(ETranslation.PAGES_PRICE_RULES_INFO_2)}
      </Alert>
      <PriceSeasonRule
        isAdd={isAdd}
        onChange={updateHandler}
        priceSeasonRule={priceSeasonRule}
        loading={loading}
        showValidation={showValidation}
      />
      {error && <Alert>{error}</Alert>}
      <EditButtons
        onSave={isValid ? submitHandler : () => setShowValidation(true)}
        disabled={showValidation && !isValid}
        loading={loading}
        onCancel={() => history.goBack()}
        onDelete={deleteHandler}
        deleteText={ETranslation.PAGES_PRICE_RULE_DELETE_CONFIRM}
        onDeleteClose={() => dispatch(actions.deletePriceSeasonRuleClear())}
        onDeleteDone={() => history.push(ERoute.PRICE_SEASON_RULES_LIST) }
        deleteError={deleteError}
        deleteLoading={deleteLoading}
        deleteOk={deleteOk}
        isAdd={isAdd}
      />
    </Container>
  );
};

export default PriceSeasonRuleEditPage;
