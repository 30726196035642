import EActionTypes from '../../interfaces/store/EActionTypes';
import IOrganizationGroupAction from '../../interfaces/store/IOrganizationGroupAction';
import IOrganizationGroupState from '../../interfaces/store/IOrganizationGroupState';
import { ETranslation } from '../../translations/translation-keys';

type ReducerAction = IOrganizationGroupAction;
type ReducerState = IOrganizationGroupState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  organizationGroup: null,
  organizationGroupLoading: false,
  organizationGroupError: null,

  organizationGroups: null,
  organizationGroupsLoading: false,
  organizationGroupsError: null,

  organizationGroupSaveOrUpdateOk: false,
  organizationGroupSaveOrUpdateError: null,
  organizationGroupSaveOrUpdateLoading: false,

  organizationGroupDeleteError: null,
  organizationGroupDeleteLoading: false,
  organizationGroupDeleteOk: false,

};

const findOrganizationGroupsStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, organizationGroupsError: null, organizationGroupsLoading: true };
};

const findOrganizationGroupsSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    organizationGroups: action.organizationGroups || null,
    organizationGroupsError: null,
    organizationGroupsLoading: false,
  };
};

const findOrganizationGroupsFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, organizationGroups: null, organizationGroupsLoading: false, organizationGroupsError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getOrganizationGroupStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, organizationGroupError: null, organizationGroupLoading: true };
};

const getOrganizationGroupSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    organizationGroup: action.organizationGroup || null,
    organizationGroupError: null,
    organizationGroupLoading: false,
  };
};

const getOrganizationGroupFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, organizationGroup: null, organizationGroupLoading: false, organizationGroupError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};

const getOrganizationGroupClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, organizationGroup: null, organizationGroupLoading: false, organizationGroupError: null};
};

const deleteOrganizationGroupStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, organizationGroupDeleteError: null, organizationGroupDeleteLoading: true, organizationGroupDeleteOk: false };
};

const deleteOrganizationGroupSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    organizationGroupDeleteError: null,
    organizationGroupDeleteLoading: false,
    organizationGroupDeleteOk: true
  };
};

const deleteOrganizationGroupFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, organizationGroupDeleteLoading: false, organizationGroupDeleteError: action.error as string, organizationGroupDeleteOk: false };
};

const deleteOrganizationGroupClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, organizationGroupDeleteError: null, organizationGroupDeleteLoading: false, organizationGroupDeleteOk: false };
};

const saveOrUpdateOrganizationGroupStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, organizationGroupSaveOrUpdateError: null, organizationGroupSaveOrUpdateLoading: true };
};

const saveOrUpdateOrganizationGroupSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    organizationGroups: null,
    organizationGroupSaveOrUpdateError: null,
    organizationGroupSaveOrUpdateLoading: false,
    organizationGroupSaveOrUpdateOk: true
  };
};

const saveOrUpdateOrganizationGroupFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, organizationGroupSaveOrUpdateLoading: false, organizationGroupSaveOrUpdateError: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const saveOrUpdateOrganizationGroupClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, organizationGroupSaveOrUpdateLoading: false, organizationGroupSaveOrUpdateError: null, organizationGroupSaveOrUpdateOk: false }
}


const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.ORGANIZATION_GROUP_FIND_START:
      return findOrganizationGroupsStart(state, action);
    case EActionTypes.ORGANIZATION_GROUP_FIND_SUCCESS:
      return findOrganizationGroupsSuccess(state, action);
    case EActionTypes.ORGANIZATION_GROUP_FIND_FAIL:
      return findOrganizationGroupsFail(state, action);
    case EActionTypes.ORGANIZATION_GROUP_GET_START:
      return getOrganizationGroupStart(state, action);
    case EActionTypes.ORGANIZATION_GROUP_GET_SUCCESS:
      return getOrganizationGroupSuccess(state, action);
    case EActionTypes.ORGANIZATION_GROUP_GET_FAIL:
      return getOrganizationGroupFail(state, action);
    case EActionTypes.ORGANIZATION_GROUP_GET_CLEAR:
      return getOrganizationGroupClear(state, action);
    case EActionTypes.ORGANIZATION_GROUP_DELETE_START:
      return deleteOrganizationGroupStart(state, action);
    case EActionTypes.ORGANIZATION_GROUP_DELETE_SUCCESS:
      return deleteOrganizationGroupSuccess(state, action);
    case EActionTypes.ORGANIZATION_GROUP_DELETE_FAIL:
      return deleteOrganizationGroupFail(state, action);
    case EActionTypes.ORGANIZATION_GROUP_DELETE_CLEAR:
      return deleteOrganizationGroupClear(state, action);
    case EActionTypes.ORGANIZATION_GROUP_SAVE_OR_UPDATE_START:
      return saveOrUpdateOrganizationGroupStart(state, action);
    case EActionTypes.ORGANIZATION_GROUP_SAVE_OR_UPDATE_SUCCESS:
      return saveOrUpdateOrganizationGroupSuccess(state, action);
    case EActionTypes.ORGANIZATION_GROUP_SAVE_OR_UPDATE_FAIL:
      return saveOrUpdateOrganizationGroupFail(state, action);
    case EActionTypes.ORGANIZATION_GROUP_SAVE_OR_UPDATE_CLEAR:
      return saveOrUpdateOrganizationGroupClear(state, action)
    default:
      return state;
  }
};

export default reducer;
