import { Message } from '@twilio/conversations';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { User } from '../../../classes/User';
import IAppState from '../../../interfaces/store/IAppState';
import IAuthState from '../../../interfaces/store/IAuthState';
import Spinner from '../../ui/Spinner/Spinner';
import TwilioChatMessage from './TwilioChatMessage/TwilioChatMessage';

import classes from './TwilioChatMessages.module.scss';


interface IProps {  
  messages: Message[];
  messagesLoading: boolean;
}

const TwilioChatMessages: React.FC<IProps> = ({ messages, messagesLoading }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { user } = useSelector<IAppState, IAuthState>(state => state.auth);

  useEffect(() => {
    const containerEl = containerRef.current;
    if (containerEl) {
      containerEl.scrollTop = containerEl.scrollHeight;
    }
  }, [messages]);

  return (
    <div className={classes.Container} ref={containerRef}>
      {messages.map((message, i) => <TwilioChatMessage key={message.sid} message={message} authUser={user as User} />)}

      {messagesLoading && <Spinner style={{ textAlign: 'center', margin: '1rem 0' }} /> }
    </div>
  )
}

export default TwilioChatMessages;