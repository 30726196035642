import { IOptionable } from "../utils/option-utils";
import { ICategorySettings } from "./ICategorySettings";
import { ITranslation } from "./ITranslation";

export enum ECategoryType {
	INTERPRETATION_SUBTYPE = "INTERPRETATION_SUBTYPE",
	INTERPRETATION_SEARCH = "INTERPRETATION_SEARCH",
	PROJECT = 'PROJECT',
}

export interface ICategory extends IOptionable {
	id: string;
	name: string;
	type: ECategoryType;
	translations: ITranslation[];
	settings?: ICategorySettings;
}
