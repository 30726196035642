import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import TwilioChat from "../../components/TwilioChat/TwilioChat";
import Spinner from "../../components/ui/Spinner/Spinner";
import IAppState from "../../interfaces/store/IAppState";
import ITwilioState from "../../interfaces/store/ITwilioState";

import * as actions from "../../store/actions";

interface IMatch {
  id: string;
}

const TwilioChatPage: React.FC = () => {
  const dispatch = useDispatch();

  const match = useRouteMatch<IMatch>()

  const { conversationToken } = useSelector<IAppState, ITwilioState>(
    (state) => state.twilio
  );

  useEffect(() => {
    dispatch(actions.getConversationToken());
  }, [dispatch]);

  return (
    <section>
      {conversationToken ? (
        <TwilioChat conversationToken={conversationToken} conversationSid={match.params.id} />
      ) : (
        <Spinner />
      )}
    </section>
  );
};

export default TwilioChatPage;
