import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute } from '../../../classes/Routes';
import NewsItem from '../../../components/NewsItem/NewsItem';
import Alert, { EAlertColor } from '../../../components/ui/Alert/Alert';
import Container from '../../../components/ui/Container/Container';
import EditButtons from '../../../components/ui/EditButtons/EditButtons';
import Spinner from '../../../components/ui/Spinner/Spinner';
import { INewsItem } from '../../../interfaces/INewsItem';
import IAppState from '../../../interfaces/store/IAppState';
import * as actions from '../../../store/actions';
import Heading from '../../../components/Heading/Heading';
import { User } from '../../../classes/User';
import classes from './NewsItemEditPage.module.scss';
import { useCustomDropzoneUploading } from '../../../hooks/useCustomDropzoneUploading';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const NewsItemEditPage: React.FC<IProps> = ({ match, history }) => {
  const [isUploading, setUploading] = useCustomDropzoneUploading();
  const [editNewsItem, setEditNewsItem] = useState<INewsItem | null>(
    null
  );
  const [isValid, setIsValid] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const dispatch = useDispatch();

  const { id } = match.params;
  const isAdd = id === "add";

  const { t } = useTranslation();

  useEffect(() => {
    if (!isAdd) {
      dispatch(actions.getNewsItem(id));
    }
  }, [isAdd, id, dispatch]);

  const { error, loading, saveOrUpdateOk, newsItem, deleteError, deleteLoading, deleteOk, user } = useSelector(
    (state: IAppState) => ({
      error: state.newsItem.newsItemError || state.newsItem.newsItemSaveOrUpdateError,
      loading: state.newsItem.newsItemLoading || state.newsItem.newsItemSaveOrUpdateLoading,
      saveOrUpdateOk: state.newsItem.newsItemSaveOrUpdateOk,
      newsItem: state.newsItem.newsItem,
      deleteLoading: state.newsItem.newsItemDeleteLoading,
      deleteError: state.newsItem.newsItemDeleteError,
      deleteOk: state.newsItem.newsItemDeleteOk,
      user: state.auth.user
    })
  );


  useEffect(() => {
    if (saveOrUpdateOk) {
      history.push(ERoute.NEWS_ITEMS_LIST);
    }
    return () => {
      dispatch(actions.saveOrUpdateNewsItemClear());
      dispatch(actions.getNewsItemClear());
      dispatch(actions.deleteNewsItemClear());
    };
  }, [dispatch, saveOrUpdateOk, history]);

  const updateHandler = useCallback(
    (newsItem: INewsItem, isValid: boolean) => {
      setEditNewsItem(newsItem);
      setIsValid(isValid);
    },
    []
  );

  const submitHandler = () => {
    if (!editNewsItem) return;

    if (isAdd) {
      dispatch(actions.saveNewsItem(editNewsItem));
    } else {
      editNewsItem.id = id;
      dispatch(actions.updateNewsItem(editNewsItem));
    }
  };

  const deleteHandler = () => {
    if(newsItem) {
      dispatch(actions.deleteNewsItem(newsItem.id));
    }
  }

  return (
    <Container>
      {error && <Alert>{error}</Alert>}
      {loading && <Spinner centerAbsolute />}
      <Heading>{t(ETranslation.MENU_NEWS)}</Heading>
      <Alert color={EAlertColor.INFO}>
        <div className={classes.Info}>
          <p>{t(ETranslation.PAGES_NEWS_INSTRUCTIONS_TITLE)}</p>
          <p>{t(ETranslation.PAGES_NEWS_INSTRUCTIONS_CONTENT_1)}</p>
          <p>{t(ETranslation.PAGES_NEWS_INSTRUCTIONS_CONTENT_2)}</p>
        </div>
      </Alert>
      <NewsItem
        onChange={updateHandler}
        loading={loading}
        newsItem={newsItem}
        showValidation={showValidation}
        user={user as User}
        setUploading={setUploading}
      />
      <EditButtons
        onSave={isValid ? submitHandler : () => setShowValidation(true)}
        disabled={(showValidation && !isValid) || isUploading}
        loading={loading}
        onCancel={() => history.goBack()}
        onDelete={deleteHandler}
        deleteText={ETranslation.PAGES_NEWS_DELETE_CONFIRM}
        onDeleteClose={() => dispatch(actions.deleteTextMessageClear())}
        onDeleteDone={() => history.push(ERoute.NEWS_ITEMS_LIST) }
        deleteError={deleteError}
        deleteLoading={deleteLoading}
        deleteOk={deleteOk}
        isAdd={isAdd}
      />
    </Container>
  );
};

export default NewsItemEditPage;
