import { IUserContractSettings } from '../../interfaces/IUserContractSettings';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IUserContractAction from '../../interfaces/store/IUserContractAction';
import IUserContractState from '../../interfaces/store/IUserContractState';
import { ETranslation } from '../../translations/translation-keys';
import { removeOne } from '../../utils/reducer-utils';
import { insertFirst, updateOne } from '../../utils/reducer-utils';

type ReducerAction = IUserContractAction;
type ReducerState = IUserContractState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  setting: null,

  settings: null,
  loading: false,
  error: null,
  
  deletingError: null,
  deleting: false,
  deletingOk: false,

  saveOrUpdateOk: false
};

const findUserContractSettingsStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const findUserContractSettingsSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    settings: action.settings || null,
    error: null,
    loading: false,
  };
};

const findUserContractSettingsFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, settings: null, loading: false, error: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getUserContractSettingsStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const getUserContractSettingsSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    setting: action.setting || null,
    error: null,
    loading: false,
  };
};

const getUserContractSettingsFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, setting: null, loading: false, error: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getUserContractSettingsClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, setting: null, loading: false, error: null};
};

const deleteUserContractSettingsStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deletingError: null, deleting: true, deletingOk: false, };
};

const deleteUserContractSettingsSuccess: ReducerSignature = (state, action): ReducerState => {
  const settings = removeOne<IUserContractSettings>(state.settings, action.id);
  return {
    ...state,
    settings,
    deletingError: null,
    deleting: false,
    deletingOk: true,
  };
};

const deleteUserContractSettingsFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deleting: false, deletingError: action.error as string, deletingOk: false, };
};

const deleteUserContractSettingsClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deletingError: null, deleting: false, deletingOk: false, };
};


const saveUserContractSettingsStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const saveUserContractSettingsSuccess: ReducerSignature = (state, action): ReducerState => {
  const settings = insertFirst(state.settings, action.setting)
  return {
    ...state,
    settings,
    error: null,
    loading: false,
    saveOrUpdateOk: true
  };
};

const saveUserContractSettingsFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const updateUserContractSettingsStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const updateUserContractSettingsSuccess: ReducerSignature = (state, action): ReducerState => {
  const settings = updateOne(state.settings, action.setting);
  return {
    ...state,
    error: null,
    loading: false,
    saveOrUpdateOk: true,
    settings
  };
};

const updateUserContractSettingsFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const saveOrUpdateUserContractSettingsClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: null, saveOrUpdateOk: false }
}

const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.USER_CONTRACT_SETTING_FIND_START:
      return findUserContractSettingsStart(state, action);
    case EActionTypes.USER_CONTRACT_SETTING_FIND_SUCCESS:
      return findUserContractSettingsSuccess(state, action);
    case EActionTypes.USER_CONTRACT_SETTING_FIND_FAIL:
      return findUserContractSettingsFail(state, action);
    case EActionTypes.USER_CONTRACT_SETTING_GET_START:
      return getUserContractSettingsStart(state, action);
    case EActionTypes.USER_CONTRACT_SETTING_GET_SUCCESS:
      return getUserContractSettingsSuccess(state, action);
    case EActionTypes.USER_CONTRACT_SETTING_GET_FAIL:
      return getUserContractSettingsFail(state, action);
    case EActionTypes.USER_CONTRACT_SETTING_GET_CLEAR:
      return getUserContractSettingsClear(state, action);
    case EActionTypes.USER_CONTRACT_SETTING_DELETE_START:
      return deleteUserContractSettingsStart(state, action);
    case EActionTypes.USER_CONTRACT_SETTING_DELETE_SUCCESS:
      return deleteUserContractSettingsSuccess(state, action);
    case EActionTypes.USER_CONTRACT_SETTING_DELETE_FAIL:
      return deleteUserContractSettingsFail(state, action);
    case EActionTypes.USER_CONTRACT_SETTING_DELETE_CLEAR:
      return deleteUserContractSettingsClear(state, action);
    case EActionTypes.USER_CONTRACT_SETTING_SAVE_START:
      return saveUserContractSettingsStart(state, action);
    case EActionTypes.USER_CONTRACT_SETTING_SAVE_SUCCESS:
      return saveUserContractSettingsSuccess(state, action);
    case EActionTypes.USER_CONTRACT_SETTING_SAVE_FAIL:
      return saveUserContractSettingsFail(state, action);
    case EActionTypes.USER_CONTRACT_SETTING_UPDATE_START:
      return updateUserContractSettingsStart(state, action);
    case EActionTypes.USER_CONTRACT_SETTING_UPDATE_SUCCESS:
      return updateUserContractSettingsSuccess(state, action);
    case EActionTypes.USER_CONTRACT_SETTING_UPDATE_FAIL:
      return updateUserContractSettingsFail(state, action);
    case EActionTypes.USER_CONTRACT_SETTING_SAVE_OR_UPDATE_CLEAR:
      return saveOrUpdateUserContractSettingsClear(state, action)
    default:
      return state;
  }
};

export default reducer;
