import EActionTypes from '../../interfaces/store/EActionTypes';
import ITextMessageAction from '../../interfaces/store/ITextMessageAction';
import ITextMessageState from '../../interfaces/store/ITextMessageState';
import { ETranslation } from '../../translations/translation-keys';

type ReducerAction = ITextMessageAction;
type ReducerState = ITextMessageState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  textMessage: null,
  textMessageLoading: false,
  textMessageError: null,

  textMessages: null,
  textMessagesLoading: false,
  textMessagesError: null,

  textMessageSendOk: false,

  textMessageSaveOrUpdateOk: false,
  textMessageSaveOrUpdateError: null,
  textMessageSaveOrUpdateLoading: false,

  textMessageDeleteError: null,
  textMessageDeleteLoading: false,
  textMessageDeleteOk: false,

};

const findTextMessagesStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, textMessagesError: null, textMessagesLoading: true };
};

const findTextMessagesSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    textMessages: action.textMessages || null,
    textMessagesError: null,
    textMessagesLoading: false,
  };
};

const findTextMessagesFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, textMessages: null, textMessagesLoading: false, textMessagesError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getTextMessageStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, textMessageError: null, textMessageLoading: true };
};

const getTextMessageSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    textMessage: action.textMessage || null,
    textMessageError: null,
    textMessageLoading: false,
  };
};

const getTextMessageFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, textMessage: null, textMessageLoading: false, textMessageError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};

const getTextMessageClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, textMessage: null, textMessageLoading: false, textMessageError: null};
};

const deleteTextMessageStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, textMessageDeleteError: null, textMessageDeleteLoading: true, textMessageDeleteOk: false };
};

const deleteTextMessageSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    textMessageDeleteError: null,
    textMessageDeleteLoading: false,
    textMessageDeleteOk: true
  };
};

const deleteTextMessageFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, textMessageDeleteLoading: false, textMessageDeleteError: action.error as string, textMessageDeleteOk: false };
};

const deleteTextMessageClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, textMessageDeleteError: null, textMessageDeleteLoading: false, textMessageDeleteOk: false };
};

const sendTextMessageStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, textMessageError: null, textMessageLoading: true, textMessageSendOk: false };
};

const sendTextMessageSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    textMessage: action.textMessage || null,
    textMessageError: null,
    textMessageLoading: false,
    textMessageSendOk: true
  };
};

const sendTextMessageFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, textMessage: null, textMessageLoading: false, textMessageError: action.error || ETranslation.REDUCER_SEARCH_ERROR, textMessageSendOk: false };
};

const sendTextMessageClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, textMessage: null, textMessageLoading: false, textMessageError: null, textMessageSendOk: false};
};

const saveOrUpdateTextMessageStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, textMessageSaveOrUpdateError: null, textMessageSaveOrUpdateLoading: true };
};

const saveOrUpdateTextMessageSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    textMessages: null,
    textMessageSaveOrUpdateError: null,
    textMessageSaveOrUpdateLoading: false,
    textMessageSaveOrUpdateOk: true
  };
};

const saveOrUpdateTextMessageFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, textMessageSaveOrUpdateLoading: false, textMessageSaveOrUpdateError: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const saveOrUpdateTextMessageClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, textMessageSaveOrUpdateLoading: false, textMessageSaveOrUpdateError: null, textMessageSaveOrUpdateOk: false }
}


const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.TEXT_MESSAGE_FIND_START:
      return findTextMessagesStart(state, action);
    case EActionTypes.TEXT_MESSAGE_FIND_SUCCESS:
      return findTextMessagesSuccess(state, action);
    case EActionTypes.TEXT_MESSAGE_FIND_FAIL:
      return findTextMessagesFail(state, action);
    case EActionTypes.TEXT_MESSAGE_GET_START:
      return getTextMessageStart(state, action);
    case EActionTypes.TEXT_MESSAGE_GET_SUCCESS:
      return getTextMessageSuccess(state, action);
    case EActionTypes.TEXT_MESSAGE_GET_FAIL:
      return getTextMessageFail(state, action);
    case EActionTypes.TEXT_MESSAGE_GET_CLEAR:
      return getTextMessageClear(state, action);
    case EActionTypes.TEXT_MESSAGE_DELETE_START:
      return deleteTextMessageStart(state, action);
    case EActionTypes.TEXT_MESSAGE_DELETE_SUCCESS:
      return deleteTextMessageSuccess(state, action);
    case EActionTypes.TEXT_MESSAGE_DELETE_FAIL:
      return deleteTextMessageFail(state, action);
    case EActionTypes.TEXT_MESSAGE_DELETE_CLEAR:
      return deleteTextMessageClear(state, action);
    case EActionTypes.TEXT_MESSAGE_SEND_START:
      return sendTextMessageStart(state, action);
    case EActionTypes.TEXT_MESSAGE_SEND_SUCCESS:
      return sendTextMessageSuccess(state, action);
    case EActionTypes.TEXT_MESSAGE_SEND_FAIL:
      return sendTextMessageFail(state, action);
    case EActionTypes.TEXT_MESSAGE_SEND_CLEAR:
      return sendTextMessageClear(state, action);
    case EActionTypes.TEXT_MESSAGE_SAVE_OR_UPDATE_START:
      return saveOrUpdateTextMessageStart(state, action);
    case EActionTypes.TEXT_MESSAGE_SAVE_OR_UPDATE_SUCCESS:
      return saveOrUpdateTextMessageSuccess(state, action);
    case EActionTypes.TEXT_MESSAGE_SAVE_OR_UPDATE_FAIL:
      return saveOrUpdateTextMessageFail(state, action);
    case EActionTypes.TEXT_MESSAGE_SAVE_OR_UPDATE_CLEAR:
      return saveOrUpdateTextMessageClear(state, action)
    default:
      return state;
  }
};

export default reducer;