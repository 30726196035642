export enum ENewsItemDisplayLocation {
  YOUPRET = 'YOUPRET',
  ALIPI = 'ALIPI',
  MASPA = 'MASPA'
}


export interface INewsItem {
    id: string;
    created: Date;
    updated: Date;
    title: string;
    rating: number;
    reviewName: string;
    languages?: string[];
    shortContent: string;
    contentText: string
    publishDate: string;
    publishDateDay: string;
    publishDateTime: string;
    published: boolean;
    displayLocation: ENewsItemDisplayLocation;
    displayLanguages?: string[];
	isReview: boolean;
  }