import EActionTypes from '../../interfaces/store/EActionTypes';
import IOrganizationAction from '../../interfaces/store/IOrganizationAction';
import IOrganizationState from '../../interfaces/store/IOrganizationState';
import { ETranslation } from '../../translations/translation-keys';

type ReducerAction = IOrganizationAction;
type ReducerState = IOrganizationState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
	organization: null,
	organizationLoading: false,
	organizationError: null,

	organizations: null,
	organizationsLoading: false,
	organizationsError: null,
	organizationsHasMore: false,

	organizationSaveOrUpdateLoading: false,
	organizationSaveOrUpdateError: null,
	organizationSaveOrUpdateOk: false,

	organizationDeleteError: null,
	organizationDeleteLoading: false,
	organizationDeleteOk: false,

	organizationNetvisorError: null,
	organizationNetvisorLoading: false,

	searchOrganizations: null,
	searchOrganizationsLoading: false,
	searchOrganizationsError: null
}


const findOrganizationsStart: ReducerSignature = (state): ReducerState => {
	return { ...state, organizationsError: null, organizationsLoading: true };
};

const findOrganizationsSuccess: ReducerSignature = (state, action): ReducerState => {
	const organizations = state.organizations ? action.organizations ? [...state.organizations, ...action.organizations] : null : action.organizations ? action.organizations : null;
	return {
		...state,
		organizations: organizations,
		organizationsError: null,
		organizationsLoading: false,
		organizationsHasMore: action.hasMore ?? false,
	};
};
const findOrganizationsClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, organizations: null, organizationsHasMore: false, organizationsLoading: false, organizationsError: null };
};

const findOrganizationsFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, organizations: null, organizationsHasMore: false, organizationsLoading: false, organizationsError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getOrganizationStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, organizationError: null, organizationLoading: true };
};

const getOrganizationSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		organization: action.organization || null,
		organizationError: null,
		organizationLoading: false,
	};
};

const getOrganizationFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, organization: null, organizationLoading: false, organizationError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};

const getOrganizationClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, organization: null, organizationLoading: false, organizationError: null };
};

const deleteOrganizationStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, organizationDeleteError: null, organizationDeleteLoading: true, organizationDeleteOk: false };
};

const deleteOrganizationSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		organizationDeleteError: null,
		organizationDeleteLoading: false,
		organizationDeleteOk: true
	};
};

const deleteOrganizationFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, organizationDeleteLoading: false, organizationDeleteError: action.error as string, organizationDeleteOk: false };
};

const deleteOrganizationClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, organizationDeleteError: null, organizationDeleteLoading: false, organizationDeleteOk: false };
};



const saveOrUpdateOrganizationStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, organizationSaveOrUpdateError: null, organizationSaveOrUpdateLoading: true };
};

const saveOrUpdateOrganizationSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		organizations: null,
		organizationSaveOrUpdateError: null,
		organizationSaveOrUpdateLoading: false,
		organizationSaveOrUpdateOk: true
	};
};

const saveOrUpdateOrganizationFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, organizationSaveOrUpdateLoading: false, organizationSaveOrUpdateError: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const saveOrUpdateOrganizationClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, organizationSaveOrUpdateLoading: false, organizationSaveOrUpdateError: null, organizationSaveOrUpdateOk: false }
}

// Search

const findSearchOrganizationsStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, searchOrganizationsError: null, searchOrganizationsLoading: true };
};

const findSearchOrganizationsSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		searchOrganizations: action.organizations || null,
		searchOrganizationsError: null,
		searchOrganizationsLoading: false,
	};
};

const findSearchOrganizationsFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, searchOrganizations: null, searchOrganizationsLoading: false, searchOrganizationsError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};

// Organizations to netvisor

const createNewOrganizationInNetvisorStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, organizationNetvisorError: null, organizationNetvisorLoading: true };
};

const createNewOrganizationInNetvisorSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, organizationNetvisorError: null, organizationNetvisorLoading: false };
};

const createNewOrganizationInNetvisorError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, organizationNetvisorError: action.error || ETranslation.REDUCER_SAVE_ERROR, organizationNetvisorLoading: false };
};

const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
	switch (action.type) {
		case EActionTypes.ORGANIZATION_FIND_START:
			return findOrganizationsStart(state, action);
		case EActionTypes.ORGANIZATION_FIND_SUCCESS:
			return findOrganizationsSuccess(state, action);
		case EActionTypes.ORGANIZATION_FIND_FAIL:
			return findOrganizationsFail(state, action);
		case EActionTypes.ORGANIZATION_FIND_CLEAR:
			return findOrganizationsClear(state, action);
		case EActionTypes.ORGANIZATION_GET_START:
			return getOrganizationStart(state, action);
		case EActionTypes.ORGANIZATION_GET_SUCCESS:
			return getOrganizationSuccess(state, action);
		case EActionTypes.ORGANIZATION_GET_FAIL:
			return getOrganizationFail(state, action);
		case EActionTypes.ORGANIZATION_GET_CLEAR:
			return getOrganizationClear(state, action);
		case EActionTypes.ORGANIZATION_DELETE_START:
			return deleteOrganizationStart(state, action);
		case EActionTypes.ORGANIZATION_DELETE_SUCCESS:
			return deleteOrganizationSuccess(state, action);
		case EActionTypes.ORGANIZATION_DELETE_FAIL:
			return deleteOrganizationFail(state, action);
		case EActionTypes.ORGANIZATION_DELETE_CLEAR:
			return deleteOrganizationClear(state, action);
		case EActionTypes.ORGANIZATION_SAVE_OR_UPDATE_START:
			return saveOrUpdateOrganizationStart(state, action);
		case EActionTypes.ORGANIZATION_SAVE_OR_UPDATE_SUCCESS:
			return saveOrUpdateOrganizationSuccess(state, action);
		case EActionTypes.ORGANIZATION_SAVE_OR_UPDATE_FAIL:
			return saveOrUpdateOrganizationFail(state, action);
		case EActionTypes.ORGANIZATION_SAVE_OR_UPDATE_CLEAR:
			return saveOrUpdateOrganizationClear(state, action);
		case EActionTypes.ORGANIZATION_SEARCH_START:
			return findSearchOrganizationsStart(state, action);
		case EActionTypes.ORGANIZATION_SEARCH_SUCCESS:
			return findSearchOrganizationsSuccess(state, action);
		case EActionTypes.ORGANIZATION_SEARCH_FAIL:
			return findSearchOrganizationsFail(state, action);
		case EActionTypes.ORGANIZATION_CREATE_IN_NETVISOR_START:
			return createNewOrganizationInNetvisorStart(state, action);
		case EActionTypes.ORGANIZATION_CREATE_IN_NETVISOR_SUCCESS:
			return createNewOrganizationInNetvisorSuccess(state, action);
		case EActionTypes.ORGANIZATION_CREATE_IN_NETVISOR_ERROR:
			return createNewOrganizationInNetvisorError(state, action);
		default:
			return state;
	}
}


export default reducer;