import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { EMessageContentType, EMessageType, IMessage } from '../../../classes/Message';
import { ERoute, ERouteStaticParams, Routes } from '../../../classes/Routes';
import CustomDropzoneContext from '../../../components/CustomDropzone/CustomDropzoneContext';
import Heading from '../../../components/Heading/Heading';
import Alert from '../../../components/ui/Alert/Alert';
import Container from '../../../components/ui/Container/Container';
import EditButtons from '../../../components/ui/EditButtons/EditButtons';
import { ECommonValue, EInputType, IInputField, IInputFieldItem } from '../../../components/ui/Input/Input';
import { getInputData, initForm, validateInputs } from '../../../components/ui/Input/input-utils';
import Spinner from '../../../components/ui/Spinner/Spinner';
import { useCreateInput } from '../../../hooks/useCreateInput';
import { useCustomDropzoneUploading } from '../../../hooks/useCustomDropzoneUploading';
import { useLanguages } from '../../../hooks/useLanguages';
import IAppState from '../../../interfaces/store/IAppState';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';

interface IMatch {
  id: string;
  messageGroupId: string;
  parentMessageId: string;
}

enum EInputs {
  language = "language",
  types = "types",
  contentType = "contentType",
  attachment = "attachment",
  textContentText = "textContentText",
  info = "info",
  allowShare = "allowShare"
}

enum ETypesInput {
  SOURCE, TARGET
}

const typesSourceInput = {
  type: EInputType.checkbox,
  labelTranslation: ETranslation.PAGES_MESSAGE_EDIT_CHOOSE_ORDERED_WORKS,
  value: "",
  options: [
    { labelTranslation: ETranslation.PAGES_MESSAGE_EDIT_RECORDING, value: EMessageType.INTERPRETATION_SOURCE },
    { labelTranslation: ETranslation.COMMON_TRANSLATION, value: EMessageType.TRANSLATION_SOURCE },
    // { labelTranslation: ETranslation.COMMON_TRANSLATION, value: EMessageType.ATTACHMENT_SOURCE },
  ],
  validation: {
    required: true,
  },
}

const typesTargetInput = {
  type: EInputType.radio,
  labelTranslation: ETranslation.PAGES_MESSAGE_EDIT_CHOOSE_RECORDING_TRANSLATION,
  value: "",
  options: [
    { labelTranslation: ETranslation.COMMON_RECORDING, value: EMessageType.INTERPRETATION_TARGET },
    { labelTranslation: ETranslation.COMMON_TRANSLATION, value: EMessageType.TRANSLATION_TARGET },
  ],
  validation: {
    required: true,
  },
}

interface IProps extends RouteComponentProps<IMatch> {}

const MessageEditPage: React.FC<IProps> = ({ match, history }) => {
  const [isValid, setIsValid] = useState(false);
  const [isUploading, setUploading] = useCustomDropzoneUploading();
  const [showValidation, setShowValidation] = useState(false);
  const { t } = useTranslation();
  const { languageOptions } = useLanguages();

  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.language]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.COMMON_LANGUAGE,
      value: "",
      placeholderTranslation: ETranslation.COMMON_CHOOSE_LANGUAGE
    },
    [EInputs.contentType]: {
      type: EInputType.radio,
      labelTranslation: ETranslation.PAGES_MESSAGE_EDIT_CHOOSE_SOURCE_TYPE,
      value: "",
      options: [
        { labelTranslation: ETranslation.COMMON_AUDIO, value: EMessageContentType.AUDIO },
        { labelTranslation: ETranslation.COMMON_TEXT, value: EMessageContentType.TEXT },
        { labelTranslation: ETranslation.COMMON_FILE, value: EMessageContentType.FILE },
      ],
      validation: {
        required: true,
        dependencies: [EInputs.attachment, EInputs.textContentText]
      },
    },
    [EInputs.attachment]: {
      type: EInputType.dropzone,
      labelTranslation: ETranslation.PAGES_MESSAGE_EDIT_ADD_FILE,
      value: [],
      validation: {
        requiredIf: [{ inputName: EInputs.contentType, value: [EMessageContentType.AUDIO, EMessageContentType.FILE] }]
      },
    },
    [EInputs.textContentText]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.COMMON_TEXT,
      placeholderTranslation: ETranslation.PAGES_MESSAGE_EDIT_ADD_TEXT,
      value: "",
      validation: {
        requiredIf: [{ inputName: EInputs.contentType, value: EMessageContentType.TEXT }]
      },
    },
    [EInputs.info]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.COMMON_ADDITIONAL_INFO,
      placeholderTranslation: ETranslation.PAGES_MESSAGE_EDIT_ADD_MORE_INFORMATION,
      value: "",
      validation: {
        required: false,
      },
    },
    [EInputs.allowShare]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.PAGES_MESSAGE_EDIT_ALLOW_SHARING,
      value: [ ECommonValue.YES ],
      options: [{ value: ECommonValue.YES }],
    },
  });

  const dispatch = useDispatch();

  const { id, messageGroupId, parentMessageId } = match.params;

  const isAdd = id === ERouteStaticParams.add;

  useEffect(() => {
    if (!isAdd) {
      dispatch(actions.getMessage(id));
    }
    return () => {
      dispatch(actions.messageSaveOrUpdateClear());
      dispatch(actions.getMessageClear());
    };
  }, [id, isAdd, dispatch]);

  useEffect(() => {
    setIsValid(validateInputs(inputs) && !isUploading);
  }, [inputs, isUploading])

  const { messageState } = useSelector(
    (state: IAppState) => ({
      messageState: state.message
    })
  );

  const { loading, error, saveOrUpdateMessage, message } = {
    loading: messageState.messageLoading || messageState.saveOrUpdateLoading,
    error: messageState.messageError || messageState.saveOrUpdateError,
    saveOrUpdateMessage: messageState.saveOrUpdateMessage,
    message: messageState.message
  };

  useEffect(() => {
    if(saveOrUpdateMessage) {
      const id = saveOrUpdateMessage.parentMessageGroupId;
      if (id) {
        history.push(Routes.withParams(ERoute.MESSAGE_GROUP_EDIT, { id }))
      }
    }

  }, [saveOrUpdateMessage, history]);


  const setTypesInput = useCallback((type: ETypesInput) => {
    setInputs(inputs => {
      let input: IInputFieldItem = typesSourceInput;
      if(type === ETypesInput.SOURCE) {
        input = {...typesSourceInput};
      } else if(type === ETypesInput.TARGET) {
        input = {...typesTargetInput}
      }   
      return { ...inputs, [EInputs.types]: input };
    });
  }, []);

  useEffect(() => {
    if(message) {
      setTypesInput(message.canAnswer() ? ETypesInput.SOURCE : ETypesInput.TARGET);
      initForm(setInputs, message);
    }
  }, [message, setTypesInput]);

  useEffect(() => {
    setTypesInput(messageGroupId ? ETypesInput.SOURCE : ETypesInput.TARGET);
  }, [messageGroupId, parentMessageId, setTypesInput]);


  const createInput = useCreateInput(inputs, setInputs, {
    showValidation
  });

  const saveOrUpdateHandler = () => {
    const data = getInputData<IMessage>(inputs);
    data.parentMessageGroupId = messageGroupId;
    data.parentMessageId = parentMessageId;
    switch(data.contentType) {
      case EMessageContentType.AUDIO:
      case EMessageContentType.FILE:
        delete data.textContentText;
        break;
      case EMessageContentType.TEXT:
        delete data.attachment;
        break;
    }
    // Radio value is not array so convert it to array because server is expecting types value to be array
    // data.types should be required value
    if(!Array.isArray(data.types)) {
      data.types = [data.types];
    }

    
    if(isAdd) {
      dispatch(actions.saveMessage(data));
    } else {
      data.id = id;
      dispatch(actions.updateMessage(data));
    }

  };

  const contentType = inputs[EInputs.contentType].value as string;

  return (
    <Container>
      {error && <Alert>{error}</Alert>}
      {loading && <Spinner centerAbsolute />}
      <>
        <Heading>{t(ETranslation.COMMON_MESSAGE)}</Heading>
        {createInput(EInputs.language, {
          options: languageOptions
        })}
        {createInput(EInputs.types)}
        {createInput(EInputs.contentType)}

        {(() => {
          switch (contentType) {
            case EMessageContentType.TEXT:
              return createInput(EInputs.textContentText);
            case EMessageContentType.FILE:
            case EMessageContentType.AUDIO:
              return (
                <CustomDropzoneContext.Provider value={{ setUploading }}>{createInput(EInputs.attachment)}</CustomDropzoneContext.Provider> 
              );
          }
        })()}
        {createInput(EInputs.info)}
        {createInput(EInputs.allowShare, { style: { display: "block" } })}

        <EditButtons
          disabled={showValidation && !isValid}
          onSave={!isValid ? () => setShowValidation(true) : saveOrUpdateHandler}
          isAdd={isAdd}
          onCancel={history.goBack}
        />
      </>
    </Container>
  );
};

export default MessageEditPage;
