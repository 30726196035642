import { useCallback } from 'react';
import { useState } from 'react';

import { IAttribute } from '../../interfaces/IAttribute';
import { isValuesAttribute } from '../../utils/attribute-utils';
import { useAttributeValuesModal } from './useAttributeValuesModal';


export const useAttributesAttach = () => {
	const [attributes, setAttributes] = useState<IAttribute[]>([]);

	const openAttributeValuesModal = useAttributeValuesModal();

	const addHandler = useCallback(async (attribute: IAttribute) => {
		if (isValuesAttribute(attribute)) {
			const values = await openAttributeValuesModal(attribute);
			if (!values) return;
			attribute.values = values;
		}
		setAttributes((attributes) => [...attributes, attribute]);
	}, [openAttributeValuesModal]);

	const deleteHandler = (id: string) => {
		setAttributes((attributes) => {
			const newAttributes = [...attributes];
			const index = newAttributes.findIndex(item => item.id === id);
			newAttributes.splice(index, 1);
			return newAttributes;
		});
	};

	const editHandler = async (attribute: IAttribute) => {
		const newAttr = {...attribute};
		const values = await openAttributeValuesModal(newAttr);
		if (!values) return;
		newAttr.values = values;
		setAttributes((attributes) => {
			const newAttrs = [...attributes];
			const index = newAttrs.findIndex(item => item.id === newAttr.id);
			newAttrs[index] = newAttr;
			return newAttrs;
		});
	};

	return {
		attributes,
		setAttributes,
		attributesDeleteHandler: deleteHandler,
		attributesEditHandler: editHandler,
		attributesAddHandler: addHandler,
	};
};
