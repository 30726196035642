import React from 'react';
import { useTranslation } from 'react-i18next';
import { EInfoContactRequestStatus } from '../../../interfaces/IInfoContactRequest';
import { getInfoContactRequestStatusString } from '../../../shared/info-contact-request-data';
import Badge, { EBadgeType } from '../../ui/Badge/Badge';


interface IProps {
  status: EInfoContactRequestStatus;
}

const InfoContactRequestStatus:React.FC<IProps> = ({ status }) => {
  
  const { t } = useTranslation();
  const text = getInfoContactRequestStatusString(t, status);

  switch (status) {
    case EInfoContactRequestStatus.NEW:
      return <Badge type={EBadgeType.DANGER}>{text}</Badge>
    case EInfoContactRequestStatus.IN_PROGRESS:
      return <Badge type={EBadgeType.WARNING}>{text}</Badge>
    case EInfoContactRequestStatus.COMPLETED:
      return <Badge type={EBadgeType.SUCCESS}>{text}</Badge>;
    case EInfoContactRequestStatus.CANCELED:
        return <Badge type={EBadgeType.DEFAULT}>{text}</Badge>;
    default:
      return null;
  }
}


export default InfoContactRequestStatus;