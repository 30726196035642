import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import IAppState from "../../../interfaces/store/IAppState";
import { RouteComponentProps } from "react-router-dom";
import { InterpretationContext } from "../OrderInterpretation/order-interpretation-context";
import Spinner from "../../../components/ui/Spinner/Spinner";
import InterpretationProcessing from "../../../components/Interpretation/InterpretationProcessing/InterpretationProcessing";
import ContainerRow from "../../../components/ui/Container/ContainerRow/ContainerRow";
import ContainerCol from "../../../components/ui/Container/ContainerCol/ContainerCol";
import HeadingBox from "../../../components/Heading/HeadingBox/HeadingBox";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const InterpretationProcessingPage: React.FC<IProps> = ({ match, history }) => {
  const { interpretation } = useSelector((state: IAppState) => ({
    interpretation: state.interpretation.interpretation,
  }));

  const { id } = match.params;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  useEffect(() => {
    if(id) {
      dispatch(actions.getInterpretation(id));
    }
  }, [dispatch, id]);

  if (!interpretation) {
    return <Spinner />
  }

  const updateHandler = () => {
    console.log("Items")
  }

  return (
    <ContainerRow>
      <ContainerCol>
        <HeadingBox>{t(ETranslation.INTERPRETATION_PROCESSING_TITLE)}</HeadingBox>
        <InterpretationContext.Provider
          value={{
            onChange: updateHandler,
            onClear: updateHandler,
            interpretation
          }}
        >
          <InterpretationProcessing/>
        </InterpretationContext.Provider>
      </ContainerCol>
    </ContainerRow>
  );
};

export default InterpretationProcessingPage;
