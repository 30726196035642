import { TFunction } from "i18next";
import { IOption } from "../components/ui/Input/Input";
import { ETranslation } from "../translations/translation-keys";

export enum EEezyTrusterPaymentStatus {
	NO_EVENT = "NO_EVENT",
	READY_TO_SEND = "READY_TO_SEND",
	SENDING = "SENDING",
	SENT = "SENT",
	FAILED = "FAILED",
	FAILED_VALIDATION = "FAILED_VALIDATION",
};

export const EEZYTRUSTER_PAYMENT_STATUS: IOption[] = [
	{ value: EEezyTrusterPaymentStatus.NO_EVENT, labelTranslation: ETranslation.EEZY_TRUSTER_STATUS_NO_EVENT },
	{ value: EEezyTrusterPaymentStatus.READY_TO_SEND, labelTranslation: ETranslation.EEZY_TRUSTER_STATUS_READY_TO_SEND },
	{ value: EEezyTrusterPaymentStatus.SENDING, labelTranslation: ETranslation.EEZY_TRUSTER_STATUS_SENDING },
	{ value: EEezyTrusterPaymentStatus.SENT, labelTranslation: ETranslation.EEZY_TRUSTER_STATUS_SENT },
	{ value: EEezyTrusterPaymentStatus.FAILED, labelTranslation: ETranslation.EEZY_TRUSTER_STATUS_FAILED },
	{ value: EEezyTrusterPaymentStatus.FAILED_VALIDATION, labelTranslation: ETranslation.EEZY_TRUSTER_STATUS_FAILED_VALIDATION },
];

export const getEezyTrusterPaymentStatusString = (
	t: TFunction, status: EEezyTrusterPaymentStatus
): string => t(EEZYTRUSTER_PAYMENT_STATUS.find(item => item.value === status)?.labelTranslation || "");

