import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute } from '../../classes/Routes';
import Heading from '../../components/Heading/Heading';
import InfoContactRequest from '../../components/InfoContactRequest/InfoContactRequest';
import Alert from '../../components/ui/Alert/Alert';
import Container from '../../components/ui/Container/Container';
import EditButtons from '../../components/ui/EditButtons/EditButtons';
import Spinner from '../../components/ui/Spinner/Spinner';
import { IInfoContactRequest } from '../../interfaces/IInfoContactRequest';
import IAppState from '../../interfaces/store/IAppState';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const InfoContactRequestEditPage: React.FC<IProps> = ({ history, match }) => {
  const [showValidation, setShowValidation] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [
    editInfoContactRequest,
    setEditInfoContactRequest,
  ] = useState<IInfoContactRequest | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id } = match.params;
  const isAdd = id === "add";

  useEffect(() => {
    if (!isAdd) {
      dispatch(actions.getInfoContactRequest(id));
    }
  }, [isAdd, id, dispatch]);

  const { error, loading, saveOrUpdateOk, infoContactRequest, deleteError, deleteLoading, deleteOk } = useSelector(
    (state: IAppState) => ({
      error: state.infoContactRequest.infoContactRequestError || state.infoContactRequest.infoContactRequestSaveOrUpdateError,
      loading: state.infoContactRequest.infoContactRequestLoading || state.infoContactRequest.infoContactRequestSaveOrUpdateLoading,
      saveOrUpdateOk: state.infoContactRequest.infoContactRequestSaveOrUpdateOk,
      infoContactRequest: state.infoContactRequest.infoContactRequest,
      deleteError: state.infoContactRequest.infoContactRequestDeleteError,
      deleteLoading: state.infoContactRequest.infoContactRequestDeleteLoading,
      deleteOk: state.infoContactRequest.infoContactRequestDeleteOk
    })
  );

  useEffect(() => {
    if (saveOrUpdateOk) {
      history.push(ERoute.INFO_CONTACT_REQUESTS_LIST);
    }
    return () => {
      dispatch(actions.saveOrUpdateInfoContactRequestClear());
      dispatch(actions.getInfoContactRequestClear());
    };
  }, [dispatch, saveOrUpdateOk, history]);

  const updateHandler = useCallback(
    (infoContactRequest: IInfoContactRequest, isValid: boolean) => {
      setEditInfoContactRequest(infoContactRequest);
      setIsValid(isValid);
    },
    []
  );

  const submitHandler = () => {
    if (editInfoContactRequest) {
      if (isAdd) {
        dispatch(actions.saveInfoContactRequest(editInfoContactRequest));
      } else {
        editInfoContactRequest.id = id;
        dispatch(actions.updateInfoContactRequest(editInfoContactRequest));
      }
    }
  };

  const deleteHandler = () => {
    if(infoContactRequest) {
      dispatch(actions.deleteInfoContactRequest(infoContactRequest.id));
    }
  }

  return (
    <Container>
      {error && <Alert>{error}</Alert>}
      {loading && <Spinner centerAbsolute />}
      <Heading>{t(ETranslation.PAGES_CONTACTREQUEST_EDIT_TITLE)}</Heading>
      <InfoContactRequest
        onChange={updateHandler}
        infoContactRequest={infoContactRequest}
        loading={loading}
        showValidation={showValidation}
      />
      {error && <Alert>{error}</Alert>}
      <EditButtons
        onSave={isValid ? submitHandler : () => setShowValidation(true)}
        disabled={showValidation && !isValid}
        loading={loading}
        onCancel={() => history.goBack()}
        onDelete={deleteHandler}
        deleteText={t(ETranslation.PAGES_CONTACTREQUEST_EDIT_DELETE)}
        onDeleteClose={() => dispatch(actions.deleteInfoContactRequestClear())}
        onDeleteDone={() => history.push(ERoute.INFO_CONTACT_REQUESTS_LIST) }
        deleteError={deleteError}
        deleteLoading={deleteLoading}
        deleteOk={deleteOk}
        isAdd={isAdd}
      />
    </Container>
  );
};

export default InfoContactRequestEditPage;
