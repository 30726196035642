import {
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IInterpretation, IInterpretationIndexItem } from "../../../../interfaces/IInterpretation";
import { ETranslation } from "../../../../translations/translation-keys";
import Button, { EButtonColor } from "../../Button/Button";
import classes from "./InputDisplayBox.module.scss";

interface IProps {
  children: ReactNode;
  interpretation: IInterpretation;
  index: number;
  alternativeIndex: number;
  setCurrentIndex: (index: number, altIndex: number) => void;
  currentIndex: number;
  currentAlternativeIndex: number;
  deleteInterpretation: (index: number) => void;
  deleteAlternativeInterpretation?: (index: number, indexAlternative: number) => void;
  showValidation: boolean;
  isAlternative: boolean;
  invalidIndexItems?: IInterpretationIndexItem[];
  setAlternativeOnly: (value: boolean) => void;
  showAlternativeOnly: boolean;
}

const InputDisplayBox: React.FC<IProps> = ({
  children,
  index,
  alternativeIndex,
  setCurrentIndex,
  currentIndex,
  currentAlternativeIndex,
  invalidIndexItems,
  showValidation,
  isAlternative,
  deleteInterpretation,
  deleteAlternativeInterpretation,
  setAlternativeOnly,
  showAlternativeOnly
}) => {
  const [showExtended, setShowExtended] = useState(false);
  const classNames = [classes.Container];
  const classNamesContainerSmall = [classes.ContainerSmall];
  const arrowClasses = [classes.Arrow];
  const delButtonClasses = [classes.DelButtonAlternative];
  const boxContainerClasses = [classes.Box];
  const { t } = useTranslation();

  useEffect(() => {
    if(!isAlternative && index === currentIndex && !showAlternativeOnly) {
      setShowExtended(true)
    }else if (!isAlternative) {
      setShowExtended(false)
    }
  }, [currentIndex, index, isAlternative, showAlternativeOnly])
  
  useEffect(() => {
    if(isAlternative && (alternativeIndex === currentAlternativeIndex) && (index === currentIndex) && showAlternativeOnly ) {
      setShowExtended(true)
    }else if (isAlternative) {
      setShowExtended(false)
    }
  }, [alternativeIndex, currentAlternativeIndex, currentIndex, index, isAlternative, showAlternativeOnly])
  

  if (showExtended) {
    classNames.push(classes.OpenContainer)
  } else {
    classNames.push(classes.CloseContainer)
  }

  // useEffect(() => {
  //   if(!invalidIndexItems) return;
    if(isAlternative && invalidIndexItems && showValidation) {
      invalidIndexItems.forEach(element => {
        if(element.index === index) {
          if(!element.invalidIndexed) return;
          if(element.invalidIndexed.includes(alternativeIndex)) {
            classNamesContainerSmall.push(classes.Validation)
          }
        }
      });
    }else if(invalidIndexItems && showValidation){
      invalidIndexItems.forEach(element => {
        if(element.isValid === false && element.index === index) {
          classNamesContainerSmall.push(classes.Validation)
        }
      });
    }

  if(isAlternative) {
    arrowClasses.push(classes.ArrowAlternative)
    classNames.push(classes.InputsContainerAlternative);
    classNamesContainerSmall.push(classes.AlternativeContainer);
    boxContainerClasses.push(classes.BoxAlternative);
  }else {
    delButtonClasses.push(classes.DelButton)
  }

  if(index > 0 || alternativeIndex > -1) {
    
  }else {
    delButtonClasses.push(classes.DelButtonHidden)
  }

  const closeOrOpen = () => {
    if (showExtended) {
      setShowExtended(false);
      classNames.push(classes.CloseContainer)
    } else {
      if(isAlternative) {
        setCurrentIndex(index, alternativeIndex ? alternativeIndex : 0);
        setAlternativeOnly(true)
      } else if (!isAlternative) {
        setCurrentIndex(index, alternativeIndex ? alternativeIndex : 0);
        setAlternativeOnly(false)
      }
      setShowExtended(true);
      classNames.push(classes.OpenContainer)
    }
  };



  return (
    <div className={classes.Container}>
      <div className={classNamesContainerSmall.join(" ")}>
        <div className={arrowClasses.join(" ")} onClick={closeOrOpen}>
          {showExtended ? (
            <FontAwesomeIcon icon={faAngleUp} />
          ) : (
            <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon>
          )}
        </div>
        <div onClick={closeOrOpen} className={boxContainerClasses.join(" ")}>{ isAlternative ? (alternativeIndex !== null && alternativeIndex !== undefined &&  "Vaihtoehto " + (alternativeIndex + 1)) : "Tulkkaus " + (index + 1)}</div>
        {/* <div className={classes.Box}>{index + 1}. Tulkkaus</div> */}
          <Button
          className={delButtonClasses.join(" ")}
          color={EButtonColor.WHITE}
          onClick={() => 
            isAlternative && deleteAlternativeInterpretation ? deleteAlternativeInterpretation(index, alternativeIndex ? alternativeIndex : 0) : 
            deleteInterpretation(index)}
        >
          {t(ETranslation.COMMON_DELETE)}
        </Button>
      </div>
      <div className={classNames.join(" ")}>{children}</div>
    </div>
  );
};

export default InputDisplayBox;
