import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useCreateInput } from '../../hooks/useCreateInput';
import { searchUsers } from '../../services/userServices';
import EditButtons from '../ui/EditButtons/EditButtons';
import { EInputType, IInputField } from '../ui/Input/Input';
import { getInputData, validateInputs } from '../ui/Input/input-utils';
import InputGroup from '../ui/InputGroup/InputGroup';
import ModalBody from '../ui/Modal/ModalBody/ModalBody';
import ModalContext, { EModalSize } from '../ui/Modal/ModalContext';
import ModalFooter from '../ui/Modal/ModalFooter/ModalFooter';

interface IProps {
	onAccept: (result: IWorkingHoursAddModalResult) => void;
}

enum EInputs {
	userId = "userId",
	date = "date",
}

export interface IWorkingHoursAddModalResult {
	date: string;
	userId: string;
}

export const useWorkingHoursAddModal = () => {
	const { setModal, closeModal } = useContext(ModalContext);

	const handler = useCallback(
		async () => {
			return new Promise<IWorkingHoursAddModalResult>((resolve) => {
				setModal({
					isOpen: true,
					size: EModalSize.MEDIUM,
					title: "Lisää / Muokkaa",
					content: (
						<WorkingHoursAddModal
							onAccept={(result) => {
								resolve(result);
								closeModal();
							}}
						/>
					),
				});
			});
		},
		[setModal, closeModal]
	);

	return handler;
};

const WorkingHoursAddModal: React.FC<IProps> = ({ onAccept }) => {
	const [isValid, setIsValid] = useState(false);
	const { closeModal } = useContext(ModalContext);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.date]: {
			type: EInputType.date,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.userId]: {
			type: EInputType.reactSelectSearch,
			options: [],
			value: "",
			validation: {
				required: true,
			},
		},
	});

	const saveHandler = () => {
		const inputData = getInputData<IWorkingHoursAddModalResult>(inputs);
		onAccept(inputData);
	};

	useEffect(() => {
		setIsValid(validateInputs(inputs));

	}, [inputs]);

	const createInput = useCreateInput(inputs, setInputs, { showLabel: false });

	return (
		<>
			<ModalBody>
				<p style={{ marginTop: 0 }}>Valitse päivä ja käyttäjä. Voit lisätä / muokata käyttäjän työaikaa kyseiselle päivälle.</p>
				<InputGroup>
					{createInput(EInputs.date)}
					{createInput(EInputs.userId, {
						fetchOptions: searchUsers,
					})}
				</InputGroup>
			</ModalBody>
			<ModalFooter>
				<EditButtons disabled={!isValid} onSave={saveHandler} loading={false} onCancel={closeModal} isAdd={true} />
			</ModalFooter>
		</>
	);
};

export default WorkingHoursAddModal;
