import { useSelector } from "react-redux";
import IAppState from "../interfaces/store/IAppState";
import IAuthState from "../interfaces/store/IAuthState";
import { useEffect, useState } from "react";
import { SITE_TYPES } from "../shared/site-data";
import { IOption } from "../components/ui/Input/Input";
import { EInterpretationType } from "../shared/interpretation-data";


export const useSiteTypes = () => {
  const [siteTypes, setSiteTypes] = useState<IOption[]>([]);
  
  const { currentSite } = useSelector<IAppState, IAuthState>((state) => state.auth);

  useEffect(() => {
    if (currentSite) {
      setSiteTypes(SITE_TYPES.filter(option => currentSite.types.includes(option.value as EInterpretationType)))
    }
  }, [currentSite]);

  return { siteTypes };
}