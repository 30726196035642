import React from 'react';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';
import { IEezyTrusterPaymentRow } from '../../../interfaces/IEezyTruster';
import Table from '../../ui/Table/Table';
import { useInterpretation } from '../../../hooks/useInterpretation';

interface IProps {
	paymentRows: IEezyTrusterPaymentRow[];
}

const EezyTrusterJobRows: React.FC<IProps> = ({ paymentRows }) => {
	const { t } = useTranslation()
	const { getInterpretationTypeString } = useInterpretation();

	return (
		<Table>
			<thead>
				<tr>
					<th>{t(ETranslation.COMMON_MINUTES_IN_TOTAL)}</th>
					<th>{t(ETranslation.COMMON_TIMESPAN)}</th>
					<th>{t(ETranslation.COMMON_TYPE)}</th>
				</tr>
			</thead>
			<tbody>
				{paymentRows.map((row) => {
					return <tr key={row.interpretation.id}>
						<td>
							{row.minutes} m
						</td>
						<td>
							{row.startDate} - {row.endDate}
						</td>
						<td>
							{getInterpretationTypeString(t, row.interpretation.type)}
						</td>
					</tr>
				})}
			</tbody>
		</Table>
	);
};

export default EezyTrusterJobRows;
