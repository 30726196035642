import { createContext, useContext} from 'react';

type TranslationContextObj = {
  isDeleting: boolean;
	isUpdating: boolean;
	actionId: string | null;
};

export const TranslationContext = createContext<TranslationContextObj>({
  isDeleting: false,
  isUpdating: false,
  actionId: null,
});

export const useTranslationContext = () => useContext(TranslationContext);
