import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSiteTypes } from '../../hooks/useSiteTypes';
import { ISalaryInfo } from '../../interfaces/ISalaryInfo';
import { formatDateRange } from '../../utils/date-utils';
import Button, { EButtonColor, EButtonSize } from '../ui/Button/Button';
import Table from '../ui/Table/Table';
import { ETranslation } from '../../translations/translation-keys';

interface IProps {
	salaryInfos: ISalaryInfo[] | null;
	onEdit: (salaryInfo: ISalaryInfo) => void;
	onDelete: (id: string) => void;
}

const SalaryInfosAttachList: React.FC<IProps> = ({ salaryInfos, onEdit, onDelete }) => {
	const { siteTypes } = useSiteTypes();
	const { t } = useTranslation();
	if (!salaryInfos || salaryInfos.length === 0) {
		return <p>{t(ETranslation.SALARYINFO_NO_SALARIES)}</p>;
	}
	return (
		<Table condensed containerStyles={{ marginTop: "1rem" }}>
			<thead>
				<tr>
					<th>Ammattinimikkeet</th>
					<th>Ajankohta</th>
					<th>Palkka / h</th>
					<th style={{ width: 1 }}></th>
				</tr>
			</thead>
			<tbody>
				{salaryInfos.map((salaryInfo) => {
					return (
						<tr key={salaryInfo.id}>
							<td>{(salaryInfo.types ?? []).map(type => t(siteTypes.find(option => option.value === type)?.labelTranslation ?? '')).join(', ')}</td>
							<td>{formatDateRange(salaryInfo.startDate, salaryInfo.endDate)}</td>
							<td>{salaryInfo.salaryPerHour}</td>
							<td>
								<div style={{ display: "flex", flexWrap: "nowrap", gap: ".5rem" }}>
									<Button
										size={EButtonSize.X_SMALL}
										onClick={() => onEdit(salaryInfo)}
										style={{ marginBottom: 0 }}
										color={EButtonColor.DEFAULT}
									>
										Muokkaa
									</Button>
									<Button
										size={EButtonSize.X_SMALL}
										onClick={() => onDelete(salaryInfo.id)}
										style={{ marginBottom: 0 }}
										color={EButtonColor.DANGER}
									>
										Poista
									</Button>
								</div>
							</td>
						</tr>
					);
				})}
			</tbody>
		</Table>
	);
};

export default SalaryInfosAttachList;
