import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { IOption } from "../Input/Input";

import classes from "./Checkbox.module.scss";
import CheckboxOption from "./CheckboxOption/CheckboxOption";

interface IProps {
  value: string[];
  onChange: (value: string[]) => void;
  options: IOption[] | undefined;
  name: string;
  disabled?: boolean;
  invalid?: boolean;
}

const Checkbox: React.FC<IProps> = ({ value, onChange, options, name, disabled, invalid}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation();


  return (
    <div className={classes.OptionContainer} ref={containerRef}>
      {options &&
        options.map(option => (
          <CheckboxOption
            key={option.value}
            value={option.value}
            label={option.labelTranslation ? t(option.labelTranslation) : option.label}
            isActive={value.indexOf(option.value) !== -1}
            onClick={onChange}
            name={name}
            disabled={disabled}
            invalid={invalid}
            containerRef={containerRef}
          />
        ))}
    </div>
  );
};

export default Checkbox;
