import { useEffect, useState } from "react";

import * as interpretationService from '../../services/interpretationServices';
import { IOption } from "../ui/Input/Input";
import * as dateUtils from '../../utils/date-utils';
import { IUser } from "../../classes/User";


const getUserDetails = (user?: IUser) => {
	if (!user) return "";
	const organizations = user.organizations;
	let arr: string[] = []
	if (organizations && organizations.length > 0) {
		arr.push(organizations[0].name);
	}
	arr.push(`(${user.name})`);
	return arr.join(" ").trim();
}


export const useUserShiftDateInterpretationOptions = (startDate: string, endDate: string, userId: string) => {
	const [loading, setLoading] = useState(false);
	const [options, setOptions] = useState<IOption[]>([]);

	useEffect(() => {
		if (startDate && startDate && userId) {
			(async () => {
				setLoading(true);
				try {
					const interpretations = await interpretationService.findInterpretations(startDate, endDate, userId);
					setOptions(interpretations.map<IOption>(interpretation => ({ label: `${dateUtils.formatDateTimeRange(interpretation.startDateUTC, interpretation.endDateUTC)} - ${getUserDetails(interpretation.customer)}`, value: interpretation.id })));
				} catch (e) {

				} finally {
					setLoading(false);
				}
			})();

		}

	}, [startDate, endDate, userId])


	return { interpretationOptions: options, interpretationOptionsLoading: loading };
}