import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IEezyTrusterPayment } from '../../../interfaces/IEezyTruster';
import { useSelector } from 'react-redux';
import IAppState from '../../../interfaces/store/IAppState';
import Table from '../../ui/Table/Table';
import { ETranslation } from '../../../translations/translation-keys';
import { CustomTotalRow, EezyTotalBookingRow, EezyTotalOnSiteRow, EezyTotalRow, ManagementTotalRow, StartTotalRow, TotalsRow } from './PaymentRows/PaymentRows';
import { AddCustomPayment } from './PaymentRows/AddCustomTotalRow';


export interface ICustomTotalAdd {
	productName: string;
	quantity: number;
	quantityUnitPrice: number;
	fromServer: boolean;
}

interface IProps {
	payment: IEezyTrusterPayment;
	removeManagementTotal: () => void;
	removeCustomTotal: (index: number) => void;
	addCustomTotalCheck: boolean;
	clickCancelCustomTotalAdd: () => void;
	saveNewCustomTotal: (customTotal: ICustomTotalAdd) => void;
}

const EezyTrusterInfoPaymentRows: React.FC<IProps> = ({ payment, removeManagementTotal, addCustomTotalCheck, clickCancelCustomTotalAdd, saveNewCustomTotal, removeCustomTotal }) => {
	const { t } = useTranslation()

	const { managementPaymentRemoveLoading, customTotalLoading, removeCustomLoading } = useSelector((state: IAppState) => ({
		managementPaymentRemoveLoading: state.eezyTruster.managementPaymentRemoveLoading,
		customTotalLoading: state.eezyTruster.customTotalLoading,
		removeCustomLoading: state.eezyTruster.removeCustomLoading
	}));

	const [customTotalSaveClicked, setCustomTotalSaveClicked] = useState(false);

	useEffect(() => {
		if (!customTotalSaveClicked) return;
		if (!customTotalLoading && customTotalSaveClicked) {
			setCustomTotalSaveClicked(false);
			clickCancelCustomTotalAdd();
		}
	}, [customTotalLoading, customTotalSaveClicked, clickCancelCustomTotalAdd])

	const saveCustomTotal = (customTotal: ICustomTotalAdd) => {
		setCustomTotalSaveClicked(true);
		saveNewCustomTotal(customTotal);
	};

	const cancelNewCustomTotal = () => {
		clickCancelCustomTotalAdd();
	};

	const removeCustomTotalClick = (index: number) => {
		removeCustomTotal(index)
	}

	return (
		<Table containerStyles={{ width: "100%" }}>
			<thead>
				<tr>
					<th>Tuotteen nimi</th>
					<th>Minuutit yhteensä</th>
					<th>Määrä</th>
					<th>{t(ETranslation.COMMON_PRICE_OF_ONE)}</th>
					<th>Maksun määrä</th>
					<th>ALV</th>
					<th>Maksun määrä + ALV</th>
					<th>Aikaväli</th>
					<th></th>
				</tr>
			</thead>
			<tbody> 
				<EezyTotalRow payment={payment} />
				<EezyTotalBookingRow payment={payment} />
				<EezyTotalOnSiteRow payment={payment} />
				<StartTotalRow payment={payment} />
				<ManagementTotalRow payment={payment} loading={managementPaymentRemoveLoading} removeManagementTotal={removeManagementTotal} />
				{payment.customTotals.map((customTotal, i) => {
					return <CustomTotalRow customTotal={customTotal} key={i} index={i} loading={removeCustomLoading} removeCustomTotalClick={removeCustomTotalClick} />
				})}
				{addCustomTotalCheck &&
					<AddCustomPayment customTotalLoading={customTotalLoading} saveCustomTotal={saveCustomTotal} cancelNewCustomTotal={cancelNewCustomTotal} />
				}
				<TotalsRow payment={payment} />
			</tbody>
		</Table>
	);
};

export default EezyTrusterInfoPaymentRows;
