
import React from "react";
import classes from "./userStyles.module.scss";

interface IUserDetailItemProps {
	label: string;
	value: string;
}

const UserCommentItem: React.FC<IUserDetailItemProps> = ({ label, value }) => {

	return (
		<div className={classes.commentContainer}>
			<div className={classes.infoLabel}>
				{label}
			</div>
			<div className={classes.infoValue}>
				{value.includes("\n") ? value.split("\n").map((string, key) => { return [<React.Fragment key={key}>string, <br /></React.Fragment>] }) : value}
			</div>
		</div>
	)
}

export default UserCommentItem;