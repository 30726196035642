import React, { useEffect, useRef, useState } from 'react';

import * as twilio from 'twilio-video';

import classes from './TwilioVideoParticipant.module.scss';

interface IProps {
  participant: twilio.Participant;
}

const TwilioVideoParticipant: React.FC<IProps> = ({ participant }) => {
  const [videoTracks, setVideoTracks] = useState<twilio.VideoTrack[]>([]);
  const [audioTracks, setAudioTracks] = useState<twilio.AudioTrack[]>([]);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    const trackSubscribed = (track: twilio.VideoTrack | twilio.AudioTrack) => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => [...videoTracks, track]);
      } else {
        setAudioTracks(audioTracks => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track: twilio.VideoTrack | twilio.AudioTrack) => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
      } else {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
      }
    };
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);


  useEffect(() => {
    const videoTrack = videoTracks[0];
    const videoEl = videoRef.current;
    if (videoTrack && videoEl) {
      videoTrack.attach(videoEl);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    const audioEl = audioRef.current;
    if (audioTrack && audioEl) {
      audioTrack.attach(audioEl);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <div className={classes.Container}>
      <video ref={videoRef} autoPlay={true} />
      <audio ref={audioRef} autoPlay={true} />
    </div>
  );
}

export default TwilioVideoParticipant;

const trackpubsToTracks = (trackMap: any) => Array.from(trackMap.values()).map((publication: any) => publication.track).filter(track => track !== null);
