import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ERoute, Routes } from '../../classes/Routes';
import Heading from '../../components/Heading/Heading';
import Button, { EButtonColor } from '../../components/ui/Button/Button';
import Container from '../../components/ui/Container/Container';
import { EInputType, IInputField } from '../../components/ui/Input/Input';
import { getInputData, initForm } from '../../components/ui/Input/input-utils';
import InputGroup from '../../components/ui/InputGroup/InputGroup';
import AddNewBtn from '../../components/User/AddNewUserBtn';
import UserListTable from '../../components/User/UsersList';
import { useCreateInput } from '../../hooks/useCreateInput';
import { IPaginationOptions, PAGINATION_FETCH_SIZE, UserPagination } from '../../hooks/userPagination';
import { searchOrganizations } from '../../services/searchServices';
import { getUserOptions, searchCustomers } from '../../services/userServices';
import { ESearchStorageKey } from '../../shared/log-items-data';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';
import Spinner from '../../components/ui/Spinner/Spinner';
import { useInitSearchValues } from '../../hooks/useInitSearchValues';
import { getOrganizationOptions } from '../../services/organizationServices';
import useCommentsModal from '../../components/Comments/CommentUtils/UseCommentModal';
import { useStoredSearchParams } from '../../hooks/useStoredSearchParams';
import IUserAction from '../../interfaces/store/IUserAction';
import { useTableSort } from '../../components/ui/Table/useTableSort';
import { IUser } from '../../classes/User';

interface IProps extends RouteComponentProps { }

enum EInputs {
	organization = "organization",
	user = "user",
	fetchDeleted = "fetchDeleted",
};

export interface IFilterOptions {
	organization: string[];
	user: string;
	fetchDeleted: boolean;
}


const CustomersPage: React.FC<IProps> = ({ history, location, match }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const openCommentsModal = useCommentsModal();

	const initialInputs = {
		[EInputs.organization]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_ORGANIZATION,
			value: null,
			multiple: true,
		},
		[EInputs.user]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_CUSTOMER,
			value: "",
		},
		[EInputs.fetchDeleted]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.UI_SHOW_DELETED,
			value: false,
		},
	}

	const [inputs, setInputs] = useState<IInputField>(initialInputs);

	const createInput = useCreateInput(inputs, setInputs);

	const { setIds: setOrganizationIds, isLoading: isLoadingOrganizations } = useInitSearchValues(setInputs, EInputs.organization, getOrganizationOptions);
	const { setIds: setUserIds, isLoading: isLoadingUsers } = useInitSearchValues(setInputs, EInputs.user, getUserOptions);

	const { loaderRef, users, loading, searchHandler, hasMoreUsers } = UserPagination();
	const { onSort, sort, items } = useTableSort<IUser>({ data: users });

	const openHandler = (id: string) => {
		history.push(Routes.withParams(ERoute.USER_EDIT, { id }));
	};

	const { onSearch, clearInputs } = useStoredSearchParams<IFilterOptions, IUserAction, IPaginationOptions>({
		setInputs,
		initialInputs,
		storageKey: ESearchStorageKey.CUSTOMERS_SEARCH_PARAMS,
		clearResults: useCallback(() => dispatch(actions.getUsersClear()), [dispatch]),
		searchHandlerDataTransform: useCallback((data: IFilterOptions) => {
			return {
				type: "CUSTOMER",
				user: data.user,
				organization: data.organization ? data.organization[0] : null,
				fetchSize: PAGINATION_FETCH_SIZE,
				fetchDeleted: data.fetchDeleted,
				start: 0,
			}
		}, []),
		searchHandler,
		initIdInputs: useCallback((data: IFilterOptions) => {
			initForm(setInputs, { ...data, user: '', organization: [] });
			if (data.organization) setOrganizationIds(data.organization)
			if (data.user) setUserIds([data.user])
		}, [setUserIds, setOrganizationIds])
	})

	return (
		<Container>
			<Heading>{t(ETranslation.MENU_USERS_CUSTOMERS)}</Heading>
			<AddNewBtn route={ERoute.USER_EDIT} id={"addCustomer"} history={history} location={location} match={match} />
			<InputGroup>
				{createInput(EInputs.organization, { fetchOptions: searchOrganizations, isLoading: isLoadingOrganizations })}
			</InputGroup>
			<InputGroup>
				{createInput(EInputs.user, { fetchOptions: searchCustomers, isLoading: isLoadingUsers })}
			</InputGroup>
			<InputGroup>
				{createInput(EInputs.fetchDeleted)}
			</InputGroup>
			<InputGroup>
				<Button loading={loading} onClick={() => { onSearch(getInputData<IFilterOptions>(inputs)) }}>
					{t(ETranslation.COMMON_SEARCH)}
				</Button>
				<Button loading={loading} color={EButtonColor.DEFAULT} onClick={clearInputs}>{t(ETranslation.UI_CLEAR)}</Button>
			</InputGroup>

			<UserListTable
				users={items}
				onOpen={openHandler}
				onComment={openCommentsModal}
				location={location}
				history={history}
				match={match}
				onSort={onSort}
				sort={sort}
			/>
			{loading ? (
				<Spinner />
			) : (<p
				ref={loaderRef}
				style={{ color: hasMoreUsers ? "#ccc" : "#fff" }}
			>
				{hasMoreUsers ? "Näytä lisää rivejä..." : ""}
			</p>)}
		</Container>
	)
}

export default CustomersPage;