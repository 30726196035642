import { Dispatch } from 'redux';

import { customFetch, EFetchMethod } from '../../custom-fetch';
import EActionTypes from '../../interfaces/store/EActionTypes';
import ICommonAction from '../../interfaces/store/ICommonAction';
import { ILanguageOption, ILanguageStatus } from './../../interfaces/ILanguage';
import { ITag } from './tagsActions';

type TAction = ICommonAction;

export interface ILanguageStatusSearchOptions {
  fromLanguage?: string;
  toLanguages?: string[];
  tags?: ITag[];
  gender?: string;
  clientConsole?: boolean;
}

const findLanguagesStart = (): TAction => {
  return {
    type: EActionTypes.COMMON_FIND_LANGUAGES_START,
  };
};

const findLanguagesSuccess = (languages: ILanguageOption[]): TAction => {
  return {
    type: EActionTypes.COMMON_FIND_LANGUAGES_SUCCESS,
    languages,
  };
};

const findLanguagesFail = (error: string): TAction => {
  return {
    type: EActionTypes.COMMON_FIND_LANGUAGES_FAIL,
    error,
  };
};

export const findLanguages = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(findLanguagesStart());
      const languages = await customFetch<ILanguageOption[]>("/languages");
      dispatch(findLanguagesSuccess(languages));
    } catch (error) {
      dispatch(findLanguagesFail((error as Error).message));
    }
  };
};

const getLanguagesStatusStart = (): TAction => {
  return {
    type: EActionTypes.COMMON_GET_LANGUAGES_STATUS_START,
  };
};

const getLanguagesStatusSuccess = (languagesStatus: ILanguageStatus[]): TAction => {
  return {
    type: EActionTypes.COMMON_GET_LANGUAGES_STATUS_SUCCESS,
    languagesStatus
  };
};

const getLanguagesStatusFail = (error: string): TAction => {
  return {
    type: EActionTypes.COMMON_GET_LANGUAGES_STATUS_FAIL,
    error,
  };
};

export const getLanguagesStatusClear = (): TAction => {
  return {
    type: EActionTypes.COMMON_GET_LANGUAGES_STATUS_CLEAR,
  };
};

export const getLanguagesStatus = (searchParams: ILanguageStatusSearchOptions) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getLanguagesStatusStart());
      const languagesStatus = await customFetch<ILanguageStatus[]>("/languages/status", EFetchMethod.POST, JSON.stringify(searchParams));
      dispatch(getLanguagesStatusSuccess(languagesStatus));
    } catch (error) {
      dispatch(getLanguagesStatusFail((error as Error).message));
    }
  };
}
