import { Dispatch } from "redux";
import EActionTypes from "../../interfaces/store/EActionTypes";
import ITagAction from "../../interfaces/store/ITagAction";
import { EFetchMethod, customFetch } from "../../custom-fetch";
import { IOptionable } from "../../utils/option-utils";

export interface ITag extends IOptionable {
	id:string;
	name:string;
	description:string;
	tagLanguageVersions: ITagLanguageVersions[];
}

export interface ITagLanguageVersions {
	description: string;
	id: string;
	language: string;
	name: string;
}


type TAction = ITagAction;


const getTagStart = (): TAction => {
	return {
		type: EActionTypes.TAG_GET_START
	};
};

const getTagSuccess = (tag: ITag): TAction => {
	return {
		type: EActionTypes.TAG_GET_SUCCESS,
		tag
	};
};

const getTagError = (error: string): TAction => {
	return {
		type: EActionTypes.TAG_GET_ERROR,
		error
	};
};

export const getTagClear = (): TAction => {
	return {
		type: EActionTypes.TAG_GET_CLEAR,
	};
};

const saveTagStart = (): TAction => {
	return {
		type: EActionTypes.TAG_SAVE_START
	};
};

export const saveTagClear = (): TAction => {
	return {
		type: EActionTypes.TAG_SAVE_CLEAR
	};
};

const saveTagComplete = (tag: ITag, actionType: string): TAction => {
	return {
		tag,
		actionType: actionType,
		type: EActionTypes.TAG_SAVE_SUCCESS
	};
};

const saveTagError = (error: string): TAction => {
	return {
		type: EActionTypes.TAG_SAVE_ERROR,
		error
	};
};

const getTagsStart = (): TAction => {
	return {
		type: EActionTypes.TAGS_GET_START
	};
};

const getTagsSuccess = (tags: ITag[]): TAction => {
	return {
		type: EActionTypes.TAGS_GET_SUCCESS,
		tags
	};
};

const getTagsError = (error: string): TAction => {
	return {
		type: EActionTypes.TAGS_GET_ERROR,
		error
	};
};

export const getTagsClear = (): TAction => {
	return {
		type: EActionTypes.TAGS_GET_CLEAR,
	};
};

export const getTags = () => {
	return async (dispatch: Dispatch) => {
		dispatch(getTagsStart())
		try {
			customFetch<ITag[]>("/tags", EFetchMethod.GET).then((data)=>{
				dispatch(getTagsSuccess(data));
			})
		} catch (error) {
			dispatch(getTagsError((error as Error).message));
		}
	};
};

export const saveTag = (tag: ITag) =>{
	return async (dispatch: Dispatch) => {
		dispatch(saveTagStart())
		try {
			customFetch<{tag:ITag, action: string}>("/tags/add", EFetchMethod.POST, JSON.stringify(tag)).then((data)=>{
				dispatch(saveTagComplete(data.tag, data.action));
			})
		} catch (error) {
			dispatch(saveTagError((error as Error).message));
		}
	};
}

export const getTag = (tagId: string) => {
	return async (dispatch: Dispatch) => {
		dispatch(getTagStart())
		try {
			customFetch<ITag>("/tags?id=" + tagId, EFetchMethod.GET).then((data)=>{
				dispatch(getTagSuccess(data));
			})
		} catch (error) {
			dispatch(getTagError((error as Error).message));
		}
	};
};
