import React, { useCallback } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IUserContractSettings } from '../../../interfaces/IUserContractSettings';
import IAppState from '../../../interfaces/store/IAppState';
import IUserContractState from '../../../interfaces/store/IUserContractState';
import * as actions from '../../../store/actions';
import ModalContext, { EModalSize } from '../../ui/Modal/ModalContext';
import UserContractSettingsModal from '../UserContractSettingsModal/UserContractSettingsModal';

export const useEditUserContractSettings = () => {
	const [settings, setSettings] = useState<IUserContractSettings[]>([]);
	const dispatch = useDispatch();

	const { setting } = useSelector<IAppState, IUserContractState>((state) => state.userContracts);
	const { setModal, closeModal } = useContext(ModalContext);

	useEffect(() => {
		if (setting) {
			setSettings((settings) => {
				return [...settings, setting];
			});
			dispatch(actions.getUserContractSettingsClear());
		}
	}, [setting, dispatch]);

	const deleteHandler = (id: string, index: number) => {
		setSettings((settings) => {
			const newSettings = [...settings];
			newSettings.splice(index, 1);
			return newSettings;
		});
	};

	const updateHandler = useCallback((setting: IUserContractSettings, index: number) => {
			setSettings((settings) => {
				const newSettings = [...settings];
				newSettings[index] = { ...setting, id: newSettings[index].id };
				return newSettings;
			});
			closeModal();
		},
		[closeModal]
	);

	const editHandler = (id: string, index: number) => {
		const setting = settings[index];
		setModal({
			isOpen: true,
			content: <UserContractSettingsModal onChange={updateHandler} settings={setting} index={index} />,
			size: EModalSize.FULL,
			title: "Sopimusasetukset",
		});
	};

	return {
		editContractSettings: settings,
		setEditContractSettings: setSettings,
		editContractSettingsDeleteHandler: deleteHandler,
		editContractSettingsEditHandler: editHandler,
	};
};
