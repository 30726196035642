import { IOrganizationDuration } from "../../interfaces/IOrganization";
import { IOption } from "../ui/Input/Input";

export const DURATIONS: IOption[] = [
	{ value: "15", label: "15" },
	{ value: "20", label: '20' },
	{ value: "30", label: '30' },
	{ value: "45", label: "45" },
	{ value: "60", label: "60" },
	{ value: "75", label: "75" },
	{ value: "90", label: '90' },
	{ value: "105", label: '105' },
	{ value: "120", label: "120" },
	{ value: "135", label: "135" },
	{ value: "150", label: "150" },
	{ value: "165", label: '165' },
	{ value: "180", label: '180' },
	{ value: "195", label: "195" },
	{ value: "210", label: "210" },
	{ value: "225", label: "225" },
	{ value: "240", label: '240' },
	{ value: "255", label: '255' },
	{ value: "270", label: "270" },
	{ value: "285", label: "285" },
	{ value: "300", label: "300" },
	{ value: "315", label: '315' },
	{ value: "330", label: '330' },
	{ value: "345", label: "345" },
	{ value: "360", label: "360" },
	{ value: "375", label: "375" },
	{ value: "390", label: '390' },
	{ value: "405", label: '405' },
	{ value: "420", label: "420" },
	{ value: "435", label: "435" },
	{ value: "450", label: "450" },
	{ value: "465", label: '465' },
	{ value: "480", label: '480' },
	{ value: "495", label: "495" },
	{ value: "510", label: "510" },
	{ value: "525", label: "525" },
	{ value: "540", label: '540' },
	{ value: "555", label: '555' },
	{ value: "570", label: "570" },
	{ value: "585", label: "585" },
	{ value: "600", label: "600" },
]

export const durationsToOptions = (durations?: IOrganizationDuration[]): IOption[] => {
	if(!durations) return [];
	return durations.map((item) => { return { value: item.durationInMinutes.toString(), label: item.durationInMinutes.toString() } })
}