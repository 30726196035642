import React from "react";
import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";

interface IProps {
	cvParseDetails: ICvParseJsonData | string;
}

export interface ICvParseJsonData {
	responseStatus: string;
	responseTime: string;
	probabilityToBeRecruited: number;
	listInterpreterEducations: string[],
	listInterpreterWorkingExperience: string[];
	dictHealthCareWords: { [key: string]: string; };
	dictLegalWords: { [key: string]: string; };
	dictChildcareWords: { [key: string]: string; };
	dictLanguagesCountsAndLevels: { [key: string]: number; }
	listCities: string[];
	yearsOfExperience: string;
}

const CvParseJsonDisplay: React.FC<IProps> = ({ cvParseDetails }) => {

	const { t } = useTranslation();

	return (
		typeof cvParseDetails !== "string" ?
			cvParseDetails.responseStatus === "success" ?
				<div>
					<span><b>{t(ETranslation.COMMISSIONS_CV_PARSE_PROBABILITY)}</b> {cvParseDetails.probabilityToBeRecruited}</span>
					<br />
					<span><b>{t(ETranslation.COMMISSIONS_CV_PARSE_SKILLS)}</b></span><br />
					<span><b>{t(ETranslation.COMMISSIONS_CV_PARSE_EDUCATION)}</b></span> <br /> {cvParseDetails.listInterpreterEducations?.map((education, key) => {
						return <div key={key}><span>{education}</span><br /></div>
					})}<br />
					<span><b>{t(ETranslation.COMMISSIONS_CV_PARSE_WORKING_EXPERIENCE)}</b> </span><br /> {cvParseDetails.listInterpreterWorkingExperience?.map((work, key) => {
						return <div key={key}><span>{work}</span><br /></div>
					})}<br />
					<span><b>{t(ETranslation.COMMISSIONS_CV_PARSE_HEALTHCARE)}</b></span><br />
					{
						cvParseDetails.dictHealthCareWords ? Object.keys(cvParseDetails.dictHealthCareWords).map((key: string) => {
							return <div key={key}><span> {key} : {cvParseDetails.dictHealthCareWords[key]} </span><br /></div>
						}) : null
					}<br />
					<span><b>{t(ETranslation.COMMISSIONS_CV_PARSE_LEGAL)}</b></span> <br />{
						cvParseDetails.dictLegalWords ? Object.keys(cvParseDetails.dictLegalWords).map((key: string) => {
							return <div key={key}><span> {key} : {cvParseDetails.dictLegalWords[key]} </span> <br /></div>
						}) : null
					}<br />
					<span><b>{t(ETranslation.COMMISSIONS_CV_PARSE_CHILDCARE)}</b></span> <br />{
						cvParseDetails.dictChildcareWords ? Object.keys(cvParseDetails.dictChildcareWords).map((key: string) => {
							return <div key={key}><span> {key} : {cvParseDetails.dictChildcareWords[key]} </span> <br /></div>
						}) : null
					}<br />
					<span><b>{t(ETranslation.COMMISSIONS_CV_PARSE_LOCATION)}</b></span><br />
					{
						cvParseDetails.listCities?.map((value, key) => {
							return <div key={key}><span> {value} </span><br /></div>
						})
					}<br />
					<span><b>{t(ETranslation.COMMISSIONS_CV_PARSE_LANGUAGES)}</b></span> <br />{
						cvParseDetails.dictLanguagesCountsAndLevels ? Object.keys(cvParseDetails.dictLanguagesCountsAndLevels).map((key: string) => {
							return <div key={key}><span> {key} : {cvParseDetails.dictLanguagesCountsAndLevels[key]} </span> <br /></div>
						}) : null
					}<br />
					<span><b>{t(ETranslation.COMMISSIONS_CV_PARSE_YEARS_OF_EXPERIENCE)}</b> {cvParseDetails.yearsOfExperience}</span>
					<br />
					<br />
				</div> :
				<div>
					<span>
						Error parsing cv
					</span>
				</div> : null
	)
}

export default CvParseJsonDisplay;