import React from 'react';


export type TSetUploading = (id: string, isUploading: boolean) => void;

const CustomDropzoneContext = React.createContext<{
  setUploading: TSetUploading;
}>({
  setUploading: () => {}
});

export default CustomDropzoneContext;
