import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import { ITranslation } from "../../interfaces/ITranslation";
import { ETranslation } from "../../translations/translation-keys";
import ModalContext, { EModalSize } from "../ui/Modal/ModalContext";
import TranslationEditModal from "./TranslationEditModal";

export const useTranslationEditModal = () => {
	const { setModal, closeModal } = useContext(ModalContext);
	const { t } = useTranslation();

	const handler = useCallback(
		(translation: ITranslation, onSave: (translation: ITranslation) => void, onClose?: VoidFunction) => {
			setModal({
				isOpen: true,
				size: EModalSize.MEDIUM,
				title: t(ETranslation.LANGUAGE_EDIT_TRANSLATION),
				content: (
					<TranslationEditModal
						translation={translation}
            onSave={onSave}
						onClose={onClose ?? closeModal}
					/>
				),
			});
		},
		[setModal, closeModal, t]
	);

	return handler;
};
