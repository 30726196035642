import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../translations/translation-keys';

import Alert from '../ui/Alert/Alert';
import Button, { EButtonColor } from '../ui/Button/Button';
import ModalBody from '../ui/Modal/ModalBody/ModalBody';
import ModalContext from '../ui/Modal/ModalContext';
import ModalFooter from '../ui/Modal/ModalFooter/ModalFooter';

interface IProps {
  text?: string;
  onConfirm?: () => void;
  error?: string | null;
}



const ConfirmationModal: React.FC<IProps> = ({
  text,
  onConfirm,
  error,
}) => {
  const { closeModal } = useContext(ModalContext);
  const { t } = useTranslation();
  return (
    <>
      <ModalBody>
        {error && <Alert>{error}</Alert>}
        {text}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => onConfirm && onConfirm()}
          color={EButtonColor.SUCCESS}
          
        > {t(ETranslation.COMMON_YES)}
        </Button>
        <Button onClick={closeModal} color={EButtonColor.DEFAULT}>
          {t(ETranslation.COMMON_RETURN)}
        </Button>
      </ModalFooter>
    </>
  );
};

export default ConfirmationModal;
