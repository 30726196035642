import React, { useEffect, useState } from 'react';

import { useCreateInput } from '../../../hooks/useCreateInput';
import { ICategorySettings } from '../../../interfaces/ICategorySettings';
import { EInputType, IInputField } from '../../ui/Input/Input';
import { getInputData, initForm, validateInputs } from '../../ui/Input/input-utils';
import Heading from '../../Heading/Heading';

enum EInputs {
	isExpirationDate = "isExpirationDate",
}

interface IProps {
	onChange: (settings: ICategorySettings, isValid: boolean) => void;
	settings: ICategorySettings | null;
	loading?: boolean;
	showValidation?: boolean;
}

const CategorySettings: React.FC<IProps> = ({ onChange, settings, loading, showValidation }) => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.isExpirationDate]: {
			type: EInputType.boolean,
			label: "Vanhenemispvm",
			value: "",
			validation: {
				required: true,
			},
		},
	});

	useEffect(() => {
		if (settings) {
			initForm(setInputs, settings);
		}
	}, [settings]);

	useEffect(() => {
		const settings = getInputData<ICategorySettings>(inputs);
		const isValid = validateInputs(inputs);
		onChange(settings, isValid);
	}, [inputs, onChange]);

	const createInput = useCreateInput(inputs, setInputs, {
		showValidation,
		disabled: loading,
	});

	return (
		<div>
			<Heading tag="h2">Asetukset</Heading>
			{createInput(EInputs.isExpirationDate)}
		</div>
	);
};

export default CategorySettings;
