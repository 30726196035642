import React, { CSSProperties, ReactNode } from 'react';

import classes from './Alert.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export enum EAlertColor {
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
  PRIMARY = 'PRIMARY',
  INFO = 'INFO',
  WARNING = "WARNING"
}

interface IProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  color?: EAlertColor;
  bold?: boolean;
  onClose?: VoidFunction;
}

const Alert: React.FC<IProps> = ({ children, className, style, color, bold, onClose }) => {
  const classNames = [classes.Container];
  if (className) {
    classNames.push(className);
  }

  if(bold) {
    classNames.push(classes.Bold);
  }

  switch(color) {
    case EAlertColor.PRIMARY:
      classNames.push(classes.Primary);
      break;
    case EAlertColor.SUCCESS:
      classNames.push(classes.Success);
      break;
    case EAlertColor.INFO:
      classNames.push(classes.Info);
      break;
	case EAlertColor.WARNING:
		classNames.push(classes.Warning);
		break;
    default: 
      classNames.push(classes.Danger);
      break;
  }

  return <div style={style} className={classNames.join(" ")}>{children} {onClose && <FontAwesomeIcon icon={faTimes} onClick={onClose} style={{ cursor: 'pointer' }}></FontAwesomeIcon>}</div>;
};

export default Alert;
