import React from "react";
import { ISummary } from "../../../store/actions/summariesActions";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";
import Table from "../../ui/Table/Table";


interface IProps {
	summary: ISummary;
}

const SummaryPaymentRows: React.FC<IProps> = ({ summary }) => {
	const { t } = useTranslation();
	return (<Table>
		<thead>
			<tr>
				<th>
					{t(ETranslation.SUMMARY_OF_SALES)}{" "}
					{summary.startDate}-{summary.endDate}
				</th>
			</tr>
		</thead>
		<tbody>
			{summary.summaryRows &&
				summary.summaryRows.map((row, id) => (
					<tr key={id}>
						<td>{row.title} {row.descriptionText}</td>
						<td>{row.value}</td>
					</tr>
				))}
			<tr>
				<td><b>{summary.summaryRowsTotal.title}</b></td>
				<td><b>{summary.summaryRowsTotal.value}</b></td>
			</tr>
		</tbody>
	</Table>);
};

export default SummaryPaymentRows;
