import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { ERoute, Routes } from '../../classes/Routes';
import { IUser } from '../../classes/User';
import useCommentsModal from '../../components/Comments/CommentUtils/UseCommentModal';
import Heading from '../../components/Heading/Heading';
import Container from '../../components/ui/Container/Container';
import Spinner from '../../components/ui/Spinner/Spinner';
import UserListTable from '../../components/User/UsersList';
import { UserPagination } from '../../hooks/userPagination';
import { ETranslation } from '../../translations/translation-keys';
import { useTableSort } from '../../components/ui/Table/useTableSort';
import InterpretersSearch from '../../components/User/UsersPages/InterpretersPage/InterpretersSearch';

interface IProps extends RouteComponentProps { }



const InterpretersPage: React.FC<IProps> = ({ history, location, match }) => {
	const { t } = useTranslation();

	const openCommentsModal = useCommentsModal();

	const { loaderRef, users, loading, searchHandler, hasMoreUsers } = UserPagination();
	const { onSort, sort, items } = useTableSort<IUser>({ data: users });

	const openHandler = (id: string) => {
		history.push(Routes.withParams(ERoute.USER_EDIT, { id }));
	};

	const [filteredUsers, setFilteredUsers] = useState<IUser[]>([])

	return (
		<Container>
			<Heading>{t(ETranslation.MENU_USERS_INTERPRETERS)}</Heading>
			<InterpretersSearch
				searchHandler={searchHandler}
				items={items}
				loading={loading}
				history={history}
				location={location}
				match={match}
				setFilteredUsers={setFilteredUsers}
			/>
			{loading ? (
				<Spinner />
			) : (
				<UserListTable
					users={filteredUsers}
					onOpen={openHandler}
					onComment={(user) => openCommentsModal(user)}
					location={location}
					history={history}
					match={match}
					onSort={onSort}
					sort={sort}
				/>
			)}
			<p ref={loaderRef} style={{ color: hasMoreUsers ? "#ccc" : "#fff" }}>
				{hasMoreUsers ? "Näytä lisää rivejä..." : ""}
			</p>
		</Container>
	);
};

export default InterpretersPage;
