import EActionTypes from '../../interfaces/store/EActionTypes';
import IReservedDateAction from '../../interfaces/store/IReservedDateAction';
import IReservedDateState from '../../interfaces/store/IReservedDateState';
import { ETranslation } from '../../translations/translation-keys';
import { removeOne } from '../../utils/reducer-utils';

type ReducerAction = IReservedDateAction;
type ReducerState = IReservedDateState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  actionId: null,
  reservedDates: null,
  reservedDatesError: null,
  reservedDatesLoading: false,
  deleteLoading: false,
  deleteError: null,
};

const findReservedDatesStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, reservedDatesError: null, reservedDatesLoading: true };
};

const findReservedDatesSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    reservedDates: action.reservedDates || null,
    reservedDatesError: null,
    reservedDatesLoading: false,
  };
};

const findReservedDatesFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, reservedDates: null, reservedDatesLoading: false, reservedDatesError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const deleteReservedDateStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deleteError: null, deleteLoading: true, actionId: action.id || null };
};

const deleteReservedDateClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, reservedDatesError: null, reservedDatesLoading: false, reservedDates: null };
};

const deleteReservedDateSuccess: ReducerSignature = (state, action): ReducerState => {
  const reservedDates = removeOne(state.reservedDates, action.id);
  return {
    ...state,
    reservedDates,
    deleteError: null,
    deleteLoading: false,
    actionId: null,
  };
};

const deleteReservedDateFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deleteLoading: false, deleteError: action.error as string, actionId: null, };
};

const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.RESERVED_DATE_FIND_START:
      return findReservedDatesStart(state, action);
    case EActionTypes.RESERVED_DATE_FIND_SUCCESS:
      return findReservedDatesSuccess(state, action);
    case EActionTypes.RESERVED_DATE_FIND_FAIL:
      return findReservedDatesFail(state, action);
    case EActionTypes.RESERVED_DATE_DELETE_START:
      return deleteReservedDateStart(state, action);
    case EActionTypes.RESERVED_DATE_DELETE_SUCCESS:
      return deleteReservedDateSuccess(state, action);
    case EActionTypes.RESERVED_DATE_DELETE_FAIL:
      return deleteReservedDateFail(state, action);
	case EActionTypes.RESERVED_DATE_FIND_CLEAR:
		return deleteReservedDateClear(state, action);
	  
    default:
      return state;
  }
};

export default reducer;
