import { Dispatch } from 'redux';

import EActionTypes from '../../interfaces/store/EActionTypes';
import { EFetchMethod, customFetchWithResponse } from '../../custom-fetch';
import ICommentAction from '../../interfaces/store/ICommentAction';
import { IComment } from '../../pages/UsersPage/UserComments';

type TAction = ICommentAction;

const getCommentsStart = (): TAction => {
	return {
		type: EActionTypes.COMMENT_GET_LIST_START
	};
};

const getCommentsSuccess = (comments: IComment[], paginationCursor: string): TAction => {
	return {
		type: EActionTypes.COMMENT_GET_LIST_SUCCESS,
		paginationCursor: paginationCursor,
		comments
	};
};

const getCommentsFail = (error: string): TAction => {
	return {
		type: EActionTypes.COMMENT_GET_LIST_FAIL,
		error
	};
};

export const getCommentsClear = (): TAction => {
	return {
		type: EActionTypes.COMMENT_GET_LIST_CLEAR,
	};
};

export interface ICommentSeachParams {
	searchType: "USER" | "ORGANIZATION" | "ORGANIZATION_GROUP" | "ALL";
	entityId: string | null;
	startDate?: string;
	endDate?: string
}


export const searchComments = (params: ICommentSeachParams) => {
	return async (dispatch: Dispatch) => {
		dispatch(getCommentsStart())

		// Tän voisi tehdä jotenkin nätimmin, esim switchillä?
		let url = "";

		let fetchBody = {};
		switch (params.searchType) {
			case "USER":
				url = "/v2/comments/listbyuser";
				fetchBody = {
					userId: params.entityId,
					startDate: params.startDate ?? null,
					endDate: params.endDate ?? null,
				};
				break;
			case "ORGANIZATION":
				url = "/v2/comments/listbyorganization";
				fetchBody = {
					organizationId: params.entityId,
					startDate: params.startDate ?? null,
					endDate: params.endDate ?? null,
				};
				break;
			case "ORGANIZATION_GROUP":
				url = "/v2/comments/listbyorganizationgroup";
				fetchBody = {
					organizationGroupId: params.entityId,
					startDate: params.startDate ?? null,
					endDate: params.endDate ?? null,
				};
				break;
			case "ALL":
				url = "/v2/comments/listbydates";
				fetchBody = {
					startDate: params.startDate ?? null,
					endDate: params.endDate ?? null,
				};
				break;
		}
		try {
			const res = await customFetchWithResponse(url, EFetchMethod.POST, JSON.stringify(fetchBody));
			const paginationCursor = res.headers.get("Pagination-Cursor") ?? "";
			const json = await res.json();
			dispatch(getCommentsSuccess(json, paginationCursor));
		} catch (error) {
			dispatch(getCommentsFail((error as Error).message));
		}
	};
};

