import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute, Routes } from '../../classes/Routes';
import Heading from '../../components/Heading/Heading';
import Button from '../../components/ui/Button/Button';
import Container from '../../components/ui/Container/Container';
import UserContractSettingsList from '../../components/UserContractSettings/UserContractSettingsList/UserContractSettingsList';
import IAppState from '../../interfaces/store/IAppState';
import IUserContractState from '../../interfaces/store/IUserContractState';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';

interface IProps extends RouteComponentProps {}

const UserContractSettingsListPage: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { settings, loading, error } = useSelector<IAppState, IUserContractState>(state => state.userContracts);

  const { t } = useTranslation();

  useEffect(() => {
    if (!settings) {
      dispatch(actions.findUserContractSettings());
    }
  }, [dispatch, settings]);

  const addHandler = () => {
    openHandler("add");
  };

  const openHandler = (id?: string) => {
    if (!id) return;
    history.push(Routes.withParams(ERoute.USER_CONTRACT_SETTINGS_EDIT, { id }));
  };

  return (
    <Container>
      <Heading>{t(ETranslation.USER_CONTRACT_SETTINGS_LIST_TITLE)}</Heading>
      <Button onClick={addHandler}>{t(ETranslation.USER_CONTRACT_SETTINGS_LIST_ADD)}</Button>
      <UserContractSettingsList
        settings={settings}
        loading={loading}
        error={error}
        onClick={openHandler}
      />
    </Container>
  );
};

export default UserContractSettingsListPage;
