import { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
	IOrder,
	exportToYoupretPay,
	getOrder,
	getOrderClear,
	saveOrUpdateOrderClear,
	saveOrder,
	updatePaymentStatus,
} from "../../store/actions/ordersActions";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../components/ui/Container/Container";
import React from "react";
import IAppState from "../../interfaces/store/IAppState";
import { ERoute } from "../../classes/Routes";
import Spinner from "../../components/ui/Spinner/Spinner";
import EditButtons from "../../components/ui/EditButtons/EditButtons";
import Order from "../../components/Orders/Order";

interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const OrderPage: React.FC<IProps> = ({ match, history }) => {
	const [editOrder, setEditOrder] = useState<IOrder | null>(null);

	const dispatch = useDispatch();

	const { id } = match.params;
	const isAdd = id === "add";

	const { order, loading, saveOrUpdateOk, saveOrUpdateLoading } = useSelector(
		(state: IAppState) => state.orders
	);

	useEffect(() => {
		if (order?.id && order?.paymentStatus) {
			dispatch(updatePaymentStatus(order.id));
		}
	}, [dispatch, order]);

	useEffect(() => {
		if (!isAdd) {
			dispatch(getOrder(id));
		}
	}, [isAdd, id, dispatch]);

	const onSave = () => {
		if (!editOrder) {
			return;
		}
		dispatch(saveOrder(editOrder));
	};

	useEffect(() => {
		if (saveOrUpdateOk) {
			history.push(ERoute.ORDERS_LIST);
		}
		return () => {
			dispatch(saveOrUpdateOrderClear());
			dispatch(getOrderClear());
		};
	}, [dispatch, saveOrUpdateOk, history]);

	const updateCB = useCallback((order: IOrder) => {
		setEditOrder(order);
	}, []);

	const exportToPay = useCallback(() => {
		if (order?.id) {
			dispatch(exportToYoupretPay(order.id));
		}
	}, [dispatch, order]);

	return (
		<Container>
			{loading || saveOrUpdateLoading ? (
				<Spinner />
			) : (
				<Order
					onUpdate={updateCB}
					order={!isAdd ? order : null}
					onExport={exportToPay}
				/>
			)}
			<EditButtons
				onCancel={function (): void {
					history.push(ERoute.ORDERS_LIST);
				}}
				isAdd={isAdd}
				onSave={onSave}
			/>
		</Container>
	);
};

export default OrderPage;
