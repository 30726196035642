import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useCreateInput } from "../../hooks/useCreateInput";
import { searchUsers } from "../../services/userServices";
import { ETranslation } from "../../translations/translation-keys";
import Button from "../ui/Button/Button";
import { EInputType, IInputField } from "../ui/Input/Input";
import { getInputData, validateInputs } from "../ui/Input/input-utils";
import InputGroup from "../ui/InputGroup/InputGroup";

export interface IWorkingHoursSummariesSearchParams {
	userId: string;
}

interface IProps {
	onSearch: (params: IWorkingHoursSummariesSearchParams) => void;
}

enum EInputs {
	userId = "userId",
}

const WorkingHoursSummariesSearch: React.FC<IProps> = ({ onSearch }) => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.userId]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_USER,
			value: "",
			validation: {
				required: false,
			},
		},
	});

	const createInput = useCreateInput(inputs, setInputs, { showValidation: true });
	const [isValid, setIsValid] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		setIsValid(validateInputs(inputs));
	}, [inputs]);

	const searchHandler = () => {
		onSearch(getInputData<IWorkingHoursSummariesSearchParams>(inputs));
	};

	return (
		<InputGroup>
			{createInput(EInputs.userId, { fetchOptions: searchUsers })}
			<Button onClick={searchHandler} disabled={!isValid}>
				{t(ETranslation.COMMON_SEARCH)}
			</Button>
		</InputGroup>
	);
};

export default WorkingHoursSummariesSearch;
