import { ETranslation } from "./translation-keys";

const uiTranslations = {
	[ETranslation.UI_MAKE_ORDER]: 'Tilaa työntekijä',
};

const menuTranslations = {
	[ETranslation.MENU_ORDER_INTERPRETER]: "Tilaa työntekijä",
	[ETranslation.MENU_INTERPRETATIONS_LIST]: "Toimeksiannot lista",
	[ETranslation.MENU_INTERPRETATIONS]: "Toimeksiannot",
	[ETranslation.MENU_USERS_INTERPRETERS]: "Työntekijät",
	[ETranslation.MENU_ORDER_INTERPRETER_NEW]: "Tilaa työntekijä (Uusi)",
	[ETranslation.MENU_COMISSIONS]: "Kiinnostuneet työntekijät",
};

const usersTranslations = {
	[ETranslation.USER_INTERPRETER]: "Työntekijän  tiedot",
	[ETranslation.USER_TRAINED_INTERPRETER]: "Työntekijä koulutettu",
	[ETranslation.USER_ADD_FAVOURITE_INTERPRETER]: "Lisää suosikkityöntekijä",
	[ETranslation.USER_ALLOW_FAVORITE_INTERPRETER]: "Salli suosikkityöntekijät",
	[ETranslation.USER_SHOW_ALL_COMPANY_INTERPRETATIONS]: "Näytä yritysten kaikki toimeksiannot",
	[ETranslation.USER_SHOW_ALL_GROUP_INTERPRETATIONS]: "Näytä ryhmän kaikki toimeksiannot",
	[ETranslation.USER_STATUS_OF_INTERPRETER]: "työntekijän tila",
	[ETranslation.USER_SHOW_ORGANIZATION_INTERPRETATIONS]: "Näytä organisaation toimeksiannot",
	[ETranslation.USER_ACCORDION_FAVOURITES]: "Suosikkityöntekijät",
	[ETranslation.USER_ACCORDION_BLOCKED]: "Estetyt työntekijät",
	[ETranslation.USER_ALLOWED_TYPES]: "Sallitut työtarjous tyypit",
};

const commonTranslations = {
	[ETranslation.COMMON_INTERPRETER]: "Työntekijä",
	[ETranslation.COMMON_INTERPRETATION_DONE]: "Toimeksianto valmis",
	[ETranslation.COMMON_INTERPRETATIONS]: "Toimeksiannot",
	[ETranslation.COMMON_INTERPRETATION_INFORMATION]: "Toimeksiannon tiedot",
	[ETranslation.COMMON_INFO_TO_INTERPRETER]: "Lisätiedot työntekijälle",
	[ETranslation.COMMON_REVIEW_FROM_INTERPRETER]: "Työntekijän arvio toimeksiannosta",
	[ETranslation.COMMON_REVIEW_FROM_CUSTOMER]: "Asiakkaan arvio toimeksiannosta",
};

const Base64DropzoneTranslations = {};

const customDropZoneTranslations = {};

const deleteModalTranslations = {};

const infoContactRequestTranslations = {
	[ETranslation.CONTACT_REQUEST_NEED_INTERPRETER]: "Tarvitsen työntekijän",
	[ETranslation.CONTACT_REQUEST_TO_LANGUAGE]: "Tehtävä työ",
};

const messageTranslations = {
	[ETranslation.MESSAGE_INFORMATION]: "Työtarjouksen tiedot",
};

const newsItemTranslations = {
	[ETranslation.NEWS_INTERPRETER_REVIEW]: "Työntekijän arviointi",
	[ETranslation.NEWS_NO_REVIEW]: "Arviointia kyseiselle toimeksiannolle ei ole",
};

const organizationGroupTranslations = {};

const priceSeasonsTranslations = {};

const siteTranslations = {};

const textMessageTranslations = {};

const pagesFileUploadTranslations = {};

const pagesContactRequestTranslations = {};

const pagesMessagesTranslations = {};

const pagesNewsTranslations = {};

const pagesOrganizationsTranslations = {};

const organizationCompaniesTranslations = {
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_INTERPRETER_CALL_TO_CUSTOMER]: "Työntekijä soittaa",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_CUSTOMER_CALL_TO_INTERPRETER]: "Soitan työntekijälle",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_CONTACT_CALL_TO_INTERPRETER]: "Antamani yhteystieto soittaa työntekijälle",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_INTERPRETER_CALL_TO_CONTACT]: "Työntekijä soittaa antamani yhteystiedon numeroon",
	[ETranslation.ORGANIZATION_COMPANY_INTERPRETER_FEE_SETTINGS]: "Työntekijöiden asiakasmaksujen asetukset",
	[ETranslation.ORGANIZATION_DURATIONS]: "Yrityksen toimeksiantojen kestot",
	[ETranslation.ORGANIZATION_COMPANY_DURATIONS]: "Yrityksen toimeksiantojen kestot",
	[ETranslation.ORGANIZATION_DEFAULT_DURATIONS]: "Oletus toimeksiannon kesto",
	[ETranslation.ORGANIZATION_COMPANY_DEFAULT_INTERPRETATION_TYPE]: "Oletustyötarjouksen tyyppi",
	[ETranslation.ORGANIZATION_COMPANY_ONLY_SECURITY_CLEARED_INTERPRETERS]: "Vain turvaselvityt työntekijät",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_CONNECTION_TYPE_SELECT_FOR_INSTANT]: "Salli yhteydenottotavan valinta pikatoimeksiannossa",
	[ETranslation.ORGANIZATION_COMPANY_MIN_BILLING_PERIOD_IN_MINUTES_FOR_INSTANT]: "Pikatoimeksianto: minimi laskutusjakso minuutteina",
	[ETranslation.ORGANIZATION_COMPANY_PRICE_WITHOUT_VAT_PER_MINUTE_FOR_INSTANT_EXTENDED]: "Pikatoimeksianto yli 30 min: alviton hinta / min",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_NOTES_TITLE]: "Asiakkaan toimeksiannon aihe",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_NOTES_INFO_TEXT]: "Asiakkaan toimeksiannon ohje",
};

const pagesPriceSeasonsTranslations = {};

const pagesPriceRulesTranslations = {};

const pagesSiteTranslations = {};

const pagesTextMessagesTranslations = {};

const pagesCustomerReportsTranslations = {};

const dataContactRequestTranslations = {};

const dataPriceSeasonsTranslations = {
	[ETranslation.DATA_PRICE_SEASON_PRICE_PER_INTERPRETATION]: "Toimeksianto hinta",
	[ETranslation.DATA_PRICE_SEASON_PRICE_P_PER_INTERPRETATION]: "Toimeksianto prosentti",
};

const storeTranslations = {};

const appTranslations = {};

const logItemTranslations = {
	[ETranslation.LOG_ITEM_LIST_INTERPRETATION]: "Toimeksianto",
};

const interpretationTranslations = {
	[ETranslation.INTERPRETATION_AMOUNT_OF_INTERPRETATIONS]: "Toimeksiantoja:",
	[ETranslation.INTERPRETATION_CUSTOMER_ESTIMATE]: "Arvioi, kuinka kauan tarvitset työntekijää?",
	[ETranslation.INTERPRETATION_GENDER]: "Työntekijän sukupuoli (Ei pakollinen)",
	[ETranslation.INTERPRETATION_CUSTOMER_NOTES]: "Toimeksiannon aihe ",
	[ETranslation.INTERPRETATION_AVAILABLE_INTERPRETERS]: "Saatavilla olevat työntekijät",
	[ETranslation.INTERPRETATION_ORDER_MULTIPLE]: "Tilaa toimeksiantoja",
	[ETranslation.INTERPRETATION_ON_SITE_INFO]: "Yksikön nimi ja ryhmä",
	[ETranslation.INTERPRETATION_INTERPRETER_REVIEW]: "Työntekijän arvostelu",
	[ETranslation.INTERPRETATION_INTERPRETER_RATING]: "Työntekijän arvostelun arvosana",
	[ETranslation.INTERPRETATION_INTERPRETER_NAME]: "Työntekijän nimi",
	[ETranslation.INTERPRETATION_ADD_INTERPRETATION]: "Lisää toimeksianto",
	[ETranslation.INTERPRETATION_ADD_INTERPRETER]: "Lisää työntekijä",
	[ETranslation.INTERPRETATION_SEND_AUTOMATIC_OFFERS]: "Lähetä automaattiset työtarjoukset",
};

const interpretationInfoBoxTranslation = {
	[ETranslation.INTERPRETATION_INFO_INTERPRETER_INVOICE]: "Työntekijän asiakasmaksu € / min. ilman ALVia",
	[ETranslation.INTERPRETATION_INTERPRETER_PAYMENT]: "Työntekijän maksu",
	[ETranslation.INTERPRETATION_INTERPRETERS_PAYMENT_TYPE]: "Työntekijän maksutapa",
	[ETranslation.INTERPRETATION_CANDIDATES]: "Tarjotut työntekijät",
}

const interpretationDataTranslations = {
	[ETranslation.INTERPRETATION_DATA_CONNECTION_DETAIL_TO_CUSTOMER]: "Työntekijä soittaa.",
	[ETranslation.INTERPRETATION_DATA_STATUS_INTERPRETERS_NOT_FOUND]: "Työntekijää ei löytynyt",
	[ETranslation.INTERPRETATION_DATA_STATUS_FAVORITES_NOT_FOUND]: "Suosikkia ei löytynyt",
	[ETranslation.INTERPRETATION_DATA_STATUS_INTERPRETER_CANCEL]: "Keskeytetty(työntekijä)",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_INTERPRETER]: "Odottaa työntekijän yhteenvetoa",
	[ETranslation.INTERPRETATION_DATA_TYPE_INSTANT]: 'Pikatoimeksianto',
	[ETranslation.INTERPRETATION_DATA_STATUS_OFFER_BOOKING_SELECT]: "Toimeksianto ajanvarauksesta (työntekijät voi valita tallennuksen jälkeen)",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_REVIEWS_SELECT]: "Odottaa yhteenvetoja (Työntekijällä ja asiakkaalla mahdollisuus arvioida jälkeenpäin)",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_INTERPRETER_REVIEW]: "Odottaa työntekijän yhteenvetoa",
};

const interpretationProcessingTranslations = {
	[ETranslation.INTERPRETATION_PROCESSING_INTERPRETER_WILL_CALL]: "Ole valmiina. Työntekijä soittaa sinulle numeroon ",
	[ETranslation.INTERPRETATION_PROCESSING_CALL_INTERPRETER]: "Ole hyvä, voit soittaa työntekijälle. Työntekijä odottaa soittoasi. Soita numeroon ",
	[ETranslation.INTERPRETATION_PROCESSING_INTERPRETERS_NOT_FOUND]: "Työntekijöitä ei löytynyt",
	[ETranslation.INTERPRETATION_PROCESSING_FAVORITES_NOT_FOUND]: "Suosikkeja ei löytynyt",
	[ETranslation.INTERPRETATION_PROCESSING_OPEN_INTERPRETATION]: "Avaa toimeksianto ja sulje tilaus",
};

const interpretationReviewTranslations = {
	[ETranslation.INTERPRETATION_RATING]: "Arvioi toimeksianto",
	[ETranslation.INTERPRETATION_REVIEW_ADD_FAVORITE_INTERPRETER]: "Lisää suosikkityöntekijä",
	[ETranslation.INTERPRETATION_REVIEW_INTERPRETER_IS_FAVORITE]: "Työntekijä on suosikeissa",
	[ETranslation.INTERPRETATION_REVIEW_TEXT]: "Voit antaa lyhyen arvostelun toimeksiannosta",
	[ETranslation.INTERPRETATION_REVIEW_INTERPRETER]: "Sinulle työskenteli ",
};

const interpretationsTranslations = {
	[ETranslation.INTERPRETATIONS_NOT_FOUND]: "Toimeksiantoja ei löytynyt",
	[ETranslation.INTERPRETATIONS_CLEAR_ALL]: "Tyhjennä toimeksiannot",
};

const videoTranslations = {};

const infoTextTranslations = {
	[ETranslation.INFO_TEXT_BOOKING]: "Tee etätyöntekijan ajanvaraus täyttämällä tiedot ja valitse Tee varaus.",
	[ETranslation.INFO_TEXT_ON_SITE]: "Valitse Läsnäolo-välilehti, jos tahdot työntekijän paikan päälle.",
	[ETranslation.INFO_TEXT_INSTANT]: "Valitse näytön yläosasta Pikatyötarjous-välilehti, jos tarvitset yhteyden työntekijään heti.",
};

const modalTranslations = {
	[ETranslation.MODAL_CONFIRMATION_INTERRUPT]:"Haluatko varmasti keskeyttää toimeksiannon?",
	[ETranslation.MODAL_ARE_YOU_SURE_CLEAR_INTERPRETATIONS]:"Haluatko varmasti tyhjentää toimeksiannon?",
	[ETranslation.MODAL_AMOUNT_OF_TRANSLATIONS]: " toimeksiannosta?",
	[ETranslation.MODAL_AMOUNT_ONE_TRANSLATION]: " toimeksiannokseen?",
};

const calendarTranslations = {
	[ETranslation.CALENDAR_MODAL_TITLE]: "Vapaat ja varatut työntekijät",
	[ETranslation.CALENDAR_NO_USERS_MESSAGE]:"Filttereillä ei löydetty työntekijöitä",
};

const cancelationFormTranslations = {};

const commentTranslations = {};

const commissionTranslations = {
	[ETranslation.COMMISSION_GENERAL]: "Kiinnostunut työntekijä",
	[ETranslation.COMMISSIONS_EVERYDAY]: "Asioimistyöntekijä",
	[ETranslation.COMMISSIONS_PROFESSIONAL]: "Ammattityöntekijä",
	[ETranslation.COMMISSIONS_ADDED_AS_INTERPRETER]: "Lisätty työntekijäksi",
};

const summaryTranslations = {
	[ETranslation.SUMMARY_GRADING_POINTS]: "Asiakkaiden antama keskiarvo toimeksiannoksestasi asteikolla 1-5 oli:",
};

const tagTranslations = {};

const reservedDateTranslations = {};

const orderTranslations = {};

const tasksTranslations = {};

const feesTranslations = {}

const valitysklinikkaFi = {
	...commonTranslations,
	...menuTranslations,
	...Base64DropzoneTranslations,
	...customDropZoneTranslations,
	...deleteModalTranslations,
	...infoContactRequestTranslations,
	...messageTranslations,
	...newsItemTranslations,
	...organizationGroupTranslations,
	...priceSeasonsTranslations,
	...siteTranslations,
	...textMessageTranslations,
	...uiTranslations,
	...pagesFileUploadTranslations,
	...pagesContactRequestTranslations,
	...pagesMessagesTranslations,
	...pagesNewsTranslations,
	...pagesOrganizationsTranslations,
	...organizationCompaniesTranslations,
	...pagesPriceSeasonsTranslations,
	...pagesPriceRulesTranslations,
	...pagesSiteTranslations,
	...pagesTextMessagesTranslations,
	...dataContactRequestTranslations,
	...dataPriceSeasonsTranslations,
	...pagesCustomerReportsTranslations,
	...storeTranslations,
	...appTranslations,
	...logItemTranslations,
	...interpretationTranslations,
	...interpretationInfoBoxTranslation,
	...interpretationDataTranslations,
	...interpretationProcessingTranslations,
	...videoTranslations,
	...interpretationReviewTranslations,
	...interpretationsTranslations,
	...infoTextTranslations,
	...modalTranslations,
	...calendarTranslations,
	...cancelationFormTranslations,
	...usersTranslations,
	...commentTranslations,
	...commissionTranslations,
	...summaryTranslations,
	...tagTranslations,
	...reservedDateTranslations,
	...orderTranslations,
	...tasksTranslations,
	...feesTranslations,
};

export default valitysklinikkaFi;
