import { IOption } from "../components/ui/Input/Input";
import { EReservedDateOption, EReservedDateType } from "../interfaces/IReservedDate";


export const RESERVED_DATE_TYPES: IOption[] = [
  { value: EReservedDateType.TIMERANGE, label: "Jatkuva" },
  { value: EReservedDateType.DAILY, label: "Toistuva" },
]

export const RESERVED_DATE_OPTIONS: IOption[] = [
  { value: EReservedDateOption.MONDAY, label: "Ma" },
  { value: EReservedDateOption.TUESDAY, label: "Ti" },
  { value: EReservedDateOption.WEDNESDAY, label: "Ke" },
  { value: EReservedDateOption.THURSDAY, label: "To" },
  { value: EReservedDateOption.FRIDAY, label: "Pe" },
  { value: EReservedDateOption.SATURDAY, label: "La" },
  { value: EReservedDateOption.SUNDAY, label: "Su" },
  { value: EReservedDateOption.NATIONAL_HOLIDAY, label: "Arkipyhä" },
]