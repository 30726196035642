import * as dateFns from 'date-fns';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCreateInput } from '../../hooks/useCreateInput';
import { IUserShiftDate } from '../../interfaces/IUserShiftDate';
import { IUserShiftDateItem } from '../../interfaces/IUserShiftDateItem';
import { IUserShiftMonth } from '../../interfaces/IUserShiftMonth';
import IAppState from '../../interfaces/store/IAppState';
import IUserShiftState from '../../interfaces/store/IUserShiftState';
import { EUserShiftDay, USER_SHIFT_DAY_OPTIONS } from '../../shared/user-shifts-data';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';
import { transformStartTimeEndTime } from '../../utils/date-utils';
import Alert from '../ui/Alert/Alert';
import EditButtons from '../ui/EditButtons/EditButtons';
import { EInputType, IInputField } from '../ui/Input/Input';
import { getInputData } from '../ui/Input/input-utils';
import InputGroup from '../ui/InputGroup/InputGroup';
import ModalBody from '../ui/Modal/ModalBody/ModalBody';
import ModalContext from '../ui/Modal/ModalContext';
import ModalFooter from '../ui/Modal/ModalFooter/ModalFooter';
import UserShiftDateItems, { IUserShiftDateItemsHandle } from './UserShiftDateItems';
import { useUserShiftDateInterpretationOptions } from './useUserShiftDateInterpretationOptions';

interface IProps {
	shift: IUserShiftMonth;
	date?: string;
}

export interface IUserShiftGenerateData {
	startDate: string;
	endDate: string;
	days: EUserShiftDay[];
	items: IUserShiftDateItem[];
	description: string;
	interpretationId: string;
}

enum EInputs {
	startDate = "startDate",
	endDate = "endDate",
	days = "days",
	interpretationId = 'interpretationId',
	description = "description",
}

const deriveInitStartDate = (year: number, month: number) => {
	return dateFns.formatISO(new Date(year, month - 1, 1), { representation: "date" });
};

const deriveInitEndDate = (year: number, month: number) => {
	return dateFns.formatISO(dateFns.lastDayOfMonth(new Date(year, month - 1, 1)), { representation: "date" });
};

const isValidDayOption = (days: EUserShiftDay[], date: Date) => {
	return (
		(days.includes(EUserShiftDay.MONDAY) && dateFns.isMonday(date)) ||
		(days.includes(EUserShiftDay.TUESDAY) && dateFns.isTuesday(date)) ||
		(days.includes(EUserShiftDay.WEDNESDAY) && dateFns.isWednesday(date)) ||
		(days.includes(EUserShiftDay.THURSDAY) && dateFns.isThursday(date)) ||
		(days.includes(EUserShiftDay.FRIDAY) && dateFns.isFriday(date)) ||
		(days.includes(EUserShiftDay.SATURDAY) && dateFns.isSaturday(date)) ||
		(days.includes(EUserShiftDay.SUNDAY) && dateFns.isSunday(date))
	);
};



const UserShiftDateCreateModal: React.FC<IProps> = ({ shift, date }) => {
	const itemsRef = useRef<IUserShiftDateItemsHandle>(null);
	const [showValidation, setShowValidation] = useState(false);
	const dispatch = useDispatch();
	const { closeModal } = useContext(ModalContext);

	const { saveDatesError, saveDatesOk, saveDatesLoading } = useSelector<IAppState, IUserShiftState>(
		(state) => state.userShifts
	);

	useEffect(() => {
		if (saveDatesOk) {
			dispatch(actions.saveUserShiftDatesClear());
			closeModal();
		}
	}, [saveDatesOk, closeModal, dispatch]);

	const { year, month } = shift;

	const initStartDate = deriveInitStartDate(year, month);
	const initEndDate = deriveInitEndDate(year, month);

	const { interpretationOptions, interpretationOptionsLoading } = useUserShiftDateInterpretationOptions(initStartDate, initEndDate, shift.userId);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.startDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_START_DATE,
			value: date ? date : initStartDate,
			min: initStartDate,
			max: initEndDate,
		},
		[EInputs.endDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_END_DATE,
			value: date ? date : initEndDate,
			min: initStartDate,
			max: initEndDate,
		},
		[EInputs.interpretationId]: {
			type: EInputType.reactSelect,
			label: "Toimeksianto",
			placeholder: "Valitse toimeksianto",
			value: "",
		},
		[EInputs.days]: {
			type: EInputType.checkbox,
			label: "Viikonpäivät",
			value: [
				EUserShiftDay.MONDAY,
				EUserShiftDay.TUESDAY,
				EUserShiftDay.WEDNESDAY,
				EUserShiftDay.THURSDAY,
				EUserShiftDay.FRIDAY,
				...(date ? [EUserShiftDay.SATURDAY, EUserShiftDay.SUNDAY] : []),
			],
			options: USER_SHIFT_DAY_OPTIONS,
		},
		[EInputs.description]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_ADDITIONAL_INFO,
			value: "",
		},
	});

	const createUserShiftDates = () => {
		const items = itemsRef.current?.getItems();
		if (items) {
			const data = getInputData<IUserShiftGenerateData>(inputs);
			let startDate = dateFns.parseISO(data.startDate);
			const endDate = dateFns.parseISO(data.endDate);

			const dates: IUserShiftDate[] = [];

			while (dateFns.isBefore(startDate, endDate) || dateFns.isEqual(startDate, endDate)) {
				if (isValidDayOption(data.days, startDate)) {
					const date = dateFns.formatISO(startDate, { representation: "date" });
					dates.push({
						date,
						items: items.map((item) => ({
							...item,
							...transformStartTimeEndTime(date, item.startTime, item.endTime),
							description: data.description,
							interpretationId: data.interpretationId,
						})),
					});
				}
				startDate = dateFns.addDays(startDate, 1);
			}
			return dates;
		}
	};



	const saveHandler = () => {
		const isValid = itemsRef.current?.isValid();
		if (isValid) {
			const dates = createUserShiftDates();
			if (dates) {
				dispatch(actions.saveUserShiftDates(shift.id, dates));
			}
		} else {
			setShowValidation(true);
		}
	};

	const createInput = useCreateInput(inputs, setInputs, {
		showValidation,
	});

	return (
		<>
			<ModalBody>
				<InputGroup>
					{createInput(EInputs.startDate)}
					{createInput(EInputs.endDate)}
				</InputGroup>
				{createInput(EInputs.days)}
				<UserShiftDateItems items={[]} showValidation={showValidation} ref={itemsRef} />
				{createInput(EInputs.interpretationId, { options: interpretationOptions, isLoading: interpretationOptionsLoading, containerStyles: { marginTop: '1rem' } })}
				{createInput(EInputs.description,)}
			</ModalBody>
			<ModalFooter>
				{saveDatesError && <Alert style={{ marginTop: 0 }}>{saveDatesError}</Alert>}
				<EditButtons onSave={saveHandler} loading={saveDatesLoading} onCancel={closeModal} isAdd={true} />
			</ModalFooter>
		</>
	);
};

export default UserShiftDateCreateModal;
