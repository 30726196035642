import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useCreateInput } from "../../hooks/useCreateInput";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useConfirmModal } from "../../hooks/useConfirmModal";
import { ILanguage } from "../../interfaces/ILanguage";
import IAppState from "../../interfaces/store/IAppState";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";
import Button, { EButtonColor, EButtonSize } from "../ui/Button/Button";
import { ECommonValue, EInputType, IInputField } from "../ui/Input/Input";
import {
	getInputData,
	initForm,
	validateInputs,
} from "../ui/Input/input-utils";
import ModalContext, { EModalSize } from "../ui/Modal/ModalContext";
import classes from "./Language.module.scss";
import LanguageTable from "./LanguageTable/LanguageTable";
import ToLanguagesModal from "./ToLanguagesModal/ToLanguagesModal";
import Alert from "../ui/Alert/Alert";
import Translations from "../Translations/Translations";
import { TranslationContext } from "../Translations/translation-context";
import ILanguageState from "../../interfaces/store/ILanguageState";
import { ITranslation } from "../../interfaces/ITranslation";

enum EInputs {
	languageCode = "languageCode",
	active = "active",
	name = "name",
	isTestLanguage = "isTestLanguage",
	nativeTranslation = "nativeTranslation",
}

interface IProps {
	onChange: (language: ILanguage, isValid: boolean) => void;
	language: ILanguage | null;
	loading?: boolean;
	showValidation?: boolean;
}

const Language: React.FC<IProps> = ({
	onChange,
	loading,
	language,
	showValidation,
}) => {
	const dispatch = useDispatch();
	const { setModal } = useContext(ModalContext);
	const { t } = useTranslation();
	const openConfirmModal = useConfirmModal();

	useEffect(() => {
		dispatch(actions.findLanguageCodes());
		dispatch(actions.listLanguages());
	}, [dispatch]);

	const {
		languageCodes,
		languageCodesLoading,
		languages,
		languagesLoading,
		linkError,
		unlinkError,
		deleteTranslationError,
		updateTranslationError,
		deleteTranslationLoading,
		updateTranslationLoading,
		id: actionId
	} = useSelector<IAppState, ILanguageState>(state => state.language);

	const languageCodesOptions = useMemo(
		() =>
			languageCodes
				?.filter(
					(code) =>
						code.languageCode === language?.languageCode ||
						!languages?.find(
							(lang) => lang.languageCode === code.languageCode
						)
				)
				.map((languageCode) => ({
					value: languageCode.languageCode,
					label: languageCode.name,
				})),
		[languageCodes, languages, language]
	);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.name]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_NAME,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.languageCode]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.LANGUAGE_LANGUAGE_CODE,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.active]: {
			type: EInputType.checkbox,
			labelTranslation: ETranslation.SITE_ACTIVE,
			value: [ECommonValue.YES],
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.isTestLanguage]: {
			type: EInputType.checkbox,
			labelTranslation: ETranslation.LANGUAGE_IS_TEST_LANGUAGE,
			value: [],
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.nativeTranslation]: {
			type: EInputType.text,
			labelTranslation: ETranslation.LANGUAGE_NATIVE_TRANSLATION,
			value: "",
		},
	});

	useEffect(() => {
		if (language) {
			initForm(setInputs, language);
		}
	}, [language]);

	useEffect(() => {
		const language = getInputData<ILanguage>(inputs);
		const isValid = validateInputs(inputs);
		onChange(language, isValid);
	}, [inputs, onChange, languages]);

	const createInput = useCreateInput(inputs, setInputs, {
		showValidation,
		disabled: loading,
	});

	const deleteToLanguageHandler = useCallback(
		async (languageCode: string, onClose?: () => void) => {
			if (!language) return;
			const isConfirm = await openConfirmModal(
				t(ETranslation.LANGUAGE_CONFIRM_DELETE)
			);
			if (!isConfirm) {
				if (onClose) onClose();
				return;
			}
			dispatch(
				actions.unlinkLanguages(language.languageCode, languageCode)
			);
			if (onClose) onClose();
		},
		[openConfirmModal, t, language, dispatch]
	);

	const editToLanguagesHandler = useCallback(() => {
		setModal({
			isOpen: true,
			content: (
				<ToLanguagesModal
					onDelete={(languageCode) =>
						deleteToLanguageHandler(
							languageCode,
							editToLanguagesHandler
						)
					}
				/>
			),
			size: EModalSize.MEDIUM,
			title: t(ETranslation.LANGUAGE_EDIT_LANGUAGES),
		});
	}, [t, setModal, deleteToLanguageHandler]);


	const deleteTranslationHandler = useCallback((langId: string, id: string) => {
			dispatch(actions.deleteLanguageTranslation(langId, id));
	}, [dispatch]);

	const saveTranslationHandler = useCallback((langCode: string, translation: ITranslation) => {
		dispatch(
			actions.updateLanguageTranslation(
				langCode,
				translation.languageCode,
				translation.name
			)
		);
	}, [dispatch]);

	return (
		<>
			{createInput(EInputs.name)}
			{createInput(EInputs.languageCode, {
				options: languageCodesOptions,
				isLoading: languageCodesLoading,
				disabled: !!language?.id,
			})}
			{createInput(EInputs.nativeTranslation)}
			<div style={{ display: "flex", gap: "1rem" }}>
				{createInput(EInputs.active, {
					confirmValueChange: async (value) => {
						if ((value as string[]).length === 0)
							return await openConfirmModal(
								t(ETranslation.LANGUAGE_ACTIVE_CONFIRM)
							);
						return true;
					},
				})}
				{createInput(EInputs.isTestLanguage)}
			</div>
			{language?.id && (
				<>
					<div>
						<div className={classes.TableHeader}>
							<h2>{t(ETranslation.LANGUAGE_TO_LANGUAGES)}</h2>
							<Button
								onClick={editToLanguagesHandler}
								loading={languagesLoading}
								color={EButtonColor.PRIMARY}
								size={EButtonSize.SMALL}
							>
								{t(ETranslation.LANGUAGE_EDIT_LANGUAGES)}
							</Button>
						</div>
						{linkError && <Alert>{linkError}</Alert>}
						{unlinkError && <Alert>{unlinkError}</Alert>}
						<LanguageTable
							toLanguages={language?.toLanguages ?? []}
							onDelete={deleteToLanguageHandler}
						/>
					</div>
					{/* Contextin vois poistaa ja käyttää noissa niitä modal context?! Ehkä voiskin olla uiActions !? */}
					<TranslationContext.Provider value={{
						isDeleting: deleteTranslationLoading,
						isUpdating: updateTranslationLoading,
						actionId
					}}>
						<Translations
							translations={language.languageTranslations ?? []}
							onDelete={(id) => deleteTranslationHandler(language.id, id)}
							onSave={(translation) => saveTranslationHandler(language.languageCode, translation)}
							isLoading={languagesLoading}
							error={deleteTranslationError || updateTranslationError}
						/>
					</TranslationContext.Provider>
				</>
			)}
			<br />
		</>
	);
};

export default Language;
