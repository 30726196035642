import React from 'react';
import { useDispatch, useSelector} from 'react-redux';
import * as actions from "../../../store/actions";
import Button from '../../../components/ui/Button/Button';
import IInterpretationState from '../../../interfaces/store/IInterpretationState';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';
import IAppState from '../../../interfaces/store/IAppState';
import Spinner from '../../../components/ui/Spinner/Spinner';


interface IProps {
	interpretation: IInterpretationState["interpretation"];
	customer: boolean;
}

const NewsItemCreateReview: React.FC<IProps> = ({ customer, interpretation}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	
	const { loading, saveReviewOK} = useSelector(
		(state: IAppState) => ({
		  error: state.newsItem.newsItemSaveReviewError,
		  loading: state.newsItem.newsItemSaveReviewLoading,
		  saveReviewOK: state.newsItem.newsItemSaveReviewOK
		})
	  );
	const openHandler = () => {
		dispatch(actions.newsItemSaveReview(interpretation?.id ?? "", customer))
	}
	return (
		<div>
		  {loading ? (
			<div>
			  <Spinner />
			</div>
		  ) : saveReviewOK ? (
			<div>
			  {t(ETranslation.NEWS_REVIEW_PUBLISH_DONE)}
			</div>
		  ) : (
			<div>
			  {t(ETranslation.NEWS_REVIEW_PUBLISH_TEXT)}
			  <div style={{ display: "block", marginTop: "30px" }}>
				<Button onClick={openHandler}>
				  {t(ETranslation.NEWS_MAKE_REVIEW_BUTTON)}
				</Button>
			  </div>
			</div>
		  )}
		</div>
	  );
	};
export default NewsItemCreateReview;