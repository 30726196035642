import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute, Routes } from '../../../classes/Routes';
import Button from '../../../components/ui/Button/Button';
import Container from '../../../components/ui/Container/Container';
import IAppState from '../../../interfaces/store/IAppState';
import * as actions from '../../../store/actions';
import TextMessageList from '../../../components/TextMessage/TextMessageList/TextMessageList';
import Heading from '../../../components/Heading/Heading';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';

interface IProps extends RouteComponentProps {}

const TextMessagesListPage: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { textMessages, loading, error } = useSelector(
    (state: IAppState) => ({
      textMessages: state.textMessage.textMessages,
      loading: state.textMessage.textMessagesLoading,
      error: state.textMessage.textMessagesError,
    })
  );

  useEffect(() => {
    dispatch(actions.findTextMessages());
  }, [dispatch]);

  const addHandler = () => {
    openHandler("add");
  };

  const openHandler = (id?: string) => {
    if (!id) return;
    history.push(Routes.withParams(ERoute.TEXT_MESSAGE_EDIT, { id }));
  };

  return (
    <Container>
      <Heading>{t(ETranslation.PAGES_TEXTMESSAGES_LIST_TITLE)}</Heading>
      <Button onClick={addHandler}>{t(ETranslation.PAGES_TEXTMESSAGES_LIST_ADD_NEW)}</Button>
      <TextMessageList
        textMessages={textMessages}
        loading={loading}
        error={error}
        onClick={openHandler}
      />
    </Container>
  );
};

export default TextMessagesListPage;