import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IInterpretation, IInterpretationReview } from "../../../interfaces/IInterpretation";
import { ETranslation } from "../../../translations/translation-keys";
import Button from "../../ui/Button/Button";
import Container from "../../ui/Container/Container";
import {
  EInputType,
  IInputField,
} from "../../ui/Input/Input";
import {
  getInputData,
  initForm,
  validateInputs,
} from "../../ui/Input/input-utils";
import InputGroup from "../../ui/InputGroup/InputGroup";
import * as actions from "../../../store/actions";
import IAppState from "../../../interfaces/store/IAppState";
import IAuthState from "../../../interfaces/store/IAuthState";
import Alert from "../../ui/Alert/Alert";
import { useTranslation } from "react-i18next";
import { useCreateInput } from "../../../hooks/useCreateInput";

enum EInputs {
  rating = "rating",
  reviewText = "reviewText",
  customerNotes = "customerNotes",
  customerReference = "customerReference",
  customerReference2 = "customerReference2",
}

interface IProps {
  onChange: (interpretationReview: IInterpretationReview, isValid: boolean) => void;
  interpretation: IInterpretation | null;
  loading?: boolean;
  showValidation?: boolean;
}

const InterpretationReview: React.FC<IProps> = ({
  onChange,
  loading,
  interpretation,
  showValidation,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { error } = useSelector((state: IAppState) => ({
    error:state.interpretation.addFavoriteInterpreterError || state.interpretation.interpretationReviewError,
  }));
  const [interpreterIsFavorite, setInterpreterIsFavorite] = useState(false);

  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.rating]: {
      type: EInputType.star,
      labelTranslation: ETranslation.INTERPRETATION_RATING,
      placeholderTranslation: ETranslation.INTERPRETATION_RATING,
      value: "",
      options: [
        { value: "1" },
        { value: "2" },
        { value: "3" },
        { value: "4" },
        { value: "5" },
      ],
    },
    [EInputs.reviewText]: {
      type: EInputType.textarea,
      placeholderTranslation: ETranslation.INTERPRETATION_REVIEW_TEXT,
      value: "",
    },
    [EInputs.customerNotes]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.INTERPRETATION_CUSTOMER_NOTES,
      value: "",
    },
    [EInputs.customerReference]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.COMMON_CUSTOMER_REFERENCE,
      value: "",
    },
    [EInputs.customerReference2]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.COMMON_CUSTOMER_REFERENCE_2,
      value: "",
    },
  });

  useEffect(() => {
    if (interpretation) {
      initForm(setInputs, interpretation);
    }
  }, [interpretation]);

  useEffect(() => {
    const interpretationReview = getInputData<IInterpretationReview>(inputs);
    const isValid = validateInputs(inputs);
    onChange(interpretationReview, isValid);
  }, [inputs, onChange]);

  const addFavoriteInterpreter = () => {
    if (!interpreter) return;
    dispatch(actions.addFavoriteInterpreter(interpreter));
    if (!error) {
      setInterpreterIsFavorite(true);
    }
  };
  const { user } = useSelector<IAppState, IAuthState>((state) => state.auth);
  const interpreter = interpretation?.interpreter;

  useEffect(() => {
    if (!interpreter) return;
    if (interpreter.isFavorite && !error) {
      setInterpreterIsFavorite(true);
    }
  }, [error, interpreter]);

  const rating = inputs[EInputs.rating].value as String;

  const createInput = useCreateInput(inputs, setInputs, {
    showValidation,
    disabled: loading
  });

  return (
    <Container>
      {error && <Alert>{error}</Alert>}
      <InputGroup>{createInput(EInputs.rating)}</InputGroup>
      {rating && <InputGroup>{createInput(EInputs.reviewText)}</InputGroup>}
      {interpreter && (
        <InputGroup>
          <div>{t(ETranslation.INTERPRETATION_REVIEW_INTERPRETER)} {interpretation?.interpreter?.name}</div>
        </InputGroup>
      )}
      {user?.allowFavoriteInterpreters &&
      !interpreter?.isFavorite &&
      !interpreterIsFavorite ? (
        <InputGroup>
          <div>
            <Button onClick={addFavoriteInterpreter}>
              {t(ETranslation.INTERPRETATION_REVIEW_ADD_FAVORITE_INTERPRETER)}
            </Button>
          </div>
        </InputGroup>
      ) : (
        !error && (
          <InputGroup>
            <div>
              <small>{t(ETranslation.INTERPRETATION_REVIEW_INTERPRETER_IS_FAVORITE)}</small>
            </div>
          </InputGroup>
        )
      )}
      <br />
      <InputGroup>{createInput(EInputs.customerNotes)}</InputGroup>
      <InputGroup>{createInput(EInputs.customerReference)}</InputGroup>
    </Container>
  );
};

export default InterpretationReview;
