import React from 'react';
import Button, { EButtonColor } from '../../../ui/Button/Button';
import classes from '../InterpretationsSearch.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { IInputField } from '../../../ui/Input/Input';
import { getInputData } from '../../../ui/Input/input-utils';
import { EInterpretationSearchInputs, IInterpretationSearchInputInterface } from '../InterpretationsSearch';
import * as dateFns from 'date-fns';
import InputGroup from '../../../ui/InputGroup/InputGroup';
import SearchMonthSelector from './SearchMonthSelector';


interface IProps {
	setInputs: React.Dispatch<React.SetStateAction<IInputField>>
	inputs: IInputField;
}

const today = dateFns.format(
	new Date(),
	"yyyy-MM-dd"
);

export const defaultStartDate = dateFns.format(
	dateFns.startOfYear(new Date()),
	"yyyy-MM-dd"
);

export const defaultEndDate = dateFns.format(
	dateFns.endOfYear(new Date()),
	"yyyy-MM-dd"
);

const SearchDateTools: React.FC<IProps> = ({ setInputs, inputs }) => {

	const setYesterday = () => {
		const data = getInputData<IInterpretationSearchInputInterface>(inputs);
		const endNextDay = dateFns.format(dateFns.subDays(dateFns.parse(data.endDate, "dd.MM.yyyy", new Date()), 1), "yyyy-MM-dd");
		const startNextDay = dateFns.format(dateFns.subDays(dateFns.parse(data.startDate, "dd.MM.yyyy", new Date()), 1), "yyyy-MM-dd");
		setInputs({
			...inputs,
			[EInterpretationSearchInputs.endDate]: {
				...inputs.endDate,
				value: endNextDay,
			},
			[EInterpretationSearchInputs.startDate]: {
				...inputs.startDate,
				value: startNextDay,
			},
		});
	}

	const setTomorrow = () => {
		const data = getInputData<IInterpretationSearchInputInterface>(inputs);
		const endNextDay = dateFns.format(dateFns.add(dateFns.parse(data.endDate, "dd.MM.yyyy", new Date()), { days: 1 }), "yyyy-MM-dd");
		const startNextDay = dateFns.format(dateFns.add(dateFns.parse(data.startDate, "dd.MM.yyyy", new Date()), { days: 1 }), "yyyy-MM-dd");
		setInputs({
			...inputs,
			[EInterpretationSearchInputs.endDate]: {
				...inputs.endDate,
				value: endNextDay,
			},
			[EInterpretationSearchInputs.startDate]: {
				...inputs.startDate,
				value: startNextDay,
			},
		});
	}

	const setToday = () => {
		setInputs({
			...inputs,
			[EInterpretationSearchInputs.endDate]: {
				...inputs.endDate,
				value: today,
			},
			[EInterpretationSearchInputs.startDate]: {
				...inputs.startDate,
				value: today,
			},
			[EInterpretationSearchInputs.startTime]: {
				...inputs.startTime,
				value: "00:00",
			},
			[EInterpretationSearchInputs.endTime]: {
				...inputs.endTime,
				value: "23:59",
			},
		});
	}

	const setThisMonth = () => {
		setInputs({
			...inputs,
			[EInterpretationSearchInputs.endDate]: {
				...inputs.endDate,
				value: dateFns.format(dateFns.lastDayOfMonth(new Date()), 'yyyy-MM-dd'),
			},
			[EInterpretationSearchInputs.startDate]: {
				...inputs.startDate,
				value: dateFns.format(new Date(), 'yyyy-MM-01'),
			},
			[EInterpretationSearchInputs.startTime]: {
				...inputs.startTime,
				value: "00:00",
			},
			[EInterpretationSearchInputs.endTime]: {
				...inputs.endTime,
				value: "23:59",
			},
		});
	}
	const resetDates = () => {
		setInputs({
			...inputs,
			[EInterpretationSearchInputs.endDate]: {
				...inputs.endDate,
				value: defaultEndDate,
			},
			[EInterpretationSearchInputs.startDate]: {
				...inputs.startDate,
				value: defaultStartDate,
			},
			[EInterpretationSearchInputs.startTime]: {
				...inputs.startTime,
				value: "00:00",
			},
			[EInterpretationSearchInputs.endTime]: {
				...inputs.endTime,
				value: "23:59",
			},
		});
	}

	return (
		<>
			<InputGroup className={classes.sectionNoTitle}>
				<Button className={classes.searchMargin} color={EButtonColor.WHITE} onClick={setYesterday}><FontAwesomeIcon icon={faChevronLeft} /></Button>
				<Button className={classes.searchMargin} color={EButtonColor.WHITE} onClick={setToday}>Tänään</Button>
				<Button className={classes.searchMargin} color={EButtonColor.WHITE} onClick={setTomorrow}><FontAwesomeIcon icon={faChevronRight} /></Button>
				<Button className={classes.searchMargin} color={EButtonColor.WHITE} onClick={setThisMonth}>Tämä kuu</Button>
				<Button className={classes.searchMargin} color={EButtonColor.WHITE} onClick={resetDates}>Nollaa</Button>
			</InputGroup>
			<SearchMonthSelector setInputs={setInputs} inputs={inputs} />
		</>
	)
}

export default SearchDateTools;