import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../components/ui/Container/Container";
import Heading from "../../components/Heading/Heading";
import { ETranslation } from "../../translations/translation-keys";
import { RouteComponentProps } from "react-router-dom";
import CommentsSearch from "./CommentsSearch";
import CommentsList from "./CommentsList";


interface IProps extends RouteComponentProps { }

const CommentsPage: React.FC<IProps> = ({ history }) => {

	const { t } = useTranslation();

	return (
		<Container>
			<Heading>{t(ETranslation.MENU_COMMENTS)}</Heading>
			<CommentsSearch />
			<CommentsList />
		</Container>
	)
}

export default CommentsPage;