import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Button, { EButtonColor, EButtonSize } from "../../ui/Button/Button";
import Input, { EInputType } from "../../ui/Input/Input";

import classes from "./TwilioChatInput.module.scss";

interface IProps {
  onMessage: (message: string) => void;
  isConnected: boolean;
}

const TwilioChatInput: React.FC<IProps> = ({ onMessage, isConnected }) => {
  const [message, setMessage] = useState("");

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();

    if (message.length === 0) {
      return;
    }

    onMessage(message);
    setMessage("");
  };

  return (
    <form onSubmit={(e) => submitHandler(e)} className={classes.Container}>
      <Input
        inputName="chat-message"
        onChange={(value) => setMessage(value as string)}
        type={EInputType.text}
        value={message}
        placeholder="Viesti"
        weight={1}
        containerStyles={{ margin: '0 .5rem 0 0'}}
      />
      <Button
        onClick={submitHandler}
        color={EButtonColor.PRIMARY}
        size={EButtonSize.SMALL}
        disabled={!isConnected || message.length === 0}
      >
        <FontAwesomeIcon icon={faPaperPlane} />
      </Button>
    </form>
  );
};

export default TwilioChatInput;
