import React, { useContext, useEffect, useState } from "react";
import { IInterpretation } from "../../../../interfaces/IInterpretation";
import Button from "../../../ui/Button/Button";
import Alert, { EAlertColor } from "../../../ui/Alert/Alert";
import ConfirmationModal from "../../../ConfirmationModal/ConfirmationModal";
import ModalContext, { EModalSize } from "../../../ui/Modal/ModalContext";
import { useDispatch, useSelector } from "react-redux";
import { confirmationEmailReset, confirmationEmailSend, resetInterpretation, resetInterpretationClear, sendFeedBackSurvey } from "../../../../store/actions/interpretationActions";
import InterpretationConfirmationEmailModal from "../../InterpretationSendConfirmationEmailsModal/InterpretationConfirmationEmailModal";
import classes from "./interpretationAction.module.scss"
import { EInterpretationStatus } from "../../../../shared/interpretation-data";
import Spinner from "../../../ui/Spinner/Spinner";
import IAppState from "../../../../interfaces/store/IAppState";
import InterpretationFeedbackSurvey from "../InterpretationFeedbackSurvey/InterpretationFeedbackSurvey";
import { ETranslation } from "../../../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import { useLanguageName } from "../../../../hooks/useLanguageName";


interface Iprops {
	interpretation: IInterpretation;
	model: "LIST" | "INTERPRETATION"
}

const InterpretationActions: React.FC<Iprops> = ({ interpretation, model }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation()
	const fromLanguage  = useLanguageName(interpretation.fromLanguage);
	const toLanguage  = useLanguageName(interpretation.toLanguage);

	const { setModal, closeModal } = useContext(ModalContext);

	const startDateTime = interpretation.bookingStartDateTime ?? interpretation.startDateTime

	const canSendMails: boolean = !(interpretation.status === EInterpretationStatus.CONFIRMED_BOOKING
		|| interpretation.status === EInterpretationStatus.CUSTOMER_CANCEL);


	const resetModal = (payment: boolean) => {
		setModal({
			isOpen: true,
			size: EModalSize.EXTRASMALL,
			content: (
				<ConfirmationModal
					text={"Haluatko varmasti nollata tulkkauksen " + (payment ? "veloituksen kanssa" : "ilman veloitusta")}
					onConfirm={() => {
						dispatch(resetInterpretation(payment, interpretation.id))
						closeModal()
					}}
				/>
			),
		});
	}

	const sendFeedbackSureveyCustomer = () => {
		const feedbacktext = `Hei!

Kiitos paljon palautteestasi koskien tulkkausta ${startDateTime} kieliparille ${fromLanguage.languageName} - ${toLanguage.languageName}.

Huomasimme järjestelmästä, että olit antanut kyseiselle tulkkaukselle arvioinniksi ${interpretation.interpretationReviews?.find((review) => review.userRole === "CUSTOMER")?.rating ?? ""} tähteä.
Haluaisimme kysyä, toimiko tulkki ${interpretation.interpreter?.name ?? ""} virheellisesti tulkkaustilanteessa tai koskeeko palaute muuta asiaa palvelussamme?

Olemme yhteydessä tulkkiin ja varmistamme hänen osaamistasonsa sekä keskustelemme aiheesta.
Aloitamme tarkemman käsittelyn heti, kun olemme saaneet vastauksesi. Kiitos vielä paljon palautteesta, nämä ovat tärkeitä yrityksemme laadunhallinnan kannalta.

Ystävällisin terveisin,`
		setModal({
			isOpen: true,
			size: EModalSize.SMALL,
			title: t(ETranslation.INTREPRETATION_FEEDBACK_CONFIRM),
			content: (
				<InterpretationFeedbackSurvey
					emails={interpretation.customer.email}
					defaultBody={feedbacktext}
					onConfirm={(emails, body) => {
						dispatch(sendFeedBackSurvey(emails, body, interpretation.id))
						closeModal()
					}}
					cancelHandler={() => {
						closeModal();
					}}
				/>
			),
		});
	}

	const sendFeedbackSureveyWorker = () => {
		const body = `Hei, kiitos yhteistyöstä.

Miten sinun tulkkaukset on mennyt tähän asti?

Olemme valitettavasti saaneet palautetta tulkkauksestasi ${startDateTime}, ${fromLanguage.languageName} - ${toLanguage.languageName}.
[Palaute: ${interpretation.interpretationReviews?.find((review) => review.userRole === "CUSTOMER")?.reviewText ?? ""} / Tähdet: ${interpretation.interpretationReviews?.find((review) => review.userRole === "CUSTOMER")?.rating ?? ""}]

Mitä mieltä olet itse tästä palautteesta/arvioinnista? Kuulisimme mielellämme sinun näkemyksen tulkkauksesta.

Ystävällisin terveisin,`
		setModal({
			isOpen: true,
			size: EModalSize.SMALL,
			title: t(ETranslation.INTREPRETATION_FEEDBACK_CONFIRM),
			content: (
				<InterpretationFeedbackSurvey
					emails={interpretation.interpreter?.email ?? ""}
					defaultBody={body}
					onConfirm={(emails, body) => {
						dispatch(sendFeedBackSurvey(emails, body, interpretation.id))
						closeModal()
					}}
					cancelHandler={() => {
						closeModal();
					}}
				/>
			),
		});
	}

	const sendConfirmationEmails = (updated: boolean) => {
		setModal({
			isOpen: true,
			size: EModalSize.SMALL,
			title: "Lähetä tilausvahvistus",
			content: (
				<InterpretationConfirmationEmailModal
					onConfirm={(emails) => {
						dispatch(confirmationEmailSend(interpretation.id, emails, updated))
						closeModal();
					}} emails={interpretation.customer?.email}
				/>
			),
		})
	}

	const { resetLoading, resetError, resetDone, confirmationEmailDone, confirmationEmailLoading, confirmationEmailError, sendFeedbackSurveyLoading, sendFeedbackSurveyError, sendFeedbackSurveyDone } = useSelector((state: IAppState) => state.interpretation);

	const [actionLoading, setActionLoading] = useState(false);
	const [actionError, setActionError] = useState<string | null>(null);
	const [actionDone, setActionDone] = useState<boolean>(false);
	const [showActionTooltip, setShowActionTooltip] = useState(false);
	useEffect(() => {
		setActionLoading((resetLoading || confirmationEmailLoading || sendFeedbackSurveyLoading));
		setShowActionTooltip(resetLoading || resetError !== null || resetDone || confirmationEmailDone || confirmationEmailLoading || confirmationEmailError !== null || sendFeedbackSurveyLoading || sendFeedbackSurveyDone || sendFeedbackSurveyError !== null);
		setActionError(confirmationEmailError !== null ? confirmationEmailError : resetError !== null ? resetError : sendFeedbackSurveyError !== null ? sendFeedbackSurveyError : null);
		setActionDone((resetDone || confirmationEmailDone || sendFeedbackSurveyDone));
	}, [resetLoading, resetError, resetDone, confirmationEmailDone, confirmationEmailLoading, confirmationEmailError, sendFeedbackSurveyLoading, sendFeedbackSurveyError, sendFeedbackSurveyDone])

	const resetActions = () => {
		(resetError || resetDone) ? dispatch(resetInterpretationClear()) : (confirmationEmailDone || confirmationEmailError) ? dispatch(confirmationEmailReset()) : void (null);
	}

	// TODO(Joonas): Fix this shit with dependencies.
	// Also fix the loading state bubblegum above this
	useEffect(()=>{
		if(actionLoading) return;
		if(!showActionTooltip) return;
		if(!actionLoading && showActionTooltip){
			setTimeout(()=>{
				resetActions();
			},2000)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[actionLoading, showActionTooltip ])

	/* const { t } = useTranslation(); */
	return <div className={model === "LIST" ? classes.list : classes.interpretation}>
		<Button disabled={showActionTooltip}
			className={classes.openButton}
			onClick={() => resetModal(false)}>
			Nollaa ei veloitusta
		</Button>

		<Button disabled={showActionTooltip}
			className={classes.openButton}
			onClick={() => resetModal(true)}>
			Nollaa veloitetaan
		</Button>

		<Button
			disabled={canSendMails || showActionTooltip}
			className={classes.openButton}
			onClick={() => sendConfirmationEmails(false)} >
			lähetä tilausvahvistus
		</Button>
		<Button
			disabled={canSendMails || showActionTooltip}
			className={classes.openButton}
			onClick={() => sendConfirmationEmails(true)} >
			lähetä päivitetty tilausvahvistus
		</Button>
		<Button
			disabled={!interpretation.interpreter}
			className={classes.openButton}
			onClick={() => sendFeedbackSureveyWorker()} >
			{t(ETranslation.INTREPRETATION_FEEDBACK_SEND_TO_WORKER)}
		</Button>
		<Button
			disabled={!interpretation.customer}
			className={classes.openButton}
			onClick={() => sendFeedbackSureveyCustomer()} >
			{t(ETranslation.INTREPRETATION_FEEDBACK_SEND_TO_CUSTOMER)}
		</Button>
		{showActionTooltip &&
			<Alert
				color={actionError ? undefined : actionLoading ? EAlertColor.INFO : actionDone ? EAlertColor.SUCCESS : undefined}
				onClose={!actionLoading ? () => { resetActions(); } : undefined}
				children={actionLoading ? <Spinner /> : actionError ? <p>{actionError}</p> : actionDone ? "Done" : "Something went wrong"} />
		}
	</div>
}

export default InterpretationActions;