import React from "react";
import classes from "../userStyles.module.scss";
import Button from "../../ui/Button/Button";

import * as dateFns from 'date-fns';
import UserLanguageItem, { ILanguageItem } from "./UserLanguageItem";


interface ILanguageItemsProps {
	items: ILanguageItem[];
	onUpdate: (value: React.SetStateAction<ILanguageItem[]>) => void
	onDelete: (index: number) => void
}

const UserLanguageItemsList: React.FC<ILanguageItemsProps> = ({ items, onUpdate, onDelete }) => {

	const addNew = () => {
		onUpdate([
			...items,
			{
				descriptionText: "",
				language: "",
				updated: dateFns.format(new Date(), "dd.MM.yyyy HH:mm:ss")
			}
		])
	}

	const onItemUpdate = (item: ILanguageItem, key: number) => {
		const updatedLanguageItems = items.map((languageItem, mapKey) => {
			if (mapKey === key) {
				return item;
			} else {
				return languageItem;
			}
		})
		onUpdate(updatedLanguageItems);
	};

	return <>
		<Button onClick={addNew}>Lisää kieli</Button>
		{items.map((item, key) => {
			return <div key={item.language + key} className={classes.FlexColumn}>
				<UserLanguageItem item={item} onUpdate={(item) => onItemUpdate(item, key)} onDelete={() => onDelete(key)} />
			</div>
		})}
	</>
}

export default UserLanguageItemsList;