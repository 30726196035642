import React from "react";
import {
	EReservedDateType,
	IReservedDate,
} from "../../../../interfaces/IReservedDate";
import {
	formatDate,
	formatDateTime,
	formatTime,
} from "../../../../utils/date-utils";
import Button, { EButtonColor, EButtonSize } from "../../../ui/Button/Button";
import { ETranslation } from "../../../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import IAppState from "../../../../interfaces/store/IAppState";
import IReservedDateState from "../../../../interfaces/store/IReservedDateState";
import { useSingleOptionLabel } from "../../../../hooks/useSingleOptionLabel";
import {
	RESERVED_DATE_OPTIONS,
	RESERVED_DATE_TYPES,
} from "../../../../shared/reserved-date-data";
import { useMultiOptionLabel } from "../../../../hooks/useMultiOptionLabel";

interface IProps {
	reservedDate: IReservedDate;
	onDelete: (id: string) => void;
}

const ReservedDateListItem: React.FC<IProps> = ({ reservedDate, onDelete }) => {
	const { t } = useTranslation();

	const { deleteLoading, actionId } = useSelector<
		IAppState,
		IReservedDateState
	>((state) => state.reservedDate);

	const type = useSingleOptionLabel(RESERVED_DATE_TYPES, reservedDate.type);
	const options = useMultiOptionLabel(
		RESERVED_DATE_OPTIONS,
		reservedDate.options
	);

	return (
		<tr key={reservedDate.id}>
			<td>{type}</td>
			<td>{reservedDate.userName}</td>
			<td>
				{reservedDate.type === EReservedDateType.TIMERANGE ? (
					<>
						{formatDateTime(reservedDate.startDate)} -{" "}
						{formatDateTime(reservedDate.endDate)}
					</>
				) : reservedDate.type === EReservedDateType.DAILY ? (
					<>
						{formatDate(reservedDate.startDate)} -{" "}
						{formatDate(reservedDate.endDate)}{" "}
						{formatTime(reservedDate.startTime)} -{" "}
						{formatTime(reservedDate.endTime)}
					</>
				) : null}
			</td>
			<td>{options?.join(", ")}</td>
			<td style={{ whiteSpace: "pre-wrap" }}>
				{reservedDate.description}
			</td>
			<td>
				<Button
					size={EButtonSize.SMALL}
					color={EButtonColor.DANGER}
					loading={deleteLoading && actionId === reservedDate.id}
					onClick={() => onDelete(reservedDate.id)}
				>
					{t(ETranslation.COMMON_DELETE)}
				</Button>
			</td>
		</tr>
	);
};

export default ReservedDateListItem;
