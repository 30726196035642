import React from "react";
import { TInputValue } from "../Input/Input";
import ReactDatePicker from "react-datepicker";
import * as dateFns from 'date-fns';
import { fi } from "date-fns/locale";

interface IDatePickerProps {
	className: string;
	onChange: (value: TInputValue) => void;
	onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
	isClearable: boolean;
	placeholderText: string;
	disabled: boolean;
	wrapperClassName: string;
	value: string | number | false | Date | null | undefined;
}

const defaultYear = "2023";
const defaultDay = "01";
const defaultMonth = "01";

const DatePicker: React.FC<IDatePickerProps> = ({ className, value, onChange, onBlur, isClearable, placeholderText, disabled, wrapperClassName }) => {


	// This function parses 
	const dateParseFromString = (dateStr: string) =>{
		const rgx = /^(\d{1,2})?(\.*)?(\d{1,2})?(\.*)?(\d{1,4})?(.*)/gm;
		const value = dateStr.replace(rgx, (_match, p1, p2, p3, p4, p5) => {
			const year = (p5 && parseFloat(p5) !== 0) && p5.length > 3 ? p5 : defaultYear;
			const month = (p3 && parseFloat(p3) !== 0) ? p3.length > 1 ? parseFloat(p3) > 12 ? "12" : p3 : "0" + p3 : defaultMonth;
			const lastDayOfMonth = dateFns.lastDayOfMonth(dateFns.parse(`01.${month}.${year}`, "dd.MM.yyyy", new Date())).getDate();
			let day = (p1 && parseFloat(p1) !== 0) ? p1.length > 1 ? parseFloat(p1) > 31 ? "31" : p1 : "0" + p1 : defaultDay;
			if (parseFloat(day) > lastDayOfMonth) {
				day = lastDayOfMonth
			}
			return `${day}.${month}.${year}`
		});
		return value;
	}

	return (
		<ReactDatePicker
			className={className}
			selected={value as Date}
			onChange={(date, e) => {
				if (e?.defaultPrevented) return;
				onChange(date);
			}}
			onChangeRaw={(event) => {
				if (!event.target.value) return
				event.defaultPrevented = true;
				const date = dateFns.parse(dateParseFromString(event.target.value), "dd.MM.yyyy", new Date());
				onChange(date);
			}}
			onBlur={onBlur}
			locale={fi}
			dateFormat="dd.MM.yyyy"
			isClearable={isClearable}
			placeholderText={placeholderText}
			disabled={disabled}
			wrapperClassName={wrapperClassName}
		/>
	);
}

export default DatePicker;