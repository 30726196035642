import { Dispatch } from 'redux';

import EActionTypes from '../../interfaces/store/EActionTypes';
import { IUser } from '../../classes/User';
import { EFetchMethod, customFetch, customFetchWithResponse } from '../../custom-fetch';
import IUserAction from '../../interfaces/store/IUserAction';
import { IPaginationOptions } from '../../hooks/userPagination';
import { IUserContractSettings } from '../../interfaces/IUserContractSettings';

type TAction = IUserAction;

const getUserStart = (): TAction => {
	return {
		type: EActionTypes.USER_GET_USER_START
	};
};

const saveUserStart = (): TAction => {
	return {
		type: EActionTypes.USER_SAVE_USER_START
	};
};

const saveUserSuccess = (): TAction => {
	return {
		type: EActionTypes.USER_SAVE_USER_SUCCESS
	};
};

const saveUserError = (error: string): TAction => {
	return {
		type: EActionTypes.USER_SAVE_USER_ERROR,
		error
	};
};

const getUsersStart = (): TAction => {
	return {
		type: EActionTypes.USER_GET_LIST_START
	};
};

const getUserSuccess = (user: IUser): TAction => {
	return {
		type: EActionTypes.USER_GET_USER_SUCCESS,
		user,
	};
};

const getUsersSuccess = (users: IUser[], paginationCursor: string): TAction => {
	return {
		type: EActionTypes.USER_GET_LIST_SUCCESS,
		paginationCursor: paginationCursor,
		users
	};
};

const getUserFail = (error: string): TAction => {
	return {
		type: EActionTypes.USER_GET_USER_FAIL,
		error
	}
};

const getUsersFail = (error: string): TAction => {
	return {
		type: EActionTypes.USER_GET_LIST_FAIL,
		error
	};
};

export const getUserClear = (): TAction => {
	return {
		type: EActionTypes.USER_GET_USER_CLEAR,
	}
};

export const getUsersClear = (): TAction => {
	return {
		type: EActionTypes.USER_GET_LIST_CLEAR,
	};
};


const resetSendPassowrdStart = (): TAction => {
	return {
		type: EActionTypes.USER_RESET_SEND_PASSWORD_START,
	}
}

const resetSendPassowrdSuccess = (newPass: string): TAction => {
	return {
		type: EActionTypes.USER_RESET_SEND_PASSWORD_SUCCESS,
		message: newPass,
	}
}

const resetSendPassowrdError = (): TAction => {
	return {
		type: EActionTypes.USER_RESET_SEND_PASSWORD_ERROR,
	}
}

export const resetSendPassowrdClear = (): TAction => {
	return {
		type: EActionTypes.USER_RESET_SEND_PASSWORD_CLEAR,
	}
}
export const sendNewPassword = (user: IUser) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(resetSendPassowrdStart());
			var requestData = { "id": user.id };
			customFetchWithResponse("/users/generatepassword", EFetchMethod.POST, JSON.stringify(requestData)).then((result) => {
				result.json().then((result) => {
					dispatch(resetSendPassowrdSuccess(result.password));
				});
			}).catch((err) => {
				dispatch(resetSendPassowrdError());
				console.error(err);
			})
		} catch (err) {
			dispatch(resetSendPassowrdError());
			console.error(err)
		}
	}
}

export const searchUsers = (params: IPaginationOptions) => {
	return async (dispatch: Dispatch) => {
		dispatch(getUsersStart())
		// TODO normalize
		const fetchBody = {
			fetchSize: params.fetchSize,
			id: params.user,
			paginationCursor: params.paginationCursor,
			start: params.start,
			organization: params.organization,
			fetchDeleted: params.fetchDeleted ?? false,
			status: params.status ?? null,
		}
		try {
			// Ompas kyllä räkästy bäkkärille... workaround että saa tän nyt nappiin ja testiin, pitää korjata...
			const dataString = "&data=" + JSON.stringify(fetchBody)
			const urlParams = "?role=" + params.type + (params.user ? "&id=" + params.user : "") + dataString
			const res = await customFetchWithResponse("/users" + urlParams, EFetchMethod.GET);
			const paginationCursor = res.headers.get("Pagination-Cursor") ?? "";
			const json = await res.json();
			// For some reason, same API responds different way with different type of user search
			if (params.user) {
				dispatch(getUsersSuccess([json], paginationCursor));
			} else if (params.type === "INTERPRETER") {
				dispatch(getUsersSuccess(json, paginationCursor));
			} else if (params.type === "ADMIN") {
				dispatch(getUsersSuccess(json, paginationCursor));
			} else {
				dispatch(getUsersSuccess(json.data, paginationCursor));
			}

		} catch (error) {
			dispatch(getUsersFail((error as Error).message));
		}
	};
};



export const saveUser = (user: IUser, orderId?: string | null) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(saveUserStart())
			const blob = [new Blob()];
			const formData = new FormData();
			if(orderId){
				formData.append("data", JSON.stringify({...user, orderId: orderId}));
			} else {
				formData.append("data", JSON.stringify(user));
			}
			for (let file in blob) {
				formData.append("files", file)
			}
			await customFetch<IUser>(`/users/add`, EFetchMethod.POST, formData);
			dispatch(saveUserSuccess());
		} catch (error) {
			dispatch(saveUserError((error as Error).message));
		}

	};
}

export const getUser = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getUserStart());
			const user = await customFetch<IUser>(`/users?id=${id}`);
			dispatch(getUserSuccess(user));
		} catch (error) {
			dispatch(getUserFail((error as Error).message));
		}

	};
};


// RESET EEZYTRUSTER ID
const resetEezyTrusterIdStart = (): TAction => {
	return {
		type: EActionTypes.USER_RESET_EEZY_TRUSTER_ID_START,
	}
}

const resetEezyTrusterIdSuccess = (): TAction => {
	return {
		type: EActionTypes.USER_RESET_EEZY_TRUSTER_ID_SUCCESS,
	}
}

const resetEezyTrusterIdError = (error: string): TAction => {
	return {
		type: EActionTypes.USER_RESET_EEZY_TRUSTER_ID_ERROR,
		error
	}
}
export const resetEezyTrusterClear = (): TAction => {
	window.location.reload();
	return {
		type: EActionTypes.USER_RESET_EEZY_TRUSTER_ID_CLEAR,
	}
}

export const resetEezyTrusterId = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(resetEezyTrusterIdStart());
			customFetch(`/users/reseteezyid`, EFetchMethod.POST, JSON.stringify({id: id})).then((result)=>{
				dispatch(resetEezyTrusterIdSuccess());
				// Really?
				//window.location.reload()
			}).catch((err) =>{
				dispatch(resetEezyTrusterIdError((err as Error).message));
			});
		} catch (error) {
			dispatch(resetEezyTrusterIdError((error as Error).message));
		}

	};
};

// Remove user
const removeUserStart = (): TAction => {
	return {
		type: EActionTypes.USER_REMOVE_START,
	}
}

const removeUserDone = (): TAction => {
	return {
		type: EActionTypes.USER_REMOVE_SUCCESS,
	}
}

const removeUserError = (error: string): TAction => {
	return {
		type: EActionTypes.USER_REMOVE_ERROR,
		error
	}
}

export const removeUser = (id: string) =>{
	return async (dispatch: Dispatch) => {
		try {
			dispatch(removeUserStart());
			customFetch(`/users/delete`, EFetchMethod.POST, JSON.stringify({ids: [id]})).then((result)=>{
				dispatch(removeUserDone());
			}).catch((err) =>{
				dispatch(removeUserError((err as Error).message));
			});
		} catch (error) {
			dispatch(removeUserError((error as Error).message));
		}
	}
}


// Move user to netvisor
const moveUserToNetvisorStart = (): TAction => {
	return {
		type: EActionTypes.USER_MOVE_TO_NETVISOR_START,
	}
}

const moveUserToNetvisorDone = (): TAction => {
	return {
		type: EActionTypes.USER_MOVE_TO_NETVISOR_SUCCESS,
	}
}

const moveUserToNetvisorError = (error: string): TAction => {
	return {
		type: EActionTypes.USER_MOVE_TO_NETVISOR_ERROR,
		error
	}
}

export interface INewNetvisorUser {
	netvisorProductIdentifier: string;
	netvisorProductName: string;
	organizations: string;
	netvisorCostCenter: string;
}

export const createNewNetvisorUser = (data: INewNetvisorUser) =>{
	return async (dispatch: Dispatch) => {
		try {
			dispatch(moveUserToNetvisorStart());
			customFetch(`/users/createnewinterpreterinnetvisor`, EFetchMethod.POST, JSON.stringify(data)).then(()=>{
				alert("Creation of Interpreter in Netvisor started. Please check Netvisor.")
				dispatch(moveUserToNetvisorDone());
			}).catch((err) =>{
				dispatch(moveUserToNetvisorError((err as Error).message));
			});
		} catch (error) {
			dispatch(moveUserToNetvisorError((error as Error).message));
		}
	}
}

const getUserContractSettingsStart = (): TAction => {
	return {
		type: EActionTypes.USER_GET_CONTRACT_SETTINGS_START,
	};
};

const getUserContractSettingsSuccess = (contractSettings?: IUserContractSettings): TAction => {
	return {
		type: EActionTypes.USER_GET_CONTRACT_SETTINGS_SUCCESS,
		contractSettings,
	};
};

const getUserContractSettingsFail = (error: string): TAction => {
	return {
		type: EActionTypes.USER_GET_CONTRACT_SETTINGS_FAIL,
		error,
	};
};

export const getUserContractSettingsClear = (error: string): TAction => {
	return {
		type: EActionTypes.USER_GET_CONTRACT_SETTINGS_CLEAR,
	};
};

export const getValidUserContractSettings = (date: string, userId: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getUserContractSettingsStart());
			const contractSettings = await customFetch<IUserContractSettings>(`/users/contractsettings?date=${date}&userId=${userId}`);
			dispatch(getUserContractSettingsSuccess(contractSettings));
		} catch (error) {
			dispatch(getUserContractSettingsFail((error as Error).message));
		}
	};
};