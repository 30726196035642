import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IOption } from '../components/ui/Input/Input';
import IAppState from '../interfaces/store/IAppState';
import IOrganizationGroupState from '../interfaces/store/IOrganizationGroupState';
import * as actions from '../store/actions';
import { createOptions } from '../utils/option-utils';

export const useOrganizationGroupOptions = () => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<IOption[]>([]);

  const { organizationGroups, organizationGroupsLoading } = useSelector<IAppState, IOrganizationGroupState>(state => state.organizationGroup);

  useEffect(() => {
    if (organizationGroups) {
      setOptions(createOptions(organizationGroups));
    } else {
      dispatch(actions.findOrganizationGroups());
    }
  }, [organizationGroups, dispatch]);

  return { organizationGroupOptions: options, organizationGroupsLoading };
}