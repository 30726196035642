import EActionTypes from "../../interfaces/store/EActionTypes";
import IPriceSeasonAction from "../../interfaces/store/IPriceSeasonAction";
import IPriceSeasonState from "../../interfaces/store/IPriceSeasonState";
import { ETranslation } from "../../translations/translation-keys";
import { removeOne } from "../../utils/reducer-utils";
import { IPriceSeasonRule } from "./../../interfaces/IPriceSeasonRule";
import { insertFirst, updateOne } from "./../../utils/reducer-utils";

type ReducerAction = IPriceSeasonAction;
type ReducerState = IPriceSeasonState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
	priceSeasonId: null,
	priceSeason: null,
	priceSeasonLoading: false,
	priceSeasonError: null,

	priceSeasonDeleteError: null,
	priceSeasonDeleteLoading: false,
	priceSeasonDeleteOk: false,

	priceSeasons: null,
	priceSeasonsLoading: false,
	priceSeasonsError: null,

	priceSeasonSaveOrUpdateOk: false,
	priceSeasonSaveOrUpdateError: null,
	priceSeasonSaveOrUpdateLoading: false,

	priceSeasonCopyOk: false,
	priceSeasonCopyError: null,
	priceSeasonCopyLoading: false,

	priceSeasonRule: null,

	priceSeasonRules: null,
	priceSeasonRulesLoading: false,
	priceSeasonRulesError: null,

	priceSeasonRuleDeleteError: null,
	priceSeasonRuleDeleteLoading: false,
	priceSeasonRuleDeleteOk: false,

	priceSeasonRuleSaveOrUpdateOk: false,
};

const findPriceSeasonsStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, priceSeasonsError: null, priceSeasonsLoading: true };
};

const findPriceSeasonsSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		priceSeasons: action.priceSeasons || null,
		priceSeasonsError: null,
		priceSeasonsLoading: false,
	};
};

const findPriceSeasonsFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		priceSeasons: null,
		priceSeasonsLoading: false,
		priceSeasonsError: action.error || ETranslation.REDUCER_SEARCH_ERROR,
	};
};

const getPriceSeasonStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, priceSeasonError: null, priceSeasonLoading: true, priceSeasonId: action.id || null };
};

const getPriceSeasonSuccess: ReducerSignature = (state, action): ReducerState => {
	const priceSeasons = updateOne(state.priceSeasons, action.priceSeason);
	return {
		...state,
		priceSeasons,
		priceSeason: action.priceSeason || null,
		priceSeasonError: null,
		priceSeasonLoading: false,
		priceSeasonId: null,
	};
};

const getPriceSeasonFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		priceSeason: null,
		priceSeasonLoading: false,
		priceSeasonError: action.error || ETranslation.REDUCER_SEARCH_ERROR,
	};
};

const getPriceSeasonClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, priceSeasonId: null, priceSeason: null, priceSeasonLoading: false, priceSeasonError: null };
};

const deletePriceSeasonStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, priceSeasonDeleteError: null, priceSeasonDeleteLoading: true, priceSeasonDeleteOk: false };
};

const deletePriceSeasonSuccess: ReducerSignature = (state, action): ReducerState => {
	const priceSeasons = removeOne(state.priceSeasons, action.id);
	return {
		...state,
		priceSeasons,
		priceSeasonDeleteError: null,
		priceSeasonDeleteLoading: false,
		priceSeasonDeleteOk: true,
	};
};

const deletePriceSeasonFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		priceSeasonDeleteLoading: false,
		priceSeasonDeleteError: action.error as string,
		priceSeasonDeleteOk: false,
	};
};

const deletePriceSeasonClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, priceSeasonDeleteError: null, priceSeasonDeleteLoading: false, priceSeasonDeleteOk: false };
};

const savePriceSeasonStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, priceSeasonSaveOrUpdateError: null, priceSeasonSaveOrUpdateLoading: true };
};

const savePriceSeasonSuccess: ReducerSignature = (state, action): ReducerState => {
	const priceSeasons = insertFirst(state.priceSeasons, action.priceSeason);
	return {
		...state,
		priceSeasons,
		priceSeasonSaveOrUpdateError: null,
		priceSeasonSaveOrUpdateLoading: false,
		priceSeasonSaveOrUpdateOk: true,
	};
};

const savePriceSeasonFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, priceSeasonSaveOrUpdateLoading: false, priceSeasonSaveOrUpdateError: action.error as string };
};

const copyPriceSeasonStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, priceSeasonCopyError: null, priceSeasonCopyLoading: true };
};

const copyPriceSeasonSuccess: ReducerSignature = (state, action): ReducerState => {
	const priceSeasons = insertFirst(state.priceSeasons, action.priceSeason);
	return {
		...state,
		priceSeasons,
		priceSeasonCopyError: null,
		priceSeasonCopyLoading: false,
		priceSeasonCopyOk: true,
	};
};

const copyPriceSeasonFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, priceSeasonCopyLoading: false, priceSeasonCopyError: action.error as string };
};

const copyPriceSeasonClear: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		priceSeasonCopyLoading: false,
		priceSeasonCopyError: null,
		priceSeasonCopyOk: false,
	};
};

const updatePriceSeasonStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, priceSeasonSaveOrUpdateError: null, priceSeasonSaveOrUpdateLoading: true };
};

const updatePriceSeasonSuccess: ReducerSignature = (state, action): ReducerState => {
	const priceSeasons = updateOne(state.priceSeasons, action.priceSeason);
	return {
		...state,
		priceSeasons,
		priceSeasonSaveOrUpdateError: null,
		priceSeasonSaveOrUpdateLoading: false,
		priceSeasonSaveOrUpdateOk: true,
	};
};

const updatePriceSeasonFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, priceSeasonSaveOrUpdateLoading: false, priceSeasonSaveOrUpdateError: action.error as string };
};

const saveOrUpdatePriceSeasonClear: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		priceSeasonSaveOrUpdateLoading: false,
		priceSeasonSaveOrUpdateError: null,
		priceSeasonSaveOrUpdateOk: false,
	};
};

const findPriceSeasonRulesStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, priceSeasonRulesError: null, priceSeasonRulesLoading: true };
};

const findPriceSeasonRulesSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		priceSeasonRules: action.priceSeasonRules || null,
		priceSeasonRulesError: null,
		priceSeasonRulesLoading: false,
	};
};

const findPriceSeasonRulesFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		priceSeasonRules: null,
		priceSeasonRulesLoading: false,
		priceSeasonRulesError: action.error || ETranslation.REDUCER_SEARCH_ERROR,
	};
};

const getPriceSeasonRuleStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, priceSeasonRulesError: null, priceSeasonRulesLoading: true };
};

const getPriceSeasonRuleSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		priceSeasonRule: action.priceSeasonRule || null,
		priceSeasonRulesError: null,
		priceSeasonRulesLoading: false,
	};
};

const getPriceSeasonRuleFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		priceSeasonRule: null,
		priceSeasonRulesLoading: false,
		priceSeasonRulesError: action.error || ETranslation.REDUCER_SEARCH_ERROR,
	};
};

const getPriceSeasonRuleClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, priceSeasonRule: null, priceSeasonRulesLoading: false, priceSeasonRulesError: null };
};

const deletePriceSeasonRuleStart: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		priceSeasonRuleDeleteError: null,
		priceSeasonRuleDeleteLoading: true,
		priceSeasonRuleDeleteOk: false,
	};
};

const deletePriceSeasonRuleSuccess: ReducerSignature = (state, action): ReducerState => {
	const priceSeasonRules = removeOne<IPriceSeasonRule>(state.priceSeasonRules, action.id);
	return {
		...state,
		priceSeasonRules,
		priceSeasonRuleDeleteError: null,
		priceSeasonRuleDeleteLoading: false,
		priceSeasonRuleDeleteOk: true,
	};
};

const deletePriceSeasonRuleFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		priceSeasonRuleDeleteLoading: false,
		priceSeasonRuleDeleteError: action.error as string,
		priceSeasonRuleDeleteOk: false,
	};
};

const deletePriceSeasonRuleClear: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		priceSeasonRuleDeleteError: null,
		priceSeasonRuleDeleteLoading: false,
		priceSeasonRuleDeleteOk: false,
	};
};

const savePriceSeasonRuleStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, priceSeasonRulesError: null, priceSeasonRulesLoading: true };
};

const savePriceSeasonRuleSuccess: ReducerSignature = (state, action): ReducerState => {
	const priceSeasonRules = insertFirst(state.priceSeasonRules, action.priceSeasonRule);
	return {
		...state,
		priceSeasonRules,
		priceSeasonRulesError: null,
		priceSeasonRulesLoading: false,
		priceSeasonRuleSaveOrUpdateOk: true,
	};
};

const savePriceSeasonRuleFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		priceSeasonRulesLoading: false,
		priceSeasonRulesError: action.error || ETranslation.REDUCER_SAVE_ERROR,
	};
};

const updatePriceSeasonRuleStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, priceSeasonRulesError: null, priceSeasonRulesLoading: true };
};

const updatePriceSeasonRuleSuccess: ReducerSignature = (state, action): ReducerState => {
	const priceSeasonRules = updateOne(state.priceSeasonRules, action.priceSeasonRule);
	return {
		...state,
		priceSeasonRulesError: null,
		priceSeasonRulesLoading: false,
		priceSeasonRuleSaveOrUpdateOk: true,
		priceSeasonRules,
	};
};

const updatePriceSeasonRuleFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		priceSeasonRulesLoading: false,
		priceSeasonRulesError: action.error || ETranslation.REDUCER_SAVE_ERROR,
	};
};

const saveOrUpdatePriceSeasonRuleClear: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		priceSeasonRulesLoading: false,
		priceSeasonRulesError: null,
		priceSeasonRuleSaveOrUpdateOk: false,
	};
};

const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
	switch (action.type) {
		case EActionTypes.PRICE_SEASON_FIND_START:
			return findPriceSeasonsStart(state, action);
		case EActionTypes.PRICE_SEASON_FIND_SUCCESS:
			return findPriceSeasonsSuccess(state, action);
		case EActionTypes.PRICE_SEASON_FIND_FAIL:
			return findPriceSeasonsFail(state, action);
		case EActionTypes.PRICE_SEASON_GET_START:
			return getPriceSeasonStart(state, action);
		case EActionTypes.PRICE_SEASON_GET_SUCCESS:
			return getPriceSeasonSuccess(state, action);
		case EActionTypes.PRICE_SEASON_GET_FAIL:
			return getPriceSeasonFail(state, action);
		case EActionTypes.PRICE_SEASON_GET_CLEAR:
			return getPriceSeasonClear(state, action);
		case EActionTypes.PRICE_SEASON_DELETE_START:
			return deletePriceSeasonStart(state, action);
		case EActionTypes.PRICE_SEASON_DELETE_SUCCESS:
			return deletePriceSeasonSuccess(state, action);
		case EActionTypes.PRICE_SEASON_DELETE_FAIL:
			return deletePriceSeasonFail(state, action);
		case EActionTypes.PRICE_SEASON_DELETE_CLEAR:
			return deletePriceSeasonClear(state, action);
		case EActionTypes.PRICE_SEASON_SAVE_START:
			return savePriceSeasonStart(state, action);
		case EActionTypes.PRICE_SEASON_SAVE_SUCCESS:
			return savePriceSeasonSuccess(state, action);
		case EActionTypes.PRICE_SEASON_SAVE_FAIL:
			return savePriceSeasonFail(state, action);
		case EActionTypes.PRICE_SEASON_COPY_START:
			return copyPriceSeasonStart(state, action);
		case EActionTypes.PRICE_SEASON_COPY_SUCCESS:
			return copyPriceSeasonSuccess(state, action);
		case EActionTypes.PRICE_SEASON_COPY_FAIL:
			return copyPriceSeasonFail(state, action);
		case EActionTypes.PRICE_SEASON_COPY_CLEAR:
			return copyPriceSeasonClear(state, action);
		case EActionTypes.PRICE_SEASON_UPDATE_START:
			return updatePriceSeasonStart(state, action);
		case EActionTypes.PRICE_SEASON_UPDATE_SUCCESS:
			return updatePriceSeasonSuccess(state, action);
		case EActionTypes.PRICE_SEASON_UPDATE_FAIL:
			return updatePriceSeasonFail(state, action);
		case EActionTypes.PRICE_SEASON_SAVE_OR_UPDATE_CLEAR:
			return saveOrUpdatePriceSeasonClear(state, action);
		case EActionTypes.PRICE_SEASON_RULE_FIND_START:
			return findPriceSeasonRulesStart(state, action);
		case EActionTypes.PRICE_SEASON_RULE_FIND_SUCCESS:
			return findPriceSeasonRulesSuccess(state, action);
		case EActionTypes.PRICE_SEASON_RULE_FIND_FAIL:
			return findPriceSeasonRulesFail(state, action);
		case EActionTypes.PRICE_SEASON_RULE_GET_START:
			return getPriceSeasonRuleStart(state, action);
		case EActionTypes.PRICE_SEASON_RULE_GET_SUCCESS:
			return getPriceSeasonRuleSuccess(state, action);
		case EActionTypes.PRICE_SEASON_RULE_GET_FAIL:
			return getPriceSeasonRuleFail(state, action);
		case EActionTypes.PRICE_SEASON_RULE_GET_CLEAR:
			return getPriceSeasonRuleClear(state, action);
		case EActionTypes.PRICE_SEASON_RULE_DELETE_START:
			return deletePriceSeasonRuleStart(state, action);
		case EActionTypes.PRICE_SEASON_RULE_DELETE_SUCCESS:
			return deletePriceSeasonRuleSuccess(state, action);
		case EActionTypes.PRICE_SEASON_RULE_DELETE_FAIL:
			return deletePriceSeasonRuleFail(state, action);
		case EActionTypes.PRICE_SEASON_RULE_DELETE_CLEAR:
			return deletePriceSeasonRuleClear(state, action);
		case EActionTypes.PRICE_SEASON_RULE_SAVE_START:
			return savePriceSeasonRuleStart(state, action);
		case EActionTypes.PRICE_SEASON_RULE_SAVE_SUCCESS:
			return savePriceSeasonRuleSuccess(state, action);
		case EActionTypes.PRICE_SEASON_RULE_SAVE_FAIL:
			return savePriceSeasonRuleFail(state, action);
		case EActionTypes.PRICE_SEASON_RULE_UPDATE_START:
			return updatePriceSeasonRuleStart(state, action);
		case EActionTypes.PRICE_SEASON_RULE_UPDATE_SUCCESS:
			return updatePriceSeasonRuleSuccess(state, action);
		case EActionTypes.PRICE_SEASON_RULE_UPDATE_FAIL:
			return updatePriceSeasonRuleFail(state, action);
		case EActionTypes.PRICE_SEASON_RULE_SAVE_OR_UPDATE_CLEAR:
			return saveOrUpdatePriceSeasonRuleClear(state, action);
		default:
			return state;
	}
};

export default reducer;
