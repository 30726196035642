import { Dispatch } from 'redux';

import { customFetch, EFetchMethod } from '../../custom-fetch';
import { IOrganizationGroup } from '../../interfaces/IOrganizationGroup';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IOrganizationGroupAction from '../../interfaces/store/IOrganizationGroupAction';

type TAction = IOrganizationGroupAction;

const findOrganizationGroupsStart = (): TAction => {
  return {
    type: EActionTypes.ORGANIZATION_GROUP_FIND_START,
  };
};

const findOrganizationGroupsSuccess = (organizationGroups: IOrganizationGroup[]): TAction => {
  return {
    type: EActionTypes.ORGANIZATION_GROUP_FIND_SUCCESS,
    organizationGroups,
  };
};

const findOrganizationGroupsFail = (error: string): TAction => {
  return {
    type: EActionTypes.ORGANIZATION_GROUP_FIND_FAIL,
    error,
  };
};

export const findOrganizationGroups = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(findOrganizationGroupsStart());
      const organizationGroups = await customFetch<IOrganizationGroup[]>("/organizationgroups/list");
      dispatch(findOrganizationGroupsSuccess(organizationGroups));
    } catch (error) {
      dispatch(findOrganizationGroupsFail((error as Error).message));
    }
  };
};

const getOrganizationGroupStart = (id: string): TAction => {
  return {
    type: EActionTypes.ORGANIZATION_GROUP_GET_START,
    id
  };
};

const getOrganizationGroupSuccess = (organizationGroup: IOrganizationGroup): TAction => {
  return {
    type: EActionTypes.ORGANIZATION_GROUP_GET_SUCCESS,
    organizationGroup,
  };
};

const getOrganizationGroupFail = (error: string): TAction => {
  return {
    type: EActionTypes.ORGANIZATION_GROUP_GET_FAIL,
    error,
  };
};

export const getOrganizationGroupClear = (): TAction => {
  return {
    type: EActionTypes.ORGANIZATION_GROUP_GET_CLEAR,
  };
};

export const getOrganizationGroup = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getOrganizationGroupStart(id));
      const organizationGroup = await customFetch<IOrganizationGroup>(`/organizationgroups/get?id=${id}`);
      dispatch(getOrganizationGroupSuccess(organizationGroup));
    } catch (error) {
      dispatch(getOrganizationGroupFail((error as Error).message));
    }
  };
};

const deleteOrganizationGroupStart = (): TAction => {
  return {
    type: EActionTypes.ORGANIZATION_GROUP_DELETE_START,
  };
};

const deleteOrganizationGroupSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.ORGANIZATION_GROUP_DELETE_SUCCESS,
    id,
  };
};

const deleteOrganizationGroupFail = (error: string): TAction => {
  return {
    type: EActionTypes.ORGANIZATION_GROUP_DELETE_FAIL,
    error,
  };
};

export const deleteOrganizationGroupClear = (): TAction => {
  return {
    type: EActionTypes.ORGANIZATION_GROUP_DELETE_CLEAR,
  };
};

export const deleteOrganizationGroup = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deleteOrganizationGroupStart());
      await customFetch<boolean>(`/organizationgroups/delete?id=${id}`, EFetchMethod.DELETE);
      dispatch(deleteOrganizationGroupSuccess(id));
    } catch (error) {
      dispatch(deleteOrganizationGroupFail((error as Error).message));
    }
  };
};

const saveOrUpdateOrganizationGroupStart = (): TAction => {
  return {
    type: EActionTypes.ORGANIZATION_GROUP_SAVE_OR_UPDATE_START,
  };
};

const saveOrUpdateOrganizationGroupSuccess = (): TAction => {
  return {
    type: EActionTypes.ORGANIZATION_GROUP_SAVE_OR_UPDATE_SUCCESS,
  };
};

const saveOrUpdateOrganizationGroupFail = (error: string): TAction => {
  return {
    type: EActionTypes.ORGANIZATION_GROUP_SAVE_OR_UPDATE_FAIL,
    error,
  };
};

export const saveOrUpdateOrganizationGroupClear = (): TAction => {
  return {
    type: EActionTypes.ORGANIZATION_GROUP_SAVE_OR_UPDATE_CLEAR
  }
}

export const saveOrganizationGroup = (organizationGroup: IOrganizationGroup) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveOrUpdateOrganizationGroupStart());
      await customFetch<IOrganizationGroup>("/organizationgroups/add", EFetchMethod.POST, JSON.stringify(organizationGroup));
      dispatch(saveOrUpdateOrganizationGroupSuccess());
    } catch (error) {
      dispatch(saveOrUpdateOrganizationGroupFail((error as Error).message));
    }
  };
};

export const updateOrganizationGroup = (organizationGroup: IOrganizationGroup) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveOrUpdateOrganizationGroupStart());
      await customFetch<IOrganizationGroup>("/organizationgroups/update", EFetchMethod.PUT, JSON.stringify(organizationGroup));
      dispatch(saveOrUpdateOrganizationGroupSuccess());
    } catch (error) {
      dispatch(saveOrUpdateOrganizationGroupFail((error as Error).message));
    }
  };
};


