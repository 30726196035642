import React, {
	CSSProperties,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";

import classes from "./Input.module.scss";
import InputContext from "./InputContext";
import RadioButton from "../RadioButton/RadioButton";
import Checkbox from "../Checkbox/Checkbox";
import CustomDropzone, { IDropzoneSettings } from "../../CustomDropzone/CustomDropzone";
import { IAttachment } from "../../../interfaces/IAttachment";
import { ETranslation } from "../../../translations/translation-keys";
import WysiwygEditor from "../WysiwygEditor/WysiwygEditor";
import Base64Dropzone from "../../Base64Dropzone/Base64Dropzone";
import { useTranslation } from "react-i18next";
import Star from "../Star/Star";
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { IOrganization } from "../../../interfaces/IOrganization";
import { fi } from "date-fns/locale";
import Select from "../Select/Select";
import { ELanguageStatus } from "../../../shared/languages-data";
import ToLanguageSelect from "../ToLanguageSelect/ToLanguageSelect";
import SelectSearch, { TFetchOption } from "../Select/SelectSearch";
import DatePicker from "../DatePicker/DatePicker";
import { allRoles, getSiteUserRoles } from "../../../shared/site-data";

export enum ECommonValue {
	ALL = "ALL",
	YES = "YES",
	NO = "NO",
	MALE = "MALE",
	FEMALE = "FEMALE",
}

export enum EInfoColor {
	DANGER,
}

export interface IOption {
	value: string;
	label?: string;
	labelTranslation?: ETranslation;
	status?: ELanguageStatus;
	active?: boolean;
}

export const RoleSelections: () => IOption[] = () => {
	let roles: IOption[] = [];
	allRoles.forEach((role) =>{
		const option = getSiteUserRoles(role);
		if (option) roles.push(option);
	})
	return roles;
}


export enum EInputType {
	static = "static",
	text = "text",
	password = "password",
	number = "number",
	radio = "radio",
	date = "date",
	time = "time",
	email = "email",
	tel = "tel",
	select = "select",
	checkbox = "checkbox",
	textarea = "textarea",
	dropzone = "dropzone",
	wysiwyg = "wysiwyg",
	base64Dropzone = "base64Dropzone",
	star = "star",
	datepicker = "datepicker",
	datetimepicker = "datetimepicker",
	toLanguageSelect = "toLanguageSelect",
	reactSelect = "reactSelect",
	reactSelectSearch = "reactSelectSearch",
	boolean = "boolean",
}

export interface IValidSamples {
	[key: string]: boolean;
}

export interface IValidationResult {
	isValid: boolean;
	message: string | null;
	messageParams?: {
		[key: string]: string;
	};
}

interface IRequiredIf {
	inputName: string,
	value?: string | string[];
}

export interface IInputValidation {
	required?: boolean;
	requiredCompareValue?: string;
	requiredIf?: IRequiredIf[];
	requiredIfOr?: boolean;
	requiredIfNot?: IRequiredIf[];
	dependencies?: string[];
	minLength?: number;
	minLengthMessage?: ETranslation;
	maxLength?: number;
	maxLengthMessage?: ETranslation;
	minAmount?: number;
	minAmountMessage?: ETranslation;
	maxAmount?: number;
	maxAmountMessage?: ETranslation;
}

export interface IInputFieldItem {
	type: EInputType;
	label?: string;
	customLabel?: string;
	labelTranslation?: ETranslation;
	labelClassName?: string;
	value: TInputValue;
	options?: IOption[];
	dateAsString?: boolean;
	placeholder?: string;
	placeholderTranslation?: ETranslation;
	disabled?: boolean;
	validation?: IInputValidation;
	validationResult?: IValidationResult;
	max?: string;
	min?: string;
	multiple?: boolean;
	uncheckable?: boolean;
	dropzoneSettings?: IDropzoneSettings;
	isSearchable?: boolean;
	isClearable?: boolean;
	isCreatable?: boolean;
	isMulti?: boolean;
}

export interface IInputField {
	[key: string]: IInputFieldItem;
}

export type TInputValue =
	| IOption
	| IOption[]
	| string
	| string[]
	| number
	| Date
	| IAttachment[]
	| IAttachment
	| IOrganization
	| boolean
	| null
	| undefined;

export interface IInputOptions {
	updateAction?: string;
	weight?: number;
	info?: string;
	topInfo?: string | JSX.Element;
	infoColor?: EInfoColor;
	data?: Object;
	disabled?: boolean;
	showLabel?: boolean;
	containerStyles?: CSSProperties;
	rows?: number;
	showValidation?: boolean;
	onBlurModifyValue?: (value: TInputValue) => TInputValue;
	onBlur?: (value: TInputValue) => void;
	onFocus?: (value: TInputValue) => void;
	onClick?: (
		event: React.MouseEvent<HTMLInputElement | HTMLSelectElement, MouseEvent>
	) => void;
	onKeyup?: (event: React.KeyboardEvent) => void;
	hideUnselected?: boolean;
	pre?: string;
	post?: string;
	options?: IOption[];
	fetchOptions?: TFetchOption;
	confirmValueChange?: (value: TInputValue) => Promise<boolean>;
	style?: CSSProperties;
	isLoading?: boolean;
	placeholderTranslation?: ETranslation;
	labelStyles?: CSSProperties;
}

interface IProps extends IInputFieldItem, IInputOptions {
	onChange: (value: TInputValue) => void;
	inputName: string;
	isLoading?: boolean;
}

export const isInputDisabled = (
	disableFields: boolean,
	item: IInputFieldItem,
	options?: IInputOptions
) => {
	return disableFields || item.disabled || (options && options.disabled);
};

export const inputsToObject = function <T>(inputs: IInputField): T {
	const result: { [key: string]: TInputValue } = {};

	for (let key in inputs) {
		result[key] = inputs[key].value;
	}

	return (result as unknown) as T;
};

const Input: React.FC<IProps> = ({
	label,
	labelTranslation,
	customLabel,
	value,
	onChange,
	type,
	options,
	weight,
	inputName,
	info,
	topInfo,
	infoColor,
	placeholder,
	placeholderTranslation,
	validationResult = { isValid: true, message: "" },
	data,
	disabled,
	max,
	rows,
	min,
	showLabel,
	labelClassName,
	updateAction,
	containerStyles,
	showValidation,
	pre,
	post,
	onClick,
	hideUnselected,
	onBlurModifyValue,
	onBlur,
	onFocus,
	onKeyup,
	fetchOptions,
	multiple,
	style,
	uncheckable,
	dropzoneSettings,
	isSearchable,
	isClearable,
	isLoading,
	isCreatable,
	isMulti,
	confirmValueChange,
	labelStyles,
}) => {

	const { t } = useTranslation();

	const { onAutoUpdate, initDone } = useContext(InputContext);

	const [initValue, setInitValue] = useState(value);

	useEffect(() => {
		if (initDone) {
			setInitValue(value);
		}

		// eslint-disable-next-line
	}, [initDone, setInitValue]);

	useEffect(() => {
		onChange(value); // When init onChange validates input when created
		// eslint-disable-next-line
	}, []);

	const autoUpdateHandler = (value: TInputValue) => {
		if (onBlur) {
			onBlur(value);
		}

		if (onBlurModifyValue) {
			value = onBlurModifyValue(value);
		}

		if (initValue !== value) {
			if (updateAction) {
				onAutoUpdate(inputName, value, updateAction, data); // Maybe needs some checks here if context missing.
			}
			setInitValue(value);
		}
	};

	const canUpdateValue = async (value: TInputValue) => {
		let updateValue = true;
		if (confirmValueChange) {
			updateValue = await confirmValueChange(value);
		}
		return updateValue;
	}

	const singleChangeHandler = async (value: TInputValue) => {
		if (await canUpdateValue(value)) {
			onChange(value);
			autoUpdateHandler(value);
		}
	};



	const infoClasses = [classes.Info];

	switch (infoColor) {
		case EInfoColor.DANGER:
			infoClasses.push(classes.InfoDanger);
			break;
		default:
	}

	const getLabel = useCallback((label?: string, showLabel: boolean = true, labelClassName?: string) => {
		if (!label && !showLabel) return null;
		if (!showLabel) return null;
		const classNames = [classes.Label];

		if (labelClassName) {
			classNames.push(labelClassName);
		}

		return (
			<label className={classNames.join(' ')} style={labelStyles}>
				{label} {showLabel && <>&nbsp;</>}
			</label>
		);
	}, [labelStyles]);

	const getPlaceholder = useCallback((useLabel: boolean = true) => {
		if (placeholderTranslation) return t(placeholderTranslation);
		if (placeholder) return placeholder;
		if (useLabel) {
			if (labelTranslation) return t(labelTranslation);
			if (label) return label;
		}
		return "";
	}, [placeholderTranslation, placeholder, labelTranslation, label, t]);

	const invalid = showValidation && !validationResult.isValid && !disabled;

	const invalidMessage =
		showValidation && !validationResult.isValid && validationResult.message;

	const classNames = [classes.Container];

	if (invalid) {
		classNames.push(classes.Invalid);
	}

	const inputGroupClassNames = [classes.InputGroup];

	if (pre) {
		inputGroupClassNames.push(classes.HasPre);
	}

	if (post) {
		inputGroupClassNames.push(classes.HasPost);
	}

	return (
		<div
			className={classNames.join(" ")}
			style={{ flexGrow: weight, ...containerStyles }}
		>
			{getLabel(customLabel ? customLabel : labelTranslation ? t(labelTranslation) : label, type === EInputType.boolean ? false : showLabel, labelClassName)}

			{topInfo && <div className={classes.TopInfo}>{topInfo}</div>}

			<div className={inputGroupClassNames.join(" ")}>
				{pre && <div className={classes.Pre}>{pre}</div>}

				{(() => {
					switch (type) {
						case EInputType.text:
						case EInputType.password:
						case EInputType.number:
						case EInputType.date:
						case EInputType.time:
						case EInputType.email:
						case EInputType.tel:
							return (
								<input
									className={classes.Input}
									disabled={disabled}
									type={type}
									value={value as string}
									placeholder={getPlaceholder()}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
										onChange(event.target.value)
									}
									onBlur={() => autoUpdateHandler(value)}
									onKeyUp={onKeyup ?? undefined}
									onFocus={onFocus ? () => onFocus(value) : undefined}
									onClick={onClick}
									max={max}
									min={min}
									style={style}
								/>
							);
						case EInputType.boolean:
							return (
								<div>
									<label className={classes.booleanToggle}>
										<input
											id={(labelTranslation ?? label)?.replaceAll(" ", "")}
											className={"checkbox"}
											disabled={disabled}
											type={"checkbox"}
											value={value as string}
											onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
												singleChangeHandler(!value as boolean)
											}
											onBlur={() => autoUpdateHandler(value)}
											onFocus={onFocus ? () => onFocus(value) : undefined}
											onKeyUp={onKeyup ?? undefined}
											onClick={onClick}
											style={style}
											checked={value as boolean}
										/>
										<span className={classes.slider}></span>
									</label>
									<label htmlFor={(labelTranslation ?? label)?.replaceAll(" ", "")} style={labelStyles}>
										{labelTranslation ? t(labelTranslation) : label}
									</label>


								</div>
							);
						case EInputType.select:
							return (
								<select
									disabled={disabled}
									className={classes.Input}
									onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
										singleChangeHandler(event.target.value)
									}
									value={value as string}
									onClick={onClick}
									style={style}
								>
									{options &&
										options.map(option => (
											<option key={option.value} value={option.value}>
												{" "}
												{option.labelTranslation ? t(option.labelTranslation) : option.label}
											</option>
										))}
								</select>
							);

						case EInputType.radio:
							return (
								<RadioButton
									value={value as string}
									options={options}
									onChange={singleChangeHandler}
									disabled={disabled}
									invalid={invalid}
									hideUnselected={hideUnselected}
									uncheckable={uncheckable}
									style={style}
								/>
							);

						case EInputType.checkbox:
							return (
								<Checkbox
									name={inputName}
									value={value as string[]}
									options={options}
									onChange={singleChangeHandler}
									disabled={disabled}
									invalid={invalid}
								/>
							);

						case EInputType.textarea:
							return (
								<textarea
									className={classes.Input}
									value={value as string}
									placeholder={getPlaceholder()}
									onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
										onChange(event.target.value)
									}
									onBlur={() => autoUpdateHandler(value)}
									onKeyUp={onKeyup ?? undefined}
									disabled={disabled}
									rows={rows}
								/>
							);
						case EInputType.wysiwyg:
							return (
								<WysiwygEditor
									onChange={onChange}
									value={value as string}
									disabled={disabled}
								/>
							);
						case EInputType.static:
							return <p style={{ margin: 0 }}>{value}</p>;
						case EInputType.dropzone:
							return (
								<CustomDropzone
									className={classes.Input}
									onChange={(attachments: Array<IAttachment>) => {
										onChange(attachments);
									}}
									settings={dropzoneSettings}
									value={value as Array<IAttachment>}
									multiple={multiple}
								/>
							);
						case EInputType.base64Dropzone:
							return (
								<Base64Dropzone
									className={classes.Input}
									text={t(ETranslation.UI_INPUT_PRESS_OR_DROP_IMAGE)}
									dragText={t(ETranslation.UI_INPUT_DROP_IMAGE)}
									onChange={onChange}
									value={value as string}
								/>
							);
						case EInputType.star:
							return (
								<Star
									value={value?.toString() ?? ""}
									options={options}
									onChange={singleChangeHandler}
									disabled={disabled}
									invalid={invalid}
								/>
							);
						case EInputType.reactSelect:
							return (
								// isCreatable ? (
								//   <CreatableReactSelect
								//     name={inputName}
								//     value={selectValue}
								//     onChange={(option) => changeHandler(option as IOption| IOption[] | null)}
								//     options={selectOptions}
								//     isDisabled={disabled}
								//     isSearchable={isSearchable}
								//     isClearable={isClearable}
								//     isLoading={isLoading}
								//     formatCreateLabel={value => `${t(ETranslation.REACT_SELECT_ADD_NEW)} "${value}"`}
								//     placeholder={t(ETranslation.REACT_SELECT_PLACEHOLDER)}
								//     isMulti={isMulti}
								//   />
								// ) : (
								<Select
									className={classes.Select}
									name={inputName}
									value={value as IOption | IOption[]}
									onChange={(value) => singleChangeHandler(value)}
									options={options}
									disabled={disabled}
									isSearchable={isSearchable}
									isClearable={isClearable}
									loading={isLoading}
									placeholder={
										placeholderTranslation
											? t(placeholderTranslation)
											: placeholder
												? placeholder
												: t(ETranslation.REACT_SELECT_PLACEHOLDER)
									}
									multiple={multiple}
								/>
							);
						case EInputType.reactSelectSearch:
							return (
								<SelectSearch
									className={classes.Select}
									value={value as IOption | IOption[]}
									onChange={(value) => singleChangeHandler(value)}
									disabled={disabled}
									fetchOptions={fetchOptions}
									isLoading={isLoading}
									placeholder={
										placeholderTranslation
											? t(placeholderTranslation)
											: placeholder
												? placeholder
												: undefined
									}
									multiple={multiple}
								/>
							);
						case EInputType.toLanguageSelect:
							return (
								<ToLanguageSelect
									name={inputName}
									value={value as string}
									onChange={(value) => singleChangeHandler(value)}
									options={options}
									disabled={disabled}
									isSearchable={isSearchable}
									isClearable={isClearable}
									loading={isLoading}
									placeholder={
										placeholderTranslation
											? t(placeholderTranslation)
											: placeholder
												? placeholder
												: t(ETranslation.REACT_SELECT_PLACEHOLDER)
									}
									multiple={multiple}
								/>
							)
						case EInputType.datetimepicker:
							if (value) value = new Date(value as string);
							return (
								<ReactDatePicker
									className={['form-control', classes.Input].join(' ')}
									selected={value as Date}
									onChange={date => onChange(date)}
									onBlur={() => autoUpdateHandler(value)}
									todayButton
									locale={fi}
									showTimeSelect={true}
									dateFormat="dd.MM.yyyy HH:mm"
									isClearable={isClearable}
									placeholderText={placeholderTranslation ? t(placeholderTranslation) : placeholder ? placeholder : label}
									disabled={disabled}
									wrapperClassName={classes.datePicker}
								/>
							);
						case EInputType.datepicker:
							if (value) value = new Date(value as string);
							return (
								// 23.10.2023 refactored to take in raw string with no delimiters to determine the date.
								<DatePicker
									className={['form-control', classes.Input].join(' ')}
									value={value}
									onChange={onChange}
									onBlur={() => autoUpdateHandler(value)}
									isClearable={isClearable ?? false}
									placeholderText={placeholderTranslation ? t(placeholderTranslation) : placeholder ? placeholder : label ?? ""}
									disabled={disabled ?? false}
									wrapperClassName={classes.datePicker}
								/>
							);
					}
				})()}

				{post && <div className={classes.Post}>{post}</div>}
			</div>

			{info && <div className={infoClasses.join(" ")}>{info}</div>}

			{invalidMessage && (
				<div className={[classes.Info, classes.InfoDanger].join(" ")}>
					{invalidMessage}
				</div>
			)}
		</div>
	);
};

export default React.memo(Input, (prevProps, nextProps) => {
	return (
		prevProps.value === nextProps.value &&
		prevProps.validationResult === nextProps.validationResult &&
		prevProps.disabled === nextProps.disabled &&
		prevProps.showValidation === nextProps.showValidation &&
		prevProps.options === nextProps.options &&
		prevProps.min === nextProps.min &&
		prevProps.info === nextProps.info &&
		prevProps.topInfo === nextProps.topInfo &&
		prevProps.pre === nextProps.pre &&
		prevProps.post === nextProps.post &&
		prevProps.onClick === nextProps.onClick &&
		prevProps.label === nextProps.label &&
		prevProps.labelTranslation === nextProps.labelTranslation &&
		prevProps.customLabel === nextProps.customLabel &&
		prevProps.placeholderTranslation === nextProps.placeholderTranslation &&
		prevProps.isLoading === nextProps.isLoading
	);
});
