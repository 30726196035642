import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateInput } from '../../../hooks/useCreateInput';

import { useLanguages } from '../../../hooks/useLanguages';
import { IPriceSeasonRule, IPriceSeasonRuleSettings } from '../../../interfaces/IPriceSeasonRule';
import {
  EPriceSeasonRuleChargeType,
  PRICE_SEASON_RULE_CHARGE_TYPES,
  PRICE_SEASON_RULE_DAYS,
  PRICE_SEASON_RULE_TAGS,
} from '../../../shared/price-season-data';
import { ETranslation } from '../../../translations/translation-keys';
import Fieldset from '../../ui/Fieldset/Fieldset';
import { ECommonValue, EInputType, IInputField } from '../../ui/Input/Input';
import { getInputData, initForm, validateInputs } from '../../ui/Input/input-utils';
import InputGroup from '../../ui/InputGroup/InputGroup';
import PriceSeasonRuleSettings from './PriceSeasonRuleSettings/PriceSeasonRuleSettings';
import { useSiteTypes } from '../../../hooks/useSiteTypes';
import { EInterpretationType } from '../../../shared/interpretation-data';

enum EInputs {
  chargeType = "chargeType",
  name = "name",
  description = "description",
  startTime = "startTime",
  endTime = "endTime",
  active = "active",
  fromLanguages = "fromLanguages",
  toLanguages = "toLanguages",
  priceVatP = "priceVatP",
  tags = "tags",
  days = "days",
}

interface IProps {
  index?: number;
  onChange: (priceSeasonRule: IPriceSeasonRule, isValid: boolean, index?: number) => void;
  priceSeasonRule: IPriceSeasonRule | null;
  loading?: boolean;
  showValidation?: boolean;
  isAdd: boolean;
}

interface ISettingsObject {
  [key: string]: IPriceSeasonRuleSettings;
}

const PriceSeasonRule: React.FC<IProps> = ({
  onChange,
  index,
  priceSeasonRule,
  loading,
  showValidation,
  isAdd
}) => {
  const { languageOptions } = useLanguages();
  const { t } = useTranslation();
  const [settingsObj, setSettingsObj] = useState<ISettingsObject>({});
  const { siteTypes } = useSiteTypes();
  const [canInit, setCanInit] = useState(!isAdd);

  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.chargeType]: {
      type: EInputType.select,
      labelTranslation: ETranslation.COMMON_TYPE,
      value: "",
      options: [
        { value: "", labelTranslation: ETranslation.PRICE_SEASON_CHOOSE_TYPE },
        ...PRICE_SEASON_RULE_CHARGE_TYPES,
      ],
      validation: {
        required: true
      }
    },
    [EInputs.name]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_NAME,
      value: "",
      validation: {
        required: true
      }
    },
    [EInputs.description]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.COMMON_DESCRIPTION,
      value: "",
    },
    [EInputs.startTime]: {
      type: EInputType.time,
      labelTranslation: ETranslation.COMMON_START_TIME,
      value: "",
    },
    [EInputs.endTime]: {
      type: EInputType.time,
      labelTranslation: ETranslation.COMMON_END_TIME,
      value: "",
    },
    [EInputs.active]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.PRICE_SEASON_ACTIVE,
      value: [],
      options: [{ value: ECommonValue.YES }],
    },
    [EInputs.fromLanguages]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.COMMON_FROM_LANGUAGE,
      value: [],
      placeholderTranslation: ETranslation.COMMON_CHOOSE_LANGUAGES,
      multiple: true,
    },
    [EInputs.toLanguages]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.COMMON_TO_LANGUAGE,
      placeholderTranslation: ETranslation.COMMON_CHOOSE_LANGUAGES,
      value: [],
      multiple: true,
    },
    [EInputs.priceVatP]: {
      type: EInputType.number,
      labelTranslation: ETranslation.COMMON_VAT,
      value: "",
    },
    [EInputs.tags]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.COMMON_TAGS,
      value: [],
      options: PRICE_SEASON_RULE_TAGS,
    },
    [EInputs.days]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.PRICE_SEASON_DAYRULES,
      value: [],
      options: PRICE_SEASON_RULE_DAYS,
    },
  });

  useEffect(() => {
    if (priceSeasonRule && canInit) {
      initForm(setInputs, priceSeasonRule);
      setSettingsObj(priceSeasonRule.settings.reduce<ISettingsObject>((current, value) => {
        current[value.type] = value
        return current;
      }, {}))
      setCanInit(false);
    }
  }, [priceSeasonRule, canInit]);

  useEffect(() => {
    const priceSeasonRule = getInputData<IPriceSeasonRule>(inputs);
    priceSeasonRule.settings = Object.values(settingsObj);
    const isValid = validateInputs(inputs);
    onChange(priceSeasonRule, isValid, index);
  }, [
    inputs,
    onChange,
    index,
    settingsObj
  ]);

  const createInput = useCreateInput(inputs, setInputs, {
    showValidation,
    disabled: loading
  });

  const updateSettings = useCallback((type: EInterpretationType, settingsObj: ISettingsObject, settings: IPriceSeasonRuleSettings) => {
    settingsObj[type] = { ...settings, type };
    return { ...settingsObj };
  }, []);

  const settingsChangeHandler = useCallback((type: EInterpretationType, value: IPriceSeasonRuleSettings ) => {
    setSettingsObj(settingsObj => updateSettings(type, settingsObj, value));
  }, [updateSettings]);

  const chargeType = inputs[EInputs.chargeType]
    .value as EPriceSeasonRuleChargeType;

  return (
    <div>
      {createInput(EInputs.chargeType)}
      {createInput(EInputs.name)}
      {createInput(EInputs.description)}
      <InputGroup>
        {createInput(EInputs.startTime)}
        {createInput(EInputs.endTime)}
        {createInput(EInputs.active)}
        {createInput(EInputs.priceVatP)}
      </InputGroup>
      <InputGroup>
        {createInput(EInputs.fromLanguages, {
          options: languageOptions,
        })}
        {createInput(EInputs.toLanguages, {
          options: languageOptions,
        })}
      </InputGroup>
      <InputGroup>
        {createInput(EInputs.tags)}
        {createInput(EInputs.days)}
      </InputGroup>
      {!canInit && siteTypes.map(option => (
        <Fieldset label= {t(option.labelTranslation ?? '')} key={option.value}>
          <PriceSeasonRuleSettings
            onChange={settingsChangeHandler}
            type={option.value as EInterpretationType}
            settings={settingsObj[option.value]}
            loading={loading}
            chargeType={chargeType}
          />
        </Fieldset>
      ))}
      {/*
        <Fieldset label= {t(ETranslation.COMMON_INSTANT)}>
          <PriceSeasonRuleSettings
            onChange={setInstantSettings}
            settings={priceSeasonRule?.instantSettings}
            loading={loading}
            chargeType={chargeType}
          />
        </Fieldset>
        <Fieldset label= {t(ETranslation.COMMON_BOOKING)}>
          <PriceSeasonRuleSettings
            onChange={setBookingSettings}
            settings={priceSeasonRule?.bookingSettings}
            loading={loading}
            chargeType={chargeType}
          />
        </Fieldset>
        <Fieldset label= {t(ETranslation.COMMON_ATTENDANCE)}>
          <PriceSeasonRuleSettings
            onChange={setOnSiteSettings}
            settings={priceSeasonRule?.onSiteSettings}
            loading={loading}
            chargeType={chargeType}
          />
        </Fieldset>
    */}

    </div>
  );
};

export default PriceSeasonRule;
