import React, { useCallback, useContext, useState } from 'react';

import { IPriceSeasonRule } from '../../../../interfaces/IPriceSeasonRule';
import EditButtons from '../../../ui/EditButtons/EditButtons';
import ModalBody from '../../../ui/Modal/ModalBody/ModalBody';
import ModalContext from '../../../ui/Modal/ModalContext';
import ModalFooter from '../../../ui/Modal/ModalFooter/ModalFooter';
import PriceSeasonRule from '../PriceSeasonRule';

interface IProps {
  onChange: (priceSeasonRule: IPriceSeasonRule, index: number) => void;
  priceSeasonRule: IPriceSeasonRule;
  index: number;
}

const PriceSeasonRuleModal: React.FC<IProps> = ({
  priceSeasonRule,
  onChange,
  index,
}) => {
  const [showValidation, setShowValidation] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [editPriceSeasonRule, setEditPriceSeasonRule] = useState<
    IPriceSeasonRule
  >(priceSeasonRule);

  const { closeModal } = useContext(ModalContext);

  const saveHandler = () => {
    onChange(editPriceSeasonRule, index);
  };

  const updateHandler = useCallback(
    (priceSeasonRule: IPriceSeasonRule, isValid: boolean) => {
      setIsValid(isValid);
      setEditPriceSeasonRule(priceSeasonRule);
    },
    []
  );

  return (
    <>
      <ModalBody>
        <PriceSeasonRule
          isAdd={false}
          onChange={updateHandler}
          priceSeasonRule={priceSeasonRule}
          showValidation={showValidation}
        />
      </ModalBody>
      <ModalFooter>
        <EditButtons
          onSave={isValid ? saveHandler : () => setShowValidation(true)}
          disabled={showValidation && !isValid}
          onCancel={closeModal}
          isAdd={true}
        />
      </ModalFooter>
    </>
  );
};

export default PriceSeasonRuleModal;
