import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import InterpretationDetails from "../../../components/Interpretation/InterpretationDetails/InterpretationDetails";
import Alert from "../../../components/ui/Alert/Alert";
import Container from "../../../components/ui/Container/Container";
import { ETranslation } from "../../../translations/translation-keys";
import IAppState from "../../../interfaces/store/IAppState";
import { useDispatch, useSelector } from "react-redux";
import { IInterpretation } from "../../../interfaces/IInterpretation";
import * as actions from "../../../store/actions";
import { ERoute, Routes } from "../../../classes/Routes";
import Spinner from "../../../components/ui/Spinner/Spinner";
import Button, { EButtonColor } from "../../../components/ui/Button/Button";

interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

const InterpretationPage: React.FC<IProps> = ({ match, history }) => {
	const { t } = useTranslation();
	const { id } = match.params;
	const isAdd = id === "add";
	const dispatch = useDispatch();

	const { interpretation, loading, updateLoadingProperty } = useSelector(
		(state: IAppState) => ({
			interpretation: state.interpretation.interpretation,
			loading: state.interpretation.loading,
			error: state.interpretation.error,
			updateLoadingProperty: state.interpretation.updateLoadingProperty,
		})
	);

	const [interpretationInfo, setInterpretationInfo] = useState<IInterpretation | null>(interpretation)

	useEffect(()=>{
		if(isAdd) { return }
		dispatch(actions.getInterpretation(id))
	}, [id, isAdd, dispatch])

	useEffect(()=>{
		setInterpretationInfo(interpretation);
	}, [interpretation])


	return (
		<Container>
			{!id && <Alert>{t(ETranslation.NO_ACCESS)}</Alert>}
			{loading && <Spinner centerAbsolute />}
			{/* <Heading>{t(ETranslation.LOG_ITEM_LIST_INTERPRETATION)}</Heading> */}
			{(!loading && interpretationInfo) &&
				<InterpretationDetails interpretation={interpretationInfo} updateLoadingProperty={updateLoadingProperty} />
			}
			<Button color={EButtonColor.DEFAULT} onClick={()=>{history.push(Routes.withParams(ERoute.INTERPRETATIONS_LIST, { }));}} >{t(ETranslation.COMMON_BACK)}</Button>
		</Container>
	);
};

export default InterpretationPage;
