import EActionTypes from '../../interfaces/store/EActionTypes';
import INewsItemAction from '../../interfaces/store/INewsItemAction';
import INewsItemState from '../../interfaces/store/INewsItemState';
import { ETranslation } from '../../translations/translation-keys';

type ReducerAction = INewsItemAction;
type ReducerState = INewsItemState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  newsItem: null,
  newsItemLoading: false,
  newsItemError: null,

  newsItems: null,
  newsItemsLoading: false,
  newsItemsError: null,

  newsItemSaveOrUpdateOk: false,
  newsItemSaveOrUpdateError: null,
  newsItemSaveOrUpdateLoading: false,

  newsItemDeleteError: null,
  newsItemDeleteLoading: false,
  newsItemDeleteOk: false,

  newsItemSaveReviewOK: false,
  newsItemSaveReviewError: null,
  newsItemSaveReviewLoading: false,


};

const findNewsItemsStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, newsItemsError: null, newsItemsLoading: true };
};

const findNewsItemsSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    newsItems: action.newsItems || null,
    newsItemsError: null,
    newsItemsLoading: false,
  };
};

const findNewsItemsFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, newsItems: null, newsItemsLoading: false, newsItemsError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getNewsItemStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, newsItemError: null, newsItemLoading: true };
};

const getNewsItemSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    newsItem: action.newsItem || null,
    newsItemError: null,
    newsItemLoading: false,
  };
};

const getNewsItemFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, newsItem: null, newsItemLoading: false, newsItemError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};

const getNewsItemClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, newsItem: null, newsItemLoading: false, newsItemError: null};
};

const deleteNewsItemStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, newsItemDeleteError: null, newsItemDeleteLoading: true, newsItemDeleteOk: false };
};

const deleteNewsItemSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    newsItemDeleteError: null,
    newsItemDeleteLoading: false,
    newsItemDeleteOk: true
  };
};

const deleteNewsItemFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, newsItemDeleteLoading: false, newsItemDeleteError: action.error as string, newsItemDeleteOk: false };
};

const deleteNewsItemClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, newsItemDeleteError: null, newsItemDeleteLoading: false, newsItemDeleteOk: false };
};

const saveOrUpdateNewsItemStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, newsItemSaveOrUpdateError: null, newsItemSaveOrUpdateLoading: true };
};

const saveOrUpdateNewsItemSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    newsItems: null,
    newsItemSaveOrUpdateError: null,
    newsItemSaveOrUpdateLoading: false,
    newsItemSaveOrUpdateOk: true
  };
};

const saveOrUpdateNewsItemFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, newsItemSaveOrUpdateLoading: false, newsItemSaveOrUpdateError: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const saveOrUpdateNewsItemClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, newsItemSaveOrUpdateLoading: false, newsItemSaveOrUpdateError: null, newsItemSaveOrUpdateOk: false }
}
const saveNewsItemReviewStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, newsItemSaveReviewError: null, newsItemSaveReviewLoading: true };
  };
  
  const saveNewsItemReviewSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
	  ...state,
	  newsItemSaveReviewError: null,
	  newsItemSaveReviewLoading: false,
	  newsItemSaveReviewOK: true
	};
  };
  
  const saveNewsItemReviewFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, newsItemSaveReviewLoading: false, newsItemSaveReviewError: action.error || ETranslation.REDUCER_SAVE_ERROR };
  };
  
  const saveNewsItemReviewClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, newsItemSaveReviewLoading: false, newsItemSaveReviewError: null, newsItemSaveReviewOK: false };
  }
  


const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
	switch (action.type) {
		case EActionTypes.NEWS_ITEM_FIND_START:
			return findNewsItemsStart(state, action);
		case EActionTypes.NEWS_ITEM_FIND_SUCCESS:
			return findNewsItemsSuccess(state, action);
		case EActionTypes.NEWS_ITEM_FIND_FAIL:
			return findNewsItemsFail(state, action);
		case EActionTypes.NEWS_ITEM_GET_START:
			return getNewsItemStart(state, action);
		case EActionTypes.NEWS_ITEM_GET_SUCCESS:
			return getNewsItemSuccess(state, action);
		case EActionTypes.NEWS_ITEM_GET_FAIL:
			return getNewsItemFail(state, action);
		case EActionTypes.NEWS_ITEM_GET_CLEAR:
			return getNewsItemClear(state, action);
		case EActionTypes.NEWS_ITEM_DELETE_START:
			return deleteNewsItemStart(state, action);
		case EActionTypes.NEWS_ITEM_DELETE_SUCCESS:
			return deleteNewsItemSuccess(state, action);
		case EActionTypes.NEWS_ITEM_DELETE_FAIL:
			return deleteNewsItemFail(state, action);
		case EActionTypes.NEWS_ITEM_DELETE_CLEAR:
			return deleteNewsItemClear(state, action);
		case EActionTypes.NEWS_ITEM_SAVE_OR_UPDATE_START:
			return saveOrUpdateNewsItemStart(state, action);
		case EActionTypes.NEWS_ITEM_SAVE_OR_UPDATE_SUCCESS:
			return saveOrUpdateNewsItemSuccess(state, action);
		case EActionTypes.NEWS_ITEM_SAVE_OR_UPDATE_FAIL:
			return saveOrUpdateNewsItemFail(state, action);
		case EActionTypes.NEWS_ITEM_SAVE_OR_UPDATE_CLEAR:
			return saveOrUpdateNewsItemClear(state, action);
		case EActionTypes.NEWS_ITEM_REVIEW_SAVE_START:
			return saveNewsItemReviewStart(state, action);
		case EActionTypes.NEWS_ITEM_REVIEW_SAVE_SUCCESS:
			return saveNewsItemReviewSuccess(state, action);
		case EActionTypes.NEWS_ITEM_REVIEW_SAVE_FAIL:
			return saveNewsItemReviewFail(state, action);
		case EActionTypes.NEWS_ITEM_REVIEW_SAVE_CLEAR:
			return saveNewsItemReviewClear(state, action)

    default:
      return state;
  }
};

export default reducer;