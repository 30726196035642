//component

import React from 'react';


import { useTranslation } from 'react-i18next';
import { customFetch } from '../../custom-fetch';
import { ISendGridEmail } from '../../interfaces/ISendGridEmail';
import { ETranslation } from '../../translations/translation-keys';
import Table from '../ui/Table/Table';

import Alert from '../ui/Alert/Alert';
import Spinner from '../ui/Spinner/Spinner';


import { Dispatch } from 'redux';

import EActionTypes from '../../interfaces/store/EActionTypes';
import ISendGridEmailAction from '../../interfaces/store/ISendGridEmailAction';


interface IProps {
  sendGridEmails: ISendGridEmail[] | null;
  loading: boolean;
  error: string | null;
  onClick: (id: string) => void;
}




const SendGridEmailsComponent: React.FC<IProps> = ({
    sendGridEmails,
    loading,
    error,
    onClick,
    }) => {
    const { t } = useTranslation();
    if (error) {
        return <Alert>{error}</Alert>;
    }
    
    if (!sendGridEmails || loading) {
        return <Spinner />;
    }
    
    if (sendGridEmails.length === 0) {
        return <p>{t(ETranslation.SENDGRID_EMAILS_NO_EMAIL)}</p>;
    }
    
    //const findSendGridEmails = (item: ISendGridEmail): boolean => {
    //    return (
    //    filter.length === 0 ||
    //    item.email?.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    //    );
    //};



    type TAction = ISendGridEmailAction;

    const findSendGridEmailsStart = (): TAction => {
        return {
          type: EActionTypes.SENDGRIDEMAILS_FIND_START,
        };
      };
      
      //modified
      const findSendGridEmailsSuccess = (sendGridEmails: ISendGridEmail[]): TAction => {
        return {
          type: EActionTypes.SENDGRIDEMAILS_FIND_SUCCESS,
          sendGridEmails,
        };
      };
      
      const findSendGridEmailsFail = (error: string): TAction => {
        return {
          type: EActionTypes.SENDGRIDEMAILS_FIND_FAIL,
          error,
        };
      };

    const findSendGridEmails = () => {
        return async (dispatch: Dispatch) => {
          try {
            dispatch(findSendGridEmailsStart());
            const sendGridEmails = await customFetch<ISendGridEmail[]>("/sendgridemails");
            dispatch(findSendGridEmailsSuccess(sendGridEmails));
          } catch (error) {
            dispatch(findSendGridEmailsFail((error as Error).message));
          }
        };
      };
  
    return (
      <>
      
  
      <Table hover={true}>
          <thead>
          <tr>
              <th>Vastaanottaja</th>
              <th>Päivämäärä</th>
              <th>Status</th>
              <th>Bounced/blocked</th>
          </tr>
          </thead>
          <tbody>
          {sendGridEmails
              .filter(findSendGridEmails)
              .map((sendGridEmail, index) => (
              <tr
                  key={sendGridEmail.id}
//                  onClick={() => onClick(sendGridEmail.id)}
//                  onClick={() => ()}
              >
                  <td>{sendGridEmail.email}</td>
                  <td>{sendGridEmail.createdDateString}</td>
                  <td>{sendGridEmail.reason}</td>
                  <td>{sendGridEmail.bouncedOrblocked}</td>
              </tr>
              ))}
          </tbody>
      </Table>
      </>
  );
  };



  /*
const SendGridEmailsPage2: React.FC = () => {
  const [sendGridEmails,setsendGridEmails] = useState<ISendGridEmail | null>();


  customFetch<ISendGridEmail>('/sendgridemails', EFetchMethod.GET, undefined)      
  .then(function(response) {
    setsendGridEmails(response);
  });


  
  return (
    <Container>
      <Heading>HELLO WORLD {sendGridEmails?.date!}</Heading> 
      </Container>
      )

}
*/

export default SendGridEmailsComponent;