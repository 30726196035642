import { TFunction } from "i18next";
import { ETranslation } from "../translations/translation-keys";



export const minutesToStrings = (t: TFunction, mins?: number) => {
	if (!mins || mins === 0) return "0" + t(ETranslation.COMMON_MIN);
	const minutesPerHour = 60;
    const hoursPerDay = 24;
    const minutesPerDay = minutesPerHour * hoursPerDay;

    const days = Math.floor(mins / minutesPerDay);
    const remainingMinutesAfterDays = mins % minutesPerDay;
    const hours = Math.floor(remainingMinutesAfterDays / minutesPerHour);
    const minutes = remainingMinutesAfterDays % minutesPerHour;

	const showDays = days > 0;
	const showHours = showDays || hours > 0;

	const array = [];
	if (showDays) array.push(days + 'pv');
	if (showHours) array.push(hours + "t");
	array.push(minutes + t(ETranslation.COMMON_MIN));
	return array.join(" ").trim();
}