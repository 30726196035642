import { faSortAlphaDown, faSortAlphaUp, faSortNumericDown, faSortNumericUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useContext } from 'react';
import TableSortContext from '../TableSortContext';
import { TSortType } from '../useTableSort';



interface IProps {
  children: ReactNode;
  sort: string;
  numeric?: boolean;
  type?: TSortType;
  format?: string;
}

const TableTh: React.FC<IProps & React.HTMLProps<HTMLTableCellElement>> = ({ children, sort, numeric, type, colSpan, format }) => {
  const { onSort, sort: contextSort } = useContext(TableSortContext);


  const classNames = [];
  if (sort) {
    classNames.push('pointer');
  }

  let iconUp = faSortAlphaUp;
  let iconDown = faSortAlphaDown;

  if(numeric) {
    iconUp = faSortNumericUp;
    iconDown = faSortNumericDown;
  }

  return (
    <th
	colSpan={colSpan}
      className={classNames.join(" ")}
      onClick={sort ? () => onSort(sort, type ?? "string", format) : undefined}
    >
      {children}
      {sort && sort === contextSort.key && (
        <FontAwesomeIcon
          style={{ marginLeft: '.25rem' }}
          icon={contextSort.reverse ? iconUp : iconDown}
        />
      )}
    </th>
  );
}

export default TableTh;