import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import Container from "../../components/ui/Container/Container";
import Heading from "../../components/Heading/Heading";
import { EInputType, IInputField } from "../../components/ui/Input/Input";
import { ETranslation } from "../../translations/translation-keys";
import { useCreateInput } from "../../hooks/useCreateInput";
import Button from "../../components/ui/Button/Button";
import { getInputData } from "../../components/ui/Input/input-utils";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../store/actions/authActions";
import { useTranslation } from "react-i18next";
import IAppState from "../../interfaces/store/IAppState";
import Alert, { EAlertColor } from "../../components/ui/Alert/Alert";
import Spinner from "../../components/ui/Spinner/Spinner";

enum EInputs {
	username = "username",
	password = "password",
}

interface loginInfo {
	username: string;
	password: string;
}

const LoginPage: React.FC<RouteComponentProps> = ({ history }) => {

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { user, loginError, loading } = useSelector(
		(state: IAppState) => (state.auth)
	)

	const [errorMessage, setError] = useState<string | null>(null)
	const [successMessage, setSuccessMessage] = useState<boolean>(false)

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.username]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_EMAIL,
			value: "",
		},
		[EInputs.password]: {
			type: EInputType.password,
			labelTranslation: ETranslation.USER_PASSWORD,
			value: "",
		},
	})
	
	const login = () => {
		if(loading) return;
		const data = getInputData<loginInfo>(inputs);
		dispatch(userLogin(data.username, data.password))
	}

	useEffect(() => {
		loginError ? setError(loginError) : setError(null);
	}, [loginError])

	useEffect(() => {
		if(loading) return;
		if(user){
			setSuccessMessage(true);
			setTimeout(()=>{
				window.location.replace("/console")
			}, 2000)
		}
	}, [user, loading, setSuccessMessage, history])


	const createInput = useCreateInput(inputs, setInputs);

	return (
		<Container>
			<Heading>
				{t(ETranslation.LOGIN)}
			</Heading>
			<Container>
				{createInput(EInputs.username)}
				{createInput(EInputs.password, {onKeyup: (e)=>{
					if(e.key === "Enter"){
						login();
					}
				}})}
				{successMessage ? <Alert color={EAlertColor.SUCCESS}>{t(ETranslation.LOGIN_SUCCESS)}</Alert> : null}
				{errorMessage ? <Alert>{errorMessage}</Alert> : null}
				<Button onClick={login} disabled={loading}>{loading && <Spinner />}{t(ETranslation.LOGIN)}</Button>
			</Container>
		</Container>
	);
};

export default LoginPage;
