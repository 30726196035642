import React, { useEffect, useState } from "react";
import { useCreateInput } from "../../hooks/useCreateInput";

import { ILogItem } from "../../interfaces/ILogItem";
import { ETranslation } from "../../translations/translation-keys";
import {
  EInputType,
  IInputField,
} from "../ui/Input/Input";
import {
  getInputData,
  initForm,
  validateInputs,
} from "../ui/Input/input-utils";

enum EInputs {
  message = "message",
  event = "event",
  adminNotes = "adminNotes",
  browser = "browser",
  city = "city",
  country = "country",
  region = "region",
  fullUrl = "fullUrl",
  ipAddress = "ipAddress",
  operatingSystem = "operatingSystem",
  referer = "referer",
  userAgent = "userAgent",
  user = "user",
  created = "created",
  summary = "summary",
  userFirstName = "userFirstName",
  userLastName = "userLastName",
}

interface IProps {
  onChange: (logItem: ILogItem, isValid: boolean) => void;
  logItem: ILogItem | null;
  loading?: boolean;
  showValidation?: boolean;
}

const LogItem: React.FC<IProps> = ({
  onChange,
  loading,
  logItem,
  showValidation,
}) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.event]: {
      type: EInputType.static,
      labelTranslation: ETranslation.LOG_ITEM_LIST_EVENT,
      labelClassName: "bold",
      value: "",
    },
    [EInputs.userFirstName]: {
      type: EInputType.static,
      labelTranslation: ETranslation.COMMON_FIRST_NAME,
      labelClassName: "bold",
      value: "",
    },
    [EInputs.userLastName]: {
      type: EInputType.static,
      labelTranslation: ETranslation.COMMON_LAST_NAME,
      labelClassName: "bold",
      value: "",
    },
    [EInputs.message]: {
      type: EInputType.static,
      labelTranslation: ETranslation.COMMON_MESSAGE,
      labelClassName: "bold",
      value: "",
    },
    [EInputs.adminNotes]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_ADMIN_NOTES,
      labelClassName: "bold",
      value: "",
    },
    [EInputs.browser]: {
      type: EInputType.static,
      labelTranslation: ETranslation.LOG_ITEM_LIST_BROWSER,
      labelClassName: "bold",
      value: "",
    },
    [EInputs.city]: {
      type: EInputType.static,
      labelTranslation: ETranslation.LOG_ITEM_LIST_CITY,
      labelClassName: "bold",
      value: "",
    },
    [EInputs.country]: {
      type: EInputType.static,
      labelTranslation: ETranslation.COMMON_COUNTRY,
      labelClassName: "bold",
      value: "",
    },
    [EInputs.region]: {
      type: EInputType.static,
      labelTranslation: ETranslation.LOG_ITEM_LIST_REGION,
      labelClassName: "bold",
      value: "",
    },
    [EInputs.fullUrl]: {
      type: EInputType.static,
      labelTranslation: ETranslation.LOG_ITEM_LIST_FULLURL,
      labelClassName: "bold",
      value: "",
    },
    [EInputs.ipAddress]: {
      type: EInputType.static,
      labelTranslation: ETranslation.LOG_ITEM_LIST_IPADDRESS,
      labelClassName: "bold",
      value: "",
    },
    [EInputs.operatingSystem]: {
      type: EInputType.static,
      labelTranslation: ETranslation.LOG_ITEM_LIST_OPERATINGSYSTEM,
      labelClassName: "bold",
      value: "",
    },
    [EInputs.referer]: {
      type: EInputType.static,
      labelTranslation: ETranslation.LOG_ITEM_LIST_REFERER,
      labelClassName: "bold",
      value: "",
    },
    [EInputs.userAgent]: {
      type: EInputType.static,
      labelTranslation: ETranslation.COMMON_USER,
      labelClassName: "bold",
      value: "",
    },
    [EInputs.created]: {
      type: EInputType.static,
      labelTranslation: ETranslation.COMMON_CREATED,
      labelClassName: "bold",
      value: "",
    },
    [EInputs.summary]: {
      type: EInputType.static,
      labelTranslation: ETranslation.LOG_ITEM_LIST_SUMMARY,
      labelClassName: "bold",
      value: "",
    },
  });

  useEffect(() => {
    if (logItem) {
      initForm(setInputs, logItem);
    }
    // eslint-disable-next-line
  }, [logItem]);

  useEffect(() => {
    const logItem = getInputData<ILogItem>(inputs);
    const isValid = validateInputs(inputs);
    onChange(logItem, isValid);
  }, [inputs, onChange]);


  const createInput = useCreateInput(inputs, setInputs, {
    showValidation,
    disabled: loading
  });

  return (
    <>
      {createInput(EInputs.created)}
      {createInput(EInputs.userFirstName)}
      {createInput(EInputs.userLastName)}
      {createInput(EInputs.event)}
      {createInput(EInputs.message)}
      {createInput(EInputs.summary)}
      {createInput(EInputs.adminNotes)}
      {createInput(EInputs.browser)}
      {createInput(EInputs.city)}
      {createInput(EInputs.country)}
      {createInput(EInputs.region)}
      {createInput(EInputs.fullUrl)}
      {createInput(EInputs.ipAddress)}
      {createInput(EInputs.referer)}
      {createInput(EInputs.userAgent)}

    </>
  );
};

export default LogItem;
