import { IAttribute } from '../../interfaces/IAttribute';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IAttributeAction from '../../interfaces/store/IAttributeAction';
import IAttributeState from '../../interfaces/store/IAttributeState';
import { ETranslation } from '../../translations/translation-keys';
import { removeOne } from '../../utils/reducer-utils';
import { insertFirst, updateOne } from '../../utils/reducer-utils';

type ReducerAction = IAttributeAction;
type ReducerState = IAttributeState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  attribute: null,

  attributes: null,
  loading: false,
  error: null,
  
  deletingError: null,
  deleting: false,
  deletingOk: false,

  saveOrUpdateOk: false
};

const findCategoryStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const findCategorySuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    attributes: action.attributes || null,
    error: null,
    loading: false,
  };
};

const findCategoryFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, attributes: null, loading: false, error: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getCategoryStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const getCategorySuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    attribute: action.attribute || null,
    error: null,
    loading: false,
  };
};

const getCategoryFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, attribute: null, loading: false, error: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getCategoryClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, attribute: null, loading: false, error: null};
};

const deleteCategoryStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deletingError: null, deleting: true, deletingOk: false, };
};

const deleteCategorySuccess: ReducerSignature = (state, action): ReducerState => {
  const attributes = removeOne<IAttribute>(state.attributes, action.id);
  return {
    ...state,
    attributes,
    deletingError: null,
    deleting: false,
    deletingOk: true,
  };
};

const deleteCategoryFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deleting: false, deletingError: action.error as string, deletingOk: false, };
};

const deleteCategoryClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deletingError: null, deleting: false, deletingOk: false, };
};


const saveCategoryStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const saveCategorySuccess: ReducerSignature = (state, action): ReducerState => {
  const attributes = insertFirst(state.attributes, action.attribute)
  return {
    ...state,
    attributes,
    error: null,
    loading: false,
    saveOrUpdateOk: true
  };
};

const saveCategoryFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const updateCategoryStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const updateCategorySuccess: ReducerSignature = (state, action): ReducerState => {
  const attributes = updateOne(state.attributes, action.attribute);
  return {
    ...state,
    error: null,
    loading: false,
    saveOrUpdateOk: true,
    attributes
  };
};

const updateCategoryFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const saveOrUpdateCategoryClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: null, saveOrUpdateOk: false }
}

const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.ATTRIBUTE_FIND_START:
      return findCategoryStart(state, action);
    case EActionTypes.ATTRIBUTE_FIND_SUCCESS:
      return findCategorySuccess(state, action);
    case EActionTypes.ATTRIBUTE_FIND_FAIL:
      return findCategoryFail(state, action);
    case EActionTypes.ATTRIBUTE_GET_START:
      return getCategoryStart(state, action);
    case EActionTypes.ATTRIBUTE_GET_SUCCESS:
      return getCategorySuccess(state, action);
    case EActionTypes.ATTRIBUTE_GET_FAIL:
      return getCategoryFail(state, action);
    case EActionTypes.ATTRIBUTE_GET_CLEAR:
      return getCategoryClear(state, action);
    case EActionTypes.ATTRIBUTE_DELETE_START:
      return deleteCategoryStart(state, action);
    case EActionTypes.ATTRIBUTE_DELETE_SUCCESS:
      return deleteCategorySuccess(state, action);
    case EActionTypes.ATTRIBUTE_DELETE_FAIL:
      return deleteCategoryFail(state, action);
    case EActionTypes.ATTRIBUTE_DELETE_CLEAR:
      return deleteCategoryClear(state, action);
    case EActionTypes.ATTRIBUTE_SAVE_START:
      return saveCategoryStart(state, action);
    case EActionTypes.ATTRIBUTE_SAVE_SUCCESS:
      return saveCategorySuccess(state, action);
    case EActionTypes.ATTRIBUTE_SAVE_FAIL:
      return saveCategoryFail(state, action);
    case EActionTypes.ATTRIBUTE_UPDATE_START:
      return updateCategoryStart(state, action);
    case EActionTypes.ATTRIBUTE_UPDATE_SUCCESS:
      return updateCategorySuccess(state, action);
    case EActionTypes.ATTRIBUTE_UPDATE_FAIL:
      return updateCategoryFail(state, action);
    case EActionTypes.ATTRIBUTE_SAVE_OR_UPDATE_CLEAR:
      return saveOrUpdateCategoryClear(state, action)
    default:
      return state;
  }
};

export default reducer;
