import React, { useEffect, useState } from "react";
import { EInputType, IInputField } from "../../ui/Input/Input";
import InputGroup from "../../ui/InputGroup/InputGroup";
import { getInputData } from "../../ui/Input/input-utils";
import { ETranslation } from "../../../translations/translation-keys";
import { useCreateInput } from "../../../hooks/useCreateInput";


interface IProps {
	inputTranslation: ETranslation;
	setFilter: React.Dispatch<React.SetStateAction<boolean>>;
}

enum EInput {
	boolean = "boolean",
}

const UserBooleanTableFilter: React.FC<IProps> = ({ inputTranslation, setFilter }) => {

	const initialInputs = {
		[EInput.boolean]: {
			type: EInputType.boolean,
			labelTranslation: inputTranslation,
			value: false,
		},
	}

	const [inputs, setInputs] = useState<IInputField>(initialInputs);
	const createInput = useCreateInput(inputs, setInputs);

	useEffect(()=>{
		const data = getInputData<{boolean: boolean}>(inputs);
		setFilter(data.boolean);
	}, [inputs, setFilter])

	return <InputGroup>{createInput(EInput.boolean)}</InputGroup>
}

export default UserBooleanTableFilter;