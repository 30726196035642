import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, parse } from "date-fns";
import * as dateFns from "date-fns";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CUSTOMER_PAYMENT_METHODS, ECustomerPaymentMethod, EUserRole, getPaymentMethodString } from "../../../classes/User";
import ModalContext, { EModalSize } from "../../../components/ui/Modal/ModalContext";
import { useConfirmModal } from "../../../hooks/useConfirmModal";
import { useInterpretation } from "../../../hooks/useInterpretation";
import { useLanguages } from "../../../hooks/useLanguages";
import { IAttribute } from "../../../interfaces/IAttribute";
import { IConnectionDetail, IInterpretation, ILocationDetail } from "../../../interfaces/IInterpretation";
import IAppState from "../../../interfaces/store/IAppState";
import NewsItemCreateReview from "../../../pages/NewsItem/NewsItemsList/NewsItemsCreateReview";
import { searchOrganizations } from "../../../services/searchServices";
import {
	EInterpretationStatus,
	EInterpreterFeeType,
	getInterpreterGenderString,
	INTERPRETATION_GENDER,
	INTERPRETATION_PREFILLED_CUSTOMER_NOTES_OPTIONS,
	INTERPRETATION_STATUS,
	INTERPRETER_FEE_TYPE_ALL,
} from '../../../shared/interpretation-data';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';
import { createOptions } from '../../../utils/option-utils';
import AttributesBadgeList from '../../Attributes/AttributesBadgeList';
import { useAttributeSelectModal } from '../../Attributes/useAttributeSelectModal';
import EditableField from '../../EditableField/EditableField';
import Heading from '../../Heading/Heading';
import LocationDetailEdit from '../../LocationDetailEdit/LocationDetailEdit';
import { useNotificationModal } from '../../NotificationModal/useNotificationModal';
import Accordion from '../../ui/Accordion/Accordion';
import Button from '../../ui/Button/Button';
import { EInputType, TInputValue } from '../../ui/Input/Input';
import InterpretersOffered from '../InterpretationOfferedInterpreters/InterpretersOffered';
import ConnectionDetail from './ConnectionDetail/ConnectionDetail';
import InterpretationActions from './InterpretationActions/InterpretationActions';
import InterpretationContactModal from './InterpretationContactModal/InterpretationContactModal';
import classes from './interpretationDetails.module.scss';
import InterpretationEmails from './InterpretationEmails/InterpretationEmails';
import InterpretationFeeDetails from './InterpretationFeeDetails/InterpretationFeeDetails';
import InterpretationLogRows from './InterpretationLogRows/InterpretationLogRows';
import InterpretationPushMessages from './InterpretationPushMessages/InterpretationPushMessages';
import InterpretationUserDetails from './InterpretationUserDetails/InterpretationUserDetails';
import SiteOnlyContent from '../../ui/SiteOnlyContent/SiteOnlyContent';
import { ESitePrefix } from '../../../interfaces/ISite';
import { useLanguageName } from "../../../hooks/useLanguageName";
import { ECategoryType } from "../../../interfaces/ICategory";

export enum EInterpretation {
	status = "status",
	type = "type",
	organizations = "organizations",
	bookingStartDateTime = "bookingStartDateTime",
	deleteInterpretationFeeRow = "deleteInterpretationFeeRow",
	updateInterpretationFeeRow = "updateInterpretationFeeRow",
	lostMinutes = "lostMinutes",
	paymentMethodType = "paymentMethodType",
	bookingInMinutes = "bookingInMinutes",
	customerReference2 = "customerReference2",
	customerReviewText = "customerReviewText",
	interpreterReviewText = "interpreterReviewText",
	reclamationText = "reclamationText",
	startDateTime = "startDateTime",
	endDateTime = "endDateTime",
	gender = "gender",
	fromLanguage = "fromLanguage",
	startTime = "startTime",
	bookingEndDateTime = "bookingEndDateTime",
	durationInMinutes = "durationInMinutes",
	interpreterFeeWithoutVatPerMinute = "interpreterFeeWithoutVatPerMinute",
	minBillingPeriodInMinutes = "minBillingPeriodInMinutes",
	duration = "duration",
	customerCancelDateTime = "customerCancelDateTime",
	averageResponseTime = "averageResponseTime",
	priceWithoutVatPerMinute = "priceWithoutVatPerMinute",
	customer = "customer",
	interpreterCancelDateTime = "interpreterCancelDateTime",
	fromLanguageName = "fromLanguageName",
	tags = "tags",
	toLanguageName = "toLanguageName",
	interpreter = "interpreter",
	toLanguage = "toLanguage",
	customerNotes = "customerNotes",
	customerReference = "customerReference",
	interpreterInfoText = "interpreterInfoText",
	billSentDateTime = "billSentDateTime",
	locationDetail = "locationDetail",
	customerReview = "customerReview",
	interpreterReview = "interpreterReview",
	adminNotesText = "adminNotesText",
	feeType = "feeType",
	paymentStatus = "paymentStatus",
	interpreterForInterpretationUserRows = "interpreterForInterpretationUserRows",
	connectionDetail = "connectionDetail",
	deleteInterpretersInterpretationUserRow = "deleteInterpretersInterpretationUserRow",
	addInterpretationUserRows = "addInterpretationUserRows",
	attributeIds = "attributeIds",
	subtypeIds = "subtypeIds",
	salaryPerHour = "salaryPerHour",
}

export enum EInterpretationFieldNeedReload {
	deleteInterpretationFeeRow = "deleteInterpretationFeeRow",
	updateInterpretationFeeRow = "updateInterpretationFeeRow",
	interpreterForInterpretationUserRows = "interpreterForInterpretationUserRows",
	deleteInterpretersInterpretationUserRow = "deleteInterpretersInterpretationUserRow",
}

const StaticField: React.FC<{ title: ETranslation | string; value: string }> = ({ title, value }) => {
	const { t } = useTranslation();
	return (
		<div>
			<p>
				<b>{t(title)}</b>
			</p>
			<p>{value}</p>
		</div>
	);
};

interface IProps {
	interpretation: IInterpretation;
	updateLoadingProperty: string | null;
}

const InterpretationDetails: React.FC<IProps> = ({ interpretation, updateLoadingProperty }) => {
	const { t } = useTranslation();
	const { getInterpretationTypeString, getSiteInterpretationTypes, getFeeTypeString } = useInterpretation();
	const dispatch = useDispatch();
	const openNotificationModal = useNotificationModal();
	const [reloadableUpdate, setReloadableUpdate] = useState<boolean>(false);
	const fromLanguage  = useLanguageName(interpretation.fromLanguage);
	const toLanguage  = useLanguageName(interpretation.toLanguage);

	useEffect(() => {
		if (reloadableUpdate && updateLoadingProperty) return;
		if (
			updateLoadingProperty !== null &&
			Object.values(EInterpretationFieldNeedReload).filter(
				(value) => value.valueOf() === updateLoadingProperty
			) &&
			!reloadableUpdate
		)
			setReloadableUpdate(true);
		if (reloadableUpdate && !updateLoadingProperty) {
			setReloadableUpdate(false);
			dispatch(actions.getInterpretation(interpretation.id));
		}
	}, [updateLoadingProperty, reloadableUpdate, interpretation, dispatch]);

	const { currentUser: user, tags } = useSelector((state: IAppState) => ({
		currentUser: state.auth.user,
		tags: state.tags.tags,
	}));

	useEffect(() => {
		dispatch(actions.getTags());
	}, [dispatch]);

	const [customerReview, setCustomerReview] = useState<{ rating: number; review: string } | null>(null);
	const [interpreterReview, setInterpreterReview] = useState<{ rating: number; review: string } | null>(null);

	useEffect(() => {
		if (!interpretation.interpretationReviews) return;
		interpretation.interpretationReviews.forEach((review) => {
			if (review.userRole === "CUSTOMER") {
				setCustomerReview({ rating: review.rating, review: review.reviewText });
			} else if (review.userRole === "INTERPRETER") {
				setInterpreterReview({ rating: review.rating, review: review.reviewText });
			}
		});
		return () => {
			setInterpreterReview(null);
			setCustomerReview(null);
		};
	}, [interpretation]);

	const { languageOptions } = useLanguages();

	const { setModal, closeModal } = useContext(ModalContext);
	const useModal = () => {
		const openModal = (customer: boolean, handle: number) => {
			setModal({
				title: "Arviointi",
				content:
					interpretation?.interpretationReviews?.[handle]?.reviewText == null &&
						interpretation?.interpretationReviews?.[handle]?.rating == null ? (
						<div>{t(ETranslation.NEWS_NO_REVIEW)}</div>
					) : (
						<NewsItemCreateReview customer={customer} interpretation={interpretation} />
					),
				isOpen: true,
				onModalClose: () => dispatch(actions.newsItemSaveReviewClear()),
				size: EModalSize.MEDIUM,
			});
		};
		return { openModal };
	};
	const { openModal } = useModal();

	const handleClick = (customer: boolean, isWhose: string, handle: number) => {
		openModal(customer, handle);
	};

	const changeContactDetails = () => {
		setModal({
			isOpen: true,
			size: EModalSize.SMALL,
			title: t(ETranslation.INTREPRETATION_CONNECTION_TYPE),
			content: (
				<InterpretationContactModal
					connectionDetails={interpretation.connectionDetail}
					onConfirm={(data) => {
						editHandler(EInterpretation.connectionDetail, data);
						closeModal();
					}}
					cancelHandler={() => {
						closeModal();
					}}
				/>
			),
		});
	};

	const sendEmailOrPushMessageToCustomer = useCallback(
		async (propertyName: EInterpretation, value: TInputValue | ILocationDetail | IConnectionDetail) => {
			const canNotify =
				propertyName === EInterpretation.status &&
				[EInterpretationStatus.FAVORITES_NOT_FOUND, EInterpretationStatus.INTERPRETERS_NOT_FOUND].includes(
					value as EInterpretationStatus
				);
			if (canNotify) {
				return await openNotificationModal("Vaihda tila ja lähetä valitut ilmoitukset asiakkaalle.");
			}
			return { sendPushMessage: false, sendEmail: false };
		},
		[openNotificationModal]
	);

	const editHandler = useCallback(
		async (
			propertyName: EInterpretation,
			value: TInputValue | ILocationDetail | IConnectionDetail,
			secondaryValue?: TInputValue
		) => {
			if (!interpretation) return;
			if (
				(propertyName === EInterpretation.startDateTime ||
					propertyName === EInterpretation.bookingStartDateTime) &&
				value &&
				secondaryValue
			) {
				const date = parse(value + " " + secondaryValue, "yyyy-MM-dd HH:mm", new Date());
				value = format(date, "dd.MM.yyyy HH:mm");
			} else if (
				propertyName === EInterpretation.customerCancelDateTime ||
				propertyName === EInterpretation.interpreterCancelDateTime ||
				propertyName === EInterpretation.billSentDateTime ||
				propertyName === EInterpretation.bookingEndDateTime
			) {
				value = format(value as Date, "dd.MM.yyyy HH:mm");
			}
			const { sendEmail, sendPushMessage } = await sendEmailOrPushMessageToCustomer(propertyName, value);
			dispatch(
				actions.updateInterpretationProperty(interpretation.id, propertyName, value, sendEmail, sendPushMessage)
			);
		},
		[dispatch, interpretation, sendEmailOrPushMessageToCustomer]
	);
	if (!interpretation) {
		return null;
	}

	const isShowAttributes = user?.isShowAttributes;


	return (
		<>
			<SiteOnlyContent>
				<InterpretationActions interpretation={interpretation} model={"INTERPRETATION"} />
			</SiteOnlyContent>
			<Heading>{t(ETranslation.LOG_ITEM_LIST_INTERPRETATION)}</Heading>
			<div className={classes.fieldsContainer}>
				<span className={classes.dividerHeader}>{t(ETranslation.COMMON_USERS)}</span>
				<InterpretationUserDetails
					user={interpretation.customer}
					editable={true}
					editHandler={editHandler}
					updateLoadingProperty={updateLoadingProperty}
					userRole={EUserRole.CUSTOMER}
				/>
				<InterpretationUserDetails
					user={interpretation.interpreter}
					editable={true}
					editHandler={editHandler}
					updateLoadingProperty={updateLoadingProperty}
					userRole={EUserRole.INTERPRETER}
				/>

				<div className={classes.detailsContainer}>
					<h3>{t(ETranslation.COMMON_PAYMENTS)}</h3>
					<span>
						{t(ETranslation.INTERPRETATION_INFO_INTERPRETER_INVOICE)}:{" "}
						<b>{interpretation.interpreterFeeWithoutVatPerMinute}€</b>
					</span>
					<span>
						{t(ETranslation.INTERPRETATION_INTERPRETER_PAYMENT)}:{" "}
						<b>
							{interpretation.interpreterFeeWithoutVat}({interpretation.interpreterFee})€
						</b>
					</span>
					<span>
						{t(ETranslation.INTERPRETATION_INTERPRETERS_PAYMENT_TYPE)}:{" "}
						<b>{interpretation.feeType ? getFeeTypeString(t, interpretation.feeType) : ""}</b>
					</span>
					<span>
						{t(ETranslation.INTERPRETATION_CUSTOMER_PAYMENT)}:{" "}
						<b>{interpretation.priceWithoutVatPerMinute}€</b>
					</span>
					<span>
						{t(ETranslation.INTERPRETATION_INFO_CUSTOMER_INVOICE)}:{" "}
						<b>
							{interpretation.priceWithoutVat}({interpretation.price})€
						</b>
					</span>
					<span>
						{t(ETranslation.INTERPRETATION_CUSTOMER_PAYMENT_TYPE)}:{" "}
						<b>
							{interpretation.paymentMethodType
								? getPaymentMethodString(t, interpretation.paymentMethodType)
								: ""}
						</b>
					</span>
				</div>
				<span className={classes.dividerHeader}>{t(ETranslation.USER_ACCORDION_BASIC)}</span>
				<EditableField
					propertyName={EInterpretation.status}
					initialValue={interpretation.status}
					onEdit={editHandler}
					labelTranslation={ETranslation.COMMON_STATUS}
					type={EInputType.select}
					options={INTERPRETATION_STATUS}
					loading={updateLoadingProperty === EInterpretation.status}
				/>
				{/* funky */}
				<EditableField
					propertyName={EInterpretation.type}
					initialValue={interpretation.type}
					displayValue={getInterpretationTypeString(t, interpretation.type)}
					onEdit={editHandler}
					labelTranslation={ETranslation.COMMON_TYPE}
					type={EInputType.select}
					options={getSiteInterpretationTypes()}
					loading={updateLoadingProperty === EInterpretation.type}
				/>
				<InterpretationAttributes attributes={interpretation.subtypes} onSave={editHandler} propertyName={EInterpretation.subtypeIds} />
				<StaticField
					title={ETranslation.COMMON_CREATED}
					value={interpretation.createdDateTime || interpretation.createdDate}
				/>
				<StaticField title={ETranslation.COMMON_ORDER_NUMBER} value={interpretation.number.toString() ?? ""} />
				<EditableField
					propertyName={EInterpretation.startDateTime}
					initialValue={interpretation.startDateISO}
					onEdit={editHandler}
					labelTranslation={ETranslation.INTREPRETATION_WORK_STARTED_AT}
					type={EInputType.date}
					initialSecondaryValue={interpretation.startTime}
					displayValue={interpretation.startDateTime}
					loading={updateLoadingProperty === EInterpretation.startDateTime}
				/>
				<StaticField
					title={ETranslation.INTREPRETATION_WORK_ENDED_AT}
					value={interpretation.endDateTime ?? ""}
				/>
				{/* <EditableField
					propertyName={EInterpretation.endDateTime}
					initialValue={interpretation.endDateISO}
					onEdit={(_, date, time) => {
						if (date && time) {
							// prevent empty
							const endDate = dateUtils.addTimeToDate(date as string, time as string);
							const minutes = dateFns.differenceInMinutes(
								new Date(endDate),
								new Date(interpretation.startDateUTC)
							);
							editHandler(EInterpretation.durationInMinutes, minutes);
						}
					}}
					labelTranslation={ETranslation.INTREPRETATION_WORK_ENDED_AT}
					type={EInputType.date}
					initialSecondaryValue={interpretation.endTime}
					displayValue={interpretation.endDateTime}
					loading={updateLoadingProperty === EInterpretation.durationInMinutes}
				/> */}
				<EditableField
					propertyName={EInterpretation.durationInMinutes}
					initialValue={interpretation.durationInMinutes}
					onEdit={editHandler}
					labelTranslation={ETranslation.COMMON_DURATION_IN_MINUTES}
					type={EInputType.number}
					loading={updateLoadingProperty === EInterpretation.durationInMinutes}
				/>
				{user?.role === "ADMIN" && (
					<EditableField
						propertyName={EInterpretation.adminNotesText}
						initialValue={interpretation.adminNotesText}
						onEdit={editHandler}
						labelTranslation={ETranslation.COMMON_ADMIN_NOTES}
						type={EInputType.textarea}
						loading={updateLoadingProperty === EInterpretation.adminNotesText}
					/>
				)}
				<EditableField
					propertyName={EInterpretation.customerNotes}
					initialValue={interpretation.customerNotes ?? "-"}
					onEdit={editHandler}
					options={INTERPRETATION_PREFILLED_CUSTOMER_NOTES_OPTIONS}
					labelTranslation={ETranslation.INTERPRETATION_CUSTOMER_NOTES_TEXT}
					loading={updateLoadingProperty === EInterpretation.customerNotes}
				/>
				<EditableField
					propertyName={EInterpretation.interpreterInfoText}
					initialValue={interpretation.interpreterInfoText ? interpretation.interpreterInfoText : "-"}
					onEdit={editHandler}
					labelTranslation={ETranslation.COMMON_INFO_TO_INTERPRETER}
					loading={updateLoadingProperty === EInterpretation.interpreterInfoText}
				/>
				<LocationDetailEdit
					initialAddress={interpretation?.locationDetail?.address ?? "-"}
					initialZip={interpretation.locationDetail?.zip}
					initialCity={interpretation.locationDetail?.city}
					initialInfo={interpretation.locationDetail?.info}
					onEdit={(value) => editHandler(EInterpretation.locationDetail, value)}
					labelTranslation={ETranslation.COMMON_ADDRESS}
					displayValue={`${interpretation.locationDetail?.address ?? ""} ${interpretation.locationDetail?.zip ?? ""
						} ${interpretation.locationDetail?.city ?? ""} ${interpretation.locationDetail?.info ?? ""}`}
					loading={updateLoadingProperty === EInterpretation.locationDetail}
				/>
				{isShowAttributes && (
					<InterpretationAttributes attributes={interpretation.attributes} onSave={editHandler} propertyName={EInterpretation.attributeIds} />
				)}
				<SiteOnlyContent sites={[ESitePrefix.valitysklinikka]}>
					<EditableField
						propertyName={EInterpretation.salaryPerHour}
						initialValue={interpretation.salaryPerHour ?? "-"}
						type={EInputType.number}
						onEdit={editHandler}
						labelTranslation={ETranslation.COMMON_SALARY_PER_HOUR}
						loading={updateLoadingProperty === EInterpretation.salaryPerHour}
					/>
				</SiteOnlyContent>

				<SiteOnlyContent>
					<EditableField
						propertyName={EInterpretation.customerCancelDateTime}
						initialValue={
							interpretation.customerCancelDateTime
								? dateFns.parse(interpretation.customerCancelDateTime, "dd.MM.yyyy HH:mm", new Date())
								: ""
						}
						onEdit={editHandler}
						labelTranslation={ETranslation.INTERPRETATION_CANCELED_CUSTOMER}
						type={EInputType.datetimepicker}
						displayValue={interpretation.customerCancelDateTime}
						loading={updateLoadingProperty === EInterpretation.customerCancelDateTime}
					/>
					<EditableField
						propertyName={EInterpretation.interpreterCancelDateTime}
						initialValue={
							interpretation.interpreterCancelDateTime
								? dateFns.parse(
									interpretation.interpreterCancelDateTime,
									"dd.MM.yyyy HH:mm",
									new Date()
								)
								: ""
						}
						onEdit={editHandler}
						labelTranslation={ETranslation.INTERPRETATION_CANCELED_WORKER}
						type={EInputType.datetimepicker}
						displayValue={interpretation.interpreterCancelDateTime}
						loading={updateLoadingProperty === EInterpretation.interpreterCancelDateTime}
					/>
					<EditableField
						propertyName={EInterpretation.gender}
						initialValue={interpretation.gender ?? ""}
						onEdit={editHandler}
						labelTranslation={ETranslation.INTERPRETATION_GENDER_IN_ORDER}
						type={EInputType.reactSelect}
						options={INTERPRETATION_GENDER}
						displayValue={getInterpreterGenderString(t, interpretation.gender)}
						loading={updateLoadingProperty === EInterpretation.gender}
					/>
					<EditableField
						propertyName={EInterpretation.tags}
						initialValue={interpretation.tags ? createOptions(interpretation.tags) : []}
						onEdit={editHandler}
						labelTranslation={ETranslation.COMMON_TAGS}
						type={EInputType.reactSelect}
						multiple={true}
						options={
							tags
								? tags?.map((tag) => {
									return { value: tag.id, label: tag.name };
								})
								: []
						}
						displayValue={interpretation.tags
							?.map((tag) => {
								return tag.name;
							})
							.join(", ")}
						loading={updateLoadingProperty === EInterpretation.tags}
					/>
					<EditableField
						propertyName={EInterpretation.organizations}
						initialValue={interpretation.organizations ? createOptions(interpretation.organizations) : []}
						onEdit={editHandler}
						labelTranslation={ETranslation.COMMON_ORGANIZATIONS}
						type={EInputType.reactSelectSearch}
						multiple={true}
						fetchOptions={searchOrganizations}
						displayValue={interpretation.organizations
							?.map((item) => {
								return item.name;
							})
							.join(", ")}
						loading={updateLoadingProperty === EInterpretation.organizations}
					/>
					<EditableField
						propertyName={EInterpretation.reclamationText}
						initialValue={interpretation.reclamationText ?? ""}
						onEdit={editHandler}
						labelTranslation={ETranslation.INTERPRETATTION_RECLAMATION}
						type={EInputType.textarea}
						displayValue={interpretation.reclamationText}
						loading={updateLoadingProperty === EInterpretation.reclamationText}
					/>
					<EditableField
						propertyName={EInterpretation.fromLanguage}
						initialValue={interpretation.fromLanguage ?? ""}
						onEdit={editHandler}
						labelTranslation={ETranslation.COMMON_FROM_LANGUAGE}
						type={EInputType.reactSelect}
						options={languageOptions}
						displayValue={fromLanguage.languageName}
						loading={updateLoadingProperty === EInterpretation.fromLanguage}
					/>
					<EditableField
						propertyName={EInterpretation.toLanguage}
						initialValue={interpretation.toLanguage ?? ""}
						onEdit={editHandler}
						labelTranslation={ETranslation.COMMON_TO_LANGUAGE}
						type={EInputType.reactSelect}
						options={languageOptions}
						displayValue={toLanguage.languageName}
						loading={updateLoadingProperty === EInterpretation.toLanguage}
					/>
					<ConnectionDetail
						connection={interpretation.connectionDetail}
						onChange={changeContactDetails}
						loading={updateLoadingProperty === EInterpretation.connectionDetail}
					/>
					<span className={classes.dividerHeader}>{t(ETranslation.COMMON_PAYMENT_INFORMATION)}</span>
					<EditableField
						propertyName={EInterpretation.billSentDateTime}
						initialValue={
							interpretation.billSentDateTime
								? dateFns.parse(interpretation.billSentDateTime, "dd.MM.yyyy HH:mm", new Date())
								: ""
						}
						onEdit={editHandler}
						labelTranslation={ETranslation.COMMON_BILL_SENT_DATE}
						type={EInputType.datetimepicker}
						displayValue={interpretation.billSentDateTime}
						loading={updateLoadingProperty === EInterpretation.billSentDateTime}
					/>
					<EditableField
						propertyName={EInterpretation.feeType}
						initialValue={interpretation.feeType ?? ""}
						onEdit={editHandler}
						labelTranslation={ETranslation.COMMON_FEE_TYPE}
						type={EInputType.select}
						options={INTERPRETER_FEE_TYPE_ALL}
						displayValue={getFeeTypeString(
							t,
							interpretation.feeType ?? EInterpreterFeeType.FEE_TYPE_NO_PAYMENT
						)}
						loading={updateLoadingProperty === EInterpretation.feeType}
					/>
					<EditableField
						propertyName={EInterpretation.lostMinutes}
						initialValue={interpretation.lostMinutes ?? ""}
						onEdit={editHandler}
						labelTranslation={ETranslation.COMMON_LOST_MINUTES}
						type={EInputType.number}
						loading={updateLoadingProperty === EInterpretation.lostMinutes}
					/>
					<EditableField
						propertyName={EInterpretation.interpreterFeeWithoutVatPerMinute}
						initialValue={interpretation.interpreterFeeWithoutVatPerMinute ?? ""}
						onEdit={editHandler}
						labelTranslation={ETranslation.PAYMENT_FEE_WITHOUT_VAT}
						type={EInputType.number}
						loading={updateLoadingProperty === EInterpretation.interpreterFeeWithoutVatPerMinute}
					/>
					<EditableField
						propertyName={EInterpretation.minBillingPeriodInMinutes}
						initialValue={interpretation.minBillingPeriodInMinutes ?? ""}
						onEdit={editHandler}
						labelTranslation={ETranslation.PRICE_SEASON_MIN_BILLING_PERIOD}
						type={EInputType.number}
						loading={updateLoadingProperty === EInterpretation.minBillingPeriodInMinutes}
					/>
					{interpretation.bookable && (
						<>
							<EditableField
								propertyName={EInterpretation.bookingInMinutes}
								initialValue={interpretation.bookingInMinutes ?? 0}
								onEdit={editHandler}
								labelTranslation={ETranslation.COMMON_BOOKING_IN_MINUTES}
								type={EInputType.number}
								displayValue={interpretation.bookingInMinutes}
								loading={updateLoadingProperty === EInterpretation.bookingInMinutes}
							/>
							<EditableField
								propertyName={EInterpretation.bookingStartDateTime}
								initialValue={dateFns.format(
									new Date(interpretation.bookingStartDateUTC?.toString() ?? ""),
									"yyyy-MM-dd"
								)}
								onEdit={editHandler}
								labelTranslation={ETranslation.INTERPRETATION_BOOKING_STARTED}
								type={EInputType.date}
								initialSecondaryValue={dateFns.format(
									new Date(interpretation.bookingStartDateUTC?.toString() ?? ""),
									"HH:mm"
								)}
								displayValue={interpretation.bookingStartDateTime}
								loading={updateLoadingProperty === EInterpretation.bookingStartDateTime}
							/>
							<StaticField
								value={interpretation.bookingEndDateTime ?? ""}
								title={ETranslation.INTERPRETATION_BOOKING_ENDED}
							/>
						</>
					)}
					<EditableField
						propertyName={EInterpretation.paymentMethodType}
						initialValue={interpretation.paymentMethodType ?? ""}
						onEdit={editHandler}
						labelTranslation={ETranslation.INTERPRETATION_CUSTOMER_PAYMENT_TYPE}
						type={EInputType.select}
						options={CUSTOMER_PAYMENT_METHODS}
						displayValue={getPaymentMethodString(
							t,
							interpretation.paymentMethodType ?? ECustomerPaymentMethod.NO_CHARGE
						)}
						loading={updateLoadingProperty === EInterpretation.paymentMethodType}
					/>
					<EditableField
						propertyName={EInterpretation.customerReference}
						initialValue={interpretation.customerReference ? interpretation.customerReference : "-"}
						onEdit={editHandler}
						labelTranslation={ETranslation.COMMON_CUSTOMER_REFERENCE}
						loading={updateLoadingProperty === EInterpretation.customerReference}
					/>
					<EditableField
						propertyName={EInterpretation.customerReference2}
						initialValue={interpretation.customerReference2 ? interpretation.customerReference2 : "-"}
						onEdit={editHandler}
						labelTranslation={ETranslation.COMMON_CUSTOMER_REFERENCE_2}
						loading={updateLoadingProperty === EInterpretation.customerReference2}
					/>
					<EditableField
						propertyName={EInterpretation.priceWithoutVatPerMinute}
						initialValue={interpretation.priceWithoutVatPerMinute ?? ""}
						onEdit={editHandler}
						labelTranslation={ETranslation.INTERPRETATION_PRICE_WITOUTH_VAT_PER_MIN}
						type={EInputType.number}
						loading={updateLoadingProperty === EInterpretation.priceWithoutVatPerMinute}
					/>
				</SiteOnlyContent>
			</div>
			<Accordion title={t(ETranslation.INTERPRETERS_OFFERED)}>
				<InterpretersOffered interpretation={interpretation} editHandler={editHandler} />
			</Accordion>

			<SiteOnlyContent>
				<Accordion openDefault={false} title={t(ETranslation.COMMON_REVIEWS)}>

					<EditableField
						propertyName={EInterpretation.customerReview}
						initialValue={customerReview?.rating}
						onEdit={editHandler}
						labelTranslation={ETranslation.INTERPRETATION_CUSTOMER_RATING}
						type={EInputType.number}
						showLabel={true}
						label={"arviointi 1-5, 0 postaa arvioinnin"}
						displayValue={customerReview?.rating ?? ""}
						loading={updateLoadingProperty === EInterpretation.customerReview}
					/>
					<EditableField
						propertyName={EInterpretation.customerReviewText}
						initialValue={customerReview?.review ?? ""}
						onEdit={editHandler}
						labelTranslation={ETranslation.INTERPRETATION_CUSTOMER_REVIEW}
						type={EInputType.textarea}
						displayValue={customerReview?.review}
						loading={updateLoadingProperty === EInterpretation.customerReviewText}
					/>

					<EditableField
						propertyName={EInterpretation.interpreterReview}
						initialValue={interpreterReview?.rating}
						onEdit={editHandler}
						labelTranslation={ETranslation.INTERPRETATION_INTERPRETER_RATING}
						type={EInputType.number}
						showLabel={true}
						label={"arviointi 1-5, 0 postaa arvioinnin"}
						displayValue={interpreterReview?.rating ?? ""}
						loading={updateLoadingProperty === EInterpretation.interpreterReview}
					/>
					<EditableField
						propertyName={EInterpretation.interpreterReviewText}
						initialValue={interpreterReview?.review ?? ""}
						onEdit={editHandler}
						labelTranslation={ETranslation.INTERPRETATION_INTERPRETER_REVIEW}
						type={EInputType.textarea}
						displayValue={interpreterReview?.review}
						loading={updateLoadingProperty === EInterpretation.interpreterReviewText}
					/>
					<div>
						<p style={{ fontWeight: "bold" }}>{t(ETranslation.NEWS_MAKE_REVIEW)}</p>
						<Button onClick={() => handleClick(true, "asiakkaan", 0)} style={{ marginRight: "10px" }}>
							{t(ETranslation.NEWS_CUSTOMER_REVIEW)}
						</Button>
						<Button onClick={() => handleClick(false, "tulkin", 1)} style={{ marginLeft: "10px" }}>
							{t(ETranslation.NEWS_INTERPRETER_REVIEW)}
						</Button>
					</div>
				</Accordion>
				{interpretation.interpretationLogs && (
					<Accordion title={t(ETranslation.INTERPRETATION_LENGTH_FEES_PAYMENTS)}>
						<InterpretationFeeDetails
							updateHandler={editHandler}
							interpretation={interpretation}
							updateLoadingProperty={updateLoadingProperty}
						/>
					</Accordion>
				)}
				{interpretation.interpretationLogs && (
					<Accordion title={t(ETranslation.MENU_LOG_ITEMS)}>
						<InterpretationLogRows logs={interpretation.interpretationLogs} />
					</Accordion>
				)}
				{interpretation.pushMessages && (
					<Accordion title={t(ETranslation.MESSAGE_MESSAGES)}>
						<InterpretationPushMessages messages={interpretation.pushMessages} />
					</Accordion>
				)}
				{interpretation.emailMessages && (
					<Accordion title={t(ETranslation.COMMON_EMAILS)}>
						<InterpretationEmails mails={interpretation.emailMessages} />
					</Accordion>
				)}
			</SiteOnlyContent>
		</>
	);
};

interface IInterpretationAttributesProps {
	attributes: IAttribute[] | null;
	onSave: (propertyName: EInterpretation, value: TInputValue) => void;
	propertyName: EInterpretation.attributeIds | EInterpretation.subtypeIds;
}

const InterpretationAttributes: React.FC<IInterpretationAttributesProps> = ({ attributes, onSave, propertyName  }) => {
	const openAttributesModal = useAttributeSelectModal();
	const openConfirmModal = useConfirmModal();


	const getSettings = useCallback(() => {
		let modalTitle = "";
		let deleteText = "";
		let title = "";
		let types: ECategoryType[];
		if (propertyName === EInterpretation.subtypeIds) {
			modalTitle = "Lisää ammattinimike";
			types = [ECategoryType.INTERPRETATION_SUBTYPE];
			deleteText = "Haluatko varmasti poistaa ammattinimikkeen?";
			title = "Ammattinimikkeet";
		} else {
			types = [ECategoryType.INTERPRETATION_SEARCH];
			modalTitle = "Lisää vaatimus";
			deleteText = "Haluatko varmasti poistaa vaatimuksen?";
			title = "Muut vaatimukset";
		}
		return {
			modalTitle,
			deleteText,
			title,
			types
		}
	}, [propertyName]);

	const settings = getSettings();

	const saveHandler = (attributes: IAttribute[]) => {
		onSave(
			propertyName,
			attributes.map((attr) => attr.id)
		);
	};

	const editHandler = async () => {
		const newAttribute = await openAttributesModal(attributes ?? [], settings.modalTitle, settings.types);
		if (newAttribute) {
			saveHandler([...(attributes ?? []), newAttribute]);
		}
	};

	const deleteHandler = async (id: string) => {
		const success = await openConfirmModal(settings.deleteText);
		if (success) {
			saveHandler(attributes?.filter((attr) => attr.id !== id) ?? []);
		}
	};

	return (
		<div>
			<p style={{ marginBottom: ".5rem" }}>
				<b>{settings.title}</b>{" "}
				<FontAwesomeIcon icon={faPlus} style={{ cursor: "pointer" }} onClick={editHandler} />
			</p>
			<AttributesBadgeList attributes={attributes ?? []} onDelete={deleteHandler} />
		</div>
	);
};

export default InterpretationDetails;
