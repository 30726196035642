import { v4 as uuidV4 } from "uuid";

import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";

import { IWorkingHoursDate, IWorkingHoursDateEvent } from "../../interfaces/IWorkingHoursDate";
import Button, { EButtonColor } from "../ui/Button/Button";
import { IUserContractSettings } from "../../interfaces/IUserContractSettings";
import WorkingHoursEventEdit from "./WorkingHoursEventEdit";
import { addTimeToDate, formatTimeEmptyIfNull } from "../../utils/date-utils";

interface IProps {
	date: string;
	workingHoursDate: IWorkingHoursDate | null;
	contractSettings: IUserContractSettings | null;
	showValidation: boolean;
}

export interface IWorkingHoursEventsHandle {
	getEvents: () => IWorkingHoursDateEvent[];
	isValid: () => boolean;
}

interface IValidation {
	[key: string]: boolean;
}

const WorkingHoursEdit: React.ForwardRefRenderFunction<IWorkingHoursEventsHandle, IProps> = (
	{ date, workingHoursDate, contractSettings, showValidation },
	ref
) => {
	const [isValid, setIsValid] = useState<IValidation>({});
	const [events, setEvents] = useState<IWorkingHoursDateEvent[]>([]);

	const breakMinutes = contractSettings?.breakMinutes || 0;

	useImperativeHandle(ref, () => ({
		getEvents: () => {
			return events.map((event) => ({
				...event,
				startTime: addTimeToDate(date, event.startTime),
				endTime: addTimeToDate(date, event.endTime),
			}));
		},
		isValid: () => {
			const keys = Object.keys(isValid);
			return keys.length > 0 && keys.every((key) => isValid[key]);
		},
	}));

	useEffect(() => {
		if (workingHoursDate) {
			setEvents(
				workingHoursDate.events.map((event) => ({
					...event,
					startTime: formatTimeEmptyIfNull(event.startTime),
					endTime: formatTimeEmptyIfNull(event.endTime),
				}))
			);
		}
	}, [workingHoursDate]);

	const addEventHandler = useCallback(() => {
		setEvents((events) => [
			...events,
			{
				id: uuidV4(),
				date: "",
				breakMinutes,
				description: "",
				startTime: "",
				endTime: "",
				hours: 7.5,
				isBreakIncluded: breakMinutes > 0 && !events.some(event => event.isBreakIncluded),
				travelAmount: 0,
				type: null,
			},
		]);
	}, [breakMinutes]);

	const updateIsValid = useCallback(
		(id: string, value: boolean) => {
			setIsValid((prevIsValid) => ({
				...prevIsValid,
				[id]: value,
			}));
		},
		[setIsValid]
	);

	const deleteEventHandler = (id: string) => {
		setEvents((prevEvents) => prevEvents.filter((event) => event.id !== id));
	};

	const eventsChangeHandler = useCallback(
		(id: string, eventData: IWorkingHoursDateEvent, isValid: boolean) => {
			updateIsValid(id, isValid);
			setEvents((events) => events.map((prevEvent) => (id === prevEvent.id ? {...prevEvent, ...eventData} : prevEvent)));
		},
		[updateIsValid]
	);

	return (
		<div style={{ marginBottom: "2rem" }}>
			{events.map((event, i) => (
				<WorkingHoursEventEdit
					key={event.id}
					date={date}
					event={event}
					breakMinutes={breakMinutes}
					number={i + 1}
					onChange={eventsChangeHandler}
					onRemove={deleteEventHandler}
					showValidation={showValidation}
				/>
			))}
			<Button onClick={addEventHandler} color={EButtonColor.DEFAULT}>
				Lisää tapahtuma
			</Button>
		</div>
	);
};

export default forwardRef(WorkingHoursEdit);
