import React, { ReactNode, useState } from "react";
import classes from "./Accordion.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

interface IProps {
	title: string;
	children: ReactNode;
	openDefault?: boolean;
}

const Accordion: React.FC<IProps> = ({ title, children, openDefault }) => {

	const [open, setOpen] = useState(openDefault ?? false);

	const classNames = [classes.Container];

	if (open) {
		classNames.push(classes.Open);
	}

	return (
		<div className={classNames.join(' ')}>
			<div className={classes.Title} onClick={() => { setOpen(!open) }}>
				{title}
				<FontAwesomeIcon className={classes.Toggle} icon={open ? faAngleUp : faAngleDown} />
			</div>
			<div className={classes.Content}>
				{children}
			</div>
		</div>
	)
};

export default Accordion;
