import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { IUser } from "../../../classes/User";
import UserComments from "../../../pages/UsersPage/UserComments";
import ModalContext, { EModalSize } from "../../ui/Modal/ModalContext";
import { ETranslation } from "../../../translations/translation-keys";

const useCommentsModal = () => {
	const { setModal } = useContext(ModalContext);
	const { t } = useTranslation();

	const openHandler = useCallback(
		(user: IUser) => {
			setModal({
				title: "Käyttäjän " + user.name + " " + t(ETranslation.COMMON_COMMENTS),
				content: <UserComments user={user} />,
				isOpen: true,
				size: EModalSize.FULL,
			});
		},
		[setModal, t]
	);

	return openHandler;
};

export default useCommentsModal;