import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import Container from "../../components/ui/Container/Container";
import { useDispatch, useSelector } from "react-redux";
import IAppState from "../../interfaces/store/IAppState";
import { ITag, getTag, getTagClear, saveTag, saveTagClear } from "../../store/actions/tagsActions";
import Spinner from "../../components/ui/Spinner/Spinner";
import EditButtons from "../../components/ui/EditButtons/EditButtons";
import { ERoute, Routes } from "../../classes/Routes";
import Alert from "../../components/ui/Alert/Alert";
import Tag from "../../components/Tags/Tag";


interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

const TagPage: React.FC<IProps> = ({ match, history }) => {
	const dispatch = useDispatch();
	const { id } = match.params;
	const isAdd = id === "add";

	const [selectedTag, setSelectedTag] = useState<ITag>()

	const [isValid, setIsValid] = useState<boolean>(false);

	const {
		tag,
		loading,
		tagSaveLoading,
		tagSaveError,
		tagSaveSuccess
	} = useSelector((state: IAppState) => state.tags);

	/**
	 * Tän saa salee vielä tiiviimminkin tehtyä, mutta hyvä example sivurakenteelle.
	 * TODO(joonas): Error handling tähän vielä ja entity delete kutsut.
	 */

	useEffect(() => {
		if (!isAdd) {
			dispatch(getTag(id))
		}
	}, [id, dispatch, isAdd])

	useEffect(() => {
		if (!tag) { return }
		setSelectedTag(tag);
	}, [tag])

	useEffect(() => {
		if (tagSaveSuccess) {
			history.push(Routes.withParams(ERoute.TAGS_LIST, {}));
		}
		return () => {
			dispatch(saveTagClear());
			dispatch(getTagClear());
		};
	}, [tagSaveSuccess, history, dispatch])

	const saveTagCb = () => {
		if (!selectedTag) { return }
		dispatch(saveTag(selectedTag));
	}

	const tagUpdate = useCallback((tag: ITag, valid: boolean) => {
		setIsValid(valid);
		setSelectedTag(tag);
	}, [])

	return (
		<Container>
			{(loading || tagSaveLoading) ? <Spinner /> :
				<>
					{tagSaveError && <Alert>{tagSaveError}</Alert>}
					<Tag tag={tag} onUpdate={tagUpdate} />
					<EditButtons disabled={!isValid} onSave={saveTagCb} isAdd onCancel={() => {
						history.push(ERoute.TAGS_LIST);
					}} />
				</>}
		</Container>
	)
}

export default TagPage;