import IAuthAction from "../../interfaces/store/IAuthAction";
import EActionTypes from "../../interfaces/store/EActionTypes";
import ITwilioState from "../../interfaces/store/ITwilioState";
import ITwilioAction from "../../interfaces/store/ITwilioAction";


type ReducerState = ITwilioState;
type ReducerSignature = (state: ReducerState, action: ITwilioAction) => ReducerState;

const initialState: ReducerState = {
  conversationToken: null,
  loading: false,
  error: null,
};



const getConversationTokenStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const getConversationTokenSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    conversationToken: action.conversationToken || null,
    error: null,
    loading: false,
  };
};

const getConversationTokenFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, conversationToken: null, loading: false, error: action.error };
};

const reducer = (state: ReducerState = initialState, action: IAuthAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.TWILIO_GET_CONVERSATION_TOKEN_START:
      return getConversationTokenStart(state, action);
    case EActionTypes.TWILIO_GET_CONVERSATION_TOKEN_SUCCESS:
      return getConversationTokenSuccess(state, action);
    case EActionTypes.TWILIO_GET_CONVERSATION_TOKEN_FAIL:
      return getConversationTokenFail(state, action);
    default:
      return state;
  }
};

export default reducer;
