import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';

import Alert from '../../ui/Alert/Alert';
import Button, { EButtonColor } from '../../ui/Button/Button';
import ModalBody from '../../ui/Modal/ModalBody/ModalBody';
import ModalContext from '../../ui/Modal/ModalContext';
import ModalFooter from '../../ui/Modal/ModalFooter/ModalFooter';

interface IProps {
  sendText?: string;
  onSend?: () => void;
  error?: string | null;
}

const SendModal: React.FC<IProps> = ({
  sendText,
  onSend,
  error,
}) => {
  const { closeModal } = useContext(ModalContext);
  const { t } = useTranslation();
  return (
    <>
      <ModalBody>
        {error && <Alert>{error}</Alert>}
        {sendText || t(ETranslation.TEXTMESSAGE_ARE_YOU_SURE_SEND)}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => onSend && onSend()}
          color={EButtonColor.SUCCESS}
        >
          {t(ETranslation.COMMON_SEND)}
        </Button>
        <Button onClick={closeModal} color={EButtonColor.DANGER}>
        {t(ETranslation.COMMON_CANCEL)}
        </Button>
      </ModalFooter>
    </>
  );
};

export default SendModal;