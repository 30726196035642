import { format } from "date-fns";
import React, { useEffect, useState } from "react";

import { User } from "../../classes/User";
import { useCreateInput } from "../../hooks/useCreateInput";
import { useLanguages } from "../../hooks/useLanguages";
import {
	ENewsItemDisplayLocation,
	INewsItem,
} from "../../interfaces/INewsItem";
import {
	NEWS_ITEM_DISPLAY_LOCATIONS,
	NEWS_ITEM_RATING,
} from "../../shared/news-item-data";
import { ETranslation } from "../../translations/translation-keys";
import CustomDropzoneContext, {
	TSetUploading,
} from "../CustomDropzone/CustomDropzoneContext";
import { ECommonValue, EInputType, IInputField } from "../ui/Input/Input";
import {
	getInputData,
	initForm,
	validateInputs,
} from "../ui/Input/input-utils";
import InputGroup from "../ui/InputGroup/InputGroup";

enum EInputs {
	displayLocation = "displayLocation",
	displayLanguages = "displayLanguages",
	title = "title",
	image = "image",
	languages = "languages",
	shortContent = "shortContent",
	rating = "rating",
	reviewName = "reviewName",
	contentText = "contentText",
	publishDate = "publishDate",
	publishDateDay = "publishDateDay",
	publishDateTime = "publishDateTime",
	published = "published",
	showPublishDate = "showPublishDate",
	isReview = "isReview",
}

interface IProps {
	onChange: (newsItem: INewsItem, isValid: boolean) => void;
	newsItem: INewsItem | null;
	loading?: boolean;
	showValidation?: boolean;
	user: User;
	setUploading: TSetUploading;
}

const NewsItem: React.FC<IProps> = ({
	onChange,
	loading,
	newsItem,
	showValidation,
	user,
	setUploading,
}) => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.displayLocation]: {
			type: EInputType.select,
			labelTranslation: ETranslation.NEWS_SHOWN_IN_PLACES,
			value: ENewsItemDisplayLocation.YOUPRET,
			options: [...NEWS_ITEM_DISPLAY_LOCATIONS],
		},
		[EInputs.rating]: {
			type: EInputType.select,
			labelTranslation: ETranslation.NEWS_RATING,
			value: "null",
			options: [...NEWS_ITEM_RATING],
		},
		[EInputs.displayLanguages]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.NEWS_SHOWN_TO_LANGUAGES,
			value: [],
			multiple: true,
			placeholderTranslation: ETranslation.COMMON_CHOOSE_LANGUAGES,
		},
		[EInputs.languages]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_LANGUAGE,
			value: [],
			multiple: true,
			placeholderTranslation: ETranslation.COMMON_CHOOSE_LANGUAGES,
		},
		[EInputs.title]: {
			type: EInputType.text,
			labelTranslation: ETranslation.NEWS_HEADLINE,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.image]: {
			type: EInputType.dropzone,
			labelTranslation: ETranslation.NEWS_IMAGE,
			value: "",
			dropzoneSettings: {
				accept: "image/*",
				maxSizeKb: 500,
				publicAccess: true,
			},
		},
		[EInputs.shortContent]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.NEWS_IN_BRIEF,
			value: "",
		},
		[EInputs.contentText]: {
			type: EInputType.wysiwyg,
			labelTranslation: ETranslation.NEWS_CONTENT,
			value: "",
		},
		[EInputs.publishDateDay]: {
			type: EInputType.date,
			labelTranslation: ETranslation.NEWS_PUBLISH_DATE,
			value: format(new Date(), "yyyy-MM-dd"),
		},
		[EInputs.publishDateTime]: {
			type: EInputType.time,
			labelTranslation: ETranslation.NEWS_PUBLISH_TIME,
			value: format(new Date(), "HH:mm"),
		},
		[EInputs.publishDate]: {
			type: EInputType.date,
			label: "",
			value: "",
		},
		[EInputs.published]: {
			type: EInputType.checkbox,
			labelTranslation: ETranslation.COMMON_PUBLISHED,
			value: [],
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.isReview]: {
			type: EInputType.checkbox,
			labelTranslation: ETranslation.NEWS_REVIEW,
			value: [],
			options: [{ value: ECommonValue.YES }],
		},
		[EInputs.showPublishDate]: {
			type: EInputType.checkbox,
			labelTranslation: ETranslation.NEWS_SHOW_PUBLISH_DATE,
			value: [ECommonValue.YES],
			options: [{ value: ECommonValue.YES }],
		},
	});

	const { languageOptions } = useLanguages();

	useEffect(() => {
		if (newsItem) {
			initForm(setInputs, newsItem);
		}
		// eslint-disable-next-line
	}, [newsItem]);

	useEffect(() => {
		const newsItem = getInputData<INewsItem>(inputs);
		const isValid = validateInputs(inputs);
		onChange(newsItem, isValid);
	}, [inputs, onChange]);

	const createInput = useCreateInput(inputs, setInputs, {
		showValidation,
		disabled: loading,
	});

	return (
		<>
			{user.isYoupretAdmin && createInput(EInputs.displayLocation)}
			{createInput(EInputs.displayLanguages, {
				options: languageOptions,
			})}
			{user.isYoupretAdmin &&
				createInput(EInputs.languages, { options: languageOptions })}
			{createInput(EInputs.title)}
			{user.isYoupretAdmin && createInput(EInputs.rating)}
			<CustomDropzoneContext.Provider value={{ setUploading }}>
				{createInput(EInputs.image)}
			</CustomDropzoneContext.Provider>
			{createInput(EInputs.shortContent)}
			{createInput(EInputs.contentText)}
			<InputGroup>
				{createInput(EInputs.publishDateDay)}
				{createInput(EInputs.publishDateTime)}
				{createInput(EInputs.published)}
				{user.isYoupretAdmin && createInput(EInputs.isReview)}
			</InputGroup>
			{createInput(EInputs.showPublishDate)}
			<br />
		</>
	);
};

export default NewsItem;
