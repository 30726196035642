import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateInput } from '../../hooks/useCreateInput';
import { USER_SHIFT_MONTH_OPTIONS, USER_SHIFT_YEAR_OPTIONS } from '../../shared/user-shifts-data';
import { ETranslation } from '../../translations/translation-keys';
import Button from '../ui/Button/Button';
import { EInputType, IInputField } from '../ui/Input/Input';
import { validateInputs } from '../ui/Input/input-utils';
import InputGroup from '../ui/InputGroup/InputGroup';

export interface IUserShiftsSearch {
	year: number;
	month: number;
}

interface IProps {
	onSearch: (year: number, month: number) => void;
}

enum EInputs {
	year = "year",
	month = "month",
}

const initYear = new Date().getFullYear();
const initMonth = new Date().getMonth() + 1;

const UserShiftsSearch: React.FC<IProps> = ({ onSearch }) => {
	
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.year]: {
			type: EInputType.reactSelect,
			label: "Vuosi",
			value: initYear.toString(),
			options: USER_SHIFT_YEAR_OPTIONS(initYear),
			validation: {
				required: true,
			},
		},
		[EInputs.month]: {
			type: EInputType.reactSelect,
			label: "Kuukausi",
			value: initMonth.toString(),
			options: USER_SHIFT_MONTH_OPTIONS,
			validation: {
				required: true,
			},
		},
	});

	const createInput = useCreateInput(inputs, setInputs);
	const [isValid, setIsValid] = useState(false);
	const { t } = useTranslation();

	const searchHandler = () => {
		const year = parseInt(inputs[EInputs.year].value as string);
		const month = parseInt(inputs[EInputs.month].value as string);
		onSearch(year, month);
	};

	useEffect(() => {
		if (onSearch) {
			onSearch(initYear, initMonth);
		}
	}, [onSearch])

	useEffect(() => {
		setIsValid(validateInputs(inputs));
	}, [inputs]);

	

	return (
		<InputGroup>
			{createInput(EInputs.year)}
			{createInput(EInputs.month)}
			<Button onClick={searchHandler} disabled={!isValid}>
				{t(ETranslation.COMMON_SEARCH)}
			</Button>
		</InputGroup>
	);
};

export default UserShiftsSearch;
