import React, { useEffect, useState } from "react";
import { IConnectionDetail, IInterpretation, ILocationDetail } from "../../../../interfaces/IInterpretation";
import Table from "../../../ui/Table/Table";
import classes from "./InterpretationFeeDetails.module.scss";
import Button from "../../../ui/Button/Button";
import { EInterpretation } from "../InterpretationDetails";
import { TInputValue } from "../../../ui/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import IAppState from "../../../../interfaces/store/IAppState";
import { getInterpretation } from "../../../../store/actions";
import { deleteInterpretationPriceRow } from "../../../../store/actions/interpretationActions";


interface Iprops {
	interpretation: IInterpretation;
	updateHandler: (propertyName: EInterpretation, value: TInputValue | ILocationDetail | IConnectionDetail, secondaryValue?: TInputValue) => Promise<void>;
	updateLoadingProperty: string | null
}

const InterpretationFeeDetails: React.FC<Iprops> = ({ interpretation, updateHandler, updateLoadingProperty }) => {

	// Tired af bubblegum code
	const [reload, setReload] = useState(false);
	const dispatch = useDispatch();

	const { deleteInterpretationPriceRowLoading } = useSelector((state: IAppState) => ({
		deleteInterpretationPriceRowLoading: state.interpretation.deleteInterpretationPriceRowLoading,
	}));

	useEffect(() => {
		if (deleteInterpretationPriceRowLoading && !reload) setReload(true);
		if (!deleteInterpretationPriceRowLoading && reload) {setReload(false); dispatch(getInterpretation(interpretation.id));}
	}, [deleteInterpretationPriceRowLoading, reload, dispatch, interpretation.id])

	return <>
		<Table containerClassName={classes.feesTable}>
			<thead>
				<tr>
					<th></th>
					<th>Aloitusaika</th>
					<th>Päättymisaika</th>
					<th>Kesto min</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Tulkkaus</td>
					<td>{interpretation.startDateTime}</td>
					<td>{interpretation.endDateTime}</td>
					<td>{interpretation.durationInMinutes}</td>
				</tr>
				<tr>
					<td>Ajanvaraus</td>
					<td>{interpretation.bookingStartDateTime}</td>
					<td>{interpretation.bookingEndDateTime}</td>
					<td>{interpretation.bookingInMinutes}</td>
				</tr>
				<tr>
					<td>Laskutetaan</td>
					<td></td>
					<td></td>
					<td>{interpretation.billableDurationInMinutes}</td>
				</tr>
			</tbody>
		</Table>
		<Table containerClassName={classes.feesTable}>
			<thead>
				<tr>
					<th>Otsikko</th>
					<th>Määrä</th>
					<th>Alv %</th>
					<th>á hinta €</th>
					<th>Hinta €</th>
					<th>Alennus %</th>
					<th>Alennus €</th>
					<th>Hinta yhteensä €</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{interpretation.interpretationPriceRows?.map((row, key) => {
					return <tr key={key}>
						<td>{row.title}<br />{row.organizationId}</td>
						<td>{row.amount} {row.unit}</td>
						<td>{row.vatP}</td>
						<td>{row.priceWithoutVat}<br />{row.priceVat}<br />{row.price}</td>
						<td>{row.priceOriginalTotalWithoutVat}<br />{row.priceOriginalTotalVat}<br />{row.priceOriginalTotal}</td>
						<td>{row.discountP} %</td>
						<td>{row.discountTotalWithoutVat}<br />{row.discountTotalVat}<br />{row.discountTotal}</td>
						<td>{row.priceTotalWithoutVat}<br />{row.priceTotalVat}<br />{row.priceTotal}</td>

						<td>
							<Button loading={deleteInterpretationPriceRowLoading} onClick={() => { dispatch(deleteInterpretationPriceRow(interpretation.id, key)) }}  >Poista</Button>
						</td>
					</tr>
				})}

			</tbody>
		</Table>
		<Table containerClassName={classes.feesTable}>
			<thead>
				<tr>
					<th></th>
					<th></th>
					<th>Summa</th>
					<th>Alv</th>
					<th>Yhteensä</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Asiakkaan hinta</td>
					<td>{interpretation.priceWithoutVatPerMinute} € / min</td>
					<td>{interpretation.priceWithoutVat} €</td>
					<td>{interpretation.priceVat} € ({interpretation.priceVatP} %)</td>
					<td>{interpretation.price} €</td>
					<td></td>
				</tr>
				<tr>
					<td><b>Asiakasmaksu</b></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
				</tr>
				<tr>
					<td>Maksu: <b>{interpretation.interpreter?.name}</b></td>
					<td>{interpretation.interpreterFeeWithoutVatPerMinute} € / min</td>
					<td>{interpretation.interpreterFeeWithoutVat} €</td>
					<td>{interpretation.interpreterFeeVat} € ({interpretation.interpreterFeeVatP} %)</td>
					<td>{interpretation.interpreterFee} €</td>
					<td></td>
				</tr>
				{interpretation.interpretationFeeRows?.map((row, key) => {
					return <tr key={key}>
						<td>+ Hyväksymismaksu: <b>{row.user.name}</b></td>
						<td>{row.amount} kpl</td>
						<td>{row.feeTotalWithoutVat} €</td>
						<td>{row.feeTotalVat} € ({row.feeVatP} %)</td>
						<td>{row.feeTotal} €</td>
						<td><Button loading={updateLoadingProperty === EInterpretation.deleteInterpretationFeeRow} onClick={() => { updateHandler(EInterpretation.deleteInterpretationFeeRow, key) }}>Poista</Button>
							{" "}
							<Button loading={updateLoadingProperty === EInterpretation.updateInterpretationFeeRow} onClick={() => updateHandler(EInterpretation.updateInterpretationFeeRow, key)}>Päivitä alv</Button></td>
					</tr>
				})}
				<tr>
					<td><b>Maksut yhteensä:</b></td>
					<td></td>
					<td>{interpretation.feesTotalWithoutVat} €</td>
					<td>{interpretation.feesTotalVat} €</td>
					<td>{interpretation.feesTotal} €</td>
					<td></td>
				</tr>

				<tr>
					<td><b>Välitysmaksu</b></td>
					<td></td>
					<td>{interpretation.commissionWithoutVat} €</td>
					<td>{interpretation.commissionVat} € ({interpretation.commissionVatP} %)</td>
					<td>{interpretation.commission} €</td>
					<td></td>
				</tr>
			</tbody>
		</Table>
	</>

}

export default InterpretationFeeDetails;