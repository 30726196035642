import React, { ReactNode } from 'react';

import classes from './Heading.module.scss';

interface IProps {
  children: ReactNode;
  tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  className?: string;
}

const Heading: React.FC<IProps> = ({ children, tag: Tag = "h1", className }) => {
  const classNames = [classes.Container];
  if(className) {
    classNames.push(className);
  }
  return <Tag className={classNames.join(' ')}>{children}</Tag>;
};

export default Heading;
