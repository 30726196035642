import { Dispatch } from "redux";
import { INetvisorSettings } from "../../interfaces/INetvisorSettings";
import INetvisorSettingsAction from "../../interfaces/store/INetvisorSettingsAction";
import EActionTypes from "../../interfaces/store/EActionTypes";
import { EFetchMethod, customFetch } from "../../custom-fetch";

type TAction = INetvisorSettingsAction;

const urls = {
	get: "/netvisorsettings/get",
	save: "/netvisorsettings/save"
}

const saveNetvisorSettingsStart = (): TAction => {
	return {
		type: EActionTypes.NETVISOR_SETTINGS_SAVE_START,
	};
};

const saveNetvisorSettingsSuccess = (netvisorSettings: INetvisorSettings): TAction => {
	return {
		type: EActionTypes.NETVISOR_SETTINGS_SAVE_SUCCESS,
		netvisorSettings
	};
};

const saveNetvisorSettingsFail = (error: string): TAction => {
	return {
		type: EActionTypes.NETVISOR_SETTINGS_SAVE_FAIL,
		error,
	};
};

export const saveNetvisorSettingsClear = (): TAction => {
	return {
		type: EActionTypes.NETVISOR_SETTINGS_SAVE_CLEAR,
	};
};

export const saveNetvisorSettings = (netvisorSettings: INetvisorSettings) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(saveNetvisorSettingsStart());
			customFetch<INetvisorSettings>(urls.save, EFetchMethod.POST, JSON.stringify(netvisorSettings)).then((result) =>{
				dispatch(saveNetvisorSettingsSuccess(result));
			}).catch((error)=>{
				dispatch(saveNetvisorSettingsFail((error as Error).message));
			})
		} catch (error) {
			dispatch(saveNetvisorSettingsFail((error as Error).message));
		}
	};
};

const getNetvisorSettingsStart = (): TAction => {
	return {
		type: EActionTypes.NETVISOR_SETTINGS_GET_START,
	};
};

const getNetvisorSettingsSuccess = (netvisorSettings: INetvisorSettings): TAction => {
	return {
		type: EActionTypes.NETVISOR_SETTINGS_GET_SUCCESS,
		netvisorSettings
	};
};

const getNetvisorSettingsFail = (error: string): TAction => {
	return {
		type: EActionTypes.NETVISOR_SETTINGS_GET_FAIL,
		error,
	};
};

export const getNetvisorSettingsClear = (): TAction => {
	return {
		type: EActionTypes.NETVISOR_SETTINGS_GET_CLEAR,
	};
};

export const getNetvisorSettings = (siteId: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getNetvisorSettingsStart());
			customFetch<INetvisorSettings>(`${urls.get}?siteId=${siteId}`, EFetchMethod.GET).then((result) =>{
				dispatch(getNetvisorSettingsSuccess(result));
			}).catch((error)=>{
				dispatch(getNetvisorSettingsFail((error as Error).message));
			})
		} catch (error) {
			dispatch(getNetvisorSettingsFail((error as Error).message));
		}
	};
};

