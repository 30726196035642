import { IInterpretation } from './../interfaces/IInterpretation';
import { TFunction } from "i18next";
import { useSelector } from "react-redux";
import { IUser } from "../classes/User";
import { IOption } from "../components/ui/Input/Input";
import { IOrganization } from "../interfaces/IOrganization";
import IAppState from "../interfaces/store/IAppState";
import IAuthState from "../interfaces/store/IAuthState";
import { EConnectionDetail, EInterpretationMailType, EInterpretationType, EInterpreterFeeType, EInterpreterOfferStatus, INTERPRETATION_CONNECTION_DETAIL_ALL, INTERPRETATION_EMAIL_TYPE_ALL, INTERPRETATION_INTERPRETER_OFFER_STATUS_ALL, INTERPRETER_FEE_TYPE_ALL, JOHANNAREKRY_TYPES, VALITYSKLINIKKA_TYPES, YOUPRET_TYPES } from "../shared/interpretation-data";
import { ETranslation } from "../translations/translation-keys";
import { useCallback } from 'react';
import { getSiteTypes } from '../shared/site-data';
import { ESitePrefix } from '../interfaces/ISite';


export const useInterpretation = (customerUser?: IUser, customerOrganization?: IOrganization) => {
	const { user, currentSite } = useSelector<IAppState, IAuthState>((state) => state.auth);

	const sitePrefix = user?.site?.prefix;

	const INTERPRETATION_TYPE_ALL: IOption[] = (() => {
		if (sitePrefix) {
			switch (sitePrefix) {
				case ESitePrefix.johannarekry:
					return JOHANNAREKRY_TYPES;
				case ESitePrefix.valitysklinikka:
					return VALITYSKLINIKKA_TYPES;
			}
		}
		return YOUPRET_TYPES;
	})();

	/**
	 * get interpretation types set for this site.
	 * @returns IOptions for current site
	 */
	const getSiteInterpretationTypes = () => {
		let types: IOption[] = [];
		if(currentSite?.name === "Youpret Finland") {
			types = getUserInterpretationTypes();
		} else {
			types = currentSite?.types ? [...currentSite.types.map((type) =>{
				const option = getSiteTypes(type);
				if(option) { return option }
				else { return {value: "", label: ""}}
			})]: [];
		}
		return types;
	}

	const getUserConnectionDetails = () => {

		const INTERPRETATION_CONNECTION_DETAIL: IOption[] = [];
		if (customerOrganization?.allowInterpreterCallToCustomer) {
			INTERPRETATION_CONNECTION_DETAIL.push({ value: EConnectionDetail.INTERPRETER_CALL_TO_CUSTOMER, labelTranslation: ETranslation.INTERPRETATION_DATA_CONNECTION_DETAIL_TO_CUSTOMER })
		}
		if (customerOrganization?.allowCustomerCallToInterpreter) {
			INTERPRETATION_CONNECTION_DETAIL.push({ value: EConnectionDetail.CUSTOMER_CALL_TO_INTERPRETER, labelTranslation: ETranslation.INTERPRETATION_DATA_CONNECTION_DETAIL_TO_INTERPRETER })
		}
		if ((customerOrganization?.allowVideoConference)) {
			INTERPRETATION_CONNECTION_DETAIL.push({ value: EConnectionDetail.VIDEO_CONFERENCE_TWILIO, labelTranslation: ETranslation.INTERPRETATION_DATA_CONNECTION_DETAIL_VIDEO_CONFERENCE_TWILIO })
		}
		if (customerUser?.allowVideo || user?.allowVideo) {
			INTERPRETATION_CONNECTION_DETAIL.push({ value: EConnectionDetail.VIDEO_CONFERENCE, labelTranslation: ETranslation.INTERPRETATION_DATA_CONNECTION_DETAIL_VIDEO_CONFERENCE })
		}
		return INTERPRETATION_CONNECTION_DETAIL;
	}

	const getUserInterpretationTypes = () => {

		if (user?.isYoupretAdmin) {
			return INTERPRETATION_TYPE_ALL;
		}

		const INTERPRETATION_TYPE: IOption[] = [];
		if (customerUser?.allowInstant || user?.allowInstant) {
			INTERPRETATION_TYPE.push({ value: EInterpretationType.INSTANT, labelTranslation: ETranslation.COMMON_INSTANT })
		}
		if (customerUser?.allowBooking || user?.allowBooking) {
			INTERPRETATION_TYPE.push({ value: EInterpretationType.BOOKING, labelTranslation: ETranslation.COMMON_BOOKING })
		}
		if (customerUser?.allowOnSite || user?.allowOnSite) {
			INTERPRETATION_TYPE.push({ value: EInterpretationType.ON_SITE, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_ON_SITE })
		}
		return INTERPRETATION_TYPE;
	}

	const getInterpretationTypeString = (
		t: TFunction, type: EInterpretationType
	): string => t(INTERPRETATION_TYPE_ALL.find(item => item.value === type)?.labelTranslation || "");

	const getFeeTypeString = (
		t: TFunction, type: EInterpreterFeeType
	): string => t(INTERPRETER_FEE_TYPE_ALL.find(item => item.value === type)?.labelTranslation || "");


	const getConnectionDetailString = (
		t: TFunction, status: EConnectionDetail
	): string => t(INTERPRETATION_CONNECTION_DETAIL_ALL.find(item => item.value === status)?.labelTranslation || "");

	const getInterpretationMailTypeString = (t: TFunction, type: EInterpretationMailType): string => t(INTERPRETATION_EMAIL_TYPE_ALL.find(item => item.value === type)?.labelTranslation || "");

	const getInterpreterStatusString = (t: TFunction, type: EInterpreterOfferStatus): string => t(INTERPRETATION_INTERPRETER_OFFER_STATUS_ALL.find(item => item.value === type)?.labelTranslation || "");

	const setConnectionDetailInformation = useCallback((interpretation: IInterpretation) => {
		if (!interpretation) return;
		const { phoneNumber, connectionType, videoConferenceUrl, videoConferenceInfo } = interpretation;

		const interpretationDetails: IInterpretation = {
			...interpretation,
			connectionDetail: {
				phoneNumber,
				connectionType,
				videoConferenceUrl,
				videoConferenceInfo
			}
		}
		return interpretationDetails;
	}, [])

	const setLocationDetailInformation = useCallback((interpretation: IInterpretation) => {
		if (!interpretation) return;
		const { address, zip, city, info } = interpretation;

		const interpretationDetails: IInterpretation = {
			...interpretation,
			locationDetail: {
				address,
				zip,
				city,
				info
			}
		}
		return interpretationDetails;
	}, [])

	const initInterpretation = (interpretation: IInterpretation) => {
		if (!interpretation) return;
		const { type } = interpretation;

		const interpretationDetails: IInterpretation = {
			...interpretation,
			type

		}
		return interpretationDetails;
	}

	return { initInterpretation, getFeeTypeString, getInterpretationMailTypeString, getInterpreterStatusString, getSiteInterpretationTypes, setConnectionDetailInformation, setLocationDetailInformation, getConnectionDetailString, getInterpretationTypeString, getUserInterpretationTypes, getUserConnectionDetails };

};