import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { ERoute, ERouteUnAuthAllow } from './classes/Routes';
import Alert from './components/ui/Alert/Alert';
import Spinner from './components/ui/Spinner/Spinner';
import GuardedRoute from './GuardedRoute';
import IAppState from './interfaces/store/IAppState';
import FileUploadPage from './pages/FileUpload/FileUploadPage';
import InfoContactRequestEditPage from './pages/InfoContactRequestEdit/InfoContactRequestEditPage';
import InfoContactRequestsListPage from './pages/InfoContactRequestsList/InfoContactRequestsListPage';
import CustomerReportsPage from './pages/CustomerReports/CustomerReportsPage'; //added AB 4.2021
import MessageEditPage from './pages/Message/MessageEdit/MessageEditPage';
import MessageGroupEditPage from './pages/Message/MessageGroupEdit/MessageGroupEditPage';
import MessageGroupsListPage from './pages/Message/MessageGroupsList/MessageGroupsListPage';
import MessageShowPage from './pages/Message/MessageShow/MessageShowPage';
import NewsItemEditPage from './pages/NewsItem/NewsItemEdit/NewsItemEditPage';
import NewsItemsListPage from './pages/NewsItem/NewsItemsList/NewsItemsListPage';
import OrganizationGroupEditPage from './pages/OrganizationGroup/OrganizationGroupEdit/OrganizationGroupEditPage';
import OrganizationGroupsListPage from './pages/OrganizationGroup/OrganizationGroupsList/OrganizationGroupsListPage';
import PriceSeasonEditPage from './pages/PriceSeason/PriceSeasonEdit/PriceSeasonEditPage';
import PriceSeasonRuleEditPage from './pages/PriceSeason/PriceSeasonRuleEdit/PriceSeasonRuleEditPage';
import PriceSeasonRulesListPage from './pages/PriceSeason/PriceSeasonRulesList/PriceSeasonRulesListPage';
import PriceSeasonsListPage from './pages/PriceSeason/PriceSeasonsList/PriceSeasonsListPage';
import SendGridEmailsPage from './pages/SendGridEmails/SendGridEmailsPage';
import SiteEditPage from './pages/Site/SiteEdit/SiteEditPage';
import SitesListPage from './pages/Site/SitesList/SitesListPage';
import TextMessageEditPage from './pages/TextMessage/TextMessageEdit/TextMessageEditPage';
import TextMessagesListPage from './pages/TextMessage/TextMessagesList/TextMessagesListPage';
import * as actions from './store/actions';
import { ETranslation } from './translations/translation-keys';
import { useTranslation } from 'react-i18next';
import LogItemEditPage from './pages/LogItem/LogItemEdit/LogItemEditPage';
import LogItemsListPage from './pages/LogItem/LogItemsList/LogItemsListPage';
import VideoPage from './pages/Video/VideoPage';
import InterpretationReviewPage from './pages/Interpretations/InterpretationReview/InterpretationReviewPage';
import TwilioChatPage from './pages/TwilioChat/TwilioChatPage';
import InterpretationsListPage from './pages/Interpretations/InterpretationsList/InterpretationsListPage';
import OrderInterpretationPage from './pages/Interpretations/OrderInterpretation/OrderInterpretationPage';
import InterpretationProcessingPage from './pages/Interpretations/InterpretationProcessing/InterpretationProcessingPage';
import InterpretationPage from './pages/Interpretations/Interpretation/InterpretationPage';
import CalendarViewPage from './pages/CalendarView/CalendarView';
import ReservationModify from './pages/ReservationModify/ReservationModify';
import ModalSwitch from './ModalSwitch';
import CustomersPage from './pages/UsersPage/CustomersPage';
import CommentsPage from './pages/Comments/CommentsPage';
import InterpretersPage from './pages/UsersPage/InterpretersPage';
import CommissionsPage from './pages/Comissions/CommissionsPage';
import CommissionPage from './pages/Comissions/CommissionPage';
import TagsPage from './pages/Tags/TagsListPage';
import ReservedDatesListPage from './pages/ReservedDates/ReservedDatesList/ReservedDatesListPage';
import LanguageCodeListPage from './pages/LanguageCode/LanguageCodeList/LanguageCodeListPage';
import LanguageCodeEditPage from './pages/LanguageCode/LanguageCodeEdit/LanguageCodeEditPage';
import LanguageListPage from './pages/Language/LanguageList/LanguageListPage';
import LanguageEditPage from './pages/Language/LanguageEdit/LanguageEditPage';

import OrdersPage from './pages/Orders/OrdersPage';
import TasksPage from './pages/Tasks/TasksPage';
import OrganizationsPage from './pages/Organizations/OrganizationsPage';
import OrganizationEditPage from './pages/Organizations/OrganizationEdit/OrganizationEditPage';
import SummariesPage from './pages/Summaries/SummariesPage';
import SummariesViewPage from './pages/Summaries/SummariesViewPage';
import UserEditPage from './pages/UsersPage/UserEditPage';
import OrderPage from './pages/Orders/OrderPage';
import TagPage from './pages/Tags/TagPage';
import FeesListPage from './pages/Fees/FeesListPage';
import FeeEditPage from './pages/Fees/FeeEditPage';
import AdminsPage from './pages/UsersPage/AdminsPage';
import InterpretationAddPage from './pages/Interpretations/InterpretationAdd/InterpretationAddPage';
import LoginPage from './pages/Login/Login';
import WorkingHoursListPage from './pages/WorkingHours/WorkingHoursListPage';
import UserGroupsListPage from './pages/UserGroupsList/UserGroupsListPage';
import UserGroupEditPage from './pages/UserGroupEdit/UserGroupEditPage';
import UserContractSettingsListPage from './pages/UserContractSettingsList/UserContractSettingsListPage';
import UserContractSettingsEditPage from './pages/UserContractSettingsEdit/UserContractSettingsEditPage';
import UserShiftsEditPage from './pages/UserShifts/UserShiftsEditPage';
import UserShiftsListPage from './pages/UserShifts/UserShiftsListPage';
import EezyTrusterEventsPage from './pages/EezyTrusterEvents/EezyTrusterEventsPage';
import EezyTrusterEventPage from './pages/EezyTrusterEvents/EezyTrusterEventPage';
import CategoryListPage from './pages/Category/CategoryListPage';
import CategoryEditPage from './pages/Category/CategoryEditPage';
import AttributeEditPage from './pages/Attribute/AttributeEditPage';
import AttributeListPage from './pages/Attribute/AttributeListPage';
import WorkingHoursSummariesListPage from './pages/WorkingHours/WorkingHoursSummariesListPage';
import WorkingHoursEditPage from './pages/WorkingHours/WorkingHoursEditPage';

interface IProps { }


const App: React.FC<IProps> = () => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const [showHeader, setShowHeader] = useState(true);
	const [unAuthAllow, setUnAuthAllow] = useState(false);
	const location = useLocation()

	const { loading, error, currentUser: user } = useSelector((state: IAppState) => ({
		loading: state.auth.loading,
		error: state.auth.error,
		currentUser: state.auth.user
	}));

	useEffect(() => {
		dispatch(actions.getAuthUser());
		dispatch(actions.findLanguages());
		dispatch(actions.findUserGroups());
	}, [dispatch]);

	useEffect(()=>{
		if(!user || !user.site || !user.site.id) return;
		dispatch(actions.getSiteAndSetAsCurrent(user.site.id));
		return () =>{ dispatch(actions.clearCurrentUserSite()) }
	},[dispatch, user])

	useEffect(() => {
		if (!user) return;
		// Quick and dirty way to crossmatch namespaces with site name and setting the correct translations and copies
		// There's something funky going on with i18n implementation + header components. Have to set language to trigger
		// useEffect on header to re-render it.
		// TODO(Joonas): Find a better solution for header re-rendering as this is not the appropriate usage of changeLanguage.
		if (i18n.options.ns && typeof i18n.options.ns !== "string") {
			for (let ns of i18n.options.ns) {
				if (user.site?.prefix === ns) {
					i18n.setDefaultNamespace(ns);
					i18n.changeLanguage(i18n.language)
				}
			}
		}
	}, [user, i18n])

	useEffect(() => {
		if (location.search.includes('nh')) { // No Header
			setShowHeader(false);
		}
		// Consider possible auth problems
		if (Object.values(ERouteUnAuthAllow).includes(location.pathname as ERouteUnAuthAllow)) {
			setShowHeader(false);
			setUnAuthAllow(true);
		}
	}, [location])

	// If no unauth bool -> login page
	if (error && !unAuthAllow) {
		return <Redirect to={ERoute.USER_LOGIN} />
	}

	// TODO Consider moving unauth forms from here to somewhere else to get one clean modalswitch
	if (unAuthAllow) {
		return (
			<ModalSwitch header={showHeader}>
				<Route path={ERoute.USER_LOGIN} component={LoginPage} />
				<Route path={ERoute.ORDER_MODIFY_PAGE} component={ReservationModify} />
			</ModalSwitch>
		)
	}

	if (loading || !user) {
		return <Spinner center />;
	}

	// TODO probably not needed since we got login page now
	if (user.routes.length === 0) {
		return <Alert>{t(ETranslation.NO_ACCESS)}</Alert>
	}

	return (
		<ModalSwitch header={showHeader}>
			<GuardedRoute user={user} path={ERoute.PRICE_SEASONS_LIST} exact component={PriceSeasonsListPage} />
			<GuardedRoute user={user} path={ERoute.PRICE_SEASON_EDIT} component={PriceSeasonEditPage} />
			<GuardedRoute user={user} path={ERoute.PRICE_SEASON_RULES_LIST} exact component={PriceSeasonRulesListPage} />
			<GuardedRoute user={user} path={ERoute.PRICE_SEASON_RULE_EDIT} component={PriceSeasonRuleEditPage} />
			<GuardedRoute user={user} path={ERoute.ORGANIZATION_GROUPS_LIST} exact component={OrganizationGroupsListPage} />
			<GuardedRoute user={user} path={ERoute.ORGANIZATION_GROUP_EDIT} component={OrganizationGroupEditPage} />
			<GuardedRoute user={user} path={ERoute.MESSAGE_GROUPS_LIST} exact component={MessageGroupsListPage} />
			<GuardedRoute user={user} path={ERoute.MESSAGE_GROUP_EDIT} component={MessageGroupEditPage} />
			<GuardedRoute user={user} path={ERoute.MESSAGE_SHOW} component={MessageShowPage} />
			<GuardedRoute user={user} path={ERoute.MESSAGE_EDIT} component={MessageEditPage} />
			<GuardedRoute user={user} path={ERoute.MESSAGE_ANSWER} component={MessageEditPage} />
			<GuardedRoute user={user} path={ERoute.SENDGRID_EMAILS_LOGS} exact component={SendGridEmailsPage} />
			<GuardedRoute user={user} path={ERoute.SITES_LIST} exact component={SitesListPage} />
			<GuardedRoute user={user} path={ERoute.SITE_EDIT} component={SiteEditPage} />
			<GuardedRoute user={user} path={ERoute.TEXT_MESSAGES_LIST} exact component={TextMessagesListPage} />
			<GuardedRoute user={user} path={ERoute.TEXT_MESSAGE_EDIT} component={TextMessageEditPage} />
			<GuardedRoute user={user} path={ERoute.INFO_CONTACT_REQUESTS_LIST} exact component={InfoContactRequestsListPage} />
			<GuardedRoute user={user} path={ERoute.INFO_CONTACT_REQUEST_EDIT} component={InfoContactRequestEditPage} />
			<GuardedRoute user={user} path={ERoute.CUSTOMER_REPORTS_LIST} exact component={CustomerReportsPage} />
			<GuardedRoute user={user} path={ERoute.NEWS_ITEMS_LIST} exact component={NewsItemsListPage} />
			<GuardedRoute user={user} path={ERoute.NEWS_ITEM_EDIT} component={NewsItemEditPage} />
			<GuardedRoute user={user} path={ERoute.FILE_UPLOAD} component={FileUploadPage} />
			<GuardedRoute user={user} path={ERoute.LOG_ITEMS_LIST} exact component={LogItemsListPage} />
			<GuardedRoute user={user} path={ERoute.LOG_ITEM_EDIT} component={LogItemEditPage} />
			<GuardedRoute user={user} path={ERoute.ORDER_INTERPRETATION} component={OrderInterpretationPage} />
			<GuardedRoute user={user} path={ERoute.VIDEO} component={VideoPage} />
			<GuardedRoute user={user} path={ERoute.INTERPRETATION_REVIEW} component={InterpretationReviewPage} />
			<GuardedRoute user={user} path={ERoute.CHAT} component={TwilioChatPage} />
			<GuardedRoute user={user} path={ERoute.INTERPRETATIONS_LIST} exact component={InterpretationsListPage} />
			<GuardedRoute user={user} path={ERoute.INTERPRETATION_EDIT} component={InterpretationPage} />
			<GuardedRoute user={user} path={ERoute.INTERPRETATION_PROCESSING} component={InterpretationProcessingPage} />
			<GuardedRoute user={user} path={ERoute.CALENDAR_PAGE} component={CalendarViewPage} />
			<GuardedRoute user={user} path={ERoute.USERS_CUSTOMERS_LIST} exact component={CustomersPage} />
			<GuardedRoute user={user} path={ERoute.USERS_ADMINS_LIST} exact component={AdminsPage} />
			<GuardedRoute user={user} path={ERoute.USERS_INTERPRETERS_LIST} exact component={InterpretersPage} />
			<GuardedRoute user={user} path={ERoute.COMMENTS_PAGE} component={CommentsPage} />
			<GuardedRoute user={user} path={ERoute.COMMISSIONS_LIST} exact component={CommissionsPage} />
			<GuardedRoute user={user} path={ERoute.COMMISSION_EDIT} component={CommissionPage} />
			<GuardedRoute user={user} path={ERoute.TAG_EDIT} component={TagPage} />
			<GuardedRoute user={user} path={ERoute.TAGS_LIST} exact component={TagsPage} />
			<GuardedRoute user={user} path={ERoute.RESERVED_DATES_PAGE} component={ReservedDatesListPage} />
			<GuardedRoute user={user} path={ERoute.ORDER_EDIT} component={OrderPage} />
			<GuardedRoute user={user} path={ERoute.ORDERS_LIST} exact component={OrdersPage} />
			<GuardedRoute user={user} path={ERoute.TASKS_LIST} exact component={TasksPage} />
			<GuardedRoute user={user} path={ERoute.ORGANIZATION_EDIT} component={OrganizationEditPage} />
			<GuardedRoute user={user} path={ERoute.ORGANIZATIONS_LIST} exact component={OrganizationsPage} />
			<GuardedRoute user={user} path={ERoute.SUMMARIES_LIST} exact component={SummariesPage} />
			<GuardedRoute user={user} path={ERoute.SUMMARY_EDIT} component={SummariesViewPage} />
			<GuardedRoute user={user} path={ERoute.USER_EDIT} component={UserEditPage} />
			<GuardedRoute user={user} path={ERoute.FEE_EDIT} component={FeeEditPage} />
			<GuardedRoute user={user} path={ERoute.FEES_LIST} exact component={FeesListPage} />
			<GuardedRoute user={user} path={ERoute.INTERPRETATION_ADD} exact component={InterpretationAddPage} />
			<GuardedRoute user={user} path={ERoute.LANGUAGE_CODES_LIST} exact component={LanguageCodeListPage} />
			<GuardedRoute user={user} path={ERoute.LANGUAGE_CODE_EDIT} component={LanguageCodeEditPage} />
			<GuardedRoute user={user} path={ERoute.LANGUAGES_LIST} exact component={LanguageListPage} />
			<GuardedRoute user={user} path={ERoute.LANGUAGE_EDIT} component={LanguageEditPage} />
			<GuardedRoute user={user} path={ERoute.WORKING_HOURS_EDIT_PAGE} component={WorkingHoursEditPage} />
			<GuardedRoute user={user} path={ERoute.WORKING_HOURS_LIST_PAGE} component={WorkingHoursListPage} />
			<GuardedRoute user={user} path={ERoute.WORKING_HOURS_SUMMARIES_LIST_PAGE} component={WorkingHoursSummariesListPage} />
			<GuardedRoute user={user} path={ERoute.USER_GROUPS_LIST} exact component={UserGroupsListPage} />
			<GuardedRoute user={user} path={ERoute.USER_GROUPS_EDIT} component={UserGroupEditPage} />
			<GuardedRoute user={user} path={ERoute.USER_CONTRACT_SETTINGS_LIST} exact component={UserContractSettingsListPage} />
			<GuardedRoute user={user} path={ERoute.USER_CONTRACT_SETTINGS_EDIT} component={UserContractSettingsEditPage} />
			<GuardedRoute user={user} path={ERoute.USER_SHIFTS_LIST} exact component={UserShiftsListPage} />
			<GuardedRoute user={user} path={ERoute.USER_SHIFTS_EDIT} component={UserShiftsEditPage} />
			<GuardedRoute user={user} path={ERoute.CATEGORIES_LIST} exact component={CategoryListPage} />
			<GuardedRoute user={user} path={ERoute.CATEGORIES_EDIT} component={CategoryEditPage} />
			<GuardedRoute user={user} path={ERoute.ATTRIBUTES_LIST} exact component={AttributeListPage} />
			<GuardedRoute user={user} path={ERoute.ATTRIBUTES_EDIT} component={AttributeEditPage} />
			{/** EEZY TRUSTER EVENTS */}
			<GuardedRoute user={user} path={ERoute.EEZY_TRUSTER_EVENTS} exact component={EezyTrusterEventsPage} />
			<GuardedRoute user={user} path={ERoute.EEZY_TRUSTER_EVENT} component={EezyTrusterEventPage} />
			{/** Prevent redirect on every page reload and when accessing a link sent by other users. */}
			{!user.hasAccess(location.pathname as string) ? <Redirect to={user.redirect} /> : <></>}
		</ModalSwitch>
	);
};

export default App;
