import { useCallback, useEffect } from "react";
import { IInputField } from "../components/ui/Input/Input";
import { ESearchStorageKey } from "../shared/log-items-data";
import { clearParameter, getParameter, setParameter } from "../services/useSearchInputCache";
import { initForm } from "../components/ui/Input/input-utils";


interface IUseStoredSearchParamsProps<TFormOptions, TActions, TSearchParams> {
	setInputs: React.Dispatch<React.SetStateAction<IInputField>>;
	initialInputs: IInputField;
	storageKey: ESearchStorageKey;
	clearResults(): TActions;
	initIdInputs?: (data: TFormOptions) => void;
	searchHandler: (search: TSearchParams) => void;
	searchHandlerDataTransform: (data: TFormOptions) => TSearchParams;
}

export const useStoredSearchParams = function <TFormOptions, TActions, TSearchParam>({
	setInputs,
	initialInputs,
	storageKey,
	clearResults,
	initIdInputs,
	searchHandler,
	searchHandlerDataTransform }: IUseStoredSearchParamsProps<TFormOptions, TActions, TSearchParam>) {

	const onSearch = useCallback((searchParams: TFormOptions) => {
		if (clearResults) clearResults();
		searchHandler(searchHandlerDataTransform(searchParams));
		setParameter(storageKey, searchParams);
	}, [searchHandler, clearResults, storageKey, searchHandlerDataTransform]);

	useEffect(() => {
		const storageData = getParameter(storageKey);
		if (storageData) {
			const data: TFormOptions = JSON.parse(storageData)
			initForm(setInputs, data);
			if (initIdInputs) initIdInputs(data);
		}
	}, [initIdInputs, setInputs, storageKey])

	const clearInputs = () => {
		clearParameter(storageKey);
		setInputs(initialInputs);
	};

	return { onSearch, clearInputs }
}