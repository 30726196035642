import React, { useContext, useEffect, useState } from 'react';

import { useCreateInput } from '../../hooks/useCreateInput';
import { IAttribute } from '../../interfaces/IAttribute';
import { IAttributeValues } from '../../interfaces/IAttributeValues';
import EditButtons from '../ui/EditButtons/EditButtons';
import { EInputType, IInputField } from '../ui/Input/Input';
import { getInputData, initForm } from '../ui/Input/input-utils';
import InputGroup from '../ui/InputGroup/InputGroup';
import ModalBody from '../ui/Modal/ModalBody/ModalBody';
import ModalContext from '../ui/Modal/ModalContext';
import ModalFooter from '../ui/Modal/ModalFooter/ModalFooter';

interface IProps {
	onAccept: (values: IAttributeValues) => void	
	attribute: IAttribute;
}

enum EInputs {
	expirationDate = "expirationDate",
}

const AttributeValuesModal: React.FC<IProps> = ({ onAccept, attribute }) => {
	const { closeModal } = useContext(ModalContext);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.expirationDate]: {
			type: EInputType.date,
			value: "",
			label: 'Vanhentumispvm'
		},
	});

	useEffect(() => {
		initForm(setInputs, attribute.values);
	}, [attribute]);

	const saveHandler = () => {
		const inputData = getInputData<IAttributeValues>(inputs);
		onAccept(inputData)
	};

	const createInput = useCreateInput(inputs, setInputs);
	const settings = attribute.category!.settings!;
	return (
		<>
			<ModalBody>
				<InputGroup>
					{settings.isExpirationDate && createInput(EInputs.expirationDate)}
				</InputGroup>
			</ModalBody>
			<ModalFooter>
				<EditButtons onSave={saveHandler} loading={false} onCancel={closeModal} isAdd={true} />
			</ModalFooter>
		</>
	);
};

export default AttributeValuesModal;
