import React, { CSSProperties, ReactNode } from 'react';

import classes from './LabelText.module.scss';

interface IProps {
  label: string;
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
}

const LabelText: React.FC<IProps> = ({ label, children, style, className }) => {
  const classNames = [classes.Container];

  if(className) {
    classNames.push(className);
  }

  return (
    <div className={classes.Container} style={style}>
      <label>{label}</label>
      <p>{children}</p>
    </div>
  )
}

export default LabelText;