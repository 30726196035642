import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute, Routes } from '../../classes/Routes';
import CategoryList from '../../components/Categories/CategoryList/CategoryList';
import Heading from '../../components/Heading/Heading';
import Button from '../../components/ui/Button/Button';
import Container from '../../components/ui/Container/Container';
import IAppState from '../../interfaces/store/IAppState';
import ICategoryState from '../../interfaces/store/ICategoryState';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';

interface IProps extends RouteComponentProps {}

const CategoryListPage: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { categories, loading, error } = useSelector<IAppState, ICategoryState>(state => state.category);

  const { t } = useTranslation();

  useEffect(() => {
    if (!categories) {
      dispatch(actions.findCategorys());
    }
  }, [dispatch, categories]);

  const addHandler = () => {
    openHandler("add");
  };

  const openHandler = (id?: string) => {
    if (!id) return;
    history.push(Routes.withParams(ERoute.CATEGORIES_EDIT, { id }));
  };

  return (
    <Container>
      <Heading>{t(ETranslation.MENU_CATEGORIES)}</Heading>
      <Button onClick={addHandler}>{t(ETranslation.CATEGORY_ADD_CATEGORY)}</Button>
      <CategoryList
        categories={categories}
        loading={loading}
        error={error}
        onClick={openHandler}
      />
    </Container>
  );
};

export default CategoryListPage;
