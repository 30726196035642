import EActionTypes from "../../interfaces/store/EActionTypes";
import ICommentState from "../../interfaces/store/ICommentState";
import ICommentAction from "../../interfaces/store/ICommentAction";


type ReducerState = ICommentState;
type ReducerSignature = (state: ReducerState, action: ICommentAction) => ReducerState;

const initialState: ReducerState = {
	comment: null,
	loading: false,
	error: null,
	comments: null,
	commentsLoading: false,
	paginationCursor: null,
	commentsError: null,
	hasMoreComments: false,
	searchParams: null,
	organization: null,
	organizationGroup: null,
};

const getCommentsStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: true, comments: null };
};

const getCommentsSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		paginationCursor: action.paginationCursor || null,
		comments: action.comments || null,
		error: null,
		loading: false,
	};
};

const getCommentsFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, comment: null, loading: false, error: action.error };
};

const getCommentsClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, comments: null, loading: false, error: null };
}

const reducer = (state: ReducerState = initialState, action: ICommentAction): ReducerState => {
	switch (action.type) {
		case EActionTypes.COMMENT_GET_LIST_START:
			return getCommentsStart(state, action);
		case EActionTypes.COMMENT_GET_LIST_SUCCESS:
			return getCommentsSuccess(state, action);
		case EActionTypes.COMMENT_GET_LIST_FAIL:
			return getCommentsFail(state, action);
		case EActionTypes.COMMENT_GET_LIST_CLEAR:
			return getCommentsClear(state, action);
		default:
			return state;
	}
};

export default reducer;
