import React from 'react';

import { Message } from '../../../classes/Message';
import MessageListItem from '../MessageListItem/MessageListItem';
import classes from './MessageList.module.scss';

interface IProps {
  messages?: Message[];
}

const MessageList: React.FC<IProps> = ({ messages }) => {
  return (
    <>
      {messages &&
        messages.map((message) => {
          if (message.parentMessageId) {
            return <MessageListItem key={message.id} message={message} />;
          }
          return (
            <ul className={classes.List} key={message.id}>
              <MessageListItem message={message} />
            </ul>
          );
        })}
    </>
  );
};

export default MessageList;
