import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute, Routes } from '../../../classes/Routes';
import Button from '../../../components/ui/Button/Button';
import Container from '../../../components/ui/Container/Container';
import IAppState from '../../../interfaces/store/IAppState';
import * as actions from '../../../store/actions';
import SiteList from '../../../components/Site/SiteList/SiteList';
import Heading from '../../../components/Heading/Heading';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';

interface IProps extends RouteComponentProps {}

const SitesListPage: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { sites, loading, error } = useSelector(
    (state: IAppState) => ({
      sites: state.site.sites,
      loading: state.site.sitesLoading,
      error: state.site.sitesError,
    })
  );

  useEffect(() => {
    dispatch(actions.findSites());
  }, [dispatch]);

  const addHandler = () => {
    openHandler("add");
  };

  const openHandler = (id?: string) => {
    if (!id) return;
    history.push(Routes.withParams(ERoute.SITE_EDIT, { id }));
  };

  return (
    <Container>
      <Heading>{t(ETranslation.MENU_SITES)}</Heading>
      <Button onClick={addHandler}>{t(ETranslation.PAGES_SITES_LIST_ADD_NEW)}</Button>
      <SiteList
        sites={sites}
        loading={loading}
        error={error}
        onClick={openHandler}
      />
    </Container>
  );
};

export default SitesListPage;
