import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute } from '../../../classes/Routes';
import Heading from '../../../components/Heading/Heading';
import LogItem from '../../../components/LogItem/LogItem';
import Alert, { EAlertColor } from '../../../components/ui/Alert/Alert';
import Container from '../../../components/ui/Container/Container';
import EditButtons from '../../../components/ui/EditButtons/EditButtons';
import Spinner from '../../../components/ui/Spinner/Spinner';
import { ILogItem } from '../../../interfaces/ILogItem';
import IAppState from '../../../interfaces/store/IAppState';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';
import classes from './LogItemEditPage.module.scss';

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const LogItemEditPage: React.FC<IProps> = ({ match, history }) => {
  const [editLogItem, setEditLogItem] = useState<ILogItem | null>(null);
  const [isValid, setIsValid] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const dispatch = useDispatch();

  const { id } = match.params;
  const isAdd = id === "add";

  const { t } = useTranslation();

  useEffect(() => {
    if (!isAdd) {
      dispatch(actions.getLogItem(id));
    }
  }, [isAdd, id, dispatch]);

  const {
    error,
    loading,
    saveOrUpdateOk,
    logItem,
    deleteError,
    deleteLoading,
    deleteOk,
  } = useSelector((state: IAppState) => ({
    error: state.logItem.logItemError || state.logItem.logItemSaveOrUpdateError,
    loading: state.logItem.logItemLoading || state.logItem.logItemSaveOrUpdateLoading,
    saveOrUpdateOk: state.logItem.logItemSaveOrUpdateOk,
    logItem: state.logItem.logItem,
    deleteLoading: state.logItem.logItemDeleteLoading,
    deleteError: state.logItem.logItemDeleteError,
    deleteOk: state.logItem.logItemDeleteOk,
  }));

  useEffect(() => {
    if (saveOrUpdateOk) {
      history.push(ERoute.LOG_ITEMS_LIST);
    }
    return () => {
      dispatch(actions.saveOrUpdateLogItemClear());
      dispatch(actions.getLogItemClear());
//    dispatch(actions.deleteLogItemClear());
    };
  }, [dispatch, saveOrUpdateOk, history]);

  const updateHandler = useCallback((logItem: ILogItem, isValid: boolean) => {
    setEditLogItem(logItem);
    setIsValid(isValid);
  }, []);

  const submitHandler = () => {
    if (!editLogItem) return;

    if (isAdd) {
      dispatch(actions.saveLogItem(editLogItem));
    } else {
      editLogItem.id = id;
      dispatch(actions.updateLogItem(editLogItem));
    }
  };

  /*
  const deleteHandler = () => {
    if (logItem) {
      dispatch(actions.deleteLogItem(logItem.id));
    }
  };
  */


  return (
    <Container>
      {error && <Alert>{error}</Alert>}
      {loading && <Spinner centerAbsolute />}
      <Heading className={classes.Heading}>
        {t(ETranslation.PAGES_LOG_ITEM_EDIT_TITLE)}
      </Heading>
      <Alert color={EAlertColor.INFO}>
      {t(ETranslation.PAGES_LOG_ITEM_EDIT_INFO)}
      </Alert>
      <LogItem
        onChange={updateHandler}
        loading={loading}
        logItem={logItem}
        showValidation={showValidation}
      />
      {/*      onDelete={deleteHandler} */}
      <EditButtons
        onSave={isValid ? submitHandler : () => setShowValidation(true)}
        disabled={showValidation && !isValid}
        loading={loading}
        onCancel={() => history.goBack()}
        deleteText={ETranslation.COMMON_DELETE}
        onDeleteClose={() => dispatch(actions.deleteLogItemClear())}
        onDeleteDone={() => history.push(ERoute.LOG_ITEMS_LIST)}
        deleteError={deleteError}
        deleteLoading={deleteLoading}
        deleteOk={deleteOk}
        isAdd={isAdd}
      />
    </Container>
  );
};

export default LogItemEditPage;
