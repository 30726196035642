import React from "react";
import Table from "../../components/ui/Table/Table";
import { IFee } from "../../store/actions/feeActions";
import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";

interface IProps {
	fees: IFee[] | null;
	loading: boolean;
	error: string | null;
	openFee: (feeId: string) => void;
}

const FeesList: React.FC<IProps> = ({ fees, loading, error, openFee }) => {
	const { t } = useTranslation()
	return (
		error ? <tr> {error}</tr> :
			<Table hover={true} >
				<thead>
					<tr>
						<th>{t(ETranslation.COMMON_CREATED)}</th>
						<th>{t(ETranslation.PAYMENT_DATE)}</th>
						<th>{t(ETranslation.COMMON_USERS)}</th>
						<th>{t(ETranslation.COMMON_PRICE)}</th>
					</tr>
				</thead>

				<tbody>
					{fees && fees.map((fee, id) => {
						return (
							<tr key={id} onClick={() => { openFee(fee.id ?? "") }} >
								<td>{fee.feeDate}</td>
								<td>{fee.created}</td>
								<td>
									<table>
										<tbody>
											{fee.users.map((user, key) => (
												<tr key={key}><td>{user.name}</td></tr>
											))}
										</tbody>
									</table>
								</td>
								<td>
									<table>
										<thead>
											<tr>
												<th>{t(ETranslation.COMMON_PRICE)}</th>
												<th>{t(ETranslation.PAYMENT_FEE_WITHOUT_VAT)}</th>
												<th>{t(ETranslation.COMMON_VAT)}</th>
											</tr>
										</thead>
										<tbody>
											{fee.feeRows.map((feeRow, key) => (
												<tr key={key}>
													<td>{(feeRow.feeWithoutVat * feeRow.amount * (feeRow.feeVatP / 100 + 1)).toString()}</td>
													<td>{(feeRow.feeWithoutVat * feeRow.amount).toString()}</td>
													<td>{(feeRow.feeWithoutVat * (feeRow.feeVatP / 100)).toString()}</td>
												</tr>
											))}
										</tbody>
									</table>
								</td>

							</tr>
						)
					})
					}
				</tbody>
			</Table>
	)
}

export default FeesList;