import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute } from '../../../classes/Routes';
import Button, { EButtonColor } from '../../../components/ui/Button/Button';
import Container from '../../../components/ui/Container/Container';
import InputGroup from '../../../components/ui/InputGroup/InputGroup';
import { IInterpretation } from '../../../interfaces/IInterpretation';
import IAppState from '../../../interfaces/store/IAppState';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';
import InterpretationAddFormYoupret from '../../../components/AddInterpretation/YoupretForm/AddInterpretationYoupretForm';
import InterpretationAddFormOthers from '../../../components/AddInterpretation/OthersForm/AddInterpretationOthersForm';

interface IMatch {
	id: string;
}

export interface IInterpretationAddHandle {
	getData: () => IInterpretation;
	isValid: () => boolean;
}

interface IProps extends RouteComponentProps<IMatch> { }

const InterpretationAddPage: React.FC<IProps> = ({
	history,
	match,
	location
}) => {
	const [showValidation, setShowValidation] = useState(false);
	const formRef = useRef<IInterpretationAddHandle>(null);
	const searchParams = new URLSearchParams(window.location.search);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { interpretationSaveOrUpdateOk, interpretationSaveOrUpdateLoading } = useSelector((state: IAppState) => state.interpretation);

	const { user: currentUser } = useSelector((state: IAppState) => state.auth);

	useEffect(() => {
		if (interpretationSaveOrUpdateOk) {
			dispatch(actions.saveOrUpdateInterpretationClear());
			history.push(ERoute.INTERPRETATIONS_LIST);
		}
	}, [interpretationSaveOrUpdateOk, history, dispatch]);

	const submitHandler = () => {
		if (formRef.current?.isValid()) {
			const data = formRef.current?.getData();
			dispatch(actions.saveInterpretation(data))
		} else {
			setShowValidation(true);
		}
	};

	const isJohannaOrValitys = currentUser?.isJohannaOrValitys;
	return (
		<Container>
			{isJohannaOrValitys ? (
				<InterpretationAddFormOthers ref={formRef} showValidation={showValidation} />
			) : (
				<InterpretationAddFormYoupret
					ref={formRef}
					urlParams={searchParams}
					showValidation={showValidation}
					history={history}
					match={match}
					location={location}
				/>
			)}
			<InputGroup style={{ margin: 0 }}>
				<Button
					loading={interpretationSaveOrUpdateLoading}
					onClick={submitHandler}
				>
					{t(ETranslation.COMMON_SAVE)}
				</Button>
				<Button
					onClick={() => { history.push(ERoute.INTERPRETATIONS_LIST) }}
					loading={interpretationSaveOrUpdateLoading}
					color={EButtonColor.DEFAULT}
				>
					{t(ETranslation.COMMON_CANCEL)}
				</Button>
			</InputGroup>
		</Container>
	);
};

export default InterpretationAddPage;