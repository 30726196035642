import { IOption } from "../components/ui/Input/Input";
import { ETranslation } from "../translations/translation-keys";


export enum EUserContractSettingsType {
	TEMPORARY = 'TEMPORARY', 
  PERMANENT = 'PERMANENT'
};

export const USER_CONTRACT_SETTINGS_TYPES: IOption[] = [
	{ value: EUserContractSettingsType.TEMPORARY, labelTranslation: ETranslation.USER_CONTRACT_SETTINGS_TYPE_TEMPORARY },
	{ value: EUserContractSettingsType.PERMANENT, labelTranslation: ETranslation.USER_CONTRACT_SETTINGS_TYPE_PERMANENT },
];

/*
export enum EUserContractBreakSettings {
	BREAK_30_MINUTES = 'BREAK_30_MINUTES', 
};

export const USER_CONTRACT_MEAL_BREAK_OPTIONS: IOption[] = [
	{ value: EUserContractBreakSettings.BREAK_30_MINUTES, labelTranslation: ETranslation.USER_CONTRACT_BREAK_SETTINGS_BREAK_30_MINUTES },
];
*/

export enum EUserContractHolidayAccurual {
	ONE_YEAR_TWO_AFTER_TWO_AND_HALF = 'ONE_YEAR_TWO_AFTER_TWO_AND_HALF', 
};

export const USER_CONTRACT_HOLIDAY_ACCURUAL_OPTIONS: IOption[] = [
	{ value: EUserContractHolidayAccurual.ONE_YEAR_TWO_AFTER_TWO_AND_HALF, labelTranslation: ETranslation.USER_CONTRACT_HOLIDAY_ACCURUAL_ONE_YEAR_TWO_AFTER_TWO_AND_HALF },
];