import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute } from '../../../classes/Routes';
import Heading from '../../../components/Heading/Heading';
import Language from '../../../components/Language/Language';
import Alert from '../../../components/ui/Alert/Alert';
import Container from '../../../components/ui/Container/Container';
import EditButtons from '../../../components/ui/EditButtons/EditButtons';
import Spinner from '../../../components/ui/Spinner/Spinner';
import { ILanguage } from '../../../interfaces/ILanguage';
import IAppState from '../../../interfaces/store/IAppState';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';

interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const LanguageEditPage: React.FC<IProps> = ({ match, history }) => {
	const [editLanguage, setEditLanguage] = useState<ILanguage | null>(null);
	const [isValid, setIsValid] = useState(false);
	const [showValidation, setShowValidation] = useState(false);

	const dispatch = useDispatch();

	const { id } = match.params;
	const isAdd = id === "add";

	const { t } = useTranslation();

	useEffect(() => {
		if (!isAdd) {
			dispatch(actions.getLanguage(id));
		}
	}, [isAdd, id, dispatch]);

	const {
		error,
		loading,
		saveOrUpdateOk,
		language,
		deleteError,
		deleteLoading,
		deleteOk,
	} = useSelector((state: IAppState) => ({
		error:
			state.language.languageError ||
			state.language.languageSaveOrUpdateError,
		loading:
			state.language.languageLoading ||
			state.language.languageSaveOrUpdateLoading,
		saveOrUpdateOk: state.language.languageSaveOrUpdateOk,
		language: state.language.language,
		deleteLoading: state.language.languageDeleteLoading,
		deleteError: state.language.languageDeleteError,
		deleteOk: state.language.languageDeleteOk,
	}));

	useEffect(() => {
		if (saveOrUpdateOk) {
			history.push(ERoute.LANGUAGES_LIST);
		}
		return () => {
			dispatch(actions.clearLanguageState());
		};
	}, [dispatch, saveOrUpdateOk, history]);

	const updateHandler = useCallback(
		(language: ILanguage, isValid: boolean) => {
			setEditLanguage(language);
			setIsValid(isValid);
		},
		[]
	);

	const submitHandler = () => {
		if (!editLanguage) return;

		if (isAdd) {
			dispatch(actions.saveLanguage(editLanguage));
		} else {
			editLanguage.id = id;
			dispatch(actions.updateLanguage(editLanguage));
		}
	};

	const deleteHandler = () => {
		dispatch(actions.deleteLanguage(id));
	};

	return (
		<Container>
			{error && <Alert>{error}</Alert>}
			{loading && <Spinner centerAbsolute />}
			<Heading>{t(ETranslation.PAGES_LANGUAGE_EDIT_TITLE)}</Heading>
			{/*
				<Alert color={EAlertColor.INFO}>
					{t(ETranslation.PAGES_LANGUAGE_EDIT_INFO)}
				</Alert>
			*/}
			<Language
				onChange={updateHandler}
				loading={loading}
				language={language}
				showValidation={showValidation}
			/>
			<EditButtons
				onSave={isValid ? submitHandler : () => setShowValidation(true)}
				disabled={showValidation && !isValid}
				loading={loading}
				onCancel={() => history.goBack()}
				deleteText={t(ETranslation.PAGES_LANGUAGE_EDIT_DELETE_CONFIRM)}
				onDeleteClose={() => dispatch(actions.deleteLanguageClear())}
				onDeleteDone={() => history.push(ERoute.LANGUAGES_LIST)}
				deleteError={deleteError}
				deleteLoading={deleteLoading}
				deleteOk={deleteOk}
				isAdd={isAdd}
				onDelete={deleteHandler}
			/>
		</Container>
	);
};

export default LanguageEditPage;
