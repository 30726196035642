import { IOption } from "../components/ui/Input/Input";
import { ETranslation } from "../translations/translation-keys";

export enum EUserShiftDay {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export const USER_SHIFT_DAY_OPTIONS: IOption[] = [
  { value: EUserShiftDay.MONDAY, labelTranslation: ETranslation.COMMON_MONDAY},
  { value: EUserShiftDay.TUESDAY, labelTranslation: ETranslation.COMMON_TUESDAY},
  { value: EUserShiftDay.WEDNESDAY, labelTranslation: ETranslation.COMMON_WEDNESDAY},
  { value: EUserShiftDay.THURSDAY, labelTranslation: ETranslation.COMMON_THURSDAY},
  { value: EUserShiftDay.FRIDAY, labelTranslation: ETranslation.COMMON_FRIDAY},
  { value: EUserShiftDay.SATURDAY, labelTranslation: ETranslation.COMMON_SATURDAY},
  { value: EUserShiftDay.SUNDAY, labelTranslation: ETranslation.COMMON_SUNDAY},
]

export const USER_SHIFT_MONTH_OPTIONS: IOption[] = [
	{ label: "Tammikuu", value: "1" },
	{ label: "Helmikuu", value: "2" },
	{ label: "Maaliskuu", value: "3" },
	{ label: "Huhtikuu", value: "4" },
	{ label: "Toukokuu", value: "5" },
	{ label: "Kesäkuu", value: "6" },
	{ label: "Heinäkuu", value: "7" },
	{ label: "Elokuu", value: "8" },
	{ label: "Syyskuu", value: "9" },
	{ label: "Lokakuu", value: "10" },
	{ label: "Marraskuu", value: "11" },
	{ label: "Joulukuu", value: "12" },
];

export const USER_SHIFT_YEAR_OPTIONS = (value?: number): IOption[] => {
	const now = new Date();
	const year = now.getFullYear();
	let years = [year, year + 1];
	if (value && !years.includes(value)) {
		years = [value, ...years];
	}
	return years.map((year) => ({ value: year.toString(), label: year.toString() }));
};