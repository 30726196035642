import { Dispatch } from "redux";
import EActionTypes from "../../interfaces/store/EActionTypes";
import { EFetchMethod, customFetch, customFetchWithResponse } from "../../custom-fetch";
import { IUser } from "../../classes/User";
import { IOrganization } from "../../interfaces/IOrganization";
import { ISite } from "../../interfaces/ISite";
import IFeeAction from "../../interfaces/store/IFeeAction";
import { IFeeSearchParams } from "../../components/Fee/FeeSearchInputs";

export interface IFee {
	id: string | null;
	created: string;
	feeDate: string;
	updated: string;
	users: IUser[];
	organizations: IOrganization[];
	site: ISite | string;
	feeRows: IFeeRow[];
}

export interface IFeeRow {
	id: string;
	created: string;
	updated: string;
	type: "ACCEPTANCE_FEE" | "EXTRA_FEE";
	feeVatP: number;
	feeWithoutVat: number;
	amount: number;
	unit: "PCS" | "MINUTES";
	currency: "EUR" | "USD";
	descriptionText: string;
	adminNotesText: string;
	user: IUser | null;
	paymentMethod: "EEZY" | "NO_PAYMENT" | "OTHER";
}

type TAction = IFeeAction;

const getFeesStart = (): TAction => {
	return {
		type: EActionTypes.FEES_GET_START
	}
}

const getFeesSuccess = (fees: IFee[], paginationCursor: string, hasMoreFees: boolean): TAction => {
	return {
		type: EActionTypes.FEES_GET_SUCCESS,
		paginationCursor,
		hasMoreFees,
		fees
	}
}

const getFeesError = (error: string): TAction => {
	return {
		type: EActionTypes.FEES_GET_ERROR,
		error
	}
}

export const getFeesClear = (): TAction => {
	return {
		type: EActionTypes.FEES_GET_CLEAR
	}
}

const getFeeStart = (): TAction => {
	return {
		type: EActionTypes.FEE_GET_START
	}
}

const getFeeSuccess = (fee: IFee): TAction => {
	return {
		type: EActionTypes.FEE_GET_SUCCESS,
		fee
	}
}

const getFeeError = (error: string): TAction => {
	return {
		type: EActionTypes.FEE_GET_ERROR,
		error
	}
}

export const getFeeClear = (): TAction => {
	return {
		type: EActionTypes.FEE_GET_CLEAR
	}
}

const feeSaveStart = (): TAction => {
	return {
		type: EActionTypes.FEE_SAVE_START
	}
}

const feeSaveSuccess = (newFee?: IFee): TAction => {
	return {
		type: EActionTypes.FEE_SAVE_SUCCESS,
		newFee
	}
}

const feeSaveError = (error: string): TAction => {
	return {
		type: EActionTypes.FEE_SAVE_ERROR,
		error
	}
}

export const feeSaveClear = (): TAction => {
	return {
		type: EActionTypes.FEE_SAVE_CLEAR
	}
}

export const feeDeleteClear = (): TAction => {
	return {
		type: EActionTypes.FEE_DELETE_CLEAR,
	}
}

const feeDeleteError = (error: string): TAction => {
	return {
		type: EActionTypes.FEE_DELETE_ERROR,
		error,
	}
}

const feeDeleteSuccess = (feeId: string): TAction => {
	return {
		type: EActionTypes.FEE_DELETE_SUCCESS,
		deletedFee: feeId,
	}
}

const feeDeleteStart = (): TAction => {
	return {
		type: EActionTypes.FEE_DELETE_START
	}
}

export const deleteFee = (feeId: string) =>{
	return async (dispatch: Dispatch) =>{
		try{
			dispatch(feeDeleteStart());
			customFetch<IFee>("/fees/delete?id=" + feeId, EFetchMethod.POST).then((data) => {
				dispatch(feeDeleteSuccess(feeId));
			}).catch((error) => {
				dispatch(feeDeleteError("Tapahtui virhe: " + (error as Error).message));
			})
		} catch (error) {
			dispatch(getFeesError((error as Error).message));
		}
	}
}

export const getFee = (feeId: string) => {
	return (dispatch: Dispatch) => {
		dispatch(getFeeStart())
		customFetch<IFee>("/fees?id=" + feeId, EFetchMethod.GET).then((data) => {
			dispatch(getFeeSuccess(data));
		}).catch((error) => {
			dispatch(getFeeError("Tapahtui virhe: " + (error as Error).message));
		})
	};
};

export const saveOrAddFee = (fee: IFee, isAdd: boolean) => {
	return (dispatch: Dispatch) => {
		dispatch(feeSaveStart())
		customFetch<IFee>("/fees/add", EFetchMethod.POST, JSON.stringify(fee)).then((data) => {
			isAdd ? dispatch(feeSaveSuccess(data)) : dispatch(feeSaveSuccess());
		}).catch((error) => {
			dispatch(feeSaveError("Tapahtui virhe: " + (error as Error).message));
		})
	};
};



export const getFees = (searchParams?: IFeeSearchParams) => {
	return async (dispatch: Dispatch) => {
		dispatch(getFeesStart())
		try {
			const res = await customFetchWithResponse("/fees/list", EFetchMethod.POST, JSON.stringify(searchParams))
			const paginationCursor = res.headers.get("Pagination-Cursor") ?? "";
			const json = await res.json();
			dispatch(getFeesSuccess(json.items, paginationCursor, json.hasNext))
		} catch (error) {
			dispatch(getFeesError((error as Error).message));
		}
	};
};