import React, { useEffect } from "react";
import Button from "../../components/ui/Button/Button";
import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import IAppState from "../../interfaces/store/IAppState";
import ModalBody from "../ui/Modal/ModalBody/ModalBody";
import ModalFooter from "../ui/Modal/ModalFooter/ModalFooter";

interface IProps {
	selectedRows: string[] | null;
	selectedValue: "PUBLISHED" | "DRAFT" | "DELETE";
	closeModal: () => void;
}

const SummaryCheckedOptionsModal: React.FC<IProps> = ({ selectedRows, selectedValue, closeModal }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { updateOk, loading } = useSelector((state: IAppState) => ({
		updateOk: state.summaries.updateOk,
		loading: state.summaries.updateLoading
	}));

	useEffect(() => {
		if (!updateOk) return;
		closeModal()
	}, [updateOk, closeModal])

	const updateSummaryCallBack = () => {
		if (!selectedRows) return;
		selectedValue === "DELETE" ? dispatch(actions.deleteSummary(selectedRows)) : dispatch(actions.updateSummaryStatus(selectedRows, selectedValue));
	};

	const validationText = () => {
		switch (selectedValue) {
			case "PUBLISHED":
				return "Haluatko julkaista valitut yhteenvedot?"
			case "DELETE":
				return "Haluatko varmasti poistaa valitut yhteenvedot?"
			case "DRAFT":
				return "Haluatko muuttaa valitut yhteenvedot luonnoksiksi?"
		}
	}

	return (
		<>
			<ModalBody>
				{validationText()}
			</ModalBody>
			<ModalFooter>
				<Button onClick={updateSummaryCallBack} loading={loading}>
					{t(ETranslation.COMMON_YES)}
				</Button>
				<Button onClick={closeModal} loading={loading}>
					{t(ETranslation.COMMON_CANCEL)}
				</Button>
			</ModalFooter>
		</>
	);
};
export default SummaryCheckedOptionsModal;
