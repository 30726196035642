import { IUserShiftMonth } from '../../interfaces/IUserShiftMonth';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IUserShiftAction from '../../interfaces/store/IUserShiftAction';
import IUserShiftState from '../../interfaces/store/IUserShiftState';
import { ETranslation } from '../../translations/translation-keys';
import { removeOne } from '../../utils/reducer-utils';

type ReducerAction = IUserShiftAction;
type ReducerState = IUserShiftState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  userShift: null,

  userShifts: null,
  loading: false,
  updating: false,
  error: null,
  
  deletingError: null,
  deleting: false,
  deletingOk: false,

  saveOrUpdateOk: false,
  saveDatesError: null,
  saveDatesLoading: false,
  saveDatesOk: false,

  deleteDatesError: null,
  deleteDatesLoading: false,
  deleteDatesOk: false,
};

const findUserShiftMonthStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const findUserShiftMonthSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    userShifts: action.userShifts || null,
    error: null,
    loading: false,
  };
};

const findUserShiftMonthFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, userShifts: null, loading: false, error: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getUserShiftMonthStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true, userShift: null };
};

const getUserShiftMonthSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    userShift: action.userShift || null,
    error: null,
    loading: false,
  };
};

const getUserShiftMonthFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, userShift: null, loading: false, error: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getUserShiftMonthClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, userShift: null, loading: false, error: null};
};

const deleteUserShiftMonthStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deletingError: null, deleting: true, deletingOk: false, };
};

const deleteUserShiftMonthSuccess: ReducerSignature = (state, action): ReducerState => {
  const userShifts = removeOne<IUserShiftMonth>(state.userShifts, action.id);
  return {
    ...state,
    userShifts,
    deletingError: null,
    deleting: false,
    deletingOk: true,
  };
};

const deleteUserShiftMonthFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deleting: false, deletingError: action.error as string, deletingOk: false, };
};

const deleteUserShiftMonthClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deletingError: null, deleting: false, deletingOk: false, };
};


const saveUserShiftMonthStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const saveUserShiftMonthSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    userShift: action.userShift || null,
    error: null,
    loading: false,
    saveOrUpdateOk: true
  };
};

const saveUserShiftMonthFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const updateUserShiftMonthStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, updating: true };
};

const updateUserShiftMonthSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    error: null,
    updating: false,
    saveOrUpdateOk: true,
    userShift: action.userShift || null,
  };
};

const updateUserShiftMonthFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, updating: false, error: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const saveOrUpdateUserShiftMonthClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: null, saveOrUpdateOk: false }
}

const saveUserShiftDatesStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, saveDatesError: null, saveDatesLoading: true };
};

const saveUserShiftDatesSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    userShift: action.userShift || null,
    saveDatesError: null,
    saveDatesLoading: false,
    saveDatesOk: true
  };
};

const saveUserShiftDatesFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, saveDatesLoading: false, saveDatesError: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const saveUserShiftDatesClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, saveDatesOk: false, saveDatesLoading: false, saveDatesError: null };
};

const deleteUserShiftDatesStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deleteDatesError: null, deleteDatesLoading: true };
};

const deleteUserShiftDatesSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    userShift: action.userShift || null,
    deleteDatesLoading: false,
  };
};

const deleteUserShiftDatesFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deleteDatesLoading: false, deleteDatesError: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.USER_SHIFT_FIND_START:
      return findUserShiftMonthStart(state, action);
    case EActionTypes.USER_SHIFT_FIND_SUCCESS:
      return findUserShiftMonthSuccess(state, action);
    case EActionTypes.USER_SHIFT_FIND_FAIL:
      return findUserShiftMonthFail(state, action);
    case EActionTypes.USER_SHIFT_GET_START:
      return getUserShiftMonthStart(state, action);
    case EActionTypes.USER_SHIFT_GET_SUCCESS:
      return getUserShiftMonthSuccess(state, action);
    case EActionTypes.USER_SHIFT_GET_FAIL:
      return getUserShiftMonthFail(state, action);
    case EActionTypes.USER_SHIFT_GET_CLEAR:
      return getUserShiftMonthClear(state, action);
    case EActionTypes.USER_SHIFT_DELETE_START:
      return deleteUserShiftMonthStart(state, action);
    case EActionTypes.USER_SHIFT_DELETE_SUCCESS:
      return deleteUserShiftMonthSuccess(state, action);
    case EActionTypes.USER_SHIFT_DELETE_FAIL:
      return deleteUserShiftMonthFail(state, action);
    case EActionTypes.USER_SHIFT_DELETE_CLEAR:
      return deleteUserShiftMonthClear(state, action);
    case EActionTypes.USER_SHIFT_SAVE_START:
      return saveUserShiftMonthStart(state, action);
    case EActionTypes.USER_SHIFT_SAVE_SUCCESS:
      return saveUserShiftMonthSuccess(state, action);
    case EActionTypes.USER_SHIFT_SAVE_FAIL:
      return saveUserShiftMonthFail(state, action);
    case EActionTypes.USER_SHIFT_UPDATE_START:
      return updateUserShiftMonthStart(state, action);
    case EActionTypes.USER_SHIFT_UPDATE_SUCCESS:
      return updateUserShiftMonthSuccess(state, action);
    case EActionTypes.USER_SHIFT_UPDATE_FAIL:
      return updateUserShiftMonthFail(state, action);
    case EActionTypes.USER_SHIFT_SAVE_OR_UPDATE_CLEAR:
      return saveOrUpdateUserShiftMonthClear(state, action)
    case EActionTypes.USER_SHIFT_DATES_SAVE_START:
      return saveUserShiftDatesStart(state, action);
    case EActionTypes.USER_SHIFT_DATES_SAVE_SUCCESS:
      return saveUserShiftDatesSuccess(state, action);
    case EActionTypes.USER_SHIFT_DATES_SAVE_FAIL:
      return saveUserShiftDatesFail(state, action);
    case EActionTypes.USER_SHIFT_DATES_SAVE_CLEAR:
      return saveUserShiftDatesClear(state, action);
    case EActionTypes.USER_SHIFT_DATES_DELETE_START:
      return deleteUserShiftDatesStart(state, action);
    case EActionTypes.USER_SHIFT_DATES_DELETE_SUCCESS:
      return deleteUserShiftDatesSuccess(state, action);
    case EActionTypes.USER_SHIFT_DATES_DELETE_FAIL:
      return deleteUserShiftDatesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
