
export enum EAttachmentStatus {
  DRAFT = 'DRAFT', 
  COMPLETE = 'COMPLETE', 
  FAIL = 'FAIL'
}

export interface IAttachment {
  id: string;
  contentType: string;
  size: number;
  clientName: string;
  location: string;
  file?: File;
  status: EAttachmentStatus;
  link?: string;
  publicAccess?: boolean;
}