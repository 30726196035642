import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IUser } from "../../../classes/User";

import { useCreateInput } from "../../../hooks/useCreateInput";
import { useInterpretation } from "../../../hooks/useInterpretation";

import { IInterpretation } from "../../../interfaces/IInterpretation";
import { IOrganization } from "../../../interfaces/IOrganization";
import IAppState from "../../../interfaces/store/IAppState";
import IAuthState from "../../../interfaces/store/IAuthState";
import { ETranslation } from "../../../translations/translation-keys";
import Alert, { EAlertColor } from "../../ui/Alert/Alert";
import {
  EInputType,
  IInputField,
} from "../../ui/Input/Input";
import {
  getInputData,
  validateInputs,
} from "../../ui/Input/input-utils";
import InputGroupOrder from "../../ui/InputGroup/InputGroupOrder/InputGroupOrder";
import classes from "./OrderInterpretation.module.scss"

enum EInputs {
  type = "type"
}

interface IProps {
  onChange: (interpretation: IInterpretation, isValid: boolean) => void;
  organization: IOrganization | null;
  loading?: boolean;
  showValidation?: boolean;
}

const OrderInterpretationType: React.FC<IProps> = ({
  onChange,
  loading,
  organization,
  showValidation,
}) => {
  const { user } = useSelector<IAppState, IAuthState>((state) => state.auth);
  const [ infoText, setInfoText ] = useState<string>();
  const { t } = useTranslation();
  const {getSiteInterpretationTypes} = useInterpretation(user as IUser, organization as IOrganization);
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.type]: {
      type: EInputType.radio,
      value: "",
      options: getSiteInterpretationTypes()
    }
  });

  useEffect(() => {
    let value = "";
    if(user?.allowBooking)value += t(ETranslation.INFO_TEXT_BOOKING) + " ";
    if(user?.allowOnSite)value += t(ETranslation.INFO_TEXT_ON_SITE) + " ";
    if(user?.allowInstant)value += t(ETranslation.INFO_TEXT_INSTANT);
    setInfoText(value)
  }, [t, user])


  useEffect(() => {
    const interpretation = getInputData<IInterpretation>(inputs);
    const isValid = validateInputs(inputs);
    onChange(interpretation, isValid);
  }, [inputs, onChange]);


  const createInput = useCreateInput(inputs, setInputs, {
    showValidation,
    disabled: loading
  });

  return (
    <>
    {!user?.isYoupretAdmin && <Alert color={EAlertColor.INFO}>{infoText}</Alert>}
    <InputGroupOrder className={classes.PaddingTop1}>
      {createInput(EInputs.type)}
    </InputGroupOrder>
    </>
  );
};

export default OrderInterpretationType;
