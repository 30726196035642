import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute, Routes } from '../../classes/Routes';
import Heading from '../../components/Heading/Heading';
import SendGridEmailsComponent from '../../components/SendGridEmails/SendGridEmails';
import Container from '../../components/ui/Container/Container';
import IAppState from '../../interfaces/store/IAppState';
import * as actions from '../../store/actions';

interface IProps extends RouteComponentProps {}

const SendGridEmailsPage: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { sendGridEmails, loading, error } = useSelector(
    (state: IAppState) => ({
      sendGridEmails: state.sendgridemails.sendgridemails,
      loading: state.sendgridemails.sendgridemailLoading,
      error: state.sendgridemails.sendgridemailError,
    })
  );

  useEffect(() => {
    dispatch(actions.findSendGridEmails());
  }, [dispatch]);

  const openHandler = (id?: string) => {
    if (!id) return;
    history.push(Routes.withParams(ERoute.SITE_EDIT, { id }));
  };

  return (
    <Container>
      <Heading>Sähköpostilokit (Sendgrid bounced/blocked)</Heading>
      <SendGridEmailsComponent
        sendGridEmails={sendGridEmails}
        loading={loading}
        error={error}
        onClick={openHandler}
      />
    </Container>
  );
};

export default SendGridEmailsPage;
