import EActionTypes from '../../interfaces/store/EActionTypes';
import ICommonAction from '../../interfaces/store/ICommonAction';
import ICommonState from '../../interfaces/store/ICommonState';
import { ETranslation } from '../../translations/translation-keys';

type ReducerAction = ICommonAction;
type ReducerState = ICommonState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  languages: null,
  languagesLoading: false,
  languagesError: null,

  languagesStatus: null,
  languagesStatusLoading: false,
  languagesStatusError: null
};



const findLanguagesStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, languagesError: null, languagesLoading: true };
};

const findLanguagesSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    languages: action.languages || null,
    languagesError: null,
    languagesLoading: false,
  };
};

const findLanguagesFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, languages: null, languagesLoading: false, languagesError: action.error };
};

const getLanguagesStatusStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, languagesStatusError: null, languagesStatusLoading: true };
};

const getLanguagesStatusSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    languagesStatus: action.languagesStatus || null,
    languagesStatusError: null,
    languagesStatusLoading: false,
  };
};

const getLanguagesStatusFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, languagesStatus: null, languagesStatusLoading: false, languagesStatusError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};

const getLanguagesStatusClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, languagesStatusLoading: false, languagesStatusError: null, languagesStatus: null };
};

const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.COMMON_FIND_LANGUAGES_START:
      return findLanguagesStart(state, action);
    case EActionTypes.COMMON_FIND_LANGUAGES_SUCCESS:
      return findLanguagesSuccess(state, action);
    case EActionTypes.COMMON_FIND_LANGUAGES_FAIL:
      return findLanguagesFail(state, action);
    case EActionTypes.COMMON_GET_LANGUAGES_STATUS_START:
      return getLanguagesStatusStart(state, action);
    case EActionTypes.COMMON_GET_LANGUAGES_STATUS_SUCCESS:
      return getLanguagesStatusSuccess(state, action);
    case EActionTypes.COMMON_GET_LANGUAGES_STATUS_FAIL:
      return getLanguagesStatusFail(state, action);
    case EActionTypes.COMMON_GET_LANGUAGES_STATUS_CLEAR:
      return getLanguagesStatusClear(state, action);
    default:
      return state;
  }
};

export default reducer;
