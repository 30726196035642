import React, { useCallback, useContext } from 'react';

import { ISalaryInfo } from '../../interfaces/ISalaryInfo';
import ModalContext, { EModalSize } from '../ui/Modal/ModalContext';
import SalaryInfoEditModal from './SalaryInfoEditModal';




export const useSalaryInfoEditModal = () => {
  const { setModal, closeModal } = useContext(ModalContext);

  const handler = useCallback(async (salaryInfo?: ISalaryInfo) => {
    return new Promise<ISalaryInfo>((resolve) => {
      setModal({
        isOpen: true,
        size: EModalSize.MEDIUM,
        title: "Palkka",
        content: <SalaryInfoEditModal salaryInfo={salaryInfo} onAccept={(result) => {
          resolve(result);
          closeModal();
        }} />,
      });
    })
  }, [setModal, closeModal]);

  return handler;
};
