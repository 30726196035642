import { ICategory } from '../../interfaces/ICategory';
import EActionTypes from '../../interfaces/store/EActionTypes';
import ICategoryAction from '../../interfaces/store/ICategoryAction';
import ICategoryState from '../../interfaces/store/ICategoryState';
import { ETranslation } from '../../translations/translation-keys';
import { removeOne } from '../../utils/reducer-utils';
import { insertFirst, updateOne } from '../../utils/reducer-utils';

type ReducerAction = ICategoryAction;
type ReducerState = ICategoryState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  category: null,

  categories: null,
  loading: false,
  error: null,
  
  deletingError: null,
  deleting: false,
  deletingOk: false,

  saveOrUpdateOk: false
};

const findCategoryStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const findCategorySuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    categories: action.categories || null,
    error: null,
    loading: false,
  };
};

const findCategoryFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, categories: null, loading: false, error: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getCategoryStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const getCategorySuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    category: action.category || null,
    error: null,
    loading: false,
  };
};

const getCategoryFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, category: null, loading: false, error: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getCategoryClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, category: null, loading: false, error: null};
};

const deleteCategoryStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deletingError: null, deleting: true, deletingOk: false, };
};

const deleteCategorySuccess: ReducerSignature = (state, action): ReducerState => {
  const categories = removeOne<ICategory>(state.categories, action.id);
  return {
    ...state,
    categories,
    deletingError: null,
    deleting: false,
    deletingOk: true,
  };
};

const deleteCategoryFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deleting: false, deletingError: action.error as string, deletingOk: false, };
};

const deleteCategoryClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deletingError: null, deleting: false, deletingOk: false, };
};


const saveCategoryStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const saveCategorySuccess: ReducerSignature = (state, action): ReducerState => {
  const categories = insertFirst(state.categories, action.category)
  return {
    ...state,
    categories,
    error: null,
    loading: false,
    saveOrUpdateOk: true
  };
};

const saveCategoryFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const updateCategoryStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const updateCategorySuccess: ReducerSignature = (state, action): ReducerState => {
  const categories = updateOne(state.categories, action.category);
  return {
    ...state,
    error: null,
    loading: false,
    saveOrUpdateOk: true,
    categories
  };
};

const updateCategoryFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const saveOrUpdateCategoryClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: null, saveOrUpdateOk: false }
}

const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.CATEGORY_FIND_START:
      return findCategoryStart(state, action);
    case EActionTypes.CATEGORY_FIND_SUCCESS:
      return findCategorySuccess(state, action);
    case EActionTypes.CATEGORY_FIND_FAIL:
      return findCategoryFail(state, action);
    case EActionTypes.CATEGORY_GET_START:
      return getCategoryStart(state, action);
    case EActionTypes.CATEGORY_GET_SUCCESS:
      return getCategorySuccess(state, action);
    case EActionTypes.CATEGORY_GET_FAIL:
      return getCategoryFail(state, action);
    case EActionTypes.CATEGORY_GET_CLEAR:
      return getCategoryClear(state, action);
    case EActionTypes.CATEGORY_DELETE_START:
      return deleteCategoryStart(state, action);
    case EActionTypes.CATEGORY_DELETE_SUCCESS:
      return deleteCategorySuccess(state, action);
    case EActionTypes.CATEGORY_DELETE_FAIL:
      return deleteCategoryFail(state, action);
    case EActionTypes.CATEGORY_DELETE_CLEAR:
      return deleteCategoryClear(state, action);
    case EActionTypes.CATEGORY_SAVE_START:
      return saveCategoryStart(state, action);
    case EActionTypes.CATEGORY_SAVE_SUCCESS:
      return saveCategorySuccess(state, action);
    case EActionTypes.CATEGORY_SAVE_FAIL:
      return saveCategoryFail(state, action);
    case EActionTypes.CATEGORY_UPDATE_START:
      return updateCategoryStart(state, action);
    case EActionTypes.CATEGORY_UPDATE_SUCCESS:
      return updateCategorySuccess(state, action);
    case EActionTypes.CATEGORY_UPDATE_FAIL:
      return updateCategoryFail(state, action);
    case EActionTypes.CATEGORY_SAVE_OR_UPDATE_CLEAR:
      return saveOrUpdateCategoryClear(state, action)
    default:
      return state;
  }
};

export default reducer;
