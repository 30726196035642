import { ILanguage } from "../../interfaces/ILanguage";
import EActionTypes from "../../interfaces/store/EActionTypes";
import ILanguageCodeAction from "../../interfaces/store/ILanguageAction";
import ILanguageCodeState from "../../interfaces/store/ILanguageState";
import { ETranslation } from "../../translations/translation-keys";

type ReducerAction = ILanguageCodeAction;
type ReducerState = ILanguageCodeState;
type ReducerSignature = (
	state: ReducerState,
	action: ReducerAction
) => ReducerState;

const initialState: ReducerState = {
	id: null,

	languageCode: null,
	languageCodeLoading: false,
	languageCodeError: null,

	languageCodes: null,
	languageCodesLoading: false,
	languageCodesError: null,

	languageCodeSaveOrUpdateOk: false,
	languageCodeSaveOrUpdateError: null,
	languageCodeSaveOrUpdateLoading: false,

	languageCodeDeleteError: null,
	languageCodeDeleteLoading: false,
	languageCodeDeleteOk: false,

	language: null,
	languageLoading: false,
	languageError: null,

	languages: null,
	languagesLoading: false,
	languagesError: null,

	languageSaveOrUpdateOk: false,
	languageSaveOrUpdateError: null,
	languageSaveOrUpdateLoading: false,

	languageDeleteError: null,
	languageDeleteLoading: false,
	languageDeleteOk: false,

	linkError: null,
	linkLoading: false,
	linkOk: false,

	unlinkError: null,
	unlinkLoading: false,
	unlinkOk: false,

	updateTranslationError: null,
	updateTranslationLoading: false,
	updateTranslationOk: false,

	deleteTranslationError: null,
	deleteTranslationLoading: false,
	deleteTranslationOk: false,
};

const findLanguageCodesStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return { ...state, languageCodesError: null, languageCodesLoading: true };
};

const findLanguageCodesSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		languageCodes: action.languageCodes || null,
		languageCodesError: null,
		languageCodesLoading: false,
	};
};

const findLanguageCodesFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		languageCodes: null,
		languageCodesLoading: false,
		languageCodesError: action.error || ETranslation.REDUCER_SEARCH_ERROR,
	};
};

const getLanguageCodeStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return { ...state, languageCodeError: null, languageCodeLoading: true };
};

const getLanguageCodeSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		languageCode: action.languageCode || null,
		languageCodeError: null,
		languageCodeLoading: false,
	};
};

const getLanguageCodeFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		languageCode: null,
		languageCodeLoading: false,
		languageCodeError: action.error || ETranslation.REDUCER_SEARCH_ERROR,
	};
};

const getLanguageCodeClear: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		languageCode: null,
		languageCodeLoading: false,
		languageCodeError: null,
	};
};

const deleteLanguageCodeStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		languageCodeDeleteError: null,
		languageCodeDeleteLoading: true,
		languageCodeDeleteOk: false,
	};
};

const deleteLanguageCodeSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		languageCodeDeleteError: null,
		languageCodeDeleteLoading: false,
		languageCodeDeleteOk: true,
	};
};

const deleteLanguageCodeFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		languageCodeDeleteLoading: false,
		languageCodeDeleteError: action.error as string,
		languageCodeDeleteOk: false,
	};
};

const deleteLanguageCodeClear: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		languageCodeDeleteError: null,
		languageCodeDeleteLoading: false,
		languageCodeDeleteOk: false,
	};
};

const saveOrUpdateLanguageCodeStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		languageCodeSaveOrUpdateError: null,
		languageCodeSaveOrUpdateLoading: true,
	};
};

const saveOrUpdateLanguageCodeSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		languageCodes: null,
		languageCodeSaveOrUpdateError: null,
		languageCodeSaveOrUpdateLoading: false,
		languageCodeSaveOrUpdateOk: true,
	};
};

const saveOrUpdateLanguageCodeFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		languageCodeSaveOrUpdateLoading: false,
		languageCodeSaveOrUpdateError:
			action.error || ETranslation.REDUCER_SAVE_ERROR,
	};
};

const saveOrUpdateLanguageCodeClear: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		languageCodeSaveOrUpdateLoading: false,
		languageCodeSaveOrUpdateError: null,
		languageCodeSaveOrUpdateOk: false,
	};
};

const findLanguagesStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, languagesError: null, languagesLoading: true };
};

const findLanguagesSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		languages: action.languages || null,
		languagesError: null,
		languagesLoading: false,
	};
};

const findLanguagesFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		languages: null,
		languagesLoading: false,
		languagesError: action.error || ETranslation.REDUCER_SEARCH_ERROR,
	};
};

const getLanguageStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, languageError: null, languageLoading: true };
};

const getLanguageSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		language: action.language || null,
		languageError: null,
		languageLoading: false,
	};
};

const getLanguageFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		language: null,
		languageLoading: false,
		languageError: action.error || ETranslation.REDUCER_SEARCH_ERROR,
	};
};

const deleteLanguageStart: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		languageDeleteError: null,
		languageDeleteLoading: true,
		languageDeleteOk: false,
	};
};

const deleteLanguageSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		languageDeleteError: null,
		languageDeleteLoading: false,
		languageDeleteOk: true,
	};
};

const deleteLanguageFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		languageDeleteLoading: false,
		languageDeleteError: action.error as string,
		languageDeleteOk: false,
	};
};

const deleteLanguageClear: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		languageDeleteError: null,
		languageDeleteLoading: false,
		languageDeleteOk: false,
	};
};

const saveOrUpdateLanguageStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		languageSaveOrUpdateError: null,
		languageSaveOrUpdateLoading: true,
	};
};

const saveOrUpdateLanguageSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		languages: null,
		languageSaveOrUpdateError: null,
		languageSaveOrUpdateLoading: false,
		languageSaveOrUpdateOk: true,
	};
};

const saveOrUpdateLanguageFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		languageSaveOrUpdateLoading: false,
		languageSaveOrUpdateError:
			action.error || ETranslation.REDUCER_SAVE_ERROR,
	};
};

const linkLanguagesStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, linkError: null, linkLoading: true, linkOk: false };
};

const linkLanguagesSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	const languages = state.languages;
	const language = state.language;
	if (action.id && languages && language) {
		const newLang = languages?.find(
			(lang) => lang.languageCode === action.id
		);
		if (newLang) {
			language.toLanguages = language.toLanguages
				? [
						{
							languageCode: newLang.languageCode,
							active: newLang.active,
						} as ILanguage,
						...language.toLanguages,
				  ]
				: [newLang];
		}
	}
	return {
		...state,
		language,
		linkError: null,
		linkLoading: false,
		linkOk: true,
	};
};

const linkLanguagesFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		linkLoading: false,
		linkError: action.error || ETranslation.REDUCER_SEARCH_ERROR,
		linkOk: false,
	};
};

const unlinkLanguagesStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		id: action.id ?? null,
		unlinkError: null,
		unlinkLoading: true,
		unlinkOk: false,
	};
};

const unlinkLanguagesSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	const language = state.language;
	if (action.id && language) {
		language.toLanguages = language.toLanguages.filter(
			(lang) => lang.languageCode !== action.id
		);
	}
	return {
		...state,
		id: null,
		language,
		unlinkError: null,
		unlinkLoading: false,
		unlinkOk: true,
	};
};

const unlinkLanguagesFail: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		id: null,
		unlinkLoading: false,
		unlinkError: action.error || ETranslation.REDUCER_SEARCH_ERROR,
		unlinkOk: false,
	};
};

const updateLanguageTranslationStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		id: action.id ?? null,
		updateTranslationError: null,
		updateTranslationLoading: true,
		updateTranslationOk: false,
	};
};

const updateLanguageTranslationSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	let language = state.language;
	const languageTranslation = action.languageTranslation;
	if (languageTranslation && language) {
		const languageTranslations = language.languageTranslations;
		const index = languageTranslations.findIndex(
			(lang) => lang.id === languageTranslation.id
		);
		const newTranslations = [...languageTranslations];
		if (index !== -1) {
			newTranslations[index] = languageTranslation;
		} else {
			newTranslations.push(languageTranslation);
		}
		language = {
			...language,
			languageTranslations: newTranslations,
		};
	}
	return {
		...state,
		id: null,
		language,
		updateTranslationError: null,
		updateTranslationLoading: false,
		updateTranslationOk: true,
	};
};

const updateLanguageTranslationFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		id: null,
		updateTranslationLoading: false,
		updateTranslationError:
			action.error || ETranslation.REDUCER_SEARCH_ERROR,
		updateTranslationOk: false,
	};
};

const deleteLanguageTranslationStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		id: action.id ?? null,
		deleteTranslationError: null,
		deleteTranslationLoading: true,
		deleteTranslationOk: false,
	};
};

const deleteLanguageTranslationSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	const language = state.language;
	if (action.id && language) {
		language.languageTranslations = language.languageTranslations.filter(
			(lang) => lang.id !== action.id
		);
	}
	return {
		...state,
		id: null,
		language,
		deleteTranslationError: null,
		deleteTranslationLoading: false,
		deleteTranslationOk: true,
	};
};

const deleteLanguageTranslationFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		id: null,
		deleteTranslationLoading: false,
		deleteTranslationError:
			action.error || ETranslation.REDUCER_SEARCH_ERROR,
		deleteTranslationOk: false,
	};
};

const clearLanguageState: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		id: null,

		language: null,
		languageLoading: false,
		languageError: null,

		languages: null,
		languagesLoading: false,
		languagesError: null,

		languageSaveOrUpdateOk: false,
		languageSaveOrUpdateError: null,
		languageSaveOrUpdateLoading: false,

		languageDeleteError: null,
		languageDeleteLoading: false,
		languageDeleteOk: false,

		linkError: null,
		linkLoading: false,
		linkOk: false,

		unlinkError: null,
		unlinkLoading: false,
		unlinkOk: false,

		updateTranslationError: null,
		updateTranslationLoading: false,
		updateTranslationOk: false,

		deleteTranslationError: null,
		deleteTranslationLoading: false,
		deleteTranslationOk: false,
	};
};

const reducer = (
	state: ReducerState = initialState,
	action: ReducerAction
): ReducerState => {
	switch (action.type) {
		case EActionTypes.LANGUAGE_CODE_FIND_START:
			return findLanguageCodesStart(state, action);
		case EActionTypes.LANGUAGE_CODE_FIND_SUCCESS:
			return findLanguageCodesSuccess(state, action);
		case EActionTypes.LANGUAGE_CODE_FIND_FAIL:
			return findLanguageCodesFail(state, action);
		case EActionTypes.LANGUAGE_CODE_GET_START:
			return getLanguageCodeStart(state, action);
		case EActionTypes.LANGUAGE_CODE_GET_SUCCESS:
			return getLanguageCodeSuccess(state, action);
		case EActionTypes.LANGUAGE_CODE_GET_FAIL:
			return getLanguageCodeFail(state, action);
		case EActionTypes.LANGUAGE_CODE_GET_CLEAR:
			return getLanguageCodeClear(state, action);
		case EActionTypes.LANGUAGE_CODE_DELETE_START:
			return deleteLanguageCodeStart(state, action);
		case EActionTypes.LANGUAGE_CODE_DELETE_SUCCESS:
			return deleteLanguageCodeSuccess(state, action);
		case EActionTypes.LANGUAGE_CODE_DELETE_FAIL:
			return deleteLanguageCodeFail(state, action);
		case EActionTypes.LANGUAGE_CODE_DELETE_CLEAR:
			return deleteLanguageCodeClear(state, action);
		case EActionTypes.LANGUAGE_CODE_SAVE_OR_UPDATE_START:
			return saveOrUpdateLanguageCodeStart(state, action);
		case EActionTypes.LANGUAGE_CODE_SAVE_OR_UPDATE_SUCCESS:
			return saveOrUpdateLanguageCodeSuccess(state, action);
		case EActionTypes.LANGUAGE_CODE_SAVE_OR_UPDATE_FAIL:
			return saveOrUpdateLanguageCodeFail(state, action);
		case EActionTypes.LANGUAGE_CODE_SAVE_OR_UPDATE_CLEAR:
			return saveOrUpdateLanguageCodeClear(state, action);
		case EActionTypes.LANGUAGE_FIND_START:
			return findLanguagesStart(state, action);
		case EActionTypes.LANGUAGE_FIND_SUCCESS:
			return findLanguagesSuccess(state, action);
		case EActionTypes.LANGUAGE_FIND_FAIL:
			return findLanguagesFail(state, action);
		case EActionTypes.LANGUAGE_GET_START:
			return getLanguageStart(state, action);
		case EActionTypes.LANGUAGE_GET_SUCCESS:
			return getLanguageSuccess(state, action);
		case EActionTypes.LANGUAGE_GET_FAIL:
			return getLanguageFail(state, action);
		case EActionTypes.LANGUAGE_DELETE_START:
			return deleteLanguageStart(state, action);
		case EActionTypes.LANGUAGE_DELETE_SUCCESS:
			return deleteLanguageSuccess(state, action);
		case EActionTypes.LANGUAGE_DELETE_FAIL:
			return deleteLanguageFail(state, action);
		case EActionTypes.LANGUAGE_DELETE_CLEAR:
			return deleteLanguageClear(state, action);
		case EActionTypes.LANGUAGE_SAVE_OR_UPDATE_START:
			return saveOrUpdateLanguageStart(state, action);
		case EActionTypes.LANGUAGE_SAVE_OR_UPDATE_SUCCESS:
			return saveOrUpdateLanguageSuccess(state, action);
		case EActionTypes.LANGUAGE_SAVE_OR_UPDATE_FAIL:
			return saveOrUpdateLanguageFail(state, action);
		case EActionTypes.LANGUAGE_LINK_START:
			return linkLanguagesStart(state, action);
		case EActionTypes.LANGUAGE_LINK_SUCCESS:
			return linkLanguagesSuccess(state, action);
		case EActionTypes.LANGUAGE_LINK_FAIL:
			return linkLanguagesFail(state, action);
		case EActionTypes.LANGUAGE_UNLINK_START:
			return unlinkLanguagesStart(state, action);
		case EActionTypes.LANGUAGE_UNLINK_SUCCESS:
			return unlinkLanguagesSuccess(state, action);
		case EActionTypes.LANGUAGE_UNLINK_FAIL:
			return unlinkLanguagesFail(state, action);
		case EActionTypes.LANGUAGE_UPDATE_TRANSLATION_START:
			return updateLanguageTranslationStart(state, action);
		case EActionTypes.LANGUAGE_UPDATE_TRANSLATION_SUCCESS:
			return updateLanguageTranslationSuccess(state, action);
		case EActionTypes.LANGUAGE_UPDATE_TRANSLATION_FAIL:
			return updateLanguageTranslationFail(state, action);
		case EActionTypes.LANGUAGE_DELETE_TRANSLATION_START:
			return deleteLanguageTranslationStart(state, action);
		case EActionTypes.LANGUAGE_DELETE_TRANSLATION_SUCCESS:
			return deleteLanguageTranslationSuccess(state, action);
		case EActionTypes.LANGUAGE_DELETE_TRANSLATION_FAIL:
			return deleteLanguageTranslationFail(state, action);
		case EActionTypes.LANGUAGE_CLEAR:
			return clearLanguageState(state, action);
		default:
			return state;
	}
};

export default reducer;
