import React, { useEffect, useState } from 'react';
import Alert, { EAlertColor } from '../Alert/Alert';
import Spinner from '../Spinner/Spinner';


interface IProps {
	error: string | null;
	loading: boolean;
	done: boolean;
}

const ConditionalInfoBox: React.FC<IProps> = ({ error, loading, done }) => {

	const [finalHide, setFinalHide] = useState(false);

	useEffect(() => {
		if (done) {
			setTimeout(() => {
				setFinalHide(true);
			}, 4000)
		}
		return (() => {
			setFinalHide(false);
		})
	}, [done])
	return !finalHide ?
		<>
			{
				(error || loading || done) &&
				<Alert color={loading ? EAlertColor.INFO : error ? EAlertColor.WARNING : done ? EAlertColor.SUCCESS : undefined}
					children={loading ? <Spinner /> : error ? <p>{error}</p> : done ? "Done" : "Something went wrong"} />
			}
		</>
		:
		<></>
};

export default ConditionalInfoBox;
