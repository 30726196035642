import EActionTypes from "../../interfaces/store/EActionTypes";
import IWorkingHoursState from "../../interfaces/store/IWorkingHoursState";
import IWorkingHoursAction from "../../interfaces/store/IWorkingHoursAction";
import { ETranslation } from "../../translations/translation-keys";
import { insertFirst, removeOne, updateOne } from "../../utils/reducer-utils";
import { IWorkingHoursDate } from "../../interfaces/IWorkingHoursDate";

type ReducerState = IWorkingHoursState;
type ReducerSignature = (state: ReducerState, action: IWorkingHoursAction) => ReducerState;

const initialState: ReducerState = {
	workingHours: null,
	workingHoursDate: null,
	workingHourEvents: null,
	workingHourEventsSummary: null,
	summaries: null,
	loading: false,
	error: null,
	deletingError: null,
	deleting: false,
	deletingOk: false,
	saveOrUpdateOk: false,
};

const listWorkingHoursStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: true };
};

const listWorkingHoursSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		workingHours: action.workingHours ?? null,
		error: null,
		loading: false,
	};
};

const listWorkingHoursFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, workingHours: null, loading: false, error: action.error ?? null };
};

const listWorkingHoursClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, workingHours: null, loading: false, error: action.error ?? null };
};

const listWorkingHourEventsStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: true, workingHourEventsSummary: null };
};

const listWorkingHourEventsSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		workingHourEvents: action.workingHourEvents ?? null,
		workingHourEventsSummary: action.workingHourEventsSummary ?? null,
		error: null,
		loading: false,
	};
};

const listWorkingHourEventsFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, workingHourEvents: null, loading: false, error: action.error ?? null };
};

const listWorkingHourEventsClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, workingHourEvents: null, loading: false, error: action.error ?? null, workingHourEventsSummary: null };
};

const listSummariesStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: true, summaries: null };
};

const listSummariesSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		summaries: action.summaries ?? null,
		error: null,
		loading: false,
	};
};

const listSummariesFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, summaries: null, loading: false, error: action.error ?? null };
};

const listSummariesClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, summaries: null, loading: false, error: action.error ?? null };
};

const getWorkingHoursDateStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: true };
};

const getWorkingHoursDateSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		workingHoursDate: action.workingHoursDate ?? null,
		error: null,
		loading: false,
	};
};

const getWorkingHoursDateFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, workingHoursDate: null, loading: false, error: action.error ?? null };
};

const getWorkingHoursDateClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, workingHoursDate: null, loading: false, error: action.error ?? null };
};

const deleteWorkingHoursDateStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, deletingError: null, deleting: true, deletingOk: false };
};

const deleteWorkingHoursDateSuccess: ReducerSignature = (state, action): ReducerState => {
	const workingHours = removeOne<IWorkingHoursDate>(state.workingHours, action.id);
	return {
		...state,
		workingHours,
		deletingError: null,
		deleting: false,
		deletingOk: true,
	};
};

const deleteWorkingHoursDateFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, deleting: false, deletingError: action.error as string, deletingOk: false };
};

const deleteWorkingHoursDateClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, deletingError: null, deleting: false, deletingOk: false };
};

const saveWorkingHoursDateStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: true };
};

const saveWorkingHoursDateSuccess: ReducerSignature = (state, action): ReducerState => {
	const workingHours = insertFirst(state.workingHours, action.workingHoursDate);
	return {
		...state,
		workingHours,
		error: null,
		loading: false,
		saveOrUpdateOk: true,
	};
};

const saveWorkingHoursDateFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, loading: false, error: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const updateWorkingHoursDateStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: true };
};

const updateWorkingHoursDateSuccess: ReducerSignature = (state, action): ReducerState => {
	const workingHours = updateOne(state.workingHours, action.workingHoursDate);
	return {
		...state,
		error: null,
		loading: false,
		saveOrUpdateOk: true,
		workingHours,
	};
};

const updateWorkingHoursDateFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, loading: false, error: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const saveOrUpdateWorkingHoursDateClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, loading: false, error: null, saveOrUpdateOk: false };
};

const reducer = (state: ReducerState = initialState, action: IWorkingHoursAction): ReducerState => {
	switch (action.type) {
		case EActionTypes.WORKING_HOURS_LIST_START:
			return listWorkingHoursStart(state, action);
		case EActionTypes.WORKING_HOURS_LIST_SUCCESS:
			return listWorkingHoursSuccess(state, action);
		case EActionTypes.WORKING_HOURS_LIST_FAIL:
			return listWorkingHoursFail(state, action);
		case EActionTypes.WORKING_HOURS_LIST_CLEAR:
			return listWorkingHoursClear(state, action);
		case EActionTypes.WORKING_HOUR_EVENTS_LIST_START:
			return listWorkingHourEventsStart(state, action);
		case EActionTypes.WORKING_HOUR_EVENTS_LIST_SUCCESS:
			return listWorkingHourEventsSuccess(state, action);
		case EActionTypes.WORKING_HOUR_EVENTS_LIST_FAIL:
			return listWorkingHourEventsFail(state, action);
		case EActionTypes.WORKING_HOUR_EVENTS_LIST_CLEAR:
			return listWorkingHourEventsClear(state, action);
		case EActionTypes.WORKING_HOURS_GET_START:
			return getWorkingHoursDateStart(state, action);
		case EActionTypes.WORKING_HOURS_GET_SUCCESS:
			return getWorkingHoursDateSuccess(state, action);
		case EActionTypes.WORKING_HOURS_GET_FAIL:
			return getWorkingHoursDateFail(state, action);
		case EActionTypes.WORKING_HOURS_GET_CLEAR:
			return getWorkingHoursDateClear(state, action);
		case EActionTypes.WORKING_HOURS_DELETE_START:
			return deleteWorkingHoursDateStart(state, action);
		case EActionTypes.WORKING_HOURS_DELETE_SUCCESS:
			return deleteWorkingHoursDateSuccess(state, action);
		case EActionTypes.WORKING_HOURS_DELETE_FAIL:
			return deleteWorkingHoursDateFail(state, action);
		case EActionTypes.WORKING_HOURS_DELETE_CLEAR:
			return deleteWorkingHoursDateClear(state, action);
		case EActionTypes.WORKING_HOURS_SAVE_START:
			return saveWorkingHoursDateStart(state, action);
		case EActionTypes.WORKING_HOURS_SAVE_SUCCESS:
			return saveWorkingHoursDateSuccess(state, action);
		case EActionTypes.WORKING_HOURS_SAVE_FAIL:
			return saveWorkingHoursDateFail(state, action);
		case EActionTypes.WORKING_HOURS_UPDATE_START:
			return updateWorkingHoursDateStart(state, action);
		case EActionTypes.WORKING_HOURS_UPDATE_SUCCESS:
			return updateWorkingHoursDateSuccess(state, action);
		case EActionTypes.WORKING_HOURS_UPDATE_FAIL:
			return updateWorkingHoursDateFail(state, action);
		case EActionTypes.WORKING_HOURS_SAVE_OR_UPDATE_CLEAR:
			return saveOrUpdateWorkingHoursDateClear(state, action);
		case EActionTypes.WORKING_HOURS_MONTHLY_SUMMARY_LIST_START:
			return listSummariesStart(state, action);
		case EActionTypes.WORKING_HOURS_MONTHLY_SUMMARY_LIST_SUCCESS:
			return listSummariesSuccess(state, action);
		case EActionTypes.WORKING_HOURS_MONTHLY_SUMMARY_LIST_FAIL:
			return listSummariesFail(state, action);
		case EActionTypes.WORKING_HOURS_MONTHLY_SUMMARY_LIST_CLEAR:
			return listSummariesClear(state, action);
		default:
			return state;
	}
};

export default reducer;
