import React, { ReactNode, useContext, useState } from 'react';

import { useCreateInput } from '../../hooks/useCreateInput';
import EditButtons from '../ui/EditButtons/EditButtons';
import { ECommonValue, EInputType, IInputField } from '../ui/Input/Input';
import { getInputData } from '../ui/Input/input-utils';
import InputGroup from '../ui/InputGroup/InputGroup';
import ModalBody from '../ui/Modal/ModalBody/ModalBody';
import ModalContext from '../ui/Modal/ModalContext';
import ModalFooter from '../ui/Modal/ModalFooter/ModalFooter';

interface IProps {
	onAccept: (result: INotificationModalResult) => void	
	text: ReactNode;
}

enum EInputs {
	sendEmail = "sendEmail",
	sendPushMessage = "sendPushMessage",
}

export interface INotificationModalResult {
  sendEmail: boolean;
  sendPushMessage: boolean;
}

const NotificationModal: React.FC<IProps> = ({ onAccept, text }) => {
	const { closeModal } = useContext(ModalContext);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.sendEmail]: {
			type: EInputType.checkbox,
			options: [{ value: ECommonValue.YES, label: "Lähetä sähköposti"}],
			value: [ECommonValue.YES],
		},
		[EInputs.sendPushMessage]: {
			type: EInputType.checkbox,
			options: [{ value: ECommonValue.YES, label: "Lähetä push viesti"}],
			value: [ECommonValue.YES],
		},
	});


	const saveHandler = () => {
		const inputData = getInputData<INotificationModalResult>(inputs);
		onAccept(inputData)
	};

	const createInput = useCreateInput(inputs, setInputs, { showLabel: false });

	return (
		<>
			<ModalBody>
				<p style={{ marginTop: 0 }}>{text}</p>
				<InputGroup>
					{createInput(EInputs.sendEmail)}
					{createInput(EInputs.sendPushMessage)}
				</InputGroup>
			</ModalBody>
			<ModalFooter>
				<EditButtons onSave={saveHandler} loading={false} onCancel={closeModal} isAdd={true} />
			</ModalFooter>
		</>
	);
};

export default NotificationModal;
