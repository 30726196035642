import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { IInterpretation } from "../../../../interfaces/IInterpretation";
import {
  EConnectionDetail,
  ECustomerEstimates,
  getCustomerEstimateString,
  getInterpreterGenderString,
} from "../../../../shared/interpretation-data";
import { ETranslation } from "../../../../translations/translation-keys";
import * as dateFns from "date-fns";
import HeadingBox from "../../../Heading/HeadingBox/HeadingBox";
import classes from "../OrderInterpretation.module.scss";
import { getDayName } from "../../../../utils/date-utils";
import { IOrganization } from "../../../../interfaces/IOrganization";
import { useInterpretation } from "../../../../hooks/useInterpretation";
import { useSelector } from "react-redux";
import IAppState from "../../../../interfaces/store/IAppState";
import ICommonState from "../../../../interfaces/store/ICommonState";
import SummaryItem from "./SummaryItem/SummaryItem";

interface IProps {
  interpretations: IInterpretation[] | null;
  organization: IOrganization | null;
  currentIndex: number;
  currentAlternativeIndex: number;
  showAlternativeOnly: boolean;
  setCurrentIndex: (index: number, altIndex: number) => void;
  setAlternativeOnly: (value: boolean) => void;
}

const OrderInterpretationSummary: React.FC<IProps> = ({
  interpretations,
  currentIndex,
  currentAlternativeIndex,
  organization,
  showAlternativeOnly,
  setCurrentIndex,
  setAlternativeOnly,
}) => {
  const { t } = useTranslation();
  const {
    getConnectionDetailString,
    //getInterpretationTypeString
  } = useInterpretation();
  const { languages } = useSelector<IAppState, ICommonState>((state) => state.common);

  const getDate = (startDate?: string, startTime?: string) => {
    if (!startTime || !startDate) return;
    const dateAsString = dateFns.format(
      dateFns.parseISO(startDate + " " + startTime),
      "dd.MM.yyyy HH:mm"
    );
    return (
      dateAsString +
      " " +
      getDayName(dateFns.getDay(dateFns.parseISO(startDate)), t)
    );
  };

  const getDurationString = (durationInMinutes?: string) => {
    if (!durationInMinutes || !organization?.organizationDurations) return;
    const duration = organization?.organizationDurations?.find(
      (i) => i.durationInMinutes.toString() === durationInMinutes
    );
    return duration?.titleFi;
  };

  const getCostCenter = (costCenter?: string) => {
    if (!costCenter || !organization || !organization.costCenters) return;
    const costCenterItem = organization.costCenters.find(
      (j) => j.name === costCenter
    );
    return costCenterItem?.name;
  };

  const summaryItem = (
    val1?: String,
    val2?: String,
    showVal2?: boolean,
    val3?: String,
    showVal3?: boolean,
    val4?: String,
    showVal4?: boolean
  ) => {
    let value = val1;
    if (val2 && showVal2) {
      value += "\n" + val2;
    }
    if (val3 && showVal3) {
      value += "\n" + val3;
    }
    if (val4 && showVal4) {
      value += "\n" + val4;
    }
    if (value) {
      return <div className={classes.SummaryItem}>{value}</div>;
    }
  };

  const fromToLanguageItem = (fromLanguage: String, toLanguage: String) => {
    if (!languages || !toLanguage || !fromLanguage) return;
    const fromLanguageString = languages.find(
      (lang) => lang.id === fromLanguage
    )?.nameFi;
    const toLanguageString = languages.find(
      (lang) => lang.id === toLanguage
    )?.nameFi;
    return (
      <div className={classes.SummaryItem}>
        {fromLanguageString} <FontAwesomeIcon icon={faArrowRight} />{" "}
        {toLanguageString}
      </div>
    );
  };

  const getSummaryItem = (
    interpretation: IInterpretation,
    index: number,
    alternativeIndex: number,
    isAlternative: boolean,
    showAlternativeOnly: boolean
  ) => {
    if (!interpretation.type) return;
    let indexValue = null;
    switch (isAlternative) {
      case true:
        indexValue = alternativeIndex;
        break;
      case false:
        indexValue = index;
        break;
      default:
        indexValue = 0;
    }
    return (
      <React.Fragment key={indexValue}>
        <SummaryItem
          alternativeIndex={alternativeIndex}
          isAlternative={isAlternative}
          interpretationType={interpretation.type}
          currentIndex={currentIndex}
          currentAlternativeIndex={currentAlternativeIndex}
          showAlternativeOnly={showAlternativeOnly}
          index={index}
          dateString={getDate(
            interpretation.startDate,
            interpretation.startTime
          )}
          setAlternativeOnly={(value) => setAlternativeOnly(value)}
          setCurrentIndex={(index, altIndex) =>
            setCurrentIndex(index, altIndex)
          }
        >
          {summaryItem(
            getDate(interpretation.startDate, interpretation.startTime)
          )}
          {fromToLanguageItem(
            interpretation.fromLanguage,
            interpretation.toLanguage
          )}
          {summaryItem(
            interpretation?.connectionType &&
              getConnectionDetailString(t, interpretation?.connectionType),
            interpretation?.phoneNumber && interpretation?.phoneNumber,
            interpretation?.connectionType ===
              EConnectionDetail.INTERPRETER_CALL_TO_CUSTOMER
              ? true
              : false
          )}
          {summaryItem(
            interpretation?.gender &&
              getInterpreterGenderString(t, interpretation?.gender)
          )}
          {summaryItem(
            getCustomerEstimateString(
              t,
              interpretation?.customerEstimate as ECustomerEstimates
            )
          )}
          {summaryItem(interpretation?.interpreterInfoText)}
          {summaryItem(getDurationString(interpretation?.durationInMinutes?.toString()))}
          {summaryItem(
            interpretation?.customerReference,
            interpretation?.customerReference2,
            true,
            getCostCenter(interpretation?.costCenter),
            true
          )}
          {summaryItem(
            interpretation?.address,
            interpretation?.zip,
            true,
            interpretation?.city,
            true,
            interpretation?.info,
            true
          )}
        </SummaryItem>
      </React.Fragment>
    );
  };

  return (
    <>
      <div className={classes.Sticky}>
        <HeadingBox>{t(ETranslation.COMMON_SUMMARY)}</HeadingBox>
        {interpretations &&
          interpretations?.map((interpretation, index) => (
            <React.Fragment key={index}>
            {interpretation.type &&
              <div className={classes.SummaryBox}>
                {getSummaryItem(
                  interpretation,
                  index,
                  -1,
                  false,
                  showAlternativeOnly
                )}
                {interpretation.alternatives != null &&
                  interpretation.alternatives.map(
                    (alternativeInterpretation, alternativeIndex) =>
                      getSummaryItem(
                        alternativeInterpretation,
                        index,
                        alternativeIndex,
                        true,
                        showAlternativeOnly
                      )
                  )}
              </div>
              }
            </React.Fragment>
          ))}
      </div>
    </>

  );
};

export default OrderInterpretationSummary;
