import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../translations/translation-keys';

import Alert from '../ui/Alert/Alert';
import Button, { EButtonColor } from '../ui/Button/Button';
import ModalBody from '../ui/Modal/ModalBody/ModalBody';
import ModalContext from '../ui/Modal/ModalContext';
import ModalFooter from '../ui/Modal/ModalFooter/ModalFooter';

interface IProps {
  deleteText?: string;
  onDelete?: () => void;
  error?: string | null;
}



const DeleteModal: React.FC<IProps> = ({
  deleteText,
  onDelete,
  error,
}) => {
  const { closeModal } = useContext(ModalContext);
  const { t } = useTranslation();
  return (
    <>
      <ModalBody>
        {error && <Alert>{error}</Alert>}
        {deleteText ? t(deleteText) : t(ETranslation.DELETEMODAL_CONFIRMATION)}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => onDelete && onDelete()}
          color={EButtonColor.SUCCESS}

        > {t(ETranslation.COMMON_DELETE)}
        </Button>
        <Button onClick={closeModal} color={EButtonColor.DANGER}>
        {t(ETranslation.DELETEMODAL_CANCEL)}
        </Button>
      </ModalFooter>
    </>
  );
};

export default DeleteModal;
