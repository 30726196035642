import { faMicrophone, faMicrophoneSlash, faPhoneSlash, faVideo, faVideoSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {  } from "react";


import { useRoom } from "../../../hooks/useRoom";
import Button, { EButtonColor } from "../../ui/Button/Button";
import TwilioVideoParticipant from "../TwilioVideoParticipant/TwilioVideoParticipant";

import classes from './TwilioVideoRoom.module.scss';

interface IProps {
  accessToken: string;
  name: string;
}

const TwilioVideoRoom: React.FC<IProps> = ({ accessToken, name }) => {
  // const [room, setRoom] = useState(null);

  const {
    participants,
    localParticipant,
    enableVideo,
    disableVideo,
    enableMicrophone,
    disableMicrophone,
    endVideoCall,
    isVideo,
    isMicrophone,
  } = useRoom(accessToken, name);

  const remoteParticipants = participants.map((participant) => (
    <TwilioVideoParticipant key={participant.sid} participant={participant} />
  ));

  return (
    <div className={classes.Room}>
      <div className={classes.Participants}>{remoteParticipants}</div>
      <div className={classes.LocalParticipant}>
        {localParticipant && (
          <TwilioVideoParticipant participant={localParticipant} />
        )}
      </div>
      <div className={classes.Buttons}>

        <Button onClick={endVideoCall} color={EButtonColor.DANGER}>
          <FontAwesomeIcon icon={faPhoneSlash} />
        </Button>

        {isVideo ? (
          <Button onClick={disableVideo} color={EButtonColor.PRIMARY}>
            <FontAwesomeIcon icon={faVideo} />
          </Button>
        ) : (
          <Button onClick={enableVideo} color={EButtonColor.DANGER}>
            <FontAwesomeIcon icon={faVideoSlash} />
          </Button>
        )}

        {isMicrophone ? (
          <Button onClick={disableMicrophone} color={EButtonColor.PRIMARY}>
            <FontAwesomeIcon icon={faMicrophone} />
          </Button>
        ) : (
          <Button onClick={enableMicrophone} color={EButtonColor.DANGER}>
            <FontAwesomeIcon icon={faMicrophoneSlash} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default TwilioVideoRoom;
