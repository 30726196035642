import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { ESitePrefix } from '../../../interfaces/ISite';
import IAppState from '../../../interfaces/store/IAppState';
import IAuthState from '../../../interfaces/store/IAuthState';

interface IProps {
	children: ReactNode;
	sites?: ESitePrefix[];
}
/**
 * Check currentUser site and if matches sites param then shows content. If sites is not given defaults to youpret
 * @param sites
 * @returns
 */
const SiteOnlyContent: React.FC<IProps> = ({ children, sites = [ESitePrefix.youpret] }) => {
	const { user } = useSelector<IAppState, IAuthState>(state => state.auth)

	const prefix = user?.site?.prefix ?? ESitePrefix.youpret;

	if (sites.includes(prefix)) {
		return <>{children}</>;
	}
	return null;
}

export default SiteOnlyContent;