import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import IAppState from "../../interfaces/store/IAppState";
import * as actions from "../../store/actions";
import Spinner from "../../components/ui/Spinner/Spinner";
import TwilioVideoRoom from "../../components/TwilioVideo/TwilioVideoRoom/TwilioVideoRoom";

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const VideoPage: React.FC<IProps> = ({ match, history }) => {

  const { interpretation } = useSelector(
    (state: IAppState) => ({
      interpretation: state.interpretation.interpretation
    })
  );

  const dispatch = useDispatch();

  const { id } = match.params;

  useEffect(() => {
    if(id) {
      dispatch(actions.getInterpretation(id));
    }
  }, [dispatch, history, id]);

  if (!interpretation) {
    return <Spinner />
  }

  const { roomAccessToken, roomUniqueName } = interpretation;

  return (
    <>
      {roomUniqueName && (
        <TwilioVideoRoom
          accessToken={roomAccessToken}
          name={roomUniqueName}
        />
      )}
    </>
  );
};

export default VideoPage;
