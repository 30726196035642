import React, { useState } from "react";
import { IReservedDate } from "../../../interfaces/IReservedDate";
import Alert from "../../ui/Alert/Alert";
import Spinner from "../../ui/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import Input, { EInputType, TInputValue } from "../../ui/Input/Input";
import { ETranslation } from "../../../translations/translation-keys";
import Table from "../../ui/Table/Table";

import { useSelector } from "react-redux";
import IAppState from "../../../interfaces/store/IAppState";
import IReservedDateState from "../../../interfaces/store/IReservedDateState";
import ReservedDateListItem from "./ReservedDateListItem/ReservedDateListItem";

interface IProps {
	onDelete: (id: string) => void;
}

const ReservedDateList: React.FC<IProps> = ({ onDelete }) => {
	const {
		reservedDates,
		reservedDatesError,
		reservedDatesLoading,
		deleteError,
	} = useSelector<IAppState, IReservedDateState>(
		(state) => state.reservedDate
	);

	const [filter, setFilter] = useState("");

	const { t } = useTranslation();

	if (reservedDatesError) {
		return <Alert>{reservedDatesError}</Alert>;
	}

	if (!reservedDates || reservedDatesLoading) {
		return <Spinner />;
	}

	if (reservedDates.length === 0) {
		return <p>{t(ETranslation.RESERVED_DATE_NO_RESULTS)}</p>;
	}

	const filterReservedDates = (item: IReservedDate): boolean => {
		return (
			filter.length === 0 ||
			item.userName?.toLowerCase().indexOf(filter.toLowerCase()) !== -1
		);
	};

	return (
		<>
			{deleteError && <Alert>{deleteError}</Alert>}
			<Input
				containerStyles={{ margin: "1rem 0" }}
				type={EInputType.text}
				inputName="filter"
				value={filter}
				onChange={(value: TInputValue) => setFilter(value as string)}
				placeholderTranslation={ETranslation.COMMON_FILTER_BY_NAME}
			/>
			<Table>
				<thead>
					<tr>
						<th>{t(ETranslation.RESERVED_DATE_TYPE)}</th>
						<th>{t(ETranslation.RESERVED_DATE_USER_NAME)}</th>
						<th>{t(ETranslation.RESERVED_DATE_DATE)}</th>
						<th>{t(ETranslation.RESERVED_DATE_OPTIONS)}</th>
						<th>{t(ETranslation.RESERVED_DATE_DESCRIPTION)}</th>
						<th style={{ width: "1px" }}></th>
					</tr>
				</thead>
				<tbody>
					{reservedDates
						.filter(filterReservedDates)
						.map((reservedDate) => (
							<ReservedDateListItem
								key={reservedDate.id}
								reservedDate={reservedDate}
								onDelete={onDelete}
							/>
						))}
				</tbody>
			</Table>
		</>
	);
};

export default ReservedDateList;
