import React from "react";
import { ISummary } from "../../../store/actions/summariesActions";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";
import Star from "../../ui/Star/Star";
import classes from "./Summary.module.scss";


interface IProps {
	summary: ISummary;
}

const starOptions = [{ value: "1" }, { value: "2" }, { value: "3" }, { value: "4" }, { value: "5" }];

const SummaryFooter: React.FC<IProps> = ({ summary }) => {
	const { t } = useTranslation();
	return (<>
		<h4>
			{t(ETranslation.SUMMARY_GRADING_AVERAGE)} {summary.startDate}-
			{summary.endDate}
		</h4>
		<p>{t(ETranslation.SUMMARY_GRADING_POINTS)}</p>
		<div className={classes.reviewSection}>
			<span><Star disabled={true} value={Math.round(summary.totals.ratingAverage).toString()} onChange={() => { }} options={starOptions} /></span>
		</div>
		<p>{summary.message}</p>
		<p>
			{t(ETranslation.SUMMARY_QUESTIONS)}
			<a href="mailto:office@youpret.com">
				{t(ETranslation.SUMMARY_EMAIL)}
			</a>
		</p>
	</>);
};

export default SummaryFooter;
