import { ETranslation } from './translation-keys';

const uiTranslations = {
	[ETranslation.UI_MAKE_ORDER]: 'Order worker',
};

const menuTranslations = {
	[ETranslation.MENU_ORDER_INTERPRETER]: "Order worker",
	[ETranslation.MENU_INTERPRETATIONS_LIST]: "Commissions list",
	[ETranslation.MENU_INTERPRETATIONS]: "Commissions",
	[ETranslation.MENU_USERS_INTERPRETERS]: "Workers",
	[ETranslation.MENU_ORDER_INTERPRETER_NEW]: "Order worker (new)",
	[ETranslation.MENU_COMISSIONS]: "Interested workers",
};

const usersTranslations = {
	[ETranslation.USER_INTERPRETER]: "Worker information",
	[ETranslation.USER_TRAINED_INTERPRETER]: "Trained worker",
	[ETranslation.USER_ADD_FAVOURITE_INTERPRETER]: "Add favorite worker",
	[ETranslation.USER_ALLOW_FAVORITE_INTERPRETER]: "Allow favorite workers",
	[ETranslation.USER_SHOW_ALL_COMPANY_INTERPRETATIONS]: "Show all company commissions",
	[ETranslation.USER_SHOW_ALL_GROUP_INTERPRETATIONS]: "Show all group commissions",
	[ETranslation.USER_STATUS_OF_INTERPRETER]: "Worker status",
	[ETranslation.USER_SHOW_ORGANIZATION_INTERPRETATIONS]: "Show organization commissions",
	[ETranslation.USER_ACCORDION_FAVOURITES]: "Favorite workers",
	[ETranslation.USER_ACCORDION_BLOCKED]: "Blocked workers",
	[ETranslation.USER_ALLOWED_TYPES]: "Allowed joboffer types",
};

const commonTranslations = {
	[ETranslation.COMMON_INTERPRETER]: "Worker",
	[ETranslation.COMMON_INTERPRETATION_DONE]: "Commission completed",
	[ETranslation.COMMON_INTERPRETATIONS]: "Commissions",
	[ETranslation.COMMON_INTERPRETATION_INFORMATION]: "Commission information",
	[ETranslation.COMMON_INFO_TO_INTERPRETER]: "Additional information to worker",
	[ETranslation.COMMON_REVIEW_FROM_INTERPRETER]: "Worker's review of commission",
	[ETranslation.COMMON_REVIEW_FROM_CUSTOMER]: "Customer's review of commission",
};

const Base64DropzoneTranslations = {};

const customDropZoneTranslations = {};

const deleteModalTranslations = {};

const infoContactRequestTranslations = {
	[ETranslation.CONTACT_REQUEST_NEED_INTERPRETER]: "I need a worker",
	[ETranslation.CONTACT_REQUEST_TO_LANGUAGE]: "The job to be done",
};

const messageTranslations = {
	[ETranslation.MESSAGE_INFORMATION]: "Commission information",
};


const newsItemTranslations = {
	[ETranslation.NEWS_INTERPRETER_REVIEW]: "Worker review",
	[ETranslation.NEWS_NO_REVIEW]: "No review for this commission",
};
const organizationGroupTranslations = {};

const priceSeasonsTranslations = {};

const siteTranslations = {};

const textMessageTranslations = {};

const pagesFileUploadTranslations = {};

const pagesContactRequestTranslations = {};

const pagesMessagesTranslations = {};

const pagesNewsTranslations = {};

const pagesOrganizationsTranslations = {};


const organizationCompaniesTranslations = {
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_INTERPRETER_CALL_TO_CUSTOMER]: "Worker is calling",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_CUSTOMER_CALL_TO_INTERPRETER]: "I will call the worker",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_CONTACT_CALL_TO_INTERPRETER]: "Contact number makes the call to the worker",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_INTERPRETER_CALL_TO_CONTACT]: "Worker calls the contact number I provided",
	[ETranslation.ORGANIZATION_COMPANY_INTERPRETER_FEE_SETTINGS]: "Worker customer fees settings",
	[ETranslation.ORGANIZATION_DURATIONS]: "Company commission durations",
	[ETranslation.ORGANIZATION_COMPANY_DURATIONS]: "Company commission duration",
	[ETranslation.ORGANIZATION_DEFAULT_DURATIONS]: "Default commission duration",
	[ETranslation.ORGANIZATION_COMPANY_DEFAULT_INTERPRETATION_TYPE]: "Default commission type",
	[ETranslation.ORGANIZATION_COMPANY_ONLY_SECURITY_CLEARED_INTERPRETERS]: "Only security cleared workers",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_CONNECTION_TYPE_SELECT_FOR_INSTANT]: "Allow contact method selection for instant commissions",
	[ETranslation.ORGANIZATION_COMPANY_MIN_BILLING_PERIOD_IN_MINUTES_FOR_INSTANT]: "Instant commission: minimum billing period in minutes",
	[ETranslation.ORGANIZATION_COMPANY_PRICE_WITHOUT_VAT_PER_MINUTE_FOR_INSTANT_EXTENDED]: "Instant commission over 30 mins: price without VAT per min",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_NOTES_TITLE]: "Customer's commission subject",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_NOTES_INFO_TEXT]: "Customer's commission instructions",
};

const pagesPriceSeasonsTranslations = {};

const pagesPriceRulesTranslations = {};

const pagesSiteTranslations = {};

const pagesTextMessagesTranslations = {};

const pagesCustomerReportsTranslations = {};

const dataContactRequestTranslations = {};

const dataPriceSeasonsTranslations = {
	[ETranslation.DATA_PRICE_SEASON_PRICE_PER_INTERPRETATION]: "Commission price",
	[ETranslation.DATA_PRICE_SEASON_PRICE_P_PER_INTERPRETATION]: "Commission percentage",
};

const storeTranslations = {};

const appTranslations = {};

const logItemTranslations = {
	[ETranslation.LOG_ITEM_LIST_INTERPRETATION]: "Commission",
};

const interpretationTranslations = {
	[ETranslation.INTERPRETATION_AMOUNT_OF_INTERPRETATIONS]: "Commissions:",
	[ETranslation.INTERPRETATION_CUSTOMER_ESTIMATE]: "Estimate how long you need the worker?",
	[ETranslation.INTERPRETATION_GENDER]: "Worker's gender (Not mandatory)",
	[ETranslation.INTERPRETATION_CUSTOMER_NOTES]: "Commission subject",
	[ETranslation.INTERPRETATION_AVAILABLE_INTERPRETERS]: "Available workers",
	[ETranslation.INTERPRETATION_ORDER_MULTIPLE]: "Order commissions",
	[ETranslation.INTERPRETATION_INTERPRETER_REVIEW]: "Worker's review",
	[ETranslation.INTERPRETATION_INTERPRETER_RATING]: "Worker's review rating",
	[ETranslation.INTERPRETATION_INTERPRETER_NAME]: "Worker's name",
	[ETranslation.INTERPRETATION_ADD_INTERPRETATION]: "Add commission",
	[ETranslation.INTERPRETATION_ADD_INTERPRETER]: "Add worker",
	[ETranslation.INTERPRETATION_SEND_AUTOMATIC_OFFERS]: "Send automatic job offers",
};

const interpretationInfoBoxTranslation = {
	[ETranslation.INTERPRETATION_INFO_INTERPRETER_INVOICE]: "Worker customer fee € / min. excluding VAT",
	[ETranslation.INTERPRETATION_INTERPRETER_PAYMENT]: "Worker payment",
	[ETranslation.INTERPRETATION_INTERPRETERS_PAYMENT_TYPE]: "Worker payment method",
	[ETranslation.INTERPRETATION_CANDIDATES]: "Offered workers",
}

const interpretationDataTranslations = {
	[ETranslation.INTERPRETATION_DATA_CONNECTION_DETAIL_TO_CUSTOMER]: "Worker is calling.",
	[ETranslation.INTERPRETATION_DATA_STATUS_INTERPRETERS_NOT_FOUND]: "No worker found",
	[ETranslation.INTERPRETATION_DATA_STATUS_FAVORITES_NOT_FOUND]: "No favorite found",
	[ETranslation.INTERPRETATION_DATA_STATUS_INTERPRETER_CANCEL]: "Cancelled(worker)",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_INTERPRETER]: "Waiting for worker review",
	[ETranslation.INTERPRETATION_DATA_TYPE_INSTANT]: 'Instant commission',
	[ETranslation.INTERPRETATION_DATA_STATUS_OFFER_BOOKING_SELECT]: "Commission from booking (workers can be selected after saving)",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_REVIEWS_SELECT]: "Waiting for reviews (Option for worker and customer to review later)",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_INTERPRETER_REVIEW]: "Waiting for worker review",
};

const interpretationProcessingTranslations = {
	[ETranslation.INTERPRETATION_PROCESSING_INTERPRETER_WILL_CALL]: "Be ready. Worker will call you at",
	[ETranslation.INTERPRETATION_PROCESSING_CALL_INTERPRETER]: "Please, you can call the worker. Worker is waiting for your call. Call at",
	[ETranslation.INTERPRETATION_PROCESSING_INTERPRETERS_NOT_FOUND]: "No workers found",
	[ETranslation.INTERPRETATION_PROCESSING_FAVORITES_NOT_FOUND]: "No favorites found",
	[ETranslation.INTERPRETATION_PROCESSING_OPEN_INTERPRETATION]: "Open commission and close order",
};

const interpretationReviewTranslations = {
	[ETranslation.INTERPRETATION_RATING]: "Rate commission",
	[ETranslation.INTERPRETATION_REVIEW_ADD_FAVORITE_INTERPRETER]: "Add favorite worker",
	[ETranslation.INTERPRETATION_REVIEW_INTERPRETER_IS_FAVORITE]: "Worker is in favorites",
	[ETranslation.INTERPRETATION_REVIEW_TEXT]: "You can give a short review of the commission",
	[ETranslation.INTERPRETATION_REVIEW_INTERPRETER]: "Worker who worked for you is  ",
};

const interpretationsTranslations = {
	[ETranslation.INTERPRETATIONS_NOT_FOUND]: "No commissions found",
	[ETranslation.INTERPRETATIONS_CLEAR_ALL]: "Clear commissions",
};

const videoTranslations = {};

const infoTextTranslations = {
	[ETranslation.INFO_TEXT_BOOKING]: "Make a booking for a worker by filling in the details and selecting Create Booking.",
	[ETranslation.INFO_TEXT_ON_SITE]: "Select On-site tab if you want the worker to come to the location.",
	[ETranslation.INFO_TEXT_INSTANT]: "Select the Instant commissions tab from the top if you need immediate connection with the worker.",
};

const modalTranslations = {
	[ETranslation.MODAL_CONFIRMATION_INTERRUPT]:"Are you sure you want to interrupt the commissions?",
	[ETranslation.MODAL_ARE_YOU_SURE_CLEAR_INTERPRETATIONS]:"Are you sure you want to clear the commissions?",
	[ETranslation.MODAL_AMOUNT_OF_TRANSLATIONS]: " commissions?",
	[ETranslation.MODAL_AMOUNT_ONE_TRANSLATION]: " commissions?",
};

const calendarTranslations = {
	[ETranslation.CALENDAR_MODAL_TITLE]: "Available and reserved workers",
	[ETranslation.CALENDAR_NO_USERS_MESSAGE]:"No workers found with the filters",
};

const cancelationFormTranslations = {};

const commentTranslations = {};

const commissionTranslations = {
	[ETranslation.COMMISSION_GENERAL]: "Interested worker",
	[ETranslation.COMMISSIONS_EVERYDAY]: "Everyday worker",
	[ETranslation.COMMISSIONS_PROFESSIONAL]: "Professional worker",
	[ETranslation.COMMISSIONS_ADDED_AS_INTERPRETER]: "Added as a worker",
};

const summaryTranslations = {
	[ETranslation.SUMMARY_GRADING_POINTS]: "Average rating given by customers for your commissions on a scale of 1-5 was:",
};

const tagTranslations = {};

const reservedDateTranslations = {};

const orderTranslations = {};

const tasksTranslations = {};

const feesTranslations = {}

const johannarekryFi = {
	...commonTranslations,
	...menuTranslations,
	...Base64DropzoneTranslations,
	...customDropZoneTranslations,
	...deleteModalTranslations,
	...infoContactRequestTranslations,
	...messageTranslations,
	...newsItemTranslations,
	...organizationGroupTranslations,
	...priceSeasonsTranslations,
	...siteTranslations,
	...textMessageTranslations,
	...uiTranslations,
	...pagesFileUploadTranslations,
	...pagesContactRequestTranslations,
	...pagesMessagesTranslations,
	...pagesNewsTranslations,
	...pagesOrganizationsTranslations,
	...organizationCompaniesTranslations,
	...pagesPriceSeasonsTranslations,
	...pagesPriceRulesTranslations,
	...pagesSiteTranslations,
	...pagesTextMessagesTranslations,
	...dataContactRequestTranslations,
	...dataPriceSeasonsTranslations,
	...pagesCustomerReportsTranslations,
	...storeTranslations,
	...appTranslations,
	...logItemTranslations,
	...interpretationTranslations,
	...interpretationInfoBoxTranslation,
	...interpretationDataTranslations,
	...interpretationProcessingTranslations,
	...videoTranslations,
	...interpretationReviewTranslations,
	...interpretationsTranslations,
	...infoTextTranslations,
	...modalTranslations,
	...calendarTranslations,
	...cancelationFormTranslations,
	...usersTranslations,
	...commentTranslations,
	...commissionTranslations,
	...summaryTranslations,
	...tagTranslations,
	...reservedDateTranslations,
	...orderTranslations,
	...tasksTranslations,
	...feesTranslations,
};

export default johannarekryFi;
