import { Dispatch } from 'redux';

import { customFetch, EFetchMethod } from '../../custom-fetch';
import { IUserContractSettings } from '../../interfaces/IUserContractSettings';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IUserContractAction from '../../interfaces/store/IUserContractAction';

type TAction = IUserContractAction;

const findUserContractSettingsStart = (): TAction => {
  return {
    type: EActionTypes.USER_CONTRACT_SETTING_FIND_SUCCESS,
  };
};

const findUserContractSettingsSuccess = (settings: IUserContractSettings[]): TAction => {
  return {
    type: EActionTypes.USER_CONTRACT_SETTING_FIND_SUCCESS,
    settings,
  };
};

const findUserContractSettingsFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_CONTRACT_SETTING_FIND_FAIL,
    error,
  };
};

export const findUserContractSettings = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(findUserContractSettingsStart());
      const settings = await customFetch<IUserContractSettings[]>("/usercontracts/settings/list");
      dispatch(findUserContractSettingsSuccess(settings));
    } catch (error) {
      dispatch(findUserContractSettingsFail((error as Error).message));
    }
  };
};

const getUserContractSettingsStart = (): TAction => {
  return {
    type: EActionTypes.USER_CONTRACT_SETTING_GET_START,
  };
};

const getUserContractSettingsSuccess = (setting: IUserContractSettings): TAction => {
  return {
    type: EActionTypes.USER_CONTRACT_SETTING_GET_SUCCESS,
    setting,
  };
};

const getUserContractSettingsFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_CONTRACT_SETTING_GET_FAIL,
    error,
  };
};

export const getUserContractSettingsClear = (): TAction => {
  return {
    type: EActionTypes.USER_CONTRACT_SETTING_GET_CLEAR,
  };
};

export const getUserContractSettings = (id: string, copy: boolean = false) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getUserContractSettingsStart());
      const priceSeason = await customFetch<IUserContractSettings>(`/usercontracts/settings/get?id=${id}&copy=${copy}`);
      dispatch(getUserContractSettingsSuccess(priceSeason));
    } catch (error) {
      dispatch(getUserContractSettingsFail((error as Error).message));
    }
  };
};

const deleteUserContractSettingsStart = (): TAction => {
  return {
    type: EActionTypes.USER_CONTRACT_SETTING_DELETE_START,
  };
};

const deleteUserContractSettingsSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.USER_CONTRACT_SETTING_DELETE_SUCCESS,
    id,
  };
};

const deleteUserContractSettingsFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_CONTRACT_SETTING_DELETE_FAIL,
    error,
  };
};

export const deleteUserContractSettingsClear = (): TAction => {
  return {
    type: EActionTypes.USER_CONTRACT_SETTING_DELETE_CLEAR,
  };
};

export const deleteUserContractSettings = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deleteUserContractSettingsStart());
      await customFetch<boolean>(`/usercontracts/settings/delete?id=${id}`, EFetchMethod.DELETE);
      dispatch(deleteUserContractSettingsSuccess(id));
    } catch (error) {
      dispatch(deleteUserContractSettingsFail((error as Error).message));
    }
  };
};

const saveUserContractSettingsStart = (): TAction => {
  return {
    type: EActionTypes.USER_CONTRACT_SETTING_SAVE_START,
  };
};

const saveUserContractSettingsSuccess = (setting: IUserContractSettings): TAction => {
  return {
    type: EActionTypes.USER_CONTRACT_SETTING_SAVE_SUCCESS,
    setting
  };
};

const saveUserContractSettingsFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_CONTRACT_SETTING_SAVE_FAIL,
    error,
  };
};



export const saveUserContractSettings = (setting: IUserContractSettings) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveUserContractSettingsStart());
      setting = await customFetch<IUserContractSettings>("/usercontracts/settings/add", EFetchMethod.POST, JSON.stringify(setting));
      dispatch(saveUserContractSettingsSuccess(setting));
    } catch (error) {
      dispatch(saveUserContractSettingsFail((error as Error).message));
    }
  };
};

const updateUserContractSettingsStart = (): TAction => {
  return {
    type: EActionTypes.USER_CONTRACT_SETTING_UPDATE_START,
  };
};

const updateUserContractSettingsSuccess = (setting: IUserContractSettings): TAction => {
  return {
    type: EActionTypes.USER_CONTRACT_SETTING_UPDATE_SUCCESS,
    setting
  };
};

const updateUserContractSettingsFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_CONTRACT_SETTING_UPDATE_FAIL,
    error,
  };
};

export const updateUserContractSettings = (setting: IUserContractSettings) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateUserContractSettingsStart());
      setting = await customFetch<IUserContractSettings>("/usercontracts/settings/update", EFetchMethod.PUT, JSON.stringify(setting));
      dispatch(updateUserContractSettingsSuccess(setting));
    } catch (error) {
      dispatch(updateUserContractSettingsFail((error as Error).message));
    }
  };
};

export const saveOrUpdateUserContractSettingsClear = (): TAction => {
  return {
    type: EActionTypes.USER_CONTRACT_SETTING_SAVE_OR_UPDATE_CLEAR
  }
}

