interface IQueryString {
  [key: string]: any;
}

export const parseQueryString = <T extends IQueryString>(search?: string): T | null => {
  if(!search || !search.startsWith("?")) return null;
  search = search.substring(1, search.length);
  
  const result = {} as IQueryString;
  const array = search.split("&");
  array.forEach(item => {
    const [name, value] = item.split("=");
    result[name] = decodeURIComponent(value);
  })

  return result as T;
}

export const objectToQueryString = (params: any) => {
  if (!params || params === null) return '';

  const qs = Object.keys(params)
  .filter(key => {
    const param = params[key];
    return !!param && param.toString().length;
  })
  .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
  .join('&');

  return qs.length > 0 ? `?${qs}` : '';
}