import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateInput } from '../../hooks/useCreateInput';
import { ITranslation } from '../../interfaces/ITranslation';
import { ETranslation } from '../../translations/translation-keys';
import Button, { EButtonColor } from '../ui/Button/Button';
import { EInputType, IInputField } from '../ui/Input/Input';
import { getInputData, initForm } from '../ui/Input/input-utils';
import InputGroup from '../ui/InputGroup/InputGroup';
import ModalBody from '../ui/Modal/ModalBody/ModalBody';
import ModalFooter from '../ui/Modal/ModalFooter/ModalFooter';

enum EInputs {
	languageCode = "languageCode",
	name = "name",
}

interface IProps {
	translation: ITranslation;
	onSave: (translation: ITranslation) => void;
	onClose: () => void;
}

const TranslationEditModal: React.FC<IProps> = ({
	translation,
	onSave,
	onClose,
}) => {
	const { t } = useTranslation();

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.name]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_NAME,
			value: translation.name ?? "",
			validation: {
				required: true,
			},
		},
	});

	useEffect(() => {
		if (translation) {
			initForm(setInputs, translation);
		}
	}, [translation]);

	const createInput = useCreateInput(inputs, setInputs);

	const saveHandler = useCallback(() => {
		const newTranslation = getInputData<ITranslation>(inputs);
		newTranslation.languageCode = translation.languageCode;
		onSave(newTranslation);
		onClose();
	}, [inputs, onSave, translation, onClose]);

	return (
		<>
			<ModalBody>
				<InputGroup
					style={{
						alignItems: "end",
					}}
				>
					{createInput(EInputs.name)}
				</InputGroup>
			</ModalBody>
			<ModalFooter>
				<InputGroup>
					<Button onClick={saveHandler}>
						{t(ETranslation.UI_SAVE)}
					</Button>
					<Button onClick={onClose} color={EButtonColor.DANGER}>
						{t(ETranslation.UI_CANCEL)}
					</Button>
				</InputGroup>
			</ModalFooter>
		</>
	);
};

export default TranslationEditModal;
