import React from "react";
import { EUserRole, EUserStatus, IUser } from "../../../classes/User";
import { useSelector } from "react-redux";
import IAppState from "../../../interfaces/store/IAppState";
import IAuthState from "../../../interfaces/store/IAuthState";
import { useUser } from "../../../hooks/useUser";
import UserContractSettingsText from "../UserContractSettingsText/UserContractSettingsText";
import Button, { EButtonSize } from "../../ui/Button/Button";
import { ETranslation } from "../../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import { useLanguageName } from "../../../hooks/useLanguageName";
import UserListAdditionalInformationColumn from "./UserListAdditionalInformationColumn";
import { getLocationString } from "../../../shared/location-data";

interface IProps {
	user: IUser;
	onOpen: (userId: string) => void
	onComment: (user: IUser, openModalOnClose: boolean) => void;
	showWorkOrders: (user: IUser) => void
}

const UsersListItem: React.FC<IProps> = ({ user, onOpen, onComment, showWorkOrders }) => {

	const { t } = useTranslation();

	const { user: currentUser } = useSelector<IAppState, IAuthState>((state) => state.auth);
	const isYoupretAdmin = currentUser?.isYoupretAdmin;

	const { findLangNameWithCode } = useLanguageName();

	const { getUserStatusString } = useUser();

	const commentsHandler = (e: React.MouseEvent, user: IUser) => {
		e.stopPropagation();
		onComment(user, false);
	};

	const {
		createdDate,
		name,
		phoneNumber,
		email,
		organizations,
		userLanguageItems,
		lastSeenDate,
		site,
		status,
		contractSettings,
		locations,
		city
	} = user;
	return (
		<tr key={user.id} onClick={() => onOpen(user.id)}>
			<td>{createdDate}</td>
			<td>
				{name}
				{user.role === "INTERPRETER" && <>
					<br />
					<Button size={EButtonSize.X_SMALL} onClick={(e) => { e.stopPropagation(); showWorkOrders(user) }}>Näytä toimeksiannot</Button>
				</>
				}
			</td>
			{user.role === "INTERPRETER" ?
				<>
					<td>{phoneNumber}<br />{email} <br /> {city} {locations?.map((location, id) => { return <React.Fragment key={id}><br /> {getLocationString(location)}</React.Fragment> })}</td>
					<UserListAdditionalInformationColumn user={user} />
				</> :
				<>
					<td>{phoneNumber}</td>
					<td>{email}</td>
				</>
			}

			<td>{organizations?.map((org) => org.name).join(", ")}</td>
			{isYoupretAdmin && (
				<td style={{ whiteSpace: 'pre-wrap' }}>
					{userLanguageItems
						?.map((lang) => findLangNameWithCode(lang.language))
						.join("\n")}
				</td>
			)}
			<td>{lastSeenDate}</td>
			{isYoupretAdmin && (
				<>
					{user.role === EUserRole.INTERPRETER && <td>{getUserStatusString(t, status ?? EUserStatus.OFFLINE)}</td>}
					{user.role === EUserRole.ADMIN && <td>{site?.name}</td>}
					<td>
						<UserContractSettingsText contractSettings={contractSettings} />
					</td>
					<td>
						<Button
							size={EButtonSize.X_SMALL}
							onClick={(e) => commentsHandler(e, user)}
						>
							{t(ETranslation.USER_COMMENTS)}
						</Button>
					</td>
				</>
			)}
		</tr>
	);
}

export default UsersListItem;