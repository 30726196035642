import { EUserRole } from "../classes/User";
import { IOption } from "../components/ui/Input/Input";
import { ETranslation } from '../translations/translation-keys';
import { EInterpretationType } from "./interpretation-data";

export const SITE_TYPES: IOption[] = [
	{ value: EInterpretationType.INSTANT, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_INSTANT },
	{ value: EInterpretationType.BOOKING, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_BOOKING },
	{ value: EInterpretationType.ON_SITE, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_ON_SITE },
	{ value: EInterpretationType.MESSAGE, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_MESSAGE },
	{ value: EInterpretationType.ASSIGNMENT, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_ASSIGNMENT },
	{ value: EInterpretationType.DAYCARE_NURSE, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_NURSE },
	{ value: EInterpretationType.DAYCARE_TEACHER, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_TEACHER },
	{ value: EInterpretationType.DAYCARE_SPECIAL_TEACHER, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_SPECIAL_TEACHER },
	{ value: EInterpretationType.DAYCARE_ASSISTANT, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_ASSISTANT },
	{ value: EInterpretationType.DAYCARE_SOCIAL_WORKER, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_SOCIAL_WORKER },
	{ value: EInterpretationType.SUBSTITUTE_ON_SITE, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_SUBSTITUTE_ON_SITE },
	{ value: EInterpretationType.SHIFT_ON_SITE, labelTranslation: ETranslation.INTERPRETATION_DATA_TYPE_SHIFT_ON_SITE },
]

export const allRoles: EUserRole[] = [
	EUserRole.ADMIN,
	EUserRole.CUSTOMER,
	EUserRole.INTERPRETER
]

export const SITE_ROLES: IOption[] = [
	{ value: EUserRole.ADMIN, labelTranslation: ETranslation.COMMON_ADMIN },
	{ value: EUserRole.CUSTOMER, labelTranslation: ETranslation.COMMON_CUSTOMER },
	{ value: EUserRole.INTERPRETER, labelTranslation: ETranslation.COMMON_INTERPRETER },
]

export const getSiteUserRoles = (role: EUserRole): IOption | null =>{
	const option = SITE_ROLES.find(item => item.value === role);
	return option ?? null;
}

export const getSiteTypes = (type: EInterpretationType): IOption | null => {
	const option = SITE_TYPES.find(item => item.value === type);
	return option ?? null;
}

export const getSiteTypeString = (type: EInterpretationType): string => {
	return SITE_TYPES.find(item => item.value === type)?.label || "";
}