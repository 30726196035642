import { Dispatch } from 'redux';

import { customFetch, EFetchMethod } from '../../custom-fetch';
import { IInfoContactRequest } from '../../interfaces/IInfoContactRequest';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IInfoContactRequestAction from '../../interfaces/store/IInfoContactRequestAction';

type TAction = IInfoContactRequestAction;

const findInfoContactRequestsStart = (): TAction => {
  return {
    type: EActionTypes.INFO_CONTACT_REQUEST_FIND_SUCCESS,
  };
};

const findInfoContactRequestsSuccess = (infoContactRequests: IInfoContactRequest[]): TAction => {
  return {
    type: EActionTypes.INFO_CONTACT_REQUEST_FIND_SUCCESS,
    infoContactRequests,
  };
};

const findInfoContactRequestsFail = (error: string): TAction => {
  return {
    type: EActionTypes.INFO_CONTACT_REQUEST_FIND_FAIL,
    error,
  };
};

export const findInfoContactRequests = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(findInfoContactRequestsStart());
      const infoContactRequests = await customFetch<IInfoContactRequest[]>("/infocontactrequests/list");
      dispatch(findInfoContactRequestsSuccess(infoContactRequests));
    } catch (error) {
      dispatch(findInfoContactRequestsFail((error as Error).message));
    }
  };
};

const getInfoContactRequestStart = (id: string): TAction => {
  return {
    type: EActionTypes.INFO_CONTACT_REQUEST_GET_START,
    id
  };
};

const getInfoContactRequestSuccess = (infoContactRequest: IInfoContactRequest): TAction => {
  return {
    type: EActionTypes.INFO_CONTACT_REQUEST_GET_SUCCESS,
    infoContactRequest,
  };
};

const getInfoContactRequestFail = (error: string): TAction => {
  return {
    type: EActionTypes.INFO_CONTACT_REQUEST_GET_FAIL,
    error,
  };
};

export const getInfoContactRequestClear = (): TAction => {
  return {
    type: EActionTypes.INFO_CONTACT_REQUEST_GET_CLEAR,
  };
};

export const getInfoContactRequest = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getInfoContactRequestStart(id));
      const infoContactRequest = await customFetch<IInfoContactRequest>(`/infocontactrequests/get?id=${id}`);
      dispatch(getInfoContactRequestSuccess(infoContactRequest));
    } catch (error) {
      dispatch(getInfoContactRequestFail((error as Error).message));
    }
  };
};

const deleteInfoContactRequestStart = (): TAction => {
  return {
    type: EActionTypes.INFO_CONTACT_REQUEST_DELETE_START,
  };
};

const deleteInfoContactRequestSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.INFO_CONTACT_REQUEST_DELETE_SUCCESS,
    id,
  };
};

const deleteInfoContactRequestFail = (error: string): TAction => {
  return {
    type: EActionTypes.INFO_CONTACT_REQUEST_DELETE_FAIL,
    error,
  };
};

export const deleteInfoContactRequestClear = (): TAction => {
  return {
    type: EActionTypes.INFO_CONTACT_REQUEST_DELETE_CLEAR,
  };
};

export const deleteInfoContactRequest = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deleteInfoContactRequestStart());
      await customFetch<boolean>(`/infocontactrequests/delete?id=${id}`, EFetchMethod.DELETE);
      dispatch(deleteInfoContactRequestSuccess(id));
    } catch (error) {
      dispatch(deleteInfoContactRequestFail((error as Error).message));
    }
  };
};

const saveInfoContactRequestStart = (): TAction => {
  return {
    type: EActionTypes.INFO_CONTACT_REQUEST_SAVE_START,
  };
};

const saveInfoContactRequestSuccess = (infoContactRequest: IInfoContactRequest): TAction => {
  return {
    type: EActionTypes.INFO_CONTACT_REQUEST_SAVE_SUCCESS,
    infoContactRequest
  };
};

const saveInfoContactRequestFail = (error: string): TAction => {
  return {
    type: EActionTypes.INFO_CONTACT_REQUEST_SAVE_FAIL,
    error,
  };
};

export const saveInfoContactRequest = (infoContactRequest: IInfoContactRequest) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveInfoContactRequestStart());
      infoContactRequest = await customFetch<IInfoContactRequest>("/infocontactrequests/add", EFetchMethod.POST, JSON.stringify(infoContactRequest));
      dispatch(saveInfoContactRequestSuccess(infoContactRequest));
    } catch (error) {
      dispatch(saveInfoContactRequestFail((error as Error).message));
    }
  };
};

const updateInfoContactRequestStart = (): TAction => {
  return {
    type: EActionTypes.INFO_CONTACT_REQUEST_UPDATE_START,
  };
};

const updateInfoContactRequestSuccess = (infoContactRequest: IInfoContactRequest): TAction => {
  return {
    type: EActionTypes.INFO_CONTACT_REQUEST_UPDATE_SUCCESS,
    infoContactRequest
  };
};

const updateInfoContactRequestFail = (error: string): TAction => {
  return {
    type: EActionTypes.INFO_CONTACT_REQUEST_UPDATE_FAIL,
    error,
  };
};

export const updateInfoContactRequest = (infoContactRequest: IInfoContactRequest) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateInfoContactRequestStart());
      infoContactRequest = await customFetch<IInfoContactRequest>("/infocontactrequests/update", EFetchMethod.PUT, JSON.stringify(infoContactRequest));
      dispatch(updateInfoContactRequestSuccess(infoContactRequest));
    } catch (error) {
      dispatch(updateInfoContactRequestFail((error as Error).message));
    }
  };
};

export const saveOrUpdateInfoContactRequestClear = (): TAction => {
  return {
    type: EActionTypes.INFO_CONTACT_REQUEST_SAVE_OR_UPDATE_CLEAR
  }
}
