import React, { useEffect, useState } from "react";
import InputGroup from "../../../components/ui/InputGroup/InputGroup";
import * as dateFns from 'date-fns';
import {
	EInputType,
	IInputField,
	IOption,
} from "../../../components/ui/Input/Input";
import { initForm, getInputData } from "../../../components/ui/Input/input-utils";
import { ETranslation } from "../../../translations/translation-keys";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { useLanguages } from "../../../hooks/useLanguages";
import Button, { EButtonColor } from "../../../components/ui/Button/Button";
import { useTranslation } from "react-i18next";
import { searchUsers } from "../../../services/userServices";
import classes from "./calendar.module.scss";
import { INTERPRETATION_GENDER } from "../../../shared/interpretation-data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";


enum EInputs {
	toLanguage = "toLanguage",
	createdDateStart = "createdDateStart",
	gender = "gender",
	createdDateEnd = "createdDateEnd",
	type = "type",
	users = "users"
};

export const defaultStartDate = dateFns.format(
	new Date(),
	"yyyy-MM-dd"
);
export const defaultEndDate = dateFns.format(
	new Date(),
	"yyyy-MM-dd"
);



export interface ISearchOptions {
	toLanguage: [string];
	createdDateStart: string;
	createdDateEnd: string;
	gender: string;
	users: IOption[];
}

interface IProps {
	onSearch: (search: ISearchOptions) => void;
};

const CalendarSearch: React.FC<IProps> = ({ onSearch }) => {

	const { languageOptions } = useLanguages();
	const { t } = useTranslation();


	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.toLanguage]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_LANGUAGE,
			value: [],
			multiple: true,
		},
		[EInputs.createdDateStart]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_START_DATE,
			value: "",
		},
		[EInputs.createdDateEnd]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_END_DATE,
			value: "",
		},
		[EInputs.users]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_USER,
			value: [],
			multiple: true,
		},
		[EInputs.type]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_TYPE,
			value: ["BOOKING", "ON_SITE"],
			multiple: true,
		},
		[EInputs.gender]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.INTERPRETATION_GENDER,
			value: [],
			uncheckable: true,
			options: INTERPRETATION_GENDER,
			labelClassName: classes.Bold
		},
	});

	const nextDays = ()=>{
		let data = getInputData<ISearchOptions>(inputs);
		const endNextDay = dateFns.format(dateFns.addDays(dateFns.parse(data.createdDateEnd, "yyyy-MM-dd", new Date()), 1), "yyyy-MM-dd");
		const startNextDay = dateFns.format(dateFns.addDays(dateFns.parse(data.createdDateStart, "yyyy-MM-dd", new Date()), 1), "yyyy-MM-dd");
		setInputs({
			...inputs,
			[EInputs.createdDateEnd]: {
				...inputs.createdDateEnd,
				value: endNextDay,
			},
			[EInputs.createdDateStart]: {
				...inputs.createdDateStart,
				value: startNextDay,
			},
		});
	}

	const previousDays = ()=>{
		let data = getInputData<ISearchOptions>(inputs);
		const endNextDay = dateFns.format(dateFns.subDays(dateFns.parse(data.createdDateEnd, "yyyy-MM-dd", new Date()), 1), "yyyy-MM-dd");
		const startNextDay = dateFns.format(dateFns.subDays(dateFns.parse(data.createdDateStart, "yyyy-MM-dd", new Date()), 1), "yyyy-MM-dd");
		setInputs({
			...inputs,
			[EInputs.createdDateEnd]: {
				...inputs.createdDateEnd,
				value: endNextDay,
			},
			[EInputs.createdDateStart]: {
				...inputs.createdDateStart,
				value: startNextDay,
			},
		});
	}

	const defaultDates = ()=>{
		setInputs({
			...inputs,
			[EInputs.createdDateEnd]: {
				...inputs.createdDateEnd,
				value: defaultEndDate,
			},
			[EInputs.createdDateStart]: {
				...inputs.createdDateStart,
				value: defaultStartDate,
			}
		});
	}

	useEffect(() => {
		const interperterParam = new URLSearchParams(window.location.search).get("interperter")
		const storedSearchOptions: string | null = localStorage.getItem("CALENDAR_ITEMS_SEARCH");
		let inputsData: ISearchOptions;
		if(storedSearchOptions != null){
			inputsData = JSON.parse(storedSearchOptions);
		} else {
			inputsData = {
				toLanguage: ["fi"],
				createdDateStart: defaultStartDate,
				gender: "",
				createdDateEnd: defaultEndDate,
				users: [],
			};
			localStorage.setItem("CALENDAR_ITEMS_SEARCH", JSON.stringify(inputsData));
		}
		if (interperterParam != null) {
			const usersFromUrl: [{value: string, label: string}] = JSON.parse(interperterParam);
			const userForPage = usersFromUrl.map((userInfo) => {
				return {
					value: userInfo.value,
					label: userInfo.label,
				}
			})
			inputsData.users = userForPage
		}
		initForm(setInputs, inputsData);
		onSearch(inputsData);
	}, [onSearch]);

	const clearInputCache = ()=>{
		localStorage.removeItem("CALENDAR_ITEMS_SEARCH");
		const inputsData: ISearchOptions = {
			toLanguage: ["fi"],
			createdDateStart: defaultStartDate,
			gender: "",
			createdDateEnd: defaultEndDate,
			users: [],
		};
		localStorage.setItem("CALENDAR_ITEMS_SEARCH", JSON.stringify(inputsData));
		initForm(setInputs, inputsData);
		onSearch(inputsData);
	}

	const createInput = useCreateInput(inputs, setInputs);

	const searchHandler = () => {
		const usersForSearch = inputs[EInputs.users].value as IOption[]
		let data = getInputData<ISearchOptions>(inputs);
		// Transform data to contain IOption values
		data.users = usersForSearch;
		localStorage.setItem("CALENDAR_ITEMS_SEARCH", JSON.stringify(data));
		onSearch(data);
	}
	return (
		<div >
			<InputGroup>
				{createInput(EInputs.toLanguage, {
					options: languageOptions,
				})}
				<Button color={EButtonColor.WHITE} onClick={previousDays}><FontAwesomeIcon icon={faChevronLeft} /></Button>
				{createInput(EInputs.createdDateStart)}
				<Button color={EButtonColor.WHITE} onClick={defaultDates}>Nollaa</Button>
				{createInput(EInputs.createdDateEnd)}
				<Button color={EButtonColor.WHITE} onClick={nextDays}><FontAwesomeIcon icon={faChevronRight} /></Button>
				{createInput(EInputs.gender)}
				{createInput(EInputs.users, {
					fetchOptions: async (term, signal) => {
						const users = searchUsers(term, signal)
						return users;
					},
				})}
				<Button onClick={searchHandler}>{t(ETranslation.COMMON_SEARCH)}</Button>
				<Button color={EButtonColor.DEFAULT} onClick={clearInputCache}>{t(ETranslation.UI_CLEAR)}</Button>
			</InputGroup>
		</div>
	)
}

export default CalendarSearch;