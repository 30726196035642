import { ETranslation } from './../translations/translation-keys';
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as twilio from "twilio-video";
enum ESettingType {
  VIDEO,
  AUDIO,
}



export const useRoom = (accessToken: string, name: string) => {

  const [participants, setParticipants] = useState<twilio.RemoteParticipant[]>([]);
  const [localParticipant, setLocalParticipant] = useState<twilio.LocalParticipant | null>(null);
  const [twilioRoom, setTwilioRoom] = useState<twilio.Room>();
  const [isMicrophone, setIsMicrophone] = useState(true);
  const [isVideo, setIsVideo] = useState(true);

  const { t } = useTranslation();

  const connectHandler = useCallback(async (accessToken: string, name: string) => {
    try {
      const twilioRoom = await twilio.connect(accessToken, {
        audio: true,
        name,
        video: true,
      });
  
      twilioRoom.on("participantConnected", participantConnectedHandler);
      twilioRoom.on("participantDisconnected", participantDisconnectedHandler);
      twilioRoom.participants.forEach(participantConnectedHandler);  
      setLocalParticipant(twilioRoom.localParticipant);
      setTwilioRoom(twilioRoom);
    } catch (error) {
      window.alert(t(ETranslation.VIDEO_TOKEN_EXPIRED))
      window.close()
    }
  }, [t])

  useEffect(() => {
    connectHandler(accessToken, name);
  }, [accessToken, name, connectHandler]);

  const disconnectHandler = useCallback((twilioRoom: twilio.Room) =>  {
    setLocalParticipant(null);
    twilioUtils.disconnect(twilioRoom);
  }, [])


  useEffect(() => {

    if (twilioRoom) {
      window.onbeforeunload = () => disconnectHandler(twilioRoom);
      return () => {
        disconnectHandler(twilioRoom);
      }
    }

  }, [twilioRoom, disconnectHandler]);


  const participantConnectedHandler = (participant: twilio.RemoteParticipant) => {
    setParticipants((participants => [...participants, participant]));
  }

  const participantDisconnectedHandler = (participant: twilio.RemoteParticipant) => {
    setParticipants(participants => participants.filter(p => p.sid !== participant.sid));
  }


  const enableMicrophone = () => {
    twilioUtils.updateLocalTrackSettings(ESettingType.AUDIO, true, twilioRoom);
    setIsMicrophone(true);
  }

  const disableMicrophone = () => {
    twilioUtils.updateLocalTrackSettings(ESettingType.AUDIO, false, twilioRoom);
    setIsMicrophone(false);
  }

  const enableVideo = () => {
    twilioUtils.updateLocalTrackSettings(ESettingType.VIDEO, true, twilioRoom);
    setIsVideo(true)
  }

  const disableVideo = () => {
    twilioUtils.updateLocalTrackSettings(ESettingType.VIDEO, false, twilioRoom);
    setIsVideo(false);
  }

  const endVideoCall = () => {
    if (twilioRoom) {
      window.close()
      return () => {
        disconnectHandler(twilioRoom);
      }
    }
  }

  return { participants, localParticipant, disableMicrophone, enableMicrophone, disableVideo, enableVideo, endVideoCall, isMicrophone, isVideo };
}



const twilioUtils = {
  updateLocalTrackSettings: (settingType: ESettingType, enable: boolean, twilioRoom?: twilio.Room) =>  {
    if (!twilioRoom) return;
    const item =
      settingType === ESettingType.VIDEO ? "videoTracks" : "audioTracks";
    const func = enable ? "enable" : "disable";
    twilioRoom.localParticipant[item].forEach((publication: twilio.LocalVideoTrackPublication | twilio.LocalAudioTrackPublication) => {
      publication.track[func]();
    });
  },
  disconnect: (twilioRoom: twilio.Room) => {
    const { state } = twilioRoom.localParticipant;
    if (state === "connected") {
      // tracks.forEach(publication => publication.track.stop());
      twilioRoom.disconnect();
    }
  }
}