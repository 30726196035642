import React, { useEffect } from 'react';
import Container from '../../../components/ui/Container/Container';
import Button from '../../../components/ui/Button/Button';
import { RouteComponentProps } from 'react-router-dom';
import { Routes, ERoute, ERouteStaticParams } from '../../../classes/Routes';

import * as actions from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import IAppState from '../../../interfaces/store/IAppState';
import PriceSeasonList from '../../../components/PriceSeason/PriceSeasonList/PriceSeasonList';
import Heading from '../../../components/Heading/Heading';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';

interface IProps extends RouteComponentProps {

}

const PriceSeasonsListPage:React.FC<IProps> = ({ history }) => {

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { priceSeasons, loading, error } = useSelector((state: IAppState) => ({
    priceSeasons: state.priceSeason.priceSeasons,
    loading: state.priceSeason.priceSeasonsLoading,
    error: state.priceSeason.priceSeasonsError
  }))

  useEffect(() => {
    if(!priceSeasons) {
      dispatch(actions.findPriceSeasons());
    }
  }, [dispatch, priceSeasons])

  const addHandler = () => {
    openHandler(ERouteStaticParams.add);
  }

  const openHandler = (id?: string) => {
    if(!id) return;
    history.push(Routes.withParams(ERoute.PRICE_SEASON_EDIT, { id }))
  }

  return (
    <Container>
      <Heading>{t(ETranslation.MENU_PRICE_SEASONS)}</Heading>
      <Button onClick={addHandler}>{t(ETranslation.PAGES_PRICE_SEASONS_ADD_NEW)}</Button>
      <PriceSeasonList priceSeasons={priceSeasons} loading={loading} error={error} onClick={openHandler} />
    </Container>
  )
}

export default PriceSeasonsListPage;