import { useLocation } from "react-router";
import { Location } from "history";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";
import { ERoute } from "../../../../classes/Routes";
import { User } from "../../../../classes/User";

import classes from "./MenuItem.module.scss";
import { ETranslation } from "../../../../translations/translation-keys";
import { useTranslation } from "react-i18next";


export enum EMenuItem {
	ORDER_INTERPRETER,
	PRICES,
	PRICES_PRICE_SEASONS,
	PRICES_PRICE_SEASON_RULES,
	ORGANIZATIONS,
	ORGANIZATIONS_ORGANIZATION_GROUPS,
	ORGANIZATIONS_ORGANIZATION_COMPANIES,
	TEXT_MESSAGES,
	TEXT_MESSAGES_MESSAGES,
	TEXT_EMAILS_LOGS,
	NEWS,
	NEWS_NEWS,
	RECORDINGS_AND_TRANSLATIONS,
	CONTACT_REQUESTS,
	CUSTOMER_REPORT,
	SETTINGS,
	SETTINGS_SITES,
	LOG_ITEMS,
	INTERPRETATION,
	INTERPRETATIONS,
	CALENDAR,
	USERS,
	USERS_INTERPRETERS,
	USERS_CUSTOMERS,
	USERS_ADMINS,
	COMMENT_SEARCH,
	COMISSIONS,
	SUMMARIES,
	TAGS_PAGE,
	RESERVED_DATES,
	ORDERS_PAGE,
	TASKS_PAGE,
	MANAGEMENT,
	ADMIN,
	INTERPRETATION_MANAGEMENT,
	FEES_PAGE,
	TRANSLATE_MENU,
	TRANSLATE_FI,
	TRANSLATE_EN,
	LANGUAGE_CODES,
	LANGUAGES,
	USER_LOGOUT,
	USER_LOGIN,
	WORKING_HOURS_LIST_PAGE,
  WORKING_HOURS_SUMMARIES_LIST_PAGE,
	USER_GROUPS_LIST_PAGE,
	USER_CONTRACT_SETTINGS,
	USER_SHIFTS_MONTH_LIST_PAGE,
	EEZY_TRUSTER_EVENTS,
	CATEGORIES_PAGE,
  ATTRIBUTES_PAGE
}

export interface IMenuItem {
  id: EMenuItem;
  translationKey: ETranslation;
  link?: string;
  showLink?: boolean;
  to?: ERoute;
  subMenuItems?: IMenuItem[];
  isOpen?: boolean;
  onClick?: () => void;
}

interface IProps {
  menuItem: IMenuItem;
  isTopLevel?: boolean;
  user: User | null;
  toggleMenu: () => void;
  toggleMenuItem: (id: EMenuItem) => void;
}

const menuItemClickHandler = (
  menuItem: IMenuItem,
  onOpenMenuItem: (id: EMenuItem) => void
): void => {
  if (menuItem.link) {
    window.location.href = menuItem.link;
  } else {
    onOpenMenuItem(menuItem.id);
  }
};

const isActiveChild = (
  location: Location,
  menuItems?: IMenuItem[]
): boolean => {
  if (!menuItems) return false;
  return menuItems.some(
    (menuItem) => menuItem.to && location.pathname.startsWith(menuItem.to)
  );
};

const userRoutesIncludesAtleastOneMatch = (
  user: User | null,
  menuItems?: IMenuItem[]
): boolean => {
  if (!menuItems) return false;
  return menuItems.some(
    (menuItem) => (menuItem.to && user?.routes.includes(menuItem.to)) || menuItem.onClick
  );
};

const MenuItem: React.FC<IProps> = ({
  menuItem,
  isTopLevel,
  user,
  toggleMenu,
  toggleMenuItem: onOpenMenuItem,
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  const getTopLevelMenuItem = () => {
    if (menuItem.to) {
      if (!user?.routes.includes(menuItem.to)) return null;
      return (
        <NavLink
          to={menuItem.to}
          className={classes.MenuItem}
          onClick={toggleMenu}
          activeClassName={classes.MenuItemActive}
        >
          {t(menuItem.translationKey)}
        </NavLink>
      );
    } else {
      if (
        !userRoutesIncludesAtleastOneMatch(user, menuItem.subMenuItems) &&
        (!(!menuItem.link && !menuItem.showLink) || !menuItem.onClick)
      ){
		return null;
	  }

      const classNames = [classes.MenuItem];
      const isActive = isActiveChild(location, menuItem.subMenuItems);
      if (isActive) {
        classNames.push(classes.MenuItemActive);
      }

      return (
        <div
          className={classNames.join(" ")}
          onClick={menuItem.onClick ? menuItem.onClick : () => menuItemClickHandler(menuItem, onOpenMenuItem)}
        >
          {t(menuItem.translationKey)}{" "}
          {menuItem.subMenuItems && (
            <FontAwesomeIcon
              icon={faChevronDown}
              className={classes.MenuIcon}
            />
          )}
        </div>
      );
    }
  };

  const renderMenuItem = () => {
    const topLevelMenuItem = getTopLevelMenuItem();
    if (!topLevelMenuItem) return null;

    const classNames = [classes.MenuItemContainer];

    if (isTopLevel) {
      classNames.push(classes.MenuItemContainerTop);
    }

    if (menuItem.isOpen) {
      classNames.push(classes.IsOpen);
    }

    return (
      <div className={classNames.join(" ")}>
        {topLevelMenuItem}
        {menuItem.subMenuItems && (
          <div className={classes.Dropdown}>
            {menuItem.subMenuItems.map((menuItem) => (
              <MenuItem
                key={menuItem.id}
                menuItem={menuItem}
                user={user}
                toggleMenu={toggleMenu}
                toggleMenuItem={onOpenMenuItem}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  return renderMenuItem();
};

export default MenuItem;
