import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute } from '../../../classes/Routes';
import TextMessage from '../../../components/TextMessage/TextMessage';
import Alert, { EAlertColor } from '../../../components/ui/Alert/Alert';
import Container from '../../../components/ui/Container/Container';
import EditButtons from '../../../components/ui/EditButtons/EditButtons';
import Spinner from '../../../components/ui/Spinner/Spinner';
import { ITextMessage } from '../../../interfaces/ITextMessage';
import IAppState from '../../../interfaces/store/IAppState';
import * as actions from '../../../store/actions';
import Heading from '../../../components/Heading/Heading';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const TextMessageEditPage: React.FC<IProps> = ({ match, history }) => {
  const [editTextMessage, setEditTextMessage] = useState<ITextMessage | null>(
    null
  );
  const [isValid, setIsValid] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const dispatch = useDispatch();

  const { id } = match.params;
  const isAdd = id === "add";

  const { t } = useTranslation();

  useEffect(() => {
    if (!isAdd) {
      dispatch(actions.getTextMessage(id));
    }
  }, [isAdd, id, dispatch]);

  const { error, loading, saveOrUpdateOk, textMessage, deleteError, deleteLoading, deleteOk } = useSelector(
    (state: IAppState) => ({
      error: state.textMessage.textMessageError || state.textMessage.textMessageSaveOrUpdateError,
      loading: state.textMessage.textMessageLoading || state.textMessage.textMessageSaveOrUpdateLoading,
      saveOrUpdateOk: state.textMessage.textMessageSaveOrUpdateOk,
      textMessage: state.textMessage.textMessage,
      deleteLoading: state.textMessage.textMessageDeleteLoading,
      deleteError: state.textMessage.textMessageDeleteError,
      deleteOk: state.textMessage.textMessageDeleteOk,
    })
  );


  useEffect(() => {
    if (saveOrUpdateOk) {
      history.push(ERoute.TEXT_MESSAGES_LIST);
    }
    return () => {
      dispatch(actions.saveOrUpdateTextMessageClear());
      dispatch(actions.getTextMessageClear());
      dispatch(actions.deleteTextMessageClear());
    };
  }, [dispatch, saveOrUpdateOk, history]);

  const updateHandler = useCallback(
    (textMessage: ITextMessage, isValid: boolean) => {
      setEditTextMessage(textMessage);
      setIsValid(isValid);
    },
    []
  );

  const submitHandler = () => {
    if (!editTextMessage) return;

    if (isAdd) {
      dispatch(actions.saveTextMessage(editTextMessage));
    } else {
      editTextMessage.id = id;
      dispatch(actions.updateTextMessage(editTextMessage));
    }
  };

  const deleteHandler = () => {
    if(textMessage) {
      dispatch(actions.deleteTextMessage(textMessage.id));
    }
  }

  return (
    <Container>
      {error && <Alert>{error}</Alert>}
      {loading && <Spinner centerAbsolute />}
      <Heading>{t(ETranslation.PAGES_TEXTMESSAGE_TITLE)}</Heading>
      <Alert color={EAlertColor.INFO}>{t(ETranslation.PAGES_TEXTMESSAGE_INFO)}</Alert>
      <TextMessage
        onChange={updateHandler}
        loading={loading}
        textMessage={textMessage}
        showValidation={showValidation}
      />
      <EditButtons
        onSave={isValid ? submitHandler : () => setShowValidation(true)}
        disabled={showValidation && !isValid}
        loading={loading}
        onCancel={() => history.goBack()}
        onDelete={deleteHandler}
        deleteText={ETranslation.PAGES_TEXTMESSAGE_DELETE_CONFIRM}
        onDeleteClose={() => dispatch(actions.deleteTextMessageClear())}
        onDeleteDone={() => history.push(ERoute.TEXT_MESSAGES_LIST) }
        deleteError={deleteError}
        deleteLoading={deleteLoading}
        deleteOk={deleteOk}
        isAdd={isAdd}
      />
    </Container>
  );
};

export default TextMessageEditPage;
