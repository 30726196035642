import React, { useEffect, useState } from 'react';
import * as dateFns from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import IAppState from '../../../interfaces/store/IAppState';
import IAuthState from '../../../interfaces/store/IAuthState';
import { ESearchStorageKey } from '../../../shared/log-items-data';
import { ILogItemSearchOptions } from '../../../store/actions/logItemActions';
import { ETranslation } from '../../../translations/translation-keys';
import Button, { EButtonColor } from '../../ui/Button/Button';
import { IInputField, EInputType } from '../../ui/Input/Input';
import { initForm, getInputData } from '../../ui/Input/input-utils';
import InputGroup from '../../ui/InputGroup/InputGroup';
import { searchUsers } from '../../../services/userServices';
import { useCreateInput } from '../../../hooks/useCreateInput';
import { setParameter, clearParameter } from '../../../services/useSearchInputCache';


interface IProps {
  onSearch: (search: ILogItemSearchOptions) => void;
};

enum EInputs {
  createdDateStart = "createdDateStart",
  createdDateStartTime = "createdDateStartTime",
  createdDateEnd = "createdDateEnd",
  createdDateEndTime = "createdDateEndTime",
  user = "user"
}

const defaultCreatedStartDate = dateFns.format(dateFns.addHours(new Date(), -2), 'yyyy-MM-dd');
const defaultCreatedStartTime = dateFns.format(dateFns.addHours(new Date(), -2), 'HH:mm');

const LogItemsSearch: React.FC<IProps> = ({ onSearch }) => {

  const { user } = useSelector<IAppState, IAuthState>((state) => state.auth);

  const { t } = useTranslation();

  useEffect(() => {
	if (inputs) {
	  const inputFieldNames = Object.keys(inputs);
	  
	  const updatedInputs = { ...inputs };
	  
	  inputFieldNames.forEach((fieldName) => {
		const savedItem = localStorage.getItem(ESearchStorageKey.LOG_ITEMS_SEARCH);
		if (savedItem) {
			var parsedObject = JSON.parse(savedItem);
			var fieldObject = parsedObject[fieldName];
			const newValue = fieldObject.value;
			updatedInputs[fieldName] = {
		  ...updatedInputs[fieldName],
		  value: newValue,
		};
		} else {
		
		}
	  });
	  setInputs(updatedInputs);
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.createdDateStart]: {
      type: EInputType.date,
      labelTranslation: ETranslation.COMMON_START_DATE,
      value: ''
    },
    [EInputs.createdDateStartTime]: {
      type: EInputType.time,
      labelTranslation: ETranslation.COMMON_START_TIME,
      value: ''
    },
    [EInputs.createdDateEnd]: {
      type: EInputType.date,
      labelTranslation: ETranslation.COMMON_END_DATE,
      value: "",
    },
    [EInputs.createdDateEndTime]: {
      type: EInputType.time,
      labelTranslation: ETranslation.COMMON_END_TIME,
      value: ''
    },
    [EInputs.user]: {
      type: EInputType.reactSelectSearch,
      labelTranslation: ETranslation.COMMON_USER,
      value: []
    },
  });

  useEffect(() => {
    let inputsData: ILogItemSearchOptions = { 
      createdDateStart: defaultCreatedStartDate,
      createdDateStartTime: defaultCreatedStartTime,
      createdDateEnd: undefined,
      createdDateEndTime: undefined,
      user: undefined
    };
    initForm(setInputs, inputsData);
    onSearch(inputsData);
  }, [onSearch]);

  const createInput = useCreateInput(inputs, setInputs);

  const searchHandler = () => {
    const data = getInputData<ILogItemSearchOptions>(inputs);
    localStorage.setItem(ESearchStorageKey.LOG_ITEMS_SEARCH, JSON.stringify(data));
    onSearch(data);
	setParameter(ESearchStorageKey.LOG_ITEMS_SEARCH, inputs)
}

  const clearInputs = () => {
		
	clearParameter(ESearchStorageKey.LOG_ITEMS_SEARCH)
}

  return (
    <InputGroup>
      {createInput(EInputs.createdDateStart)}
      {createInput(EInputs.createdDateStartTime)}
      {createInput(EInputs.createdDateEnd)}
      {createInput(EInputs.createdDateEndTime)}
      { user?.isYoupretAdmin && (
        createInput(EInputs.user, {
          fetchOptions: async (term, signal) => {
            const users = searchUsers(term, signal)
            return users;
          }
        })
      )}
		<Button onClick={searchHandler}>{t(ETranslation.COMMON_SEARCH)}</Button>
		<Button color={EButtonColor.DEFAULT} onClick={clearInputs}>{t(ETranslation.UI_CLEAR)}</Button>
	</InputGroup>
  );
};

export default LogItemsSearch;