import React from "react";
import { IUser } from "../../../classes/User";
import classes from "../userStyles.module.scss";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


interface IProps {
	user: IUser;
}

const UserListAdditionalInformationColumn: React.FC<IProps> = ({ user }) => {
	const { t } = useTranslation();
	return <td className={classes.columnFaToEnd}>
		{t(ETranslation.COMMON_SECURITY_CLEARED)} : {user.securityCleared ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />} <br />
		{t(ETranslation.USER_TRAINED_INTERPRETER)} :  {user.isInterpreterTrained ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}
	</td>
}

export default UserListAdditionalInformationColumn;