import { ESearchStorageKey } from "../shared/log-items-data";

export const getParameter = (key: ESearchStorageKey): string | null => {
	return localStorage.getItem(key);
};

export const setParameter = <T>(item: ESearchStorageKey, value: T): void => {
	localStorage.setItem(item, JSON.stringify(value));
};

export const clearParameter = <T>(item: ESearchStorageKey): void => {
	localStorage.removeItem(item);
};
