import { IPriceSeasonRule } from "./../../interfaces/IPriceSeasonRule";
import { Dispatch } from "redux";

import { customFetch, EFetchMethod } from "../../custom-fetch";
import { IPriceSeason } from "../../interfaces/IPriceSeason";
import EActionTypes from "../../interfaces/store/EActionTypes";
import IPriceSeasonAction from "../../interfaces/store/IPriceSeasonAction";

type TAction = IPriceSeasonAction;

const findPriceSeasonsStart = (): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_FIND_SUCCESS,
	};
};

const findPriceSeasonsSuccess = (priceSeasons: IPriceSeason[]): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_FIND_SUCCESS,
		priceSeasons,
	};
};

const findPriceSeasonsFail = (error: string): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_FIND_FAIL,
		error,
	};
};

export const findPriceSeasons = () => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(findPriceSeasonsStart());
			const priceSeasons = await customFetch<IPriceSeason[]>("/priceseasons/list");
			dispatch(findPriceSeasonsSuccess(priceSeasons));
		} catch (error) {
			dispatch(findPriceSeasonsFail((error as Error).message));
		}
	};
};

const getPriceSeasonStart = (id: string): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_GET_START,
		id,
	};
};

const getPriceSeasonSuccess = (priceSeason: IPriceSeason): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_GET_SUCCESS,
		priceSeason,
	};
};

const getPriceSeasonFail = (error: string): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_GET_FAIL,
		error,
	};
};

export const getPriceSeasonClear = (): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_GET_CLEAR,
	};
};

export const getPriceSeason = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getPriceSeasonStart(id));
			const priceSeason = await customFetch<IPriceSeason>(`/priceseasons/get?id=${id}`);
			priceSeason.fromGet = true;
			dispatch(getPriceSeasonSuccess(priceSeason));
		} catch (error) {
			dispatch(getPriceSeasonFail((error as Error).message));
		}
	};
};

const deletePriceSeasonStart = (): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_DELETE_START,
	};
};

const deletePriceSeasonSuccess = (id: string): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_DELETE_SUCCESS,
		id,
	};
};

const deletePriceSeasonFail = (error: string): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_DELETE_FAIL,
		error,
	};
};

export const deletePriceSeasonClear = (): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_DELETE_CLEAR,
	};
};

export const deletePriceSeason = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(deletePriceSeasonStart());
			await customFetch<boolean>(`/priceseasons/delete?id=${id}`, EFetchMethod.DELETE);
			dispatch(deletePriceSeasonSuccess(id));
		} catch (error) {
			dispatch(deletePriceSeasonFail((error as Error).message));
		}
	};
};

const savePriceSeasonStart = (): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_SAVE_START,
	};
};

const savePriceSeasonSuccess = (priceSeason: IPriceSeason): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_SAVE_SUCCESS,
		priceSeason,
	};
};

const savePriceSeasonFail = (error: string): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_SAVE_FAIL,
		error,
	};
};

export const savePriceSeason = (priceSeason: IPriceSeason) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(savePriceSeasonStart());
			priceSeason = await customFetch<IPriceSeason>(
				"/priceseasons/add",
				EFetchMethod.POST,
				JSON.stringify(priceSeason)
			);
			dispatch(savePriceSeasonSuccess(priceSeason));
		} catch (error) {
			dispatch(savePriceSeasonFail((error as Error).message));
		}
	};
};

const copyPriceSeasonStart = (): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_COPY_START,
	};
};

const copyPriceSeasonSuccess = (priceSeason: IPriceSeason): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_COPY_SUCCESS,
		priceSeason,
	};
};

const copyPriceSeasonFail = (error: string): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_COPY_FAIL,
		error,
	};
};

export const copyPriceSeason = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(copyPriceSeasonStart());
			const priceSeason = await customFetch<IPriceSeason>(
				"/priceseasons/copy",
				EFetchMethod.POST,
				JSON.stringify({ id })
			);
			dispatch(copyPriceSeasonSuccess(priceSeason));
		} catch (error) {
			dispatch(copyPriceSeasonFail((error as Error).message));
		}
	};
};
export const copyPriceSeasonClear = (): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_COPY_CLEAR,
	};
};

const updatePriceSeasonStart = (): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_UPDATE_START,
	};
};

const updatePriceSeasonSuccess = (priceSeason: IPriceSeason): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_UPDATE_SUCCESS,
		priceSeason,
	};
};

const updatePriceSeasonFail = (error: string): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_UPDATE_FAIL,
		error,
	};
};

export const updatePriceSeason = (priceSeason: IPriceSeason) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(updatePriceSeasonStart());
			priceSeason = await customFetch<IPriceSeason>(
				"/priceseasons/update",
				EFetchMethod.PUT,
				JSON.stringify(priceSeason)
			);
			dispatch(updatePriceSeasonSuccess(priceSeason));
		} catch (error) {
			dispatch(updatePriceSeasonFail((error as Error).message));
		}
	};
};

export const saveOrUpdatePriceSeasonClear = (): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_SAVE_OR_UPDATE_CLEAR,
	};
};

const findPriceSeasonRulesStart = (): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_RULE_FIND_SUCCESS,
	};
};

const findPriceSeasonRulesSuccess = (priceSeasonRules: IPriceSeasonRule[]): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_RULE_FIND_SUCCESS,
		priceSeasonRules,
	};
};

const findPriceSeasonRulesFail = (error: string): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_RULE_FIND_FAIL,
		error,
	};
};

export const findPriceSeasonRules = () => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(findPriceSeasonRulesStart());
			const priceSeasonRules = await customFetch<IPriceSeasonRule[]>("/priceseasons/rules/list");
			dispatch(findPriceSeasonRulesSuccess(priceSeasonRules));
		} catch (error) {
			dispatch(findPriceSeasonRulesFail((error as Error).message));
		}
	};
};

const getPriceSeasonRuleStart = (): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_RULE_GET_START,
	};
};

const getPriceSeasonRuleSuccess = (priceSeasonRule: IPriceSeasonRule): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_RULE_GET_SUCCESS,
		priceSeasonRule,
	};
};

const getPriceSeasonRuleFail = (error: string): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_RULE_GET_FAIL,
		error,
	};
};

export const getPriceSeasonRuleClear = (): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_RULE_GET_CLEAR,
	};
};

export const getPriceSeasonRule = (id: string, copy: boolean = false) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getPriceSeasonRuleStart());
			const priceSeason = await customFetch<IPriceSeasonRule>(`/priceseasons/rules/get?id=${id}&copy=${copy}`);
			dispatch(getPriceSeasonRuleSuccess(priceSeason));
		} catch (error) {
			dispatch(getPriceSeasonRuleFail((error as Error).message));
		}
	};
};

const deletePriceSeasonRuleStart = (): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_RULE_DELETE_START,
	};
};

const deletePriceSeasonRuleSuccess = (id: string): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_RULE_DELETE_SUCCESS,
		id,
	};
};

const deletePriceSeasonRuleFail = (error: string): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_RULE_DELETE_FAIL,
		error,
	};
};

export const deletePriceSeasonRuleClear = (): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_RULE_DELETE_CLEAR,
	};
};

export const deletePriceSeasonRule = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(deletePriceSeasonRuleStart());
			await customFetch<boolean>(`/priceseasons/rules/delete?id=${id}`, EFetchMethod.DELETE);
			dispatch(deletePriceSeasonRuleSuccess(id));
		} catch (error) {
			dispatch(deletePriceSeasonRuleFail((error as Error).message));
		}
	};
};

const savePriceSeasonRuleStart = (): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_RULE_SAVE_START,
	};
};

const savePriceSeasonRuleSuccess = (priceSeasonRule: IPriceSeasonRule): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_RULE_SAVE_SUCCESS,
		priceSeasonRule,
	};
};

const savePriceSeasonRuleFail = (error: string): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_RULE_SAVE_FAIL,
		error,
	};
};

export const savePriceSeasonRule = (priceSeasonRule: IPriceSeasonRule) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(savePriceSeasonRuleStart());
			priceSeasonRule = await customFetch<IPriceSeasonRule>(
				"/priceseasons/rules/add",
				EFetchMethod.POST,
				JSON.stringify(priceSeasonRule)
			);
			dispatch(savePriceSeasonRuleSuccess(priceSeasonRule));
		} catch (error) {
			dispatch(savePriceSeasonRuleFail((error as Error).message));
		}
	};
};

const updatePriceSeasonRuleStart = (): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_RULE_UPDATE_START,
	};
};

const updatePriceSeasonRuleSuccess = (priceSeasonRule: IPriceSeasonRule): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_RULE_UPDATE_SUCCESS,
		priceSeasonRule,
	};
};

const updatePriceSeasonRuleFail = (error: string): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_RULE_UPDATE_FAIL,
		error,
	};
};

export const updatePriceSeasonRule = (priceSeasonRule: IPriceSeasonRule) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(updatePriceSeasonRuleStart());
			priceSeasonRule = await customFetch<IPriceSeasonRule>(
				"/priceseasons/rules/update",
				EFetchMethod.PUT,
				JSON.stringify(priceSeasonRule)
			);
			dispatch(updatePriceSeasonRuleSuccess(priceSeasonRule));
		} catch (error) {
			dispatch(updatePriceSeasonRuleFail((error as Error).message));
		}
	};
};

export const saveOrUpdatePriceSeasonRuleClear = (): TAction => {
	return {
		type: EActionTypes.PRICE_SEASON_RULE_SAVE_OR_UPDATE_CLEAR,
	};
};
