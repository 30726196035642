import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IOrganizationGroup } from '../../../../interfaces/IOrganizationGroup';
import { ETranslation } from '../../../../translations/translation-keys';
import Alert from '../../../ui/Alert/Alert';
import Input, { EInputType, TInputValue } from '../../../ui/Input/Input';
import Spinner from '../../../ui/Spinner/Spinner';
import Table from '../../../ui/Table/Table';

interface IProps {
  organizationGroups: IOrganizationGroup[] | null;
  loading: boolean;
  error: string | null;
  onClick: (id: string) => void;
}

const OrganizationGroupList: React.FC<IProps> = ({
  organizationGroups,
  loading,
  error,
  onClick,
}) => {
  const [filter, setFilter] = useState("");
  const { t } = useTranslation();
  if (error) {
    return <Alert>{error}</Alert>;
  }

  if (!organizationGroups || loading) {
    return <Spinner />;
  }

  if (organizationGroups.length === 0) {
    return <p>{t(ETranslation.ORGANIZATION_GROUP_NO_GROUPS)}</p>;
  }

  const filterOrganizationGroup = (item: IOrganizationGroup): boolean => {
    return (
      filter.length === 0 ||
      item.name?.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  };

  return (
    <>
      <Input
        containerStyles={{ margin: "1rem 0" }}
        type={EInputType.text}
        inputName="filter"
        value={filter}
        onChange={(value: TInputValue) => setFilter(value as string)}
        placeholderTranslation = {ETranslation.COMMON_FILTER_BY_NAME}
      />

      <Table hover={true}>
        <thead>
          <tr>
            <th>{t(ETranslation.COMMON_NAME)}</th>
          </tr>
        </thead>
        <tbody>
          {organizationGroups
            .filter(filterOrganizationGroup)
            .map((organizationGroup, index) => (
              <tr
                key={organizationGroup.id}
                onClick={() => onClick(organizationGroup.id)}
              >
                <td>{organizationGroup.name}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default OrganizationGroupList;
