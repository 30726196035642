import { IInfoUser } from './IInfoUser';

export enum EInfoContactRequestStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED'
}

export enum EInfoContactRequestSubject {
  IMMIGRATION = 'IMMIGRATION',
  PERMIT_MIGRI = 'PERMIT_MIGRI',
  CLARIFICATION_AND_FORMS = 'CLARIFICATION_AND_FORMS',
  STUDYING = 'STUDYING',
  WORK = 'WORK',
  OTHER = 'OTHER'
}

export interface IInfoContactRequest {
  id: string;
  created: string;
  status: EInfoContactRequestStatus;
  subject: EInfoContactRequestSubject;
  subjectOther?: string;
  user: IInfoUser;
  municipality: string;
  needInterpreter: boolean;
  toLanguage: string;
  canceledDate?: string;
  notes: string;
}