import React, { useEffect, useState } from 'react';
import { useCreateInput } from '../../hooks/useCreateInput';

import { ITextMessage } from '../../interfaces/ITextMessage';
import { ETranslation } from '../../translations/translation-keys';
import { EInputType, IInputField } from '../ui/Input/Input';
import { getInputData, initForm, validateInputs } from '../ui/Input/input-utils';

enum EInputs {
  name = "name",
  messageText = "messageText",
  recipientNumbers = "recipientNumbers",
}

interface IProps {
  onChange: (textMessage: ITextMessage, isValid: boolean) => void;
  textMessage: ITextMessage | null;
  loading?: boolean;
  showValidation?: boolean;
}

const TextMessage: React.FC<IProps> = ({ onChange, loading, textMessage, showValidation}) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.name]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_NAME,
      value: "",
      validation: {
        required: true
      }
    },
    [EInputs.messageText]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.TEXTMESSAGE_MESSAGE_TEXT,
      value: "",
    },
    [EInputs.recipientNumbers]: {
      type: EInputType.text,
      labelTranslation: ETranslation.TEXTMESSAGE_RECEIVER_NUMBER,
      value: "",
    }
  });


  useEffect(() => {
    if (textMessage) {
      initForm(setInputs, textMessage);
    }
    // eslint-disable-next-line
  }, [textMessage]);

  useEffect(() => {
    const textMessage = getInputData<ITextMessage>(inputs);
    const isValid = validateInputs(inputs);
    onChange(textMessage, isValid);
  }, [inputs, onChange]);

  const createInput = useCreateInput(inputs, setInputs, {
    showValidation,
    disabled: loading
  });

  return (
    <>
      {createInput(EInputs.name)}
      {createInput(EInputs.messageText)}
      {createInput(EInputs.recipientNumbers)}
      <br/>
    </>
  );
};

export default TextMessage;