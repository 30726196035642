import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEn from "./translations/translation-en";
import translationsFi from "./translations/translation-fi";
import johannarekryEn from "./translations/johannarekry-en";
import johannarekryFi from "./translations/johannarekry-fi";
import valitysklinikkaEn from "./translations/valitysklinikka-en";
import valitysklinikkaFi from "./translations/valitysklinikka-fi";

export enum ELanguage {
	FI = "fi",
	EN = "en",
}

// the translations
// (tip move them in a JSON file and import them)
const resources: Resource = {
	[ELanguage.EN]: {
		common: translationsEn,
		johannarekry: johannarekryEn,
    valitysklinikka: valitysklinikkaEn,
	},
	[ELanguage.FI]: {
		common: translationsFi,
		johannarekry: johannarekryFi,
    valitysklinikka: valitysklinikkaFi,
	},
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		defaultNS: "common",
		fallbackNS: "common",
		ns: ["common", "johannarekry", "valitysklinikka"],
		lng: ELanguage.FI,
		keySeparator: false, // we do not use keys in form messages.welcome
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
