import { IOption } from "../components/ui/Input/Input";
import { EWorkingHoursDateEventType } from "../interfaces/IWorkingHoursDate";

export const WORKING_HOURS_EVENT_TYPE_OPTIONS:IOption[] = [
	{ value: EWorkingHoursDateEventType.WORK, label: "Työ" },
	{ value: EWorkingHoursDateEventType.ABSENT_FLEX, label: "Poissa (liukuma)" },
	{ value: EWorkingHoursDateEventType.ANNUAL_LEAVE, label: "Vuosiloma" },
	{ value: EWorkingHoursDateEventType.SICK_LEAVE, label: "Sairasloma" },
	{ value: EWorkingHoursDateEventType.ABSENT_UNPAID, label: "Poissa (palkaton)" },
	{ value: EWorkingHoursDateEventType.NO_SHIFT, label: "Ei työvuoroa" },
	{ value: EWorkingHoursDateEventType.FLEX_FIX, label: "Korjaus (liukuma)" },
	{ value: EWorkingHoursDateEventType.ANNUAL_LEAVE_FIX, label: "Korjaus (vuosiloma)" },
];