import { useState } from "react"
import { EInputType, IInputField } from "../ui/Input/Input"

export const useNetvisorSettingsForm = () => {
	const EInputs = {
		netvisorCustomerId: "netvisorCustomerId",
		netvisorPrivateKey: "netvisorPrivateKey",
		netvisorClientName: "netvisorClientName",
		netvisorCompanyVatId: "netvisorCompanyVatId",
		netvisorPartnerId: "netvisorPartnerId",
		netvisorPartnerPrivateKey: "netvisorPartnerPrivateKey",
	}

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.netvisorCustomerId]: {
			type: EInputType.text,
			label: "netvisorCustomerId",
			value: "",
		}, [EInputs.netvisorPrivateKey]: {
			type: EInputType.text,
			label: "netvisorPrivateKey",
			value: "",
		}, [EInputs.netvisorClientName]: {
			type: EInputType.text,
			label: "netvisorClientName",
			value: "",
		}, [EInputs.netvisorCompanyVatId]: {
			type: EInputType.text,
			label: "netvisorCompanyVatId",
			value: "",
		}, [EInputs.netvisorPartnerId]: {
			type: EInputType.text,
			label: "netvisorPartnerId",
			value: "",
		}, [EInputs.netvisorPartnerPrivateKey]: {
			type: EInputType.text,
			label: "netvisorPartnerPrivateKey",
			value: "",
		},
	})

	return { inputs, setInputs, EInputs }
}