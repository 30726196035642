import React from "react";
import { Routes, ERoute } from "../../classes/Routes";
import { ETranslation } from "../../translations/translation-keys";
import Button from "../ui/Button/Button";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

interface IProps extends RouteComponentProps {
	route: ERoute
	id: string;
}

const AddNewBtn: React.FC<IProps> = ({ history, route, id }) => {
	const { t } = useTranslation();
	return (
		<Button style={{position: "absolute", right: "20px", top: "20px"}} onClick={() => history.push(Routes.withParams(route, { id }))}>{t(ETranslation.COMMON_ADD_NEW)}</Button>
	)
}

export default AddNewBtn;

