import React, { useState, useEffect } from "react";
import { useCreateInput } from "../../hooks/useCreateInput";
import { ITag, getTags } from "../../store/actions/tagsActions";
import { ETranslation } from "../../translations/translation-keys";
import { IInputField, EInputType } from "../ui/Input/Input";
import { getInputData } from "../ui/Input/input-utils";
import { useDispatch, useSelector } from "react-redux";
import IAppState from "../../interfaces/store/IAppState";

interface ITagProps {
	tags: ITag[];
	onUpdate: (tags: ITag[]) => void;
}

enum EInputs {
	tags = "tags",
}

const ITagElement: React.FC<ITagProps> = ({ tags, onUpdate }) => {

	const dispatch = useDispatch();
	const { tags: allTags, tagsLoading } = useSelector((state: IAppState) => ({
		tagsLoading: state.tags.tagsLoading,
		tags: state.tags.tags,
	}));

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.tags]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_TAGS,
			value:
				tags?.map((tag) => {
					return {
						label: tag.name,
						value: tag.id,
					};
				}) || [],
			multiple: true,
			options: allTags?.map((tag) => {
				return { value: tag.id, label: tag.name };
			}),
		},
	});
	useEffect(() => {
		if (!allTags && !tagsLoading) dispatch(getTags())
/* 		if (allTags) setInputs({
			...inputs, [EInputs.tags]: {
				...inputs[EInputs.tags], options: allTags?.map((tag) => {
					return { value: tag.id, label: tag.name };
				}),
			}
		}) */
	}, [allTags, tagsLoading, dispatch, /* inputs */])


	const createInput = useCreateInput(inputs, setInputs);

	useEffect(() => {
		const data = getInputData<{ tags: string[] }>(inputs);

		const tagsToSend = allTags?.filter((tag) => data.tags.includes(tag.id)) ?? [];
		onUpdate(tagsToSend);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs])

	return <>{createInput(EInputs.tags, {
		options: allTags?.map((tag) => {
			return { value: tag.id, label: tag.name };
		})
	})}</>;
};


export default ITagElement