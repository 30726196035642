import React, { useContext } from 'react';

import { IUserShiftMonth } from '../../interfaces/IUserShiftMonth';
import ModalContext, { EModalSize } from '../ui/Modal/ModalContext';
import UserShiftDateCreateModal from './UserShiftDateCreateModal';


export const useUserShiftDateCreateModal = () => {
  const { setModal } = useContext(ModalContext);


  const handler = (shift: IUserShiftMonth, date?: string) => {
      setModal({
        isOpen: true,
        size: EModalSize.MEDIUM,
        title: "Luo työvuorot",
        content: (
          <UserShiftDateCreateModal shift={shift} date={date} />
        ),
      });
  };

  return handler;
};
