import React, { useState } from 'react';
import Button, { EButtonColor } from '../../ui/Button/Button';
import ModalBody from '../../ui/Modal/ModalBody/ModalBody';
import ModalFooter from '../../ui/Modal/ModalFooter/ModalFooter';
import { EInputType, IInputField } from '../../ui/Input/Input';
import { ETranslation } from '../../../translations/translation-keys';
import { useCreateInput } from '../../../hooks/useCreateInput';
import { getInputData } from '../../ui/Input/input-utils';
import { useSelector } from 'react-redux';
import IAppState from '../../../interfaces/store/IAppState';

enum EInputs {
	paymentDate = "paymentDate",
};

interface IInputInterface {
	paymentDate: string;
}

interface IProps {
	onAccept: (date: string) => void;
	onCancel: () => void;
}

const GenerateSummariesModal: React.FC<IProps> = ({ onAccept, onCancel }) => {

	const { loading } = useSelector((state: IAppState) => ({
		loading: state.interpretation.generateSummariesLoading,
	}));
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.paymentDate]: {
			type: EInputType.datepicker,
			isClearable: false,
			labelTranslation: ETranslation.PAYMENT_DATE,
			dateAsString: true,
			value: null,
		}
	}
	);

	const createInput = useCreateInput(inputs, setInputs);

	const accept = () =>{
		const data = getInputData<IInputInterface>(inputs);
		onAccept(data.paymentDate);
	}


	return (
		<>
			<ModalBody>
				Maksupäivä
				{createInput(EInputs.paymentDate)}
			</ModalBody>
			<ModalFooter>
				<Button onClick={accept} loading={loading} style={{ marginRight: '.5rem' }}>Vahvista</Button>
				<Button onClick={onCancel} loading={loading} color={EButtonColor.DANGER}>Peruuta</Button>
			</ModalFooter>
		</>
	)
}

export default GenerateSummariesModal;