import React, { useEffect, useState } from "react";
import { useCreateInput } from "../../hooks/useCreateInput";

import { ILanguageCode } from "../../interfaces/ILanguageCode";
import { ETranslation } from "../../translations/translation-keys";
import { EInputType, IInputField } from "../ui/Input/Input";
import {
  getInputData,
  initForm,
  validateInputs,
} from "../ui/Input/input-utils";

enum EInputs {
	languageCode = "languageCode",
	name = "name",
}

interface IProps {
	onChange: (languageCode: ILanguageCode, isValid: boolean) => void;
	languageCode: ILanguageCode | null;
	loading?: boolean;
	showValidation?: boolean;
}

const LanguageCode: React.FC<IProps> = ({
	onChange,
	loading,
	languageCode,
	showValidation,
}) => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.languageCode]: {
			type: EInputType.text,
			labelTranslation: ETranslation.PAGES_LANGUAGE_CODE_EDIT_TITLE,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.name]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_NAME,
			value: "",
			validation: {
				required: true,
			},
		},
	});

	useEffect(() => {
		if (languageCode) {
			initForm(setInputs, languageCode);
		}
	}, [languageCode]);

	useEffect(() => {
		const languageCode = getInputData<ILanguageCode>(inputs);
		const isValid = validateInputs(inputs);
		onChange(languageCode, isValid);
	}, [inputs, onChange]);

	const createInput = useCreateInput(inputs, setInputs, {
		showValidation,
		disabled: loading,
	});

	return (
		<>
			{createInput(EInputs.languageCode)}
			{createInput(EInputs.name)}
			<br />
		</>
	);
};

export default LanguageCode;
