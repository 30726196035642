import React, { useEffect, useState } from "react";
import { EInputType, IInputField } from "../../ui/Input/Input";
import InputGroup from "../../ui/InputGroup/InputGroup";
import { getInputData } from "../../ui/Input/input-utils";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { ETranslation } from "../../../translations/translation-keys";

interface IGender {
	gender: "UNKNOWN" | "MALE"| "FEMALE"
}

interface IProps {
	setFilter: React.Dispatch<React.SetStateAction<IGender["gender"]>>;
}

enum EInput {
	gender = "gender",
}

export const GenderFilter: React.FC<IProps> = ({ setFilter }) => {

	const initialInputs = {
		[EInput.gender]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.COMMON_GENDER,
			options: [
				{
					value: "UNKNOWN",
					labelTranslation: ETranslation.COMMON_DOESNT_MATTER,
				},
				{
					value: "MALE",
					labelTranslation: ETranslation.COMMON_GENDER_MALE,
				},
				{
					value: "FEMALE",
					labelTranslation: ETranslation.COMMON_GENDER_FEMALE,
				},
			],
			value: "UNKNOWN",
		},
	}

	const [inputs, setInputs] = useState<IInputField>(initialInputs);
	const createInput = useCreateInput(inputs, setInputs);

	useEffect(()=>{
		const data = getInputData<IGender>(inputs);
		setFilter(data.gender);
	}, [inputs, setFilter])

	return <InputGroup>{createInput(EInput.gender)}</InputGroup>
}