import React, { ReactNode } from 'react';
import ModalBody from '../ui/Modal/ModalBody/ModalBody';
import ModalFooter from '../ui/Modal/ModalFooter/ModalFooter';
import Button, { EButtonColor } from '../ui/Button/Button';


interface IProps {
  children: ReactNode;
  onAccept: () => void;
  onCancel: () => void;
}

const ConfirmModal: React.FC<IProps> = ({ children, onAccept, onCancel }) => {

  return (
    <>
      <ModalBody>
        {children}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onAccept} style={{ marginRight: '.5rem' }}>Vahvista</Button>
        <Button onClick={onCancel} color={EButtonColor.DANGER}>Peruuta</Button>
      </ModalFooter>
    </>
  )
}

export default ConfirmModal;