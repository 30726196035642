import React, { forwardRef, useImperativeHandle, useState } from 'react';

import Button, { EButtonColor, EButtonSize } from '../../../components/ui/Button/Button';
import Fieldset from '../../../components/ui/Fieldset/Fieldset';
import { EInputType, IInputField } from '../../../components/ui/Input/Input';
import { getInputData, updateInputHandler, validateInputs } from '../../../components/ui/Input/input-utils';
import InputGroup from '../../../components/ui/InputGroup/InputGroup';
import { useCreateInput } from '../../../hooks/useCreateInput';
import { ILocationDetail } from '../../../interfaces/IInterpretation';
import * as userServices from '../../../services/userServices';
import { ETranslation } from '../../../translations/translation-keys';

interface IProps {
	userId?: string;
	showValidation: boolean;
}

enum EInputs {
	address = "address",
	zip = "zip",
	city = "city",
	info = "info",
}

export interface IInterpretationAddLocationFormHandle {
	getData: () => ILocationDetail;
	isValid: () => boolean;
}

const InterpretationAddLocationForm: React.ForwardRefRenderFunction<IInterpretationAddLocationFormHandle, IProps> = (
	{ userId, showValidation },
	ref
) => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.address]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_ADDRESS,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.zip]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_ZIP,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.city]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_LOCATION,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.info]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.INTERPRETATION_ON_SITE_INFO,
			value: "",
		},
	});

	const { populateByUserHandler, loading } = usePopulateByUser(setInputs);

	useImperativeHandle(ref, () => ({
		getData: () => getInputData<ILocationDetail>(inputs),
		isValid: () => validateInputs(inputs),
	}));

	const createInput = useCreateInput(inputs, setInputs, { showValidation });

	return (
		<>
			<Fieldset label="Sijainti">
				{userId && (
					<Button loading={loading} onClick={() => populateByUserHandler(userId)} color={EButtonColor.DEFAULT} size={EButtonSize.X_SMALL} style={{ marginBottom: '.5rem' }}>
						Tuo asiakkaan tiedot
					</Button>
				)}
				{createInput(EInputs.address)}
				<InputGroup>
					{createInput(EInputs.zip)}
					{createInput(EInputs.city)}
				</InputGroup>
				{createInput(EInputs.info)}
			</Fieldset>
		</>
	);
};

const usePopulateByUser = (setInputs: React.Dispatch<React.SetStateAction<IInputField>>) => {
	const [loading, setLoading] = useState(false);
	const populateByUserHandler = async (id: string) => {
		try {
			setLoading(true);
			const user = await userServices.getUser(id);
			const organizations = user.organizations;
			if (organizations && organizations.length > 0) {
				const organization = organizations[0];
				updateInputHandler(EInputs.address, organization.streetName, setInputs);
				updateInputHandler(EInputs.zip, organization.zip, setInputs);
				updateInputHandler(EInputs.city, organization.city, setInputs);
				updateInputHandler(EInputs.info, organization.name, setInputs);
			}
		} catch (e) { } finally {
			setLoading(false);
		}
	};

	return { populateByUserHandler, loading };
};

export default forwardRef(InterpretationAddLocationForm);
