import React from "react";
import { IOrganization } from "../../../interfaces/IOrganization";


interface IProps {
	organization: IOrganization;
}

const OrganizationInfoBox: React.FC<IProps> = ({ organization }) => {
	return (
		<>
			{organization.name}<br />
			{organization.businessId ?? ""} <br />
			{organization.streetName ? <>{organization.streetName}<br /></> : ""}
			{organization.zip} {organization.city}<br />
		</>
	);
};

export default OrganizationInfoBox;
