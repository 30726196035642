import EActionTypes from '../../interfaces/store/EActionTypes';
import IModalAction from '../../interfaces/store/IModalAction';

type TAction = IModalAction;

export const modalUpdating = (isValue: boolean): TAction => {
  return {
    type: EActionTypes.MODAL_UPDATING,
    isValue
  };
};

export const modalUpdatingOk = (isValue: boolean): TAction => {
  return {
    type: EActionTypes.MODAL_UPDATING_OK,
    isValue
  };
};

export const modalUpdatingError = (error: string | null): TAction => {
  return {
    type: EActionTypes.MODAL_UPDATING_ERROR,
    error
  };
};


export const modalDeleting = (isValue: boolean): TAction => {
  return {
    type: EActionTypes.MODAL_DELETING,
    isValue
  };
};

export const modalDeletingOk = (isValue: boolean): TAction => {
  return {
    type: EActionTypes.MODAL_DELETING_OK,
    isValue
  };
};

export const modalDeletingError = (error: string | null): TAction => {
  return {
    type: EActionTypes.MODAL_DELETING_ERROR,
    error
  };
};


