import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute } from '../../../classes/Routes';
import OrganizationGroup from '../../../components/OrganizationGroup/OrganizationGroup/OrganizationGroup';
import Alert, { EAlertColor } from '../../../components/ui/Alert/Alert';
import Container from '../../../components/ui/Container/Container';
import EditButtons from '../../../components/ui/EditButtons/EditButtons';
import Spinner from '../../../components/ui/Spinner/Spinner';
import { IOrganizationGroup } from '../../../interfaces/IOrganizationGroup';
import IAppState from '../../../interfaces/store/IAppState';
import * as actions from '../../../store/actions';
import Heading from '../../../components/Heading/Heading';
import { ETranslation } from '../../../translations/translation-keys';
import { useTranslation } from 'react-i18next';

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const OrganizationGroupEditPage: React.FC<IProps> = ({ match, history }) => {
  const [editOrganizationGroup, setEditOrganizationGroup] = useState<IOrganizationGroup | null>(
    null
  );
  const [isValid, setIsValid] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const dispatch = useDispatch();

  const { id } = match.params;
  const isAdd = id === "add";

  const { t } = useTranslation();

  useEffect(() => {
    if (!isAdd) {
      dispatch(actions.getOrganizationGroup(id));
    }
  }, [isAdd, id, dispatch]);

  const { error, loading, saveOrUpdateOk, organizationGroup, deleteError, deleteLoading, deleteOk } = useSelector(
    (state: IAppState) => ({
      error: state.organizationGroup.organizationGroupError || state.organizationGroup.organizationGroupSaveOrUpdateError,
      loading: state.organizationGroup.organizationGroupLoading || state.organizationGroup.organizationGroupSaveOrUpdateLoading,
      saveOrUpdateOk: state.organizationGroup.organizationGroupSaveOrUpdateOk,
      organizationGroup: state.organizationGroup.organizationGroup,
      deleteLoading: state.organizationGroup.organizationGroupDeleteLoading,
      deleteError: state.organizationGroup.organizationGroupDeleteError,
      deleteOk: state.organizationGroup.organizationGroupDeleteOk,
    })
  );


  useEffect(() => {
    if (saveOrUpdateOk) {
      history.push(ERoute.ORGANIZATION_GROUPS_LIST);
    }
    return () => {
      dispatch(actions.saveOrUpdateOrganizationGroupClear());
      dispatch(actions.getOrganizationGroupClear());
      dispatch(actions.deleteOrganizationGroupClear());
    };
  }, [dispatch, saveOrUpdateOk, history]);

  const updateHandler = useCallback(
    (organizationGroup: IOrganizationGroup, isValid: boolean) => {
      setEditOrganizationGroup(organizationGroup);
      setIsValid(isValid);
    },
    []
  );

  const submitHandler = () => {
    if (!editOrganizationGroup) return;

    if (isAdd) {
      dispatch(actions.saveOrganizationGroup(editOrganizationGroup));
    } else {
      editOrganizationGroup.id = id;
      dispatch(actions.updateOrganizationGroup(editOrganizationGroup));
    }
  };

  const deleteHandler = () => {
    if(organizationGroup) {
      dispatch(actions.deleteOrganizationGroup(organizationGroup.id));
    }
  }

  return (
    <Container>
      {error && <Alert>{error}</Alert>}
      {loading && <Spinner centerAbsolute />}
      <Heading>{t(ETranslation.COMMON_ORGANIZATION_GROUP)}</Heading>
      <Alert color={EAlertColor.INFO}>{t(ETranslation.PAGES_ORGANIZATION_INFO)}</Alert>
      <OrganizationGroup
        onChange={updateHandler}
        loading={loading}
        organizationGroup={organizationGroup}
        showValidation={showValidation}
      />
      <EditButtons
        onSave={isValid ? submitHandler : () => setShowValidation(true)}
        disabled={showValidation && !isValid}
        loading={loading}
        onCancel={() => history.goBack()}
        onDelete={deleteHandler}
        deleteText={ETranslation.PAGES_ORGANIZATION_DELETE_CONFIRM}
        onDeleteClose={() => dispatch(actions.deleteOrganizationGroupClear())}
        onDeleteDone={() => history.push(ERoute.ORGANIZATION_GROUPS_LIST) }
        deleteError={deleteError}
        deleteLoading={deleteLoading}
        deleteOk={deleteOk}
        isAdd={isAdd}
      />
    </Container>
  );
};

export default OrganizationGroupEditPage;
