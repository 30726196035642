import * as dateFns from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button, { EButtonColor } from '../../components/ui/Button/Button';
import { EInputType, IInputField } from '../../components/ui/Input/Input';
import { getInputData } from '../../components/ui/Input/input-utils';
import InputGroup from '../../components/ui/InputGroup/InputGroup';
import { useCreateInput } from '../../hooks/useCreateInput';
import IAppState from '../../interfaces/store/IAppState';
import { searchOrganizations } from '../../services/searchServices';
import { searchUsers } from '../../services/userServices';
import { clearParameter, setParameter } from '../../services/useSearchInputCache';
import { ESearchStorageKey } from '../../shared/log-items-data';
import * as actions from '../../store/actions';
import { ICommentSeachParams } from '../../store/actions/commentActions';
import { ETranslation } from '../../translations/translation-keys';


enum EInputs {
	organization = "organization",
	commentedDateStart = "commentedDateStart",
	commentedDateEnd = "commentedDateEnd",
	organizationGroup = "organizationGroup",
	user = "user",
	type = "type",
};

export const defaultStartDate = dateFns.format(
	new Date(),
	"yyyy-MM-dd HH:mm"
);
export const defaultEndDate = dateFns.format(
	new Date(),
	"yyyy-MM-dd HH:mm"
);


export interface IFilterOptions {
	organization: string;
	user: string;
	organizationGroup: string;
	commentedDateStart: string;
	commentedDateEnd: string;
	type: "user" | "organization" | "organizationGroup" | "all";
}

interface IProps {
}

const CommentsSearch: React.FC<IProps> = () => {

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const [searchType, setSearchType] = useState<"user" | "organization" | "organizationGroup" | "all">("all")

	const [error, setError] = useState<null|string>(null);
	
	const {
		organizationGroups
	} = useSelector((state: IAppState) => state.organizationGroup);

	// update organization group input after loading for organizationgroups is done
	useEffect(() => {
		setInputs({
			...inputs,
			[EInputs.organizationGroup]: {
				type: EInputType.reactSelect,
				labelTranslation: ETranslation.COMMON_NAME,
				value: [],
				options: organizationGroups?.map((organizationGroup)=>{return {value: organizationGroup.id, label: organizationGroup.name}}),
			},
		})
		// TODO Find a better solution for the onMount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organizationGroups]);

	useEffect(() => {
		if (inputs) {
			const inputFieldNames = Object.keys(inputs);

			const updatedInputs = { ...inputs };

			inputFieldNames.forEach((fieldName) => {
				const savedItem = localStorage.getItem(ESearchStorageKey.COMMENTS_SEARCH);
				if (savedItem) {
					var parsedObject = JSON.parse(savedItem);
					var fieldObject = parsedObject[fieldName];
					const newValue = fieldObject.value;
					updatedInputs[fieldName] = {
						...updatedInputs[fieldName],
						value: newValue,
					};
				} else {

				}
			});
			setInputs(updatedInputs);
		}
		// TODO Find a better solution for the onMount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Input set
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.organization]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_ORGANIZATION,
			value: [],
			multiple: false,
		},
		[EInputs.user]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_USERS,
			value: "",
		},
		[EInputs.organizationGroup]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_NAME,
			value: [],
			options: organizationGroups?.map((organizationGroup)=>{return {value: organizationGroup.id, label: organizationGroup.name}}),
		},
		[EInputs.commentedDateStart]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_START_DATE,
			value: "",
		},
		[EInputs.commentedDateEnd]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_END_DATE,
			value: "",
		},
		[EInputs.type]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_TYPE,
			options: [
				{
					value: "all",
					labelTranslation: ETranslation.COMMON_ALL,
				},
				{
					value: "user",
					labelTranslation: ETranslation.COMMON_USER,
				},
				{
					value: "organization",
					labelTranslation: ETranslation.COMMON_ORGANIZATION,
				},
				{
					value: "organizationGroup",
					labelTranslation: ETranslation.COMMON_ORGANIZATION_GROUP,
				}],
			value: "all",
		},
	});

	const createInput = useCreateInput(inputs, setInputs);

	// Handle searchtype changes
	useEffect(() => {
		let data = getInputData<IFilterOptions>(inputs);
		if (searchType !== data.type) {
			setSearchType(data.type);
			dispatch(actions.getCommentsClear());
		}
		// TODO Find a better solution for the onMount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs]);

	// Once displayed, fetch organizationGroups
	useEffect(() => {
		dispatch(actions.findOrganizationGroups());
		// TODO Find a better solution for the onMount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Search handler with search type clauses and query options
	const doSearch = () => {
		setError(null);
		let data = getInputData<IFilterOptions>(inputs);
		let searchParamCheck = false;
		const params: ICommentSeachParams = {
			entityId: null,
			searchType: "ALL",
			startDate: data.commentedDateStart,
			endDate: data.commentedDateEnd,
		}
		if (searchType === "user") {
			params.searchType = "USER";
			params.entityId = data.user;
			searchParamCheck = true;
		} else if (searchType === "organization") {
			params.searchType = "ORGANIZATION";
			params.entityId = data.organization;
			searchParamCheck = true;
		} else if (searchType === "organizationGroup") {
			params.searchType = "ORGANIZATION_GROUP";
			params.entityId = data.organizationGroup;
			searchParamCheck = true;
		}
		// If entity is required for the seach, display an error message if it's not selected
		if(searchParamCheck){
			if(params.entityId && params.entityId.length > 0){
				dispatch(actions.searchComments(params));
			} else {
				setError("Search Param not set, please select some entity to search or if you want to see every comment, please select Type \"All\"");
			}
		} else {
			dispatch(actions.searchComments(params));
		}		
		setParameter(ESearchStorageKey.COMMENTS_SEARCH, inputs)
	}

	const clearInputs = () => {

		clearParameter(ESearchStorageKey.COMMENTS_SEARCH)
	}

	return (
		<div>
			<InputGroup>
				{createInput(EInputs.type)}
			</InputGroup>
			<InputGroup>
				{searchType === "organization" && createInput(EInputs.organization, { fetchOptions: searchOrganizations })}
				{searchType === "user" && createInput(EInputs.user, {
					fetchOptions: async (term, signal) => {
						const users = searchUsers(term, signal);
						return users;
					},
				})}
				{searchType === "organizationGroup" && createInput(EInputs.organizationGroup)}
				{createInput(EInputs.commentedDateStart)}
				{createInput(EInputs.commentedDateEnd)}
				<Button onClick={doSearch}>{t(ETranslation.COMMON_SEARCH)}</Button>
				<Button color={EButtonColor.DEFAULT} onClick={clearInputs}>{t(ETranslation.UI_CLEAR)}</Button>
		</InputGroup>
			{error ? <div style={{color: "red"}}>{error}</div> : null}
		</div>
	)
}

export default CommentsSearch;