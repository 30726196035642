import { useUser } from "../../../../hooks/useUser";
import { LOCATION_OPTIONS } from "../../../../shared/location-data";
import { ETranslation } from "../../../../translations/translation-keys";
import { EInputType } from "../../../ui/Input/Input";

export enum EInterpreterSearchInputs {
	organization = "organization",
	user = "user",
	language = "language",
	locations = "locations",
	city = "city",
	status = "status",
	fetchDeleted = "fetchDeleted",
	showTrained = "showTrained",
	showSecurityCleared = "showSecurityCleared",
	gender = "gender",
}

const { USER_STATUS_ALL } = useUser();

export const initialInterpreterSearchInputs = {
	[EInterpreterSearchInputs.organization]: {
		type: EInputType.reactSelectSearch,
		labelTranslation: ETranslation.COMMON_ORGANIZATION,
		value: [],
		multiple: true,
	},
	[EInterpreterSearchInputs.user]: {
		type: EInputType.reactSelectSearch,
		labelTranslation: ETranslation.COMMON_INTERPRETER,
		value: "",
	},
	[EInterpreterSearchInputs.language]: {
		type: EInputType.reactSelect,
		labelTranslation: ETranslation.COMMON_LANGUAGE,
		value: [],
		multiple: true,
		clearable: true,
	},
	[EInterpreterSearchInputs.fetchDeleted]: {
		type: EInputType.boolean,
		labelTranslation: ETranslation.UI_SHOW_DELETED,
		value: false,
	},
	[EInterpreterSearchInputs.city]: {
		type: EInputType.text,
		labelTranslation: ETranslation.SITE_CITY,
		value: "",
	},
	[EInterpreterSearchInputs.locations]: {
		type: EInputType.reactSelect,
		options: LOCATION_OPTIONS,
		labelTranslation: ETranslation.USER_LOCATION,
		value: [],
		multiple: true,
	},
	[EInterpreterSearchInputs.status]: {
		type: EInputType.reactSelect,
		labelTranslation: ETranslation.USER_STATUS_OF_INTERPRETER,
		options: USER_STATUS_ALL,
		value: [],
		multiple: true,
	},
};