import React from "react";
import { IConnectionDetail } from "../../../../interfaces/IInterpretation";
import { useTranslation } from "react-i18next";
import { EConnectionDetail, getConnectionTypeString } from "../../../../shared/interpretation-data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faEdit, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { ETranslation } from "../../../../translations/translation-keys";
import Spinner from "../../../ui/Spinner/Spinner";


interface Iprops {
	connection?: IConnectionDetail;
	onChange: () => void;
	loading: boolean;
}

const ConnectionDetail: React.FC<Iprops> = ({ connection, onChange, loading }) => {

	const { t } = useTranslation();
	return <div>
		<p>
			<b>{t(ETranslation.COMMON_CONNECTION_DETAIL)}</b>{" "}
			<FontAwesomeIcon
				icon={faEdit}
				onClick={onChange}
				style={{ cursor: "pointer" }}
			/>
		</p>
		{loading ?
			<Spinner /> :
			connection !== undefined ? 
			<>
				<p>{getConnectionTypeString(t, connection.connectionType)}</p>
				{(connection.connectionType === EConnectionDetail.INTERPRETER_CALL_TO_CONTACT || connection.connectionType === EConnectionDetail.CONTACT_CALL_TO_INTERPRETER) &&
					<>
						<p>
							<b>{connection.firstName} {connection.lastName}</b>
						</p>
						<p>
							<FontAwesomeIcon icon={faBuilding} /> {connection.organizationName}
						</p>
						<p>
							<FontAwesomeIcon icon={faPhone} /> 	{connection.phoneNumber}
						</p>
						<p>
							<FontAwesomeIcon icon={faEnvelope} /> {connection.email}
						</p>
						{(connection.connectionType === EConnectionDetail.CONTACT_CALL_TO_INTERPRETER) &&
							<p><b>{t(ETranslation.INTREPRETATION_CONNECTION_SENT_VIA)}{" : "}</b>
								{connection.deliverySms ? t(ETranslation.INTREPRETATION_CONNECTION_INFO_VIA_PHONE) + " " : ""}
								{connection.deliveryEmail ? t(ETranslation.INTREPRETATION_CONNECTION_INFO_VIA_MAIL) + " " : ""}
							</p>
						}
					</>
				}
				{connection.connectionType === EConnectionDetail.VIDEO_CONFERENCE &&
					<>
						<p>
							{t(ETranslation.INTERPRETATION_VIDEO_URL)}:
						</p>
						<p>
							{connection.videoConferenceUrl}
						</p>
						<p>
							{t(ETranslation.INTERPRETATION_VIDEO_INFO)}:
						</p>
						<p>
							{connection.videoConferenceInfo}
						</p>
					</>
				}
			</> : <></>
		}
	</div>
}

export default ConnectionDetail;