import React, { MouseEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ERoute, Routes } from '../../classes/Routes';
import Heading from '../../components/Heading/Heading';
import Button from '../../components/ui/Button/Button';
import Container from '../../components/ui/Container/Container';
import UserShiftsMonthList from '../../components/UserShifts/UserShiftsMonthList';
import UserShiftsSearch from '../../components/UserShifts/UserShiftsSearch';
import { useCustomHistory } from '../../hooks/useCustomHistory';
import IAppState from '../../interfaces/store/IAppState';
import IUserShiftState from '../../interfaces/store/IUserShiftState';
import * as actions from '../../store/actions';

const UserShiftsListPage: React.FC = () => {
	const dispatch = useDispatch();
  const history = useCustomHistory();

  /*
  const [year, setYear] = useState<number | null>(null);
  const [month, setMonth] = useState<number | null>(null)
  */

	const { userShifts, loading, error } = useSelector<IAppState, IUserShiftState>((state) => state.userShifts);

	const addHandler = (ev: MouseEvent) => {
		openHandler(ev, "add");
	};

	const openHandler = (ev: MouseEvent,  id?: string) => {
		if (!id) return;
		history.push(ev, Routes.withParams(ERoute.USER_SHIFTS_EDIT, { id }));
	};

	const searchHandler = useCallback((year: number, month: number) => {
    /*
    setYear(year)
    setMonth(month);
    */
		dispatch(actions.findUserShiftMonths(year, month));
	}, [dispatch]);

	return (
		<>
			<Container>
        <UserShiftsSearch onSearch={searchHandler} />
				<Heading>Työvuorot</Heading>
				<Button style={{ marginBottom: "1rem" }} onClick={addHandler}>
					Lisää kuukausi
				</Button>
        {/*
          <UserShiftsMonthsGrid year={year} month={month} userShifts={userShifts} />
        **/}
				<UserShiftsMonthList userShifts={userShifts} loading={loading} error={error} onClick={openHandler} />
			</Container>
		</>
	);
};

export default UserShiftsListPage;
