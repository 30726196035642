import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";

import DeleteModal from "../../DeleteModal/DeleteModal";
import Button, { EButtonColor } from "../Button/Button";
import ModalContext, { EModalSize } from "../Modal/ModalContext";
import { useConfirmModal } from "../../../hooks/useConfirmModal";

interface IProps {
	onSave?: () => void;
	onCancel: () => void;
	onSend?: () => void;
	onDelete?: () => void;
	onDeleteClose?: () => void;
	onDeleteDone?: () => void;
	onCopy?: () => void;
	deleteText?: string;
	loading?: boolean;
	disabled?: boolean;
	deleteLoading?: boolean;
	deleteError?: string | null;
	deleteOk?: boolean;
	isAdd: boolean;
}

const EditButtons: React.FC<IProps> = ({
	onSave,
	onCancel,
	onSend,
	onDelete,
	onDeleteClose,
	onDeleteDone,
	onCopy,
	loading,
	disabled,
	deleteText,
	deleteLoading,
	deleteError,
	deleteOk,
	isAdd,
}) => {
	const { setModal, closeModal } = useContext(ModalContext);
	const [showDelete, setShowDelete] = useState(false);
	const openConfirmModal = useConfirmModal();
	const { t } = useTranslation();
	useEffect(() => {
		if (deleteOk) {
			setTimeout(() => {
				closeModal();
				if (onDeleteDone) {
					onDeleteDone();
				}
			}, 1);
		}
	}, [deleteOk, closeModal, onDeleteDone]);

	useEffect(() => {
		if (showDelete) {
			setModal({
				isOpen: showDelete,
				title: t(ETranslation.DELETEMODAL_CONFIRMATION),
				size: EModalSize.SMALL,
				loading: deleteLoading,
				content: <DeleteModal deleteText={deleteText} onDelete={onDelete} error={deleteError} />,
				onModalClose: () => {
					setShowDelete(false);
					if (onDeleteClose) {
						onDeleteClose();
					}
				},
			});
		}
		// eslint-disable-next-line
	}, [deleteLoading, deleteError, showDelete, deleteText, setModal]);

	const deleteHandler = () => {
		setShowDelete(true);
	};

	const copyHandler = async () => {
		if (!onCopy) return;
		const success = await openConfirmModal(t(ETranslation.COMMON_COPY_CONFIRM_TEXT));
		if (success) {
			onCopy();
		}
	}

	return (
		<>
			{onSend && (
				<Button onClick={onSend} style={{ marginRight: ".5rem" }} loading={loading} disabled={disabled}>
					{t(ETranslation.UI_SEND)}
				</Button>
			)}
			{onSave && (
				<Button onClick={onSave} style={{ marginRight: ".5rem" }} loading={loading} disabled={disabled}>
					{t(ETranslation.UI_SAVE)}
				</Button>
			)}
			{!isAdd && onDelete && (
				<Button onClick={deleteHandler} color={EButtonColor.DANGER}>
					{t(ETranslation.COMMON_DELETE)}
				</Button>
			)}
			{!isAdd && onCopy && (
				<Button onClick={copyHandler} style={{ marginLeft: ".5rem" }} color={EButtonColor.DEFAULT}>
					{t(ETranslation.COMMON_COPY)}
				</Button>
			)}
			<Button onClick={onCancel} style={{ marginLeft: ".5rem" }} color={EButtonColor.DEFAULT}>
				{t(ETranslation.UI_CANCEL)}
			</Button>
		</>
	);
};

export default EditButtons;
