import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute, Routes } from '../../../classes/Routes';
import Button from '../../../components/ui/Button/Button';
import Container from '../../../components/ui/Container/Container';
import IAppState from '../../../interfaces/store/IAppState';
import * as actions from '../../../store/actions';
import NewsItemList from '../../../components/NewsItem/NewsItemList/NewsItemList';
import Heading from '../../../components/Heading/Heading';
import { User } from '../../../classes/User';
import { ETranslation } from '../../../translations/translation-keys';
import { useTranslation } from 'react-i18next';

interface IProps extends RouteComponentProps {}

const NewsItemsListPage: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  
  const { newsItems, loading, error, user } = useSelector(
    (state: IAppState) => ({
      newsItems: state.newsItem.newsItems,
      loading: state.newsItem.newsItemsLoading,
      error: state.newsItem.newsItemsError,
      user: state.auth.user
    })
  );

  useEffect(() => {
    dispatch(actions.findNewsItems());
  }, [dispatch]);

  const addHandler = () => {
    openHandler("add");
  };

  const openHandler = (id?: string) => {
    if (!id) return;
    history.push(Routes.withParams(ERoute.NEWS_ITEM_EDIT, { id }));
  };

  return (
    <Container>
      <Heading>{t(ETranslation.MENU_NEWS)}</Heading>
      <Button onClick={addHandler}>{t(ETranslation.PAGES_ADD_NEWS)}</Button>
      <NewsItemList
        newsItems={newsItems}
        loading={loading}
        error={error}
        onClick={openHandler}
        user={user as User}
      />
    </Container>
  );
};

export default NewsItemsListPage;