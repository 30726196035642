import React, { useEffect, useState } from "react";
import Container from "../../components/ui/Container/Container";
import Heading from "../../components/Heading/Heading";
import { useSelector } from "react-redux";
import { ITag, ITagLanguageVersions } from "../../store/actions/tagsActions";
import IAppState from "../../interfaces/store/IAppState";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../translations/translation-keys";
import { EInputType, IInputField } from "../../components/ui/Input/Input";
import { useCreateInput } from "../../hooks/useCreateInput";
import { getInputData, validateInputs } from "../../components/ui/Input/input-utils";
import Button from "../../components/ui/Button/Button";
import Spinner from "../../components/ui/Spinner/Spinner";
import TagLanguageVersion from "./TagLanguageVersion";
import classes from "./tags.module.scss";

interface IProps {
	tag?: ITag | null;
	onUpdate: (tag: ITag, isValid: boolean) => void;
}

enum EInputs {
	id = "id",
	name = "name",
	description = "description",
}

const Tag: React.FC<IProps> = ({ tag, onUpdate }) => {
	const { t } = useTranslation();
	const { loading } = useSelector((state: IAppState) => state.tags);
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.id]: {
			type: EInputType.text,
			label: "id",
			value: tag?.id ?? null,
		},
		[EInputs.name]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_NAME,
			value: tag?.name ?? "",
			validation: {
				required: true,
				minLength: 1,
			},
		},
		[EInputs.description]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_DESCRIPTION,
			value: tag?.description ?? "",
			validation: {
				required: true,
				minLength: 1,
			},
		},
	});
	const [tagLangVersions, setTagLangVersions] = useState<ITagLanguageVersions[] | null>(null);

	useEffect(() => {
		if (tag && tag !== null) {
			if (tag.tagLanguageVersions) {
				setTagLangVersions(tag.tagLanguageVersions);
			}
			setInputs({
				[EInputs.id]: {
					type: EInputType.text,
					label: "id",
					value: tag?.id ?? null,
				},
				[EInputs.name]: {
					type: EInputType.text,
					labelTranslation: ETranslation.COMMON_NAME,
					value: tag?.name ?? "",
					validation: {
						required: true,
						minLength: 1,
					},
				},
				[EInputs.description]: {
					type: EInputType.textarea,
					labelTranslation: ETranslation.COMMON_DESCRIPTION,
					value: tag?.description ?? "",
					validation: {
						required: true,
						minLength: 1,
					},
				},
			})
		}
	}, [tag]);

	const addNewTagLang = () => {
		let tagsToAlter: ITagLanguageVersions[];
		if (tagLangVersions) {
			tagsToAlter = [...tagLangVersions];
		} else {
			tagsToAlter = [];
		}

		tagsToAlter.push({
			name: "",
			description: "",
			// Solely for the purpose of key and removing existing tags.
			id: new Date().getTime().toString(),
			language: "",
		});
		setTagLangVersions(tagsToAlter);
	};


	useEffect(() => {
		const isValid = validateInputs(inputs);
		const data = getInputData<ITag>(inputs, false);
		data.tagLanguageVersions = tagLangVersions != null ? tagLangVersions : [];
		onUpdate(data, isValid);
	}, [inputs, tagLangVersions, onUpdate]);

	let createInput = useCreateInput(inputs, setInputs);

	return (
		<Container>
			{loading ? (
				<Spinner />
			) : (
				<>
					<Heading>{t(ETranslation.COMMON_EDIT)}</Heading>
					{createInput(EInputs.name)}
					{createInput(EInputs.description)}
					<span>{t(ETranslation.TAGS_LANGUAGE_VERSION)}</span>
					<Button className={classes.languageVersionButton} onClick={addNewTagLang}>
						{t(ETranslation.COMMON_ADD_NEW)}
					</Button>
					<div className={classes.tagsLangVersionContainer}>
						{tagLangVersions?.map((tagLangVersion, id) => (
							<TagLanguageVersion
								key={tagLangVersion.id}
								tag={tagLangVersion}
								removeTag={() => {
									const tagsToAlter = [...tagLangVersions];
									const tagsForAlter = tagsToAlter.filter((tag) => tag !== tagLangVersion);
									setTagLangVersions(tagsForAlter);
								}}
								onUpdate={(data) => {
									const TagData = getInputData<ITagLanguageVersions>(data, false);
									const newTags = tagLangVersions.map((tag) => {
										if (tag.id === TagData.id) {
											return TagData;
										} else {
											return tag;
										}
									});
									setTagLangVersions(newTags);
								}}
							/>
						))}
					</div>
				</>
			)}
		</Container>
	);
};

export default Tag;
