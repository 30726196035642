import React from "react";
import { useTranslation } from "react-i18next";
import { IInterpretation } from "../../../interfaces/IInterpretation";
import { ETranslation } from "../../../translations/translation-keys";
import HeadingBox from "../../Heading/HeadingBox/HeadingBox";
import classes from "./OrderInterpretation.module.scss"

interface IProps {
  interpretation: IInterpretation | null;
}

const OrderInterpretationAvailableInterpreters: React.FC<IProps> = ({ interpretation }) => {
  const { t } = useTranslation();

  return (
    <>
      <HeadingBox>{t(ETranslation.INTERPRETATION_AVAILABLE_INTERPRETERS)}</HeadingBox>
      <div className={classes.Sticky}>
      </div>
    </>
  );
};

export default OrderInterpretationAvailableInterpreters;
