import React from 'react';

const CustomerReferenceHelper: React.FC = () => {

	return (
		<ul>
			<li>
				Hakee kaikilla sanoilla ja osittaisilla sanoilla, kun ei käytä hakusanoissa heittomerkkejä.
			</li>
			<li>
				Tarkan haun voi tehdä laittamalla hakusanat ""-merkkien sisään.
			</li>
			<li>
				Jos haluat hakea vain/myös tyhjät, lisää hakusana: customerReference:empty
			</li>
			<li>
				Jos haluat hakea vain/myös kaikki täytetyt, lisää hakusana: customerReference:has
			</li>
			<li>
				Jos haluat poissulkea sanoja, lisää hakusana: "customerReference:notContains Esimerkki"
			</li>
			<li>
				Tarkkoja hakuja voi yhdistellä. Esim. "private accommodation" "central accommodation" "customerReference:empty"
			</li>
			<li>
				Haku ei huomioi kirjainten kokoa.
			</li>
		</ul>
	)
}

export default CustomerReferenceHelper;