import { useCallback, useEffect, useState } from "react";
import { IInputField, IOption } from "../components/ui/Input/Input";


type TFunction = (idsList: string[]) => Promise<IOption[]>;

export const useInitSearchValues = (setInputs: React.Dispatch<React.SetStateAction<IInputField>>, inputName: string, func: TFunction) => {
	const [ids, setStateIds] = useState<string[] | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [multiple, setMultiple] = useState(true);

	useEffect(() => {
		if (ids) {
			(async () => {
				setIsLoading(true);
				const options = await func(ids);
				setIsLoading(false);
				setInputs((inputs) => {
					const newInputs = { ...inputs };
					newInputs[inputName].value = multiple ? options : options[0];
					return newInputs;
				})
			})();
		}
	}, [ids, func, inputName, setInputs, multiple]);

	const setId = useCallback((id: string) => {
		setMultiple(false);
		setStateIds([id]);
	}, [setMultiple, setStateIds])

	const setIds = useCallback((id: string[]) => {
		setStateIds(id);
	}, [setStateIds])

	return { setIds, setId, isLoading };
}