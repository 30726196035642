import React, { useContext, useEffect, useState } from "react";
import Container from "../../components/ui/Container/Container";
import Spinner from "../../components/ui/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import IAppState from "../../interfaces/store/IAppState";
import * as actions from "../../store/actions";
import UserEdit from "../../components/User/UserEdit";
import { IUser, User, newUserDummyAdmin, newUserDummyCustomer, newUserDummyInterpreter } from "../../classes/User";
import { RouteComponentProps } from "react-router-dom";
import ModalContext, { EModalSize } from "../../components/ui/Modal/ModalContext";
import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import UserComments from "./UserComments";
import { ERoute } from "../../classes/Routes";
import { removeUser } from "../../store/actions/userActions";
import { getOrder, getOrderClear } from "../../store/actions/ordersActions";

interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const UserEditPage: React.FC<IProps> = ({ match, history }) => {

	const dispatch = useDispatch();
	const { id } = match.params;
	const orderId = new URLSearchParams(window.location.search).get("orderId");
	const isAdd = id === "addCustomer" || id === "addInterpreter" || id === "addAdmin";

	const { user, loading, saveUserDone, saveUserLoading, order } = useSelector((state: IAppState) => ({
		user: state.user.user,
		loading: state.user.loading || state.orders.loading,
		saveUserDone: state.user.saveUserDone,
		saveUserLoading: state.user.saveUserLoading,
		order: state.orders.order,
	}));

	const [newUser, setNewUser] = useState<IUser | null>(null);

	const { setModal } = useContext(ModalContext);

	useEffect(()=>{
		if(!orderId) return;
		dispatch(getOrder(orderId));
		return ()=>{
			dispatch(getOrderClear());
		}
	}, [orderId, dispatch])

	const { t } = useTranslation();
	const openComments = (user: IUser, openModalOnClose: boolean) => {
		user ? setModal({
			title: "Käyttäjän " + user.firstName + " " + user.lastName + " " + t(ETranslation.COMMON_COMMENTS),
			content: <UserComments
				user={user}
			/>,
			isOpen: true,
			size: EModalSize.FULL,
		}) : console.error("No User Set");
	}

	useEffect(() => {
		if((orderId && isAdd)&& !order) return;
		if (isAdd) {
			const user = id === "addCustomer" ? new User(newUserDummyCustomer) : id === "addInterpreter" ? new User(newUserDummyInterpreter) : id === "addAdmin" ? new User(newUserDummyAdmin) : new User(newUserDummyInterpreter);
			if(order){
				user.firstName = order.customerFirstName;
				user.lastName = order.customerLastName;
				user.email = order.customerEmail;
				user.phoneNumber = order.customerPhoneNumber;
			}
			setNewUser(user)
			return
		} else {
			dispatch(actions.getUser(id));
		}
	}, [isAdd, dispatch, id, order, orderId]);

	useEffect(() => {
		if (saveUserDone) {
			const roleToBackTo = isAdd ? newUser?.role : user?.role;
			history.push(roleToBackTo === "ADMIN" ? ERoute.USERS_ADMINS_LIST : roleToBackTo === "INTERPRETER" ? ERoute.USERS_INTERPRETERS_LIST : ERoute.USERS_CUSTOMERS_LIST);
		}
		return () => {
			dispatch(actions.getUserClear())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saveUserDone])

	const saveUserCB = (user: IUser) => {
		dispatch(actions.saveUser(user, orderId))
	}

	const removeUserCb = () =>{
		if(!user?.id || isAdd) return;
		dispatch(removeUser(user.id))
	}

	const cancelHandel = () => {
		const roleToBackTo = isAdd ? newUser?.role : user?.role;
		history.push(roleToBackTo === "ADMIN" ? ERoute.USERS_ADMINS_LIST : roleToBackTo === "INTERPRETER" ? ERoute.USERS_INTERPRETERS_LIST : ERoute.USERS_CUSTOMERS_LIST);
		dispatch(actions.getUserClear())
	}

	return (loading ?
		<Spinner center /> :
		user ?
			<Container>
				<div>
					<UserEdit user={user} removeUser={removeUserCb} openComments={openComments} onCancel={cancelHandel} saveUser={saveUserCB} newUser={false} saveUserLoading={saveUserLoading} />
				</div>
			</Container> :
			newUser ?
				<UserEdit user={newUser} removeUser={removeUserCb} openComments={openComments} onCancel={cancelHandel} saveUser={saveUserCB} newUser={true} saveUserLoading={saveUserLoading} />
				: <Spinner center />
	)
}

export default UserEditPage;