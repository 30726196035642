import React from 'react';
import Button, { EButtonColor } from '../../../ui/Button/Button';
import classes from '../InterpretationsSearch.module.scss';
import { IInputField } from '../../../ui/Input/Input';
import InputGroup from '../../../ui/InputGroup/InputGroup';
import { EInterpretationSearchInputs } from '../InterpretationsSearch';
import * as dateFns from 'date-fns';


interface IProps {
	setInputs: React.Dispatch<React.SetStateAction<IInputField>>
	inputs: IInputField;
}

const SearchMonthSelector: React.FC<IProps> = ({ setInputs, inputs }) => {

	/**
	 *
	 * @param month number of month, 0 based
	 */
	const setMonth = (month: number) => {
		setInputs({
			...inputs,
			[EInterpretationSearchInputs.endDate]: {
				...inputs.endDate,
				value: dateFns.format(dateFns.lastDayOfMonth(dateFns.setMonth(new Date(), month)), 'yyyy-MM-dd'),
			},
			[EInterpretationSearchInputs.startDate]: {
				...inputs.startDate,
				value: dateFns.format(dateFns.setMonth(new Date(), month), 'yyyy-MM-01'),
			},
			[EInterpretationSearchInputs.startTime]: {
				...inputs.startTime,
				value: "00:00",
			},
			[EInterpretationSearchInputs.endTime]: {
				...inputs.endTime,
				value: "23:59",
			},
		});
	}

	return (
		<InputGroup className={classes.sectionNoTitle}>
			<Button className={classes.monthSetMargin} color={EButtonColor.WHITE} onClick={() => setMonth(0)}>1</Button>
			<Button className={classes.monthSetMargin} color={EButtonColor.WHITE} onClick={() => setMonth(1)}>2</Button>
			<Button className={classes.monthSetMargin} color={EButtonColor.WHITE} onClick={() => setMonth(2)}>3</Button>
			<Button className={classes.monthSetMargin} color={EButtonColor.WHITE} onClick={() => setMonth(3)}>4</Button>
			<Button className={classes.monthSetMargin} color={EButtonColor.WHITE} onClick={() => setMonth(4)}>5</Button>
			<Button className={classes.monthSetMargin} color={EButtonColor.WHITE} onClick={() => setMonth(5)}>6</Button>
			<Button className={classes.monthSetMargin} color={EButtonColor.WHITE} onClick={() => setMonth(6)}>7</Button>
			<Button className={classes.monthSetMargin} color={EButtonColor.WHITE} onClick={() => setMonth(7)}>8</Button>
			<Button className={classes.monthSetMargin} color={EButtonColor.WHITE} onClick={() => setMonth(8)}>9</Button>
			<Button className={classes.monthSetMargin} color={EButtonColor.WHITE} onClick={() => setMonth(9)}>10</Button>
			<Button className={classes.monthSetMargin} color={EButtonColor.WHITE} onClick={() => setMonth(10)}>11</Button>
			<Button className={classes.monthSetMargin} color={EButtonColor.WHITE} onClick={() => setMonth(11)}>12</Button>
		</InputGroup>
	)
}

export default SearchMonthSelector;