import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';

import Alert from '../../ui/Alert/Alert';
import Button, { EButtonColor } from '../../ui/Button/Button';
import ModalBody from '../../ui/Modal/ModalBody/ModalBody';
import ModalContext from '../../ui/Modal/ModalContext';
import classes from './OrderConfirmedModal.module.scss'

interface IProps {
  error?: string | null;
}



const OrderConfirmedModal: React.FC<IProps> = ({
  error,
}) => {
  const { closeModal } = useContext(ModalContext);
  const { t } = useTranslation();
  return (
    <>
      <div>
        <ModalBody>
          {error && <Alert>{error}</Alert>}
          <div className={classes.AlignSelfCenter}>
              <div className={classes.Check}><FontAwesomeIcon icon={faCheck}/></div>
              <div>{t(ETranslation.MODAL_ORDER_CONFIRMED)}</div>
              <div>{t(ETranslation.MODAL_ORDER_RECEIVED)}</div>
              <div>{t(ETranslation.MODAL_ORDER_TRACK)}</div>
              <Button onClick={closeModal} color={EButtonColor.SUCCESS}>
                {t(ETranslation.COMMON_RETURN)}
              </Button>
          </div>
        </ModalBody>
      </div>
    </>
  );
};

export default OrderConfirmedModal;
