import { Dispatch } from 'redux';

import EActionTypes from '../../interfaces/store/EActionTypes';
import { EFetchMethod, customFetchWithResponse } from '../../custom-fetch';
import ICommissionAction from '../../interfaces/store/ICommissionAction';
import ICommission from '../../interfaces/ICommission';

type TAction = ICommissionAction;


const getCommissionsStart = (): TAction => {
	return {
		type: EActionTypes.COMMISSION_GET_LIST_START
	};
};

const getCommissionStart = (): TAction =>{
	return {
		type: EActionTypes.COMMISSION_GET_COMMISSION_START
	}
}

const getCommissionSuccess = (commission: ICommission): TAction =>{
	return {
		type: EActionTypes.COMMISSION_GET_COMMISSION_SUCCESS,
		commission: commission,
	};
}

const getCommissionListSuccess = (commissions: ICommission[], paginationCursor: string): TAction => {
	return {
		type: EActionTypes.COMMISSION_GET_LIST_SUCCESS,
		paginationCursor: paginationCursor,
		commissions
	};
};

const getCommissionFail = (error: string): TAction =>{
	return {
		type: EActionTypes.COMMISSION_GET_COMMISSION_FAIL,
		error
	}
}

const getCommissionsNoPagination = (): TAction => {
	return {
		type: EActionTypes.COMMISSION_GET_LIST_NO_PAGINATION,
		hasMoreCommissions: false,
	};
};


const getCommissionsFail = (error: string): TAction => {
	return {
		type: EActionTypes.COMMISSION_GET_LIST_FAIL,
		error
	};
};

export const getCommissionsClear = (): TAction => {
	return {
		type: EActionTypes.COMMISSION_GET_LIST_CLEAR,
	};
};

export interface ICommissionSeachParams {
	type: string | null;
	userId: string | null;
	startDate?: string;
	endDate?: string
	organization?: string;
	fetchSize?: number;
	paginationCursor?: string;
	start: number;
	dictSearch: boolean;
}

const saveCommissionStart = (): TAction => {
	return {
		type: EActionTypes.COMMISSION_SAVE_START,
	};
};

const saveCommissionDone = (): TAction => {
	return {
		type: EActionTypes.COMMISSION_SAVE_DONE,
	};
};

const saveCommissionError = (error: string): TAction => {
	return {
		type: EActionTypes.COMMISSION_SAVE_ERROR,
		commissionSaveError: error,
	};
};

export const saveCommissionClear = (): TAction => {
	return {
		type: EActionTypes.COMMISSION_SAVE_CLEAR,
	};
};

export const saveCommission = (commission: ICommission) =>{
	return async (dispatch: Dispatch) => {
		dispatch(saveCommissionStart())
		let url = "/commissions/add";
		try {
			 await customFetchWithResponse(url, EFetchMethod.POST, JSON.stringify(commission));
			dispatch(saveCommissionDone());
		} catch (error) {
			dispatch(saveCommissionError((error as Error).message));
		}
	};
}


export const getCommission = (commissionId: string) =>{
	return async (dispatch: Dispatch) => {
		dispatch(getCommissionStart())
		try {
			const res = await customFetchWithResponse("/commissions/get?id=" + commissionId, EFetchMethod.GET);
			const json = await res.json();
			dispatch(getCommissionSuccess(json));
		} catch (error) {
			dispatch(getCommissionFail((error as Error).message));
		}

	}
}

export const searchCommissions = (params: ICommissionSeachParams) => {
	return async (dispatch: Dispatch) => {
		dispatch(getCommissionsStart())

		let url = "/commissions/list";

		let fetchBody = {
			start: params.start,
			fetchSize: params.fetchSize,
			startDate: params.startDate,
			user: params.userId,
			type: params.type,
			dictSearch: params.dictSearch,
		};
		if(params.userId != null){
			getCommissionsNoPagination();
		}
		try {
			const res = await customFetchWithResponse(url + "?data=" + JSON.stringify(fetchBody), EFetchMethod.GET);
			const paginationCursor = res.headers.get("Pagination-Cursor") ?? "";
			const json = await res.json();
			if(json.data.length < 1){
				dispatch(getCommissionsFail("No results for this user"));
			} else {
				dispatch(getCommissionListSuccess(json.data, paginationCursor));
			}
		} catch (error) {
			dispatch(getCommissionsFail((error as Error).message));
		}
	};
};

