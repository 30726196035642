import { Dispatch } from 'redux';

import { customFetch, EFetchMethod } from '../../custom-fetch';
import { ITextMessage } from '../../interfaces/ITextMessage';
import EActionTypes from '../../interfaces/store/EActionTypes';
import ITextMessageAction from '../../interfaces/store/ITextMessageAction';

type TAction = ITextMessageAction;

const findTextMessagesStart = (): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_FIND_START,
  };
};

const findTextMessagesSuccess = (textMessages: ITextMessage[]): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_FIND_SUCCESS,
    textMessages,
  };
};

const findTextMessagesFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_FIND_FAIL,
    error,
  };
};

export const findTextMessages = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(findTextMessagesStart());
      const TextMessages = await customFetch<ITextMessage[]>("/textmessages/list");
      dispatch(findTextMessagesSuccess(TextMessages));
    } catch (error) {
      dispatch(findTextMessagesFail((error as Error).message));
    }
  };
};

const getTextMessageStart = (id: string): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_GET_START,
    id
  };
};

const getTextMessageSuccess = (textMessage: ITextMessage): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_GET_SUCCESS,
    textMessage,
  };
};

const getTextMessageFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_GET_FAIL,
    error,
  };
};

export const getTextMessageClear = (): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_GET_CLEAR,
  };
};

export const getTextMessage = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getTextMessageStart(id));
      const TextMessage = await customFetch<ITextMessage>(`/textmessages/get?id=${id}`);
      dispatch(getTextMessageSuccess(TextMessage));
    } catch (error) {
      dispatch(getTextMessageFail((error as Error).message));
    }
  };
};

const deleteTextMessageStart = (): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_DELETE_START,
  };
};

const deleteTextMessageSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_DELETE_SUCCESS,
    id,
  };
};

const deleteTextMessageFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_DELETE_FAIL,
    error,
  };
};

export const deleteTextMessageClear = (): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_DELETE_CLEAR,
  };
};

export const deleteTextMessage = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deleteTextMessageStart());
      await customFetch<boolean>(`/textmessages/delete?id=${id}`, EFetchMethod.DELETE);
      dispatch(deleteTextMessageSuccess(id));
    } catch (error) {
      dispatch(deleteTextMessageFail((error as Error).message));
    }
  };
};

const saveOrUpdateTextMessageStart = (): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_SAVE_OR_UPDATE_START,
  };
};

const saveOrUpdateTextMessageSuccess = (): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_SAVE_OR_UPDATE_SUCCESS,
  };
};

const saveOrUpdateTextMessageFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_SAVE_OR_UPDATE_FAIL,
    error,
  };
};

export const saveOrUpdateTextMessageClear = (): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_SAVE_OR_UPDATE_CLEAR
  }
}

export const saveTextMessage = (textMessage: ITextMessage) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveOrUpdateTextMessageStart());
      await customFetch<ITextMessage>("/textmessages/add", EFetchMethod.POST, JSON.stringify(textMessage));
      dispatch(saveOrUpdateTextMessageSuccess());
    } catch (error) {
      dispatch(saveOrUpdateTextMessageFail((error as Error).message));
    }
  };
};

export const updateTextMessage = (textMessage: ITextMessage) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveOrUpdateTextMessageStart());
      await customFetch<ITextMessage>("/textmessages/update", EFetchMethod.PUT, JSON.stringify(textMessage));
      dispatch(saveOrUpdateTextMessageSuccess());
    } catch (error) {
      dispatch(saveOrUpdateTextMessageFail((error as Error).message));
    }
  };
};

const sendTextMessageStart = (id: string): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_SEND_START,
    id
  };
};

const sendTextMessageSuccess = (textMessage: ITextMessage): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_SEND_SUCCESS,
    textMessage,
  };
};

const sendTextMessageFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_SEND_FAIL,
    error,
  };
};

export const sendTextMessageClear = (): TAction => {
  return {
    type: EActionTypes.TEXT_MESSAGE_SEND_CLEAR,
  };
};

export const sendTextMessage = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(sendTextMessageStart(id));
      const TextMessage = await customFetch<ITextMessage>(`/textmessages/send?id=${id}`);
      dispatch(sendTextMessageSuccess(TextMessage));
    } catch (error) {
      dispatch(sendTextMessageFail((error as Error).message));
    }
  };
};