import React, { useState } from 'react';
import Container from '../../components/ui/Container/Container';
import { IInputField, EInputType } from '../../components/ui/Input/Input';
import { getInputData } from '../../components/ui/Input/input-utils';
import Heading from '../../components/Heading/Heading';
import Alert from '../../components/ui/Alert/Alert';
import Button from '../../components/ui/Button/Button';
import { ETranslation } from '../../translations/translation-keys';
import { useTranslation } from 'react-i18next';
import { useCreateInput } from '../../hooks/useCreateInput';


enum EInputs {
  attachments = 'attachments'
}

const FileUploadPage:React.FC = () => {
  const { t } = useTranslation();

  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.attachments]: {
      type: EInputType.dropzone,
      labelTranslation: ETranslation.PAGES_FILEUPLOAD_FILES,
      value: [],
    },
  });

  const createInput = useCreateInput(inputs, setInputs);

  const clickHandler = () => {
    console.log(getInputData(inputs));
  }

  return (
    <Container>
      <Heading>{t(ETranslation.PAGES_FILEUPLOAD_UPLOAD_DEMO)}</Heading>
      <Alert>{t(ETranslation.PAGES_FILEUPLOAD_DROPZONE_ALERT)}</Alert>
      {createInput(EInputs.attachments)}

      <Button onClick={clickHandler}>Console Log</Button>
    </Container>
  )
}

export default FileUploadPage;