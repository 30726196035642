import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute, Routes } from '../../classes/Routes';
import Container from '../../components/ui/Container/Container';
import OrganizationList from '../../components/Organization/OrganizationList/OrganizationList';
import Heading from '../../components/Heading/Heading';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../translations/translation-keys';
import { useOrganizationPagination } from '../../hooks/useOrganizationPagination';
import { useDispatch } from 'react-redux';
import { findOrganizationsClear } from '../../store/actions/organizationActions';
import OrganizationsSearch from './OrganizationsSearch';
import AddNewBtn from '../../components/User/AddNewUserBtn';

interface IProps extends RouteComponentProps { }

const OrganizationsPage: React.FC<IProps> = ({ history, location, match }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { loaderRef, organizations, organizationsLoading, organizationsHasMore, organizationsError, searchHandler } = useOrganizationPagination();

	const openHandler = (id?: string) => {
		if (!id) return;
		dispatch(findOrganizationsClear());
		history.push(Routes.withParams(ERoute.ORGANIZATION_EDIT, { id: id }));
	};

	return (
		<Container>
			<Heading>{t(ETranslation.MENU_ORGANIZATION_COMPANIES)}</Heading>
			<AddNewBtn history={history} location={location} match={match} route={ERoute.ORGANIZATION_EDIT} id={"add"} />
			<OrganizationsSearch searchHandler={searchHandler} loading={organizationsLoading} />
			<>
				<OrganizationList
					organizations={organizations}
					loading={organizationsLoading}
					error={organizationsError}
					onClick={openHandler}
				/>
				<p
					ref={loaderRef}
					style={{ color: organizationsHasMore ? "#ccc" : "#fff" }}
				>
					{organizationsHasMore ? "Näytä lisää rivejä..." : ""}
				</p>
			</>
		</Container>
	);
};

export default OrganizationsPage;
