import { Dispatch } from 'redux';

import { customFetch } from '../../custom-fetch';

import EActionTypes from '../../interfaces/store/EActionTypes';

//new
import { ISendGridEmail } from '../../interfaces/ISendGridEmail';
import ISendGridEmailAction from '../../interfaces/store/ISendGridEmailAction';


type TAction = ISendGridEmailAction;

const findSendGridEmailsStart = (): TAction => {
  return {
    type: EActionTypes.SENDGRIDEMAILS_FIND_START,
  };
};

//modified
const findSendGridEmailsSuccess = (sendGridEmails: ISendGridEmail[]): TAction => {
  return {
    type: EActionTypes.SENDGRIDEMAILS_FIND_SUCCESS,
    sendGridEmails,
  };
};

const findSendGridEmailsFail = (error: string): TAction => {
  return {
    type: EActionTypes.SENDGRIDEMAILS_FIND_FAIL,
    error,
  };
};

export const findSendGridEmails = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(findSendGridEmailsStart());
      const sendGridEmails = await customFetch<ISendGridEmail[]>("/sendgridemails");
      dispatch(findSendGridEmailsSuccess(sendGridEmails));
    } catch (error) {
      dispatch(findSendGridEmailsFail((error as Error).message));
    }
  };
};

/**
const getSiteStart = (id: string): TAction => {
  return {
    type: EActionTypes.SITE_GET_START,
    id
  };
};

const getSiteSuccess = (site: ISite): TAction => {
  return {
    type: EActionTypes.SITE_GET_SUCCESS,
    site,
  };
};

const getSiteFail = (error: string): TAction => {
  return {
    type: EActionTypes.SITE_GET_FAIL,
    error,
  };
};

export const getSiteClear = (): TAction => {
  return {
    type: EActionTypes.SITE_GET_CLEAR,
  };
};

export const getSite = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getSiteStart(id));
      const site = await customFetch<ISite>(`/sites/get?id=${id}`);
      dispatch(getSiteSuccess(site));
    } catch (error) {
      dispatch(getSiteFail((error as Error).message));
    }
  };
};

const deleteSiteStart = (): TAction => {
  return {
    type: EActionTypes.SITE_DELETE_START,
  };
};

const deleteSiteSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.SITE_DELETE_SUCCESS,
    id,
  };
};

const deleteSiteFail = (error: string): TAction => {
  return {
    type: EActionTypes.SITE_DELETE_FAIL,
    error,
  };
};

export const deleteSiteClear = (): TAction => {
  return {
    type: EActionTypes.SITE_DELETE_CLEAR,
  };
};

export const deleteSite = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deleteSiteStart());
      await customFetch<boolean>(`/sites/delete?id=${id}`, EFetchMethod.DELETE);
      dispatch(deleteSiteSuccess(id));
    } catch (error) {
      dispatch(deleteSiteFail((error as Error).message));
    }
  };
};

const saveOrUpdateSiteStart = (): TAction => {
  return {
    type: EActionTypes.SITE_SAVE_OR_UPDATE_START,
  };
};

const saveOrUpdateSiteSuccess = (): TAction => {
  return {
    type: EActionTypes.SITE_SAVE_OR_UPDATE_SUCCESS,
  };
};

const saveOrUpdateSiteFail = (error: string): TAction => {
  return {
    type: EActionTypes.SITE_SAVE_OR_UPDATE_FAIL,
    error,
  };
};

export const saveOrUpdateSiteClear = (): TAction => {
  return {
    type: EActionTypes.SITE_SAVE_OR_UPDATE_CLEAR
  }
}

export const saveSite = (site: ISite) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveOrUpdateSiteStart());
      await customFetch<ISite>("/sites/add", EFetchMethod.POST, JSON.stringify(site));
      dispatch(saveOrUpdateSiteSuccess());
    } catch (error) {
      dispatch(saveOrUpdateSiteFail((error as Error).message));
    }
  };
};

export const updateSite = (site: ISite) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveOrUpdateSiteStart());
      await customFetch<ISite>("/sites/update", EFetchMethod.PUT, JSON.stringify(site));
      dispatch(saveOrUpdateSiteSuccess());
    } catch (error) {
      dispatch(saveOrUpdateSiteFail((error as Error).message));
    }
  };
};
*/


