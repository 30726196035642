import { TFunction } from 'i18next';
import { IInterpretation } from "../interfaces/IInterpretation";
import { EInterpretationType } from "../shared/interpretation-data";

import * as dateFns from 'date-fns';
import * as dateUtils from "./date-utils";
import * as durationUtils from './duration-utils';

export const INTERPRETATION_SUBSTITUTE_TYPES = [
	EInterpretationType.SUBSTITUTE_ON_SITE,
];

export const isSubstituteType = (type?: EInterpretationType) => {
	return !!type && INTERPRETATION_SUBSTITUTE_TYPES.includes(type);
}


export const formatDuration = (t: TFunction, type: EInterpretationType, mins?: number) => {
	if (isSubstituteType(type)) {
		if (mins) {
			// Tässä kyl varmaan bugi jos subistution kesto onkin pienempi
			const days = Math.floor((mins + 1) / (60 * 24));
			return days + "pv";
		}
		return "0";
	}
	return durationUtils.minutesToStrings(t, mins);
}

export const formatDate = (interpretation: IInterpretation) => {
	const { type, startDateUTC, endDateUTC, bookingStartDateUTC, bookingEndDateUTC } = interpretation;
	const startDate = type === EInterpretationType.INSTANT ? startDateUTC : bookingStartDateUTC;
	const endDate = type === EInterpretationType.INSTANT ? endDateUTC : bookingEndDateUTC;

	if (isSubstituteType(type)) {
		return [dateUtils.formatDate(startDate), dateUtils.formatDate(endDate)].join(' - ');
	}

	if (startDate) {
		let end: string | null = "";
		if (endDate) {
			end = dateFns.isSameDay(new Date(startDate), new Date(endDate)) ? dateUtils.formatTime(endDate) : dateUtils.formatDateTime(endDate);
		}
		return [dateUtils.formatDateTime(startDate), end].join(" - ").trim();
	}
	return "?? - ??";
}