import React, { useEffect, useState } from 'react';
import { useCreateInput } from '../../../../hooks/useCreateInput';

import { IPriceSeasonRuleSettings } from '../../../../interfaces/IPriceSeasonRule';
import { PRICE_SEASON_RULE_TERMS_OF_CANCEL, EPriceSeasonRuleChargeType, PRICE_SEASON_RULE_CHARGE_TYPES } from '../../../../shared/price-season-data';
import { ETranslation } from '../../../../translations/translation-keys';
import { EInputType, IInputField } from '../../../ui/Input/Input';
import { getInputData, initForm } from '../../../ui/Input/input-utils';
import InputGroup from '../../../ui/InputGroup/InputGroup';
import { EInterpretationType } from '../../../../shared/interpretation-data';

enum EInputs {
  charge = "charge",
  minBillingPeriodInMinutes = "minBillingPeriodInMinutes",
  minRoundingPeriodInMinutes = "minRoundingPeriodInMinutes",
  termsOfCancel = "termsOfCancel",
  durationMinInMinutes = "durationMinInMinutes",
  durationMaxInMinutes = "durationMaxInMinutes",
  minutesBeforeStart = "minutesBeforeStart",
}

interface IProps {
  type: EInterpretationType;
  onChange: (type: EInterpretationType, settings: IPriceSeasonRuleSettings) => void;
  settings?: IPriceSeasonRuleSettings;
  loading?: boolean;
  chargeType?: EPriceSeasonRuleChargeType;
}

const PriceSeasonRuleSettings: React.FC<IProps> = ({ type, onChange, settings, loading, chargeType}) => {
  const [canInit, setCanInit] = useState(true);
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.charge]: {
      type: EInputType.number,
      labelTranslation: ETranslation.PRICE_SEASON_CHARGE,
      value: "",
    },
    [EInputs.minBillingPeriodInMinutes]: {
      type: EInputType.number,
      labelTranslation: ETranslation.PRICE_SEASON_MIN_BILLING_PERIOD,
      value: "",
    },
    [EInputs.minRoundingPeriodInMinutes]: {
      type: EInputType.number,
      labelTranslation: ETranslation.PRICE_SEASON_MIN_ROUNDING_PERIOD,
      value: "",
    },
    [EInputs.termsOfCancel]: {
      type: EInputType.select,
      labelTranslation: ETranslation.PRICE_SEASON_TERMS_OF_CANCEL,
      value: "",
      options: [
        { value: "", labelTranslation: ETranslation.PRICE_SEASON_CHOOSE_TERMS_OF_CANCEL },
        ...PRICE_SEASON_RULE_TERMS_OF_CANCEL,
      ],
    },
    [EInputs.durationMinInMinutes]: {
      type: EInputType.number,
      labelTranslation: ETranslation.PRICE_SEASON_MIN_DURATION,
      value: "",
    },
    [EInputs.durationMaxInMinutes]: {
      type: EInputType.number,
      labelTranslation: ETranslation.PRICE_SEASON_MAX_DURATION,
      value: "",
    },
    [EInputs.minutesBeforeStart]: {
      type: EInputType.number,
      labelTranslation: ETranslation.PRICE_SEASON_MINUTES_BEFORE_START,
      value: "",
    },
  });

  useEffect(() => {
    if(chargeType) {
      const chargeLabel = PRICE_SEASON_RULE_CHARGE_TYPES.find(item => item.value === chargeType)?.label;
      setInputs(inputs => {
        return {
          ...inputs,
          [EInputs.charge]: {
            ...inputs[EInputs.charge],
            label: chargeLabel
          }
        }
      });
    }
  }, [chargeType]);

  useEffect(() => {
    if(settings && canInit) {
      initForm(setInputs, settings);
      setCanInit(false);
    }
  }, [settings, canInit]);

  useEffect(() => {
    const settings = getInputData<IPriceSeasonRuleSettings>(inputs);
    onChange(type, settings);
  }, [inputs, onChange, type]);

  const createInput = useCreateInput(inputs, setInputs, {
    disabled: loading
  });

  return (
    <>
      <InputGroup>
        {createInput(EInputs.charge)}
        {createInput(EInputs.minBillingPeriodInMinutes)}
        {createInput(EInputs.minRoundingPeriodInMinutes)}
        {createInput(EInputs.durationMinInMinutes)}
        {createInput(EInputs.durationMaxInMinutes)}
        {createInput(EInputs.minutesBeforeStart)}
      </InputGroup>
      {createInput(EInputs.termsOfCancel)}
    </>
  );
};

export default PriceSeasonRuleSettings;
