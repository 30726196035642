import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { ERoute } from "../../classes/Routes";
import Heading from "../../components/Heading/Heading";
import Alert from "../../components/ui/Alert/Alert";
import Container from "../../components/ui/Container/Container";
import EditButtons from "../../components/ui/EditButtons/EditButtons";
import Spinner from "../../components/ui/Spinner/Spinner";
import WorkingHoursEdit, { IWorkingHoursEventsHandle } from "../../components/WorkingHours/WorkingHoursEdit";
import { IWorkingHoursDate } from "../../interfaces/IWorkingHoursDate";
import IAppState from "../../interfaces/store/IAppState";
import IUserState from "../../interfaces/store/IUserState";
import IWorkingHoursState from "../../interfaces/store/IWorkingHoursState";
import * as actions from "../../store/actions";
import { formatDate } from "../../utils/date-utils";

interface IMatch {
	userId: string;
	date: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const WorkingHoursEditPage: React.FC<IProps> = ({ history, match }) => {
	const eventsRef = useRef<IWorkingHoursEventsHandle>(null);
	const [showValidation, setShowValidation] = useState(false);

	const dispatch = useDispatch();

	const { userId, date } = match.params;

	const { t } = useTranslation();

	useEffect(() => {
		dispatch(actions.getWorkingHoursDate(date, userId));
		dispatch(actions.getUser(userId));
		dispatch(actions.getValidUserContractSettings(date, userId));
	}, [userId, date, dispatch]);

	const { error, loading, workingHoursDate, saveOrUpdateOk, deletingError, deleting, deletingOk } = useSelector<
		IAppState,
		IWorkingHoursState
	>((state) => state.workingHours);

	const id = workingHoursDate?.id;

	const {
		user,
		loading: userLoading,
		contractSettings,
		contractSettingsLoading,
	} = useSelector<IAppState, IUserState>((state) => state.user);

	useEffect(() => {
		if (saveOrUpdateOk) {
			history.push(ERoute.WORKING_HOURS_LIST_PAGE);
		}
		return () => {
			dispatch(actions.saveOrUpdateWorkingHoursDateClear());
			dispatch(actions.getWorkingHoursDateClear());
		};
	}, [dispatch, saveOrUpdateOk, history]);

	const submitHandler = useCallback(() => {
		const events = eventsRef.current?.getEvents() || [];
		if (eventsRef.current?.isValid() && events.length > 0) {
			const workingHoursDate: IWorkingHoursDate = {
				id: id ?? "",
				date,
				events,
				userId,
				userName: "",
			};
			if (id) {
				dispatch(actions.updateWorkingHoursDate(workingHoursDate));
			} else {
				dispatch(actions.saveWorkingHoursDate(workingHoursDate));
			}
		} else {
			setShowValidation(true);
		}
	}, [id, userId, date, dispatch]);

	const deleteHandler = () => {
		if (id) {
			dispatch(actions.deleteWorkingHoursDate(id));
		}
	};

	return (
		<Container>
			{error && <Alert>{error}</Alert>}

			{loading || userLoading || contractSettingsLoading ? (
				<Spinner />
			) : (
				<>
					<Heading>
						Työajat {user?.name} {formatDate(date)}
					</Heading>
					{error && <Alert>{error}</Alert>}
					<WorkingHoursEdit
						date={date}
						workingHoursDate={workingHoursDate}
						contractSettings={contractSettings}
						showValidation={showValidation}
						ref={eventsRef}
					/>
					<EditButtons
						onSave={submitHandler}
						disabled={false}
						loading={loading}
						onCancel={() => history.goBack()}
						onDelete={deleteHandler}
						deleteText={t("Haluatko varmasti poistaa työajan?")}
						onDeleteClose={() => dispatch(actions.deleteWorkingHoursDateClear())}
						onDeleteDone={() => history.push(ERoute.WORKING_HOURS_LIST_PAGE)}
						deleteError={deletingError}
						deleteLoading={deleting}
						deleteOk={deletingOk}
						isAdd={!id}
					/>
				</>
			)}
		</Container>
	);
};

export default WorkingHoursEditPage;
