import { faInfoCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { useConfirmModal } from "../../hooks/useConfirmModal";
import { useSingleOptionLabel } from "../../hooks/useSingleOptionLabel";
import { IUserShiftDateItem } from "../../interfaces/IUserShiftDateItem";
import { WORKING_HOURS_EVENT_TYPE_OPTIONS } from "../../shared/working-hours-data";
import { formatTime } from "../../utils/date-utils";
import { getWorkingHoursEventBadgeType, isWorkingHoursEventHoursType } from "../../utils/working-hours-utils";
import Badge from "../ui/Badge/Badge";
import classes from "./UserShiftDateItem.module.scss";

interface IProps {
	item: IUserShiftDateItem;
	onDelete: (id: string) => void;
}

const UserShiftDateItem: React.FC<IProps> = ({ item, onDelete }) => {
	const openConfirmModal = useConfirmModal();

	const { hours, type, startTime, endTime, description, details } = item;

	const typeText = useSingleOptionLabel(WORKING_HOURS_EVENT_TYPE_OPTIONS, type);
	// const openUserShiftDateItemModal = useUserShiftDateItemModal();

	/*
	const editShiftHandler = async () => {
		const items = await openUserShiftDateItemModal(day.items);
		onChange({
			date: day.date,
			items: items,
		});
	};
	*/

	const deleteHandler = async (e: React.MouseEvent) => {
		e.stopPropagation();
		const success = await openConfirmModal(`Haluatko varmati poistaa työvuoron?`);
		if (success) {
			onDelete(item.id);
		}
	};

	const badgeType = getWorkingHoursEventBadgeType(item.type);



	return (
		<div className={classes.Container} onClick={(e) => e.stopPropagation()}>
			<Badge type={badgeType} className={classes.Badge}>
				<small>{typeText}{details?.salaryPerHour ? ` ${details.salaryPerHour.toFixed(2)} € / h` : ''}</small>
				<div style={{ display: 'flex', gap: '.5rem' }}>
					{isWorkingHoursEventHoursType(type) ? (
						`${hours} TUNTIA`
					) : (
						<>
							{formatTime(startTime)} - {formatTime(endTime)}
						</>
					)}
					{description && <FontAwesomeIcon icon={faInfoCircle} title={description} />}
				</div>
			</Badge>
			<FontAwesomeIcon className={classes.Delete} icon={faTrash} onClick={deleteHandler} />
		</div>
	);
};

export default UserShiftDateItem;
