import React, { useEffect } from "react";
import Container from "../../components/ui/Container/Container";
import Heading from "../../components/Heading/Heading";
import { useDispatch, useSelector } from "react-redux";
import { ITag, getTags } from "../../store/actions/tagsActions";
import IAppState from "../../interfaces/store/IAppState";
import Button from "../../components/ui/Button/Button";
import { RouteComponentProps } from "react-router-dom";
import { ERoute, Routes } from "../../classes/Routes";
import TagsList from "../../components/Tags/TagsList";
import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";

interface IProps extends RouteComponentProps {}

const TagsPage: React.FC<IProps> = ({history}) => {

	const dispatch = useDispatch();
	const {t} = useTranslation();

	const {
		tags,
		error,
		loading,
	} = useSelector((state: IAppState) => state.tags);

	useEffect(() => {
		dispatch(getTags());
	}, [dispatch])

	const openTag = (tag: ITag) =>{
		history.push(Routes.withParams(ERoute.TAG_EDIT, { id:tag.id}));
	}

	const newTag = ()=>{
		history.push(Routes.withParams(ERoute.TAG_EDIT, { id:"add" }));
	}

	return (
		<Container>
			<Heading>{t(ETranslation.MENU_TAGS)}</Heading> <Button onClick={newTag}>{t(ETranslation.COMMON_ADD_NEW)}</Button>
			<TagsList tags={tags} error={error} loading={loading} openTag={openTag} />
		</Container>
	)
}

export default TagsPage;