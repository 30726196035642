import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IOption } from '../components/ui/Input/Input';
import IAppState from '../interfaces/store/IAppState';
import ICategoryState from '../interfaces/store/ICategoryState';
import * as actions from '../store/actions';
import { createOptions } from '../utils/option-utils';

export const useCategoryOptions = () => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<IOption[]>([]);

  const { categories, loading } = useSelector<IAppState, ICategoryState>(state => state.category);

  useEffect(() => {
    if (categories) {
      setOptions(createOptions(categories));
    } else {
      dispatch(actions.findCategorys());
    }
  }, [categories, dispatch]);

  return { categoryOptions: options, categoriesLoading: loading };
}