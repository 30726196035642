import React from 'react';

import Button from '../ui/Button/Button';
import { ISalaryInfo } from '../../interfaces/ISalaryInfo';
import SalaryInfosAttachList from './SalaryInfosAttachList';

interface IProps {
	salaryInfos: ISalaryInfo[];
	onAdd: () => void;
	onDelete: (id: string) => void;
	onEdit: (salaryInfo: ISalaryInfo) => void;
}

const SalaryInfoAttach: React.FC<IProps> = ({ salaryInfos, onAdd, onDelete, onEdit,  }) => {
	return (
		<>
			<div style={{ marginBottom: "1rem" }}>
				<Button onClick={onAdd}>Lisää palkka</Button>
				<SalaryInfosAttachList salaryInfos={salaryInfos} onEdit={onEdit} onDelete={onDelete} />
			</div>
		</>
	);
};

export default SalaryInfoAttach;