import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import Container from "../../components/ui/Container/Container";
import Heading from "../../components/Heading/Heading";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../translations/translation-keys";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions";
import ICommission from "../../interfaces/ICommission";
import IAppState from "../../interfaces/store/IAppState";
import Spinner from "../../components/ui/Spinner/Spinner";
import Commission from "../../components/Commissions/Commission";
import EditButtons from "../../components/ui/EditButtons/EditButtons";
import { ERoute, Routes } from "../../classes/Routes";
import { saveCommissionClear } from "../../store/actions/commissionActions";

interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

const CommissionPage: React.FC<IProps> = ({ history, match }) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const { id } = match.params;
	const isAdd = id === "add";

	// TODO(joonas): THIS IS UNIMPLEMENTED,
	const deleteCommission = () => {
		
	};

	const { loading, commission, commissionSaveLoading, commissionSaveDone } = useSelector(
		(state: IAppState) => state.commissions
	);

	const [selectedCommission, setSelectedCommission] = useState<ICommission>()

	// Once displayed, fetch organizationGroups
	useEffect(() => {
		if (isAdd) return
		dispatch(actions.getCommission(id));
	}, [id, dispatch, isAdd]);

	useEffect(() => {
		if (!commission) { return }
		setSelectedCommission(commission);
	}, [commission]);

	const updateCommission = useCallback((updated: ICommission, valid: boolean) => {
		setSelectedCommission(updated);
	}, []);

	const onSave = () => {
		if (!selectedCommission) { return }
		dispatch(actions.saveCommission(selectedCommission))
	}

	useEffect(() => {
		if (commissionSaveDone) {
			history.push(Routes.withParams(ERoute.COMMISSIONS_LIST, {}));
			dispatch(saveCommissionClear());
		}
	}, [commissionSaveDone, dispatch, history]);

	return (
		<Container>
			<Heading>{t(ETranslation.COMMISSION_GENERAL)}</Heading>
			{(loading && !selectedCommission) || commissionSaveLoading ? (
				<Spinner />
			) : (
				(!loading && (selectedCommission || isAdd)) &&
				<>
					<Commission
						commission={selectedCommission ?? null}
						onUpdate={updateCommission}
					/>
					<EditButtons onSave={onSave} onCancel={() => {
						dispatch(saveCommissionClear());
						history.push(Routes.withParams(ERoute.COMMISSIONS_LIST, {}));
					}} onDelete={deleteCommission} isAdd={isAdd} />
				</>
			)}
		</Container>
	);
};

export default CommissionPage;
