import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import IAppState from "../interfaces/store/IAppState";
import { ILogItem } from "../interfaces/ILogItem";
import { findEntityLogItems } from "../store/actions/logItemActions";

export interface IPaginationOptions {
	entityId?: String;
	paginationCursor?: string;
}

export const PAGINATION_FETCH_SIZE = 10;

/**
 * old pagination with cursor, needs to be refactored for pmuch anything and normalize all the rest of searches
 */
export const usePaginationNew = function <T>() {
	const dispatch = useDispatch();
	const loaderRef = useRef<HTMLParagraphElement>(null);
	const abortController = useRef<null | AbortController>();

	const [searchOptions, setSearchOptions] = useState<IPaginationOptions | null>(null);
	const [items, setItems] = useState<ILogItem[] | null>(null);
	const [hasMore, setHasMore] = useState<boolean>(false);

	const {
		logItems: stateLogItems,
		paginationCursor,
		hasMoreLogItems: stateHasMoreItems,
		logItemsLoading: loading,
	} = useSelector((state: IAppState) => state.logItem);

	useEffect(() => {
		setItems(stateLogItems);
	}, [stateLogItems]);

	useEffect(() => {
		setHasMore(stateHasMoreItems);
	}, [stateHasMoreItems]);

	const searchHandler = useCallback(
		(search: IPaginationOptions) => {
			if (abortController.current) abortController.current.abort();
			abortController.current = new AbortController();
			dispatch(findEntityLogItems(search, abortController.current.signal));
			setSearchOptions(search);
		},
		[dispatch]
	);

  const handleObserver = useCallback((entries) => {
      if (!stateHasMoreItems || !searchOptions || loading || stateLogItems?.length === 0) return;
      const timeoutId = setTimeout(() => {
        const target = entries[0];
        if (target.isIntersecting) {
          searchHandler({
            ...searchOptions,
            paginationCursor: paginationCursor ?? "",
          });
        }
      }, 500);
      return () => clearTimeout(timeoutId);
    }, [loading, stateLogItems, searchHandler, searchOptions, stateHasMoreItems, paginationCursor]);

	useEffect(() => {
		const observer = new IntersectionObserver(handleObserver, {
			root: null,
			rootMargin: "",
			threshold: 1,
		});
		if (loaderRef.current) observer.observe(loaderRef.current);
		return () => observer.disconnect();
	}, [handleObserver]);

	return { loaderRef, items, loading, searchHandler, hasMore };
};
