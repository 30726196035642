import { IAttachment } from './../interfaces/IAttachment';
import axios from 'axios';

import { customFetch, EFetchMethod } from './../custom-fetch';

interface ICreateResponse {
  attachmentId: string;
  location: string;
}

export interface ICreateAttachment {
  attachmentId: string;
  location: string;
  file: File;
}

export const createAttachment = async (file: File, publicAccess?: boolean): Promise<ICreateAttachment>  => {
  const data = { fileName: file.name, contentType: file.type, size: file.size, publicAccess };
  const { location, attachmentId } = await customFetch<ICreateResponse>(`/attachments/add`, EFetchMethod.POST, JSON.stringify(data))
  return { location, attachmentId, file };
}

export const uploadFile = async (createAttachment: ICreateAttachment, onProgress: (progress: number) => void): Promise<string> => {

  await axios.put(createAttachment.location, createAttachment.file, {
    onUploadProgress: (progress) => {
      onProgress(Math.round(progress.loaded / progress.total * 100))
    }
  });

  return createAttachment.attachmentId;
}


export const updateAttachment = async (attachment: Partial<IAttachment>): Promise<IAttachment> => {
  return await customFetch<IAttachment>(`/attachments/update`, EFetchMethod.PUT, JSON.stringify(attachment));
} 


export const getAttachment = async (id: string): Promise<IAttachment> => {
  return await customFetch<IAttachment>(`/attachments/get?id=${id}`, EFetchMethod.GET);
} 