import { Dispatch } from 'redux';

import { EFetchMethod, customFetch } from '../../custom-fetch';
import IEezyTrusterAction from '../../interfaces/store/IEezyTrusterAction';
import EActionTypes from '../../interfaces/store/EActionTypes';
import { IEezyTrusterEvent, IEezyTrusterPayment } from '../../interfaces/IEezyTruster';
import { IEezyTrusterFetchOptions } from '../../hooks/useEezyTrusterEvents';
import { ICustomTotalAdd } from '../../components/Eezy/EezyTrusterEvent/EezyTrusterInfoPaymentRows';

type TAction = IEezyTrusterAction;


const getEventsStart = (): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_GET_EVENTS_START,
	};
};

const getEventsSuccess = (events: IEezyTrusterEvent[], count: number): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_GET_EVENTS_DONE,
		events,
		eventsCount: count
	};
};

const getEventsFail = (error: string): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_GET_EVENTS_ERROR,
		error,
	};
};

export const getEventsClear = (): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_GET_EVENTS_CLEAR,
	};
};

export const getEezyTrusterEvents = (options: IEezyTrusterFetchOptions) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getEventsStart());
			customFetch<{ data: IEezyTrusterEvent[], count: number }>(`/eezy/events?data={"start":${options.start},"fetchSize":${options.fetchSize}}`).then((data: { data: IEezyTrusterEvent[], count: number }) => {
				dispatch(getEventsSuccess(data.data, data.count));
			}).catch((err) => {
				dispatch(getEventsFail((err as Error).message));
			})
		} catch (error) {
			dispatch(getEventsFail((error as Error).message));
		}
	};
}

const getEventStart = (): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_GET_EVENT_START,
	};
};

const getEventSuccess = (event: IEezyTrusterEvent): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_GET_EVENT_DONE,
		event,
	};
};

const getEventFail = (error: string): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_GET_EVENT_ERROR,
		error,
	};
};

export const getEventClear = (): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_GET_EVENT_CLEAR,
	};
};


export const getEezyTrusterEvent = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getEventStart());
			customFetch<IEezyTrusterEvent>(`/eezy/events?id=${id}`).then((event: IEezyTrusterEvent) => {
				dispatch(getEventSuccess(event));
			}).catch((err) => {
				dispatch(getEventFail((err as Error).message));
			})
		} catch (error) {
			dispatch(getEventFail((error as Error).message));
		}
	};
}


const addManagementTotalStart = (): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_ADD_MANAGEMENT_TOTAL_START,
	};
};

const addManagementTotalSuccess = (payment: IEezyTrusterPayment, paymentIndex: number): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_ADD_MANAGEMENT_TOTAL_DONE,
		payment,
		paymentIndex,
	};
};

const addManagementTotalFail = (error: string): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_ADD_MANAGEMENT_TOTAL_ERROR,
		error,
	};
};


export const addManagementTotal = (id: string, paymentIndex: number) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(addManagementTotalStart());
			customFetch<{ eezyPayment: IEezyTrusterPayment }>(`/eezy/managementtotal/add`, EFetchMethod.POST, JSON.stringify({ payment: id })).then(({ eezyPayment }) => {
				dispatch(addManagementTotalSuccess(eezyPayment, paymentIndex));
			}).catch((err) => {
				dispatch(addManagementTotalFail((err as Error).message));
			})
		} catch (error) {
			dispatch(addManagementTotalFail((error as Error).message));
		}
	};
}


const removeManagementTotalStart = (): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_REMOVE_MANAGEMENT_TOTAL_START,
	};
};

const removeManagementTotalSuccess = (payment: IEezyTrusterPayment, paymentIndex: number): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_REMOVE_MANAGEMENT_TOTAL_DONE,
		payment,
		paymentIndex,
	};
};

const removeManagementTotalFail = (error: string): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_REMOVE_MANAGEMENT_TOTAL_ERROR,
		error,
	};
};


export const removeManagementTotal = (id: string, paymentIndex: number) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(removeManagementTotalStart());
			customFetch<{ eezyPayment: IEezyTrusterPayment }>(`/eezy/managementtotal/delete?payment=${id}`, EFetchMethod.DELETE).then(({ eezyPayment }) => {
				dispatch(removeManagementTotalSuccess(eezyPayment, paymentIndex));
			}).catch((err) => {
				dispatch(removeManagementTotalFail((err as Error).message));
			})
		} catch (error) {
			dispatch(removeManagementTotalFail((error as Error).message));
		}
	};
}


const sendEezyTrusterPaymentStart = (): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_SEND_PAYMENT_START,
	};
};

const sendEezyTrusterPaymentSuccess = (): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_SEND_PAYMENT_DONE,
	};
};

const sendEezyTrusterPaymentFail = (error: string): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_SEND_PAYMENT_ERROR,
		error,
	};
};


export const sendEezyTrusterPayment = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(sendEezyTrusterPaymentStart());
			customFetch(`/eezy/payments/send`, EFetchMethod.POST, JSON.stringify({ id: id })).then(() => {
				dispatch(sendEezyTrusterPaymentSuccess());
			}).catch((err) => {
				dispatch(sendEezyTrusterPaymentFail((err as Error).message));
			})
		} catch (error) {
			dispatch(sendEezyTrusterPaymentFail((error as Error).message));
		}
	};
}

const removeEezyTrusterPaymentStart = (): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_REMOVE_PAYMENT_START,
	};
};

const removeEezyTrusterPaymentSuccess = (paymentIndex: number): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_REMOVE_PAYMENT_DONE,
		paymentIndex,
	};
};

const removeEezyTrusterPaymentError = (error: string): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_REMOVE_PAYMENT_ERROR,
		error,
	};
};


export const removeEezyTrusterPayment = (id: string, paymentIndex: number) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(removeEezyTrusterPaymentStart());
			customFetch(`/eezy/payments/delete?id=${id}`, EFetchMethod.DELETE).then(() => {
				dispatch(removeEezyTrusterPaymentSuccess(paymentIndex));
			}).catch((err) => {
				dispatch(removeEezyTrusterPaymentError((err as Error).message));
			})
		} catch (error) {
			dispatch(removeEezyTrusterPaymentError((error as Error).message));
		}
	};
}


const sendEezyTrusterEventStart = (): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_SEND_EVENT_START,
	};
};

const sendEezyTrusterEventSuccess = (): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_SEND_EVENT_DONE,
	};
};

const sendEezyTrusterEventError = (error: string): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_SEND_EVENT_ERROR,
		error,
	};
};


export const sendEezyTrusterEvent = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(sendEezyTrusterEventStart());
			customFetch(`/eezy/events/send`, EFetchMethod.POST, JSON.stringify({ id: id })).then(() => {
				dispatch(sendEezyTrusterEventSuccess());
			}).catch((err) => {
				dispatch(sendEezyTrusterEventError((err as Error).message));
			})
		} catch (error) {
			dispatch(sendEezyTrusterEventError((error as Error).message));
		}
	};
}



const saveNewEezyTrusterCustomStart = (): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_SEND_NEW_CUSTOM_START,
	};
};

const saveNewEezyTrusterCustomSuccess = (payment: IEezyTrusterPayment, paymentIndex: number): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_SEND_NEW_CUSTOM_DONE,
		payment,
		paymentIndex,
	};
};

const saveNewEezyTrusterCustomError = (error: string): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_SEND_NEW_CUSTOM_ERROR,
		error,
	};
};


export const saveNewEezyTrusterCustom = (id: string, paymentIndex: number, customTotal: ICustomTotalAdd) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(saveNewEezyTrusterCustomStart());
			const data = { payment: id, customTotal: customTotal }
			customFetch<{ eezyPayment: IEezyTrusterPayment }>(`/eezy/customtotal/add`, EFetchMethod.POST, JSON.stringify(data)).then((data) => {
				dispatch(saveNewEezyTrusterCustomSuccess(data.eezyPayment, paymentIndex));
			}).catch((err) => {
				dispatch(saveNewEezyTrusterCustomError((err as Error).message));
			})
		} catch (error) {
			dispatch(saveNewEezyTrusterCustomError((error as Error).message));
		}
	};
}

const removeNewEezyTrusterCustomStart = (): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_REMOVE_CUSTOM_START,
	};
};

const removeNewEezyTrusterCustomSuccess = (payment: IEezyTrusterPayment, paymentIndex: number): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_REMOVE_CUSTOM_DONE,
		payment,
		paymentIndex,
	};
};

const removeNewEezyTrusterCustomError = (error: string): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_REMOVE_CUSTOM_ERROR,
		error,
	};
};


export const removeNewEezyTrusterCustom = (id: string, paymentIndex: number, position: number) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(removeNewEezyTrusterCustomStart());
			customFetch<{ eezyPayment: IEezyTrusterPayment }>(`/eezy/customtotal/delete?payment=${id}&position=${position}`, EFetchMethod.DELETE).then((data) => {
				dispatch(removeNewEezyTrusterCustomSuccess(data.eezyPayment, paymentIndex));
			}).catch((err) => {
				dispatch(removeNewEezyTrusterCustomError((err as Error).message));
			})
		} catch (error) {
			dispatch(removeNewEezyTrusterCustomError((error as Error).message));
		}
	};
}

// Sales orders from eezy API

export interface ISalesOrderReponse {
	created_at: string;
	invoiced_at: string;
	due_date: string;
	invoiced_amount: string;
	paid_amount: string;
}

const getSalesOrderStart = (): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_GET_SALES_ORDER_START,
	};
};

const getSalesOrderSuccess = (salesOrder: ISalesOrderReponse): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_GET_SALES_ORDER_DONE,
		salesOrder,
	};
};

const getSalesOrderCustomError = (error: string): TAction => {
	return {
		type: EActionTypes.EEZY_TRUSTER_GET_SALES_ORDER_ERROR,
		error,
	};
};

export const getSalesOrder = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getSalesOrderStart());
			customFetch<{ salesOrder: ISalesOrderReponse }>(`/eezy/salesorder?id=${id}`, EFetchMethod.GET).then((data) => {
				dispatch(getSalesOrderSuccess(data.salesOrder));
			}).catch((err) => {
				dispatch(getSalesOrderCustomError((err as Error).message));
			})
		} catch (error) {
			dispatch(getSalesOrderCustomError((error as Error).message));
		}
	}
}
