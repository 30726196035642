import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute, Routes } from '../../classes/Routes';
import Heading from '../../components/Heading/Heading';
import Button from '../../components/ui/Button/Button';
import Container from '../../components/ui/Container/Container';
import UserGroupList from '../../components/UserGroups/UserGroupList/UserGroupList';
import IAppState from '../../interfaces/store/IAppState';
import IUserGroupState from '../../interfaces/store/IUserGroupState';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';

interface IProps extends RouteComponentProps {}

const UserGroupsListPage: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { userGroups, loading, error } = useSelector<IAppState, IUserGroupState>(state => state.userGroups);

  const { t } = useTranslation();

  useEffect(() => {
    if (!userGroups) {
      dispatch(actions.findUserGroups());
    }
  }, [dispatch, userGroups]);

  const addHandler = () => {
    openHandler("add");
  };

  const openHandler = (id?: string) => {
    if (!id) return;
    history.push(Routes.withParams(ERoute.USER_GROUPS_EDIT, { id }));
  };

  return (
    <Container>
      <Heading>{t(ETranslation.USER_GROUPS_LIST_TITLE)}</Heading>
      <Button onClick={addHandler}>{t(ETranslation.USER_GROUPS_LIST_ADD)}</Button>
      <UserGroupList
        userGroups={userGroups}
        loading={loading}
        error={error}
        onClick={openHandler}
      />
    </Container>
  );
};

export default UserGroupsListPage;
