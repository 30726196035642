import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { IOption } from "../components/ui/Input/Input";
import IAppState from "../interfaces/store/IAppState";
import * as actions from "../store/actions";
import { createOptions } from "../utils/option-utils";

export const useOrganizationOptions = () => {
  const dispatch = useDispatch();
  const [organizationOptions, setOrganizationOptions] = useState<IOption[]>([]);
  const [tryFetch, setTryFetch] = useState(false);

  const { user } = useSelector((state: IAppState) => state.auth);
  const {
    searchOrganizations: organizations,
    searchOrganizationsLoading: loading,
  } = useSelector((state: IAppState) => state.organization);

  useEffect(() => {
	// JAVA jsonbaseservlet row 116 ""/json/organizations/user"; // ONLY CUSTOMER"
    if (user && user.role === "CUSTOMER") {
      setTryFetch(user.showOrganizationGroupInterpretations);
    }
  }, [user]);

  useEffect(() => {
    if (tryFetch && !organizations && !loading) {
      dispatch(actions.findSearchOrganizations());
      setTryFetch(false);
    }
  }, [dispatch, organizations, tryFetch, loading]);

  useEffect(() => {
    if (organizations) {
      let optionUsers = [...organizations];
      setOrganizationOptions(createOptions(optionUsers));
    } else {
      setOrganizationOptions([]);
    }
  }, [organizations]);

  return { organizationOptions, organizationsLoading: loading };
};
