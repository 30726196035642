import { useEffect, useState } from "react"
import { IOption } from "../components/ui/Input/Input";

export const useSingleOptionLabel = (items?: IOption[], value?: string | number) => {
  const [label, setLabel] = useState<string | null>(null);

  useEffect(() => {
    if (!value || !items) return;
    setLabel(items.find(item => item.value.toString() === value.toString())?.label || null);
  }, [items, value]);

  return label;
}