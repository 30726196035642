import React from "react";
import { IUserContractSettings } from "../../../interfaces/IUserContractSettings";
import { useTranslation } from "react-i18next";
import { USER_CONTRACT_SETTINGS_TYPES } from "../../../shared/user-contract-settings-data";

// Aivan hirve koodi tässä ei pidä ottaa mallia
interface IContractSettingsTextProps {
	contractSettings?: IUserContractSettings[];
}

const UserContractSettingsText: React.FC<IContractSettingsTextProps> = ({ contractSettings }) => {
	const { t, i18n } = useTranslation();
	if (!contractSettings || contractSettings.length === 0) return null;
	const setting = contractSettings[0];

	return (
		<>
			{setting.name} |{" "}
			{t(USER_CONTRACT_SETTINGS_TYPES.find((option) => option.value === setting.type)?.labelTranslation ?? "")} |{" "}
			{new Date(setting.contractStartDate).toLocaleDateString(i18n.language)}
		</>
	);
};

export default UserContractSettingsText;