import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ISite } from '../../../interfaces/ISite';
import { ETranslation } from '../../../translations/translation-keys';
import Alert from '../../ui/Alert/Alert';
import Input, { EInputType, TInputValue } from '../../ui/Input/Input';
import Spinner from '../../ui/Spinner/Spinner';
import Table from '../../ui/Table/Table';



interface IProps {
    sites: ISite[] | null;
    loading: boolean;
    error: string | null;
    onClick: (id: string) => void;
  }


const SiteList: React.FC<IProps> = ({
sites,
loading,
error,
onClick,
}) => {
const [filter, setFilter] = useState("");
const { t } = useTranslation();
if (error) {
    return <Alert>{error}</Alert>;
}

if (!sites || loading) {
    return <Spinner />;
}

if (sites.length === 0) {
    return <p>{t(ETranslation.SITE_NO_SITES)}</p>;
}

const filterSite = (item: ISite): boolean => {
    return (
    filter.length === 0 ||
    item.name?.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
};

return (
    <>
    <Input
        containerStyles={{ margin: "1rem 0" }}
        type={EInputType.text}
        inputName="filter"
        value={filter}
        onChange={(value: TInputValue) => setFilter(value as string)}
        placeholderTranslation= {ETranslation.COMMON_FILTER_BY_NAME}
    />

    <Table hover={true}>
        <thead>
        <tr>
            <th>{t(ETranslation.COMMON_NAME)}</th>
            <th>{t(ETranslation.SITE_PREFIX)}</th>
        </tr>
        </thead>
        <tbody>
        {sites
            .filter(filterSite)
            .map((site, index) => (
            <tr
                key={site.id}
                onClick={() => onClick(site.id)}
            >
                <td>{site.name}</td>
                <td>{site.prefix}</td>
            </tr>
            ))}
        </tbody>
    </Table>
    </>
);
};

export default SiteList;
  