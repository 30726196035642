import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import PriceSeason from "../../../components/PriceSeason/PriceSeason";
import Alert, { EAlertColor } from "../../../components/ui/Alert/Alert";
import Container from "../../../components/ui/Container/Container";
import Spinner from "../../../components/ui/Spinner/Spinner";
import { IPriceSeason } from "../../../interfaces/IPriceSeason";
import IAppState from "../../../interfaces/store/IAppState";
import * as actions from "../../../store/actions";
import { ERoute } from "../../../classes/Routes";
import EditButtons from "../../../components/ui/EditButtons/EditButtons";
import Heading from "../../../components/Heading/Heading";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const PriceSeasonEditPage: React.FC<IProps> = ({ match, history }) => {
  const [editPriceSeason, setEditPriceSeason] = useState<IPriceSeason | null>(
    null
  );
  const [isValid, setIsValid] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const dispatch = useDispatch();

  const { id } = match.params;
  const isAdd = id === "add";

  const { t } = useTranslation();

  useEffect(() => {
    if (!isAdd) {
      dispatch(actions.getPriceSeason(id));
    }
  }, [isAdd, id, dispatch]);

  const { error, loading, okState, priceSeason, deleteError, deleteLoading, deleteOk } = useSelector(
    (state: IAppState) => ({
      error: state.priceSeason.priceSeasonError || state.priceSeason.priceSeasonSaveOrUpdateError || state.priceSeason.priceSeasonCopyError,
      loading: state.priceSeason.priceSeasonLoading || state.priceSeason.priceSeasonSaveOrUpdateLoading || state.priceSeason.priceSeasonCopyLoading,
      okState: state.priceSeason.priceSeasonSaveOrUpdateOk || state.priceSeason.priceSeasonCopyOk,
      priceSeason: state.priceSeason.priceSeason,
      deleteLoading: state.priceSeason.priceSeasonDeleteLoading,
      deleteError: state.priceSeason.priceSeasonDeleteError,
      deleteOk: state.priceSeason.priceSeasonDeleteOk
    })
  );


  useEffect(() => {
    if (okState) {
      history.push(ERoute.PRICE_SEASONS_LIST);
    }
    return () => {
      dispatch(actions.saveOrUpdatePriceSeasonClear());
      dispatch(actions.getPriceSeasonClear());
      dispatch(actions.deletePriceSeasonClear());
	  dispatch(actions.copyPriceSeasonClear());
    };
  }, [dispatch, okState, history]);

  const updateHandler = useCallback(
    (priceSeason: IPriceSeason, isValid: boolean) => {
      setEditPriceSeason(priceSeason);
      setIsValid(isValid);
    },
    []
  );

  const submitHandler = () => {
    if (!editPriceSeason) return;

    if (isAdd) {
      dispatch(actions.savePriceSeason(editPriceSeason));
    } else {
      editPriceSeason.id = id;
      dispatch(actions.updatePriceSeason(editPriceSeason));
    }
  };

  const deleteHandler = () => {
    if(priceSeason) {
      dispatch(actions.deletePriceSeason(priceSeason.id));
    }
  }

  const copyHandler = () => {
	if (priceSeason) {
		dispatch(actions.copyPriceSeason(priceSeason.id));
	}
  }

  return (
    <Container>
      {error && <Alert>{error}</Alert>}
      {loading && <Spinner centerAbsolute />}
      <Heading>{t(ETranslation.PAGES_PRICE_SEASON_TITLE)}</Heading>
      <Alert color={EAlertColor.INFO}>{t(ETranslation.PAGES_PRICE_SEASON_INFO)}</Alert>
      <PriceSeason
        onChange={updateHandler}
        loading={loading}
        priceSeason={priceSeason}
        showValidation={showValidation}
      />
      <EditButtons
        onSave={isValid ? submitHandler : () => setShowValidation(true)}
        disabled={showValidation && !isValid}
        loading={loading}
        onCancel={() => history.goBack()}
        onDelete={deleteHandler}
        deleteText={ETranslation.PAGES_PRICE_SEASON_CONFIRM_DELETE}
        onDeleteClose={() => dispatch(actions.deletePriceSeasonClear())}
        onDeleteDone={() => history.push(ERoute.PRICE_SEASONS_LIST) }
        deleteError={deleteError}
        deleteLoading={deleteLoading}
        deleteOk={deleteOk}
		onCopy={copyHandler}
        isAdd={isAdd}
      />
    </Container>
  );
};

export default PriceSeasonEditPage;
