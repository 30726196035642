import React, { useEffect, useState } from "react";
import { EInputType, IInputField } from "../../../ui/Input/Input";
import { ETranslation } from "../../../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import { useCreateInput } from "../../../../hooks/useCreateInput";
import { getInputData } from "../../../ui/Input/input-utils";
import EditButtons from "../../../ui/EditButtons/EditButtons";
import { CONNECTION_TYPES, EConnectionDetail } from "../../../../shared/interpretation-data";
import { IConnectionDetail } from "../../../../interfaces/IInterpretation";
import classes from "./interpretationConnectionTypeModal.module.scss"
import { contactOptionsLanguages } from "../../../../shared/languages-data";

interface Iprops {
	connectionDetails: IConnectionDetail,
	onConfirm: (data: IConnectionDetail) => void;
	cancelHandler: () => void;
}

const EInputs = {
	connectionType: "connectionType",
	phoneNumber: "phoneNumber",
	videoConferenceInfo: "videoConferenceInfo",
	videoConferenceUrl: "videoConferenceUrl",
	language: "language",
	email: "email",
	organizationName: "organizationName",
	firstName: "firstName",
	lastName: "lastName",
	deliveryEmail: "deliveryEmail",
	deliverySms: "deliverySms",
	customerNotesToContact: "customerNotesToContact",
	customerNotesToInterpreter: "customerNotesToInterpreter",
	customerReferenceToContact: "customerReferenceToContact",
	customerReferenceToInterpreter: "customerReferenceToInterpreter",
}
const InterpretationContactModal: React.FC<Iprops> = ({ connectionDetails, onConfirm, cancelHandler }) => {

	const { t } = useTranslation()
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.connectionType]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.INTREPRETATION_CONNECTION_TYPE,
			options: CONNECTION_TYPES,
			value: connectionDetails.connectionType ?? EConnectionDetail.CUSTOMER_CALL_TO_INTERPRETER,
		},
		[EInputs.phoneNumber]: {
			type: EInputType.text,
			labelTranslation: ETranslation.INTREPRETATION_CONNECTION_PHONE,
			value: connectionDetails.phoneNumber ?? "",
		},
		[EInputs.videoConferenceInfo]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.INTERPRETATION_VIDEO_INFO,
			value: connectionDetails.videoConferenceInfo ?? "",
		},
		[EInputs.videoConferenceUrl]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.INTERPRETATION_VIDEO_URL,
			value: connectionDetails.videoConferenceUrl ?? "",
		},
		[EInputs.language]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.INTREPRETATION_CONNECTION_LANGUAGE,
			options: contactOptionsLanguages(),
			value: connectionDetails.language ?? "",
		},
		[EInputs.email]: {
			type: EInputType.text,
			labelTranslation: ETranslation.INTREPRETATION_CONNECTION_EMAIL,
			options: CONNECTION_TYPES,
			value: connectionDetails.email ?? "",
		},
		[EInputs.organizationName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.INTREPRETATION_CONNECTION_ORGANIZATION,
			options: CONNECTION_TYPES,
			value: connectionDetails.organizationName ?? "",
		},
		[EInputs.firstName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.INTREPRETATION_CONNECTION_FIRSTNAME,
			options: CONNECTION_TYPES,
			value: connectionDetails.firstName ?? "",
		},
		[EInputs.lastName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.INTREPRETATION_CONNECTION_LASTNAME,
			options: CONNECTION_TYPES,
			value: connectionDetails.lastName ?? "",
		},
		[EInputs.deliveryEmail]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.INTREPRETATION_CONNECTION_INFO_VIA_MAIL,
			options: CONNECTION_TYPES,
			value: connectionDetails.deliveryEmail ?? false,
		},
		[EInputs.deliverySms]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.INTREPRETATION_CONNECTION_INFO_VIA_PHONE,
			options: CONNECTION_TYPES,
			value: connectionDetails.deliverySms ?? false,
		},
		[EInputs.customerNotesToContact]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.INTREPRETATION_CONNECTION_NOTES_TO_CONTACT,
			value: connectionDetails.customerNotesToContact ?? false,
		},
		[EInputs.customerNotesToInterpreter]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.INTREPRETATION_CONNECTION_NOTES_TO_WORKER,
			value: connectionDetails.customerNotesToInterpreter ?? false,
		},
		[EInputs.customerReferenceToContact]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.INTREPRETATION_CONNECTION_REFERENCE_TO_CONTACT,
			value: connectionDetails.customerReferenceToContact ?? false,
		},
		[EInputs.customerReferenceToInterpreter]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.INTREPRETATION_CONNECTION_REFERENCE_TO_WORKER,
			value: connectionDetails.customerReferenceToInterpreter ?? false,
		},
	});
	const createInput = useCreateInput(inputs, setInputs);

	const [connectionType, setConnectionType] = useState(connectionDetails.connectionType);

	useEffect(() => {
		const { connectionType } = getInputData<IConnectionDetail>(inputs);
		setConnectionType(connectionType);
	}, [inputs])

	const onSubmit = () => {
		const data = getInputData<IConnectionDetail>(inputs);
		onConfirm(data);
	}

	const onCancel = () => {
		cancelHandler();
	}

	return <div className={classes.container}>
		{createInput(EInputs.connectionType)}
		{(connectionType === EConnectionDetail.INTERPRETER_CALL_TO_CONTACT || connectionType === EConnectionDetail.CONTACT_CALL_TO_INTERPRETER) &&
			<>
				<div style={{ marginBottom: "10px", color: "grey" }}>{t(ETranslation.INTREPRETATION_CONNECTION_SENT_VIA)}</div>
				{createInput(EInputs.deliveryEmail)}
				{createInput(EInputs.deliverySms)}
				{createInput(EInputs.firstName)}
				{createInput(EInputs.lastName)}
				{createInput(EInputs.organizationName)}
				{createInput(EInputs.phoneNumber)}
				{createInput(EInputs.email)}
				{createInput(EInputs.language)}
				{createInput(EInputs.customerNotesToContact)}
				{createInput(EInputs.customerNotesToInterpreter)}
				{createInput(EInputs.customerReferenceToContact)}
				{createInput(EInputs.customerReferenceToInterpreter)}
			</>
		}
		{connectionType === EConnectionDetail.VIDEO_CONFERENCE &&
			<>
				{createInput(EInputs.videoConferenceUrl)}
				{createInput(EInputs.videoConferenceInfo)}
			</>
		}
		<div className={classes.buttons}>
			<EditButtons onCancel={onCancel} onSend={onSubmit} isAdd={false} />
		</div>
	</div>
}

export default InterpretationContactModal;