import * as dateFns from 'date-fns';
import React from 'react';

import { IAttachment } from '../../../interfaces/IAttachment';
import { ETranslation } from '../../../translations/translation-keys';
import { ECommonValue, EInputType, IInputField, IInputFieldItem, IOption, IValidationResult, TInputValue } from './Input';

export function updateObject<T>(
  oldObject: T,
  updatedProperties: Partial<T>
): T {
  return {
    ...oldObject,
    ...updatedProperties,
  };
}

export const isValidEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
};

const isOptionArray = (value: TInputValue): value is IOption[] => {
  if(!Array.isArray(value) || !value.length) return false;
  for(let i = 0; i < value.length; i++) {
    const item = value[i] as any;
    if(typeof item.value === 'undefined') {
      return false;
    }
  }
  return true;
};

const isOption = (value: any): value is IOption => value && typeof value.value !== 'undefined';

export const isStringArray = (value: TInputValue): value is string[] => {
  if(!Array.isArray(value) || !value.length) return false;
  for(let i = 0; i < value.length; i++) {
    const item = value[i] as any;
    if(typeof item !== 'string') {
      return false;
    }
  }
  return true;
};

const getCheckboxValue = (value: string[], input: IInputFieldItem) => {
  const options = input.options;
  let isBoolean = false;
  if(options) {
    if(options.length === 1 && options[0].value === ECommonValue.YES) {
      isBoolean = true;
    }
  }

  if(isBoolean) {
    return value.length === 1 ? true : false;
  } else {
    return value;
  }
}

export function getInputData<T>(inputs: IInputField, booleanTransform: boolean = true): T {
  let data: any = {};
  for (let key in inputs) {
    const input = inputs[key];
    let value = input.value as any

    if(input.type === EInputType.dropzone) {
      const attachments = value as IAttachment[];
      if(!input.multiple && attachments && attachments.length === 1) {
        value = attachments[0];
      }
    } else if(input.type === EInputType.number) {
      const stringValue = value as string;
      if(!stringValue || stringValue.length === 0) {
        value = null;
      }
    } else if(input.type === EInputType.checkbox) {
      value = getCheckboxValue(value, input)
    } else if(input.type === EInputType.radio && booleanTransform) {
      value = value === ECommonValue.YES ? true : value;
    } else if(isOptionArray(value)) {
      value = value.map(item => item.value);
    } else if(isOption(value)) {
      value = value.value;
    } else if((input.type === EInputType.datetimepicker || input.type === EInputType.datepicker) && input.dateAsString){
      value = value != null && value ? dateFns.format(new Date(value), input.type === EInputType.datepicker ? "dd.MM.yyyy": "dd.MM.yyyy HH:mm") : value;
    }
    data[key] = value;
  }
  return data;
};

export const initForm = (
  setInputs: React.Dispatch<React.SetStateAction<IInputField>>,
  data: any
): void => {
  setInputs((inputs) => {
    let newInputs = {
      ...inputs,
    };
    // Update input valid status when initForm, fixes cases where data doesn't have all the input keys.
    for (let key in inputs) {
      updateInputValid(newInputs, key);
    }
    // Set values from data and validate
    for (let key in data) {
      const input = inputs[key];
      if (input) {
        let value: any = data[key];
        let options = input.options;

        if (typeof value === "number") {
          value = value.toString();
        }

        // This is here because coldfusion likes to convert YES -> True and NO -> False
        // So we have to add empty space and trim here it from inputs.
        if (typeof value === "string") {
          value = value.trim();
        } else if(typeof value === 'boolean') {
          if(input.type === EInputType.radio) {
            value = value ? ECommonValue.YES : "";
          } else if(input.type === EInputType.checkbox) {
            value = value ? [ECommonValue.YES] : [];
          }
        }

        if(input.type === EInputType.radio && Array.isArray(value) && value.length === 1) {
          value = value[0];
        } else if(input.type === EInputType.dropzone && !Array.isArray(value) && typeof value !== 'undefined') {
          value = [value];
        } else if(input.type === EInputType.reactSelectSearch || input.type === EInputType.reactSelect) {
          if(input.multiple) {
            if(Array.isArray(value)) {
              if(!isStringArray(value)) {
                value = value.map((value: IOption) => ({ value: value.value, label: value.label }));
                options = options ?? [...value];
              }
            } else {
              value = [];
            }
          }
        }
        if (!value) {
          switch(input.type) {
            case EInputType.dropzone:
            case EInputType.checkbox:
              value = [];
              break;
            default:
              value = '';
              break;
          }
        }


        newInputs[key] = {
          ...inputs[key],
          value,
          options
        };
        updateInputValid(newInputs, key);
      }
    }
    return newInputs;
  });
};

const updateInputValid = (
  newState: IInputField,
  inputName: string
): IInputField => {
  newState[inputName].validationResult = validateInput(
    newState,
    newState[inputName]
  );

  const validation = newState[inputName].validation;
  if (validation && validation.dependencies) {
    for (let dependencyName of validation.dependencies) {
      newState[dependencyName].validationResult = validateInput(
        newState,
        newState[dependencyName]
      );
    }
  }
  return newState;
};

export const updateInputHandler = (
  inputName: string,
  value: TInputValue,
  setInputs: React.Dispatch<React.SetStateAction<IInputField>>
) => {
  setInputs((prevState: IInputField) => {
    let newState = { ...prevState };
    newState[inputName].value = value;
    newState = updateInputValid(newState, inputName);
    return newState;
  });
};

export const disableInputs = (
  disabled: boolean,
  setInputs: React.Dispatch<React.SetStateAction<IInputField>>,
  exclude?: string[],
  include?: string[]
): void => {
  setInputs((prevState: IInputField) => {
    let newState = { ...prevState };
    for (let key in newState) {
      if (exclude && exclude.includes(key)) {
        continue;
      }
      if(include && !include.includes(key)) {
        continue;
      }

      newState[key].disabled = disabled;
    }
    return newState;
  });
};

const INVALID_DEFAULT: IValidationResult = {
  isValid: false,
  message: null,
};

const validateRequiredIf = (
  value: string | string[],
  requiredIfValue?: string | string[]
) => {
  if (!requiredIfValue) return false;
  if (Array.isArray(requiredIfValue)) {
    if (Array.isArray(value)) {
      return !requiredIfValue.some((requiredIfValue) =>
        value.includes(requiredIfValue)
      );
    }
    return !requiredIfValue.includes(value as string);
  } else {
    if (Array.isArray(value)) {
      return !value.includes(requiredIfValue);
    }
    return requiredIfValue !== value;
  }
};

const isValidValue = (
  item: IInputFieldItem,
  isRequired: boolean,
  requiredIfValue?: string | string[],
  requiredCompareValue?: string
): IValidationResult => {
  const { type, validation = {} } = item;

  let value;
  switch (type) {
    case EInputType.text:
    case EInputType.number:
    case EInputType.date:
    case EInputType.radio:
    case EInputType.tel:
    case EInputType.textarea:
    case EInputType.time:
    case EInputType.select:
    case EInputType.star:
    case EInputType.email:
      value = item.value as string;

      if(isRequired) {
        if (requiredCompareValue) {
          if (requiredCompareValue === value) return INVALID_DEFAULT;
        } else if (value === "") {
          return INVALID_DEFAULT;
        }
      }

      // if (requireIfValue && requireIfValue !== value) return INVALID_DEFAULT;
      if (validateRequiredIf(value, requiredIfValue)) return INVALID_DEFAULT;
      if (type === EInputType.email && !isValidEmail(value as string))
        return {
          isValid: false,
          message: ETranslation.VALIDATION_EMAIL, // ADD Translation
        };
      if (validation.minLength && value.length < validation.minLength) {
        return {
          isValid: false,
          message:
            validation.minLengthMessage || ETranslation.VALIDATION_MIN_LENGTH,
          messageParams: {
            minLength: validation.minLength.toString(),
          },
        };
      }
      if (validation.maxLength && value.length > validation.maxLength) {
        return {
          isValid: false,
          message:
            validation.maxLengthMessage || ETranslation.VALIDATION_MAX_LENGTH,
          messageParams: {
            maxLength: validation.maxLength.toString(),
          },
        };
      }
      if (validation.minAmount && parseInt(value) < validation.minAmount) {
        return {
          isValid: false,
          message:
            validation.minAmountMessage || ETranslation.VALIDATION_MIN_AMOUNT,
          messageParams: {
            minAmount: validation.minAmount.toString(),
          },
        };
      }
      if (validation.maxAmount && parseInt(value) > validation.maxAmount) {
        return {
          isValid: false,
          message:
            validation.maxAmountMessage || ETranslation.VALIDATION_MAX_AMOUNT,
          messageParams: {
            maxAmount: validation.maxAmount.toString(),
          },
        };
      }
      break;
    case EInputType.reactSelect:
    case EInputType.checkbox:
      value = item.value as string[];

      if (validateRequiredIf(value, requiredIfValue)) return INVALID_DEFAULT;

      if (isRequired && value.length === 0) return INVALID_DEFAULT;
      break;
    case EInputType.toLanguageSelect:
    case EInputType.reactSelectSearch:
      value = item.value as string[];
      if (isRequired && (value === null || value.length === 0)) return INVALID_DEFAULT;
      break;
    case EInputType.dropzone:
      value = item.value as IAttachment[];
      if(isRequired && value.length === 0) return INVALID_DEFAULT;
      break;
    case EInputType.datepicker:
      value = item.value as Date;
      if (!value) return INVALID_DEFAULT;
      break;
	case EInputType.datetimepicker:
      value = item.dateAsString ? item.value as string : item.value as Date;
      if (!value) return INVALID_DEFAULT;
      break;
    default:
      return INVALID_DEFAULT;
  }
  return {
    isValid: true,
    message: null,
  };
};

export const validateInput = (
  inputs: IInputField,
  item: IInputFieldItem
): IValidationResult => {
  const { validation } = item;
  if (validation) {
    if (validation.requiredIf) {

      let isRequired = false;
      const requiredIf = validation.requiredIf;
	  if (validation.requiredIfOr) {
		isRequired = requiredIf.some(ri => {
			const requiredIfInput = inputs[ri.inputName];
			return isValidValue(requiredIfInput, true, ri.value).isValid
		  });
	  } else {
		isRequired = requiredIf.every(ri => {
			const requiredIfInput = inputs[ri.inputName];
			return isValidValue(requiredIfInput, true, ri.value).isValid
		});
	  }
      if(isRequired) {
        return isValidValue(item, true);
      }
    } else if (validation.requiredIfNot) {
      let isRequired = false;
      const requiredIfNot = validation.requiredIfNot;
	  if (validation.requiredIfOr) {
		isRequired = requiredIfNot.some(rin => !isValidValue(inputs[rin.inputName], true, rin.value).isValid)
	  } else {
		isRequired = requiredIfNot.every(rin => !isValidValue(inputs[rin.inputName], true, rin.value).isValid)
	  }
      if(isRequired) {
        return isValidValue(item, true);
      }
    } else {
      return isValidValue(item, !!item.validation?.required, undefined, validation.requiredCompareValue);
    }
  }
  return {
    isValid: true,
    message: null,
  };
};

interface IValidateOptions {
  excludeTypes?: EInputType[];
  excludeFields?: string[];
}

export const validateInputs = (
  inputs: IInputField,
  options?: IValidateOptions
) => {
  const keys = Object.keys(inputs);
  for (let key of keys) {
    const item = inputs[key];
    if (options) {
      if (options.excludeTypes && options.excludeTypes.includes(item.type)) {
        continue;
      }
      if (options.excludeFields && options.excludeFields.includes(key)) {
        continue;
      }
    }
    const validationResult = validateInput(inputs, item);
    if (!validationResult.isValid) {
      return false;
    }
  }
  return true;
};

export const dateToDateTimeString = (date: Date | null): string => {
  if (!date || !dateFns.isValid(date)) return "";
  return dateFns.format(date, "dd.MM.yyyy HH:mm");
};



export const isTablePortraitDown = () => {
  return window.innerWidth <= 800;
}

export const isTableLandscapeDown = () => {
  return window.innerWidth <= 1280
}