import React, { useEffect, useState } from "react";
import { IFeeRow } from "../../store/actions/feeActions";
import { EInputType, IInputField } from "../ui/Input/Input";
import InputGroup from "../ui/InputGroup/InputGroup";
import { useCreateInput } from "../../hooks/useCreateInput";
import { searchInterpreters } from "../../services/userServices";
import { getInputData } from "../ui/Input/input-utils";
import Button, { EButtonColor } from "../ui/Button/Button";
import classes from "./feeStyles.module.scss";
import { ETranslation } from "../../translations/translation-keys";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

interface IProps {
	feeRow: IFeeRow;
	onUpdate: (feeRow: IFeeRow) => void;
	onDelete: (feeRow: IFeeRow) => void;
}

enum EInputs {
	id = "id",
	type = "type",
	feeVatP = "feeVatP",
	feeWithoutVat = "feeWithoutVat",
	amount = "amount",
	unit = "unit",
	currency = "currency",
	descriptionText = "descriptionText",
	adminNotesText = "adminNotesText",
	user = "user",
	paymentMethod = "paymentMethod"
}

const FeeRowItemEdit: React.FC<IProps> = ({ feeRow, onUpdate, onDelete }) => {

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.id]: {
			type: EInputType.text,
			label: "id",
			value: feeRow.id,
		},
		[EInputs.type]: {
			type: EInputType.select,
			labelTranslation: ETranslation.COMMON_TYPE,
			value: feeRow.type,
			options: [
				{ labelTranslation: ETranslation.PAYMENT_INSTANT_ACCEPTANCE_FEE, value: "ACCEPTANCE_FEE" },
				{ labelTranslation: ETranslation.PAYMENT_ADDITIONAL_FEE, value: "EXTRA_FEE" }
			]
		},
		[EInputs.feeVatP]: {
			type: EInputType.number,
			labelTranslation: ETranslation.COMMON_VAT,
			value: feeRow.feeVatP,
		},
		[EInputs.feeWithoutVat]: {
			type: EInputType.number,
			labelTranslation: ETranslation.PAYMENT_FEE_WITHOUT_VAT,
			value: feeRow.feeWithoutVat,
		},
		[EInputs.amount]: {
			type: EInputType.number,
			labelTranslation: ETranslation.PAYMENT_AMOUNT,
			value: feeRow.amount,
		},
		[EInputs.unit]: {
			type: EInputType.select,
			labelTranslation: ETranslation.PAYMENT_UNIT,
			value: feeRow.unit,
			options: [
				{ labelTranslation: ETranslation.COMMON_PCS, value: "PCS" },
				{ labelTranslation: ETranslation.PAYMENT_MINUTES, value: "MINUTES" }
			]
		},
		[EInputs.currency]: {
			type: EInputType.select,
			labelTranslation: ETranslation.PAYMENT_CURRENCY,
			value: feeRow.currency,
			options: [
				{ labelTranslation: ETranslation.PAYMENT_EUR, value: "EUR" },
				{ labelTranslation: ETranslation.PAYMENT_USD, value: "USD" }
			]
		},
		[EInputs.descriptionText]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.PAYMENT_DESCRIPTION,
			value: feeRow.descriptionText,
		},
		[EInputs.adminNotesText]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_NOTES,
			value: feeRow.adminNotesText,
		},
		[EInputs.user]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_USER,
			value: feeRow.user ? { label: `${feeRow.user.firstName} ${feeRow.user.lastName}`, value: feeRow.user.id } : "",
		},
		[EInputs.paymentMethod]: {
			type: EInputType.select,
			labelTranslation: ETranslation.COMMON_PAYMENT_METHOD,
			value: feeRow.paymentMethod,
			options: [
				{ labelTranslation: ETranslation.PAYMENT_EEZY, value: "EEZY" },
				{ labelTranslation: ETranslation.PAYMENT_MANUAL, value: "OTHER" },
				{ labelTranslation: ETranslation.PAYMENT_NO_PAYMENT, value: "NO_PAYMENT" },
				{ labelTranslation: ETranslation.PAYMENT_TRUSTER, value: "TRUSTER" }
			]
		},
	});

	const createInput = useCreateInput(inputs, setInputs);

	useEffect(() => {
		const data = getInputData<IFeeRow>(inputs);
		onUpdate(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs])

	return (
		<div key={feeRow.id} className={classes.feeRow}>
			<Button className={classes.deleteButton} color={EButtonColor.DANGER} onClick={() => { onDelete(feeRow) }}> <FontAwesomeIcon icon={faTrash} /> </Button>
			<InputGroup >
				{createInput(EInputs.user, {
					fetchOptions: searchInterpreters
				})}
				{createInput(EInputs.type)}
				{createInput(EInputs.paymentMethod)}
				{createInput(EInputs.unit)}
				{createInput(EInputs.currency)}
			</InputGroup>
			<InputGroup>
				{createInput(EInputs.feeWithoutVat)}
				{createInput(EInputs.feeVatP)}
				{createInput(EInputs.amount)}
				{createInput(EInputs.adminNotesText)}
				{createInput(EInputs.descriptionText)}

			</InputGroup>
		</div>
	)
}

export default FeeRowItemEdit;