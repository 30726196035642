import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ERoute, ERouteStaticParams, Routes } from '../../../classes/Routes';

import Heading from '../../../components/Heading/Heading';
import MessageShowContent from '../../../components/Message/MessageShowContent/MessageShowContent';
import Alert from '../../../components/ui/Alert/Alert';
import Button, { EButtonColor, EButtonSize } from '../../../components/ui/Button/Button';
import Container from '../../../components/ui/Container/Container';
import LabelText from '../../../components/ui/LabelText/LabelText';
import ModalContext, { EModalSize } from '../../../components/ui/Modal/ModalContext';
import Spinner from '../../../components/ui/Spinner/Spinner';
import IAppState from '../../../interfaces/store/IAppState';
import * as actions from '../../../store/actions';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { ETranslation } from '../../../translations/translation-keys';
import { useTranslation } from 'react-i18next';

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const MessageShowPage: React.FC<IProps> = ({ match, history }) => {

  const { setModal, closeModal } = useContext(ModalContext);

  const dispatch = useDispatch();

  const { id } = match.params;

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actions.getMessage(id));
    return () => {
      dispatch(actions.getMessageClear());
      dispatch(actions.deleteMessageClear());
    };
  }, [id, dispatch]);

  const { message, messageError, messageLoading, messageDeleteError, messageDeleteLoading, messageDeleteOk } = useSelector(
    (state: IAppState) => state.message
  );

  useEffect(() => {
    if(messageDeleteOk) {
      history.goBack();
    }
  }, [messageDeleteOk, history]);

  const messageAnswerHandler = () => {
    dispatch(actions.getMessageClear());
    history.push(Routes.withParams(ERoute.MESSAGE_ANSWER, { 
      id: ERouteStaticParams.add, 
      parentMessageId: id 
    }));
  }

  const messageDeleteHandler = () => {
    setModal({
      title: ETranslation.PAGES_MESSAGE_SHOW_PAGE_DELETE_MESSAGE,
      content: <DeleteModal  
        deleteText={ETranslation.PAGES_MESSAGE_SHOW_PAGE_DELETE_MESSAGE_CONFIRM}
        onDelete={() => {
          closeModal();
          dispatch(actions.deleteMessage(id));
        }}
      />,
      isOpen: true, 
      size: EModalSize.SMALL
    })

  }

  const loading = messageLoading || messageDeleteLoading;
  const error = messageError || messageDeleteError;
  
  return (
    <Container>
      {error && <Alert>{error}</Alert>}
      {loading && <Spinner centerAbsolute />}
      {message && (
        <>
          <Heading>{t(ETranslation.COMMON_MESSAGE)}</Heading>
          <MessageShowContent message={message} />
          <LabelText label={t(ETranslation.COMMON_ADDITIONAL_INFO)}>{message.info}</LabelText>

          {message.canAnswer() && (
            <Button onClick={messageAnswerHandler} size={EButtonSize.SMALL}>{t(ETranslation.PAGES_MESSAGE_SHOW_PAGE_ANWSER)}</Button>
          )}
          {message.canDelete() && (
            <Button onClick={messageDeleteHandler} size={EButtonSize.SMALL} color={EButtonColor.DANGER}>{t(ETranslation.COMMON_DELETE)}</Button>
          )}
          
        </>
      )}
    </Container>
  );
};

export default MessageShowPage;
