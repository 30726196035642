import React, { useEffect, useState } from "react";

import { useCreateInput } from "../../../hooks/useCreateInput";
import { IUserGroup } from "../../../interfaces/IUserGroup";
import { ETranslation } from "../../../translations/translation-keys";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { getInputData, initForm, validateInputs } from "../../ui/Input/input-utils";

enum EInputs {
	name = "name",
}

interface IProps {
	onChange: (userGroup: IUserGroup, isValid: boolean) => void;
	userGroup: IUserGroup | null;
	loading?: boolean;
	showValidation?: boolean;
}

const UserGroupEdit: React.FC<IProps> = ({ onChange, userGroup, loading, showValidation }) => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.name]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_NAME,
			value: "",
			validation: {
				required: true,
			},
		},
	});

	useEffect(() => {
		if (userGroup) {
			initForm(setInputs, userGroup);
		}
	}, [userGroup]);

	useEffect(() => {
		const userGroup = getInputData<IUserGroup>(inputs);
		const isValid = validateInputs(inputs);
		onChange(userGroup, isValid);
	}, [inputs, onChange]);

	const createInput = useCreateInput(inputs, setInputs, {
		showValidation,
		disabled: loading,
	});

	return <div>{createInput(EInputs.name)}</div>;
};

export default UserGroupEdit;
