import React, { MouseEvent } from 'react';

import { IUserShiftMonth } from '../../interfaces/IUserShiftMonth';
import Alert from '../ui/Alert/Alert';
import Spinner from '../ui/Spinner/Spinner';
import Table from '../ui/Table/Table';
import { formatYearMonth } from '../../utils/date-utils';

interface IProps {
  userShifts: IUserShiftMonth[] | null;
  loading: boolean;
  error: string | null;
  onClick: (ev: MouseEvent, id: string) => void;
}

const UserShiftsMonthList: React.FC<IProps> = ({
  userShifts,
  loading,
  error,
  onClick,
}) => {
  if (error) {
    return <Alert>{error}</Alert>;
  }

  if (!userShifts || loading) {
    return <Spinner />;
  }

  if (userShifts.length === 0) {
    return <p>Ei työvuoroja</p>;
  }

  return (
    <>
      <Table hover>
        <thead>
          <tr>
            <th>Kuukausi</th>
            <th>Käyttäjä</th>
            <th>Merkityt päivät</th>
          </tr>
        </thead>
        <tbody>
          {userShifts
            .map((userShift, index) => (
              <tr
                key={userShift.id}
                onClick={
                  (ev) => onClick(ev, userShift.id)
                }
              >
                <td>{formatYearMonth(userShift.year, userShift.month)}</td>
                <td>{userShift.userName}</td>
                <td>{userShift.dates.length}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default UserShiftsMonthList;
