import { IAttribute } from "./IAttribute";
import { IUserShiftsDetails } from "./IUserShiftsDetails";

export interface IWorkingHoursDate {
	date: string;
	events: IWorkingHoursDateEvent[];
	id: string;
	userName: string; // Readable name of user, not an actual username
	userId: string;
}

export enum EWorkingHoursDateEventType {
	WORK = "WORK",
	ABSENT_FLEX = "ABSENT_FLEX",
	ANNUAL_LEAVE = "ANNUAL_LEAVE",
	SICK_LEAVE = "SICK_LEAVE",
	ABSENT_UNPAID = "ABSENT_UNPAID",
	NO_SHIFT = "NO_SHIFT",
	FLEX_FIX = "FLEX_FIX",
	ANNUAL_LEAVE_FIX = "ANNUAL_LEAVE_FIX",
}

export interface IWorkingHoursDateEvent {
	date: string;
	description: string;
	endTime: string; // "2023-12-15T14:00:00Z"
	id: string; //This might actually be a key in json
	startTime: string; // "2023-12-15T14:00:00Z"
	type: EWorkingHoursDateEventType | null;
	hours: number;
	travelAmount: number;
	isBreakIncluded: boolean;
	breakMinutes: number;
	details?: IUserShiftsDetails;
	project?: IAttribute;
}
