import { useTranslation } from 'react-i18next';
import { IAttachment } from '../interfaces/IAttachment';
import { ETranslation } from '../translations/translation-keys';
import { IUser, User } from './User';


export enum EMessageStatus {
  DRAFT = 'DRAFT', WAITING = 'WAITING', IN_PROGRESS = 'IN_PROGRESS',
  CANCELLED = 'CANCELLED', NOT_FOUND = 'NOT_FOUND', COMPLETE = 'COMPLETE'
}

export enum EMessageType {
  TRANSLATION_TARGET = 'TRANSLATION_TARGET', TRANSLATION_SOURCE = 'TRANSLATION_SOURCE',
  INTERPRETATION_TARGET = 'INTERPRETATION_TARGET', INTERPRETATION_SOURCE = 'INTERPRETATION_SOURCE',
  ATTACHMENT_TARGET = 'ATTACHMENT_TARGET', ATTACHMENT_SOURCE = 'ATTACHMENT_SOURCE'
}

export enum EMessageContentType {
  AUDIO = 'AUDIO', TEXT = 'TEXT', FILE = 'FILE'
}

export interface IMessage {
  id: string;
  info: string;
  status: EMessageStatus;
  types: EMessageType[];
  user: IUser;
  contentType: EMessageContentType;
  attachment?: IAttachment;
  textContentShort?: string;
  textContentText?: string;
  parentMessageId?: string;
  parentMessageGroupId: string;
  messages?: IMessage[];
  language?: string;
  allowShare?: boolean;
}

export class Message implements IMessage {
  id: string;
  info: string;
  status: EMessageStatus;
  types: EMessageType[];
  user: User;
  contentType: EMessageContentType;
  attachment?: IAttachment;
  textContentShort?: string;
  textContentText?: string;
  parentMessageId?: string;
  parentMessageGroupId: string;
  messages?: Message[];
  language?: string;
  allowShare: boolean;


  constructor(message: IMessage) {


    this.id = message.id;
    this.info = message.info;
    this.status = message.status;
    this.types = message.types;
    this.user = new User(message.user);
    this.contentType = message.contentType;
    this.attachment = message.attachment;
    this.textContentShort = message.textContentShort;
    this.textContentText = message.textContentText;
    this.parentMessageId = message.parentMessageId;
    this.parentMessageGroupId = message.parentMessageGroupId;
    this.messages = Message.fromArray(message.messages);
    this.language = message.language;
    this.allowShare = message.allowShare || false;
  }

  public static fromArray(messages?: IMessage[]): Message[] | undefined {
    if(!messages || !messages.length) return undefined;
    return messages.map(message => new Message(message));
  }

  public canAnswer(): boolean {
    return !this.parentMessageId;
  }

  public canDelete(): boolean {
    return !!this.parentMessageId;
  }

  public canEdit(): boolean {
    return true;
  }

  public isSource(): boolean {
    return this.types && (this.types.includes(EMessageType.TRANSLATION_SOURCE) || this.types.includes(EMessageType.INTERPRETATION_SOURCE) || this.types.includes(EMessageType.ATTACHMENT_SOURCE));
  }

  public isTarget(): boolean {
    return this.types && (this.types.includes(EMessageType.TRANSLATION_TARGET) || this.types.includes(EMessageType.INTERPRETATION_TARGET) || this.types.includes(EMessageType.ATTACHMENT_TARGET));
  }

  public getTypesString(): string {
    const { t } = useTranslation();
    return this.types ? this.types.map(type => {
      switch(type) {
        case EMessageType.INTERPRETATION_SOURCE:
        case EMessageType.INTERPRETATION_TARGET:
          return t(ETranslation.COMMON_RECORDING);
        case EMessageType.TRANSLATION_SOURCE:
        case EMessageType.TRANSLATION_TARGET:
          return t(ETranslation.COMMON_TRANSLATION);
        case EMessageType.ATTACHMENT_SOURCE:
        case EMessageType.ATTACHMENT_TARGET:
          return t(ETranslation.COMMON_ATTACHMENT);
        default:
          return "";
      }
    }).join(', ') : "";
  }

  public getTypesText(): string {
    const { t } = useTranslation();
    if(this.isSource()) return t(ETranslation.COMMON_SOURCE);
    if(this.isTarget()) return t(ETranslation.COMMON_TARGET);
    return "";
  }

  public getContentTypeText(): string {
    const { t } = useTranslation();
    switch (this.contentType) {
      case EMessageContentType.AUDIO:
        return t(ETranslation.COMMON_RECORDING);
      case EMessageContentType.FILE:
        return t(ETranslation.COMMON_FILE);
      case EMessageContentType.TEXT:
        return t(ETranslation.COMMON_TEXT);
    }
  }

}
