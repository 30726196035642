import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "../../components/Heading/Heading";
import Button from "../../components/ui/Button/Button";
import { useTranslation } from "react-i18next";
import { ISummary } from "../../store/actions/summariesActions";
import { ETranslation } from "../../translations/translation-keys";
import SummaryModal from "../../pages/Summaries/SummaryModal";
import ModalContext, { EModalSize } from "../ui/Modal/ModalContext";
import * as actions from "../../store/actions";
import IAppState from "../../interfaces/store/IAppState";
import SummaryInformation from "./SummaryComponents/SummaryInformation";
import SummaryNotes from "./SummaryComponents/SummaryNotes";
import { RouteComponentProps } from "react-router-dom";

interface IProps extends RouteComponentProps {
	summary: ISummary;
	updateAdminNotes: (adminNotes: string) => void;
}


const Summary: React.FC<IProps> = ({ summary, updateAdminNotes, history, match, location }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { setModal, closeModal } = useContext(ModalContext);
	const [selectedSummary, setSelectedSummary] = useState<ISummary>(summary);

	const { updateOk } = useSelector((state: IAppState) => ({
		updateOk: state.summaries.updateOk,
	}));

	useEffect(() => {
		if (!summary) return
		setSelectedSummary(summary);
	}, [summary]);

	const openSummaryModal = () => {
		setModal({
			title: "",
			content: (
				<SummaryModal
					summary={selectedSummary}
					onAccept={updateSummary}
				/>
			),
			isOpen: true,
			size: EModalSize.MEDIUM,
			onModalClose: () => {
				closeModal();
			},
		});
	};

	const updateSummary = (paymentDate: string, message: string) => {
		const summaryToSend = { ...selectedSummary };
		summaryToSend.message = message;
		summaryToSend.paymentDate = paymentDate;
		setSelectedSummary(summaryToSend);
		dispatch(actions.updateSummary(summaryToSend));
	};

	useEffect(() => {
		if (!updateOk) { return; }
		closeModal()
	}, [updateOk, closeModal])


	return (
		<>
			<Button onClick={() => openSummaryModal()}>
				{t(ETranslation.COMMON_EDIT)}
			</Button>
			<Heading>{t(ETranslation.COMMON_SUMMARY)}</Heading>
			<SummaryInformation summary={summary} />
			<SummaryNotes summary={summary} updateAdminNotes={updateAdminNotes} history={history} location={location} match={match}/>
		</>
	);
};

export default Summary;
