import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ILanguage } from "../../../interfaces/ILanguage";
import IAppState from "../../../interfaces/store/IAppState";
import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonColor, EButtonSize } from "../../ui/Button/Button";
import Table from "../../ui/Table/Table";

interface IProps {
	toLanguages: ILanguage[];
	onDelete?: (languageCode: string) => void;
}

const LanguageTable: React.FC<IProps> = ({ toLanguages, onDelete }) => {
	const { t } = useTranslation();

	const { languages, unlinkLoading, id } = useSelector(
		(state: IAppState) => ({
			languages: state.language.languages,
			unlinkLoading: state.language.unlinkLoading,
			id: state.language.id,
		})
	);

	if (!toLanguages || !toLanguages.length) return null;
	return (
		<Table>
			<thead>
				<tr>
					<th>{t(ETranslation.LANGUAGE_LANGUAGE_CODE)}</th>
					<th>{t(ETranslation.SITE_ACTIVE)}</th>
					{onDelete && <th></th>}
				</tr>
			</thead>
			<tbody>
				{toLanguages.map((language) => (
					<tr key={language.languageCode}>
						<td>
							{`${
								languages?.find(
									(l) =>
										l.languageCode === language.languageCode
								)?.name ?? ""
							} (${language.languageCode})`}
						</td>
						<td>
							{language.active && (
								<FontAwesomeIcon icon={faCheckCircle} />
							)}
						</td>
						{onDelete && (
							<td width="1px" style={{ whiteSpace: "nowrap" }}>
								<div style={{ display: "flex", gap: "0.5rem" }}>
									{onDelete && (
										<Button
											color={EButtonColor.DANGER}
											size={EButtonSize.SMALL}
											loading={
												unlinkLoading &&
												id === language.languageCode
											}
											onClick={() =>
												onDelete(language.languageCode)
											}
										>
											{t(ETranslation.COMMON_DELETE)}
										</Button>
									)}
								</div>
							</td>
						)}
					</tr>
				))}
			</tbody>
		</Table>
	);
};

export default LanguageTable;
