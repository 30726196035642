import React from "react";
import { IInterpretationLogRow } from "../../../../interfaces/IInterpretation";
import Table from "../../../ui/Table/Table";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../../translations/translation-keys";


interface Iprops {
	logs: IInterpretationLogRow[];
}

const InterpretationLogRows: React.FC<Iprops> = ({ logs }) => {

	const { t } = useTranslation();
	return <Table>
		<thead>
			<tr>
				<th>{t(ETranslation.COMMON_DATE)}</th>
				<th>{t(ETranslation.COMMON_TYPE)}</th>
				<th>{t(ETranslation.COMMON_USER)}</th>
				<th>{t(ETranslation.COMMON_USER_AGENT)}</th>
			</tr>
		</thead>
		<tbody>
			{logs.map((log) => {
				return <tr key={log.id}>
					<td>
						{log.logDateTime}
					</td>
					<td>
						{log.logType}<br />
						{log.details}
					</td>
					<td>
						{log.userFirstName} {log.userLastName}<br />
						{log.userEmail}
					</td>
					<td>
						{log.userAgent}
					</td>
				</tr>
			})}
		</tbody>
	</Table>
}

export default InterpretationLogRows;