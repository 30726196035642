import React, { useContext } from "react";
import ModalContext, { EModalSize } from "../components/ui/Modal/ModalContext";
import InterpretationSendConfirmationEmailsModal from "../components/Interpretation/InterpretationSendConfirmationEmailsModal/InterpretationSendConfirmationEmailsModal";


export const useInterpretationSendConfirmationEmailsModal = () => {
  const { setModal, closeModal } = useContext(ModalContext);

  const handler = (): Promise<string[]> => {
    return new Promise((resolve) => {
      setModal({
        isOpen: true,
        size: EModalSize.SMALL,
        title: "Lähetä tilausvahvistukset",
        content: (
          <InterpretationSendConfirmationEmailsModal
            onConfirm={(emails) => {
              closeModal();
              resolve(emails);
            }}
          />
        ),
      });
    });
  };

  return handler;
};
