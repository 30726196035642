import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IPriceSeason } from '../../../interfaces/IPriceSeason';
import { ETranslation } from '../../../translations/translation-keys';
import Alert from '../../ui/Alert/Alert';
import Input, { EInputType, TInputValue } from '../../ui/Input/Input';
import Spinner from '../../ui/Spinner/Spinner';
import Table from '../../ui/Table/Table';
import PriceSeasonListItem from './PriceSeasonListItem/PriceSeasonListItem';

interface IProps {
  priceSeasons: IPriceSeason[] | null;
  loading: boolean;
  error: string | null;
  onClick: (id?: string) => void;
}

const PriceSeasonList: React.FC<IProps> = ({
  priceSeasons,
  loading,
  error,
  onClick,
}) => {
  const [filter, setFilter] = useState("");
  const { t } = useTranslation();
  if (error) {
    return <Alert>{error}</Alert>;
  }

  if (!priceSeasons || loading) {
    return <Spinner />;
  }

  if (priceSeasons.length === 0) {
    return <p>{t(ETranslation.PRICE_SEASON_NO_PRICE_SEASONS)}</p>;
  }

  const filterPriceSeason = (item: IPriceSeason): boolean => {
    return (
      filter.length === 0 ||
      item.name?.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  };

  return (
    <>
      <Input
        containerStyles={{ margin: "1rem 0" }}
        type={EInputType.text}
        inputName="filter"
        value={filter}
        onChange={(value: TInputValue) => setFilter(value as string)}
        placeholderTranslation={ETranslation.COMMON_FILTER_BY_NAME}
      />
      <Table hover>
        <thead>
          <tr>
            <th>{t(ETranslation.COMMON_NAME)}</th>
            <th>{t(ETranslation.PRICE_SEASON_PERIOD)}</th>
            <th style={{ width: "1px" }}>{t(ETranslation.PRICE_SEASON_ACTIVE)}</th>
            <th style={{ width: "1px" }}></th>
            <th style={{ width: "1px" }}></th>
          </tr>
        </thead>
        <tbody>
          {priceSeasons.filter(filterPriceSeason).map((priceSeason) => (
            <PriceSeasonListItem
              key={priceSeason.id}
              priceSeason={priceSeason}
              onEdit={onClick}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default PriceSeasonList;
