import { EInterpretationStatus, INTERPRETATION_STATUS_SELECT } from "../../../shared/interpretation-data";
import { ETranslation } from "../../../translations/translation-keys";
import { EInputType, IInputField } from "../../ui/Input/Input";
import * as interpretationUtils from '../../../utils/interpretation-utils';

export enum EInputs {
	subtypeIds = "subtypeIds",
	startDate = "startDate",
	endDate = "endDate",
	startTime = "startTime",
	endTime = "endTime",
	interpreter = "interpreter",
	customer = "customer",
	type = "type",
	status = "status",
	adminNotesText = "adminNotesText",
	customerReference = "customerReference",
	interpreterInfoText = "interpreterInfoText",
	address = "address",
	zip = "zip",
	city = "city",
	info = "info",
}
export const InterpretationAddOthersDefaultForm: IInputField = {
	[EInputs.type]: {
		type: EInputType.radio,
		labelTranslation: ETranslation.COMMON_TYPE,
		value: "",
		validation: {
			required: true
		}
	},
	[EInputs.subtypeIds]: {
		type: EInputType.reactSelect,
		labelTranslation: ETranslation.COMMON_SUBTYPES,
		value: [],
		validation: {
			required: true
		}
	},
	[EInputs.startDate]: {
		type: EInputType.date,
		labelTranslation: ETranslation.COMMON_START_DATE,
		value: "",
		validation: {
			required: true
		}
	},
	[EInputs.endDate]: {
		type: EInputType.date,
		labelTranslation: ETranslation.COMMON_END_DATE,
		value: "",
		validation: {
			requiredIf: [{ inputName: EInputs.type, value: interpretationUtils.INTERPRETATION_SUBSTITUTE_TYPES }]
		}
	},
	[EInputs.startTime]: {
		type: EInputType.time,
		labelTranslation: ETranslation.COMMON_START_TIME,
		value: "",
		validation: {
			requiredIfNot: [{ inputName: EInputs.type, value: interpretationUtils.INTERPRETATION_SUBSTITUTE_TYPES }]
		}
	},
	[EInputs.endTime]: {
		type: EInputType.time,
		labelTranslation: ETranslation.COMMON_END_TIME,
		value: "",
		validation: {
			requiredIfNot: [{ inputName: EInputs.type, value: interpretationUtils.INTERPRETATION_SUBSTITUTE_TYPES }]
		}
	},
	[EInputs.interpreter]: {
		type: EInputType.reactSelectSearch,
		value: "",
		labelTranslation: ETranslation.COMMON_INTERPRETER,
		validation: {
			required: false
		}
	},
	[EInputs.customer]: {
		type: EInputType.reactSelectSearch,
		value: "",
		labelTranslation: ETranslation.COMMON_CUSTOMER,
		validation: {
			required: true
		}
	},
	[EInputs.status]: {
		type: EInputType.radio,
		value: EInterpretationStatus.OFFER_BOOKING,
		labelTranslation: ETranslation.COMMON_STATUS,
		options: INTERPRETATION_STATUS_SELECT,
	},
	[EInputs.adminNotesText]: {
		type: EInputType.textarea,
		value: "",
		labelTranslation: ETranslation.COMMON_ADMIN_NOTES,
	},
	[EInputs.customerReference]: {
		type: EInputType.text,
		value: "",
		labelTranslation: ETranslation.COMMON_CUSTOMER_REFERENCE,
	},
	[EInputs.interpreterInfoText]: {
		type: EInputType.textarea,
		value: "",
		labelTranslation: ETranslation.COMMON_INFO_TO_INTERPRETER,
	},
}
