import React, { useContext, useState } from "react";
import ModalBody from "../../ui/Modal/ModalBody/ModalBody";
import ModalFooter from "../../ui/Modal/ModalFooter/ModalFooter";
import Button, { EButtonColor } from "../../ui/Button/Button";
import { ETranslation } from "../../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import ModalContext from "../../ui/Modal/ModalContext";
import CreatableSelect from "react-select/creatable";
import { IOption } from "../../ui/Input/Input";

interface IProps {
	onConfirm: (emails: string[]) => void;
}

const InterpretationSendConfirmationEmailsModal: React.FC<IProps> = ({
	onConfirm,
}) => {
  const [emails, setEmails] = useState<IOption[]>([]);
	const { t } = useTranslation();

	const { closeModal } = useContext(ModalContext);

	return (
		<>
			<ModalBody>
        <p style={{ marginTop: 0 }}>Tilausvahvistukset lähetetään vain tulkkauksista, joissa asiakkaan maksutapa on Laskutus</p>

				<label style={{ marginBottom: '.25rem', fontWeight: 'bold', display: 'block'}}>Vastaanottajien sähköpostiosoitteet</label>
				<CreatableSelect
					isMulti={true}
          noOptionsMessage={() => "Lisää vastaanottajat kirjoittamalla"}
          value={emails}
          placeholder="Lisää vastaanottajat"
          onCreateOption={(value) => { setEmails(emails => [...emails, {value, label: value }]) }}
          formatCreateLabel={(value) => `Lisää "${value}"` }
				/>
			</ModalBody>
			<ModalFooter>
				<Button disabled={emails.length === 0} onClick={() => onConfirm(emails.map(option => option.value))} color={EButtonColor.SUCCESS}>
					{t(ETranslation.COMMON_YES)}
				</Button>
				<Button onClick={closeModal} color={EButtonColor.DEFAULT}>
					{t(ETranslation.COMMON_RETURN)}
				</Button>
			</ModalFooter>
		</>
	);
};

export default InterpretationSendConfirmationEmailsModal;
