import React from 'react';
import { TInputValue } from './Input';


const InputContext = React.createContext<{
  onAutoUpdate: (inputName: string, value: TInputValue, action: string, data?: Object) => Promise<boolean>;
  initDone: boolean;
}>({
  onAutoUpdate: async (inputName, value, action, data) => { return false },
  initDone: false
});

export default InputContext;
