import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute, Routes } from '../../classes/Routes';
import Heading from '../../components/Heading/Heading';
import InfoContactRequestList from '../../components/InfoContactRequest/InfoContactRequestList/InfoContactRequestList';
import Container from '../../components/ui/Container/Container';
import IAppState from '../../interfaces/store/IAppState';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';

interface IProps extends RouteComponentProps {}

const InfoContactRequestsListPage: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { infoContactRequests, loading, error } = useSelector(
    (state: IAppState) => ({
      infoContactRequests: state.infoContactRequest.infoContactRequests,
      loading: state.infoContactRequest.infoContactRequestsLoading,
      error: state.infoContactRequest.infoContactRequestsError,
    })
  );

  useEffect(() => {
    dispatch(actions.findInfoContactRequests());
  }, [dispatch]);

  const openHandler = (id?: string) => {
    if (!id) return;
    history.push(Routes.withParams(ERoute.INFO_CONTACT_REQUEST_EDIT, { id }));
  };

  return (
    <Container>
      <Heading>{t(ETranslation.MENU_CONTACT_REQUESTS)}</Heading>
      <InfoContactRequestList
        infoContactRequests={infoContactRequests}
        loading={loading}
        error={error}
        onClick={openHandler}
      />
    </Container>
  );
};

export default InfoContactRequestsListPage;
