import * as dateFns from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateInput } from '../../hooks/useCreateInput';
import { useLanguageName } from '../../hooks/useLanguageName';

import { useLanguages } from '../../hooks/useLanguages';
import { EInfoContactRequestSubject, IInfoContactRequest } from '../../interfaces/IInfoContactRequest';
import {
  INFO_CONTACT_REQUEST_MUNICIPALITIES,
  INFO_CONTACT_REQUEST_STATUSES,
  INFO_CONTACT_REQUEST_SUBJECTS,
} from '../../shared/info-contact-request-data';
import { ETranslation } from '../../translations/translation-keys';
import { ECommonValue, EInputType, IInputField } from '../ui/Input/Input';
import { getInputData, initForm, validateInputs } from '../ui/Input/input-utils';
import LabelText from '../ui/LabelText/LabelText';


enum EInputs {
  status = 'status',
  subject = "subject",
  subjectOther = "subjectOther",
  municipality = "municipality",
  needInterpreter = "needInterpreter",
  toLanguage = "toLanguage",
  notes = 'notes'
}

interface IProps {
  onChange: (infoContactRequest: IInfoContactRequest, isValid: boolean) => void;
  infoContactRequest: IInfoContactRequest | null;
  loading?: boolean;
  showValidation?: boolean;
}

const InfoContactRequest: React.FC<IProps> = ({ onChange, loading, infoContactRequest, showValidation}) => {
  const { t } = useTranslation();
  
  const { languageOptions } = useLanguages();

  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.status]: {
      type: EInputType.select,
      labelTranslation: ETranslation.COMMON_STATUS,
      value: "",
      options: INFO_CONTACT_REQUEST_STATUSES,
      validation: {
        required: true
      }
    },
    [EInputs.subject]: {
      type: EInputType.select,
      labelTranslation: ETranslation.CONTACT_REQUEST_SUBJECT,
      value: "",
      options: INFO_CONTACT_REQUEST_SUBJECTS,
      validation: {
        required: true
      }
    },
    [EInputs.subjectOther]: {
      type: EInputType.text,
      labelTranslation: ETranslation.CONTACT_REQUEST_OTHER_SUBJECT,
      value: "",
    },
    [EInputs.municipality]: {
      type: EInputType.select,
      labelTranslation: ETranslation.CONTACT_REQUEST_MUNICIPALITY,
      value: "",
      options: INFO_CONTACT_REQUEST_MUNICIPALITIES,
      validation: {
        required: true
      }
    },
    [EInputs.needInterpreter]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.CONTACT_REQUEST_NEED_INTERPRETER,
      value: [],
      options: [{ value: ECommonValue.YES }],
    },
    [EInputs.toLanguage]: {
      type: EInputType.select,
      labelTranslation: ETranslation.CONTACT_REQUEST_TO_LANGUAGE,
      value: "",
    },
    [EInputs.notes]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.CONTACT_REQUEST_NOTES,
      value: "",
    },
  });

  useEffect(() => {
    if (infoContactRequest) {
      initForm(setInputs, infoContactRequest);
    }
  }, [infoContactRequest]);

  useEffect(() => {
    const infoContactRequest = getInputData<IInfoContactRequest>(inputs);
    const isValid = validateInputs(inputs);
    onChange(infoContactRequest, isValid);
  }, [inputs, onChange]);

  const createInput = useCreateInput(inputs, setInputs, {
    showValidation,
    disabled: loading
  });

  const subject = inputs[EInputs.subject].value as EInfoContactRequestSubject;

  const user = infoContactRequest?.user;
  const canceledDate = infoContactRequest?.canceledDate;
  
  const language = useLanguageName(user?.language);

  return (
    <>
      <LabelText label={t(ETranslation.COMMON_LANGUAGE)}>{language}</LabelText>
      <LabelText label={t(ETranslation.COMMON_PHONENUMBER)}><a href={`tel:${user?.phoneNumber}`}>{user?.phoneNumber}</a></LabelText>
      {canceledDate && (
        <LabelText label={t(ETranslation.CONTACT_REQUEST_CANCELED_DATE)}>{dateFns.format(dateFns.parseISO(canceledDate), 'dd.MM.yyyy HH:mm')}</LabelText>
      )}
      {createInput(EInputs.status)}
      {createInput(EInputs.subject)}
      {subject === EInfoContactRequestSubject.OTHER && createInput(EInputs.subjectOther)}
      {createInput(EInputs.municipality)}
      {createInput(EInputs.needInterpreter)}
      {createInput(EInputs.toLanguage, {
        options: languageOptions
      })}
      {createInput(EInputs.notes)}
      <br />
    </>
  );
};

export default InfoContactRequest;
