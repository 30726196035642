import React, { ReactNode, CSSProperties } from "react";
import classes from "./Button.module.scss";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner, { ESpinnerColor, ESpinnerSize } from "../Spinner/Spinner";

export enum EButtonSize {
  X_SMALL,
  SMALL,
  MEDIUM,
  LARGE,
}

export enum EButtonColor {
  PRIMARY,
  SUCCESS,
  DANGER,
  DEFAULT,
  WHITE
}

interface IProps {
  icon?: IconDefinition;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  size?: EButtonSize;
  color?: EButtonColor;
  loading?: boolean;
  buttonRef?: React.RefObject<HTMLButtonElement>;
}

const Button: React.FC<IProps> = ({
  icon,
  onClick,
  disabled,
  children,
  className,
  style,
  size,
  color = EButtonColor.SUCCESS,
  loading,
  buttonRef,
}) => {
  const classNames = [classes.Button];
  if (disabled) {
    classNames.push(classes.Disabled);
  }
  if (className) {
    classNames.push(className);
  }

  switch (size) {
    case EButtonSize.X_SMALL:
      classNames.push(classes.XSmall);
      break;
    case EButtonSize.SMALL:
      classNames.push(classes.Small);
      break;
    case EButtonSize.LARGE:
      classNames.push(classes.Large);
      break;
    default:
  }

  switch (color) {
    case EButtonColor.SUCCESS:
      classNames.push(classes.Success);
      break;
    case EButtonColor.DANGER:
      classNames.push(classes.Danger);
      break;
    case EButtonColor.DEFAULT:
      classNames.push(classes.Default);
      break;
    case EButtonColor.WHITE:
      classNames.push(classes.White);
      break;
    default:
      classNames.push(classes.Primary);
      break;
  }

  return (
    <button
      className={classNames.join(" ")}
      onClick={onClick}
      disabled={disabled || loading}
      type="button"
      style={style}
      ref={buttonRef}
    >
      {loading ? (
        <Spinner size={ESpinnerSize.SMALL} color={ESpinnerColor.CONTENT} />
      ) : (
        <>
          {icon && <FontAwesomeIcon icon={icon} />} {children}
        </>
      )}
    </button>
  );
};

export default Button;
