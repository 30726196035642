import React, { ReactNode, useContext } from "react";
import ModalContext, { EModalSize } from "../components/ui/Modal/ModalContext";
import ConfirmModal from "../components/ConfirmModal/ConfirmModal";


export const useConfirmModal = () => {
  const { setModal, closeModal } = useContext(ModalContext);

  const handler = (text: ReactNode): Promise<boolean> => {
    return new Promise((resolve) => {
      setModal({
        isOpen: true,
        size: EModalSize.SMALL,
        title: "Vahvista toiminto",
        content: (
          <ConfirmModal
            onAccept={() => {
              closeModal();
              resolve(true);
            }}
            onCancel={() => {
              closeModal();
              resolve(false);
            }}
          >
            {text}
          </ConfirmModal>
        ),
      });
    });
  };

  return handler;
};
