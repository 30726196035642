import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute, Routes } from '../../../classes/Routes';
import Button from '../../../components/ui/Button/Button';
import Container from '../../../components/ui/Container/Container';
import IAppState from '../../../interfaces/store/IAppState';
import * as actions from '../../../store/actions';
import Heading from '../../../components/Heading/Heading';
import MessageGroupList from '../../../components/Message/MessageGroupList/MessageGroupList';
import { IMessageGroup } from '../../../classes/MessageGroup';
import { ETranslation } from '../../../translations/translation-keys';
import { useTranslation } from 'react-i18next';

interface IProps extends RouteComponentProps {}

const MessageGroupsListPage: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { messageGroups, loading, error, saveOrUpdateMessageGroup, saveOrUpdateLoading } = useSelector(
    (state: IAppState) => ({
      saveOrUpdateLoading: state.message.saveOrUpdateLoading,
      saveOrUpdateMessageGroup: state.message.saveOrUpdateMessageGroup,
      messageGroups: state.message.messageGroups,
      loading: state.message.messageGroupsLoading,
      error: state.message.messageGroupsError || state.message.saveOrUpdateError,
    })
  );

  const openHandler = useCallback((id?: string) => {
    if (!id) return;
    history.push(Routes.withParams(ERoute.MESSAGE_GROUP_EDIT, { id }));
  }, [history]);

  useEffect(() => {
    if(saveOrUpdateMessageGroup) {
      openHandler(saveOrUpdateMessageGroup.id);
      dispatch(actions.messageSaveOrUpdateClear());
    }

  }, [dispatch, openHandler, saveOrUpdateMessageGroup])
  

  useEffect(() => {
    dispatch(actions.findMessageGroups());
  }, [dispatch]);

  const addHandler = () => {
    dispatch(actions.saveMessageGroup({} as IMessageGroup));
  };

  

  return (
    <Container>
      <Heading>{t(ETranslation.MENU_RECORDINGS_AND_TRANSLATIONS)}</Heading>
      <Button loading={saveOrUpdateLoading} onClick={addHandler}>{t(ETranslation.COMMON_ADD_NEW)}</Button>
      <MessageGroupList
        messageGroups={messageGroups}
        loading={loading}
        error={error}
        onClick={openHandler}
      />
    </Container>
  );
};

export default MessageGroupsListPage;
