import React, { ReactNode } from 'react';
import classes from './ContainerRow.module.scss';

interface IProps {
  children: ReactNode;
}

const ContainerRow:React.FC = ({ children }) => {
  return (
    <div className={classes.ContainerRow}>
        {children}
    </div>
  )
}

export default ContainerRow;