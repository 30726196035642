import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import IAppState from "../../../interfaces/store/IAppState";
import ICommonState from "../../../interfaces/store/ICommonState";

export const useBounce = (delay: number = 300) => {
	const timer = useRef<NodeJS.Timeout | null>(null);
	const bounceHandler = useCallback((callback: VoidFunction) => {
		if (timer.current) clearTimeout(timer.current);
		timer.current = setTimeout(() => {
			callback();
		}, delay)
	}, [delay]);
	return bounceHandler;
}

export const useLanguageFilter = (language: string[]) => {
	const [languageFilter, setLanguageFilter] = useState<string[] | null>(null);
	const { languages } = useSelector<IAppState, ICommonState>((state) => state.common);
	const bounce = useBounce();
	useEffect(() => {
		if (language && language.length > 0 && languages) {
			bounce(() => {
				const matchIds = languages.filter(lang => language.some(lang2 => lang.id === lang2)).map(lang => lang.id);
				setLanguageFilter(matchIds);
			});
		} else {
			setLanguageFilter(null);
		}
	}, [language, bounce, languages]);

	return languageFilter;
}