import React from "react";
import { IAttribute } from "../../interfaces/IAttribute";
import Badge, { EBadgeType } from "../ui/Badge/Badge";

interface IProps {
	attributes: IAttribute[];
	onDelete: (id: string) => void;
}

const AttributesBadgeList: React.FC<IProps> = ({ attributes, onDelete }) => {
	return (
		<div style={{ marginTop: ".5rem", display: "flex", gap: ".5rem" }}>
			{attributes.map((attr) => (
				<Badge key={attr.id} type={EBadgeType.DEFAULT} uppercase={false} onDelete={() => onDelete(attr.id)}>
					{attr.name}
				</Badge>
			))}
		</div>
	);
};

export default AttributesBadgeList;
