import { faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties } from 'react';

import classes from './StatisticsPriceBox.module.scss';

export enum EStatisticsColor {
  INFO = "INFO", SUCCESS = "SUCCESS", SUCCESSDARK="SUCCESSDARK", LIGHTRED="LIGHTRED" 
}


interface IProps {
  price: number;
  percent: number;
  children: string;
  labelInfo?: string;
  boxColor?: EStatisticsColor;
  labelColor?: EStatisticsColor;
  style?: CSSProperties;
  unit?: string;
  isPercent?: string;
}

const StatisticsPriceBox: React.FC<IProps> = ({ price, percent, children, labelInfo, boxColor = EStatisticsColor.INFO, labelColor, style, unit, isPercent }) => {

  const labelClassNames = [classes.Label];

  if (labelColor) {
    switch (labelColor) {
      case EStatisticsColor.INFO:
        labelClassNames.push(classes.LabelInfo);
        break;
      case EStatisticsColor.SUCCESS:
        labelClassNames.push(classes.LabelSuccess);
        break;
      case EStatisticsColor.SUCCESSDARK:
        labelClassNames.push(classes.LabelSuccessDark);
        break;
    }
  }

  const boxClassNames = [classes.Box];

  switch (boxColor) {
    case EStatisticsColor.INFO:
      boxClassNames.push(classes.BoxInfo);
      break;
    case EStatisticsColor.SUCCESS:
      boxClassNames.push(classes.BoxSuccess);
      break;
    case EStatisticsColor.SUCCESSDARK:
      boxClassNames.push(classes.BoxSuccessDark);
      break;
    case EStatisticsColor.LIGHTRED:
      boxClassNames.push(classes.BoxLightRed);
      break;

  }

  const percentClassNames = [classes.Percent];
  let percentIcon;
  if(percent >= 0) {
    percentClassNames.push(classes.PercentUp);
    percentIcon = faLongArrowAltUp;
  } else {
    percentClassNames.push(classes.PercentDown);
    percentIcon = faLongArrowAltDown;
  }

  var unitVariable="";
  if (unit) {
    if (unit === "minutes") {
      unitVariable="min";
    } else if (unit === "euros") {
      unitVariable="€";
    } else if (unit === "count") {
      unitVariable="kpl";
    } 
  }

  var percentIfPercent="";
  if (isPercent) {
    if (isPercent === "yes") {
      percentIfPercent="%";
    }
  }

  return (
    <div className={classes.Container}>
      <label className={labelClassNames.join(' ')}>{children}</label>
      {labelInfo && (<p className={classes.LabelInfo}>{labelInfo}</p>)}
      <div className={boxClassNames.join(' ')} style={style}>
        <div className={classes.Price}>{price} {unitVariable}</div>
        <div className={percentClassNames.join(' ')}><FontAwesomeIcon icon={percentIcon} /> {percent}{percentIfPercent}</div>
      </div>
    </div>
  );
}

export default StatisticsPriceBox;