import { MessageGroup } from './../../classes/MessageGroup';
import { Message } from './../../classes/Message';
import { Dispatch } from 'redux';
import { IMessage } from '../../classes/Message';

import { customFetch, EFetchMethod } from '../../custom-fetch';
import { IMessageGroup } from '../../classes/MessageGroup';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IMessageAction from '../../interfaces/store/IMessageAction';

type TAction = IMessageAction;

const findMessageGroupsStart = (): TAction => {
  return {
    type: EActionTypes.MESSAGE_GROUP_FIND_START,
  };
};

const findMessageGroupsSuccess = (messageGroups?: MessageGroup[]): TAction => {
  return {
    type: EActionTypes.MESSAGE_GROUP_FIND_SUCCESS,
    messageGroups,
  };
};

const findMessageGroupsFail = (error: string): TAction => {
  return {
    type: EActionTypes.MESSAGE_GROUP_FIND_FAIL,
    error,
  };
};

export const findMessageGroups = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(findMessageGroupsStart());
      const messageGroups = await customFetch<IMessageGroup[]>("/messagegroups/list");
      dispatch(findMessageGroupsSuccess(MessageGroup.fromArray(messageGroups)));
    } catch (error) {
      dispatch(findMessageGroupsFail((error as Error).message));
    }
  };
};

const getMessageGroupStart = (id: string): TAction => {
  return {
    type: EActionTypes.MESSAGE_GROUP_GET_START,
    id
  };
};

const getMessageGroupSuccess = (messageGroup: MessageGroup): TAction => {
  return {
    type: EActionTypes.MESSAGE_GROUP_GET_SUCCESS,
    messageGroup,
  };
};

const getMessageGroupFail = (error: string): TAction => {
  return {
    type: EActionTypes.MESSAGE_GROUP_GET_FAIL,
    error,
  };
};

export const getMessageGroupClear = (): TAction => {
  return {
    type: EActionTypes.MESSAGE_GROUP_GET_CLEAR,
  };
};

export const getMessageGroup = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getMessageGroupStart(id));
      const messageGroup = await customFetch<IMessageGroup>(`/messagegroups/get?id=${id}`);
      dispatch(getMessageGroupSuccess(new MessageGroup(messageGroup)));
    } catch (error) {
      dispatch(getMessageGroupFail((error as Error).message));
    }
  };
};

const deleteMessageGroupStart = (): TAction => {
  return {
    type: EActionTypes.MESSAGE_GROUP_DELETE_START,
  };
};

const deleteMessageGroupSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.MESSAGE_GROUP_DELETE_SUCCESS,
    id,
  };
};

const deleteMessageGroupFail = (error: string): TAction => {
  return {
    type: EActionTypes.MESSAGE_GROUP_DELETE_FAIL,
    error,
  };
};

export const deleteMessageGroupClear = (): TAction => {
  return {
    type: EActionTypes.MESSAGE_GROUP_DELETE_CLEAR,
  };
};

export const deleteMessageGroup = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deleteMessageGroupStart());
      await customFetch<boolean>(`/messagegroups/delete?id=${id}`, EFetchMethod.DELETE);
      dispatch(deleteMessageGroupSuccess(id));
    } catch (error) {
      dispatch(deleteMessageGroupFail((error as Error).message));
    }
  };
};

const saveOrUpdateMessageGroupStart = (): TAction => {
  return {
    type: EActionTypes.MESSAGE_GROUP_SAVE_OR_UPDATE_START,
  };
};

const saveOrUpdateMessageGroupSuccess = (messageGroup: MessageGroup): TAction => {
  return {
    type: EActionTypes.MESSAGE_GROUP_SAVE_OR_UPDATE_SUCCESS,
    messageGroup
  };
};

const saveOrUpdateMessageGroupFail = (error: string): TAction => {
  return {
    type: EActionTypes.MESSAGE_GROUP_SAVE_OR_UPDATE_FAIL,
    error,
  };
};

export const saveMessageGroup = (messageGroup: IMessageGroup) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveOrUpdateMessageGroupStart());
      const saveMessageGroup = await customFetch<IMessageGroup>("/messagegroups/add", EFetchMethod.POST, JSON.stringify(messageGroup));
      dispatch(saveOrUpdateMessageGroupSuccess(new MessageGroup(saveMessageGroup)));
    } catch (error) {
      dispatch(saveOrUpdateMessageGroupFail((error as Error).message));
    }
  };
};

export const updateMessageGroup = (messageGroup: IMessageGroup) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveOrUpdateMessageGroupStart());
      const updateMessageGroup = await customFetch<IMessageGroup>("/messagegroups/update", EFetchMethod.PUT, JSON.stringify(messageGroup));
      dispatch(saveOrUpdateMessageGroupSuccess(new MessageGroup(updateMessageGroup)));
    } catch (error) {
      dispatch(saveOrUpdateMessageGroupFail((error as Error).message));
    }
  };
};


const getMessageStart = (id: string): TAction => {
  return {
    type: EActionTypes.MESSAGE_GET_START,
    id
  };
};

const getMessageSuccess = (message: Message): TAction => {
  return {
    type: EActionTypes.MESSAGE_GET_SUCCESS,
    message,
  };
};

const getMessageFail = (error: string): TAction => {
  return {
    type: EActionTypes.MESSAGE_GET_FAIL,
    error,
  };
};

export const getMessageClear = (): TAction => {
  return {
    type: EActionTypes.MESSAGE_GET_CLEAR,
  };
};

export const getMessage = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getMessageStart(id));
      const message = await customFetch<IMessage>(`/messages/get?id=${id}`);
      dispatch(getMessageSuccess(new Message(message)));
    } catch (error) {
      dispatch(getMessageFail((error as Error).message));
    }
  };
};

const saveOrUpdateMessageStart = (): TAction => {
  return {
    type: EActionTypes.MESSAGE_SAVE_OR_UPDATE_START,
  };
};

const saveOrUpdateMessageSuccess = (message: Message): TAction => {
  return {
    type: EActionTypes.MESSAGE_SAVE_OR_UPDATE_SUCCESS,
    message
  };
};

const saveOrUpdateMessageFail = (error: string): TAction => {
  return {
    type: EActionTypes.MESSAGE_SAVE_OR_UPDATE_FAIL,
    error,
  };
};

export const messageSaveOrUpdateClear = (): TAction => {
  return {
    type: EActionTypes.MESSAGE_SAVE_OR_UPDATE_CLEAR
  }
}

export const saveMessage = (message: IMessage) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveOrUpdateMessageStart());
      const respMessage = await customFetch<IMessage>("/messages/add", EFetchMethod.POST, JSON.stringify(message));
      dispatch(saveOrUpdateMessageSuccess(new Message(respMessage)));
    } catch (error) {
      dispatch(saveOrUpdateMessageFail((error as Error).message));
    }
  };
};

export const updateMessage = (message: IMessage) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveOrUpdateMessageStart());
      const respMessage = await customFetch<IMessage>("/messages/update", EFetchMethod.PUT, JSON.stringify(message));
      dispatch(saveOrUpdateMessageSuccess(new Message(respMessage)));
    } catch (error) {
      dispatch(saveOrUpdateMessageFail((error as Error).message));
    }
  };
};

const deleteMessageStart = (): TAction => {
  return {
    type: EActionTypes.MESSAGE_DELETE_START,
  };
};

const deleteMessageSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.MESSAGE_DELETE_SUCCESS,
    id,
  };
};

const deleteMessageFail = (error: string): TAction => {
  return {
    type: EActionTypes.MESSAGE_DELETE_FAIL,
    error,
  };
};

export const deleteMessageClear = (): TAction => {
  return {
    type: EActionTypes.MESSAGE_DELETE_CLEAR,
  };
};

export const deleteMessage = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deleteMessageStart());
      await customFetch<boolean>(`/messages/delete?id=${id}`, EFetchMethod.DELETE);
      dispatch(deleteMessageSuccess(id));
    } catch (error) {
      dispatch(deleteMessageFail((error as Error).message));
    }
  };
};
