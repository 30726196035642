import { TFunction } from 'i18next';
import { IAttribute } from '../interfaces/IAttribute';
import { ECategoryType, ICategory } from '../interfaces/ICategory';
import { ETranslation } from '../translations/translation-keys';

interface IAttributeGroup extends ICategory {
  attributes: IAttribute[];
}

export const isValuesAttribute = (attribute: IAttribute) => {
	const category = attribute.category;
	if (!category) return false;
	const settings = category.settings;
	if (!settings) return false;
	return settings.isExpirationDate;
}


export const groupAttributes = (attributes: IAttribute[], t: TFunction) => {
  return attributes.reduce<IAttributeGroup[]>((acc, attribute) => {
    const category: ICategory = attribute.category ?? {
      id: 'OTHER',
      name: t(ETranslation.COMMON_OTHERS).toUpperCase(),
	  type: ECategoryType.INTERPRETATION_SEARCH,
      translations: [],
    };
    const key = category.id;
    const index = acc.findIndex(item => item.id === key);
    let item: IAttributeGroup;
    if (index === -1) {
      item = {
        ...category,
        attributes: [],
      };
      acc.push(item);
    } else {
      item = acc[index];
    }
    item.attributes.push(attribute);
    return acc;
  }, []);
}