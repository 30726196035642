import React from 'react';
import { useTranslation } from 'react-i18next';
import { IOrganization } from '../../../interfaces/IOrganization';
import { ETranslation } from '../../../translations/translation-keys';
import Alert from '../../ui/Alert/Alert';
import Spinner from '../../ui/Spinner/Spinner';
import Table from '../../ui/Table/Table';
import { useSelector } from 'react-redux';
import IAppState from '../../../interfaces/store/IAppState';
import { getSiteUserRoles } from '../../../shared/site-data';

interface IProps {
	organizations: IOrganization[] | null;
	loading: boolean;
	error: string | null;
	onClick: (id: string) => void;
}

const OrganizationList: React.FC<IProps> = ({
	organizations,
	loading,
	error,
	onClick,
}) => {
	const { t } = useTranslation();

	const { currentUser } = useSelector((state: IAppState) => ({
		currentUser: state.auth.user
	}));

	if (error) {
		return <Alert>{error}</Alert>;
	}

	if (!organizations && loading) {
		return <Spinner />;
	}

	// Quick and dirty for meeting. Fix this
	const translateType = (type: IOrganization["type"]) =>{
		if(!type) return "";
		const option = getSiteUserRoles(type)
		if(!option || !option.labelTranslation) return "";
		return t(option.labelTranslation);

	}

	return (
		<>
			<Table hover={true}>
				<thead>
					<tr>
						<th></th>
						<th>{t(ETranslation.COMMON_NAME)}</th>
						<th>{t(ETranslation.COMMON_TYPE)}</th>
						{currentUser?.isYoupretAdmin && <th>{t(ETranslation.COMMON_SITE)}</th>}
						<th>{t(ETranslation.ORGANIZATION_COMPANY_ORGANIZATIONGROUP)}</th>
						<th>{t(ETranslation.ORGANIZATION_COMPANY_BOOKINGEMAIL)}</th>
						<th>{t(ETranslation.COMMON_DESCRIPTION)}</th>
					</tr>
				</thead>
				<tbody>
					{(organizations && organizations.length > 0) ?
						organizations
							.map((organization) => (
								<tr
									key={organization.id}
									onClick={() => onClick(organization.id)}
								>
									<td>{organization.number}</td>
									<td>{organization.name}
										<br />
										{organization.businessId}
									</td>
									<td>{translateType(organization.type)}</td>
									{currentUser?.isYoupretAdmin &&<td>{organization.site?.name ?? ""}</td>}
									<td>
										{organization.organizationGroup?.name}
									</td>
									<td>{organization.bookingEmail}</td>
									<td>{organization.description}</td>
								</tr>
							)) : null}
				</tbody>
			</Table>
		</>
	);
};
export default OrganizationList;
