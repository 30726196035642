import React, { useCallback, useEffect, useState } from "react";
import { IUser } from "../../../../classes/User";
import { IInputField } from "../../../ui/Input/Input";
import { IAttribute } from "../../../../interfaces/IAttribute";
import { useLanguageFilter } from "../../Utils/UserUtils";
import { useSelector } from "react-redux";
import IAppState from "../../../../interfaces/store/IAppState";
import IAuthState from "../../../../interfaces/store/IAuthState";
import InputGroup from "../../../ui/InputGroup/InputGroup";
import { ETranslation } from "../../../../translations/translation-keys";
import UserBooleanTableFilter from "../../UsersList/UserBooleanTableFilter";
import AttributesAdd from "../../../Attributes/AttributesAdd";
import { TCreateInput } from "../../../../hooks/useCreateInput";
import { GenderFilter } from "../../UsersList/GenderFilter";
import { EInterpreterSearchInputs } from "./InterpretersSearchInitialInputs";
import { useLanguages } from "../../../../hooks/useLanguages";
import { ECategoryType } from "../../../../interfaces/ICategory";

interface IProps {
	setFilteredUsers: React.Dispatch<React.SetStateAction<IUser[]>>;
	items: IUser[];
	inputs: IInputField;
	createInput: TCreateInput
}

const InterpretersFilters: React.FC<IProps> = ({ setFilteredUsers, items, inputs, createInput }) => {
	const { user: currentUser } = useSelector<IAppState, IAuthState>((state) => state.auth);

	const language = inputs[EInterpreterSearchInputs.language].value as string[];
	const languageFilter = useLanguageFilter(language);

	const filterLanguage = useCallback((user: IUser, filters: string[] | null): boolean => {
		return filters?.every(lang => user.userLanguageItems?.some(item => item.language === lang)) ?? true;
	}, []);

	const filterAttributes = useCallback((user: IUser, attributes: IAttribute[]) => {
		return attributes.length === 0 || attributes.every(attr => user.attributes?.some(userAttr => attr.id === userAttr.id));
	}, []);

	const filterTrained = useCallback((user: IUser, trained: boolean) => {
		return trained === false || user.isInterpreterTrained === trained;
	}, []);

	const filterGender = useCallback((user: IUser, gender: string) => {
		return gender === "UNKNOWN" || user.gender === gender;
	}, []);

	const filterSecuriryCleared = useCallback((user: IUser, securityCleared: boolean) => {
		return securityCleared === false || user.securityCleared === securityCleared;
	}, []);

	const filterCity = useCallback((user: IUser, city: string) => {
		return city === null || city.length === 0 || !city || user.city?.toLowerCase() === city.toLowerCase();
	}, []);

	const filterLocations = useCallback((user: IUser, filters: string[] | null): boolean => {
		return filters?.length === 0 || filters?.some(location => user.locations?.some(item => item === location)) ? true : false;
	}, []);

	const [attributes, setAttributes] = useState<IAttribute[]>([]);
	const [showTrained, setShowTrained] = useState<boolean>(false);
	const [showSecurityCleared, setShowSecurityCleared] = useState<boolean>(false);
	const [genderFilter, setGenderFilter] = useState<"UNKNOWN" | "MALE" | "FEMALE">("UNKNOWN");
	const locations = inputs[EInterpreterSearchInputs.locations].value as string[];
	const city = inputs[EInterpreterSearchInputs.city].value as string;

	useEffect(() => {
		if (items) {
			setFilteredUsers(items.filter(user =>
				filterLanguage(user, languageFilter) &&
				filterAttributes(user, attributes) &&
				filterTrained(user, showTrained) &&
				filterSecuriryCleared(user, showSecurityCleared) &&
				filterGender(user, genderFilter) &&
				filterLocations(user, locations) &&
				filterCity(user, city)
			));
		}
	}, [
		items, setFilteredUsers,
		languageFilter, filterLanguage,
		attributes, filterAttributes,
		showTrained, filterTrained,
		showSecurityCleared, filterSecuriryCleared,
		genderFilter, filterGender,
		locations, filterLocations,
		city, filterCity,
	]);

	const { languageOptions } = useLanguages();

	return (
		<>
			{currentUser?.isYoupretAdmin && (
				<>
					<InputGroup>{createInput(EInterpreterSearchInputs.language, {options:languageOptions})}</InputGroup>
					<InputGroup>{createInput(EInterpreterSearchInputs.city)}</InputGroup>
					<InputGroup>{createInput(EInterpreterSearchInputs.locations)}</InputGroup>
					<UserBooleanTableFilter inputTranslation={ETranslation.USER_TRAINED_INTERPRETER} setFilter={setShowTrained} />
					<UserBooleanTableFilter inputTranslation={ETranslation.COMMON_SECURITY_CLEARED} setFilter={setShowSecurityCleared} />
					<GenderFilter setFilter={setGenderFilter} />
					<InputGroup>{createInput(EInterpreterSearchInputs.showSecurityCleared)}</InputGroup>
				</>
			)}
			<AttributesAdd setAttributes={setAttributes} attributes={attributes} buttonText="Suodata ominaisuudella" title="Lisää ominaisuus" types={[ECategoryType.INTERPRETATION_SEARCH, ECategoryType.INTERPRETATION_SUBTYPE]} />
		</>
	)
}

export default InterpretersFilters;

