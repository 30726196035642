import React, { useEffect, useState } from "react";
import { EInputType, IInputField } from "../../components/ui/Input/Input";
import { useCreateInput } from "../../hooks/useCreateInput";
import InputGroup from "../../components/ui/InputGroup/InputGroup";
import { getInputData } from "../../components/ui/Input/input-utils";
import * as dateFns from 'date-fns';
import { ITaskSearch, searchTasks } from "../../store/actions/tasksActions";
import Button, { EButtonColor } from "../ui/Button/Button";
import { useDispatch } from "react-redux";
import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import { setParameter, clearParameter } from "../../services/useSearchInputCache";
import { ESearchStorageKey } from "../../shared/log-items-data";
import { searchOrganizations } from "../../services/searchServices";


interface IProps {
}

enum EInputs {
	id = "id",
	startDate = "startDate",
	endDate = "endDate",
	organization = "organization",
}

const SearchTasks: React.FC<IProps> = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch();

	const currentYear = dateFns.getYear(new Date());
	const startOfYear = dateFns.startOfYear(new Date(currentYear, 0, 1));
	const endOfYear = dateFns.endOfYear(new Date(currentYear, 11, 31));

	useEffect(() => {
		if (inputs) {
			const inputFieldNames = Object.keys(inputs);

			const updatedInputs = { ...inputs };

			inputFieldNames.forEach((fieldName) => {
				const savedItem = localStorage.getItem(ESearchStorageKey.TASKS_SEARCH);
				if (savedItem) {
					var parsedObject = JSON.parse(savedItem);
					var fieldObject = parsedObject[fieldName];
					const newValue = fieldObject.value;
					updatedInputs[fieldName] = {
						...updatedInputs[fieldName],
						value: newValue,
					};
				} else {

				}
			});
			setInputs(updatedInputs);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.startDate]: {
			type: EInputType.datepicker,
			value: startOfYear,
			dateAsString: true,
		},
		[EInputs.endDate]: {
			type: EInputType.datepicker,
			value: endOfYear,
			dateAsString: true,
		},
	});

	const searchHandler = () => {
		const data = getInputData<ITaskSearch>(inputs);
		dispatch(searchTasks(data));
		setParameter(ESearchStorageKey.TASKS_SEARCH, inputs)
	}

	const clearInputs = () => {

		clearParameter(ESearchStorageKey.TASKS_SEARCH)
	}

	let createInput = useCreateInput(inputs, setInputs);

	return (
		<>
			<InputGroup>
				{createInput(EInputs.startDate)}
				{createInput(EInputs.endDate)}
				<Button onClick={searchHandler}>{t(ETranslation.COMMON_SEARCH)} </Button>
				<Button color={EButtonColor.DEFAULT} onClick={clearInputs}>{t(ETranslation.UI_CLEAR)}</Button>
			</InputGroup>
			{createInput(EInputs.organization, { fetchOptions: searchOrganizations })}

		</>
	)
}
export default SearchTasks