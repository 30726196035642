import React from "react";
import { IInterpretationPushMessage } from "../../../../interfaces/IInterpretation";
import Table from "../../../ui/Table/Table";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../../translations/translation-keys";


interface Iprops {
	messages: IInterpretationPushMessage[];
}

const InterpretationPushMessages: React.FC<Iprops> = ({ messages }) => {

	const { t } = useTranslation();
	return <Table>
		<thead>
			<tr>
				<th>{t(ETranslation.COMMON_STATUS)}</th>
				<th>{t(ETranslation.COMMON_ACTION)}</th>
				<th>{t(ETranslation.COMMON_USER)}</th>
				<th>{t(ETranslation.COMMON_DATE)}</th>
				<th>{t(ETranslation.COMMON_MESSAGE)}</th>
			</tr>
		</thead>
		<tbody>
			{messages.map((message) => {
				return <tr key={message.id}>
					<td>
						{message.status}
					</td>
					<td>
						{message.action}
					</td>
					<td>
						{message.user.firstName} {message.user.lastName}
					</td>
					<td>
						{message.sentDate}
					</td>
					<td>
						{message.messageContent}
					</td>
				</tr>
			})}
		</tbody>
	</Table>
}

export default InterpretationPushMessages;