import { JSONObject, Message } from "@twilio/conversations";
import React, { ReactNode } from "react";
import { User } from "../../../../classes/User";

import classes from "./TwilioChatMessage.module.scss";

import * as dateFns from "date-fns";
import { useLanguageName } from "../../../../hooks/useLanguageName";

enum EMessageType {
  INTERPRETATION = "INTERPRETATION",
}

interface IProps {
  message: Message;
  authUser: User;
}

const TwilioChatMessage: React.FC<IProps> = ({ message, authUser }) => {
  const { body, dateCreated, author, attributes } = message;

  const { isAdmin, adminBody } = useAdminMessage(attributes as JSONObject);

  const classNames = [classes.Container];
  const isOwner = authUser.identity === author;

  if (isAdmin) {
    classNames.push(classes.AdminMessage);
  } else {
    classNames.push(isOwner ? classes.RightMessage : classes.LeftMessage);
  }

  return (
    <div className={classNames.join(" ")}>
      {isAdmin ? (
        adminBody
      ) : (
        <>
          {body}
          {dateCreated && (
            <time className={classes.Time}>
              {dateFns.format(dateCreated, "HH.mm")}
            </time>
          )}
        </>
      )}
    </div>
  );
};

interface IAdminMessage {
  admin: boolean;
  type: EMessageType;
  fromLanguage?: string;
  toLanguage?: string;
  startDate?: string;
}

const useAdminMessage = (attributes: JSONObject) => {
  if (!attributes) {
    return {
      isAdmin: false,
      adminBody: null,
    };
  }

  const { admin, type, fromLanguage, toLanguage, startDate } =
    attributes as unknown as IAdminMessage;

  let adminBody: ReactNode | null = null;
  if (admin) {
    switch (type) {
      case EMessageType.INTERPRETATION:
        adminBody = <AdminMessageInterpretation fromLanguage={fromLanguage} toLanguage={toLanguage} startDate={startDate} />
        break;
    }
  }

  return { isAdmin: admin, adminBody };
};

const stringToDateTime = (startDate?: string) => {
  if (!startDate) return "";
  const parsedDate = dateFns.parseISO(startDate);
  if (!dateFns.isValid(parsedDate)) return "";
  return `${parsedDate.toLocaleDateString()} ${dateFns.format(parsedDate, 'HH.mm')}`
}

const AdminMessageInterpretation: React.FC<Partial<IAdminMessage>> = ({ fromLanguage, toLanguage, startDate }) => {
  const fromLanguageName = useLanguageName(fromLanguage);
  const toLanguageName = useLanguageName(toLanguage);

  return (
    <div style={{ textAlign: 'center' }}>
      Youpret - tulkkaus <br/>
      {fromLanguageName} - {toLanguageName} <br/>
      {stringToDateTime(startDate)}
    </div>
  )
}


export default TwilioChatMessage;
