let hostname;
let port: string;
if(window && window.location) {
  hostname = window.location.hostname;
  port = window.location.port;
}
const isDevelopment = hostname && (hostname === 'localhost' || hostname.startsWith('192.168'));

let serverUrl = '';
let payUrl = '';
if (isDevelopment) {
  serverUrl = `http://${hostname}:8080`;
  payUrl = `http://${hostname}:8082`
} else {
  serverUrl = `https://api.youpret.com`;
  payUrl = "https://pay.youpret.com";
}
const adminUiUrl = `${serverUrl}/admin#`;
const apiUrl = `${serverUrl}/json`;

export { adminUiUrl, apiUrl, isDevelopment, serverUrl, port, payUrl };