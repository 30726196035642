import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IPriceSeason } from "../../interfaces/IPriceSeason";
import { IPriceSeasonRule } from "../../interfaces/IPriceSeasonRule";
import IAppState from "../../interfaces/store/IAppState";
import * as actions from "../../store/actions";
import { createOptions } from "../../utils/option-utils";
import Input, { ECommonValue, EInputType, IInputField, IOption, TInputValue } from "../ui/Input/Input";
import { getInputData, initForm, validateInputs } from "../ui/Input/input-utils";
import InputGroup from "../ui/InputGroup/InputGroup";
import ModalContext, { EModalSize } from "../ui/Modal/ModalContext";
import PriceSeasonRuleList from "./PriceSeasonRule/PriceSeasonRuleList/PriceSeasonRuleList";
import PriceSeasonRuleModal from "./PriceSeasonRule/PriceSeasonRuleModal/PriceSeasonRuleModal";
import Heading from "../Heading/Heading";
import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import { useCreateInput } from "../../hooks/useCreateInput";

enum EInputs {
	name = "name",
	description = "description",
	startDate = "startDate",
	endDate = "endDate",
	active = "active",
}

interface IProps {
	onChange: (priceSeason: IPriceSeason, isValid: boolean) => void;
	priceSeason: IPriceSeason | null;
	loading?: boolean;
	showValidation?: boolean;
}

const PriceSeason: React.FC<IProps> = ({ onChange, loading, priceSeason, showValidation }) => {
	const { t } = useTranslation();
	const [editPriceSeasonRules, setEditPriceSeasonRules] = useState<IPriceSeasonRule[]>([]);
	const [priceSeasonRuleOptions, setPriceSeasonRuleOptions] = useState<IOption[]>([]);
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.name]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_NAME,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.description]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_DESCRIPTION,
			value: "",
		},
		[EInputs.startDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_START_DATE,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.endDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_END_DATE,
			value: "",
		},
		[EInputs.active]: {
			type: EInputType.checkbox,
			labelTranslation: ETranslation.PRICE_SEASON_ACTIVE,
			value: "",
			options: [{ value: ECommonValue.YES }],
		},
	});

	const { setModal, closeModal } = useContext(ModalContext);

	const dispatch = useDispatch();

	const { priceSeasonRule, priceSeasonRules, priceSeasonRulesLoading } = useSelector((state: IAppState) => ({
		priceSeasonRule: state.priceSeason.priceSeasonRule,
		priceSeasonRulesLoading: state.priceSeason.priceSeasonRulesLoading,
		priceSeasonRules: state.priceSeason.priceSeasonRules,
	}));

	useEffect(() => {
		if (priceSeasonRule) {
			setEditPriceSeasonRules((priceSeasonRules) => {
				return [...priceSeasonRules, priceSeasonRule];
			});
			dispatch(actions.getPriceSeasonRuleClear());
		}
	}, [priceSeasonRule, dispatch]);

	useEffect(() => {
		if (!priceSeasonRules) {
			dispatch(actions.findPriceSeasonRules());
		} else {
			setPriceSeasonRuleOptions(createOptions(priceSeasonRules));
		}
	}, [priceSeasonRules, dispatch]);

	useEffect(() => {
		if (priceSeason) {
			initForm(setInputs, priceSeason);
			setEditPriceSeasonRules(priceSeason.rules || []);
		}
		// eslint-disable-next-line
	}, [priceSeason]);

	useEffect(() => {
		const priceSeason = getInputData<IPriceSeason>(inputs);
		priceSeason.rules = editPriceSeasonRules;
		const isValid = validateInputs(inputs);
		onChange(priceSeason, isValid);
	}, [inputs, onChange, editPriceSeasonRules]);

	const createInput = useCreateInput(inputs, setInputs, {
		showValidation,
		disabled: loading,
	});

	const updatePriceSeasonRuleHandler = useCallback(
		(priceSeasonRule: IPriceSeasonRule, index: number) => {
			setEditPriceSeasonRules((rules) => {
				const newRules = [...rules];
				newRules[index] = { ...priceSeasonRule, id: newRules[index].id };
				return newRules;
			});
			closeModal();
		},
		[closeModal]
	);

	const addPriceSeasonRuleHandler = (value: TInputValue) => {
		if (value) {
			dispatch(actions.getPriceSeasonRule(value as string, true));
		}
	};

	const editPriceSeasonRuleHandler = (id: string, index: number) => {
		const priceSeasonRule = editPriceSeasonRules[index];
		setModal({
			isOpen: true,
			content: (
				<PriceSeasonRuleModal
					onChange={updatePriceSeasonRuleHandler}
					priceSeasonRule={priceSeasonRule}
					index={index}
				/>
			),
			size: EModalSize.FULL,
			title: t(ETranslation.PRICE_SEASON_EDIT_PRICERULES),
		});
	};

	const deletePriceSeasonRuleHandler = (id: string, index: number) => {
		setEditPriceSeasonRules((rules) => {
			const newRules = [...rules];
			newRules.splice(index, 1);
			return newRules;
		});
	};

	const movePriceSeasonRule = (id: string, index: number, way: string) => {
		const editedPriceSeasonRules = [...editPriceSeasonRules];
		const movedRule = editPriceSeasonRules[index];
		if (way === "up") {
			editedPriceSeasonRules[index] = editedPriceSeasonRules[index - 1];
			editedPriceSeasonRules[index - 1] = movedRule;
		} else if (way === "down") {
			editedPriceSeasonRules[index] = editedPriceSeasonRules[index + 1];
			editedPriceSeasonRules[index + 1] = movedRule;
		}
		setEditPriceSeasonRules(editedPriceSeasonRules);
	};

	return (
		<>
			{createInput(EInputs.name)}
			{createInput(EInputs.description)}
			<InputGroup>
				{createInput(EInputs.startDate)}
				{createInput(EInputs.endDate)}
				{createInput(EInputs.active)}
			</InputGroup>

			<Heading tag="h2">{t(ETranslation.PRICE_SEASON_PRICERULES_TITLE)}</Heading>
			<Input
				type={EInputType.reactSelect}
				inputName="priceSesonRules"
				placeholderTranslation={ETranslation.PRICE_SEASON_ADD_PRICERULE}
				onChange={addPriceSeasonRuleHandler}
				value=""
				options={priceSeasonRuleOptions}
			/>
			<PriceSeasonRuleList
				priceSeasonRules={editPriceSeasonRules}
				loading={priceSeasonRulesLoading}
				error={null}
				onClick={editPriceSeasonRuleHandler}
				onDelete={deletePriceSeasonRuleHandler}
				movePriceSeasonRule={movePriceSeasonRule}
				showDetails
			/>
			<br />
		</>
	);
};

export default PriceSeason;
