import React, { useEffect, useState } from "react";
import { ITaskitem, ITaskitemUser } from "../../../store/actions/tasksActions";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { getInputData } from "../../ui/Input/input-utils";
import { useCreateInput } from "../../../hooks/useCreateInput";
import Button, { EButtonColor } from "../../ui/Button/Button";
import { ETranslation } from "../../../translations/translation-keys";

interface IProps {
	taskItem: ITaskitem;
	removeTask: () => void;
	onUpdate: (data: ITaskitemUser, parentId: string) => void;
}

enum EInputs {
	id = "id",
	firstName = "firstName",
	lastName = "lastName",
	email = "email",
	phoneNumber = "phoneNumber",
}

const TaskItem: React.FC<IProps> = ({ taskItem, removeTask, onUpdate }) => {

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.id]: {
			type: EInputType.text,
			label: "id",
			value: taskItem.id ?? null,
		},
		[EInputs.firstName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_FIRST_NAME,
			value: taskItem.user?.firstName ?? "",
			validation: {
				required: true,
				minLength: 1,
			},
		},
		[EInputs.lastName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_LAST_NAME,
			value: taskItem.user?.lastName ?? "",
			validation: {
				required: true,
				minLength: 1,
			},
		},
		[EInputs.email]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_EMAIL,
			value: taskItem.user?.email ?? "",
			validation: {
				required: true,
				minLength: 1,
			},
		},
		[EInputs.phoneNumber]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_PHONENUMBER,
			value: taskItem.user?.phoneNumber ?? "+358",
			validation: {
				required: true,
				minLength: 1,
			},
		},
	});


	useEffect(() => {
		const TaskItemData = getInputData<ITaskitemUser>(inputs, false);
		onUpdate(TaskItemData, taskItem.id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs]);

	const createInput = useCreateInput(inputs, setInputs);

	return (

		<div>
			{createInput(EInputs.firstName)}
			{createInput(EInputs.lastName)}
			{createInput(EInputs.email)}
			{createInput(EInputs.phoneNumber)}
			<Button style={{ marginBottom: ".5rem" }} color={EButtonColor.DANGER} onClick={() => { removeTask(); }}>(t{ETranslation.UI_DELETE})</Button>
		</div>
	);
};

export default TaskItem;