import React, { ReactNode } from 'react';
import classes from './ContainerCol.module.scss';

interface IProps {
  children: ReactNode;
  dontScaleHeight?: boolean;
  sticky?: boolean;
}

const ContainerCol:React.FC<IProps> = ({ children, dontScaleHeight = false, sticky = false }) => {
  const classNames = [classes.ContainerCol];
  if (dontScaleHeight) {
    classNames.push(classes.DontScaleHeight);
  }
  if (sticky) {
    classNames.push(classes.Sticky);
  }
  return (
    <div className={classNames.join(" ")}>
        {children}
    </div>
  )
}

export default ContainerCol;