import React, { useEffect, useState } from "react";
import ReactSelect, {
  components,
  ControlProps,
  GroupBase,
  MultiValue,
  OptionProps,
  SingleValue,
} from "react-select";
import { ELanguageStatus } from "../../../shared/languages-data";
import { IOption } from "../Input/Input";
import LanguageAvailabilityStatus from "../../Interpretation/InterpretationStatus/LanguageAvailabilityStatus";
import classes from "./ToLanguageSelect.module.scss"

interface IProps {
  options?: IOption[];
  name: string;
  value: string | string[] | IOption;
  placeholder?: string;
  multiple?: boolean;
  loading?: boolean;
  disabled?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  onChange: (value: string | string[] | IOption) => void;
}

const Option: React.ComponentType<
  OptionProps<IOption, boolean, GroupBase<IOption>>
> = ({ children, ...rest }) => {
  let { status } = rest.data;

  const classNames = [classes.MarginOption]
  switch (status) {
    case ELanguageStatus.AVAILABLE:
      classNames.push(classes.PaddingAvailable)
      break;
    case ELanguageStatus.RESERVED:
      classNames.push(classes.PaddingReserved)
      break;
  }
  return (
    <components.Option {...rest}>
      <LanguageAvailabilityStatus status={status} className={classNames.join(" ")} />
      {children}
    </components.Option>
  );
};

const Control: React.ComponentType<
  ControlProps<IOption, boolean, GroupBase<IOption>>
> = ({ children, ...rest }) => {
  const propValue = rest.selectProps.value as IOption;

  return (
    <components.Control {...rest}>
      {rest.hasValue && <LanguageAvailabilityStatus status={propValue.status} className={classes.MarginSelected}/>}
      {children}
    </components.Control>
  );
};

const ToLanguageSelect: React.FC<IProps> = ({
  options,
  name,
  placeholder,
  multiple,
  loading,
  disabled,
  value,
  isSearchable,
  isClearable,
  onChange,
}) => {
  const [selectValue, setSelectValue] = useState<IOption | IOption[] | undefined>();
  useEffect(() => {
    if (value && options) {
      setSelectValue(options.find((option) => option.value === value));
    } else {
      setSelectValue(undefined);
    }
  }, [value, options, multiple]);

  const changeHandler = (value: SingleValue<IOption> | MultiValue<IOption>) => {
    if (multiple) {
      onChange((value as MultiValue<IOption> | undefined)?.map((value) => value.value) || "");
    } else {
      onChange((value as SingleValue<IOption> | undefined)?.value || "");
    }
  };

  return (
    <ReactSelect
      name={name}
      onChange={(option) => changeHandler(option as IOption | IOption[] | null)}
      isLoading={loading}
      value={selectValue}
      placeholder={placeholder}
      options={options}
      isDisabled={disabled}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isMulti={multiple}
      components={{ Option: Option, Control: Control }}
    />
  );
};

export default ToLanguageSelect;
