import { Dispatch } from 'redux';

import { customFetch } from '../../custom-fetch';
import { IAttachment } from '../../interfaces/IAttachment';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IAttachmentAction from '../../interfaces/store/IAttachmentAction';

type TAction = IAttachmentAction;


const getAttachmentStart = (): TAction => {
  return {
    type: EActionTypes.ATTACHMENT_GET_START,
  };
};

const getAttachmentSuccess = (attachment: IAttachment): TAction => {
  return {
    type: EActionTypes.ATTACHMENT_GET_SUCCESS,
    attachment
  };
};

const getAttachmentFail = (error: string): TAction => {
  return {
    type: EActionTypes.ATTACHMENT_GET_FAIL,
    error,
  };
};

export const getAttachmentClear = (): TAction => {
  return {
    type: EActionTypes.ATTACHMENT_GET_CLEAR,
  };
};

export const getAttachment = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getAttachmentStart());
      const attachment = await customFetch<IAttachment>(`/attachments/get?id=${id}`);
      dispatch(getAttachmentSuccess(attachment));
    } catch (error) {
      dispatch(getAttachmentFail((error as Error).message));
    }
  };
}
