import React, { useCallback, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import Container from "../../components/ui/Container/Container";
import Heading from "../../components/Heading/Heading";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../translations/translation-keys";
import CalendarSearch, { ISearchOptions } from "./CalendarEl/CalendarSearch";
import { customFetch, EFetchMethod } from "../../custom-fetch";
import { IUser } from "../../classes/User";
import { IInterpretation } from "../../interfaces/IInterpretation";
import CalendarList from "./CalendarEl/CalendarElemets";
import HeadingBox from "../../components/Heading/HeadingBox/HeadingBox";
import Spinner from "../../components/ui/Spinner/Spinner";

interface IProps extends RouteComponentProps { }

const CalendarViewPage: React.FC<IProps> = ({ history }) => {
	const { t } = useTranslation();
	// Tallennetaan hausta saadut tulkit
	const [usersInSearch, setUsersInSearch] = useState<null | IUser[]>(null);

	const [startDate, setStartDate] = useState<string>("");

	const [loading, setLoading] = useState<boolean>(false);

	const [endDate, setEndDate] = useState<string>("");

	const [noSelections, setNoSelections] = useState<boolean>(true)

	const [searchError, setSearchError] = useState<string | null>(null)

	const [intrepretations, setIntrepretations] = useState<null | IInterpretation[]>(null)

	// Räikeä hakuominaisuus
	// TODO(Joonas): Siistitään ja siirretään muualle
	const searchHandler = useCallback(
		(search: ISearchOptions) => {
			setIntrepretations(null);
			setLoading(true);
			setUsersInSearch(null);
			setStartDate(search.createdDateStart);
			setEndDate(search.createdDateEnd);
			setSearchError(null);
			// dateFns.differenceInDays gives out the difference from date to date, and this is why we need to add one day to it
			if (search.users.length > 0 || search.toLanguage.length > 1) {
				let paramsObj;
				setNoSelections(false);
				if(search.users.length > 0){
					const users = search.users.map((item) => {return item.value});
					paramsObj = {
						role: "INTERPRETER",
						languages: null,
						gender: search.gender.length > 1 ? search.gender : null,
						status: ["ONLINE_RESERVED", "ONLINE_NOT_AVAILABLE", "ONLINE_AVAILABLE"],
						ids: users
					};
				} else if(search.toLanguage.length > 1) {
					paramsObj = {
						role: "INTERPRETER",
						languages: search.toLanguage,
						status: ["ONLINE_RESERVED", "ONLINE_NOT_AVAILABLE", "ONLINE_AVAILABLE"],
						gender: search.gender.length > 1 ? search.gender : null
					};
				}	
				// Fetch list of Interpreters that match the given language pair

				// TODO(Users from id's to actual userObjects!!!! Required for the calenadr view's informations)
				customFetch<IUser[]>("/users/list", EFetchMethod.POST, JSON.stringify(paramsObj)).then((result: IUser[]) => {
					setUsersInSearch(result);
					let listOfIds = result.map((user) => { return user.id })
					if (listOfIds.length < 1) {
						//CALENDAR_NO_USERS_MESSAGE
						setSearchError(t(ETranslation.CALENDAR_NO_USERS_MESSAGE))
					} else {
						customFetch<IInterpretation[]>("/interpretations/list", EFetchMethod.POST, JSON.stringify(
							{
								startDate: search.createdDateStart,
								endDate: search.createdDateEnd,
								users: listOfIds, // listOfIds
								type: ["BOOKING", "ON_SITE"], //ONSITE ?Instant?
							}
						)).then((result) => {
							// raw list of intrepretations
							setLoading(false);
							setIntrepretations(result);
						}).catch((err) => {
							console.error(err);
							setSearchError("Please paste this to devs" + err);
						})
					}
				});

			} else {
				setLoading(false);
				setNoSelections(true);
			}

		},
		[t]
	);

	return (
		<Container>
			<Heading>{t(ETranslation.MENU_CALENDAR)}</Heading>
			<CalendarSearch onSearch={searchHandler} />
			{loading ?
				<Spinner center />
				: !noSelections && !searchError ?
					<CalendarList
						Intrepretations={intrepretations}
						usersInSearch={usersInSearch}
						startDate={startDate}
						endDate={endDate} />
					:
					searchError ?
						<HeadingBox>
							{searchError}
						</HeadingBox> :
						<HeadingBox>
							{t(ETranslation.CALENDAR_NO_SELECTION_MESSAGE)}
						</HeadingBox>
			}
		</Container>
	);
};

export default CalendarViewPage;
