import EActionTypes from "../../interfaces/store/EActionTypes";
import IOrderState from "../../interfaces/store/IOrderState";
import IOrderAction from "../../interfaces/store/IOrderAction";
import { EPaymentStatus } from "../../shared/order-data";
import { ETranslation } from "../../translations/translation-keys";
import { PAGINATION_FETCH_SIZE } from "../../hooks/useOrders";


type ReducerState = IOrderState;
type ReducerSignature = (state: ReducerState, action: IOrderAction) => ReducerState;

const initialState: ReducerState = {
	order: null,
	loading: false,
	saveOrUpdateOk: false,
	error: null,
	orders: null,
	ordersLoading: false,
	saveOrUpdateLoading: false,
	saveOrUpdateError: null,
	exportToYoupretPayLoading: false,
	exportToYoupretPayError: null,
	updatePaymentStatusLoading: false,
	updatePaymentStatusError: null,
	hasMoreOrders: false,
};

const getOrderStart: ReducerSignature = (state): ReducerState => {
	return { ...state, error: null, loading: true, order: null };
};

const getOrderSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		order: action.order || null,
		error: null,
		loading: false,
	};
};

const getOrderFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, order: null, loading: false, error: action.error ?? null };
};

const getOrderClear: ReducerSignature = (state): ReducerState => {
	return { ...state, order: null, loading: false, error: null };
}

const getOrdersStart: ReducerSignature = (state): ReducerState => {
	return { ...state, error: null, loading: true };
};

const getOrdersSuccess: ReducerSignature = (state, action): ReducerState => {
	const hasMoreOrders = action.orders?.length === PAGINATION_FETCH_SIZE;
	const orders = state.orders ? action.orders ? [...state.orders, ...action.orders] : [...state.orders] : action.orders;
	return {
		...state,
		orders: orders || null,
		error: null,
		loading: false,
		hasMoreOrders,
	};
};

const getOrdersFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, orders: null, loading: false, error: action.error ?? null, hasMoreOrders: false };
};

const getOrdersClear: ReducerSignature = (state): ReducerState => {
	return { ...state, orders: null, loading: false, error: null, hasMoreOrders: false };
}

const saveOrderStart: ReducerSignature = (state): ReducerState => {
	return { ...state, saveOrUpdateLoading: true, saveOrUpdateError: null };
}

const saveOrderSuccess: ReducerSignature = (state): ReducerState => {
	return { ...state, saveOrUpdateLoading: false, saveOrUpdateError: null, saveOrUpdateOk: true };
}

const saveOrderError: ReducerSignature = (state): ReducerState => {
	return { ...state, saveOrUpdateLoading: false, saveOrUpdateError: state.saveOrUpdateError, saveOrUpdateOk: false };
}

const saveOrderClear: ReducerSignature = (state): ReducerState => {
	return { ...state, saveOrUpdateLoading: false, saveOrUpdateError: null, saveOrUpdateOk: false };
}

const exportToYoupretPayStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		exportToYoupretPayLoading: true,
		exportToYoupretPayError: null,
	};
};

const exportToYoupretPaySuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	const order = state.order;
	if (order) {
		order.paymentStatus = EPaymentStatus.unpaid;
	}
	return {
		...state,
		exportToYoupretPayLoading: false,
		exportToYoupretPayError: null,
		order,
	};
};

const exportToYoupretPayFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		exportToYoupretPayLoading: false,
		exportToYoupretPayError: action.error || ETranslation.REDUCER_ERROR,
	};
};

const updatePaymentStatusStart: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		updatePaymentStatusLoading: true,
		updatePaymentStatusError: null,
	};
};

const updatePaymentStatusSuccess: ReducerSignature = (
	state,
	action
): ReducerState => {
	const order = state.order;
	if (order && action.paymentStatus) {
		order.paymentStatus = action.paymentStatus;
	}
	return {
		...state,
		updatePaymentStatusLoading: false,
		updatePaymentStatusError: null,
		order,
	};
};

const updatePaymentStatusFail: ReducerSignature = (
	state,
	action
): ReducerState => {
	return {
		...state,
		updatePaymentStatusLoading: false,
		updatePaymentStatusError: action.error || ETranslation.REDUCER_ERROR,
	};
};

const reducer = (state: ReducerState = initialState, action: IOrderAction): ReducerState => {
	switch (action.type) {
		case EActionTypes.ORDERS_GET_START:
			return getOrdersStart(state, action);
		case EActionTypes.ORDERS_GET_SUCCESS:
			return getOrdersSuccess(state, action);
		case EActionTypes.ORDERS_GET_ERROR:
			return getOrdersFail(state, action);
		case EActionTypes.ORDERS_GET_CLEAR:
			return getOrdersClear(state, action);
		case EActionTypes.ORDER_SAVE_START:
			return saveOrderStart(state, action);
		case EActionTypes.ORDER_SAVE_SUCCESS:
			return saveOrderSuccess(state, action);
		case EActionTypes.ORDER_SAVE_ERROR:
			return saveOrderError(state, action);
		case EActionTypes.ORDER_SAVE_CLEAR:
			return saveOrderClear(state, action);
		case EActionTypes.ORDER_GET_START:
			return getOrderStart(state, action);
		case EActionTypes.ORDER_GET_SUCCESS:
			return getOrderSuccess(state, action);
		case EActionTypes.ORDER_GET_ERROR:
			return getOrderFail(state, action);
		case EActionTypes.ORDER_GET_CLEAR:
			return getOrderClear(state, action);
		case EActionTypes.ORDER_EXPORT_TO_YOUPRET_PAY_START:
			return exportToYoupretPayStart(state, action);
		case EActionTypes.ORDER_EXPORT_TO_YOUPRET_PAY_SUCCESS:
			return exportToYoupretPaySuccess(state, action);
		case EActionTypes.ORDER_EXPORT_TO_YOUPRET_PAY_FAIL:
			return exportToYoupretPayFail(state, action);
		case EActionTypes.ORDER_UPDATE_PAYMENT_STATUS_START:
			return updatePaymentStatusStart(state, action);
		case EActionTypes.ORDER_UPDATE_PAYMENT_STATUS_SUCCESS:
			return updatePaymentStatusSuccess(state, action);
		case EActionTypes.ORDER_UPDATE_PAYMENT_STATUS_FAIL:
			return updatePaymentStatusFail(state, action);
		default:
			return state;
	}
};

export default reducer;
