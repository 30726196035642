import React, { useCallback, useContext, useState } from "react";
import { IUserContractSettings } from "../../../interfaces/IUserContractSettings";
import ModalContext from "../../ui/Modal/ModalContext";
import ModalBody from "../../ui/Modal/ModalBody/ModalBody";
import UserContractSettingsEdit from "../UserContractSettingsEdit/UserContractSettingsEdit";
import ModalFooter from "../../ui/Modal/ModalFooter/ModalFooter";
import EditButtons from "../../ui/EditButtons/EditButtons";

interface IProps {
	onChange: (settings: IUserContractSettings, index: number) => void;
	settings: IUserContractSettings;
	index: number;
}

const UserContractSettingsModal: React.FC<IProps> = ({ settings, onChange, index }) => {
	const [showValidation, setShowValidation] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [editSettings, setEditSettings] = useState<IUserContractSettings>(settings);

	const { closeModal } = useContext(ModalContext);

	const saveHandler = () => {
		onChange(editSettings, index);
	};

	const updateHandler = useCallback((settings: IUserContractSettings, isValid: boolean) => {
		setIsValid(isValid);
		setEditSettings(settings);
	}, []);

	return (
		<>
			<ModalBody>
				<UserContractSettingsEdit
					onChange={updateHandler}
					settings={settings}
					showValidation={showValidation}
				/>
			</ModalBody>
			<ModalFooter>
				<EditButtons
					onSave={isValid ? saveHandler : () => setShowValidation(true)}
					disabled={showValidation && !isValid}
					onCancel={closeModal}
					isAdd={true}
				/>
			</ModalFooter>
		</>
	);
};

export default UserContractSettingsModal;
