import React, { useEffect, useState } from 'react';

import { useCreateInput } from '../../hooks/useCreateInput';
import { ICostCenter } from '../../interfaces/IOrganization';
import { ETranslation } from '../../translations/translation-keys';
import Fieldset from '../ui/Fieldset/Fieldset';
import { EInputType, IInputField } from '../ui/Input/Input';
import { getInputData } from '../ui/Input/input-utils';

interface IProps {
	costCenter: ICostCenter;
	number: number;
	onDelete: () => void;
	onUpdate: (data: ICostCenter, parentId: string) => void;
}

enum EInputs {
	id = "id",
	name = "name",
	descriptionText = "descriptionText",
}
const OrganizationCostCenters: React.FC<IProps> = ({ costCenter, number, onDelete, onUpdate }) => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.id]: {
			type: EInputType.text,
			label: "id",
			value: costCenter.id ?? null,
		},
		[EInputs.name]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_COST_CENTER_NAME,
			value: costCenter?.name ?? "",
		},
		[EInputs.descriptionText]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORGANIZATION_COMPANY_COST_CENTER_DESCRIPTION,
			value: costCenter.descriptionText ?? "",
		},
	});

	useEffect(() => {
		const CostCenterData = getInputData<ICostCenter>(inputs, false);
		onUpdate(CostCenterData, costCenter.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs]);

	const createInput = useCreateInput(inputs, setInputs);

	return (
		<Fieldset label={`Kustannuspaikka ${number}`} onRemove={onDelete}>
			{createInput(EInputs.name)}
			{createInput(EInputs.descriptionText)}
		</Fieldset>
	);
};

export default OrganizationCostCenters;
