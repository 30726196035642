import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { ETranslation } from '../../translations/translation-keys';

const classes = require("./Base64Dropzone.module.scss");

interface IProps {
  className?: string;
  text: string;
  dragText: string;
  onChange: (value: string) => void;
  value: string;
}

export const MAX_SIZE = 500; // kb

const Base64Dropzone: React.FC<IProps> = ({
  className,
  text,
  dragText,
  onChange,
  value,
}) => {
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  })

  const onDropHandler = async (files: Array<File>) => {
    if(!files || !files.length) return;
    setError(null);
    const base64 = await toBase64(files[0]);
    onChange(base64);
  };

  const dropRejectedHandler = (files: any) => {
    if(!files || !files.length) return;

    const errorCode = files[0].errors[0].code;

    switch(errorCode) {
      case "file-too-large":
        setError(t(ETranslation.BASE64_DROPZONE_TOO_LARGE))
        break;
      case "file-invalid-type":
        setError(t(ETranslation.BASE64_DROPZONE_NOT_IMAGE))
        break;
    }
  }

  const deleteHandler = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    event.stopPropagation();
    onChange("");
  };


  return (
    <Dropzone onDrop={onDropHandler} maxSize={MAX_SIZE * 1000} accept="image/*" onDropRejected={dropRejectedHandler}>
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <div className={className || classes.Dropzone} {...getRootProps()}>
            <input {...getInputProps()} />
            {!value && (
              <div className={classes.Container}>
                <p className={classes.Text}>{isDragActive ? dragText : text}</p>
              </div>
            )}
            <div className={classes.PreviewContainer}>
              {error && <p className="text-danger">{error}</p>}
              {value && <span className={classes.Delete} onClick={deleteHandler}><FontAwesomeIcon icon={faTimes} /></span>}
              {value && <img src={value} alt="Preview" className={classes.Preview} /> }
            </div>
          </div>
        );
      }}
    </Dropzone>
  );
};

export default Base64Dropzone;
