import { Dispatch } from 'redux';
import { customFetch, EFetchMethod } from '../../custom-fetch';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IOrganizationAction from '../../interfaces/store/IOrganizationAction';
import { EmptyOrganization, IOrganization } from '../../interfaces/IOrganization';
import { IPaginationOptions } from '../../hooks/useOrganizationPagination';

type TAction = IOrganizationAction;

const findOrganizationsStart = (): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_FIND_START,
	};
};

const findOrganizationsSuccess = (organizations: IOrganization[], hasMore: boolean): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_FIND_SUCCESS,
		organizations,
		hasMore
	};
};

const findOrganizationsFail = (error: string): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_FIND_FAIL,
		error,
	};
};

export const findOrganizationsClear = (): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_FIND_CLEAR,
	};
};

export const findOrganizations = (searchParams: IPaginationOptions) => {
	const body = {
		type: searchParams.type ?? ["CUSTOMER", "INTERPRETER", "UNKNOWN"],
		page: searchParams.page,
		id: searchParams.organizationGroup ?? null,
	};

	return async (dispatch: Dispatch) => {
		dispatch(findOrganizationsStart());
		try {
			if (searchParams.id && searchParams.id.length > 0) {
				customFetch<IOrganization>(`/organizations?id=${searchParams.id}`).then((organization) => {
					dispatch(findOrganizationsSuccess([organization], false));
				}).catch((error) => {
					dispatch(findOrganizationsFail(error.message));
				})
			} else {
				customFetch<{ items: IOrganization[], hasNext: boolean }>("/organizations/list", EFetchMethod.POST, JSON.stringify(body))
					.then((response) => {
						dispatch(findOrganizationsSuccess(response.items, response.hasNext));
					})
					.catch((error) => {
						dispatch(findOrganizationsFail(error.message));
					});
			}
		} catch (error) {
			dispatch(findOrganizationsFail((error as Error).message));
		}
	};
};

const getOrganizationStart = (id: string): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_GET_START,
		id
	};
};

const getOrganizationSuccess = (organization: IOrganization): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_GET_SUCCESS,
		organization,
	};
};

const getOrganizationFail = (error: string): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_GET_FAIL,
		error,
	};
};

export const getOrganizationClear = (): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_GET_CLEAR,
	};
};

export const getOrganization = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getOrganizationStart(id));
			let organization;
			id === "add" ? organization = EmptyOrganization : organization = await customFetch<IOrganization>(`/organizations?id=${id}`);
			dispatch(getOrganizationSuccess(organization));
		} catch (error) {
			dispatch(getOrganizationFail((error as Error).message));
		}
	};
};

const deleteOrganizationStart = (): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_DELETE_START,
	};
};

const deleteOrganizationSuccess = (id: string): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_DELETE_SUCCESS,
		id,
	};
};

const deleteOrganizationFail = (error: string): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_DELETE_FAIL,
		error,
	};
};

export const deleteOrganizationClear = (): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_DELETE_CLEAR,
	};
};

export const deleteOrganization = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			const body = { ids: [id] }
			dispatch(deleteOrganizationStart());
			await customFetch<boolean>(`/organizations/delete?id=${id}`, EFetchMethod.POST, JSON.stringify(body))
			dispatch(deleteOrganizationSuccess(id));
		} catch (error) {
			dispatch(deleteOrganizationFail((error as Error).message));
		}
	};
};

const saveOrUpdateOrganizationStart = (): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_SAVE_OR_UPDATE_START,
	};
};

const saveOrUpdateOrganizationSuccess = (): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_SAVE_OR_UPDATE_SUCCESS,
	};
};

const saveOrUpdateOrganizationFail = (error: string): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_SAVE_OR_UPDATE_FAIL,
		error,
	};
};

export const saveOrUpdateOrganizationClear = (): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_SAVE_OR_UPDATE_CLEAR
	}
}

export const saveOrganization = (organization: IOrganization | null) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(saveOrUpdateOrganizationStart())
			const data = { ...organization, id: null };
			customFetch<IOrganization>("/organizations/add", EFetchMethod.POST, JSON.stringify(data))
			dispatch(saveOrUpdateOrganizationSuccess());
		} catch (error) {
			dispatch(saveOrUpdateOrganizationFail((error as Error).message));
		}
	};
};

export const updateOrganization = (organization: IOrganization) => {
	return async (dispatch: Dispatch) => {
		try {
			const tagsList: string[] = organization.tags?.map((tag) => {
				return tag.id;
			}) ?? [];
			const dataToSend = {
				...organization,
				tags: tagsList,
			}
			dispatch(saveOrUpdateOrganizationStart());
			await customFetch<IOrganization>(`/organizations/add?id=${organization.id}`, EFetchMethod.POST, JSON.stringify(dataToSend))
			dispatch(saveOrUpdateOrganizationSuccess());

		} catch (error) {
			dispatch(saveOrUpdateOrganizationFail((error as Error).message));
		}
	};
};

const findSearchOrganizationsStart = (): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_SEARCH_START,
	};
};

const findSearchOrganizationsSuccess = (organizations: IOrganization[]): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_SEARCH_SUCCESS,
		organizations,
	};
};

const findSearchOrganizationsFail = (error: string): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_SEARCH_FAIL,
		error,
	};
};

export const findSearchOrganizations = () => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(findSearchOrganizationsStart());
			const organizations = await customFetch<IOrganization[]>("/organizations/user");
			dispatch(findSearchOrganizationsSuccess(organizations));
		} catch (error) {
			dispatch(findSearchOrganizationsFail((error as Error).message));
		}
	};
};



// Move to netvisor


const createNewOrganizationInNetvisorStart = (): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_CREATE_IN_NETVISOR_START,
	};
};

const createNewOrganizationInNetvisorSuccess = (): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_CREATE_IN_NETVISOR_SUCCESS,
	};
};

const createNewOrganizationInNetvisorError = (error: string): TAction => {
	return {
		type: EActionTypes.ORGANIZATION_CREATE_IN_NETVISOR_ERROR,
		error,
	};
};

export const createNewOrganizationInNetvisor = (organization: IOrganization) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(createNewOrganizationInNetvisorStart());
			const netvisorData = {
				NetvisorOrganizationCodeVar: organization.netvisorInvoicingCustomerIdentifier,
				NetvisorYtunnusVar: organization.businessId,
				NetvisorOrganizationNameVar: organization.name,
				NetvisorOrganizationAddressVar: organization.streetName,
				NetvisorOrganizationCityVar: organization.city,
				NetvisorOrganizationPostalCodeVar: organization.zip,
				NetvisorOrganizationPhoneNumberVar: organization.phoneNumber,
				NetvisorOrganizationEmailVar: organization.email,
				NetvisorOrganizationeInvoiceAddressVar: organization.eInvoiceAddress,
				NetvisorOrganizationeInvoiceOperatorIdVar: organization.eInvoiceOperatorId
			};
			customFetch("/organizations/createneworganizationinnetvisor", EFetchMethod.POST, JSON.stringify(netvisorData)).then((data) => {
				alert("Creation of Organization in Netvisor started. Please check Netvisor.");
				dispatch(createNewOrganizationInNetvisorSuccess());
			}).catch((err) => {
				dispatch(createNewOrganizationInNetvisorError((err as Error).message));
			})
		} catch (err) {
			dispatch(createNewOrganizationInNetvisorError((err as Error).message));
		}
	};
};
