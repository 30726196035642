import React, { CSSProperties, useState } from 'react';
import Spinner from '../Spinner/Spinner';

interface IProps {
  style?: CSSProperties;
  src: string;
  alt: string;
  onClick?: (event: React.MouseEvent) => void;
}

const ImageSpinner: React.FC<IProps> = ({ style = {}, src, alt, onClick }) => {
  const [loading, setLoading] = useState(true);

  const onLoad = () => {
    setLoading(false);
  };

  const onError = () => {
    setLoading(false);
  };

  const styles = {
    ...style,
    display: loading ? "none" : "initial",
    maxWidth: '100%'
  };

  return (
    <React.Fragment>
      <img src={src} alt={alt} onLoad={onLoad} onError={onError} style={styles} onClick={onClick} />
      {loading ? <Spinner /> : null}
    </React.Fragment>
  );
};

export default ImageSpinner;
