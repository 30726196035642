import { ENewsItemDisplayLocation } from './../interfaces/INewsItem';
import { IOption } from "../components/ui/Input/Input";


export const NEWS_ITEM_DISPLAY_LOCATIONS: IOption[] = [
  { value: ENewsItemDisplayLocation.YOUPRET, label: 'Youpret' },
  { value: ENewsItemDisplayLocation.ALIPI, label: 'Alipi' },
  { value: ENewsItemDisplayLocation.MASPA, label: 'Maspa' }
]
export const NEWS_ITEM_RATING: IOption[] = [
  { value: "null", label: "" },
  { value: "1", label: "1" },
  { value: "2", label: '2' },
  { value: "3", label: '3' },
  { value: "4", label: "4" },
  { value: "5", label: "5" }
]


export const getNewsItemDisplayLocationString = (displayLocation: ENewsItemDisplayLocation): string => {
  return NEWS_ITEM_DISPLAY_LOCATIONS.find(item => item.value === displayLocation)?.label || "";
}