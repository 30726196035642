import EActionTypes from '../../interfaces/store/EActionTypes';
import IInfoContactRequestAction from '../../interfaces/store/IInfoContactRequestAction';
import IInfoContactRequestState from '../../interfaces/store/IInfoContactRequestState';
import { ETranslation } from '../../translations/translation-keys';
import { insertFirst, removeOne, updateOne } from '../../utils/reducer-utils';

type ReducerAction = IInfoContactRequestAction;
type ReducerState = IInfoContactRequestState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  infoContactRequest: null,
  infoContactRequestLoading: false,
  infoContactRequestError: null,

  infoContactRequestDeleteError: null,
  infoContactRequestDeleteLoading: false,
  infoContactRequestDeleteOk: false,

  infoContactRequests: null,
  infoContactRequestsLoading: false,
  infoContactRequestsError: null,

  infoContactRequestSaveOrUpdateOk: false,
  infoContactRequestSaveOrUpdateError: null,
  infoContactRequestSaveOrUpdateLoading: false,
};

const findInfoContactRequestsStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, infoContactRequestsError: null, infoContactRequestsLoading: true };
};

const findInfoContactRequestsSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    infoContactRequests: action.infoContactRequests || null,
    infoContactRequestsError: null,
    infoContactRequestsLoading: false,
  };
};

const findInfoContactRequestsFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, infoContactRequests: null, infoContactRequestsLoading: false, infoContactRequestsError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getInfoContactRequestStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, infoContactRequestError: null, infoContactRequestLoading: true };
};

const getInfoContactRequestSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    infoContactRequest: action.infoContactRequest || null,
    infoContactRequestError: null,
    infoContactRequestLoading: false,
  };
};

const getInfoContactRequestFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, infoContactRequest: null, infoContactRequestLoading: false, infoContactRequestError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};

const getInfoContactRequestClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, infoContactRequest: null, infoContactRequestLoading: false, infoContactRequestError: null};
};

const deleteInfoContactRequestStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, infoContactRequestDeleteError: null, infoContactRequestDeleteLoading: true, infoContactRequestDeleteOk: false };
};

const deleteInfoContactRequestSuccess: ReducerSignature = (state, action): ReducerState => {
  const infoContactRequests = removeOne(state.infoContactRequests, action.id);
  return {
    ...state,
    infoContactRequests,
    infoContactRequestDeleteError: null,
    infoContactRequestDeleteLoading: false,
    infoContactRequestDeleteOk: true
  };
};

const deleteInfoContactRequestFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, infoContactRequestDeleteLoading: false, infoContactRequestDeleteError: action.error as string, infoContactRequestDeleteOk: false };
};

const deleteInfoContactRequestClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, infoContactRequestDeleteError: null, infoContactRequestDeleteLoading: false, infoContactRequestDeleteOk: false };
};

const saveInfoContactRequestStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, infoContactRequestSaveOrUpdateError: null, infoContactRequestSaveOrUpdateLoading: true };
};

const saveInfoContactRequestSuccess: ReducerSignature = (state, action): ReducerState => {
  const infoContactRequests = insertFirst(state.infoContactRequests, action.infoContactRequest);
  return {
    ...state,
    infoContactRequests,
    infoContactRequestSaveOrUpdateError: null,
    infoContactRequestSaveOrUpdateLoading: false,
    infoContactRequestSaveOrUpdateOk: true
  };
};

const saveInfoContactRequestFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, infoContactRequestSaveOrUpdateLoading: false, infoContactRequestSaveOrUpdateError: action.error as string };
};

const updateInfoContactRequestStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, infoContactRequestSaveOrUpdateError: null, infoContactRequestSaveOrUpdateLoading: true };
};

const updateInfoContactRequestSuccess: ReducerSignature = (state, action): ReducerState => {
  const infoContactRequests = updateOne(state.infoContactRequests, action.infoContactRequest);
  return {
    ...state,
    infoContactRequests,
    infoContactRequestSaveOrUpdateError: null,
    infoContactRequestSaveOrUpdateLoading: false,
    infoContactRequestSaveOrUpdateOk: true
  };
};

const updateInfoContactRequestFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, infoContactRequestSaveOrUpdateLoading: false, infoContactRequestSaveOrUpdateError: action.error as string };
};

const saveOrUpdateInfoContactRequestClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, infoContactRequestSaveOrUpdateLoading: false, infoContactRequestSaveOrUpdateError: null, infoContactRequestSaveOrUpdateOk: false }
}




const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.INFO_CONTACT_REQUEST_FIND_START:
      return findInfoContactRequestsStart(state, action);
    case EActionTypes.INFO_CONTACT_REQUEST_FIND_SUCCESS:
      return findInfoContactRequestsSuccess(state, action);
    case EActionTypes.INFO_CONTACT_REQUEST_FIND_FAIL:
      return findInfoContactRequestsFail(state, action);
    case EActionTypes.INFO_CONTACT_REQUEST_GET_START:
      return getInfoContactRequestStart(state, action);
    case EActionTypes.INFO_CONTACT_REQUEST_GET_SUCCESS:
      return getInfoContactRequestSuccess(state, action);
    case EActionTypes.INFO_CONTACT_REQUEST_GET_FAIL:
      return getInfoContactRequestFail(state, action);
    case EActionTypes.INFO_CONTACT_REQUEST_GET_CLEAR:
      return getInfoContactRequestClear(state, action);
    case EActionTypes.INFO_CONTACT_REQUEST_DELETE_START:
      return deleteInfoContactRequestStart(state, action);
    case EActionTypes.INFO_CONTACT_REQUEST_DELETE_SUCCESS:
      return deleteInfoContactRequestSuccess(state, action);
    case EActionTypes.INFO_CONTACT_REQUEST_DELETE_FAIL:
      return deleteInfoContactRequestFail(state, action);
    case EActionTypes.INFO_CONTACT_REQUEST_DELETE_CLEAR:
      return deleteInfoContactRequestClear(state, action);
    case EActionTypes.INFO_CONTACT_REQUEST_SAVE_START:
      return saveInfoContactRequestStart(state, action);
    case EActionTypes.INFO_CONTACT_REQUEST_SAVE_SUCCESS:
      return saveInfoContactRequestSuccess(state, action);
    case EActionTypes.INFO_CONTACT_REQUEST_SAVE_FAIL:
      return saveInfoContactRequestFail(state, action);
    case EActionTypes.INFO_CONTACT_REQUEST_UPDATE_START:
      return updateInfoContactRequestStart(state, action);
    case EActionTypes.INFO_CONTACT_REQUEST_UPDATE_SUCCESS:
      return updateInfoContactRequestSuccess(state, action);
    case EActionTypes.INFO_CONTACT_REQUEST_UPDATE_FAIL:
      return updateInfoContactRequestFail(state, action);
    case EActionTypes.INFO_CONTACT_REQUEST_SAVE_OR_UPDATE_CLEAR:
      return saveOrUpdateInfoContactRequestClear(state, action)
    default:
      return state;
  }
};

export default reducer;
