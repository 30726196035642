import React from 'react';

import Input, { IInputField, IInputOptions } from "../components/ui/Input/Input";
import { updateInputHandler } from "../components/ui/Input/input-utils";


export type TCreateInput = (inputName: string, options?: IInputOptions) => JSX.Element | null; 

export const useCreateInput = (inputs: IInputField, setInputs: React.Dispatch<React.SetStateAction<IInputField>>, commonOptions?: IInputOptions) => {
  
  const createInput: TCreateInput = (inputName: string, options?: IInputOptions) => {
    const item = inputs[inputName];
    if (!item) return null;
    return (
      <Input
        {...commonOptions}
        {...item}
        {...options}
        onChange={(value) => updateInputHandler(inputName, value, setInputs)}
        inputName={inputName}
      />
    );
  }; 

  return createInput;
}
