
interface Identifiable {
  id: string;
}

export const removeOne = <T extends Identifiable>(array: T[] | null, id?:string): T[] | null => {
  if(!id || !array) return array;
  return array.filter(item => item.id !== id);
}

export const insertFirst = <T>(array: T[] | null, item?: T): T[] | null => {
  if(!item || !array) return array;
  return [item, ...array];
}


export const updateOne = <T extends Identifiable>(array: T[] | null, item?: T): T[] | null => {
  if(!item || !array) return array;
  const newArray = [...array];
  const index = newArray.findIndex(arrayItem => arrayItem.id === item.id);
  if(index !== -1) {
    newArray[index] = item;
  }
  return newArray;
}