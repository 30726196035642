import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as dateFns from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLanguageName } from '../../../../hooks/useLanguageName';
import { IInfoContactRequest } from '../../../../interfaces/IInfoContactRequest';
import { getInfoContactRequestSubjectString } from '../../../../shared/info-contact-request-data';
import InfoContactRequestStatus from '../../InfoContactRequestStatus/InfoContactRequestStatus';

interface IProps {
  infoContactRequest: IInfoContactRequest;
  onEdit: (id: string) => void;
}

const InfoContactRequestListItem: React.FC<IProps> = ({ infoContactRequest, onEdit }) => {
  const { t } = useTranslation();

  const formatDate = (date?: string) => {
    if (!date) return null;
    return dateFns.format(dateFns.parseISO(date), "dd.MM.yyyy HH:mm");
  };

  const editHandler = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    onEdit(infoContactRequest.id);
  };

  const { user, status, subject, subjectOther, created, notes, municipality, needInterpreter, toLanguage } = infoContactRequest;
  const { language, phoneNumber} = user;

  const languageName = useLanguageName(language);
  const toLanguageName = useLanguageName(toLanguage);

  return (
    <>
      <tr key={infoContactRequest.id} onClick={editHandler}>
        <td><InfoContactRequestStatus status={status} /></td>
        <td>{formatDate(created)}</td>
        <td>{getInfoContactRequestSubjectString(t, subject, subjectOther)}</td>
        <td>{languageName}</td>
        <td><a href={`tel:${phoneNumber}`} onClick={e => e.stopPropagation()}>{phoneNumber}</a></td>
        <td>{municipality}</td>
        <td>
          {needInterpreter && (
            <><FontAwesomeIcon className="text-success" icon={faCheck} /> {toLanguageName}</>
          )}
        </td>
        <td><pre>{notes}</pre></td>
      </tr>
    </>
  );
};

export default InfoContactRequestListItem;
