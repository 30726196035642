import React from "react";
import { ISite } from "../../interfaces/ISite";
import { useNetvisorSettings } from "./NetvisorSettingsFormUtils";
import Spinner from "../ui/Spinner/Spinner";
import Alert from "../ui/Alert/Alert";


interface IProps {
	site: ISite;
}

export const NetvisorSettingsForm: React.FC<IProps> = ({ site }) => {
	const { netvisorSettings, loading, error } = useNetvisorSettings(site);

	return <>
		{loading && <Spinner />}
		{error && <Alert>{error}</Alert>}
		{netvisorSettings && <netvisorSettings.EditForm />}
	</>
}