import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import Alert, { EAlertColor } from "../../components/ui/Alert/Alert";
import Button, { EButtonColor } from "../../components/ui/Button/Button";
import classes from './reservationmodify.module.scss';

import Container from "../../components/ui/Container/Container";
import { EInputType, IInputField } from "../../components/ui/Input/Input";
import { getInputData } from "../../components/ui/Input/input-utils";
import InputGroup from "../../components/ui/InputGroup/InputGroup";
import Spinner from "../../components/ui/Spinner/Spinner";
import { customFetch, EFetchMethod } from "../../custom-fetch";
import { useCreateInput } from "../../hooks/useCreateInput";
import { ETranslation } from "../../translations/translation-keys";
import Heading from "../../components/Heading/Heading";

interface IProps extends RouteComponentProps { }

enum EInputs {
	modifyMessage = "modifyMessage",
};

interface IInputs {
	modifyMessage: string;
}

const ReservationModify: React.FC<IProps> = ({ history }) => {
	const [interpretation, setIntrepretation] = useState<null | string>(null);
	const [loading, setLoading] = useState(true);
	const [err, setErr] = useState<null | string>(null);
	const [resVal, setResVal] = useState<null | string>(null)

	const { t } = useTranslation();

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.modifyMessage]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.CANCEL_FORM_MODIFY_TEXT,
			value: "",
		}
	});

	const createInput = useCreateInput(inputs, setInputs);

	const mailSendHandler = () => {
		setLoading(true)
		if (interpretation != null) {
			const data = getInputData<IInputs>(inputs);
			customFetch("/interpretations/modifysendnotes", EFetchMethod.POST, JSON.stringify({ interpretationId: interpretation, mailBody: data.modifyMessage })).then((result) => {
				if (result === false) {
					setErr("Jokin meni pieleen, olethan yhteydessä asiakaspalveluun +358 45 668 8113");
				} else {
					setResVal("Ajanvaraukseen liittyvä muutospyyntö on rekisteröity onnistuneesti")
				}
				setLoading(false)
			})
		}
	}

	const reservationCancelHandler = () => {
		setLoading(true)
		if (interpretation != null) {
			setIntrepretation(interpretation);
			customFetch("/interpretations/modifycancel", EFetchMethod.POST, JSON.stringify({ interpretationId: interpretation })).then((result) => {
				if (result === false) {
					setErr("Jokin meni pieleen, olethan yhteydessä asiakaspalveluun +358 45 668 8113");
				} else {
					setResVal("Ajanvaraus on peruutettu onnistuneesti")
				}
				setLoading(false)
			})
		}
	}

	useEffect(() => {
		const interpretationParam = new URLSearchParams(window.location.search).get("interpretation")
		if (interpretationParam != null) {
			setIntrepretation(interpretationParam);
			customFetch("/interpretations/modifystatusvalid", EFetchMethod.POST, JSON.stringify({ interpretationId: interpretationParam })).then((result) => {
				if (result === false) {
					// Ajanvaraus on muokattavissa 8-16 arkitoimistonunnin sisään ajanvarauksen luomisesta. Muutoin ole yhteydessä asiakaspalveluun.
					setErr("Ajanvaraus on muokattavissa 8-16 arkitoimistonunnin sisään ajanvarauksen luomisesta. Muissa tapauksissa olethan yhteydessä asiakaspalveluun.");
				}
				setLoading(false);
			})
		}

	}, []);

	return (
		<Container>
			{loading ?
				<Spinner center />
				:
				err ?
					<Alert>{err}</Alert>
					:
					resVal ?
						<Alert color={EAlertColor.SUCCESS}>{resVal}</Alert>
						:
						<div className={classes.formContainer}>
							<Heading className={classes.headerText}>Varauksen muokkauspyyntölomake</Heading>
							<p className={classes.helperText}>Varauksestasi on kulunut 8 arkitoimistotuntia. Tulkin haku varaukseen on vielä kesken, joten voit halutessasi muuttaa tilausta tai antaa vaihtoehtoisia ajankohtia. Mikäli varaukseen ei tarvitse tehdä muutoksia, voit jäädä odottamaan. Vahvistamme varauksen sinulle mahdollisimman nopeasti.</p>
							<InputGroup>
								{createInput(EInputs.modifyMessage)}
							</InputGroup>
							<Button className={classes.formButton} onClick={mailSendHandler}>{t(ETranslation.COMMON_SEND)}</Button>
							<br />
							<br />
							<br />
							<br />
							<p>Vaihtoehtoisesti voit myös perua varauksen alla olevasta napista.</p>
							<Button className={classes.formButton} color={EButtonColor.DEFAULT} onClick={reservationCancelHandler}>{t(ETranslation.COMMON_CANCEL)}</Button>
						</div>
			}
		</Container>
	);
};

export default ReservationModify;
