import React, { ReactNode } from 'react';
import classes from './Container.module.scss';

interface IProps {
  children: ReactNode;
  refEl?: React.RefObject<HTMLDivElement>;
  className?: string;
}

const Container:React.FC<IProps> = ({ children, refEl, className }) => {
  return (
    <div ref={refEl} className={[classes.Container, className].join(" ")}>
      {children}
    </div>
  )
}

export default Container;