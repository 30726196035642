import React, { ReactNode, CSSProperties } from "react";
import classes from "./InputGroup.module.scss";

interface IProps {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
  maxItems?: 1 | 2 | 3 | 4;
  showBorder?: boolean;
}

const InputGroup: React.FC<IProps> = ({ children, style, className, maxItems, showBorder }) => {
  const classNames = [classes.Container];
  if (className) {
    classNames.push(className);
  }


  if (maxItems) {
	let maxItemsClassName;
	switch (maxItems) {
		case 1:
			maxItemsClassName = classes.MaxItems1;
			break;
		case 2:
			maxItemsClassName = classes.MaxItems2;
			break;
		case 3:
			maxItemsClassName = classes.MaxItems3;
			break;
		case 4:
			maxItemsClassName = classes.MaxItems4;
			break;
	}
	classNames.push(maxItemsClassName);
  }

  if (showBorder) {
	classNames.push(classes.ShowBorder);
  }



  return (
    <div className={classNames.join(" ")} style={style}>
      {children}
    </div>
  );
};

export default InputGroup;
