import { IOption } from "../components/ui/Input/Input";


export const exportTypeOptions: IOption[] = [
	{value: "ORDER_BY_START_DATE", label: "Järjestys: alkupvm."},
	{value: "ORDER_BY_INTERPRETER_NAME_AND_START_DATE", label: "Järjestys: tulkki ja alkupvm."},
	{value: "SHEET", label: "Taulukko driveen"},
	{value: "SORTED_SHEET_AND_PDF", label: "Lajiteltu taulukko ja pdf driveen"},
	{value: "SORTED_SHEET_PDF_AND_BILL", label: "Lajiteltu taulukko, pdf-liite ja lasku"},
	{value: "SORTED_SHEET_PDF_AND_BILL_WITH_AVERAGES", label: "Lajiteltu taulukko, pdf-liite ja lasku keskiarvoilla"},
	{value: "BILL_PER_INTERPRETATION", label: "Jokaisesta tulkkauksesta oma lasku"},
	{value: "FILTER_OUT_ZERO_PRICE", label: "Jätä pois 0-hintaiset"},
	{value: "ONLY_SELECTED_USER_ACCEPTANCE_FEES", label: "Vain valitun käyttäjän hyväksymismaksut"},
	{value: "ONLY_ONE_BILL", label: "Yksi lasku (Pääorganisaation / useiden organisaatioiden tapahtumat samalle laskulle)"},		
	{value: "SORTED_SHEET_PDF_AND_BILL_WITH_TODAY_AS_BILL_DATE", label: "Lajiteltu taulukko, pdf-liite ja lasku (laskupäivä: tänään)"},
	{value: "SORTED_SHEET_PDF_AND_BILL_WITH_AVERAGES_WITH_TODAY_AS_BILL_DATE", label: "Lajiteltu taulukko, pdf-liite ja lasku keskiarvoilla (laskupäivä: tänään)"},
	{value: "INVOICE_AMOUNT_CALCULATED_BY_NETVISOR", label: "Netvisor laskee laskun loppusumman"}

];