import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute } from '../../classes/Routes';
import Heading from '../../components/Heading/Heading';
import Alert from '../../components/ui/Alert/Alert';
import Container from '../../components/ui/Container/Container';
import EditButtons from '../../components/ui/EditButtons/EditButtons';
import Spinner from '../../components/ui/Spinner/Spinner';
import { IUserContractSettings } from '../../interfaces/IUserContractSettings';
import IAppState from '../../interfaces/store/IAppState';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';
import IUserContractState from '../../interfaces/store/IUserContractState';
import UserContractSettingsEdit from '../../components/UserContractSettings/UserContractSettingsEdit/UserContractSettingsEdit';

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const UserContractSettingsEditPage: React.FC<IProps> = ({ history, match }) => {
  const [showValidation, setShowValidation] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [
    editUserContractSettings,
    setEditUserContractSettings,
  ] = useState<IUserContractSettings | null>(null);

  const dispatch = useDispatch();

  const { id } = match.params;
  const isAdd = id === "add";

  const { t } = useTranslation();

  useEffect(() => {
    if (!isAdd) {
      dispatch(actions.getUserContractSettings(id));
    }
  }, [isAdd, id, dispatch]);

  const { error, loading, saveOrUpdateOk, setting, deleting, deletingError, deletingOk } = useSelector<IAppState, IUserContractState>(state => state.userContracts);

  useEffect(() => {
    if (saveOrUpdateOk) {
      history.push(ERoute.USER_CONTRACT_SETTINGS_LIST);
    }
    return () => {
      dispatch(actions.saveOrUpdateUserContractSettingsClear());
      dispatch(actions.getUserContractSettingsClear());
    };
  }, [dispatch, saveOrUpdateOk, history]);

  const updateHandler = useCallback(
    (settings: IUserContractSettings, isValid: boolean) => {
      setEditUserContractSettings(settings);
      setIsValid(isValid);
    },
    []
  );

  const submitHandler = () => {
    if (editUserContractSettings) {
      if (isAdd) {
        dispatch(actions.saveUserContractSettings(editUserContractSettings));
      } else {
        editUserContractSettings.id = id;
        dispatch(actions.updateUserContractSettings(editUserContractSettings));
      }
    }
  };

  const deleteHandler = () => {
    if(setting) {
      dispatch(actions.deleteUserContractSettings(setting.id));
    }
  }

  return (
    <Container>
      {error && <Alert>{error}</Alert>}
      {loading && <Spinner centerAbsolute />}
      <Heading>{t(ETranslation.USER_CONTRACT_SETTINGS_EDIT_TITLE)}</Heading>
      <UserContractSettingsEdit
        onChange={updateHandler}
        settings={setting}
        loading={loading}
        showValidation={showValidation}
      />
      {error && <Alert>{error}</Alert>}
      <EditButtons
        onSave={isValid ? submitHandler : () => setShowValidation(true)}
        disabled={showValidation && !isValid}
        loading={loading}
        onCancel={() => history.goBack()}
        onDelete={deleteHandler}
        deleteText={t(ETranslation.USER_CONTRACT_SETTINGS_EDIT_DELETE)}
        onDeleteClose={() => dispatch(actions.deleteUserContractSettingsClear())}
        onDeleteDone={() => history.push(ERoute.USER_CONTRACT_SETTINGS_LIST) }
        deleteError={deletingError}
        deleteLoading={deleting}
        deleteOk={deletingOk}
        isAdd={isAdd}
      />
    </Container>
  );
};

export default UserContractSettingsEditPage;
