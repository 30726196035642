import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import IAppState from '../interfaces/store/IAppState';
import { IOption } from './../components/ui/Input/Input';
import { createLanguageOptionsFi } from './../utils/option-utils';


export const useLanguages = () => {
	const [languageOptions, setLanguageOptions] = useState<IOption[]>([]);

	const { languages } = useSelector((state: IAppState) => state.common);

	useEffect(() => {
		if (languages) {
			setLanguageOptions(createLanguageOptionsFi(languages));
		}
	}, [languages]);

	return { languageOptions };
};
