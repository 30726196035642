import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/ui/Spinner/Spinner";
import Table from "../../components/ui/Table/Table";
import { ITask, ITaskitem } from "../../store/actions/tasksActions";
import { ETranslation } from "../../translations/translation-keys";

interface IProps {
	tasks: ITask[] | null;
	taskItems?: ITaskitem[] | null;
	error: string | null;
	loading: boolean;
}

const TasksList: React.FC<IProps> = ({ tasks, error, loading }) => {
	const { t } = useTranslation();


	return loading ? (
		<Spinner />
	) : error ? (
		<tr>{error}</tr>
	) : (
		<Table hover={true}>
			<thead>
				<tr>
					<th>{t(ETranslation.COMMON_CREATED)}</th>
					<th>{t(ETranslation.TASKS_ORGANIZATION)}</th>
					<th>{t(ETranslation.TASKS_COMMISSIONER)}</th>
					<th>{t(ETranslation.TASKS_ORDERED_TASKITEMS)}</th>
					<th>{t(ETranslation.TASKS_NOTES)}</th>
					<th>{t(ETranslation.COMMON_ADMIN_NOTES)}</th>
				</tr>
			</thead>
			<tbody>
				{tasks &&
					tasks.map((task, id) => {
						return (
							<tr key={id}>
								<td>{task.createdDateTime}</td>
								<td>{task.unitName}</td>


								<td>
									<table>
										<tbody>
											{task.taskItems?.map((taskItem, id) => (
												<tr key={id}>
													<td>{taskItem.user?.firstName}</td>
													<td>{taskItem.user?.lastName}</td>
													<td>{taskItem.user?.email}</td>
													<td>{taskItem.user?.phoneNumber}</td>
												</tr>
											))}
										</tbody>
									</table>

								</td>

								<td>{task.user.firstName} {task.user.lastName}</td>
								<td>{task.notesText}</td>
								<td>{task.adminNotesText}</td>
							</tr>
						);
					})}
			</tbody>
		</Table>
	);
};
export default TasksList;
