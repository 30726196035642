import React, { useState, useEffect } from "react";
import { IPriceSeason } from "../../../../interfaces/IPriceSeason";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import classes from "./PriceSeasonListItem.module.scss";

import * as dateFns from "date-fns";
import ActiveBadge from "../../../ui/ActiveBadge/ActiveBadge";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "../../../../store/actions";
import IAppState from "../../../../interfaces/store/IAppState";
import Spinner from "../../../ui/Spinner/Spinner";
import PriceSeasonRuleList from "../../PriceSeasonRule/PriceSeasonRuleList/PriceSeasonRuleList";
import { IPriceSeasonRule } from "../../../../interfaces/IPriceSeasonRule";
import Fieldset from "../../../ui/Fieldset/Fieldset";

interface IProps {
  priceSeason: IPriceSeason;
  onEdit: (id: string) => void;
}

const PriceSeasonListItem: React.FC<IProps> = ({ priceSeason, onEdit }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const { loading, priceSeasonId } = useSelector((state: IAppState) => ({
    loading: state.priceSeason.priceSeasonLoading,
    priceSeasonId: state.priceSeason.priceSeasonId
  }));

  const formatDate = (date?: string) => {
    if (!date) return null;
    return dateFns.format(dateFns.parseISO(date), "dd.MM.yyyy");
  };

  const editHandler = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    onEdit(priceSeason.id);
  };

  const toggleOpen = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const iconClassNames = [classes.Icon];
  if (isOpen) {
    iconClassNames.push(classes.IconOpen);
  }

  useEffect(() => {
    if (!priceSeason.fromGet && isOpen) {
      dispatch(actions.getPriceSeason(priceSeason.id));
    }
    // eslint-disable-next-line
  }, [isOpen, dispatch]);

  return (
    <>
      <tr key={priceSeason.id} onClick={toggleOpen}>
        <td>{priceSeason.name}</td>
        <td>
          {formatDate(priceSeason.startDate)} -{" "}
          {formatDate(priceSeason.endDate)}
        </td>
        <td>
          <ActiveBadge active={priceSeason.active} />
        </td>
        <td>
          <span onClick={editHandler}>
            <FontAwesomeIcon icon={faEdit} />
          </span>
        </td>
        <td>
          <FontAwesomeIcon
            className={iconClassNames.join(" ")}
            icon={faChevronDown}
          />
        </td>
      </tr>
      {isOpen && (
        <tr className={classes.ToggleContent}>
          <td colSpan={5}>
            {loading && priceSeasonId === priceSeason.id ? (
              <Spinner />
            ) : (
              <Fieldset label={priceSeason.name} style={{ margin: 0}} transparent>
                <p>{priceSeason.description}</p>
                <PriceSeasonRuleList
                  priceSeasonRules={priceSeason.rules as IPriceSeasonRule[]}
                  error={null}
                  loading={false}
                  showDetails
                />
              </Fieldset>
            )}
          </td>
        </tr>
      )}
    </>
  );
};

export default PriceSeasonListItem;
