import React, { useEffect, useState } from "react";
import { ETranslation } from "../../translations/translation-keys";
import { EInputType, IInputField, IOption } from "../ui/Input/Input";
import * as dateFns from 'date-fns';
import InputGroup from "../ui/InputGroup/InputGroup";
import { useCreateInput } from "../../hooks/useCreateInput";
import { getInputData } from "../ui/Input/input-utils";
import { IUser } from "../../classes/User";
import Button, { EButtonColor } from "../ui/Button/Button";
import { EFetchMethod, customFetchWithResponse } from "../../custom-fetch";
import { useTranslation } from "react-i18next";
import { setParameter, clearParameter } from "../../services/useSearchInputCache";
import { ESearchStorageKey } from "../../shared/log-items-data";

enum EInputs {
	user = "user",
	dateStart = "dateStart",
	dictCheck = "dictCheck",
};

export interface IFilterOptions {
	user: string;
	dateStart: string;
	dictCheck: boolean;
}

export const defaultStartDate = dateFns.format(
	new Date(),
	"yyyy-MM-dd HH:mm"
);

export const defaultEndDate = dateFns.format(
	new Date(),
	"yyyy-MM-dd HH:mm"
);

interface IProps {
	searchFromServer: (userId: string, dateStart: string, dictionarySearch: boolean) => void
}

const CommissionsSearch: React.FC<IProps> = ({ searchFromServer }) => {

	const { t } = useTranslation()

	useEffect(() => {
		if (inputs) {
			const inputFieldNames = Object.keys(inputs);
			const updatedInputs = { ...inputs };
			inputFieldNames.forEach((fieldName) => {
				const savedItem = localStorage.getItem(ESearchStorageKey.COMISSIONS_SEARCH);
				if (savedItem) {
					var parsedObject = JSON.parse(savedItem);
					var fieldObject = parsedObject[fieldName];
					const newValue = fieldObject.value;
					updatedInputs[fieldName] = {
						...updatedInputs[fieldName],
						value: newValue,
					};
				} else {

				}
			});
			setInputs(updatedInputs);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.user]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_USERS,
			value: null,
			multiple: false,
		},
		[EInputs.dateStart]: {
			type: EInputType.datepicker,
			labelTranslation: ETranslation.COMMON_START_DATE,
			value: "",
		},
		[EInputs.dictCheck]: {
			type: EInputType.boolean,
			label: "Dict haku",
			value: false,
		},
	});

	const createInput = useCreateInput(inputs, setInputs);

	const onSearch = () => {
		let data = getInputData<IFilterOptions>(inputs);
		searchFromServer(data.user, data.dateStart, data.dictCheck);
		setParameter(ESearchStorageKey.COMISSIONS_SEARCH, inputs)
	}
	const clearInputs = () => {
		clearParameter(ESearchStorageKey.COMISSIONS_SEARCH)
	}

	// Once displayed, fetch organizationGroups
	useEffect(() => {
		onSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<InputGroup>
				{createInput(EInputs.user, {
					fetchOptions: async (term, signal) => {
						const res = await customFetchWithResponse("/commissions/search?q=" + JSON.stringify(term), EFetchMethod.GET, undefined, signal)
						const json: IUser[] | string = await res.json();
						const options: IOption[] = [];
						if (typeof json != "string") {
							for (let user of json) {
								options.push({
									value: user.id,
									label: `${user.firstName} ${user.lastName} ${user.email} ${user.phoneNumber}`,
								})
							}
						} else {
							options.push({ value: "", labelTranslation: ETranslation.NOT_FOUND_ERROR })
						}
						return options;
					},
				})}
				{createInput(EInputs.dateStart)}
				{createInput(EInputs.dictCheck)}
				<Button onClick={onSearch} >{t(ETranslation.COMMON_SEARCH)}</Button>
				<Button color={EButtonColor.DEFAULT} onClick={clearInputs}>{t(ETranslation.UI_CLEAR)}</Button>
			</InputGroup>
		</div>
	)
};

export default CommissionsSearch;