import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';

import IAppState from '../../interfaces/store/IAppState';
import classes from './Logo.module.scss';
import logo from '../../assets/images/youpret-logo-vaaka.svg';

interface IProps {
  containerStyle?: CSSProperties;
  style?: CSSProperties;
}

const Logo: React.FC<IProps> = ({ containerStyle, style }) => {
  const { user } = useSelector((state: IAppState) => state.auth);
  
  
  return (
  <div
    className={classes.Logo}
    style={{ ...containerStyle, cursor: "pointer" }}
    onClick={() => (window.location.href ="/")}
  >
    { user?.site?.name || <img src={logo} alt="Youpret" /> }
  </div>
)};

export default Logo;

// <img src={youpretLogo} alt="Youpret" style={style} />
