import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import IAppState from "../interfaces/store/IAppState";
import * as actions from "../store/actions";
import { ICommissionSeachParams } from "../store/actions/commissionActions";
import ICommission from "../interfaces/ICommission";


export const PAGINATION_FETCH_SIZE = 50;

export const CommissionPagination = function <T>() {
	const dispatch = useDispatch();
	const loaderRef = useRef<HTMLParagraphElement>(null);

	const [searchOptions, setSearchOptions] = useState<ICommissionSeachParams | null>(null);
	const [commissions, setCommissions] = useState<ICommission[] | null>(null);

	const [loadingCheck, setLoadingCheck] = useState(false);

	const {
		commissions: StateCommissions,
		paginationCursor,
		hasMoreCommissions,
		commissionsLoading,
		error
	} = useSelector((state: IAppState) => state.commissions);

	useEffect(() => {
		setCommissions(StateCommissions);
		setLoadingCheck(false);
	}, [StateCommissions]);

	const searchHandler = useCallback((search: ICommissionSeachParams) => {
		dispatch(actions.searchCommissions(search));
		setSearchOptions(search);
	}, [dispatch]);

	// Tää se muuten sit syö muistia juoksussa!
	const handleObserver = useCallback((entries) => {
		if (!hasMoreCommissions || !searchOptions || commissionsLoading || loadingCheck || !StateCommissions || StateCommissions?.length === 0) return;
		setLoadingCheck(true);
		const target = entries[0];
		if (target.isIntersecting) {
			searchHandler({
				...searchOptions,
				start: StateCommissions.length,
				paginationCursor: paginationCursor ?? "",
			});
		} else {
			setLoadingCheck(false);
		}
	}, [commissionsLoading, StateCommissions, searchHandler, searchOptions, hasMoreCommissions, paginationCursor, loadingCheck]);

	useEffect(() => {
		const observer = new IntersectionObserver(handleObserver, {
			root: null,
			rootMargin: "",
			threshold: 1,
		});
		if (loaderRef.current) observer.observe(loaderRef.current);
		return () => observer.disconnect();
	}, [handleObserver]);

	return { loaderRef, commissions, commissionsLoading, searchHandler, hasMoreCommissions, commissionsError: error };
};
