import React, { useCallback, useState } from "react";
import { ETranslation } from "../../../../translations/translation-keys";
import {  IInputField } from "../../../ui/Input/Input";
import { useCreateInput } from "../../../../hooks/useCreateInput";
import { ESearchStorageKey } from "../../../../shared/log-items-data";
import { useDispatch } from "react-redux";
import { EUserStatus, IUser } from "../../../../classes/User";
import { IPaginationOptions, PAGINATION_FETCH_SIZE } from "../../../../hooks/userPagination";
import * as actions from '../../../../store/actions';
import { useStoredSearchParams } from "../../../../hooks/useStoredSearchParams";
import IUserAction from "../../../../interfaces/store/IUserAction";
import { useInitSearchValues } from "../../../../hooks/useInitSearchValues";
import { getUserOptions, searchInterpreters } from "../../../../services/userServices";
import { getOrganizationOptions, searchOrganizations } from "../../../../services/organizationServices";
import AddNewBtn from "../../AddNewUserBtn";
import InputGroup from "../../../ui/InputGroup/InputGroup";
import { RouteComponentProps } from "react-router-dom";
import { ERoute } from "../../../../classes/Routes";
import Button, { EButtonColor } from "../../../ui/Button/Button";
import { initForm, getInputData } from "../../../ui/Input/input-utils";
import { useTranslation } from "react-i18next";
import InterpretersFilters from "./InterpretersFilters";
import { EInterpreterSearchInputs, initialInterpreterSearchInputs } from "./InterpretersSearchInitialInputs";



export interface IFilterOptions {
	organization: string[];
	user: string;
	status: EUserStatus[];
	fetchDeleted: boolean;
}

interface IProps extends RouteComponentProps {
	searchHandler: (search: IPaginationOptions) => void
	items: IUser[]
	loading: boolean;
	setFilteredUsers: React.Dispatch<React.SetStateAction<IUser[]>>
}

const InterpretersSearch: React.FC<IProps> = ({ searchHandler, items, history, location, match, loading, setFilteredUsers }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [inputs, setInputs] = useState<IInputField>(initialInterpreterSearchInputs);
	const createInput = useCreateInput(inputs, setInputs);

	const { setIds: setOrganizationIds, isLoading: isLoadingOrganizations } = useInitSearchValues(setInputs, EInterpreterSearchInputs.organization, getOrganizationOptions);
	const { setIds: setUserIds, isLoading: isLoadingUsers } = useInitSearchValues(setInputs, EInterpreterSearchInputs.user, getUserOptions);

	const { onSearch, clearInputs } = useStoredSearchParams<IFilterOptions, IUserAction, IPaginationOptions>({
		setInputs,
		initialInputs: initialInterpreterSearchInputs,
		storageKey: ESearchStorageKey.INTERPRETERS_SEARCH_PARAMS,
		clearResults: useCallback(() => dispatch(actions.getUsersClear()), [dispatch]),
		searchHandlerDataTransform: useCallback((data: IFilterOptions) => {
			return {
				type: "INTERPRETER",
				user: data.user,
				organization: data.organization ? data.organization : null,
				fetchSize: PAGINATION_FETCH_SIZE,
				status: data.status,
				fetchDeleted: data.fetchDeleted,
				start: 0,
			}
		}, []),
		searchHandler,
		initIdInputs: useCallback((data: IFilterOptions) => {
			initForm(setInputs, { ...data, user: '', organization: [] });
			if (data.organization) setOrganizationIds(data.organization)
			if (data.user) setUserIds([data.user])
		}, [setUserIds, setOrganizationIds])
	})

	return (
		<>
			<AddNewBtn route={ERoute.USER_EDIT} id={"addInterpreter"} history={history} location={location} match={match} />
			<InputGroup>{createInput(EInterpreterSearchInputs.organization, { fetchOptions: searchOrganizations, isLoading: isLoadingOrganizations })}</InputGroup>
			<InputGroup>{createInput(EInterpreterSearchInputs.user, { fetchOptions: searchInterpreters, isLoading: isLoadingUsers })}</InputGroup>
			<InputGroup>{createInput(EInterpreterSearchInputs.status)}</InputGroup>
			<InputGroup>{createInput(EInterpreterSearchInputs.fetchDeleted)}</InputGroup>
			<InterpretersFilters setFilteredUsers={setFilteredUsers} items={items} inputs={inputs} createInput={createInput} />
			<InputGroup>
				<Button loading={loading} onClick={() => onSearch(getInputData<IFilterOptions>(inputs))}>{t(ETranslation.COMMON_SEARCH)}</Button>
				<Button loading={loading} color={EButtonColor.DEFAULT} onClick={clearInputs}>
					{t(ETranslation.UI_CLEAR)}
				</Button>
			</InputGroup>
		</>
	)
}

export default InterpretersSearch;

