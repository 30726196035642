import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';

import Heading from '../../components/Heading/Heading';
import StatisticsPriceBox, { EStatisticsColor } from '../../components/StatisticsPriceBox/StatisticsPriceBox';
import Container from '../../components/ui/Container/Container';
import Input, { EInputType, IInputField, IInputOptions, IOption } from '../../components/ui/Input/Input';
import { updateInputHandler } from '../../components/ui/Input/input-utils';
import Table from '../../components/ui/Table/Table';
import { customFetch, EFetchMethod } from '../../custom-fetch';
import { IGraphTopUsers, IStatisticsItem } from '../../interfaces/IStatisticsCustomerReportPage';
import { searchOrganizations } from '../../services/searchServices';
import { ETranslation } from '../../translations/translation-keys';

//added AB 2021:
//import * as V from 'victory'; //visuals used from: https://formidable.com/open-source/victory/
//added AB
enum EInputs {
	testInput = "testInput",
	testInputCustomerUser = "testInputCustomerUser",
	organizations = "organizations",
	userOptions = "userOptions",
	languageOptions = "languageOptions", //used
	selectOrganizationUsers = "selectOrganizationUsers",
	usersAsIOption = "usersAsIOption", //used
}

//TODO: Samu suggested this, investigate how to pass the API call into a function
//const service ={
//  getId: async (): Promise<IResult> => {
//    return customFetch<any>('/statistics/interpretations?tzString=Europe/Helsinki&refresh=true&_=1622696895164', EFetchMethod.GET, undefined)
//      .then(res => res.data);
//    }
//}

//main
const CustomerReportsPage: React.FC = () => {
	//-----------------------------------------------------
	//---GENERATE VARIABLES
	//-----------------------------------------------------

	//added AB 5.2021
	//  const [dataFromServer,setDataFromServer] = useState<string | null>(); //data assigned to dataFromServer in state
	const [statisticsItem, setStatisticsItem] = useState<IStatisticsItem | null>(); //data assigned to dataFromServer in state
	//const [uniqOrganizationUsersList,setUniqOrganizationUsersList]=useState<IStatisticsItem | null>();
	//const [languageMenu,setLanguageMenu] = useState<IOption[]>([]);
	//const [singleVar,setSingleVar]=useState<string>();
	//const [selectedOrganizationKeyString,setSelectedOrganizationKeyString]=useState<string>();
	//const [selectedUser,setSelectedUser]=useState<string>();
	//const [selectedLanguage,setSelectedLanguage]=useState<string>();
	const { t } = useTranslation();
	//  const [languageOptions,setLanguageOptions]=useState<IOption[]>();
	/*
  const dispatch = useDispatch();
  const { infoContactRequests, loading, error } = useSelector(
    (state: IAppState) => ({
      infoContactRequests: state.infoContactRequest.infoContactRequests,
      loading: state.infoContactRequest.infoContactRequestsLoading,
      error: state.infoContactRequest.infoContactRequestsError,
    })
  );
  */

	//https://developer.mozilla.org/en-US/docs/Learn/Tools_and_testing/Client-side_JavaScript_frameworks/React_interactivity_filtering_conditional_rendering

	/*  
const renderLanguages = (itemLanguages?: string[]) => {
  if(!itemLanguages || itemLanguages.length === 0) return null;
  if(!languages) return null;
  return itemLanguages.map(itemLanguage => languages?.find(language => language.id === itemLanguage)?.nameFi).join(', ');
}
*/

	//TESTS, TODO: delete
	const USER: IOption[] = [
		{
			value: "aglub19hcHBfaWRyEQsSBFVzZXIYgICAgICAwAsM",
			labelTranslation: ETranslation.PAGES_CUSTOMER_REPORTS_SUBSCRIBER_A,
		},
		{
			value: "aglub19hcHBfaWRyEQsSBFVzZXIYgICAgICAoAkM",
			labelTranslation: ETranslation.PAGES_CUSTOMER_REPORTS_SUBSCRIBER_B,
		},
	];
	const ORGANIZATION: IOption[] = [
		{ value: "aglub19hcHBfaWRyGQsSDE9yZ2FuaXphdGlvbhiAgICAgIDICww", label: "Organization A" },
		{ value: "aglub19hcHBfaWRyGQsSDE9yZ2FuaXphdGlvbhiAgICAgIDECgw", label: "Organization D" },
	];
	//const USER2: IOption[] = [
	//  { value: uniqOrganizationUsersList?.UniqueUsers[0]!, label: uniqOrganizationUsersList?.UniqueUsers[0]!}, //empty string is uniqOrganizationUsersList?.UniqueUsers[0]!
	//  { value: uniqOrganizationUsersList?.UniqueUsers[1]!, label: uniqOrganizationUsersList?.UniqueUsers[1]!},
	//]

	//user choice
	//  let toUpdate = null;
	//  toUpdate=statisticsItem?.UniqueUsers!;
	//let userOptions=null;//: IOption[] = [];       //let options = null;
	//  if (toUpdate) {
	//    userOptions = toUpdate.map((each,index) => {return(<option value={each} key={each} selected={index===0}>{each}</option>)});
	//    }

	//language choice
	//  let languageToUpdate = null;
	//  languageToUpdate=statisticsItem?.UniqueLanguages!;
	//let languageOptions=null;//: IOption[] = [];       //let options = null;
	//  if (languageToUpdate) {
	//    languageOptions = languageToUpdate.map(each => {return(<option value={each} key={each}>{each}</option>)});
	//    }

	//language options with language names
	//let languageOptionsWithNames=null;//: IOption[] = [];       //let options = null;
	let languageToUpdateWithNames = null;
	let languagesAsIOption: IOption[] = [];
	languageToUpdateWithNames = statisticsItem?.UniqueLanguagesMapping!;
	if (languageToUpdateWithNames != null) {
		//    languageOptionsWithNames = languageToUpdateWithNames.map(each => {return(<option>{JSON.parse(JSON.stringify(each)).languageName}</option>)});
		//const getLanguagesAsIOptions = (): IOption[] => {
		//  return languageOptionsWithNames.map((item) => ({ label: item, value: item }));
		//}
		languagesAsIOption = languageToUpdateWithNames.map((each) => ({
			value: JSON.parse(JSON.stringify(each)).languageKeyString,
			label: JSON.parse(JSON.stringify(each)).languageName,
		}));
		//    console.log("languagesAsIOption label 0: "+languagesAsIOption[0].label);
		//    console.log("languagesAsIOption: "+languagesAsIOption[0].value);
		//    console.log(languagesAsIOption);
		//    console.log(USER);
		//  TEST=createOptions(languageToUpdateWithNames);
	}

	//users options with users names
	//let usersOptionsWithNames=null;//: IOption[] = [];       //let options = null;
	let usersToUpdateWithNames = null;
	let usersAsIOption: IOption[] = [];
	//let usersAsIOptionable: IOptionable[]=[];
	usersToUpdateWithNames = statisticsItem?.UniqueUsersMapping!;
	if (usersToUpdateWithNames != null) {
		//    usersOptionsWithNames = usersToUpdateWithNames.map(each => {return(<option>{JSON.parse(JSON.stringify(each)).userKeyStringLastName}</option>)});
		//const getLanguagesAsIOptions = (): IOption[] => {
		//  return languageOptionsWithNames.map((item) => ({ label: item, value: item }));
		//}
		usersAsIOption = usersToUpdateWithNames.map((each) => ({
			value: JSON.parse(JSON.stringify(each)).userKeyString,
			label: JSON.parse(JSON.stringify(each)).userKeyStringLastName,
		}));
		//  usersAsIOptionable=usersToUpdateWithNames.map((each) => ({ id: JSON.parse(JSON.stringify(each)).userKeyString, name: JSON.parse(JSON.stringify(each)).userKeyStringLastName }));
		//    console.log("usersAsIOption label 0: "+usersAsIOption[0].label);
		//    console.log("usersAsIOption: "+usersAsIOption[0].value);
		//    console.log(usersAsIOption);
		//  TEST=createOptions(languageToUpdateWithNames);
	}

	//top users
	let topUsersAsIOptionFromState = null;
	let topUsersAsIOption: IGraphTopUsers[] = [];
	topUsersAsIOptionFromState = statisticsItem?.expensesByUser!;
	if (topUsersAsIOptionFromState && topUsersAsIOptionFromState != null && topUsersAsIOptionFromState !== undefined) {
		topUsersAsIOption = topUsersAsIOptionFromState.map((each) => ({
			x: String(JSON.parse(JSON.stringify(each)).topUserLastName),
			y: JSON.parse(JSON.stringify(each)).expensesPreviousMonth,
		}));
		if (topUsersAsIOption[0] !== undefined) {
			//      console.log("topUsersAsIOption label 0: "+topUsersAsIOption[0].x);
			//      console.log("topUsersAsIOption: "+topUsersAsIOption[0].y);
		}
		//    console.log("topUsersAsIOption: "+topUsersAsIOption);
		if (topUsersAsIOption[0] === undefined) {
			topUsersAsIOption = [{ x: "none", y: 0 }];
		}
	}

	//var firstTopUser="firstTopUser";
	//if (topUsersAsIOption[0]!==undefined) {
	// firstTopUser=topUsersAsIOption[0].x;
	//};

	/*models:

  const getTimezones = (): IOption[] => {
    const timezones = [ETimezone.EuropeHelsinki];
    return timezones.map((timezone) => ({ label: timezone, value: timezone }));
  }

  value = value.map((value: IOptionable) => ({ value: value.id, label: value.name }));
  */

	//model: generate inputs using state, did not work for dynamic update hence discarded for now, kept for reference
	var [inputs, setInputs] = useState<IInputField>({
		[EInputs.testInput]: {
			type: EInputType.select,
			labelTranslation: ETranslation.PAGES_CUSTOMER_REPORTS_CHOOSE_ORGANIZATION,
			value: "",
			options: [
				{ value: "", labelTranslation: ETranslation.PAGES_CUSTOMER_REPORTS_ALL_ORGANIZATIONS },
				...ORGANIZATION,
			],
			validation: {
				required: true,
			},
		},
		[EInputs.testInputCustomerUser]: {
			type: EInputType.select,
			labelTranslation: ETranslation.PAGES_CUSTOMER_REPORTS_CHOOSE_SUBSCRIBER,
			value: "",
			options: [{ value: "", labelTranslation: ETranslation.PAGES_CUSTOMER_REPORTS_ALL_SUBSCRIBERS }, ...USER],
			validation: {
				required: true,
			},
		},
		[EInputs.organizations]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.PAGES_CUSTOMER_REPORTS_CHOOSE_CUSTOMER_ORGANIZATION,
			value: [],
			multiple: true,
		},
		[EInputs.usersAsIOption]: {
			type: EInputType.select,
			label: "Valitse käyttäjä",
			value: "",
			options: [
				{ value: "", labelTranslation: ETranslation.PAGES_CUSTOMER_REPORTS_ALL_SUBSCRIBERS },
				...usersAsIOption,
			],
			multiple: false,
		},
		[EInputs.languageOptions]: {
			type: EInputType.select,
			labelTranslation: ETranslation.COMMON_CHOOSE_LANGUAGE,
			value: "",
			options: [
				{ value: "", labelTranslation: ETranslation.PAGES_CUSTOMER_REPORTS_ALL_LANGUAGES },
				...languagesAsIOption,
			],
			multiple: false,
		},
	});

	const createInputAdmin = (inputName: EInputs, options?: IInputOptions) => {
		const item = inputs[inputName];
		if (statisticsItem?.userRole === "admin") {
			//show only if admin
			return (
				<Input
					{...item}
					{...options}
					onChange={(value) => updateInputHandler(inputName, value, setInputs)}
					inputName={inputName}
					//       disabled={loading}
					//       showValidation={showValidation}
				/>
			);
		}
	};

	//TODO: do the same for Customer if we want to show only to customer
	const createInputCustomer = (inputName: EInputs, options?: IInputOptions) => {
		const item = inputs[inputName];
		if (statisticsItem?.userRole === "customer" || statisticsItem?.userRole === "admin") {
			//show only if admin or customer
			return (
				<Input
					{...item}
					{...options}
					onChange={(value) => updateInputHandler(inputName, value, setInputs)}
					inputName={inputName}
					//          disabled={loading}
					//          showValidation={showValidation}
				/>
			);
		}
	};

	//GET EInputs values
	//handle variables chosen in user filter
	//const testInputVar = inputs[EInputs.testInput].value as string;
	//console.log("testInputVar:"+testInputVar);
	//const testInputVarLabel = inputs[EInputs.testInput].label as string;
	//console.log("testInputVarLabel:"+testInputVarLabel);
	//const testInputCustomerUser = inputs[EInputs.testInputCustomerUser].value as string;
	//console.log("testInputCustomerUser:"+testInputCustomerUser);
	//organization
	const inputOrganization = inputs[EInputs.organizations].value as string; //IInputFieldItem;
	//selected user
	const inputUser = inputs[EInputs.usersAsIOption].value as string; //IInputFieldItem;
	//  console.log("inputUser: "+inputUser)
	//selected language
	const inputLanguage = inputs[EInputs.languageOptions].value as string; //IInputFieldItem;
	//  console.log("inputLanguage: "+inputLanguage)

	//update the users filter **USELESS** TODO: delete
	//const changeUserOptionHandler = (event: React.ChangeEvent<any>) => {
	//  setSelectedOrganizationKeyString(JSON.parse(JSON.stringify(inputOrganization[0])).value);
	//};

	//last day of month (31)
	var lastDayOfMonthCumulatedPreviousMonth = 0;
	if (statisticsItem?.cumulatedPreviousMonth[29]!) {
		lastDayOfMonthCumulatedPreviousMonth = statisticsItem?.cumulatedPreviousMonth[29]!;
	}
	if (statisticsItem?.cumulatedPreviousMonth[30]) {
		lastDayOfMonthCumulatedPreviousMonth = statisticsItem?.cumulatedPreviousMonth[30]!;
	}
	var lastDayOfMonthCumulatedMonthBeforePreviousMonth = 0;
	if (statisticsItem?.cumulatedMonthBeforePreviousMonth[29]) {
		lastDayOfMonthCumulatedMonthBeforePreviousMonth = statisticsItem?.cumulatedMonthBeforePreviousMonth[29]!;
	}
	if (statisticsItem?.cumulatedMonthBeforePreviousMonth[30]) {
		lastDayOfMonthCumulatedMonthBeforePreviousMonth = statisticsItem?.cumulatedMonthBeforePreviousMonth[30]!;
	}

	//list generation for cumulatedPreviousMonthList
	const monthDays = [
		0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
	];
	//var cumulatedPreviousMonthList=monthDays.map((number) => ({ x: number+1, y: 0 }));
	var cumulatedPreviousMonthList = monthDays.map((number) => {
		if (statisticsItem?.cumulatedPreviousMonth[number] !== undefined) {
			return { x: number + 1, y: statisticsItem?.cumulatedPreviousMonth[number] };
		}
		return { x: number + 1, y: 0 };
	});
	//  var cumulatedPreviousMonthList=monthDays.map((number) => ({ x: number+1, y: statisticsItem?.cumulatedPreviousMonth[number] }));
	cumulatedPreviousMonthList[30] = { x: 31, y: lastDayOfMonthCumulatedPreviousMonth }; //add element ot list

	//list generation for cumulatedMonthBeforePreviousMonthList
	//  var cumulatedMonthBeforePreviousMonthList=monthDays.map((number) => ({ x: number+1, y: statisticsItem?.cumulatedMonthBeforePreviousMonth[number] }));
	var cumulatedMonthBeforePreviousMonthList = monthDays.map((number) => {
		if (statisticsItem?.cumulatedMonthBeforePreviousMonth[number] !== undefined) {
			return { x: number + 1, y: statisticsItem?.cumulatedMonthBeforePreviousMonth[number] };
		}
		return { x: number + 1, y: 0 };
	});
	cumulatedMonthBeforePreviousMonthList[30] = { x: 31, y: lastDayOfMonthCumulatedMonthBeforePreviousMonth }; //add element ot list

	//var test="";
	//if (statisticsItem?.UniqueLanguagesMapping!) {
	//  test=JSON.parse(JSON.stringify(statisticsItem?.UniqueLanguagesMapping![0])).languageName;
	//}

	//-----------------------------------------------------
	//---UPDATE STATE WHEN FILTERS ARE USED
	//-----------------------------------------------------
	//TODO: use params as suggested by Samu
	useEffect(() => {
		var orgKeyStringForAPIrequest = "";
		if (inputOrganization.length > 0 && JSON.parse(JSON.stringify(inputOrganization[0])).value != null) {
			orgKeyStringForAPIrequest = JSON.parse(JSON.stringify(inputOrganization[0])).value;
		}
		var user = "";
		//      if(selectedUser){
		//        user=selectedUser;
		//      }
		if (inputUser.length > 0) {
			user = inputUser;
			console.log("user: " + user);
		}
		var language = "";
		//      if(selectedLanguage){
		//        language=selectedLanguage;
		//      }
		if (inputLanguage.length > 0) {
			language = inputLanguage;
		}

		//API call
		customFetch<IStatisticsItem>(
			"/statistics/customermonthlyreport?month=052021&userFilterKeyString=" +
				user +
				"&organizationKeyString=" +
				orgKeyStringForAPIrequest +
				"&languageFilterKeyString=" +
				language,
			EFetchMethod.GET,
			undefined
		) //new: any
			.then(function (response) {
				//console.log(testInputCustomerUser);
				//            console.log(user);
				//            console.log(response);
				//            console.log('/statistics/customermonthlyreport?month=052021&userFilterKeyString='+user+'&organizationKeyString='+orgKeyStringForAPIrequest+'&languageFilterKeyString='+language);
				//console.log(JSON.stringify(response));
				setStatisticsItem(response);
				if (inputOrganization && inputOrganization.length > 0) {
					//console.log(JSON.parse(JSON.stringify(inputOrganization[0])).value); //works
				}
				//            setLanguageMenu(languagesAsIOption);

				//            console.log(JSON.parse(JSON.stringify(response.cumulatedPreviousMonth)).value);
				//            console.log(JSON.parse(JSON.stringify(response?.UniqueLanguagesMapping![0])).languageName) //works
			});
	}, [inputOrganization, inputUser, inputLanguage]);

	//  let g=null;
	//  if (languagesAsIOption[0]) {
	//    g=languagesAsIOption[0].label;
	//  }

	//const renderSelectUser = (usersAsIOptionable?: IOptionable[]) => {
	//  if(usersAsIOptionable===undefined || !usersAsIOptionable) return null;
	//  if(usersAsIOptionable!=null) return usersAsIOptionable.map(e => (e.name));
	//  createOptions(usersAsIOptionable as IOptionable[]).join(', ');
	//  if (usersAsIOption!=null) console.log(" usersAsIOption: "+ usersAsIOption[0].value);
	//  }

	//usersAsIOption.map(e => e.label);

	/*
//const createUserInput = (usersAsIOption: IOption[]) => {
  if (usersAsIOption!=null && usersAsIOption!==undefined) {
    return (
      <InputGroup>
          {createInputAdmin(EInputs.selectOrganizationUsers, {
            options: usersAsIOption,
           })}
        </InputGroup>
    );
  }
 };
 */

	//-----------------------------------------------------
	//---RENDERING
	//-----------------------------------------------------
	return (
		<Container>
			<Heading>
				{t(ETranslation.MENU_CUSTOMER_REPORT)} ({statisticsItem?.previousMonthStringWithYear!})
			</Heading>
			<br></br>
			<br></br>
			{/*
      {cumulatedPreviousMonthList[30].y}
      <br></br>
      {cumulatedMonthBeforePreviousMonthList[30].y}
      */}
			<br></br>
			<br></br>
			{createInputAdmin(EInputs.organizations, { fetchOptions: searchOrganizations })}
			<br></br>
			{/* createInput(EInputs.testInput) */}
			{/* creape input as for organization and pack the results into a list of users - should it use the state?*/}
			{/*createInput(EInputs.testInputCustomerUser)*/}
			{/*
      <form>
        <div>
          <select onChange={changeUserOptionHandler}>
          </select>
         </div>
      </form>
      */}
			{/*
      <form>
        <div>
          Valitse tilaaja (OLD)
          <br></br>
          <select onChange={function(e){setSelectedUser(e.target.value)}}>{userOptions}</select>
        </div>
      </form>

      <br></br>
      <br></br>

      <form>
        <div>
          Valitse kieli (OLD)
          <br></br>
          <select onChange={function(e){setSelectedLanguage(e.target.value)}}>{languageOptions}</select>
        </div>
      </form>
      */}
			{/* old, kept for reference (works)
      <form>
        <div>
          Valitse tilaaja:
          <br></br>
          <select onChange={function(e){setSelectedUser(e.target.value)}}>{usersAsIOption.map(e => (
            <option key={e.value} value={e.value}>
              {`${e.label}`}
            </option>
          ))}</select>
        </div>
      </form>
      <br></br>
      <form>
        <div>
          Valitse kieli:
          <br></br>
          <select onChange={function(e){setSelectedLanguage(e.target.value)}}>{languagesAsIOption.map(e => (
            <option key={e.value} value={e.value}>
              {`${e.label}`}
            </option>
          ))}</select>
        </div>
      </form>
      */}
			{createInputCustomer(EInputs.usersAsIOption, {
				options: usersAsIOption, //createOptions(usersAsIOptionable as IOptionable[]),
			})}
			{createInputCustomer(EInputs.languageOptions, {
				options: languagesAsIOption,
			})}
			<br></br>
			{/*
      {createInput(EInputs.languageOptions)}
      */}
			<hr />
			<br></br>
			TUOTEJAKAUMA:
			<br></br>
			<Table hover={true}>
				<thead>
					<tr>
						<th>
							<StatisticsPriceBox
								price={statisticsItem?.priceWithoutVATTotalPreviousMonth!}
								percent={statisticsItem?.PercpricePercentageWithoutVATTotal!}
								style={{ marginRight: "1rem" }}
								labelInfo="(vertaus edel. kk)"
								unit="euros"
								isPercent="yes"
							>
								Kustannukset
							</StatisticsPriceBox>
						</th>
						<th>
							<StatisticsPriceBox
								price={statisticsItem?.cancelledExpensesPreviousMonth!}
								percent={statisticsItem?.DiffCancelledExpensesMonthBeforePreviousMonth!}
								boxColor={EStatisticsColor.LIGHTRED}
								style={{ marginRight: "1rem" }}
								labelInfo="(vertaus edel. kk)"
								unit="euros"
								isPercent="yes"
							>
								Peruutukset
							</StatisticsPriceBox>
						</th>
					</tr>
					<tr>
						<th>
							<StatisticsPriceBox
								price={statisticsItem?.sumPriceWithoutVATInstantPeriodPreviousMonth!}
								percent={statisticsItem?.PercsumPriceWithoutVATInstantPeriodMonthBeforePreviousMonth!}
								boxColor={EStatisticsColor.SUCCESS}
								labelColor={EStatisticsColor.SUCCESS}
								unit="euros"
								isPercent="yes"
							>
								Pikatulkkaukset
							</StatisticsPriceBox>
						</th>
						<th>
							<StatisticsPriceBox
								price={statisticsItem?.sumPriceWithoutVATBookingPeriodPreviousMonth!}
								percent={statisticsItem?.PercsumPriceWithoutVATBookingPeriodMonthBeforePreviousMonth!}
								boxColor={EStatisticsColor.SUCCESSDARK}
								labelColor={EStatisticsColor.SUCCESSDARK}
								unit="euros"
								isPercent="yes"
							>
								Ajanvaraukset
							</StatisticsPriceBox>
						</th>
					</tr>
					<tr>
						<th>
							<StatisticsPriceBox
								price={statisticsItem?.avgLengthINSTANTPreviousMonth!}
								percent={statisticsItem?.DiffavgLengthINSTANTMonthBeforePreviousMonth!}
								boxColor={EStatisticsColor.SUCCESS}
								labelColor={EStatisticsColor.SUCCESS}
								unit="minutes"
							>
								Tulkkausten keskiarvo
							</StatisticsPriceBox>
						</th>
						<th>
							<StatisticsPriceBox
								price={statisticsItem?.avgLengthBOOKINGPreviousMonth!}
								percent={statisticsItem?.DiffavgLengthBOOKINGMonthBeforePreviousMonth!}
								boxColor={EStatisticsColor.SUCCESSDARK}
								labelColor={EStatisticsColor.SUCCESSDARK}
								unit="minutes"
							>
								Tulkkausten keskiarvo
							</StatisticsPriceBox>
						</th>
					</tr>
					<tr>
						<th>
							<StatisticsPriceBox
								price={statisticsItem?.overtimeCountINSTANTPreviousMonth!}
								percent={statisticsItem?.DiffovertimeCountINSTANT!}
								boxColor={EStatisticsColor.SUCCESS}
								labelColor={EStatisticsColor.SUCCESS}
								unit="count"
							>
								Suunnittelun ajan yli
							</StatisticsPriceBox>
						</th>
						<th>
							<StatisticsPriceBox
								price={statisticsItem?.overtimeCountBOOKINGPreviousMonth!}
								percent={statisticsItem?.DiffovertimeCountBOOKING!}
								boxColor={EStatisticsColor.SUCCESSDARK}
								labelColor={EStatisticsColor.SUCCESSDARK}
								unit="count"
							>
								Varatun ajan yli
							</StatisticsPriceBox>
						</th>
					</tr>
					<tr></tr>
					<tr></tr>
					<tr></tr>
				</thead>
			</Table>
			<hr />
			<br></br>
			KUMULATIIVISET KUSTANNUKSET:
			<br></br>
			<VictoryChart
				height={300}
				width={300}
				padding={{ left: 50, right: 50, bottom: 50, top: 50 }}
				containerComponent={<VictoryVoronoiContainer />}
				style={{ background: { fill: "#E9E9E9" } }}
			>
				{/* https://formidable.com/open-source/victory/docs/victory-axis/*/}
				<VictoryAxis
					label="Kuukauden pvm"
					style={{ axisLabel: { fontSize: 12, padding: 20 }, tickLabels: { fontSize: 12, padding: 5 } }}
				/>
				<VictoryAxis
					dependentAxis
					tickFormat={(y) => `${Math.round(y)}`}
					label="Kumulatiiviset kustannukset (€)"
					style={{
						axisLabel: { fontSize: 12, padding: 30 },
						tickLabels: { fontSize: 12, padding: 5 },
						grid: { stroke: "#818e99", strokeWidth: 0.5 },
					}}
				/>
				<VictoryLegend
					x={50}
					y={0}
					title=""
					centerTitle
					orientation="vertical"
					gutter={20}
					style={{ title: { fontSize: 12 }, labels: { fontSize: 12 } }}
					data={[
						{ name: "Kumulatiiviset kustannukset (viime  kk)", symbol: { fill: "#004D9E" } },
						{ name: "Vertaus edellinen kk", symbol: { fill: "#C3C3C3" } },
					]}
				/>
				<VictoryGroup
					color="#c43a31"
					labels={({ datum }) => `${datum.y}`}
					labelComponent={<VictoryTooltip style={{ fontSize: 4 }} />}
					data={cumulatedPreviousMonthList}
				>
					<VictoryLine style={{ data: { stroke: "#004D9E", strokeWidth: 1 } }} />
					<VictoryScatter size={({ active }) => (active ? 5 : 3)} style={{ data: { fill: "#004D9E" } }} />
				</VictoryGroup>

				<VictoryGroup
					//labels={({ datum }) => `${datum.y}`}
					labelComponent={<VictoryTooltip style={{ fontSize: 4 }} />}
					data={cumulatedMonthBeforePreviousMonthList}
				>
					<VictoryLine style={{ data: { stroke: "#C3C3C3", strokeWidth: 1 } }} />
					<VictoryScatter size={({ active }) => (active ? 3 : 2)} style={{ data: { fill: "#C3C3C3" } }} />
				</VictoryGroup>
			</VictoryChart>
			<hr />
			<br></br>
			TILAAJAJAKAUMA:
			<br></br>
			{/*https://formidable.com/open-source/victory/docs/common-props/#domainpadding*/}
			<VictoryChart
				horizontal
				height={300}
				width={300}
				padding={{ left: 100, right: 50, bottom: 50, top: 30 }}
				domainPadding={{ x: 10 }}
				style={{ background: { fill: "#E9E9E9" } }}
			>
				<VictoryAxis
					style={{ axisLabel: { fontSize: 12, padding: 50 }, tickLabels: { fontSize: 12, padding: 5 } }}
				/>
				<VictoryAxis
					dependentAxis
					tickFormat={(y) => `${Math.round(y)}`}
					label="Kustannukset (€)"
					style={{
						axisLabel: { fontSize: 12, padding: 20 },
						tickLabels: { fontSize: 12, padding: 5 },
						grid: { stroke: "#818e99", strokeWidth: 0.5 },
					}}
				/>
				<VictoryBar
					style={{
						data: { fill: "#004D9E" },
					}}
					data={topUsersAsIOption}
					labels={({ datum }) => datum.y}
					width={200}
					height={200}
					sortKey="y"
					sortOrder="ascending"
				/>
				{/*}
        <VictoryScatter
         data={[
          { x: "test2", y: 78 },
        ]}
        />
      */}
			</VictoryChart>
		</Container>
	);
};

export default CustomerReportsPage;
