import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import IAppState from "../interfaces/store/IAppState";
import * as actions from "../store/actions";
import { EUserStatus, IUser } from "../classes/User";

export interface IPaginationOptions {
	user?: string;
	organization?: string[] | null | string;
	fetchSize?: number;
	fetchDeleted?: boolean;
	paginationCursor?: string;
	type: string;
	start: number;
	status?: EUserStatus[];
}

export const PAGINATION_FETCH_SIZE = 50;

export const UserPagination = function <T>() {
	const dispatch = useDispatch();
	const loaderRef = useRef<HTMLParagraphElement>(null);

	const [searchOptions, setSearchOptions] = useState<IPaginationOptions | null>(null);
	const [users, setUsers] = useState<IUser[] | null>(null);

	const [loadingCheck, setLoadingCheck] = useState(false);

	const {
		users: stateUsers,
		paginationCursor,
		hasMoreUsers,
		loading,
	} = useSelector((state: IAppState) => state.user);

	useEffect(() => {
		setUsers(stateUsers);
		setLoadingCheck(false);
		return ()=>{
			dispatch(actions.getUsersClear());
			setUsers(null);
		}
	}, [stateUsers,dispatch]);

	const searchHandler = useCallback((search: IPaginationOptions) => {
		dispatch(actions.searchUsers(search));
		setSearchOptions(search);
	}, [dispatch]);

	const handleObserver = useCallback((entries) => {
		if (!hasMoreUsers || !searchOptions || loading || loadingCheck || !stateUsers || stateUsers?.length === 0) return;
		setLoadingCheck(true);
		const target = entries[0];
		if (target.isIntersecting) {
			searchHandler({
				...searchOptions,
				start: stateUsers.length + 1,
				paginationCursor: paginationCursor ?? "",
			});
		} else {
			setLoadingCheck(false);
		}
	}, [loading, stateUsers, searchHandler, searchOptions, hasMoreUsers, paginationCursor, loadingCheck]);

	useEffect(() => {
		const observer = new IntersectionObserver(handleObserver, {
			root: null,
			rootMargin: "",
			threshold: 1,
		});
		if (loaderRef.current) observer.observe(loaderRef.current);
		return () => observer.disconnect();
	}, [handleObserver]);

	return { loaderRef, users, loading, searchHandler, hasMoreUsers };
};
