import EActionTypes from "../../interfaces/store/EActionTypes";
import ITaskState from "../../interfaces/store/ITaskState";
import ITaskAction from "../../interfaces/store/ITaskAction";


type ReducerState = ITaskState;
type ReducerSignature = (state: ReducerState, action: ITaskAction) => ReducerState;
const initialState: ReducerState = {
	task: null,
	taskItems: null,
	loading: false,
	error: null,
	tasks: null,
	tasksLoading: false,
	saveOk: false,
	hasNext: false,
};

const getTaskStart: ReducerSignature = (state): ReducerState => {
	return { ...state, error: null, loading: true, task: null };
};

const getTaskSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		task: action.task || null,
		error: null,
		loading: false,
	};
};

const getTaskFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, task: null, loading: false, error: action.error ?? null };
};

const getTaskClear: ReducerSignature = (state): ReducerState => {
	return { ...state, task: null, loading: false, error: null };
}

const getTasksStart: ReducerSignature = (state): ReducerState => {
	return { ...state, error: null, loading: true, tasks: null };
};

const getTasksSuccess: ReducerSignature = (state, action): ReducerState => {
	const tasks = state.tasks ? action.tasks ? [...state.tasks, ...action.tasks] : [...state.tasks] : action.tasks;
	return {
		...state, 
		tasks: tasks || null,
		error: null,
		loading: false,
		hasNext: action.hasNext ?? false,
	};
};

const getTasksFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, tasks: null, loading: false, error: action.error ?? null, hasNext: false };
};

const getTasksClear: ReducerSignature = (state): ReducerState => {
	return { ...state, tasks: null, loading: false, error: null, hasNext: false };
}

const saveTaskStart: ReducerSignature = (state): ReducerState => {
	return { ...state, error: null, loading: true };
};

const saveTaskSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		tasks: null,
		error: null,
		loading: false,
		saveOk: true,
	};
};

const saveTaskError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, loading: false, error: action.error ?? null };
}

const saveTaskClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, loading: false, error: null, saveOk: false }
}

const reducer = (state: ReducerState = initialState, action: ITaskAction): ReducerState => {
	switch (action.type) {
		case EActionTypes.TASKS_GET_START:
			return getTasksStart(state, action);
		case EActionTypes.TASKS_GET_SUCCESS:
			return getTasksSuccess(state, action);
		case EActionTypes.TASKS_GET_ERROR:
			return getTasksFail(state, action);
		case EActionTypes.TASKS_GET_CLEAR:
			return getTasksClear(state, action);
		case EActionTypes.TASK_GET_START:
			return getTaskStart(state, action);
		case EActionTypes.TASK_GET_SUCCESS:
			return getTaskSuccess(state, action);
		case EActionTypes.TASK_GET_ERROR:
			return getTaskFail(state, action);
		case EActionTypes.TASK_GET_CLEAR:
			return getTaskClear(state, action);
		case EActionTypes.TASK_SAVE_START:
			return saveTaskStart(state, action);
		case EActionTypes.TASK_SAVE_SUCCESS:
			return saveTaskSuccess(state, action);
		case EActionTypes.TASK_SAVE_ERROR:
			return saveTaskError(state, action);
		case EActionTypes.TASK_SAVE_CLEAR:
			return saveTaskClear(state, action);
		default:
			return state;
	}
};

export default reducer;
