import React from "react";
import { useTranslation } from "react-i18next";
import { EInterpretationStatus, getInterpretationStatusString } from "../../../shared/interpretation-data";
import { ETranslation } from "../../../translations/translation-keys";
import Badge, { EBadgeType } from "../../ui/Badge/Badge";
import InputGroup from "../../ui/InputGroup/InputGroup";

interface IProps {
	status: EInterpretationStatus;
}

const InterpretationStatus: React.FC<IProps> = ({ status }) => {
	const { t } = useTranslation();
	let text = getInterpretationStatusString(t, status);
	let badgeType = EBadgeType.SUCCESS;
	let underLineText = null;
	switch (status) {
		case EInterpretationStatus.CONFIRMED_BOOKING:
			badgeType = EBadgeType.PRIMARY;
			break;

		case EInterpretationStatus.DRAFT:
		case EInterpretationStatus.DUPLICATE:
		case EInterpretationStatus.PRELIMINARY_BOOKING:
		case EInterpretationStatus.CUSTOMER_CANCEL:
		case EInterpretationStatus.INTERPRETER_CANCEL:
		case EInterpretationStatus.INTERPRETERS_NOT_FOUND:
    case EInterpretationStatus.FAVORITES_NOT_FOUND:
			badgeType = EBadgeType.DANGER;
			break;
		case EInterpretationStatus.WAITING:
		case EInterpretationStatus.IN_PROGRESS:
		case EInterpretationStatus.WAITING_CUSTOMER_APPROVAL:
			badgeType = EBadgeType.WARNING;
			break;
		case EInterpretationStatus.COMPLETE:
			badgeType = EBadgeType.SUCCESS;
			break;
		case EInterpretationStatus.OFFER_BOOKING:
			text = t(ETranslation.INTERPRETATION_DATA_STATUS_OFFER_BOOKING);
			underLineText = ETranslation.INTERPRETATION_DATA_STATUS_OFFER_BOOKING_WAITING;
			badgeType = EBadgeType.DANGER;
			break;
		case EInterpretationStatus.WAITING_INTERPRETER_REVIEW:
			text = t(ETranslation.INTERPRETATION_DATA_STATUS_WAITING);
			underLineText = ETranslation.INTERPRETATION_DATA_STATUS_WAITING_INTERPRETER;
			badgeType = EBadgeType.WARNING;
			break;
		case EInterpretationStatus.WAITING_CUSTOMER_REVIEW:
			text = t(ETranslation.INTERPRETATION_DATA_STATUS_WAITING);
			underLineText = ETranslation.INTERPRETATION_DATA_STATUS_WAITING_CUSTOMER;
			badgeType = EBadgeType.WARNING;
			break;
		case EInterpretationStatus.WAITING_REVIEWS:
			text = t(ETranslation.INTERPRETATION_DATA_STATUS_WAITING);
			underLineText = ETranslation.INTERPRETATION_DATA_STATUS_WAITING_REVIEW_MULTIPLE;
			badgeType = EBadgeType.WARNING;
			break;
	}

	return (
		<>
			<InputGroup style={{ margin: "0px" }}>
				<Badge type={badgeType}>{text}</Badge>
			</InputGroup>
			{underLineText && (
				<InputGroup style={{ margin: "0px" }}>
					<p style={{fontSize:"1.1em", padding: "0em", margin: "0em"}}>{t(underLineText)}</p>
				</InputGroup>
			)}
		</>
	);
};

export default InterpretationStatus;
