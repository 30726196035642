import React, { ReactNode, useCallback, useContext } from 'react';

import ModalContext, { EModalSize } from '../ui/Modal/ModalContext';
import NotificationModal, { INotificationModalResult } from './NotificationModal';




export const useNotificationModal = () => {
  const { setModal, closeModal } = useContext(ModalContext);
  
  const handler = useCallback(async (text: ReactNode) => {
    return new Promise<INotificationModalResult>((resolve) => {
      setModal({
        isOpen: true,
        size: EModalSize.MEDIUM,
        title: "Lähetä ilmoitukset",
        content: <NotificationModal text={text} onAccept={(result) => {
          resolve(result);
          closeModal();
        }} />,
      });
    })
  }, [setModal, closeModal]);

  return handler;
};
