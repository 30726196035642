import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useConfirmModal } from '../../hooks/useConfirmModal';
import { ITranslation } from '../../interfaces/ITranslation';
import { ETranslation } from '../../translations/translation-keys';
import Heading from '../Heading/Heading';
import Alert from '../ui/Alert/Alert';
import Button, { EButtonColor, EButtonSize } from '../ui/Button/Button';
import TranslationsTable from './TranslationsTable';
import { useTranslationManageModal } from './useTranlationsManageModal';
import { useTranslationEditModal } from './useTranslationEditModal';

interface IProps {
	error: string | null;
	translations: ITranslation[];
	isLoading: boolean;
	onDelete: (id: string, langCode: string) => void;
	onSave: (translation: ITranslation) => void;
}

const Translations: React.FC<IProps> = ({ error, translations, onDelete, onSave, isLoading }) => {
	const openConfirmModal = useConfirmModal();
	const openTranslationEditModal = useTranslationEditModal();
	const openTranslationsManageModal = useTranslationManageModal(translations);

	const { t } = useTranslation();

	const deleteHandler = useCallback(
		async (id: string, langCode: string, onClose?: () => void) => {
			const isConfirm = await openConfirmModal(t(ETranslation.LANGUAGE_TRANSLATION_CONFIRM_DELETE));
			if (isConfirm) onDelete(id, langCode);
			if (onClose) onClose();
		},
		[openConfirmModal, t, onDelete]
	);

	const editHandler = useCallback(
		(translation: ITranslation, onClose?: () => void) => {
			openTranslationEditModal(translation, onSave, onClose);
		},
		[onSave, openTranslationEditModal]
	);

	const manageHandler = useCallback(() => {
		openTranslationsManageModal(
			onSave,
			(translation) => editHandler(translation, manageHandler),
			(id, langCode) => deleteHandler(id, langCode, manageHandler)
		);
	}, [openTranslationsManageModal, onSave, deleteHandler, editHandler]);

	return (
		<div style={{ marginTop: '1rem' }}>
			<Heading tag="h2">
				<>
					{t(ETranslation.LANGUAGE_TRANSLATIONS)}
					<Button
						onClick={manageHandler}
						loading={isLoading}
						color={EButtonColor.PRIMARY}
						size={EButtonSize.SMALL}
						style={{ fontSize: "initial", marginLeft: ".5rem" }}
					>
						{t(ETranslation.LANGUAGE_EDIT_TRANSLATIONS)}
					</Button>
				</>
			</Heading>
			{error && <Alert>{error}</Alert>}
			<TranslationsTable translations={translations} onDelete={deleteHandler} onEdit={editHandler} />
		</div>
	);
};

export default Translations;
