import React from "react";
import { ISummary } from "../../../store/actions/summariesActions";
import SummaryFooter from "./SummaryFooter";
import SummaryHeader from "./SummaryHeader";
import SummaryPaymentInfo from "./SummaryPaymentInfo";
import SummaryPaymentRows from "./SummaryPaymentRows";


interface IProps {
	summary: ISummary;
}

const SummaryInformation: React.FC<IProps> = ({ summary }) => {
	return (
		<>
			<SummaryHeader summary={summary} />
			<SummaryPaymentRows summary={summary} />
			<SummaryPaymentInfo summary={summary} />
			<SummaryFooter summary={summary} />
		</>
	);
};

export default SummaryInformation;
