import { faCircle, faSpinner, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { IChatParticipant } from '../../../hooks/useTwilioChat';
import classes from './TwilioChatHeader.module.scss';

interface IProps {
  isConnected: boolean;
  isConnecting: boolean;
  otherParticipants: IChatParticipant[] | null;
  participantsLoading: boolean;
}

const TwilioChatHeader: React.FC<IProps> = ({
  isConnecting,
  isConnected,
  participantsLoading,
  otherParticipants,
}) => {
  return (
    <header className={classes.Container}>
      <Participants
        otherParticipants={otherParticipants}
        participantsLoading={participantsLoading}
      />
      <Status isConnected={isConnected} isConnecting={isConnecting} />
    </header>
  );
};

interface IParticipantsProps {
  otherParticipants: IChatParticipant[] | null;
  participantsLoading: boolean;
}

const Participants: React.FC<IParticipantsProps> = ({
  otherParticipants,
  participantsLoading,
}) => {
  if (participantsLoading || !otherParticipants) {
    return <FontAwesomeIcon icon={faSpinner} spin />;
  }
  return (
    <>{otherParticipants.map((participant) => participant.name).join(", ")}</>
  );
};

interface IStatusProps {
  isConnecting: boolean;
  isConnected: boolean;
}

const Status: React.FC<IStatusProps> = ({ isConnecting, isConnected }) => {
  let spin = false;
  let icon: IconDefinition = faCircle;
  let className = "";
  let text = "";
  if (isConnecting) {
    spin = true;
    icon = faSpinner;
    text = "Yhdistetään";
  } else if (isConnected) {
    className = classes.Connected;
    text = "Yhdistetty";
  } else {
    className = classes.Disconnected;
    text = "Ei yhteyttä";
  }

  return (
    <>
      <span className={classes.Icon}>
        <FontAwesomeIcon icon={icon} className={className} spin={spin} />
        <span className={classes.Text}>{text}</span>
      </span>
    </>
  );
};

export default TwilioChatHeader;
