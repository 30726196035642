import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ISummary } from "../../store/actions/summariesActions";
import { IInputField, EInputType } from "../../components/ui/Input/Input";
import { getInputData, validateInputs } from "../../components/ui/Input/input-utils";
import { useCreateInput } from "../../hooks/useCreateInput";
import Button from "../../components/ui/Button/Button";
import { ETranslation } from "../../translations/translation-keys";
import * as dateFns from "date-fns";

interface IProps {
	summary: ISummary;
	onAccept: (paymentDate: string, message: string) => void;
}

export enum EInputs {
	message = "message",
	paymentDate = "paymentDate",
}

export interface ISummaryEdit {
	paymentDate: string;
	message: string;
}

const SummaryModal: React.FC<IProps> = ({ summary, onAccept }) => {
	const { t } = useTranslation();

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.paymentDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.SUMMARY_PAYMENT_DATE,
			dateAsString: true,
			value: dateFns.format(dateFns.parse(summary.paymentDate, "dd.MM.yyyy", new Date()), "yyyy-MM-dd")
		},
		[EInputs.message]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_MESSAGE,
			value: summary.message,
		},
	});

	const createInput = useCreateInput(inputs, setInputs, { showValidation: true });
	const [isValid, setIsValid] = useState(false);

	useEffect(()=>{
		setIsValid(validateInputs(inputs));
	}, [inputs])

	const onFormAccept = () => {
		const data = getInputData<ISummaryEdit>(inputs, false);
		onAccept(dateFns.format(dateFns.parse(data.paymentDate, "yyyy-MM-dd", new Date()), "dd.MM.yyyy"), data.message);
	}

	return (
		<>
			{createInput(EInputs.paymentDate)}
			{createInput(EInputs.message)}
			<Button onClick={onFormAccept} disabled={!isValid}>
				{t(ETranslation.COMMON_SAVE)}
			</Button>
		</>
	);
};

export default SummaryModal;
