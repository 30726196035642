import { useCallback, useState } from "react"
import { ITranslation } from "../../interfaces/ITranslation"


export const useTranslations = () => {
  const [translations, setTranslations] = useState<ITranslation[]>([]);


  const initTranslations = useCallback((translations: ITranslation[]) => {
    setTranslations(translations ?? []);
  }, []);

  const deleteHandler = useCallback((langCode: string) => {
    setTranslations(translations => translations.filter(item => item.languageCode !== langCode));
  }, []);
;
  const saveHandler = useCallback((translation: ITranslation) => {
    setTranslations((translations) => {
      const newTranslations = [...translations];
      const index = newTranslations.findIndex(item => item.languageCode === translation.languageCode);
      if (index !== -1) {
        newTranslations[index] = translation;
      } else {
        newTranslations.push(translation);
      }
      return newTranslations;
    });
  }, [])


  return { translations, initTranslations, onDeleteTranslation: deleteHandler, onSaveTranslation: saveHandler };
}