import { Dispatch } from 'redux';

import { customFetch, EFetchMethod } from '../../custom-fetch';
import { IAttribute } from '../../interfaces/IAttribute';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IAttributeAction from '../../interfaces/store/IAttributeAction';

type TAction = IAttributeAction;

const findAttributesStart = (): TAction => {
  return {
    type: EActionTypes.ATTRIBUTE_FIND_SUCCESS,
  };
};

const findAttributesSuccess = (attributes: IAttribute[]): TAction => {
  return {
    type: EActionTypes.ATTRIBUTE_FIND_SUCCESS,
    attributes,
  };
};

const findAttributesFail = (error: string): TAction => {
  return {
    type: EActionTypes.ATTRIBUTE_FIND_FAIL,
    error,
  };
};

export const findAttributes = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(findAttributesStart());
      const attributes = await customFetch<IAttribute[]>("/attributes/list");
      dispatch(findAttributesSuccess(attributes));
    } catch (error) {
      dispatch(findAttributesFail((error as Error).message));
    }
  };
};

const getAttributeStart = (): TAction => {
  return {
    type: EActionTypes.ATTRIBUTE_GET_START,
  };
};

const getAttributeSuccess = (attribute: IAttribute): TAction => {
  return {
    type: EActionTypes.ATTRIBUTE_GET_SUCCESS,
    attribute,
  };
};

const getAttributeFail = (error: string): TAction => {
  return {
    type: EActionTypes.ATTRIBUTE_GET_FAIL,
    error,
  };
};

export const getAttributeClear = (): TAction => {
  return {
    type: EActionTypes.ATTRIBUTE_GET_CLEAR,
  };
};

export const getAttribute = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getAttributeStart());
      const attribute = await customFetch<IAttribute>(`/attributes/get?id=${id}`);
      dispatch(getAttributeSuccess(attribute));
    } catch (error) {
      dispatch(getAttributeFail((error as Error).message));
    }
  };
};

const deleteAttributeStart = (): TAction => {
  return {
    type: EActionTypes.ATTRIBUTE_DELETE_START,
  };
};

const deleteAttributeSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.ATTRIBUTE_DELETE_SUCCESS,
    id,
  };
};

const deleteAttributeFail = (error: string): TAction => {
  return {
    type: EActionTypes.ATTRIBUTE_DELETE_FAIL,
    error,
  };
};

export const deleteAttributeClear = (): TAction => {
  return {
    type: EActionTypes.ATTRIBUTE_DELETE_CLEAR,
  };
};

export const deleteAttribute = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deleteAttributeStart());
      await customFetch<boolean>(`/attributes/delete?id=${id}`, EFetchMethod.DELETE);
      dispatch(deleteAttributeSuccess(id));
    } catch (error) {
      dispatch(deleteAttributeFail((error as Error).message));
    }
  };
};

const saveAttributeStart = (): TAction => {
  return {
    type: EActionTypes.ATTRIBUTE_SAVE_START,
  };
};

const saveAttributeSuccess = (attribute: IAttribute): TAction => {
  return {
    type: EActionTypes.ATTRIBUTE_SAVE_SUCCESS,
    attribute
  };
};

const saveAttributeFail = (error: string): TAction => {
  return {
    type: EActionTypes.ATTRIBUTE_SAVE_FAIL,
    error,
  };
};



export const saveAttribute = (attribute: IAttribute) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveAttributeStart());
      attribute = await customFetch<IAttribute>("/attributes/add", EFetchMethod.POST, JSON.stringify(attribute));
      dispatch(saveAttributeSuccess(attribute));
    } catch (error) {
      dispatch(saveAttributeFail((error as Error).message));
    }
  };
};

const updateAttributeStart = (): TAction => {
  return {
    type: EActionTypes.ATTRIBUTE_UPDATE_START,
  };
};

const updateAttributeSuccess = (attribute: IAttribute): TAction => {
  return {
    type: EActionTypes.ATTRIBUTE_UPDATE_SUCCESS,
    attribute
  };
};

const updateAttributeFail = (error: string): TAction => {
  return {
    type: EActionTypes.ATTRIBUTE_UPDATE_FAIL,
    error,
  };
};

export const updateAttribute = (attribute: IAttribute) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateAttributeStart());
      attribute = await customFetch<IAttribute>("/attributes/update", EFetchMethod.PUT, JSON.stringify(attribute));
      dispatch(updateAttributeSuccess(attribute));
    } catch (error) {
      dispatch(updateAttributeFail((error as Error).message));
    }
  };
};

export const saveOrUpdateAttributeClear = (): TAction => {
  return {
    type: EActionTypes.ATTRIBUTE_SAVE_OR_UPDATE_CLEAR
  }
}

