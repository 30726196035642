import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import Button, { EButtonColor } from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

import classes from './DropdownButton.module.scss';


export interface IDropdownButtonOption {
  text: string;
  onClick: VoidFunction;
}

interface IProps {
  children: ReactNode;
  options: IDropdownButtonOption[];
  loading: boolean;
}

const DropdownButton: React.FC<IProps> = ({ children, options, loading }) => {
  const dropdownButtonRef = useRef<HTMLButtonElement | null>(null);
	const [showOptions, setShowOptions] = useState(false);


  const handleOutsideClick = useCallback((event: MouseEvent) => {
    if (dropdownButtonRef.current && !dropdownButtonRef.current.contains(event.target as Node)) {
      setShowOptions(false);
    }
  }, [dropdownButtonRef]);


  useEffect(() => {

    if (showOptions) {
      window.addEventListener('click', handleOutsideClick);
    } else {
      window.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    }

  }, [showOptions, handleOutsideClick]);

	return (
		<div className={[classes.Dropdown, showOptions ? classes.Open : ''].join(' ')}>
			<Button
				onClick={() => setShowOptions((prevVal) => !prevVal)}
				color={EButtonColor.DEFAULT}
        buttonRef={dropdownButtonRef}
        loading={loading}
			>
				{children}{" "}
				<FontAwesomeIcon
					style={{ marginLeft: ".5rem" }}
					icon={faCaretDown}
				/>
			</Button>
			{showOptions && (
				<ul className={classes.DropdownMenu}>
          {options.map((option, i) => <li key={`${option.text}-${i}`} onClick={option.onClick}>{option.text}</li>)}
				</ul>
			)}
		</div>
	);
};

export default DropdownButton;
