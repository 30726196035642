export enum EReservedDateOption {
	MONDAY = "MONDAY",
	TUESDAY = "TUESDAY",
	WEDNESDAY = "WEDNESDAY",
	THURSDAY = "THURSDAY",
	FRIDAY = "FRIDAY",
	SATURDAY = "SATURDAY",
	SUNDAY = "SUNDAY",
	NATIONAL_HOLIDAY = "NATIONAL_HOLIDAY",
}

export enum EReservedDateType {
	TIMERANGE = "TIMERANGE",
	DAILY = "DAILY",
}

export interface IReservedDate {
	id: string;
	type: EReservedDateType;
	description: string;
	startDate: string;
	endDate: string;
	startTime: string;
	endTime: string;
	userName: string;
	options: EReservedDateOption[];
}
