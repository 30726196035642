import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, { MultiValue, SingleValue } from 'react-select';
import { IOption } from '../Input/Input';
import { isStringArray } from '../Input/input-utils';
import classes from '../Input/Input.module.scss';
type TValue = IOption | IOption[] | "";
interface IProps {
  className: string,
  options?: IOption[];
  name: string;
  value: TValue;
  placeholder?: string;
  multiple?: boolean;
  loading?: boolean;
  disabled?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  onChange: (value: string | string[]) => void;
}
  
const Select: React.FC<IProps> = ({ className, options, name,  placeholder, multiple, loading, disabled, value, isSearchable, isClearable, onChange }) => {
  const [selectValue, setSelectValue] = useState<TValue>();
  const [selectOptions, setSelectOptions] = useState<IOption[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    let selectValue = value;
    if (isStringArray(value)) {
      if(selectOptions && selectOptions.length > 0) {
        selectValue = value.map((item) => {
          return selectOptions.find(option => option.value === item) as IOption;
        });
      } else {
        selectValue = value.map((item) => {
          return {value: item};
        })
      }
    } else if(typeof value === 'string' && value.length > 0 && selectOptions) {
      selectValue = selectOptions.find(option => option.value === value) as IOption;
    }
    setSelectValue(selectValue);
  }, [value, selectOptions]);

  // useEffect(() => {
  //   if (value && selectOptions) {
  //      setSelectValue(selectOptions.find(option => option.value === value));
  //   } else {
  //     setSelectValue(undefined);
  //   }

  // }, [value, selectOptions, multiple, t]);

  useEffect(() => {
    
    setSelectOptions(options ? options.map(o => ({ label: (o.labelTranslation ? t(o.labelTranslation) : o.label) || o.value, value: o.value })) : []);
  }, [options, t]);
  

  const changeHandler = (value: SingleValue<IOption> | MultiValue<IOption>) => {
    if (multiple) {
      onChange((value as MultiValue<IOption> | undefined)?.map(value => value.value) || '')
    } else {
      onChange((value as SingleValue<IOption> | undefined)?.value || '');
    }
  }

  return (
    <ReactSelect
	    className={classes.Select}
      name={name}
      onChange={(option) => changeHandler(option as IOption | IOption[] | null)}
      isLoading={loading}
      value={selectValue}
      placeholder={placeholder}
      options={selectOptions}
      isDisabled={disabled}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isMulti={multiple}
    />
  );
};

export default Select;