import { Dispatch } from 'redux';

import { customFetch, EFetchMethod } from '../../custom-fetch';
import { ICategory } from '../../interfaces/ICategory';
import EActionTypes from '../../interfaces/store/EActionTypes';
import ICategoryAction from '../../interfaces/store/ICategoryAction';

type TAction = ICategoryAction;

const findCategorysStart = (): TAction => {
  return {
    type: EActionTypes.CATEGORY_FIND_SUCCESS,
  };
};

const findCategorysSuccess = (categories: ICategory[]): TAction => {
  return {
    type: EActionTypes.CATEGORY_FIND_SUCCESS,
    categories,
  };
};

const findCategorysFail = (error: string): TAction => {
  return {
    type: EActionTypes.CATEGORY_FIND_FAIL,
    error,
  };
};

export const findCategorys = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(findCategorysStart());
      const categories = await customFetch<ICategory[]>("/categories/list");
      dispatch(findCategorysSuccess(categories));
    } catch (error) {
      dispatch(findCategorysFail((error as Error).message));
    }
  };
};

const getCategoryStart = (): TAction => {
  return {
    type: EActionTypes.CATEGORY_GET_START,
  };
};

const getCategorySuccess = (category: ICategory): TAction => {
  return {
    type: EActionTypes.CATEGORY_GET_SUCCESS,
    category,
  };
};

const getCategoryFail = (error: string): TAction => {
  return {
    type: EActionTypes.CATEGORY_GET_FAIL,
    error,
  };
};

export const getCategoryClear = (): TAction => {
  return {
    type: EActionTypes.CATEGORY_GET_CLEAR,
  };
};

export const getCategory = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getCategoryStart());
      const category = await customFetch<ICategory>(`/categories/get?id=${id}`);
      dispatch(getCategorySuccess(category));
    } catch (error) {
      dispatch(getCategoryFail((error as Error).message));
    }
  };
};

const deleteCategoryStart = (): TAction => {
  return {
    type: EActionTypes.CATEGORY_DELETE_START,
  };
};

const deleteCategorySuccess = (id: string): TAction => {
  return {
    type: EActionTypes.CATEGORY_DELETE_SUCCESS,
    id,
  };
};

const deleteCategoryFail = (error: string): TAction => {
  return {
    type: EActionTypes.CATEGORY_DELETE_FAIL,
    error,
  };
};

export const deleteCategoryClear = (): TAction => {
  return {
    type: EActionTypes.CATEGORY_DELETE_CLEAR,
  };
};

export const deleteCategory = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deleteCategoryStart());
      await customFetch<boolean>(`/categories/delete?id=${id}`, EFetchMethod.DELETE);
      dispatch(deleteCategorySuccess(id));
    } catch (error) {
      dispatch(deleteCategoryFail((error as Error).message));
    }
  };
};

const saveCategoryStart = (): TAction => {
  return {
    type: EActionTypes.CATEGORY_SAVE_START,
  };
};

const saveCategorySuccess = (category: ICategory): TAction => {
  return {
    type: EActionTypes.CATEGORY_SAVE_SUCCESS,
    category
  };
};

const saveCategoryFail = (error: string): TAction => {
  return {
    type: EActionTypes.CATEGORY_SAVE_FAIL,
    error,
  };
};



export const saveCategory = (category: ICategory) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveCategoryStart());
      category = await customFetch<ICategory>("/categories/add", EFetchMethod.POST, JSON.stringify(category));
      dispatch(saveCategorySuccess(category));
    } catch (error) {
      dispatch(saveCategoryFail((error as Error).message));
    }
  };
};

const updateCategoryStart = (): TAction => {
  return {
    type: EActionTypes.CATEGORY_UPDATE_START,
  };
};

const updateCategorySuccess = (category: ICategory): TAction => {
  return {
    type: EActionTypes.CATEGORY_UPDATE_SUCCESS,
    category
  };
};

const updateCategoryFail = (error: string): TAction => {
  return {
    type: EActionTypes.CATEGORY_UPDATE_FAIL,
    error,
  };
};

export const updateCategory = (category: ICategory) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateCategoryStart());
      category = await customFetch<ICategory>("/categories/update", EFetchMethod.PUT, JSON.stringify(category));
      dispatch(updateCategorySuccess(category));
    } catch (error) {
      dispatch(updateCategoryFail((error as Error).message));
    }
  };
};

export const saveOrUpdateCategoryClear = (): TAction => {
  return {
    type: EActionTypes.CATEGORY_SAVE_OR_UPDATE_CLEAR
  }
}

