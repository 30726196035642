import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EMessageGroupType, IMessageGroup, MessageGroup } from '../../../classes/MessageGroup';
import { useCreateInput } from '../../../hooks/useCreateInput';
import { searchOrganizations } from '../../../services/searchServices';
import { ETranslation } from '../../../translations/translation-keys';
import Heading from '../../Heading/Heading';
import Button, { EButtonSize } from '../../ui/Button/Button';
import { EInputType, IInputField } from '../../ui/Input/Input';
import { getInputData, initForm, validateInputs } from '../../ui/Input/input-utils';
import MessageList from '../MessageList/MessageList';

enum EInputs {
  type = 'type',
  name = "name",
  organizations = "organizations",
}

interface IProps {
  onChange: (messageGroup: IMessageGroup, isValid: boolean) => void;
  messageGroup: MessageGroup | null;
  loading?: boolean;
  showValidation?: boolean;
  onMessageAdd: () => void;
}

const MessageGroupEdit: React.FC<IProps> = ({ onChange, loading, messageGroup, showValidation, onMessageAdd }) => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.type]: {
      type: EInputType.radio,
      labelTranslation: ETranslation.COMMON_TYPE,
      value: '',
      options: [
        { labelTranslation: ETranslation.MESSAGE_MESSAGE_TRANSLATION, value: EMessageGroupType.MESSAGE_GROUP },
        { labelTranslation: ETranslation.MESSAGE_RECORDINGBANK, value: EMessageGroupType.BANK },
        { labelTranslation: ETranslation.COMMON_ATTACHMENT, value: EMessageGroupType.ATTACHMENT },
      ],
      validation: {
        required: true
      }
    },
    [EInputs.name]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_NAME,
      value: "",
      validation: {
        required: true
      }
    },
    [EInputs.organizations]: {
      type: EInputType.reactSelectSearch,
      labelTranslation: ETranslation.COMMON_ORGANIZATIONS,
      value: [],
      multiple: true,
    }
  });

  useEffect(() => {
    if (messageGroup) {
      initForm(setInputs, messageGroup);
    }
  }, [messageGroup]);

  useEffect(() => {
    const messageGroup = getInputData<IMessageGroup>(inputs);
    const isValid = validateInputs(inputs);
    onChange(messageGroup, isValid);
  }, [inputs, onChange]);

  const createInput = useCreateInput(inputs, setInputs, {
    showValidation,
    disabled: loading
  });

  const type = inputs[EInputs.type].value as EMessageGroupType;

  return (
    <>
      {createInput(EInputs.type)}
      {createInput(EInputs.name)}
      {type === EMessageGroupType.BANK && createInput(EInputs.organizations, { fetchOptions: searchOrganizations })}
      <Heading tag="h3">{t(ETranslation.MESSAGE_MESSAGES)}</Heading>
      <div style={{ marginBottom: '1rem' }}>
        <Button onClick={onMessageAdd} size={EButtonSize.SMALL}>{t(ETranslation.MESSAGE_ADD_MESSAGE)}</Button>
      </div>
      <MessageList messages={messageGroup?.messages} />
    </>
  );
};

export default MessageGroupEdit;
