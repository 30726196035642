import React from "react";
import { useTranslation } from "react-i18next";
import {
	EPaymentStatus,
	getPaymentStatusString,
} from "../../../shared/order-data";
import Badge, { EBadgeType } from "../../ui/Badge/Badge";

interface IProps {
	status: EPaymentStatus;
}

const OrderPaymentStatus: React.FC<IProps> = ({ status }) => {
	const { t } = useTranslation();
	let text = getPaymentStatusString(t, status);
	let badgeType = EBadgeType.SUCCESS;
	let underLineText = null;
	switch (status) {
		case EPaymentStatus.unpaid:
			badgeType = EBadgeType.DANGER;
			break;
		case EPaymentStatus.no_payment_required:
			badgeType = EBadgeType.DEFAULT;
			break;
	}

	return (
		<>
			<Badge type={badgeType}>{text}</Badge>
			{underLineText && <small>{t(underLineText)}</small>}
		</>
	);
};

export default OrderPaymentStatus;
