import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLanguages } from "../../../hooks/useLanguages";

import { IInterpretation } from "../../../interfaces/IInterpretation";
import IAppState from "../../../interfaces/store/IAppState";
import IUserState from "../../../interfaces/store/IUserState";
import {
  EConnectionDetail,
  EInterpretationType,
  INTERPRETATION_GENDER,
} from "../../../shared/interpretation-data";
import { ETranslation } from "../../../translations/translation-keys";
import * as actions from "../../../store/actions";
import { EInputType, IInputField, IOption } from "../../ui/Input/Input";
import {
  getInputData,
  initForm,
  updateInputHandler,
  validateInputs,
} from "../../ui/Input/input-utils";
import { InterpretationContext } from "../../../pages/Interpretations/OrderInterpretation/order-interpretation-context";
import { useCreateInput } from "../../../hooks/useCreateInput";
import {
  ICostCenter,
  IOrganization,
  IOrganizationDuration,
} from "../../../interfaces/IOrganization";
import IAuthState from "../../../interfaces/store/IAuthState";
import {
  createOptionsLabelAsValue,
  createOrganizationDurationsOptions,
} from "../../../utils/option-utils";
import InputGroupOrder from "../../ui/InputGroup/InputGroupOrder/InputGroupOrder";
import { useInterpretation } from "../../../hooks/useInterpretation";
import { IUser } from "../../../classes/User";
import InputDisplayBox from "../../ui/Input/InputDisplayBox/InputDisplayBox";
import classes from "./OrderInterpretation.module.scss";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import ModalContext, { EModalSize } from "../../ui/Modal/ModalContext";
import Button, { EButtonColor } from "../../ui/Button/Button";

enum EInputs {
  startDate = "startDate",
  startTime = "startTime",
  fromLanguage = "fromLanguage",
  toLanguage = "toLanguage",
  type = "type",
  interpreterInfoText = "interpreterInfoText",
  durationInMinutes = "durationInMinutes",
  gender = "gender",
  customerReference = "customerReference",
  customerReference2 = "customerReference2",
  costCenter = "costCenter",
  connectionType = "connectionType",
  videoConferenceUrl = "videoConferenceUrl",
  videoConferenceInfo = "videoConferenceInfo",
  phoneNumber = "phoneNumber",
  currentIndex = "currentIndex",
}

const OrderInterpretationBookingCustomer: React.FC = () => {
  const {
    onChange,
    onClear,
    removeInterpretation,
    removeAlternativeInterpretation,
    loading,
    organization,
    showValidation,
    editInterpretation,
    invalidIndexItems
  } = useContext(InterpretationContext);
  const dispatch = useDispatch();
  const { languageOptions } = useLanguages();
  const { user } = useSelector<IAppState, IAuthState>((state) => state.auth);
  const { user: customerUser } = useSelector<IAppState, IUserState>(
    (state) => state.user
  );

  const [customerOrganization, setCustomerOrganization] = useState<IOrganization | null | undefined>(organization);
  const { getUserConnectionDetails } = useInterpretation(
    customerUser ? customerUser : (user as IUser),
    customerOrganization as IOrganization
  );
  const [defaultDuration, setDefaultDuration] = useState(organization?.defaultOrganizationDuration ? organization.defaultOrganizationDuration.toString() : "15");
  const [organizationDurationOptions, setOrganizationDurationOptions] = useState<IOption[] | undefined>();
  const [costCenterOptions, setCostCenterOptions] = useState<IOption[]>([]);
  const [connectionDetailOptions, setConnectionDetailOptions] = useState<IOption[]>(getUserConnectionDetails);
  const { setConnectionDetailInformation } = useInterpretation();
  const [interpretationsList, setInterpretationsList] = useState<IInterpretation[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { setModal, closeModal } = useContext(ModalContext);
  const { t } = useTranslation();

  const [currentAlternativeIndex, setCurrentAlternativeIndex] = useState(0);
  const [alternativesList, setAlternativesList] = useState<IInterpretation[]>([]);
  const [showAlternativeOnly, setShowAlternativeOnly] = useState(false);

  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.startDate]: {
      type: EInputType.date,
      labelTranslation: ETranslation.COMMON_START_DATE,
      validation: {
        required: true,
      },
      value: "",
      labelClassName: classes.Bold
    },
    [EInputs.startTime]: {
      type: EInputType.time,
      labelTranslation: ETranslation.COMMON_START_TIME,
      validation: {
        required: true,
      },
      value: "",
      labelClassName: classes.Bold
    },
    [EInputs.fromLanguage]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.COMMON_FROM_LANGUAGE,
      validation: {
        required: true,
      },
      value: "fi",
      labelClassName: classes.Bold
    },
    [EInputs.toLanguage]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.COMMON_TO_LANGUAGE,
      validation: {
        required: true,
      },
      value: [],
      labelClassName: classes.Bold
    },
    [EInputs.type]: {
      type: EInputType.text,
      value: EInterpretationType.BOOKING,
      labelClassName: classes.Bold
    },
    [EInputs.interpreterInfoText]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.COMMON_INFO_TO_INTERPRETER,
      value: "",
      labelClassName: classes.Bold
    },
    [EInputs.durationInMinutes]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.COMMON_DURATION,
      validation: {
        required: true,
      },
      value: defaultDuration,
      labelClassName: classes.Bold
    },
    [EInputs.gender]: {
      type: EInputType.radio,
      labelTranslation: ETranslation.INTERPRETATION_GENDER,
      value: [],
      uncheckable: true,
      options: INTERPRETATION_GENDER,
      labelClassName: classes.Bold
    },
    [EInputs.customerReference]: {
      type: EInputType.textarea,
      customLabel: customerOrganization?.customerReferenceTitle,
      labelTranslation: ETranslation.COMMON_CUSTOMER_REFERENCE,
      value: "",
      validation: {
        required: customerOrganization?.customerReferenceMandatory,
      },
      labelClassName: classes.Bold
    },
    [EInputs.customerReference2]: {
      type: EInputType.textarea,
      customLabel: customerOrganization?.customerReference2Title,
      labelTranslation: ETranslation.COMMON_CUSTOMER_REFERENCE_2,
      value: "",
      validation: {
        required: customerOrganization?.customerReference2Mandatory,
      },
      labelClassName: classes.Bold
    },
    [EInputs.costCenter]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.INTERPRETATION_CUSTOMER_COST_CENTER,
      value: [],
      validation: {
        required: customerOrganization?.costCenterMandatory,
      },
      labelClassName: classes.Bold
    },
    [EInputs.connectionType]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.COMMON_CONNECTION_DETAIL,
      value: EConnectionDetail.CUSTOMER_CALL_TO_INTERPRETER,
      labelClassName: classes.Bold
    },
    [EInputs.videoConferenceUrl]: {
      type: EInputType.text,
      labelTranslation: ETranslation.INTERPRETATION_VIDEO_URL,
      value: "",
      labelClassName: classes.Bold
    },
    [EInputs.videoConferenceInfo]: {
      type: EInputType.text,
      labelTranslation: ETranslation.INTERPRETATION_VIDEO_INFO,
      value: "",
      labelClassName: classes.Bold
    },
    [EInputs.phoneNumber]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_PHONENUMBER,
      value: "",
      labelClassName: classes.Bold
    },
    [EInputs.currentIndex]: {
      type: EInputType.static,
      value: "",
      labelClassName: classes.Bold
    },
  });
  //clear
  useEffect(() => {
    return () => {
      dispatch(actions.getInterpretationClear());
      dispatch(actions.getUserClear());
      setInterpretationsList([]);
      setAlternativesList([])
    };
  }, [dispatch]);

  useEffect(() => {
    if (customerUser) {
      updateInputHandler(
        EInputs.phoneNumber,
        customerUser.phoneNumber,
        setInputs
      );
    } else if (user) {
      updateInputHandler(EInputs.phoneNumber, user.phoneNumber, setInputs);
    }
  }, [customerUser, user]);

  // useEffect(() => {
  //   const interpretationForValidation = getInputData<IInterpretation>(inputs);
  //   const interpretationDetails = setConnectionDetailInformation(interpretationForValidation);
  //   if(currentIndex == null || !interpretationDetails) return;
  //   const list = [...interpretationsList];
  //   list[currentIndex] = interpretationDetails;
  //   setInterpretationsList(list)
  //    // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentIndex, inputs])

  // useEffect(() => {
  //   const interpretationForValidation = interpretationsList[currentIndex];
  //   initForm(setInputs, interpretationForValidation);
  //   const interpretationDetails = setConnectionDetailInformation(interpretationForValidation);
  //   if(currentIndex == null || !interpretationDetails) return;
  //   const list = [...interpretationsList];
  //   list[currentIndex] = interpretationDetails;
  //   setInterpretationsList(prev => list)
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentIndex, setConnectionDetailInformation, updateInterpretationsList])


  // // validateInputs
  // useEffect(() => {
  //   const interpretationForValidation = getInputData<IInterpretation>(inputs);
  //   const interpretationDetails = setConnectionDetailInformation(interpretationForValidation);
  //   if (!interpretationDetails) return;
  //   onChange(interpretationDetails, validateInputs(inputs), currentIndex);
  // }, [currentIndex, inputs, onChange, setConnectionDetailInformation]);

  // get/setUserOrganization from user or customerUser
  useEffect(() => {
    if (!user || !user.organizations) return;
    dispatch(actions.getOrganization(user.organizations[0].id));
  }, [dispatch, user]);

  useEffect(() => {
    if (user && user.organizations) {
      setCustomerOrganization(organization);
    }
  }, [customerUser, organization, user]);

  // setConnectionDetailOptions by user permissions
  useEffect(() => {
    if (!customerUser) return;
    setConnectionDetailOptions(getUserConnectionDetails());
  }, [customerUser, customerOrganization, getUserConnectionDetails]);

  // setOrganizationDurations
  useEffect(() => {
    if (!organization || !organization?.organizationDurations) return;
    const organizationDurations = organization.organizationDurations;
    setOrganizationDurationOptions(
      createOrganizationDurationsOptions(
        organizationDurations as IOrganizationDuration[]
      )
    );
    setDefaultDuration(
      organization?.organizationDurations[0].durationInMinutes.toString()
    );
  }, [organization]);

  //CostCenter
  useEffect(() => {
    if (!organization || !organization?.costCenters) return;
    const costCenters = organization.costCenters;
    setCostCenterOptions(
      createOptionsLabelAsValue(costCenters as ICostCenter[])
    );
  }, [organization]);

  const createInput = useCreateInput(inputs, setInputs, {
    showValidation,
    disabled: loading,
  });

  // const addIntepretation = () => {
  //   if (editInterpretation) {
  //     setInterpretationsList((interpretationsList) => [
  //       ...interpretationsList,
  //       editInterpretation,
  //     ]);
  //     setCurrentIndex(interpretationsList.length);
  //   }
  // };



  const showClearModal = () => {
    setModal({
      isOpen: true,
      size: EModalSize.EXTRASMALL,
      content: (
        <ConfirmationModal
        text={t(ETranslation.MODAL_ARE_YOU_SURE_CLEAR_INTERPRETATIONS)}
        onConfirm={() => {
          setInterpretationsList([])
          onClear()
          closeModal()
        }}
        />
      ),
    });
  }

  // const deleteInterpretation = useCallback((indexNumber: number) => {
  //   setInterpretationsList(list => list.filter((i, index) => index !== indexNumber));
  //   if(removeInterpretation)
  //   removeInterpretation(indexNumber);
  // },[removeInterpretation]);

  const updateIndex = useCallback((index: number, altIndex: number) => {
    setCurrentIndex(index);
    setCurrentAlternativeIndex(altIndex);
  }, []);

  useEffect(() => {
    if (!interpretationsList[currentIndex]) {
      setAlternativesList([]);
      return;
    }
    const alternatives = interpretationsList[currentIndex].alternatives;
    if (alternatives) {
      setAlternativesList(alternatives);
    } else {
      setAlternativesList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, currentAlternativeIndex]);

  const updateAlternatives = useCallback(
    (list: IInterpretation[]) => {
      if (!list[currentIndex]) return;
      if (!list[currentIndex].alternatives) {
        list[currentIndex].alternatives = [...alternativesList];
      } else {
        list[currentIndex].alternatives = alternativesList;
      }
      setInterpretationsList(list);
    },
    [alternativesList, currentIndex]
  );

  // updates the information in the form based on which interpretation is selected
  const updateForm = useCallback(
    (list: IInterpretation[], index: number) => {
      if (!showAlternativeOnly) {
        initForm(setInputs, list[index]);
      } else {
        initForm(setInputs, list[index].alternatives[currentAlternativeIndex]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [currentAlternativeIndex, showAlternativeOnly]
  );

    // OnChange set information based on is the interpretation alternative
    useEffect(() => {
      const interpretation = getInputData<IInterpretation>(inputs);
      if (!interpretation) return;
      const interpretationDetails =
        setConnectionDetailInformation(interpretation);
      if (!interpretationDetails) return;
      const interpretationsListCopy = [...interpretationsList];
      const alternativesListCopy = [...alternativesList];
      if (!showAlternativeOnly) {
        interpretationDetails.isAlternative = false;
        interpretationDetails.alternatives = alternativesListCopy;
        interpretationsListCopy[currentIndex] = interpretationDetails;
        setInterpretationsList((prev) => interpretationsListCopy);
      } else {
        interpretationDetails.isAlternative = true;
        alternativesListCopy[currentAlternativeIndex] = interpretationDetails;
        interpretationsListCopy[currentIndex].alternatives = alternativesListCopy;
        setInterpretationsList((prev) => interpretationsListCopy);
      }
      onChange(interpretationDetails, validateInputs(inputs), currentIndex, currentAlternativeIndex, showAlternativeOnly);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAlternativeIndex, currentIndex, inputs, onChange]);

    useEffect(() => {
      updateAlternatives(interpretationsList);
      updateForm(interpretationsList, currentIndex);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentIndex, currentAlternativeIndex, updateAlternatives, updateForm]);

    const addIntepretation = () => {
      if (editInterpretation) {
        editInterpretation.alternatives = [];
        setInterpretationsList((interpretationsList) => [
          ...interpretationsList,
          editInterpretation,
        ]);
        setShowAlternativeOnly(false);
        setCurrentIndex(interpretationsList.length);
      }
    };

    const addIntepretationAlternative = (index: number) => {
      if (!editInterpretation) return;
      const parentInterpretation = { ...interpretationsList[index] };
      const parentInterpretation2 = { ...interpretationsList[index] };
      if (!parentInterpretation) return;
      parentInterpretation2.alternatives = [];
      parentInterpretation.alternatives.push(parentInterpretation2);
      setCurrentIndex(index);
      setCurrentAlternativeIndex(parentInterpretation.alternatives.length - 1);
      setAlternativesList(parentInterpretation.alternatives);
      setShowAlternativeOnly(true);
    };

    const deleteInterpretation = useCallback(
      (indexNumber: number) => {
        setInterpretationsList((list) =>
          list.filter((i, index) => index !== indexNumber)
        );
        if (removeInterpretation) removeInterpretation(indexNumber);
      },
      [removeInterpretation]
    );

    const deleteAlternativeInterpretation = useCallback(
      (indexNumber: number, alternativeIndexNumber: number) => {
        setCurrentIndex(indexNumber);
        const list = [...interpretationsList];
        const interpretationForFilter = list[indexNumber];
        if (!interpretationForFilter || !interpretationForFilter.alternatives)
          return;
        const filtered = interpretationForFilter.alternatives.filter(
          (i, altIndex) => altIndex !== alternativeIndexNumber
        );
        interpretationForFilter.alternatives = filtered;
        list[indexNumber] = interpretationForFilter;
        setInterpretationsList((prev) => list);
        if(removeAlternativeInterpretation) removeAlternativeInterpretation(indexNumber, alternativeIndexNumber)
        setCurrentAlternativeIndex( interpretationForFilter.alternatives.length > 0 ?
          interpretationForFilter.alternatives.length - 1 : 0
        );
        setShowAlternativeOnly(
          interpretationForFilter.alternatives !== undefined ? true : false
        );

      },
      [interpretationsList, removeAlternativeInterpretation]
    );

    const showAlternativeOnlySwitch = useCallback((value: boolean) => {
      setShowAlternativeOnly(value);
    }, []);

    const getInterpretationInputs = (
      interpretation: IInterpretation,
      index: number,
      isAlternative: boolean,
      alternativeIndex: number
    ) => {
      let indexValue = null;
      switch (isAlternative) {
        case true:
          indexValue = alternativeIndex;
          break;
        case false:
          indexValue = index;
          break;
      }
      return (
        <React.Fragment key={indexValue ? indexValue : 0}>
            <InputDisplayBox
                interpretation={interpretation}
                index={index}
                alternativeIndex={alternativeIndex}
                setCurrentIndex={updateIndex}
                currentIndex={currentIndex}
                currentAlternativeIndex={currentAlternativeIndex}
                deleteInterpretation={deleteInterpretation}
                deleteAlternativeInterpretation={deleteAlternativeInterpretation}
                showValidation={showValidation ? showValidation : false}
                invalidIndexItems={invalidIndexItems}
                isAlternative={isAlternative}
                setAlternativeOnly={showAlternativeOnlySwitch}
                showAlternativeOnly={showAlternativeOnly}
              >
              <InputGroupOrder><div className={classes.PaddingTop1}>{createInput(EInputs.startDate)}</div></InputGroupOrder>
              <InputGroupOrder>
                {createInput(EInputs.startTime)}
              </InputGroupOrder>
              <InputGroupOrder>
                {createInput(EInputs.fromLanguage, {
                  options: languageOptions,
                })}
              </InputGroupOrder>
              <InputGroupOrder>
                {createInput(EInputs.toLanguage, {
                  options: languageOptions,
                })}
              </InputGroupOrder>
              <InputGroupOrder>{createInput(EInputs.gender)}</InputGroupOrder>
              <InputGroupOrder>
                {createInput(EInputs.connectionType, {
                  options: connectionDetailOptions,
                })}
              </InputGroupOrder>
              {inputs[EInputs.connectionType].value ===
              EConnectionDetail.INTERPRETER_CALL_TO_CUSTOMER ? (
                <InputGroupOrder>
                  {createInput(EInputs.phoneNumber)}
                </InputGroupOrder>
              ) : null}
              {inputs[EInputs.connectionType].value ===
              EConnectionDetail.VIDEO_CONFERENCE ? (
                <>
                  <InputGroupOrder>
                    {createInput(EInputs.videoConferenceUrl)}
                  </InputGroupOrder>
                  <InputGroupOrder>
                    {createInput(EInputs.videoConferenceInfo)}
                  </InputGroupOrder>
                </>
              ) : null}
              <InputGroupOrder>
                {createInput(EInputs.interpreterInfoText)}
              </InputGroupOrder>
              <InputGroupOrder>
                {createInput(EInputs.durationInMinutes, {
                  options: organizationDurationOptions,
                })}
              </InputGroupOrder>
              {customerOrganization?.costCenterMandatory && (
                <InputGroupOrder>
                  {createInput(EInputs.costCenter, {
                    options: costCenterOptions,
                  })}
                </InputGroupOrder>
              )}
              <InputGroupOrder>
                {createInput(EInputs.customerReference)}
              </InputGroupOrder>
              {customerOrganization?.customerReference2Mandatory && (
                <InputGroupOrder>
                  {createInput(EInputs.customerReference2)}
                </InputGroupOrder>
              )}
            </InputDisplayBox>
          </React.Fragment>
      )}

  return (
    <>
      <div>
      {interpretationsList?.map((interpretation, index) => (
          <>
          <div className={classes.MainContainer}>
            {getInterpretationInputs(interpretation, index, false, -1)}
            {interpretation.alternatives &&
              interpretation.alternatives.map(
                (alternativeInterpretation, alternativeIndex) =>
                  getInterpretationInputs(
                    alternativeInterpretation,
                    index,
                    true,
                    alternativeIndex
                  )
              )}
            {interpretation.alternatives.length < 3 && (
              <div className={classes.AlignSelfCenter}>
                <Button
                  className={classes.AddAlternativeButton}
                  onClick={() => addIntepretationAlternative(index)}
                  color={EButtonColor.DEFAULT}
                >
                   {t(ETranslation.INTERPRETATION_ADD_ALTERNATIVE)}
                </Button>
              </div>
            )}
            </div>
          </>
        ))}
        {interpretationsList.length < 5 && (
          <div className={classes.AlignSelfCenter}>
              <Button
                className={classes.AddInterpretationButton}
                onClick={() => addIntepretation()}
                color={EButtonColor.DEFAULT}
              >
                {t(ETranslation.COMMON_ADD_NEW)}
              </Button>
          </div>
        )}
        {interpretationsList.length > 0 && (
          <div className={classes.AlignSelfCenter}>
              <Button
                className={classes.ClearAllButton}
                onClick={() => showClearModal()}
                color={EButtonColor.DEFAULT}
              >
                {t(ETranslation.INTERPRETATIONS_CLEAR_ALL)}
              </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderInterpretationBookingCustomer;
