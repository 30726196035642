import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';

import { useCreateInput } from '../../hooks/useCreateInput';
import { useSiteTypes } from '../../hooks/useSiteTypes';
import { ISalaryInfo } from '../../interfaces/ISalaryInfo';
import EditButtons from '../ui/EditButtons/EditButtons';
import { EInputType, IInputField } from '../ui/Input/Input';
import { getInputData, initForm, validateInputs } from '../ui/Input/input-utils';
import InputGroup from '../ui/InputGroup/InputGroup';
import ModalBody from '../ui/Modal/ModalBody/ModalBody';
import ModalContext from '../ui/Modal/ModalContext';
import ModalFooter from '../ui/Modal/ModalFooter/ModalFooter';

interface IProps {
	onAccept: (salaryInfo: ISalaryInfo) => void
	salaryInfo?: ISalaryInfo;
}

enum EInputs {
	types = "types",
	startDate = "startDate",
	endDate = "endDate",
	salaryPerHour = "salaryPerHour",
}

const SalaryInfoEditModal: React.FC<IProps> = ({ onAccept, salaryInfo }) => {
	const [isValid, setIsValid] = useState(true);
	const [showValidation, setShowValidation] = useState(false);
	const { closeModal } = useContext(ModalContext);

	const { siteTypes } = useSiteTypes();

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.types]: {
			type: EInputType.reactSelect,
			value: "",
			label: 'Ammattinimikeet',
			multiple: true,
			validation: {
				required: true
			}
		},
		[EInputs.startDate]: {
			type: EInputType.date,
			value: "",
			label: 'Alkupvm',
			validation: {
				required: true
			}
		},
		[EInputs.endDate]: {
			type: EInputType.date,
			value: "",
			label: 'Loppupvm'
		},
		[EInputs.salaryPerHour]: {
			type: EInputType.number,
			value: "",
			label: 'Palkka / h',
			validation: {
				required: true
			}
		},
	});

	useEffect(() => {
		setIsValid(validateInputs(inputs));
	}, [inputs]);

	useEffect(() => {
		if (salaryInfo) {
			initForm(setInputs, salaryInfo);
		}
	}, [salaryInfo]);

	const saveHandler = () => {
		if (isValid) {
			const updatedSalaryInfo = getInputData<ISalaryInfo>(inputs);
			updatedSalaryInfo.id = salaryInfo?.id ?? uuidV4();
			onAccept(updatedSalaryInfo);
		} else {
			setShowValidation(true);
		}
	};

	const createInput = useCreateInput(inputs, setInputs, { showValidation });
	return (
		<>
			<ModalBody>
				{createInput(EInputs.types, { options: siteTypes })}
				<InputGroup>
					{createInput(EInputs.startDate)}
					{createInput(EInputs.endDate)}
				</InputGroup>
				{createInput(EInputs.salaryPerHour)}
			</ModalBody>
			<ModalFooter>
				<EditButtons disabled={showValidation && !isValid} onSave={saveHandler} loading={false} onCancel={closeModal} isAdd={true} />
			</ModalFooter>
		</>
	);
};

export default SalaryInfoEditModal;
