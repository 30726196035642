import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { IUserContractSettings } from "../../../interfaces/IUserContractSettings";
import { ETranslation } from "../../../translations/translation-keys";
import Alert from "../../ui/Alert/Alert";
import Input, { EInputType, TInputValue } from "../../ui/Input/Input";
import Spinner from "../../ui/Spinner/Spinner";
import Table from "../../ui/Table/Table";
import { formatDate } from "../../../utils/date-utils";

interface IProps {
	settings: IUserContractSettings[] | null;
	loading: boolean;
	error: string | null;
	onClick: (id: string, index: number) => void;
	onDelete?: (id: string, index: number) => void;
	showFilter?: boolean;
	showDetails?: boolean;
}

const UserContractSettingsList: React.FC<IProps> = ({
	settings,
	loading,
	error,
	onClick,
	onDelete,
	showDetails,
	showFilter,
}) => {
	const [filter, setFilter] = useState("");
	const { t } = useTranslation();

	if (error) {
		return <Alert>{error}</Alert>;
	}

	if (!settings || loading) {
		return <Spinner />;
	}

	if (settings.length === 0) {
		return <p>{t(ETranslation.USER_CONTRACT_SETTINGS_LIST_NO_RESULTS)}</p>;
	}

	const filterSettings = (item: IUserContractSettings): boolean => {
		return filter.length === 0 || item.name?.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
	};

	const deleteHandler = (event: React.MouseEvent, id: string, index: number) => {
		event.stopPropagation();
		onDelete && onDelete(id, index);
	};

	return (
		<>
			{showFilter && (
				<Input
					containerStyles={{ margin: "1rem 0" }}
					type={EInputType.text}
					inputName="filter"
					value={filter}
					onChange={(value: TInputValue) => setFilter(value as string)}
					placeholderTranslation={ETranslation.COMMON_FILTER_BY_NAME}
				/>
			)}
			<Table hover>
				<thead>
					<tr>
						<th>{t(ETranslation.COMMON_NAME)}</th>
						{showDetails && <th>Sopimuskausi</th>}
						{onDelete && <th></th>}
					</tr>
				</thead>
				<tbody>
					{settings.filter(filterSettings).map((setting, index) => (
						<tr key={setting.id} onClick={() => onClick(setting.id, index)}>
							<td>{setting.name}</td>
							{showDetails && (
								<td>
									{formatDate(setting.contractStartDate)} - {formatDate(setting.contractEndDate)}
								</td>
							)}
							{onDelete && (
								<td style={{ width: "1px" }}>
									<FontAwesomeIcon
										onClick={(event) => deleteHandler(event, setting.id, index)}
										icon={faTrash}
									/>
								</td>
							)}
						</tr>
					))}
				</tbody>
			</Table>
		</>
	);
};

export default UserContractSettingsList;
