import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IInfoContactRequest } from '../../../interfaces/IInfoContactRequest';
import { getInfoContactRequestSubjectString } from '../../../shared/info-contact-request-data';
import { ETranslation } from '../../../translations/translation-keys';
import Alert from '../../ui/Alert/Alert';
import Input, { EInputType, TInputValue } from '../../ui/Input/Input';
import Spinner from '../../ui/Spinner/Spinner';
import Table from '../../ui/Table/Table';
import InfoContactRequestListItem from './InfoContactRequestListItem/InfoContactRequestListItem';

interface IProps {
  infoContactRequests: IInfoContactRequest[] | null;
  loading: boolean;
  error: string | null;
  onClick: (id?: string) => void;
}

const InfoContactRequestList: React.FC<IProps> = ({
  infoContactRequests,
  loading,
  error,
  onClick,
}) => {
  const [filter, setFilter] = useState("");

  const { t } = useTranslation();

  if (error) {
    return <Alert>{error}</Alert>;
  }

  if (!infoContactRequests || loading) {
    return <Spinner />;
  }

  if (infoContactRequests.length === 0) {
    return <p>{t(ETranslation.CONTACT_REQUEST_NO_REQUESTS)}</p>;
  }

  const filterInfoContactRequests = (item: IInfoContactRequest): boolean => {
    return (
      filter.length === 0 ||
      getInfoContactRequestSubjectString(t, item.subject).toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
      item.subjectOther?.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  };
  
  const filteredInfoContactRequests = infoContactRequests.filter(filterInfoContactRequests);

  return (
    <>
      <Input
        containerStyles={{ margin: "1rem 0" }}
        type={EInputType.text}
        inputName="filter"
        value={filter}
        onChange={(value: TInputValue) => setFilter(value as string)}
        placeholder={t(ETranslation.CONTACT_REQUEST_FILTER)}
      />
      {filteredInfoContactRequests.length ? (
        <>
          <Table hover>
            <thead>
              <tr>
                <th style={{ width: '1px' }}>{t(ETranslation.COMMON_STATUS)}</th>
                <th>{t(ETranslation.COMMON_CREATED)}</th>
                <th>{t(ETranslation.CONTACT_REQUEST_SUBJECT)}</th>
                <th>{t(ETranslation.COMMON_LANGUAGE)}</th>
                <th>{t(ETranslation.COMMON_PHONENUMBER)}</th>
                <th>{t(ETranslation.CONTACT_REQUEST_MUNICIPALITY)}</th>
                <th>{t(ETranslation.CONTACT_REQUEST_NEED_INTERPRETER)}</th>
                <th>{t(ETranslation.COMMON_COMMENTS)}</th>
              </tr>
            </thead>
            <tbody>
              {filteredInfoContactRequests.map((infoContactRequest) => (
                <InfoContactRequestListItem
                  key={infoContactRequest.id}
                  infoContactRequest={infoContactRequest}
                  onEdit={onClick}
                />
              ))}
            </tbody>
          </Table>
          <p><i>{filteredInfoContactRequests.length} {t(ETranslation.COMMON_PCS)}</i></p>
        </>
      ) : <p>{t(ETranslation.CONTACT_REQUEST_NO_REQUESTS)}</p>}
    </>
  );
};

export default InfoContactRequestList;
