import { Dispatch } from "redux";
import EActionTypes from "../../interfaces/store/EActionTypes";
import ITaskAction from "../../interfaces/store/ITaskAction";
import { EFetchMethod, customFetch } from "../../custom-fetch";
import { IUser } from "../../classes/User";
import * as dateFns from 'date-fns';
import { IOrganization } from "../../interfaces/IOrganization";

export interface ITask {
	id: string,
	keyString: string,
	createdDateTime: string,
	createdDate: string,
	createdUTC: string,
	updated?: string,
	type?: string,
	status: string,
	notesText?: string,
	adminNotesText?: string,
	site: string,
	unitName?: IOrganization,
	organizations?: IOrganization[],
	user: IUser,
	taskItems: ITaskitem[],
}

export interface ITaskSearch {
	startDate?: string,
	endDate?: string,
	page: number,
	createdDateStart?: string,
	createdDateEnd?: string,
	organization?: IOrganization,
}

export interface ITaskitem {
	id: string,
	user?: ITaskitemUser,
}

export interface ITaskitemUser {
	id: string,
	firstName: string,
	lastName: string,
	email: string,
	phoneNumber: string,
}

type TAction = ITaskAction;

const saveTaskStart = (): TAction => {
	return {
		type: EActionTypes.TASK_SAVE_START
	};
};

const saveTaskComplete = (task: ITask, actionType: string): TAction => {
	return {
		task,
		actionType: actionType,
		type: EActionTypes.TASK_SAVE_SUCCESS
	};
};

const saveTaskError = (error: string): TAction => {
	return {
		type: EActionTypes.TASK_SAVE_ERROR,
		error
	};
};

const getTasksStart = (): TAction => {
	return {
		type: EActionTypes.TASKS_GET_START
	};
};

const getTasksSuccess = (tasks: ITask[], hasNext: boolean): TAction => {
	return {
		type: EActionTypes.TASKS_GET_SUCCESS,
		tasks,
		hasNext
	};
};

const getTasksError = (error: string): TAction => {
	return {
		type: EActionTypes.TASK_SAVE_CLEAR,
		error
	};
};

//Save current dates variables and use them to retrieve infomration
const currentDate = new Date();
const startDate = dateFns.startOfYear(currentDate);
const endDate = dateFns.endOfYear(currentDate);

export const getTasks = () => {

	const body = {
		startDate: startDate,
		endDate: endDate,
		page: 1,
	}
	return async (dispatch: Dispatch) => {
		dispatch(getTasksStart())
		try {
			customFetch<{ items: ITask[], hasNext: boolean }>("/tasks/list", EFetchMethod.POST, JSON.stringify(body)).then((response) => {
				dispatch(getTasksSuccess(response.items, response.hasNext))
			})
		} catch (error) {
			dispatch(getTasksError((error as Error).message));
		}
	}
};

export const saveTaskClear = (): TAction => {
	return {
		type: EActionTypes.TASK_SAVE_CLEAR
	}
}

export const saveTask = (task: ITask) => {
	return async (dispatch: Dispatch) => {
		dispatch(saveTaskStart())
		try {
			customFetch<{ task: ITask, action: string }>("/tasks/add", EFetchMethod.POST, JSON.stringify(task)).then((data) => {
				dispatch(saveTaskComplete(data.task, data.action));
			})
		} catch (error) {
			dispatch(saveTaskError((error as Error).message));
		}
	};
}

export const searchTasksClear = (): TAction => {
	return {
		type: EActionTypes.TASKS_GET_CLEAR
	}
}

//Searches for a specific dates tasks
export const searchTasks = (data: ITaskSearch) => {

	const body = {
		startDate: data.startDate,
		endDate: data.endDate,
		page: data.page?? 1,
	};

	return async (dispatch: Dispatch) => {
		dispatch(getTasksStart());
		try {
			customFetch<{ items: ITask[], hasNext: boolean }>("/tasks/list", EFetchMethod.POST, JSON.stringify(body)).then((response) => {

				dispatch(getTasksSuccess(response.items, response.hasNext));
			});
		} catch (error) {
			dispatch(getTasksError((error as Error).message));
		}
	};
};