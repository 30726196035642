import { EInterpretationType } from "../shared/interpretation-data";

export enum ESitePrefix {
	youpret = "",
	johannarekry = "johannarekry",
	valitysklinikka = "valitysklinikka",
}

export interface ISite {
	id: string;
	name: string;
	prefix: ESitePrefix;
	description?: string;
	timeZoneId: string;
	active: boolean;
	types: EInterpretationType[];
	targetMarginP: number;
	minFeePerMin: number;
	maxFeePerMin: number;
}
