import React from "react";
import classes from "../userStyles.module.scss";
import { faEnvelope, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IUser } from "../../../classes/User";
import { resetSendPassowrdClear, resetEezyTrusterClear, sendNewPassword, resetEezyTrusterId } from "../../../store/actions/userActions";
import { ETranslation } from "../../../translations/translation-keys";
import ActionButton from "../../ui/ActionButton/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import IAppState from "../../../interfaces/store/IAppState";
import { useTranslation } from "react-i18next";

interface IProps {
	user: IUser;
}

const UserEditActions: React.FC<IProps> = ({ user }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {
		passwordResetLoading,
		passwordResetError,
		passwordResetSuccess,
		message,
		resetEezyTrusterLoading,
		resetEezyTrusterError,
		resetEezyTrusterSuccess,
	} = useSelector((state: IAppState) => state.user);

	const resetAndSendPassword = () => {
		dispatch(sendNewPassword(user));
	}

	const resetEezyId = () => {
		dispatch(resetEezyTrusterId(user.id));
	}

	return (
		<div className={classes.headerActionsCont}>
			<ActionButton
				onclick={resetAndSendPassword}
				actionLoading={passwordResetLoading}
				actionSuccess={passwordResetSuccess}
				actionError={passwordResetError}
				successMessage={message ? `${t(ETranslation.USER_NEW_PASSWORD_IS)}: ` + message : null}
				resetCb={() => dispatch(resetSendPassowrdClear())}
			>
				<FontAwesomeIcon icon={faEnvelope} />{t(ETranslation.USER_RESET_AND_SEND_PASSWORD)}
			</ActionButton>
			{user.eezyId &&
				<ActionButton
					onclick={resetEezyId}
					actionLoading={resetEezyTrusterLoading}
					actionSuccess={resetEezyTrusterSuccess}
					actionError={resetEezyTrusterError}
					successMessage={resetEezyTrusterSuccess ? `Käyttäjän Eezy/Truster ID on nollattu` : null}
					resetCb={() => dispatch(resetEezyTrusterClear())}
				>
					<FontAwesomeIcon icon={faTrash} />{t(ETranslation.USER_RESET_EEZY_TRUSTER_ID)}
				</ActionButton>}
		</div>
	)
}

export default UserEditActions;