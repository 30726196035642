import { IInterpretationIndexItem } from './../../../interfaces/IInterpretation';
import React from "react";
import { IInterpretation } from "../../../interfaces/IInterpretation";
import { IOrganization } from "../../../interfaces/IOrganization";

interface IInterpretationContext {
    onChange: (interpretation: IInterpretation, isValid: boolean, index?: number, altIndex?: number, isAlternative?: boolean) => void;
    onClear: () => void;
    removeInterpretation?: (index: number) => void;
    removeAlternativeInterpretation?: (index: number, alternativeIndex: number) => void;
    updateInterpretationsList?: (interpretations: IInterpretation[]) => void;
    interpretation?: IInterpretation | null;
    interpretations?: IInterpretation[] | null;
    editInterpretation?: IInterpretation | null;
    disabled?: boolean;
    loading?: boolean;
    organization?: IOrganization | null;
    showValidation?: boolean;
    index?: number;
    showNotValid?: boolean;
    invalidIndexItems?: IInterpretationIndexItem[];
    alternativeOnly?: boolean;
    currentIndexValue?: number;
    currentAlternativeIndexValue?: number;
  }
  
export const InterpretationContext =
  React.createContext<IInterpretationContext>({
    onChange: () => {},
    onClear: () => {},
    removeInterpretation: () => {},
    removeAlternativeInterpretation: () => {},
    updateInterpretationsList: () => {},
    interpretation: null,
    interpretations: null,
    editInterpretation: null,
    disabled: false,
    loading: false,
    organization: null,
    showValidation: false,
    showNotValid: false,
    index: 0,
    invalidIndexItems: [],
    alternativeOnly:false,
    currentIndexValue: 0,
    currentAlternativeIndexValue: 0,
  });