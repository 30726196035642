import EActionTypes from "../../interfaces/store/EActionTypes";
import ICommissionState from "../../interfaces/store/ICommissionState";
import ICommissionAction from "../../interfaces/store/ICommissionAction";
import { PAGINATION_FETCH_SIZE } from "../../hooks/commissionsPagination";


type ReducerState = ICommissionState;
type ReducerSignature = (state: ReducerState, action: ICommissionAction) => ReducerState;

const initialState: ReducerState = {
	commission: null,
	loading: false,
	error: null,
	commissions: null,
	commissionsLoading: false,
	paginationCursor: null,
	commissionsError: null,
	hasMoreCommissions: false,
	searchParams: null,
	commissionSaveLoading: false,
	commissionSaveDone: false,
	commissionSaveError: null,
};

const getCommissionsStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: true };
};

const getCommissionStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: true };
};

const getCommissionSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		commission: action.commission || null,
		error: null,
		loading: false,
	};
};

const getCommissionsSuccess: ReducerSignature = (state, action): ReducerState => {
	const hasMoreCommissions = [...(action.commissions ?? [])].length > 0 && [...(action.commissions ?? [])].length === PAGINATION_FETCH_SIZE;
	const commissions = state.commissions ? action.commissions ? [...state.commissions, ...action.commissions] : null : action.commissions ? action.commissions : null;
	return {
		...state,
		paginationCursor: action.paginationCursor || null,
		commissions: commissions,
		hasMoreCommissions,
		error: null,
		loading: false,
	};
};

const getCommissionFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, commission: null, loading: false, error: action.error };
};

const getCommissionsFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, commission: null, commissions: null, loading: false, error: action.error };
};

const getCommissionsClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, commissions: null, loading: false, error: null, commission: null };
}

const saveCommissionStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, commissionSaveLoading: true };
} 

const saveCommissionDone: ReducerSignature = (state, action): ReducerState => {
	return { ...state, commissionSaveLoading: false, commissionSaveDone: true, commissionSaveError: null };
} 

const saveCommissionError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, commissionSaveLoading: false, commissionSaveDone: true, commissionSaveError: state.commissionSaveError ?? "" };
} 

const saveCommissionClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, commissionSaveLoading: false, commissionSaveDone: false, commissionSaveError: null, commission: null };
} 

const commissionsReducer = (state: ReducerState = initialState, action: ICommissionAction): ReducerState => {
	switch (action.type) {
		case EActionTypes.COMMISSION_GET_COMMISSION_START:
			return getCommissionStart(state, action);
		case EActionTypes.COMMISSION_GET_COMMISSION_SUCCESS:
			return getCommissionSuccess(state, action);
		case EActionTypes.COMMISSION_GET_COMMISSION_FAIL:
			return getCommissionFail(state, action)
		case EActionTypes.COMMISSION_GET_LIST_START:
			return getCommissionsStart(state, action);
		case EActionTypes.COMMISSION_GET_LIST_SUCCESS:
			return getCommissionsSuccess(state, action);
		case EActionTypes.COMMISSION_GET_LIST_FAIL:
			return getCommissionsFail(state, action);
		case EActionTypes.COMMISSION_GET_LIST_CLEAR:
			return getCommissionsClear(state, action);
		case EActionTypes.COMMISSION_SAVE_START:
			return saveCommissionStart(state, action);
		case EActionTypes.COMMISSION_SAVE_DONE:
			return saveCommissionDone(state, action);
		case EActionTypes.COMMISSION_SAVE_ERROR:
			return saveCommissionError(state, action);
		case EActionTypes.COMMISSION_SAVE_CLEAR:
			return saveCommissionClear(state, action);
		default:
			return state;
	}
};

export default commissionsReducer;
