import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Container from "../../components/ui/Container/Container";
import Heading from "../../components/Heading/Heading";
import Spinner from "../../components/ui/Spinner/Spinner";
import * as dateFns from "date-fns";
import { useTranslation } from "react-i18next";
import { IOrder } from "../../store/actions/ordersActions";
import IAppState from "../../interfaces/store/IAppState";
import { EInputType, IInputField } from "../../components/ui/Input/Input";
import {
	getInputData,
	validateInputs,
} from "../../components/ui/Input/input-utils";
import { useCreateInput } from "../../hooks/useCreateInput";
import { ETranslation } from "../../translations/translation-keys";
import OrderPaymentStatus from "../Orders/OrderPaymentStatus/OrderPaymentStatus";
import Alert from "../ui/Alert/Alert";
import Button from "../ui/Button/Button";
import { payUrl } from "../../config";
import { useLanguages } from "../../hooks/useLanguages";
import { EInterpretationType } from "../../shared/interpretation-data";
import InputGroup from "../ui/InputGroup/InputGroup";
import Fieldset from "../ui/Fieldset/Fieldset";

interface IProps {
	order?: IOrder | null;
	onUpdate: (order: IOrder, valid: boolean) => void;
	onExport: () => void;
}

enum EInputs {
	id = "id",
	status = "status",
	createdDateTime = "createdDateTime",
	customerFirstName = "customerFirstName",
	customerLastName = "customerLastName",
	customerPhoneNumber = "customerPhoneNumber",
	customerEmail = "customerEmail",
	customerNotes = "customerNotes",
	customerOrganizationName = "customerOrganizationName",
	startDateText = "startDateText",
	startDate = "startDate",
	startDateTime = "startDateTime",
	startDateHours = "startDateHours",
	startDateMinutes = "startDateMinutes",
	startTime = "startTime",
	fromLanguageText = "fromLanguageText",
	durationInMinutes = "durationInMinutes",
	fromLanguage = "fromLanguage",
	toLanguage = "toLanguage",
	toLanguageText = "toLanguageText",
	adminNotes = "adminNotes",
	organizationName = "organizationName",
	startTimeString = "startTimeString",
	address = "address",
	zip = "zip",
	city = "city",
	info = "info"

}

const Order: React.FC<IProps> = ({ order, onUpdate, onExport }) => {
	const {
		loading,
		exportToYoupretPayLoading,
		exportToYoupretPayError,
		updatePaymentStatusLoading,
		updatePaymentStatusError,
	} = useSelector((state: IAppState) => state.orders);
	const { t } = useTranslation();
	const { languageOptions } = useLanguages();
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.status]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.COMMON_STATUS,
			options: [
				{
					value: "NEW",
					labelTranslation: ETranslation.COMMON_NEW,
				},
				{
					value: "OPEN",
					labelTranslation: ETranslation.COMMON_IN_PROGRESS,
				},
				{
					value: "COMPLETED",
					labelTranslation: ETranslation.COMMON_COMPLETE,
				},
				{
					value: "REJECTED",
					labelTranslation: ETranslation.COMMON_REJECTED,
				},
			],
			value: order?.status ?? "NEW",
			validation: {
				required: true,
				minLength: 1,
			},
		},
		[EInputs.id]: {
			type: EInputType.text,
			label: "id",
			value: order?.id ?? "",
		},
		[EInputs.customerFirstName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_FIRST_NAME,
			value: order?.customerFirstName ?? "",
			validation: {
				required: true,
				minLength: 1,
			},
		},
		[EInputs.customerLastName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_LAST_NAME,
			value: order?.customerLastName ?? "",
			validation: {
				required: true,
				minLength: 1,
			},
		},
		[EInputs.customerPhoneNumber]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_PHONENUMBER,
			value: order?.customerPhoneNumber ?? "",
			validation: {
				required: true,
				minLength: 1,
			},
		},
		[EInputs.customerEmail]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_EMAIL,
			value: order?.customerEmail ?? "",
			validation: {
				required: true,
				minLength: 1,
			},
		},
		[EInputs.customerNotes]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_ADDITIONAL_INFO,
			value: order?.customerNotes ?? "",
		},
		[EInputs.customerOrganizationName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.ORDERS_ORGANIZATION,
			value: order?.customerOrganizationName ?? "",
			validation: {
				required: true,
				minLength: 1,
			},
		},
		[EInputs.startDate]: {
			type: EInputType.datepicker,
			labelTranslation: ETranslation.COMMON_START_DATE,
			dateAsString: true,
			value: order?.startDate
				? dateFns.parse(order.startDate, "dd.MM.yyyy", new Date())
				: null,
			validation: {
				required: true,
				minLength: 1,
			},
		},
		[EInputs.startTime]: {
			type: EInputType.time,
			labelTranslation: ETranslation.COMMON_START_TIME,
			value: order?.startTime ?? "",
			validation: {
				required: true,
				minLength: 1,
			},
		},
		[EInputs.durationInMinutes]: {
			type: EInputType.number,
			labelTranslation: ETranslation.COMMON_DURATION_IN_MINUTES,
			value: order?.durationInMinutes ?? "",
			validation: {
				required: true,
				minLength: 1,
			},
		},

		[EInputs.fromLanguage]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_FROM_LANGUAGE,
			options: languageOptions,
			value: order?.fromLanguage ?? "",
			validation: {
				required: true,
			},
		},
		[EInputs.toLanguage]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_TO_LANGUAGE,
			options: languageOptions,
			value: order?.toLanguage ?? "",
			validation: {
				required: true,
			},
		},
		[EInputs.address]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_ADDRESS,
			value: order?.address ?? "",
			validation: {
				required: false,
			},
		},
		[EInputs.zip]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_ZIP,
			value: order?.zip ?? "",
			validation: {
				required: false,
			},
		},
		[EInputs.city]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_LOCATION,
			value: order?.city ?? "",
			validation: {
				required: false,
			},
		},
		[EInputs.info]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_ADDITIONAL_INFO,
			value: order?.info ?? "",
			validation: {
				required: false,
			},
		},
	});

	useEffect(() => {
		// Tämä lähetetään parentille, joka ohjaa edit nappuloita ja niiden disablointia
		const order = getInputData<IOrder>(inputs, false);
		const isValid = validateInputs(inputs);
		order.startDateHours = parseFloat(order.startTime.split(":")[0]);
		order.startDateMinutes = parseFloat(order.startTime.split(":")[1]);
		onUpdate(order, isValid);
	}, [inputs, onUpdate]);

	let createInput = useCreateInput(inputs, setInputs);

	return (
		<Container>
			{loading ? (
				<Spinner />
			) : (
				<>
					<Heading>{t(ETranslation.COMMON_ADD_NEW)}</Heading>
					{createInput(EInputs.status)}
					{createInput(EInputs.createdDateTime)}
					{createInput(EInputs.customerFirstName)}
					{createInput(EInputs.customerLastName)}
					{createInput(EInputs.customerPhoneNumber)}
					{createInput(EInputs.customerEmail)}
					{createInput(EInputs.customerNotes)}
					{createInput(EInputs.customerOrganizationName)}
					{createInput(EInputs.startDate)}
					{createInput(EInputs.startTime)}
					{createInput(EInputs.fromLanguage, {options: languageOptions})}
					{createInput(EInputs.toLanguage, {options: languageOptions})}
					{createInput(EInputs.durationInMinutes)}
					{order?.type === EInterpretationType.ON_SITE && (
						<Fieldset label="Sijainti">
							{createInput(EInputs.address)}
							<InputGroup>
								{createInput(EInputs.zip)}
								{createInput(EInputs.city)}
							</InputGroup>
							{createInput(EInputs.info)}
						</Fieldset>
					) }

					{order?.id && (
						<div>
							{order.paymentId || order.paymentStatus ? (
								updatePaymentStatusLoading ? (
									<Spinner />
								) : updatePaymentStatusError ? (
									<Alert>{updatePaymentStatusError}</Alert>
								) : (
									<>
										<p style={{ fontWeight: "bold" }}>
											{t(
												ETranslation.INTERPRETATION_PAYMENT_DETAILS_TITLE
											)}
										</p>
										<p>
											<OrderPaymentStatus
												status={order.paymentStatus}
											/>
										</p>
										<a
											href={order?.paymentId ? `${payUrl}/payments/${order?.paymentId}` : `${payUrl}/order-payments/${order.id}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											{t(
												ETranslation.INTERPRETATION_PAYMENT_DETAILS_TEXT
											)}
										</a>
									</>
								)
							) : (
								<>
									<p style={{ fontWeight: "bold" }}>
										{t(
											ETranslation.INTERPRETATION_EXPORT_TO_YOUPRET_PAY_TITLE
										)}
									</p>
									{exportToYoupretPayError && (
										<Alert>{exportToYoupretPayError}</Alert>
									)}
									<Button
										onClick={onExport}
										loading={exportToYoupretPayLoading}
									>
										{t(
											ETranslation.INTERPRETATION_EXPORT_TO_YOUPRET_PAY_TEXT
										)}
									</Button>
								</>
							)}
						</div>
					)}
				</>
			)}
		</Container>
	);
};

export default Order;
