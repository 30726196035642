import React from "react";
import { ETranslation } from "../../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import Button from "../../ui/Button/Button";
import UserFavouriteInterpreter, { IUserFavouriteInterpreter } from "./UserFavouriteInterpreter";

interface IProps {
	favourites: IUserFavouriteInterpreter[],
	setFavouriteInterpretes: (value: React.SetStateAction<IUserFavouriteInterpreter[]>) => void
}

const UserFavouriteWorkersList: React.FC<IProps> = ({ favourites, setFavouriteInterpretes }) => {
	const { t } = useTranslation();

	const moveFavourite = (favorite: IUserFavouriteInterpreter, movement: string) => {
		const updatetut = favourites.map((itemi) => {
			if (favorite.id !== itemi.id) {
				// Collision prevention. When we move to a new priority spot, we need to move the interpreter in that position out of the way
				if (favorite.priority === itemi.priority) {
					if (movement === "up") {
						itemi.priority++;
					} else if (movement === "down") {
						itemi.priority--;
					}
				}
				return itemi;
			} else {
				// Make sure we dont go out of bounds. Top priority is number 1 and last one is from the length
				if (favorite.priority > favourites.length) {
					favorite.priority = favourites.length;
				} else if (favorite.priority < 1) {
					favorite.priority = 1;
				}
				return favorite;
			}
		})
		setFavouriteInterpretes(updatetut);
	}
	// Remove item and fix priorities
	const onDelete = (item: IUserFavouriteInterpreter) => {
		const updatedList = favourites.filter((itemi) => { return itemi.id !== item.id }).map((itemi) => {
			if (itemi.priority > item.priority) {
				itemi.priority--;
			}
			return itemi;
		})
		setFavouriteInterpretes(updatedList);
	}

	const sortter = (a: IUserFavouriteInterpreter, b: IUserFavouriteInterpreter) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0);

	const addNewItem = () => {
		setFavouriteInterpretes([
			...favourites,
			{
				id: (favourites.length + 1).toString(),
				priority: favourites.length + 1,
				user: null,
			}
		])
	};

	return <>
		<Button onClick={addNewItem}>{t(ETranslation.USER_ADD_FAVOURITE_INTERPRETER)}</Button>
		{favourites.sort(sortter).map((item) => {
			return <UserFavouriteInterpreter key={item.id} item={item} onUpdate={moveFavourite} onDelete={()=>onDelete(item)} />
		})}
	</>
}

export default UserFavouriteWorkersList;