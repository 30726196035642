import { TFunction } from "i18next";
import { IOption } from "../components/ui/Input/Input";
import { ETranslation } from "../translations/translation-keys";

export enum ELanguages {
	ARAMAIC = "arc",
	ALBANIAN = "sq",
	AMHARIC = "am",
	ARABIC = "ar",
	ARABIC_EGYPT = "ar_eg", // ar Arabic eg = Egypt
	ARABIC_IRAQ = "ar_iq", // ar Arabic iq = Iraq
	ARABIC_NORTH_AFRICA = "ar_ana", // ar Arabic ana = Area North Africa
	ARABIC_SUDAN = "ar_sd", // ar Arabic su = Sudan
	ARABIC_SYRIA = "ar_sy", // ar Arabic sy = Syria
	ARABIC_YEMEN = "ar_ye", // ar Arabic ye = Yemen
	AZERBAIJANI = "az",
	BENGALI = "bn",
	BERBER = "ber",
	BOSNIAN = "bs",
	BULGARIAN = "bg",
	BURMESE = "bur",
	CANTONESE = "yue",
	CATALAN = "ca",
	CZECH = "cs",
	CHINESE = "zh",
	CROATIAN = "hr",
	DARI = "prs",
	DUTCH = "nl",
	ENGLISH = "en",
	ESTONIAN = "et",
	FINNISH = "fi", // getValue() returns "fi" toString() returns "FINNISH"
	FRENCH = "fr",
	GERMAN = "de",
	GREEK = "el",
	HINDI = "hi",
	HUNGARIAN = "hu",
	ITALIAN = "it",
	JAPANESE = "ja",
	KARENNI = "eku",
	KHMER = "km", //added A 1.2020 
	KIGONGO_KITUBA = "ktu",
	KOREAN = "ko",
	KINYARUANDA = "rw",
	KURDISH_GORANI = "hac",
	KURDISH_KURMANJI = "kmr",
	KURDISH_SORANI = "ckb",
	KURDISH_BADINI = "sedh",
	KURDISH_FEYLI = "sdhf",
	KURDISH_LAKI = "lki",
	KURDISH_KALHORI = "sdhk",
	KURDISH_KERMANSHASI = "sdhs",
	LARI = "lrl",
	LINGALA = "ln",
	LATVIAN = "lv",
	LITHUANIAN = "lt",
	MACEDONIAN = "mk",
	MARI = "chm",
	NEPALI = "ne",
	PADAUNG = "pdu",
	PASHTO = "ps",
	PERSIAN_FARSI = "fas",
	POLISH = "pl",
	PORTUGUESE = "pt",
	PUNJABI = "pa",
	ROMANIAN = "ro",
	RUSSIAN = "ru",
	SERBIAN = "sr",
	SLOVAK = "sk",
	SOMALI = "so",
	SPANISH = "es",
	SWAHILI = "sw",
	SWEDEN = "sv",
	TAJIKI = "tgk",
	THAI = "th",
	TIGRINYA = "ti",
	TURKISH = "tr",
	TURKMEN = "tk",
	UYGHUR = "ug",
	UKRAINIAN = "uk",
	URDU = "ur",
	VIETNAMESE = "vi",
	TEST_SPANISH = "es_yp",
	TEST_ENGLISH = "en_yp",
	TEST_RUSSIAN = "ru_yp",
	TEST_FINNISH = "fi_yp",
	TEST_ARABIC = "ar_yp"
}


export const LANGUAGES: IOption[] = [
	{ value: ELanguages.ENGLISH, label: "Englanti" },
	{ value: ELanguages.FINNISH, label: "Suomi" },
	{ value: ELanguages.ALBANIAN, label: "Albania" },
	{ value: ELanguages.AMHARIC, label: "Amhara" },
	{ value: ELanguages.ARAMAIC, label: "Aramea" },
	{ value: ELanguages.ARABIC, label: "Arabia" },
	{ value: ELanguages.ARABIC_EGYPT, label: "Arabia (Egypti)" },
	{ value: ELanguages.ARABIC_IRAQ, label: "Arabia (Irak)" },
	{ value: ELanguages.ARABIC_NORTH_AFRICA, label: "Arabia (Pohjois-Afrikka) " },
	{ value: ELanguages.ARABIC_SUDAN, label: "Arabia (Sudan)" },
	{ value: ELanguages.ARABIC_SYRIA, label: "Arabia (Syyria)" },
	{ value: ELanguages.ARABIC_YEMEN, label: "Arabia (Jemen)" },
	{ value: ELanguages.BENGALI, label: "Bengali" },
	{ value: ELanguages.BERBER, label: "Berberi" },
	{ value: ELanguages.BOSNIAN, label: "Bosnia" },
	{ value: ELanguages.CROATIAN, label: "Kroaatti" },
	{ value: ELanguages.DUTCH, label: "Hollanti" },
	{ value: ELanguages.ESTONIAN, label: "Viro" },
	{ value: ELanguages.FRENCH, label: "Ranska" },
	{ value: ELanguages.MACEDONIAN, label: "Makedonia" },
	{ value: ELanguages.NEPALI, label: "Nepali" },
	{ value: ELanguages.KURDISH_GORANI, label: "Kurdi (Gorani)" },
	{ value: ELanguages.KURDISH_KURMANJI, label: "Kurdi (Kurmandži)" },
	{ value: ELanguages.KURDISH_SORANI, label: "Kurdi (Sorani)" },
	{ value: ELanguages.KURDISH_BADINI, label: "Kurdi (Badini)" },
	{ value: ELanguages.DARI, label: "Dari" },
	{ value: ELanguages.PERSIAN_FARSI, label: "Persia/Farsi" },
	{ value: ELanguages.PUNJABI, label: "Punjabi" },
	{ value: ELanguages.RUSSIAN, label: "Venäjä" },
	{ value: ELanguages.SERBIAN, label: "Serbia" },
	{ value: ELanguages.SOMALI, label: "Somali" },
	{ value: ELanguages.SWAHILI, label: "Swahili" },
	{ value: ELanguages.TIGRINYA, label: "Tigrinja" },
	{ value: ELanguages.TURKMEN, label: "Turkmeeni" },
	{ value: ELanguages.UYGHUR, label: "Uiguuri" },
	{ value: ELanguages.UKRAINIAN, label: "Ukraina" },
	{ value: ELanguages.URDU, label: "Urdu" },
	{ value: ELanguages.VIETNAMESE, label: "Vietnam" },
	{ value: ELanguages.PASHTO, label: "Pashto" },
	{ value: ELanguages.BURMESE, label: "Burma" },
	{ value: ELanguages.ROMANIAN, label: "Romania" },
	{ value: ELanguages.KARENNI, label: "Karenni (Kayah)" },
	{ value: ELanguages.PADAUNG, label: "Padaung Karen (Kayan)" },
	{ value: ELanguages.CANTONESE, label: "Kantoninkiina" },
	{ value: ELanguages.HUNGARIAN, label: "Unkari" },
	{ value: ELanguages.LINGALA, label: "Lingala" },
	{ value: ELanguages.KINYARUANDA, label: "Kinjaruanda" },
	{ value: ELanguages.AZERBAIJANI, label: "Azeri" },
	{ value: ELanguages.KIGONGO_KITUBA, label: "Kikongo/Kituba" },
	{ value: ELanguages.KOREAN, label: "Korea" },
	{ value: ELanguages.LARI, label: "Laari" },
	{ value: ELanguages.TAJIKI, label: "Tadžikki" },
	{ value: ELanguages.KURDISH_FEYLI, label: "Kurdi (Feili)" },
	{ value: ELanguages.KURDISH_LAKI, label: "Kurdi (Laki)" },
	{ value: ELanguages.KURDISH_KALHORI, label: "Kurdi (Kalhori)" },
	{ value: ELanguages.KURDISH_KERMANSHASI, label: "Kurdi (Kermanshahi)" },
	{ value: ELanguages.SWEDEN, label: "Ruotsi" },
	{ value: ELanguages.BULGARIAN, label: "Bulgaria" },
	{ value: ELanguages.CATALAN, label: "Katalaani" },
	{ value: ELanguages.CHINESE, label: "Kiina" },
	{ value: ELanguages.GERMAN, label: "Saksa" },
	{ value: ELanguages.GREEK, label: "Kreikka" },
	{ value: ELanguages.HINDI, label: "Hindi" },
	{ value: ELanguages.ITALIAN, label: "Italia" },
	{ value: ELanguages.JAPANESE, label: "Japani" },
	{ value: ELanguages.POLISH, label: "Puola" },
	{ value: ELanguages.PORTUGUESE, label: "Portugali" },
	{ value: ELanguages.SPANISH, label: "Espanja" },
	{ value: ELanguages.THAI, label: "Thai" },
	{ value: ELanguages.TURKISH, label: "Turkki" },
	{ value: ELanguages.KHMER, label: "Khmer" },
	{ value: ELanguages.LATVIAN, label: "Latvia" },
	{ value: ELanguages.LITHUANIAN, label: "Liettua" },
	{ value: ELanguages.CZECH, label: "Tšekki" },
	{ value: ELanguages.MARI, label: "Mari (tšeremissi)" },
	{ value: ELanguages.SLOVAK, label: "Slovakki" },
]

const TEST_LANGUAGES: IOption[] = [
	{ value: ELanguages.TEST_SPANISH, label: "Test Spanish" },
	{ value: ELanguages.TEST_ENGLISH, label: "Test English" },
	{ value: ELanguages.TEST_RUSSIAN, label: "Test Russian" },
	{ value: ELanguages.TEST_FINNISH, label: "Test Finnish" },
	{ value: ELanguages.TEST_ARABIC, label: "Test Arabic" },
]

export const fromLanguageFiltered = (allowTestLanguages: boolean): IOption[] => {
	let languages = LANGUAGES.filter((language) =>
		language.value === ELanguages.FINNISH ||
		language.value === ELanguages.SWEDEN
	);
	if (allowTestLanguages) languages = [...languages, ...TEST_LANGUAGES]
	return languages;
}

export const contactOptionsLanguages = (): IOption[] => {
	let languages = LANGUAGES.filter((language) =>
		language.value === ELanguages.FINNISH ||
		language.value === ELanguages.SWEDEN ||
		language.value === ELanguages.ENGLISH
	);
	return languages;
}

export const ALL_LANGUAGES = (allowTestLanguages: boolean): IOption[] => {
	let languages = LANGUAGES;
	if (allowTestLanguages) languages = [...languages, ...TEST_LANGUAGES]
	return languages;
}

export enum ELanguageStatus {
	AVAILABLE = 'AVAILABLE',
	NOT_AVAILABLE = 'NOT_AVAILABLE',
	RESERVED = 'RESERVED'
};
export const LANGUAGE_STATUS: IOption[] = [
	{ value: ELanguageStatus.AVAILABLE, labelTranslation: ETranslation.COMMON_AVAILABLE },
	{ value: ELanguageStatus.NOT_AVAILABLE, labelTranslation: ETranslation.COMMON_NOT_AVAILABLE },
	{ value: ELanguageStatus.RESERVED, labelTranslation: ETranslation.COMMON_RESERVED },
];

export const getLanguageStatusString = (
	t: TFunction, status: string
): string => t(LANGUAGE_STATUS.find(item => item.value === status)?.labelTranslation || "");