import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ILanguage } from "../../../interfaces/ILanguage";
import { ETranslation } from "../../../translations/translation-keys";
import Alert from "../../ui/Alert/Alert";
import Input, { EInputType, TInputValue } from "../../ui/Input/Input";
import Spinner from "../../ui/Spinner/Spinner";
import Table from "../../ui/Table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

interface IProps {
	languages: ILanguage[] | null;
	loading: boolean;
	error: string | null;
	onClick: (id: string) => void;
}

const LanguageList: React.FC<IProps> = ({
	languages,
	loading,
	error,
	onClick,
}) => {
	const [filter, setFilter] = useState("");
	const { t } = useTranslation();
	if (error) {
		return <Alert>{error}</Alert>;
	}

	if (!languages || loading) {
		return <Spinner />;
	}

	if (languages.length === 0) {
		return <p>{t(ETranslation.LANGUAGE_NO_LANGUAGES)}</p>;
	}

	const itemFilter = (item: ILanguage): boolean => {
		return (
			filter.length === 0 ||
			item.name?.toLowerCase().indexOf(filter.toLowerCase()) !== -1
		);
	};

	return (
		<>
			<Input
				containerStyles={{ margin: "1rem 0" }}
				type={EInputType.text}
				inputName="filter"
				value={filter}
				onChange={(value: TInputValue) => setFilter(value as string)}
				placeholderTranslation={ETranslation.COMMON_FILTER_BY_NAME}
			/>

			<Table hover={true}>
				<thead>
					<tr>
						<th>{t(ETranslation.COMMON_NAME)}</th>
						<th>{t(ETranslation.LANGUAGE_LANGUAGE_CODE)}</th>
						<th>{t(ETranslation.SITE_ACTIVE)}</th>
					</tr>
				</thead>
				<tbody>
					{languages.filter(itemFilter).map((language) => (
						<tr
							key={language.id}
							onClick={() => onClick(language.id)}
						>
							<td>{language.name}</td>
							<td>{language.languageCode}</td>
							<td>
								{language.active && (
									<FontAwesomeIcon icon={faCheckCircle} />
								)}
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</>
	);
};

export default LanguageList;
