import React, {  } from "react";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";
import classes from "./OrderButtons.module.scss"
import Button, { EButtonColor } from "../Button/Button";

interface IProps {
  onClear?: () => void;
  onClearText?: string;
  onSend?: () => void;
  onSendText?: string;
  addNew?: () => void;
  addNewText?: string;
  ordersAmount?: number;
  loading?: boolean;
  disabled?: boolean;
}

const OrderButtons: React.FC<IProps> = ({
  onClear,
  onClearText,
  onSend,
  onSendText,
  addNew,
  addNewText,
  ordersAmount,
  loading,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {onSend && (
        <Button
        className={classes.OrderButtonStyle}
        onClick={onSend}
        loading={loading}
        disabled={disabled}
      >
        {onSendText ? onSendText : t(ETranslation.UI_MAKE_ORDER)}
      </Button>
      )}
      {addNew && ((ordersAmount && ordersAmount < 5) || !ordersAmount) && (
        <Button
        className={classes.OrderButtonsStyle}
        onClick={addNew}
        loading={loading}
        disabled={disabled}
      >
        {addNewText ? addNewText : t(ETranslation.COMMON_ADD_NEW)}
      </Button>
      )}
      {onClear && (
        <Button onClick={onClear} color={EButtonColor.DEFAULT}>
        {onClearText ? onClearText : t(ETranslation.COMMON_CLEAR)}
        </Button>
      )}

    </>
  );
};

export default OrderButtons;
