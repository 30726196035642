import { useAttributes } from './../components/Attributes/useAttributes';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { IOption } from '../components/ui/Input/Input';
import * as actions from '../store/actions';
import { createOptions } from '../utils/option-utils';
import { ECategoryType } from '../interfaces/ICategory';

export const useAttributeOptions = (types: ECategoryType[]) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<IOption[]>([]);


  const { attributes, loading } = useAttributes(types);

  useEffect(() => {
    if (attributes) {
      setOptions(createOptions(attributes));
    } else {
      dispatch(actions.findAttributes());
    }
  }, [attributes, dispatch]);

  return { attributeOptions: options, attributesLoading: loading };
}