import * as dateFns from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IMessageGroup, MessageGroup } from '../../../classes/MessageGroup';
import { ETranslation } from '../../../translations/translation-keys';
import Alert from '../../ui/Alert/Alert';
import Input, { EInputType, TInputValue } from '../../ui/Input/Input';
import Spinner from '../../ui/Spinner/Spinner';
import Table from '../../ui/Table/Table';

interface IProps {
  messageGroups: MessageGroup[] | null;
  loading: boolean;
  error: string | null;
  onClick: (id: string) => void;
}

const MessageGroupList: React.FC<IProps> = ({
  messageGroups,
  loading,
  error,
  onClick,
}) => {
  const [filter, setFilter] = useState("");
  const { t } = useTranslation();
  if (error) {
    return <Alert>{error}</Alert>;
  }

  if (!messageGroups || loading) {
    return <Spinner />;
  }

  if (messageGroups.length === 0) {
    return <p>{ETranslation.MESSAGE_NO_RECORDINGS_OR_TRANSLATIONS}</p>;
  }

  const filterMessageGroups = (item: IMessageGroup): boolean => {
    return (
      filter.length === 0 ||
      item.name?.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  };
  
  const formatDate = (date: string) => {
    return `${dateFns.format(new Date(date), "dd.MM.yyyy HH:mm")}`;
  }

  return (
    <>
      <Input
        containerStyles={{ margin: "1rem 0" }}
        type={EInputType.text}
        inputName="filter"
        value={filter}
        onChange={(value: TInputValue) => setFilter(value as string)}
        placeholderTranslation={ETranslation.COMMON_FILTER_BY_NAME}
      />

      <Table hover={true}>
        <thead>
          <tr>
            <th>{t(ETranslation.COMMON_CREATION)}</th>
            <th>{t(ETranslation.COMMON_NAME)}</th>
            <th>{t(ETranslation.COMMON_TYPE)}</th>
            <th>{t(ETranslation.MESSAGE_MESSAGES)}</th>
            <th>{t(ETranslation.COMMON_ORGANIZATIONS)}</th>
          </tr>
        </thead>
        <tbody>
          {messageGroups
            .filter(filterMessageGroups)
            .map((messageGroup, index) => (
              <tr
                key={messageGroup.id}
                onClick={() => onClick(messageGroup.id)}
              >
                <td>{formatDate(messageGroup.created)}</td>
                <td>{messageGroup.name}</td>
                <td>{messageGroup.getTypeString(t)}</td>
                <td>{messageGroup.amountMessages && `${messageGroup.amountMessages} kpl`}</td>
                <td>{messageGroup.organizations?.map(organization => organization.name).join(', ')}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default MessageGroupList;
