import React from "react";
import { useTranslation } from "react-i18next";
import { IOrder } from "../../store/actions/ordersActions";
import Table from "../ui/Table/Table";
import { ETranslation } from "../../translations/translation-keys";
import OrderRowItem from "./OrderRowItem";

interface IProps {
	orders: IOrder[];
	openOrder: (order: IOrder) => void;
}

const OrdersList: React.FC<IProps> = ({ orders, openOrder }) => {
	const { t } = useTranslation();

	return <Table hover={true}>
		<thead>
			<tr>
				<th>{t(ETranslation.COMMON_STATUS)}</th>
				<th>{t(ETranslation.COMMON_CREATION)}</th>
				<th>{t(ETranslation.COMMON_CUSTOMER)}</th>
				<th>{t(ETranslation.COMMON_FIRST_NAME)}</th>
				<th>{t(ETranslation.COMMON_LAST_NAME)}</th>
				<th>{t(ETranslation.COMMON_PHONENUMBER)}</th>
				<th>{t(ETranslation.COMMON_EMAIL)}</th>
				<th>{t(ETranslation.COMMON_ADDITIONAL_INFO)}</th>
				<th>{t(ETranslation.ORDERS_ORGANIZATION)}</th>
				<th>{t(ETranslation.COMMON_START_DATE)}</th>
				<th>{t(ETranslation.COMMON_FROM_LANGUAGE)}</th>
				<th>{t(ETranslation.COMMON_TO_LANGUAGE)}</th>
				<th>{t(ETranslation.COMMON_DURATION_IN_MINUTES)}</th>
			</tr>
		</thead>
		<tbody>
			{orders &&
				orders.map((order) => <OrderRowItem key={order.id} order={order} openOrder={openOrder} />)}
		</tbody>
	</Table>
};

export default OrdersList;
