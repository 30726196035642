import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute } from '../../../classes/Routes';
import Heading from '../../../components/Heading/Heading';
import Site from '../../../components/Site/Site';
import Alert, { EAlertColor } from '../../../components/ui/Alert/Alert';
import Container from '../../../components/ui/Container/Container';
import EditButtons from '../../../components/ui/EditButtons/EditButtons';
import Spinner from '../../../components/ui/Spinner/Spinner';
import { ISite } from '../../../interfaces/ISite';
import IAppState from '../../../interfaces/store/IAppState';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';
import classes from './SiteEditPage.module.scss';
import { NetvisorSettingsForm } from '../../../components/NetvisorSettings/NetvisorSettingsForm';

interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

const SiteEditPage: React.FC<IProps> = ({ match, history }) => {
	const [editSite, setEditSite] = useState<ISite | null>(null);
	const [isValid, setIsValid] = useState(false);
	const [showValidation, setShowValidation] = useState(false);

	const dispatch = useDispatch();

	const { id } = match.params;
	const isAdd = id === "add";

	const { t } = useTranslation();

	useEffect(() => {
		if (!isAdd) {
			dispatch(actions.getSite(id));
		}
	}, [isAdd, id, dispatch]);

	const {
		error,
		loading,
		saveOrUpdateOk,
		site,
		deleteError,
		deleteLoading,
		deleteOk,
	} = useSelector((state: IAppState) => ({
		error: state.site.siteError || state.site.siteSaveOrUpdateError,
		loading: state.site.siteLoading || state.site.siteSaveOrUpdateLoading,
		saveOrUpdateOk: state.site.siteSaveOrUpdateOk,
		site: state.site.site,
		deleteLoading: state.site.siteDeleteLoading,
		deleteError: state.site.siteDeleteError,
		deleteOk: state.site.siteDeleteOk,
	}));

	useEffect(() => {
		if (saveOrUpdateOk) {
			history.push(ERoute.SITES_LIST);
		}
		return () => {
			dispatch(actions.saveOrUpdateSiteClear());
			dispatch(actions.getSiteClear());
			dispatch(actions.deleteSiteClear());
		};
	}, [dispatch, saveOrUpdateOk, history]);

	const updateHandler = useCallback((site: ISite, isValid: boolean) => {
		setEditSite(site);
		setIsValid(isValid);
	}, []);

	const submitHandler = () => {
		if (!editSite) return;

		if (isAdd) {
			dispatch(actions.saveSite(editSite));
		} else {
			editSite.id = id;
			dispatch(actions.updateSite(editSite));
		}
	};


	/*
	const deleteHandler = () => {
	  if (site) {
		dispatch(actions.deleteSite(site.id));
	  }
	};
	*/

	const helpHandler = () => {
		console.log("help icon clicked");
	};

	return (
		<Container>
			{error && <Alert>{error}</Alert>}
			{loading && <Spinner centerAbsolute />}
			<Heading className={classes.Heading}>
				{t(ETranslation.COMMON_SITE)}
				<span onClick={helpHandler} className={classes.HelpIcon}>
					<FontAwesomeIcon icon={faInfo} />
				</span>
			</Heading>
			<Alert color={EAlertColor.INFO}>
				{t(ETranslation.PAGES_SITE_EDIT_INFO)}
			</Alert>
			<Site
				onChange={updateHandler}
				loading={loading}
				site={site}
				showValidation={showValidation}
			/>
			{site && <NetvisorSettingsForm site={site} />}
			{/*      onDelete={deleteHandler} */}
			<EditButtons
				onSave={isValid ? submitHandler : () => setShowValidation(true)}
				disabled={showValidation && !isValid}
				loading={loading}
				onCancel={() => history.goBack()}
				deleteText={ETranslation.PAGES_SITE_EDIT_DELETE_CONFIRM}
				onDeleteClose={() => dispatch(actions.deleteSiteClear())}
				onDeleteDone={() => history.push(ERoute.SITES_LIST)}
				deleteError={deleteError}
				deleteLoading={deleteLoading}
				deleteOk={deleteOk}
				isAdd={isAdd}
			/>
		</Container>
	);
};

export default SiteEditPage;
