
import React from "react";
import { ETranslation } from "../../translations/translation-keys";
import Table from "../ui/Table/Table";
import Spinner from "../ui/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import { IComment } from "../../pages/UsersPage/UserComments";
import * as dateFns from 'date-fns';
import { IUser } from "../../classes/User";

interface IProps {
	comments: IComment[] | null;
	loading: boolean;
	error: string | null | undefined;
	openComments: (user: IUser, openModalOnClose: boolean) => void;
}

const CommentListTable: React.FC<IProps> = ({ comments, loading, error, openComments }) => {

	const { t } = useTranslation();

	return (
		loading ?
			<Spinner />
			:
			error ?
				<div>{error}</div>
				:
				<Table hover={true} >
					<thead>
						<tr>
							<th>{t(ETranslation.COMMENTS_COMMENTED)}</th>
							<th>{t(ETranslation.COMMON_USER)}</th>
							<th>{t(ETranslation.COMMON_ORGANIZATION)}</th>
							<th>{t(ETranslation.COMMENTS_COMMENTER)}</th>
							<th>{t(ETranslation.COMMENTS_COMMENT_GENERAL)}</th>
						</tr>
					</thead>
					<tbody>
						{comments && comments.map((comment, id) => {
							return (
								<tr key={id}  onClick={() => {
									openComments(comment.user, false);
								}} >
									<td>{dateFns.format(new Date(comment.created), "dd.MM.yyyy HH:mm")}</td>
									<td>{comment.user.firstName + " " + comment.user.lastName}</td>
									<td>{comment.organization?.name}</td>
									<td>{comment.createdBy.firstName + " " + comment.createdBy.lastName}</td>
									<td>{comment.commentText.includes("\n") ? comment.commentText.split("\n").map((string) => { return [string, <br />] }) : comment.commentText}</td>
								</tr>
							)
						})}
					</tbody>
				</Table>
	)

}

export default CommentListTable;