import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ITranslation } from "../../interfaces/ITranslation";
import { ETranslation } from "../../translations/translation-keys";
import ModalContext, { EModalSize } from "../ui/Modal/ModalContext";
import TranslationsManageModal from "./TranslationsManageModal";

export const useTranslationManageModal = (translations: ITranslation[]) => {
	const [openFunction, setOpenFunction] = useState<((translations: ITranslation[]) => void) | null>(null);
	const { setModal } = useContext(ModalContext);
	const { t } = useTranslation();

	const initModal = useCallback(
		(
			onSave: (translation: ITranslation) => void,
			onEdit: (translation: ITranslation) => void,
			onDelete: (id: string, langCode: string) => void
		) => {
			const openFunc = (translations: ITranslation[]) => {
				setModal({
					isOpen: true,
					content: (
						<TranslationsManageModal
							onEdit={onEdit}
							onDelete={onDelete}
							onSave={onSave}
							translations={translations}
						/>
					),
					onModalClose: () => {
						setOpenFunction(null);
					},
					size: EModalSize.MEDIUM,
					title: t(ETranslation.LANGUAGE_EDIT_TRANSLATIONS),
				});
			}
			setOpenFunction(() => openFunc)
		},
		[setModal, t]
	);

	useEffect(() => {
		if (openFunction && translations) {
			openFunction(translations);
		}
	}, [openFunction, translations]);

	return initModal;
};
