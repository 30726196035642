import EActionTypes from '../../interfaces/store/EActionTypes';
import INetvisorSettingsAction from '../../interfaces/store/INetvisorSettingsAction';
import INetvisorSettingsState from '../../interfaces/store/INetvisorSettingsState';
import { ETranslation } from '../../translations/translation-keys';

type ReducerAction = INetvisorSettingsAction;
type ReducerState = INetvisorSettingsState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
	netvisorSettingsSaveLoading: false,
	netvisorSettingsSaveError: null,
	netvisorSettingsSaveDone: false,
	netvisorSettingsGetLoading: false,
	netvisorSettingsGetError: null,
	netvisorSettingsGetDone: false,
	netvisorSettings: null,
};

const saveSettingsStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, netvisorSettingsSaveError: null, netvisorSettingsSaveLoading: true, netvisorSettingsSaveDone: false };
};

const saveSettingsSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, netvisorSettingsSaveError: null, netvisorSettingsSaveLoading: false, netvisorSettingsSaveDone: true };
};

const saveSettingsFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, netvisorSettingsSaveError: action.error || ETranslation.REDUCER_SEARCH_ERROR, netvisorSettingsSaveLoading: false, netvisorSettingsSaveDone: true };
};

const saveSettingsClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, netvisorSettingsSaveError: null, netvisorSettingsSaveLoading: false, netvisorSettingsSaveDone: false };
};

const getSettingsStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, netvisorSettings: null, netvisorSettingsGetError: null, netvisorSettingsGetLoading: true, netvisorSettingsGetDone: false };
};

const getSettingsSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, netvisorSettings: action.netvisorSettings ?? null, netvisorSettingsGetError: null, netvisorSettingsGetLoading: false, netvisorSettingsGetDone: true };
};

const getSettingsFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, netvisorSettings: null, netvisorSettingsGetError: action.error || ETranslation.REDUCER_SEARCH_ERROR, netvisorSettingsGetLoading: false, netvisorSettingsGetDone: true };
};

const getSettingsClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, netvisorSettings: null, netvisorSettingsGetError: null, netvisorSettingsGetLoading: false, netvisorSettingsGetDone: false };
};

const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
	switch (action.type) {
		case EActionTypes.NETVISOR_SETTINGS_SAVE_START:
			return saveSettingsStart(state, action);
		case EActionTypes.NETVISOR_SETTINGS_SAVE_SUCCESS:
			return saveSettingsSuccess(state, action);
		case EActionTypes.NETVISOR_SETTINGS_SAVE_FAIL:
			return saveSettingsFail(state, action);
		case EActionTypes.NETVISOR_SETTINGS_SAVE_CLEAR:
			return saveSettingsClear(state, action);
		case EActionTypes.NETVISOR_SETTINGS_GET_START:
			return getSettingsStart(state, action);
		case EActionTypes.NETVISOR_SETTINGS_GET_SUCCESS:
			return getSettingsSuccess(state, action);
		case EActionTypes.NETVISOR_SETTINGS_GET_FAIL:
			return getSettingsFail(state, action);
		case EActionTypes.NETVISOR_SETTINGS_GET_CLEAR:
			return getSettingsClear(state, action);
		default:
			return state;
	}
};

export default reducer;
