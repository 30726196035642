import React from "react";

import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom";
import { ERoute } from "./classes/Routes";
import { User } from "./classes/User";

interface IProps extends RouteProps {
  user: User;
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const GuardedRoute: React.FC<IProps> = ({ component, user, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      user.routes.includes(rest.path as ERoute) ? (
        React.createElement(component, props)
      ) : (
        <Redirect to={user.routes[0]} />
      )
    }
  />
);

export default GuardedRoute;
