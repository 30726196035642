import React, { useEffect, useState } from "react";

import { useCreateInput } from "../../hooks/useCreateInput";
import { IUserShiftDateItem } from "../../interfaces/IUserShiftDateItem";
import { WORKING_HOURS_EVENT_TYPE_OPTIONS } from "../../shared/working-hours-data";
import { ETranslation } from "../../translations/translation-keys";
import Button, { EButtonColor, EButtonSize } from "../ui/Button/Button";
import { EInputType, IInputField } from "../ui/Input/Input";
import { getInputData, validateInputs } from "../ui/Input/input-utils";
import InputGroup from "../ui/InputGroup/InputGroup";
import { EWorkingHoursDateEventType } from "../../interfaces/IWorkingHoursDate";
import { isWorkingHoursEventHoursType } from "../../utils/working-hours-utils";

enum EInputs {
	type = "type",
	startTime = "startTime",
	endTime = "endTime",
	hours = "hours",
}

interface IProps {
	index?: number;
	onChange: (item: IUserShiftDateItem, isValid: boolean, index?: number) => void;
	onRemove: (id: string) => void;
	item: IUserShiftDateItem;
	loading?: boolean;
	showValidation?: boolean;
}

const UserShiftDateItemEdit: React.FC<IProps> = ({ onChange, index, item, loading, showValidation, onRemove }) => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.type]: {
			type: EInputType.select,
			label: "Tyyppi",
			value: item.type,
			options: WORKING_HOURS_EVENT_TYPE_OPTIONS,
			validation: {
				required: true,
			},
		},
		[EInputs.startTime]: {
			type: EInputType.time,
			labelTranslation: ETranslation.COMMON_START_TIME,
			value: item.startTime,
			validation: {
				required: false,
			},
		},
		[EInputs.endTime]: {
			type: EInputType.time,
			labelTranslation: ETranslation.COMMON_END_TIME,
			value: item.endTime,
			validation: {
				required: false,
			},
		},
		[EInputs.hours]: {
			type: EInputType.number,
			label: "Tunnit",
			value: item.hours,
			validation: {
				required: false,
			},
		},
	});

	const id = item.id;

	useEffect(() => {
		const item = getInputData<IUserShiftDateItem>(inputs);
		item.id = id;
		const isValid = validateInputs(inputs);
		onChange(item, isValid, index);
	}, [inputs, onChange, index, id]);

	const createInput = useCreateInput(inputs, setInputs, {
		showValidation,
		disabled: loading,
	});

	const type = inputs[EInputs.type].value as EWorkingHoursDateEventType;


	return (
		<>
			<InputGroup>
				{createInput(EInputs.type)}
				{isWorkingHoursEventHoursType(type) ? (
					createInput(EInputs.hours)
				) : (
					<>
						{createInput(EInputs.startTime)}
						{createInput(EInputs.endTime)}
					</>
				)}
				<Button onClick={() => onRemove(id)} size={EButtonSize.SMALL} color={EButtonColor.DANGER}>
					Poista
				</Button>
			</InputGroup>
		</>
	);
};

export default UserShiftDateItemEdit;
