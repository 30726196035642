import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';
import { IEezyTrusterEvent } from '../../../interfaces/IEezyTruster';
import classes from "./EezyTrusterEvent.module.scss"
import EezyTrusterPayment from './EezyTrusterPayment';
import { useDispatch, useSelector } from 'react-redux';
import { getEventClear, sendEezyTrusterEvent } from '../../../store/actions/eezyTrusterActions';
import Button, { EButtonColor } from '../../ui/Button/Button';
import IAppState from '../../../interfaces/store/IAppState';
import { EEezyTrusterPaymentStatus } from '../../../shared/eezy-truster-data';
import EezyTrusterEventStatus from '../EezyStatusBadge/EezyTrusterEventStatus';

interface IProps {
	event: IEezyTrusterEvent;
	goBack: () => void;
}

const EezyTrusterEvent: React.FC<IProps> = ({ event, goBack }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch();

	const sendEvent = () => {
		dispatch(sendEezyTrusterEvent(event.id));
	}

	const { sendEventLoading } = useSelector((state: IAppState) => ({
		sendEventLoading: state.eezyTruster.sendEventLoading,
	}));

	const [eventStatus, setEventStatus] = useState<EEezyTrusterPaymentStatus>(EEezyTrusterPaymentStatus.NO_EVENT);

	/**
	 * Purpose of this is to loop trought payment statuses on the event to see the "lowest"
	 * and display that in the main "status". Since the final status is "sent" and comes last in alphabets, 
	 * we can just sort it and pick first! Surely it will work.
	 */
	useEffect(() => {
		if (!event.eezyPayments) return;
		const uniques: EEezyTrusterPaymentStatus[] = event.eezyPayments.map((payment) => {
			return payment.status;
		}).filter((value, index, array) => { return array.indexOf(value) === index; }).sort();
		setEventStatus(uniques[0]);
		// Clear event from reducer
		return () => {
			dispatch(getEventClear());
		}
	}, [event, dispatch])

	/**
	 * Column element for eezy payment heading
	 * @param translation Translation key for colel
	 * @param children Contents of colel
	 * @returns JSX.Element for columns
	 */
	const colElement = (translation: ETranslation, children: JSX.Element | string) => {
		return <div className={classes.infoColumn}>
			<span className={classes.infoTitle}>
				{t(translation)}
			</span>
			<span>
				{children}
			</span>
		</div>
	}

	return (
		<div className={classes.infoContainer}>
			<div className={classes.inforRow}>
				{colElement(ETranslation.COMMON_STATUS, <EezyTrusterEventStatus status={eventStatus} />)}
				{colElement(ETranslation.COMMON_CREATED, event.created)}
				{colElement(ETranslation.COMMON_SENT, event.sentDate?? "")}
				{colElement(ETranslation.COMMON_TIMESPAN, `${event.startDate} - ${event.endDate}`)}
			</div>
			<div className={classes.paymentsContainer}>
				{event.eezyPayments.map((payment, index) => {
					return <EezyTrusterPayment key={payment.id} index={index} payment={payment} type={event.type} />
				})}
			</div>
			<div className={classes.inforRow}>
				<Button color={EButtonColor.DEFAULT} onClick={goBack} loading={sendEventLoading}>{t(ETranslation.COMMON_BACK)}</Button>
				<Button color={EButtonColor.SUCCESS} onClick={sendEvent} loading={sendEventLoading}>Lähetä kaikki</Button>
			</div>
		</div>
	);
};

export default EezyTrusterEvent;
