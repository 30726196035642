import React, { useEffect } from "react";

import TwilioChatInput from "./TwilioChatInput/TwilioChatInput";
import TwilioChatMessages from "./TwilioChatMessages/TwilioChatMessages";

import classes from "./TwilioChat.module.scss";
import TwilioChatHeader from "./TwilioChatHeader/TwilioChatHeader";
import useTwilioChat from "../../hooks/useTwilioChat";

interface IProps {
  conversationToken: string;
  conversationSid: string;
}

const TwilioChat: React.FC<IProps> = ({
  conversationToken,
  conversationSid,
}) => {
  const {
    initConversation,
    isConnected,
    isConnecting,
    sendMessage,
    messages,
    messagesLoading,
  //  participant,
    otherParticipants,
    participantsLoading,
  } = useTwilioChat();

  useEffect(() => {
    initConversation(conversationToken, conversationSid);
  }, [conversationSid, initConversation, conversationToken]);

  const messageHandler = (message: string) => {
    sendMessage(message);
  };

  return (
    <div className={classes.Container}>
      <div className={classes.Wrapper}>
        <TwilioChatHeader
          isConnecting={isConnecting}
          isConnected={isConnected}
          otherParticipants={otherParticipants}
          participantsLoading={participantsLoading}
        />
        <TwilioChatMessages
          messages={messages}
          messagesLoading={messagesLoading}
        />
        <TwilioChatInput onMessage={messageHandler} isConnected={isConnected} />
      </div>
    </div>
  );
};

export default TwilioChat;
