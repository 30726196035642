import React, { useState } from 'react';

import { INewsItem } from '../../../interfaces/INewsItem';
import { getNewsItemDisplayLocationString } from '../../../shared/news-item-data';
import ActiveBadge from '../../ui/ActiveBadge/ActiveBadge';
import Alert from '../../ui/Alert/Alert';
import Input, { EInputType, TInputValue } from '../../ui/Input/Input';
import Spinner from '../../ui/Spinner/Spinner';
import Table from '../../ui/Table/Table';

import * as dateFns from 'date-fns';
import { User } from '../../../classes/User';
import { ETranslation } from '../../../translations/translation-keys';
import { useTranslation } from 'react-i18next';

interface IProps {
  newsItems: INewsItem[] | null;
  loading: boolean;
  error: string | null;
  onClick: (id: string) => void;
  user: User;
}

const NewsItemList: React.FC<IProps> = ({ newsItems, onClick, error, loading, user }) => {
  const [filter, setFilter] = useState("");
  const { t } = useTranslation();
  if (error) {
    return <Alert>{error}</Alert>;
  }

  if (!newsItems || loading) {
    return <Spinner />;
  }

  if (newsItems.length === 0) {
    return <p>{t(ETranslation.NEWS_NO_NEWS)}</p>;
  }

  const filterNewsItem = (item: INewsItem): boolean => {
    return (
      filter.length === 0 ||
      item.title?.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  };

  const formatDate = (date?: string) => {
    if (!date) return null;
    return dateFns.format(dateFns.parseISO(date), "dd.MM.yyyy HH:mm");
  };

  return (
    <>
      <Input
        containerStyles={{ margin: "1rem 0" }}
        type={EInputType.text}
        inputName="filter"
        value={filter}
        onChange={(value: TInputValue) => setFilter(value as string)}
        placeholderTranslation={ETranslation.COMMON_FILTER_BY_NAME}
      />

      <Table hover={true}>
        <thead>
          <tr>
            <th>{t(ETranslation.NEWS_HEADLINE)}</th>
            <th>{t(ETranslation.NEWS_PUBLISH_DATE)}</th>
            <th>{t(ETranslation.NEWS_SHOWN_TO_LANGUAGES)}</th>
            {user.isYoupretAdmin && (<th>{t(ETranslation.NEWS_SHOWN_IN_PLACES)}</th>)}
            <th>{t(ETranslation.COMMON_PUBLISHED)}</th>
			<th>{t(ETranslation.NEWS_REVIEW)}</th>
          </tr>
        </thead>
        <tbody>
          {newsItems.filter(filterNewsItem).map((newsItem, index) => (
            <tr key={newsItem.id} onClick={() => onClick(newsItem.id)}>
              <td>{newsItem.title}</td>
              <td>{formatDate(newsItem.publishDate)}</td>
              <td>{newsItem.displayLanguages && newsItem.displayLanguages.join(', ')}</td>
              {user.isYoupretAdmin && (<td>{getNewsItemDisplayLocationString(newsItem.displayLocation)}</td>)}
              <td>
                <ActiveBadge
                  active={newsItem.published}
                  trueValueTitle={t(ETranslation.COMMON_PUBLISHED)}
                  falseValueTitle={t(ETranslation.NEWS_NOT_PUBLISHED)}
                />
              </td>
			  <td>
                <ActiveBadge
                  active={newsItem.isReview}
                  trueValueTitle={t(ETranslation.NEWS_ISREVIEW)}
                  falseValueTitle={t(ETranslation.NEWS_ISNOTREVIEW)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default NewsItemList;
