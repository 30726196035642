import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../translations/translation-keys";
import { useSelector } from "react-redux";
import IAppState from "../../interfaces/store/IAppState";
import CommentListTable from "../../components/Comments/CommentsList";
import ModalContext, { EModalSize } from "../../components/ui/Modal/ModalContext";
import { IUser } from "../../classes/User";
import UserComments from "../UsersPage/UserComments";


const CommentsList: React.FC = () => {

	const { t } = useTranslation();

	const {
		comments,
		error,
		loading,
	} = useSelector((state: IAppState) => state.comments);

	const { setModal } = useContext(ModalContext);

	const openComments = (user: IUser) => {
		user ? setModal({
			title: "Käyttäjän " + user.firstName + " " + user.lastName + " " + t(ETranslation.COMMON_COMMENTS),
			content: <UserComments
				user={user}
			/>,
			isOpen: true,
			size: EModalSize.FULL,
		}) : console.error("No User Set");
	}

	return (
		<div>
			<CommentListTable comments={comments} loading={loading} openComments={openComments} error={error} />
		</div>
	)
}

export default CommentsList;