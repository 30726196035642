import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute, Routes } from '../../classes/Routes';
import Heading from '../../components/Heading/Heading';
import Alert from '../../components/ui/Alert/Alert';
import Container from '../../components/ui/Container/Container';
import EditButtons from '../../components/ui/EditButtons/EditButtons';
import UserShiftsDatesEdit from '../../components/UserShifts/UserShiftsDatesEdit';
import UserShiftsEdit from '../../components/UserShifts/UserShiftsEdit';
import { IUserShiftMonth } from '../../interfaces/IUserShiftMonth';
import IAppState from '../../interfaces/store/IAppState';
import IUserShiftState from '../../interfaces/store/IUserShiftState';
import * as actions from '../../store/actions';
import { formatYearMonth } from '../../utils/date-utils';

interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const UserShiftsEditPage: React.FC<IProps> = ({ history, match }) => {
	const [showValidation, setShowValidation] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [editShift, setEditShift] = useState<IUserShiftMonth | null>(null);

	const dispatch = useDispatch();

	const { id } = match.params;
	const isAdd = id === "add";

	useEffect(() => {
		if (isAdd) return;
		dispatch(actions.getUserShiftMonth(id));
	}, [isAdd, id, dispatch]);

	const { error, loading, userShift, deleting, deletingError, deletingOk } = useSelector<IAppState, IUserShiftState>(
		(state) => state.userShifts
	);

	useEffect(() => {
		if (isAdd && userShift) {
			history.push(Routes.withParams(ERoute.USER_SHIFTS_EDIT, { id: userShift.id }));
		}
	}, [userShift, isAdd, history]);

	useEffect(() => {
		return () => {
			dispatch(actions.getUserShiftMonthClear());
		};
	}, [dispatch]);

	const updateHandler = useCallback((shift: IUserShiftMonth, isValid: boolean) => {
		setEditShift(shift);
		setIsValid(isValid);
	}, []);

	const submitHandler = () => {
		if (editShift) {
			if (isAdd) {
				dispatch(actions.saveUserShiftMonth(editShift));
			}
		}
	};

	const deleteHandler = () => {
		if (userShift) {
			dispatch(actions.deleteUserShiftMonth(userShift.id));
		}
	};

	const getTitle = () => {
		if (isAdd || !userShift) {
			return "Työvuorot";
		}
		const { userName, year, month } = userShift;
		return `Työvuorot - ${userName} - ${formatYearMonth(year, month)}`;
	};



	return (
		<Container>
			{error && <Alert>{error}</Alert>}
			<Heading>{getTitle()}</Heading>
			{isAdd && <UserShiftsEdit onChange={updateHandler} showValidation={showValidation} />}
			{!isAdd && userShift && <UserShiftsDatesEdit shift={userShift} />}
			<EditButtons
				onSave={isAdd ? (isValid ? submitHandler : () => setShowValidation(true)) : undefined}
				disabled={showValidation && !isValid}
				loading={loading}
				onCancel={() => history.push(ERoute.USER_SHIFTS_LIST)}
				onDelete={deleteHandler}
				deleteText={"Haluatko varmasti poistaa kuukauden työvuorot"}
				onDeleteClose={() => dispatch(actions.deleteUserShiftMonthClear())}
				onDeleteDone={() => history.push(ERoute.USER_SHIFTS_LIST)}
				deleteError={deletingError}
				deleteLoading={deleting}
				deleteOk={deletingOk}
				isAdd={isAdd}
			/>
		</Container>
	);
};

export default UserShiftsEditPage;
