import EActionTypes from '../../interfaces/store/EActionTypes';
import IAttachmentAction from '../../interfaces/store/IAttachmentAction';
import IAttachmentState from '../../interfaces/store/IAttachmentState';
import { ETranslation } from '../../translations/translation-keys';

type ReducerState = IAttachmentState;
type ReducerSignature = (
  state: ReducerState,
  action: IAttachmentAction
) => ReducerState;

const initialState: ReducerState = {
  loading: false,
  error: null,
  attachments: null,
  attachment: null
};

const getAttachmentStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true, attachment: null };
};

const getAttachmentSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    error: null,
    loading: false,
    attachment: action.attachment || null,
  };
};

const getAttachmentFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: action.error || ETranslation.REDUCER_ERROR, attachment: null };
};

const getAttachmentClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: null, attachment: null };
};

const reducer = (
  state: ReducerState = initialState,
  action: IAttachmentAction
): ReducerState => {
  switch (action.type) {
    case EActionTypes.ATTACHMENT_GET_START:
      return getAttachmentStart(state, action);
    case EActionTypes.ATTACHMENT_GET_SUCCESS:
      return getAttachmentSuccess(state, action);
    case EActionTypes.ATTACHMENT_GET_FAIL:
      return getAttachmentFail(state, action);
    case EActionTypes.ATTACHMENT_GET_CLEAR:
      return getAttachmentClear(state, action);

    default:
      return state;
  }
};

export default reducer;
