import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { ERoute } from "../../../classes/Routes";
import HeadingBox from "../../../components/Heading/HeadingBox/HeadingBox";
import InterpretationReview from "../../../components/Interpretation/InterpretationReview/InterpretationReview";
import Alert from "../../../components/ui/Alert/Alert";
import Button from "../../../components/ui/Button/Button";
import ContainerCol from "../../../components/ui/Container/ContainerCol/ContainerCol";
import ContainerRow from "../../../components/ui/Container/ContainerRow/ContainerRow";
import Spinner from "../../../components/ui/Spinner/Spinner";
import { IInterpretationReview } from "../../../interfaces/IInterpretation";
import IAppState from "../../../interfaces/store/IAppState";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";
import classes from './InterpretationReviewPage.module.scss';

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const InterpretationReviewPage: React.FC<IProps> = ({ match, history }) => {

  const { interpretation, error, loading, interpretationReviewOk } = useSelector(
    (state: IAppState) => ({
      interpretation: state.interpretation.interpretation,
      loading: state.interpretation.loading || state.interpretation.interpretationReviewLoading,
      error: state.interpretation.error || state.interpretation.interpretationReviewError,
      interpretationReviewOk: state.interpretation.interpretationReviewOk
    })
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = match.params;

  const [
    editInterpretationReview,
    setEditInterpretationReview,
  ] = useState<IInterpretationReview | null>(null);

  useEffect(() => {
    if(id) {
      dispatch(actions.getInterpretation(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (interpretationReviewOk) {
      dispatch(actions.getInterpretationClear())
      dispatch(actions.requestInterpretationClear())
      history.push(ERoute.ORDER_INTERPRETATION);
    }

  }, [dispatch, history, interpretationReviewOk]);
  
  const updateHandler = useCallback(
    (interpretationReview: IInterpretationReview) => {
      setEditInterpretationReview(interpretationReview);
    },
    []
  );

  if (!interpretation) {
    return <Spinner />
  }
  
  const submitHandler = () => {
    if (!editInterpretationReview) return;
    editInterpretationReview.id = id
    dispatch(actions.completeInterpretationReview(editInterpretationReview));
  };


  return (
    <>
    <ContainerRow>
      <ContainerCol>
        {loading && <Spinner center/>}
        <HeadingBox>
          {t(ETranslation.COMMON_SUMMARY)}
        </HeadingBox>
        {error && <Alert>{error}</Alert>}
        <InterpretationReview 
          interpretation={interpretation} 
          onChange={updateHandler}>
        </InterpretationReview>
        <div className={classes.ContainerPadding}>
          <Button className={classes.SubmitButton} onClick={submitHandler}> {t(ETranslation.COMMON_SEND)}</Button>
        </div>
      </ContainerCol>
    </ContainerRow>
    </>
  );
};

export default InterpretationReviewPage;
