import React from 'react';

import Button from '../../Button/Button';
import classes from './RadioButtonOption.module.scss';

interface IProps {
  value: string;
  label?: string;
  isActive: boolean;
  disabled?: boolean;
  invalid?: boolean;
  onClick: (value: string) => void;
}

const RadioButtonOption: React.FC<IProps> = ({
  value,
  label,
  isActive,
  disabled,
  onClick,
  invalid,
}) => {
  const classNames = [];

  if (!isActive) {
    classNames.push(classes.InActive);
  }

  if (invalid) {
    classNames.push(classes.Invalid);
  }

  return (
    <Button
      disabled={disabled}
      onClick={(event) => onClick(value)}
      className={classNames.join(" ")}
	  style={{ marginBottom: 0 }}
    >
      {label}
    </Button>
  );
};

export default RadioButtonOption;
