import React, { useCallback, useContext } from "react";

import ModalContext, { EModalSize } from "../ui/Modal/ModalContext";
import { IAttribute } from "../../interfaces/IAttribute";
import AttributeSelectModal from "./AttributeSelectModal";
import { ECategoryType } from "../../interfaces/ICategory";

export const useAttributeSelectModal = () => {
	const { setModal, closeModal } = useContext(ModalContext);

	const handler = useCallback(
		async (existingAttributes: IAttribute[], title: string, types: ECategoryType[]) => {
			return new Promise<IAttribute>((resolve) => {
				setModal({
					isOpen: true,
					size: EModalSize.MEDIUM,
					title,
					content: (
						<AttributeSelectModal
							existingAttributes={existingAttributes}
							onAdd={(attribute) => {
								resolve(attribute);
								closeModal();
							}}
							types={types}
						/>
					),
				});
			});
		},
		[setModal, closeModal]
	);

	return handler;
};
