import { useSelector } from "react-redux";
import IAppState from "../../interfaces/store/IAppState";
import IAttributeState from "../../interfaces/store/IAttributeState";
import { useEffect, useState } from "react";
import { IAttribute } from "../../interfaces/IAttribute";
import { ECategoryType } from "../../interfaces/ICategory";



export const useAttributes = (types: ECategoryType[] ) => {
	const [stateAttributes, setStateAttributes] = useState<IAttribute[] | null>(null);
	const { attributes, loading } = useSelector<IAppState, IAttributeState>((state) => state.attribute);
	useEffect(() => {
		if (attributes) {
			setStateAttributes(attributes.filter(attr => attr.category && types.includes(attr.category.type)));
		}
		// eslint-disable-next-line
	}, [attributes]);


	return { attributes: stateAttributes, loading };
}