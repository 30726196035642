import * as dateFns from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateInput } from '../../hooks/useCreateInput';
import { searchUsers } from '../../services/userServices';
import { ETranslation } from '../../translations/translation-keys';
import Button from '../ui/Button/Button';
import { EInputType, IInputField } from '../ui/Input/Input';
import { getInputData, validateInputs } from '../ui/Input/input-utils';
import InputGroup from '../ui/InputGroup/InputGroup';
import { searchOrganizations } from '../../services/searchServices';
import { searchOrganizationGroups } from '../../services/organizationGroupService';
import { useAttributeOptions } from '../../hooks/useAttributeOptions';
import { ECategoryType } from '../../interfaces/ICategory';

export interface IWorkingHourEventsSearchParams {
	userId: string;
	organizationId: string;
	organizationGroupId: string;
	startDate: Date;
	endDate: Date;
}

interface IProps {
	onSearch: (params: IWorkingHourEventsSearchParams) => void;
}

enum EInputs {
	userId = "userId",
	organizationId = "organizationId",
	organizationGroupId = "organizationGroupId",
	startDate = "startDate",
	endDate = "endDate",
	projectId = "projectId",
}

const WorkingHoursEventsSearch: React.FC<IProps> = ({ onSearch }) => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.startDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_START_DATE,
			value: dateFns.formatISO(dateFns.startOfMonth(new Date()), { representation: "date" }),
			validation: {
				required: true,
			},
		},
		[EInputs.endDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_END_DATE,
			value: dateFns.formatISO(dateFns.endOfMonth(new Date()), { representation: "date" }),
			validation: {
				required: true,
			},
		},
		[EInputs.userId]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_USER,
			value: "",
			validation: {
				required: false,
			},
		},
		[EInputs.organizationId]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_CUSTOMER_ORGANIZATION,
			value: "",
			validation: {
				required: false,
			},
		},
		[EInputs.organizationGroupId]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_CUSTOMER_ORGANIZATION_GROUP,
			value: "",
			validation: {
				required: false,
			},
		},
		[EInputs.projectId]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_PROJECT,
			value: "",
			isClearable: true,
			validation: {
				required: false,
			},
		},
	});

	const { attributeOptions: projectOptions, attributesLoading: projectsLoading} = useAttributeOptions([ECategoryType.PROJECT]);

	const createInput = useCreateInput(inputs, setInputs, { showValidation: true });
	const [isValid, setIsValid] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		setIsValid(validateInputs(inputs));
	}, [inputs]);

	const searchHandler = () => {
		onSearch(getInputData<IWorkingHourEventsSearchParams>(inputs));
	};

	return (
		<div>
			<InputGroup>
				{createInput(EInputs.startDate)}
				{createInput(EInputs.endDate)}
			</InputGroup>
			{createInput(EInputs.userId, { fetchOptions: searchUsers })}
			{createInput(EInputs.organizationGroupId, { fetchOptions: searchOrganizationGroups })}
			{createInput(EInputs.organizationId, { fetchOptions: searchOrganizations })}
			{createInput(EInputs.projectId, { options: projectOptions, isLoading: projectsLoading })}
			<Button onClick={searchHandler} disabled={!isValid}>
				{t(ETranslation.COMMON_SEARCH)}
			</Button>
		</div>
	);
};

export default WorkingHoursEventsSearch;
