import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useCreateInput } from '../../hooks/useCreateInput';
import IAppState from '../../interfaces/store/IAppState';
import { searchOrganizations } from '../../services/searchServices';
import { ITask, ITaskitem } from '../../store/actions/tasksActions';
import { ETranslation } from '../../translations/translation-keys';
import Heading from '../Heading/Heading';
import Button from '../ui/Button/Button';
import Container from '../ui/Container/Container';
import { EInputType, IInputField } from '../ui/Input/Input';
import { getInputData, validateInputs } from '../ui/Input/input-utils';
import Spinner from '../ui/Spinner/Spinner';
import TaskItem from './TaskItem/TaskItem';

interface IProps {
	task?: ITask | null;
	saveTask: (task: ITask) => void;
	removeTaskItem: (id: number) => void;
}

enum EInputs {
	id = "id",
	unitName = "unitName",
	notesText = "notesText",
	adminNotesText = "adminNotesText",
	createdDateTime = "createdDateTime",
}

// TODO(joonas): Implement searchparam saving
const Task: React.FC<IProps> = ({ task, saveTask }) => {

	const { t } = useTranslation();


	const { loading } = useSelector((state: IAppState) => state.tasks);


	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.id]: {
			type: EInputType.text,
			label: "id",
			value: task?.id ?? null,
		},
		[EInputs.notesText]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.TASKS_NOTES,
			value: task?.notesText ?? "",
		},
		[EInputs.adminNotesText]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_ADMIN_NOTES,
			value: task?.adminNotesText ?? "",
		},
		[EInputs.createdDateTime]: {
			type: EInputType.text,
			value: task?.createdDateTime ?? null,
		},
		[EInputs.unitName]: {
			type: EInputType.reactSelectSearch,
			isClearable: true,
			labelTranslation: ETranslation.COMMON_ORGANIZATIONS,
			value: task?.unitName ? { value: task?.unitName.id, label: task?.unitName.name } : "",
		},
	});
	const [taskItemsState, setTaskItemsState] = useState<ITaskitem[] | null>(null);

	useEffect(() => {
		if (task && task !== null) {
			if (task.taskItems) {
				setTaskItemsState(task.taskItems);
			}
			setInputs({
				[EInputs.id]: {
					type: EInputType.text,
					label: "id",
					value: task?.id ?? null,
				},
				[EInputs.notesText]: {
					type: EInputType.textarea,
					labelTranslation: ETranslation.TASKS_NOTES,
					value: task?.notesText ?? "",
					validation: {
						required: true,
						minLength: 1,
					},
				},
				[EInputs.adminNotesText]: {
					type: EInputType.textarea,
					labelTranslation: ETranslation.COMMON_ADMIN_NOTES,
					value: task?.adminNotesText ?? "",
					validation: {
						required: true,
						minLength: 1,
					},
				},
				[EInputs.createdDateTime]: {
					type: EInputType.text,
					value: task?.createdDateTime ?? null,
				},
			});
		}
	}, [task]);


	const [isValid, setIsValid] = useState(false);

	useEffect(() => {
		const isValid = validateInputs(inputs);
		setIsValid(isValid);
	}, [inputs]);

	const saveTaskCb = () => {
		let data = getInputData<ITask>(inputs, false);
		data.taskItems = taskItemsState != null ? taskItemsState : [];
		saveTask(data);
	};

	const addNewField = () => {


		let tasksToAlter: ITaskitem[];
		if (taskItemsState) {
			tasksToAlter = [...taskItemsState];
		} else {
			tasksToAlter = [];
		}

		tasksToAlter.push({
			id: new Date().getTime().toString(),
			user: {
				id: "",
				firstName: "",
				lastName: "",
				email: "",
				phoneNumber: "+358",
			},
		});
		setTaskItemsState(tasksToAlter);
	};


	let createInput = useCreateInput(inputs, setInputs);

	return (
		<Container>
			{loading ? (
				<Spinner />
			) : (
				<>
					<Heading>{t(ETranslation.MENU_TASKS)}</Heading>
					{createInput(EInputs.unitName, { fetchOptions: searchOrganizations })}
					{createInput(EInputs.notesText)}
					{createInput(EInputs.adminNotesText)}
					<span>{t(ETranslation.COMMON_USERS)}</span>
					<Button
						style={{ marginBottom: ".5rem", marginLeft: ".5rem" }}
						onClick={addNewField}
					>
						{t(ETranslation.TASKS_ADD_USER)}
					</Button>
					<div>

						{taskItemsState?.map((taskItem) => (
							<TaskItem
								key={taskItem.id}
								taskItem={taskItem}
								removeTask={() => {
									const tasksToAlter = [...taskItemsState];
									const tasksForAlter = tasksToAlter.filter((task) => task !== taskItem);
									setTaskItemsState(tasksForAlter);
								}}
								onUpdate={(data, parentId) => {
									const newTasks = taskItemsState.map((task) => {
										if (task.id !== parentId) {
											return task;
										} else {
											return task.user = data;
										}
									});
									setTaskItemsState(newTasks)
								}}
							/>
						))}
					</div>
					<Button onClick={saveTaskCb} disabled={!isValid}>
						{t(ETranslation.COMMON_SAVE)}
					</Button>
				</>
			)}
		</Container>
	);
};

export default Task;
