import { EInterpretationStatus, EInterpretationType, INTERPRETATION_STATUS_SELECT } from "../../../shared/interpretation-data";
import { ELanguages } from "../../../shared/languages-data";
import { ETranslation } from "../../../translations/translation-keys";
import { EInputType } from "../../ui/Input/Input";
export enum EInputs {
	startDate = "startDate",
	endDate = "endDate",
	interpreter = "interpreter",
	customer = "customer",
	fromLanguage = "fromLanguage",
	toLanguage = "toLanguage",
	type = "type",
	status = "status",
	adminNotesText = "adminNotesText",
	customerReference = "customerReference",
	interpreterInfoText = "interpreterInfoText",
	durationInMinutes = "durationInMinutes",
	address = "address",
	zip = "zip",
	city = "city",
	info = "info",
}
export const InterpretationAddYoupretDefaultForm = {
	[EInputs.startDate]: {
		type: EInputType.datetimepicker,
		labelTranslation: ETranslation.COMMON_START_DATE,
		placeholder: "Valitse pvm",
		value: "",
		validation: {
			requiredIfNot: [{ inputName: EInputs.type, value: [EInterpretationType.INSTANT] }]
		}
	},
	[EInputs.interpreter]: {
		type: EInputType.reactSelectSearch,
		value: "",
		labelTranslation: ETranslation.COMMON_INTERPRETER,
		validation: {
			requiredIf: [{ inputName: EInputs.status, value: [EInterpretationStatus.WAITING_REVIEWS, EInterpretationStatus.COMPLETE, EInterpretationStatus.CONFIRMED_BOOKING] }]
		}
	},
	[EInputs.customer]: {
		type: EInputType.reactSelectSearch,
		value: "",
		labelTranslation: ETranslation.COMMON_CUSTOMER,
		validation: {
			required: true
		}
	},
	[EInputs.fromLanguage]: {
		type: EInputType.reactSelect,
		labelTranslation: ETranslation.COMMON_FROM_LANGUAGE,
		value: ELanguages.FINNISH,
		validation: {
			required: true
		}
	},
	[EInputs.toLanguage]: {
		type: EInputType.reactSelect,
		value: "",
		labelTranslation: ETranslation.COMMON_TO_LANGUAGE,
		validation: {
			required: true
		}
	},
	[EInputs.type]: {
		type: EInputType.radio,
		labelTranslation: ETranslation.COMMON_TYPE,
		value: "",
		validation: {
			required: true,
			dependencies: [EInputs.startDate, EInputs.durationInMinutes]
		}
	},
	[EInputs.status]: {
		type: EInputType.radio,
		value: EInterpretationStatus.OFFER_BOOKING,
		labelTranslation: ETranslation.COMMON_STATUS,
		options: INTERPRETATION_STATUS_SELECT,
		validation: {
			dependencies: [EInputs.interpreter, EInputs.durationInMinutes]
		}
	},
	[EInputs.adminNotesText]: {
		type: EInputType.textarea,
		value: "",
		labelTranslation: ETranslation.COMMON_ADMIN_NOTES,
	},
	[EInputs.customerReference]: {
		type: EInputType.text,
		value: "",
		labelTranslation: ETranslation.COMMON_CUSTOMER_REFERENCE,
	},
	[EInputs.interpreterInfoText]: {
		type: EInputType.textarea,
		value: "",
		labelTranslation: ETranslation.COMMON_INFO_TO_BOTH_PARTIES,
	},
	[EInputs.durationInMinutes]: {
		type: EInputType.number,
		value: "",
		labelTranslation: ETranslation.COMMON_DURATION_IN_MINUTES,
		validation: {
			requiredIfOr: true,
			requiredIf: [{ inputName: EInputs.type, value: [EInterpretationType.BOOKING, EInterpretationType.ON_SITE] }, { inputName: EInputs.status, value: [EInterpretationStatus.WAITING_REVIEWS, EInterpretationStatus.COMPLETE] }]
		}
	},
}
