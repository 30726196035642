import React from 'react';
import { TCreateInput } from '../../../hooks/useCreateInput';
import InputGroup from '../../ui/InputGroup/InputGroup';
import { EInputs } from './InterpretationAddOthersFormComponents';

interface IDateInputsProps {
	isSubstituteType: boolean;
	createInput: TCreateInput;
}

const DateInputs: React.FC<IDateInputsProps> = ({ isSubstituteType, createInput }) => {
	return isSubstituteType ? (
		<>
			{createInput(EInputs.startDate)}
			{createInput(EInputs.endDate)}
		</>
	) : (
		<>
			{createInput(EInputs.startDate)}
			<InputGroup>
				{createInput(EInputs.startTime)}
				{createInput(EInputs.endTime)}
			</InputGroup>
		</>
	);
}

export default DateInputs;