import React from "react";
import { useTranslation } from "react-i18next";
import { ELanguageStatus, getLanguageStatusString } from "../../../shared/languages-data";
import Badge, { EBadgeType } from "../../ui/Badge/Badge";


interface IProps {
  status?: ELanguageStatus;
  className?: string;
}

const LanguageAvailabilityStatus: React.FC<IProps> = ({ status = ELanguageStatus.NOT_AVAILABLE, className }) => {
  const { t } = useTranslation();
  const text = getLanguageStatusString(t, status);
  let badgeType = EBadgeType.SUCCESS;

  switch (status) {
    case ELanguageStatus.AVAILABLE:
      badgeType = EBadgeType.SUCCESS
      break;
    case ELanguageStatus.NOT_AVAILABLE:
      badgeType = EBadgeType.DANGER
      break;
    case ELanguageStatus.RESERVED:
      badgeType = EBadgeType.WARNING
      break;
  }
  return <Badge type={badgeType} className={className}>{text}</Badge>;
};

export default LanguageAvailabilityStatus;
