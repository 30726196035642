import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import IAppState from "../interfaces/store/IAppState";
import { getFees, getFeesClear } from "../store/actions/feeActions";
import { IFeeSearchParams } from "../components/Fee/FeeSearchInputs";


export const PAGINATION_FETCH_SIZE = 50;

export const FeesPagination = function <T>() {
	const dispatch = useDispatch();
	const loaderRef = useRef<HTMLParagraphElement>(null);

	const [searchOptions, setSearchOptions] = useState<IFeeSearchParams | null>(null);

	const [loadingCheck, setLoadingCheck] = useState(false);

	const {
		fees,
		paginationCursor,
		hasMoreFees,
		loading,
		error
	} = useSelector((state: IAppState) => state.fees);

	useEffect(() => {
		setLoadingCheck(false);
	}, [fees]);

	const searchHandler = useCallback((search: IFeeSearchParams) => {
		if (!search.page) { search.page = searchOptions?.page ?? 1 }
		if (search.endDate !== searchOptions?.endDate || search.organization !== searchOptions?.organization || search.startDate !== searchOptions?.startDate || search.user !== searchOptions?.user) {
			dispatch(getFeesClear())
			search.page = 1;
		}
		dispatch(getFees(search));
		setSearchOptions(state => ({ ...state, ...search }));
	}, [dispatch, searchOptions]);

	// Tää se muuten sit syö muistia juoksussa!
	const handleObserver = useCallback((entries) => {
		if (!hasMoreFees || !searchOptions || loading || loadingCheck || !fees || fees?.length === 0) return;
		setLoadingCheck(true);
		const target = entries[0];
		if (target.isIntersecting) {
			searchHandler({
				...searchOptions,
				page: searchOptions.page + 1,
				paginationCursor: paginationCursor ?? "",
			});
		} else {
			setLoadingCheck(false);
		}
	}, [loading, fees, searchHandler, searchOptions, hasMoreFees, paginationCursor, loadingCheck]);

	useEffect(() => {
		const observer = new IntersectionObserver(handleObserver, {
			root: null,
			rootMargin: "",
			threshold: 1,
		});
		if (loaderRef.current) observer.observe(loaderRef.current);
		return () => observer.disconnect();
	}, [handleObserver]);

	return { loaderRef, fees, loading, searchHandler, hasMoreFees, error };
};
