import { TFunction } from "i18next"
import { EUserStatus } from "../classes/User"
import { IOption } from "../components/ui/Input/Input"
import { ETranslation } from "../translations/translation-keys"


export const useUser = () => {

	const USER_STATUS_ALL: IOption[] = [
		{ value: EUserStatus.ONLINE_AVAILABLE, labelTranslation: ETranslation.COMMON_AVAILABLE },
		{ value: EUserStatus.ONLINE_NOT_AVAILABLE, labelTranslation: ETranslation.COMMON_NOT_AVAILABLE },
		{ value: EUserStatus.ONLINE_RESERVED, labelTranslation: ETranslation.COMMON_RESERVED },
		{ value: EUserStatus.OFFLINE, labelTranslation: ETranslation.COMMON_OFFLINE }
	]

	const getUserStatusString = (
		t: TFunction, status: EUserStatus
	): string => t(USER_STATUS_ALL.find(item => item.value === status)?.labelTranslation || "");


	return { USER_STATUS_ALL, getUserStatusString }

}


