import React, { useEffect } from "react";
import * as actions from "../../store/actions";
import Container from "../../components/ui/Container/Container";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Summary from "../../components/Summary/Summary";
import Alert from "../../components/ui/Alert/Alert";
import Spinner from "../../components/ui/Spinner/Spinner";
import IAppState from "../../interfaces/store/IAppState";
import { ERoute } from "../../classes/Routes";

interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

const SummariesViewPage: React.FC<IProps> = ({ match, history, location }) => {
	const dispatch = useDispatch();

	const { id } = match.params;

	useEffect(() => {
		dispatch(actions.getSummary(id));
	}, [id, dispatch]);

	const { error, loading, summary, updateOk } = useSelector((state: IAppState) => ({
		updateOk: state.summaries.updateOk,
		error: state.summaries.error,
		loading: state.summaries.loading,
		summary: state.summaries.summary,
	}));

	useEffect(() => {
		if (updateOk) {
			history.push(ERoute.SUMMARIES_LIST);
		}
		return () => {
			dispatch(actions.updateSummaryClear());
		}
	}, [dispatch, updateOk, history])

	const updateAdminNotes = (adminNotes: string) => {
		if (!summary) return;
		else {
			dispatch(actions.updateSummaryAdminNotes(adminNotes, id));
		}
	}

	return (
		<Container>
			{error && <Alert>{error}</Alert>}

			{loading ? (
				<Spinner centerAbsolute />
			) : (
				summary && <Summary updateAdminNotes={updateAdminNotes} summary={summary} history={history} location={location} match={match} />
			)}
		</Container>
	);
};

export default SummariesViewPage;
