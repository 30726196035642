import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonColor } from "../../ui/Button/Button";
import * as actions from "../../../store/actions";
import IAppState from "../../../interfaces/store/IAppState";
import IAuthState from "../../../interfaces/store/IAuthState";
import { useTranslation } from "react-i18next";
import { InterpretationContext } from "../../../pages/Interpretations/OrderInterpretation/order-interpretation-context";
import {
	EConnectionDetail,
	EInterpretationStatus,
} from "../../../shared/interpretation-data";
import { ERoute, Routes } from "../../../classes/Routes";
import { EUserRole } from "../../../classes/User";
import { useHistory } from "react-router-dom";
import classes from "./InterpretationProcessing.module.scss";
import Alert, { EAlertColor } from "../../ui/Alert/Alert";
import ModalContext, { EModalSize } from "../../ui/Modal/ModalContext";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";

const InterpretationProcessing: React.FC = () => {
	const { interpretation } = useContext(InterpretationContext);
	const [confirmText, setConfirmedText] = useState<string | undefined>(
		undefined
	);
	const [showReview, setShowReview] = useState(false);
	const { setModal, closeModal } = useContext(ModalContext);

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const interpretationId = interpretation?.id;
	const interpretationStatus = interpretation?.status;
	const interpretationCustomerPhoneNumber =
		interpretation?.customer.phoneNumber;
	const interpretationConnectionType =
		interpretation?.connectionDetail.connectionType;
	let interpretationInterpreterPhoneNumber = "";
	if (interpretation?.interpreter) {
		interpretationInterpreterPhoneNumber =
			interpretation?.interpreter.phoneNumber;
	}

	useEffect(() => {
		const updateCustomerLogs = () => {
			// get interpretation data from server
			if (interpretationId) {
				dispatch(actions.getInterpretation(interpretationId));
			}
		};
		const customerLogsInterval = setInterval(() => {
			updateCustomerLogs();
		}, 5000);

		return () => clearInterval(customerLogsInterval);
	}, [dispatch, interpretationId]);

	useEffect(() => {
		switch (interpretationStatus) {
			case EInterpretationStatus.IN_PROGRESS:
				switch (interpretationConnectionType) {
					case EConnectionDetail.INTERPRETER_CALL_TO_CUSTOMER:
						setConfirmedText(
							t(ETranslation.INTERPRETATION_PROCESSING_INTERPRETER_WILL_CALL) +
							interpretationCustomerPhoneNumber +
							t(ETranslation.INTERPRETATION_PROCESSING_IN_A_MOMENT)
						);
						break;
					case EConnectionDetail.CUSTOMER_CALL_TO_INTERPRETER:
						setConfirmedText(
							t(ETranslation.INTERPRETATION_PROCESSING_CALL_INTERPRETER) +
							interpretationInterpreterPhoneNumber
						);
						break;
					case EConnectionDetail.VIDEO_CONFERENCE_TWILIO:
						setConfirmedText(
							t(ETranslation.INTERPRETATION_PROCESSING_VIDEO_CONFERENCE)
						);
						break;
					default:
						break;
				}
				break;
			case EInterpretationStatus.WAITING_REVIEWS:
				setConfirmedText(
					t(ETranslation.INTERPRETATION_DATA_STATUS_WAITING_REVIEWS)
				);
				break;
			case EInterpretationStatus.WAITING_CUSTOMER_REVIEW:
				setConfirmedText(
					t(ETranslation.COMMON_INTERPRETATION_DONE)
				);
				break;
			case EInterpretationStatus.CUSTOMER_CANCEL:
				history.push(ERoute.ORDER_INTERPRETATION);
				break;
			case EInterpretationStatus.INTERPRETERS_NOT_FOUND:
			case EInterpretationStatus.FAVORITES_NOT_FOUND:
				setConfirmedText(
					t(ETranslation.INTERPRETATION_PROCESSING_INTERPRETERS_NOT_FOUND)
				);
				break;
			case EInterpretationStatus.INTERPRETER_CANCEL:
				//Tähän mitä tapahtuu kun tulkki peruu tilauksen

				break;

			default:
				break;
		}
	}, [
		history,
		interpretationConnectionType,
		interpretationCustomerPhoneNumber,
		interpretationInterpreterPhoneNumber,
		interpretationStatus,
		t,
	]);

	const { user } = useSelector<IAppState, IAuthState>((state) => state.auth);

	const renderLogs = () =>
		interpretation?.customerLogs.map(({ logMessage, logDate, logTime, id }) => (
			<React.Fragment key={id}>
				<div className={classes.ProcessingLogItem}>
					{logMessage}{" "}
					<small>
						{logDate} {logTime}
					</small>
				</div>
				<hr />
			</React.Fragment>
		));

	const cleanHandler = () => {
		dispatch(actions.getInterpretationClear());
		dispatch(actions.requestInterpretationClear());
	};

	const openInterpretation = () => {
		const url = `/interpretations/${interpretationId}`;
		window.open(
			url,
			"_blank",
			"noopener, noreferrer"
		);
		history.push(ERoute.ORDER_INTERPRETATION);
		cleanHandler();
		return false;
	};

	const closeInterpretation = () => {
		if (interpretation) {
			history.push(ERoute.ORDER_INTERPRETATION);
			cleanHandler();
		}
	};

	const openReview = () => {
		const id = interpretationId;
		if (!id) return;
		history.push(Routes.withParams(ERoute.INTERPRETATION_REVIEW, { id }));
	};

	useEffect(() => {
		if (!interpretationStatus) return;
		if (
			interpretationStatus === "IN_PROGRESS" ||
			interpretationStatus === "WAITING_CUSTOMER_REVIEW"
		) {
			setShowReview(true);
		}
	}, [interpretationStatus]);

	const videoRedirect = () => {
		if (interpretationId) {
			const id = interpretationId;
			window.open(`/video/${id}?nh`, "_blank", "noopener, noreferrer");
		}
	};

	const showConfirmModal = () => {
		setModal({
			title: t(ETranslation.MODAL_CONFIRMATION_TITLE),
			content: <ConfirmationModal
				text={t(ETranslation.MODAL_CONFIRMATION_INTERRUPT)}
				onConfirm={() => {
					closeModal();
					if (interpretation) {
						dispatch(actions.interruptInterpretation(interpretation));
						cleanHandler();
						history.push(ERoute.ORDER_INTERPRETATION);
					}
				}}
			/>,
			isOpen: true,
			size: EModalSize.EXTRASMALL,
		})

	}

	return (
		<>
			<div className={classes.ContainerPadding}>
				{confirmText && <Alert color={EAlertColor.INFO}>{confirmText}</Alert>}
				<div className={classes.ProcessingLogContainer}>{renderLogs()}</div>
				{user?.isYoupretAdmin && (
					<>
						<Button
							className={classes.StartReviewButton}
							color={EButtonColor.DEFAULT}
							onClick={openInterpretation}
						>
							{t(ETranslation.INTERPRETATION_PROCESSING_OPEN_INTERPRETATION)}
						</Button>
						<Button
							className={classes.StartReviewButton}
							color={EButtonColor.DEFAULT}
							onClick={closeInterpretation}
						>
							{t(ETranslation.INTERPRETATION_PROCESSING_CLOSE)}
						</Button>
					</>
				)}
				{interpretationConnectionType ===
					EConnectionDetail.VIDEO_CONFERENCE_TWILIO &&
					interpretationStatus === "IN_PROGRESS" && (
						<Button
							className={classes.StartReviewButton}
							onClick={videoRedirect}
						>
							{t(ETranslation.INTERPRETATION_PROCESSING_OPEN_VIDEO_CONNECTION)}
						</Button>
					)}
				{user?.hasRole(EUserRole.CUSTOMER) && showReview && (
					<Button
						className={classes.StartReviewButton}
						color={EButtonColor.SUCCESS}
						onClick={openReview}
					>
						{t(ETranslation.COMMON_INTERPRETATION_DONE)}
					</Button>
				)}
				{user?.hasRole(EUserRole.CUSTOMER) && (
					<Button
						className={classes.StartReviewButton}
						color={EButtonColor.DEFAULT}
						onClick={showConfirmModal}
					>
						{t(ETranslation.INTERPRETATION_PROCESSING_INTERRUPT)}
					</Button>
				)}
			</div>
		</>
	);
};

export default InterpretationProcessing;
