import React from "react";
import { IEezyTrusterPayment, IEezyTrusterPaymentTotal } from "../../../../interfaces/IEezyTruster";
import { EEezyTrusterPaymentStatus } from "../../../../shared/eezy-truster-data";
import Button, { EButtonColor } from "../../../ui/Button/Button";

interface IProps {
	payment: IEezyTrusterPayment;
}

export const EezyTotalRow: React.FC<IProps> = ({ payment }) => {
	return payment.eezyTotal ? <tr>
		<td>{payment.eezyTotal.productName}</td>
		<td>{payment.minutesTotal} m</td>
		<td>{payment.eezyTotal.quantity} kpl</td>
		<td>{payment.eezyTotal.quantityUnitPrice} €</td>
		<td>{payment.eezyTotal.price} €</td>
		<td><span>{payment.eezyTotal.vatPercent}</span>({payment.eezyTotal.priceVat} €)</td>
		<td>{payment.eezyTotal.priceWithVat} €</td>
		<td>{payment.eezyTotal.startDate} - {payment.eezyTotal.endDate}</td>
		<td></td>
	</tr> : null
}

export const EezyTotalBookingRow: React.FC<IProps> = ({ payment }) => {
	return payment.eezyTotalBooking ? <tr>
		<td>{payment.eezyTotalBooking.productName}</td>
		<td>{payment.minutesTotalBooking} m</td>
		<td>{payment.eezyTotalBooking.quantity} kpl</td>
		<td >{payment.eezyTotalBooking.quantityUnitPrice} €</td>
		<td>{payment.eezyTotalBooking.price} €</td>
		<td><span>{payment.eezyTotalBooking.vatPercent}</span>({payment.eezyTotalBooking.priceVat} €)</td>
		<td>{payment.eezyTotalBooking.priceWithVat} €</td>
		<td >{payment.eezyTotalBooking.startDate} - {payment.eezyTotalBooking.endDate}</td>
		<td></td>
	</tr> : null
}

export const EezyTotalOnSiteRow: React.FC<IProps> = ({ payment }) => {
	return payment.eezyTotalOnSite ? <tr>
		<td >{payment.eezyTotalOnSite.productName}</td>
		<td>{payment.minutesTotalOnSite} m</td>
		<td >{payment.eezyTotalOnSite.quantity} kpl</td>
		<td >{payment.eezyTotalOnSite.quantityUnitPrice} €</td>
		<td>{payment.eezyTotalOnSite.price} €</td>
		<td><span >{payment.eezyTotalOnSite.vatPercent}</span>({payment.eezyTotalOnSite.priceVat} €)</td>
		<td>{payment.eezyTotalOnSite.priceWithVat} €</td>
		<td >{payment.eezyTotalOnSite.startDate} - {payment.eezyTotalOnSite.endDate}</td>
		<td></td>
	</tr> : null
}

export const StartTotalRow: React.FC<IProps> = ({ payment }) => {
	return payment.startTotal ? <tr>
		<td >{payment.startTotal.productName}</td>
		<td></td>
		<td >{payment.startTotal.quantity} kpl</td>
		<td >{payment.startTotal.quantityUnitPrice} €</td>
		<td>{payment.startTotal.price} €</td>
		<td><span >{payment.startTotal.vatPercent}</span>({payment.startTotal.priceVat} €)</td>
		<td>{payment.startTotal.priceWithVat} €</td>
		<td></td>
		<td></td>
	</tr> : null
}

interface IManagementTotalRowProps {
	payment: IEezyTrusterPayment;
	loading: boolean;
	removeManagementTotal: () => void;
}
export const ManagementTotalRow: React.FC<IManagementTotalRowProps> = ({ payment, loading, removeManagementTotal }) => {
	return payment.managementTotal ? <tr>
		<td>{payment.managementTotal.productName}</td>
		<td></td>
		<td >{payment.managementTotal.quantity} kpl</td>
		<td >{payment.managementTotal.quantityUnitPrice} €</td>
		<td>{payment.managementTotal.price} €</td>
		<td><span >{payment.managementTotal.vatPercent}</span>({payment.managementTotal.priceVat} €)</td>
		<td>{payment.managementTotal.priceWithVat} €</td>
		<td></td>
		<td>
			{(payment.status !== EEezyTrusterPaymentStatus.SENDING && payment.status !== EEezyTrusterPaymentStatus.SENT) &&
				<Button color={EButtonColor.DANGER} loading={loading} onClick={removeManagementTotal}>X</Button>
			}
		</td>
	</tr> : null
}

interface ICustomTotalRowProps {
	customTotal: IEezyTrusterPaymentTotal;
	loading: boolean;
	removeCustomTotalClick: (index: number) => void;
	index: number;
}
export const CustomTotalRow: React.FC<ICustomTotalRowProps> = ({ customTotal, loading, removeCustomTotalClick, index }) => {
	return <tr key={index}>
		<td>{customTotal.productName}</td>
		<td></td>
		<td >{customTotal.quantity + ' kpl'}</td>
		<td >{(customTotal.quantityUnitPrice) + ' €'}</td>
		<td>{(customTotal.price) + ' €'}</td>
		<td><span>{customTotal.vatPercent}</span> ({customTotal.priceVat} €)</td>
		<td>{(customTotal.priceWithVat) + " €"}</td>
		<td></td>
		<td><Button color={EButtonColor.DANGER} loading={loading} onClick={() => { removeCustomTotalClick(index) }}>X</Button></td>
	</tr>
}

export const TotalsRow: React.FC<IProps> = ({ payment, }) => {
	return <tr>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td><b>{payment.priceTotal} €</b></td>
		<td><b>{payment.priceVatTotal} €</b></td>
		<td><b>{payment.priceWithVatTotal} €</b></td>
		<td></td>
		<td></td>
	</tr>
}
