import { customFetch, EFetchMethod } from '../../custom-fetch';
import EActionTypes from '../../interfaces/store/EActionTypes';
import { IReservedDate } from '../../interfaces/IReservedDate';
import IReservedDateAction from '../../interfaces/store/IReservedDateAction';
import { Dispatch } from 'redux';

type TAction = IReservedDateAction;

const findReservedDatesStart = (): TAction => {
  return {
    type: EActionTypes.RESERVED_DATE_FIND_START,
  };
};

const findReservedDatesSuccess = (reservedDates: IReservedDate[]): TAction => {
  return {
    type: EActionTypes.RESERVED_DATE_FIND_SUCCESS,
    reservedDates,
  };
};

const findReservedDatesFail = (error: string): TAction => {
  return {
    type: EActionTypes.RESERVED_DATE_FIND_FAIL,
    error,
  };
};

export const findReservedDates = (userId?: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(findReservedDatesStart());
	  const url = userId ?  `/reserveddates/list?userId=${userId}` : "/reserveddates/list"
      const reservedDates = await customFetch<IReservedDate[]>(url);
      dispatch(findReservedDatesSuccess(reservedDates));
    } catch (error) {
      dispatch(findReservedDatesFail((error as Error).message));
    }
  };
};

export const findReservedDatesClear = () => {
	return {
		type: EActionTypes.RESERVED_DATE_FIND_CLEAR,
	};
}

const deleteReservedDateStart = (id: string): TAction => {
  return {
    type: EActionTypes.RESERVED_DATE_DELETE_START,
    id,
  };
};

const deleteReservedDateSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.RESERVED_DATE_DELETE_SUCCESS,
    id,
  };
};

const deleteReservedDateFail = (error: string): TAction => {
  return {
    type: EActionTypes.RESERVED_DATE_DELETE_FAIL,
    error,
  };
};

export const deleteReservedDate = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deleteReservedDateStart(id));
      await customFetch<boolean>(`/reserveddates/delete?id=${id}`, EFetchMethod.DELETE);
      dispatch(deleteReservedDateSuccess(id));
    } catch (error) {
      dispatch(deleteReservedDateFail((error as Error).message));
    }
  };
};



