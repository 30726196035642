import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useUserContractSettingOptions } from "../../../hooks/useUserContractSettingOptions";
import { IUserContractSettings } from "../../../interfaces/IUserContractSettings";
import IAppState from "../../../interfaces/store/IAppState";
import IUserContractState from "../../../interfaces/store/IUserContractState";
import * as actions from "../../../store/actions";
import Input, { EInputType, TInputValue } from "../../ui/Input/Input";
import UserContractSettingsList from "../UserContractSettingsList/UserContractSettingsList";
import Heading from "../../Heading/Heading";

interface IProps {
	editSettings: IUserContractSettings[];
	onDelete: (id: string, index: number) => void;
	onEdit: (id: string, index: number) => void;
  showTitle?: boolean;
}

const UserContractSettingsAttach: React.FC<IProps> = ({ editSettings, onDelete, onEdit, showTitle }) => {
	const { contractSettingsLoading, contractSettingsOptions } = useUserContractSettingOptions();

	const { loading } = useSelector<IAppState, IUserContractState>((state) => state.userContracts);

	const dispatch = useDispatch();

	const addSettings = (value: TInputValue) => {
		if (value) {
			dispatch(actions.getUserContractSettings(value as string, true));
		}
	};

	return (
		<>
			{showTitle && (<Heading tag="h3">Sopimus asetukset</Heading>)}
			<div style={{ marginBottom: "1rem" }}>
				<Input
					type={EInputType.reactSelect}
					inputName="userContractSettings"
					placeholder="Lisää sopimusasetus"
					showLabel={false}
					onChange={addSettings}
					value=""
					options={contractSettingsOptions}
					isLoading={contractSettingsLoading}
				/>

				<UserContractSettingsList
					settings={editSettings}
					error={null}
					loading={loading}
					onClick={onEdit}
					onDelete={onDelete}
					showDetails
				/>
			</div>
		</>
	);
};

export default UserContractSettingsAttach;
