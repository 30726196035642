import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateInput } from '../../hooks/useCreateInput';

import { ISite } from '../../interfaces/ISite';
import { SITE_TYPES } from '../../shared/site-data';
import { ETranslation } from '../../translations/translation-keys';
import Heading from '../Heading/Heading';
import { ECommonValue, EInputType, IInputField, IOption } from '../ui/Input/Input';
import { getInputData, initForm, validateInputs } from '../ui/Input/input-utils';
import InputGroup from '../ui/InputGroup/InputGroup';

enum EInputs {
  name = "name",
  description = "description",
  prefix = "prefix",
  timeZoneId = "timeZoneId",
  active = "active",
  targetMarginP = "targetMarginP",
  minFeePerMin = "minFeePerMin",
  maxFeePerMin = "maxFeePerMin",
  types = "types",
  address = "address",
  zip = "zip",
  city = "city",
  phoneNumber = "phoneNumber",
  organizationName = "organizationName",
  businessId = "businessId",
}

interface IProps {
  onChange: (site: ISite, isValid: boolean) => void;
  site: ISite | null;
  loading?: boolean;
  showValidation?: boolean;
}

enum ETimezone {
  EuropeHelsinki = "Europe/Helsinki"
}

const getTimezones = (): IOption[] => {
  const timezones = [ETimezone.EuropeHelsinki];
  return timezones.map((timezone) => ({ label: timezone, value: timezone }));
}

const Site: React.FC<IProps> = ({
  onChange,
  loading,
  site,
  showValidation,
}) => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.name]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_NAME,
      value: "",
      validation: {
        required: true,
      },
    },
    [EInputs.description]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.COMMON_DESCRIPTION,
      value: "",
    },
    [EInputs.prefix]: {
      type: EInputType.text,
      labelTranslation: ETranslation.SITE_PREFIX,
      value: "",
      validation: {
        required: false,
      },
    },
    [EInputs.timeZoneId]: {
      type: EInputType.select,
      labelTranslation: ETranslation.SITE_TIMEZONE,
      options: [{labelTranslation: ETranslation.SITE_CHOOSE_TIMEZONE, value: '' }, ...getTimezones()],
      value: ETimezone.EuropeHelsinki,
    },
    [EInputs.active]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.SITE_ACTIVE,
      value: [ ECommonValue.YES ],
      options: [{ value: ECommonValue.YES }],
    },
    [EInputs.targetMarginP]: {
      type: EInputType.number,
      labelTranslation: ETranslation.SITE_TARGET_MARGIN,
      value: "",
    },
    [EInputs.minFeePerMin]: {
      type: EInputType.number,
      labelTranslation: ETranslation.SITE_MIN_REWARD,
      value: "",
    },
    [EInputs.maxFeePerMin]: {
      type: EInputType.number,
      labelTranslation: ETranslation.SITE_MAX_REWARD,
      value: "",
    },
    [EInputs.types]: {
      type: EInputType.reactSelect,
      multiple: true,
      options: SITE_TYPES,
      labelTranslation: ETranslation.SITE_TYPES,
      value: [],
    },
    [EInputs.address]: {
      type: EInputType.text,
      labelTranslation: ETranslation.SITE_ADDRESS,
      value: "",
    },
    [EInputs.zip]: {
      type: EInputType.text,
      labelTranslation: ETranslation.SITE_ZIP,
      value: "",
    },
    [EInputs.city]: {
      type: EInputType.text,
      labelTranslation: ETranslation.SITE_CITY,
      value: "",
    },
    [EInputs.phoneNumber]: {
      type: EInputType.text,
      labelTranslation: ETranslation.SITE_PHONE_NUMBER,
      value: "",
    },
    [EInputs.organizationName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.SITE_ORGANIZATION_NAME,
      value: "",
    },
    [EInputs.businessId]: {
      type: EInputType.text,
      labelTranslation: ETranslation.SITE_BUSINESS_ID,
      value: "",
    },
  });

  useEffect(() => {
    if (site) {
      initForm(setInputs, site);
    }
    // eslint-disable-next-line
  }, [site]);

  useEffect(() => {
    const site = getInputData<ISite>(inputs);
    const isValid = validateInputs(inputs);
    onChange(site, isValid);
  }, [inputs, onChange]);

  const createInput = useCreateInput(inputs, setInputs, {
    showValidation,
    disabled: loading
  });

  return (
    <>
      {createInput(EInputs.name)}
      {createInput(EInputs.description)}
      {createInput(EInputs.prefix)}
      {createInput(EInputs.timeZoneId)}
      {createInput(EInputs.active, { style: { display: "block" } })}
      <InputGroup>
        {createInput(EInputs.types)}
      </InputGroup>
      <Heading tag="h2">{t(ETranslation.SITE_APPOINTMENT_REWARDS)}</Heading>
      <InputGroup>
        {createInput(EInputs.targetMarginP)}
        {createInput(EInputs.minFeePerMin)}
        {createInput(EInputs.maxFeePerMin)}
      </InputGroup>
      <Heading tag="h2">{t(ETranslation.SITE_ADDRESS_TITLE)}</Heading>
      <InputGroup>
      {createInput(EInputs.organizationName)}
      {createInput(EInputs.businessId)}
      {createInput(EInputs.phoneNumber)}
      </InputGroup>
      <InputGroup>
        {createInput(EInputs.address)}
        {createInput(EInputs.zip)}
        {createInput(EInputs.city)}
      </InputGroup>
      <br />
    </>
  );
};

export default Site;
