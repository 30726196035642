import React, { useContext, useState } from "react";
import * as dateFns from 'date-fns';
import { ETranslation } from "../../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import { IInterpretation } from "../../../interfaces/IInterpretation";
import classes from './calendar.module.scss';
import { IUser } from "../../../classes/User";
import ModalContext, { EModalSize } from "../../../components/ui/Modal/ModalContext";
import ReservationDetailsModal from "./CalendarHourDetails";

export interface ISearchOptions {
	toLanguage: [string];
	createdDateStart?: string;
	createdDateEnd?: string;
}

interface IProps {
	Intrepretations: IInterpretation[] | null;
	usersInSearch: IUser[] | null;
	startDate: string;
	endDate: string;
};

interface ICalendarData {
	// Object per day. Keyd with date string
	[key: string]:
	{
		// Per "Time" slots. Currently Per hour slots. Keyd with starting hour
		[key: string]: IInterpretation[]
	}
}

const getDatesBetween = (startDate: Date, endDate: Date): Array<Date> => {
	const dates: Array<Date> = [];
	for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
		dates.push(new Date(d));
	}
	return dates;
}

// Adds interpretation into respected hourslot and creates the slot if one doesn't exist
const addToHourSlot = (
	Intrepretation: any,
	day: string,
	hour: number,
	days: ICalendarData
) => {
	days[day] = days[day] || {};
	days[day][hour] = days[day][hour] ? [...days[day][hour], Intrepretation] : [Intrepretation];
};



const CalendarList: React.FC<IProps> = ({ Intrepretations, usersInSearch, startDate, endDate }) => {

	const { t } = useTranslation();
	const { setModal } = useContext(ModalContext);

	// Transform users array into id keyd array
	const UsersIdKeyd = (): { [key: string]: IUser } => {
		return usersInSearch?.reduce((acc: { [key: string]: IUser }, User) => {
			acc[User.id] = User;
			return acc;
		}, {}) || {};
	};

	// Show hours between 0:00 -> 7:00 and 18:00 -> 24:00
	const [showEarlyAndLateHours, setShowEarlyAndLateHours] = useState<boolean>(false);


	// Chat GTP simplified
	// Move away from component
	const dayAndHourSlots = (): ICalendarData => {
		const days: ICalendarData = {};

		// Map interpretations and slot them accordingly. If the end time is on another hour, fill it all the way
		// exception, if the ending hour is at :00 minutes.
		// TODO(Joonas): Test and make sure it works on for example 11:00->13:00 filling 11:00->12:00 and 12:00->13:00
		Intrepretations?.map((Intrepretation) => {
			// Parse dates of the interpretation
			const startDay = dateFns.format(new Date(Intrepretation.startDateUTC), "yyyy-M-d");
			const endDay = dateFns.format(
				dateFns.addMinutes(new Date(Intrepretation.startDateUTC), Intrepretation.durationInMinutes),
				"yyyy-M-d"
			);
			// Parse times of the interpretation
			const startHour = parseFloat(dateFns.format(new Date(Intrepretation.startDateUTC), "H"));
			const endHour = parseFloat(
				dateFns.format(
					dateFns.addMinutes(new Date(Intrepretation.startDateUTC), Intrepretation.durationInMinutes),
					"H"
				)
			);
			// End minute for the exception
			const EndMinute = parseFloat(
				dateFns.format(
					dateFns.addMinutes(new Date(Intrepretation.startDateUTC), Intrepretation.durationInMinutes),
					"mm"
				)
			)
			
			// Add the interpretation to the starting timeslot
			addToHourSlot(Intrepretation, startDay, startHour, days);

			// Check if it's not at the same hour and that it's either over one hour later OR end minutes are more than 0
			// 11:00->12:00 false
			// 11:00->12:01 true
			// 11:00->13:00 true but how about 13:00->14:00 slot?????
			if (endHour !== startHour && (endHour - startHour > 1 || EndMinute > 0)) {
				let diffHours = endHour > startHour ? endHour - startHour : 24 - startHour + endHour;
				// if more than 2 or 2 hours, we are going to loop it
				if (diffHours >= 2) {
					let first = true;
					for (let HourToAdd = startHour + diffHours; HourToAdd >= startHour; HourToAdd--) {
						if (HourToAdd >= 24) {
							HourToAdd -= 24;
						}
						if(first && EndMinute === 0){
							first = false;
						}else if(first){
							addToHourSlot(Intrepretation, endDay, HourToAdd, days);
							first = false;
						} else {
							addToHourSlot(Intrepretation, endDay, HourToAdd, days);
						}
					}
				} else {
					addToHourSlot(Intrepretation, endDay, endHour, days);
				}
			}
		});

		return days;
	};




	// Chat GTP round 3 hour and day fillerillä
	// Pura pienempiin paloihin ja parsi elementit palasiksi
	// Data manipulation
	// Elementtien creation
	const Columns = () => {
		const days = dayAndHourSlots();
		const allDays = getDatesBetween(new Date(startDate), new Date(endDate))

		const columnElements = [];
		// Jokaista päivää kohden tehdään oma columni
		for (const day of allDays) {
			const dayKey = dateFns.format(day, "yyyy-M-d")
			const dayEl = [];
			const dayInterpretations = days[dayKey] ? days[dayKey] : {};
			const allHours = Array.from({ length: 24 }, (_, i) => `${i}`);

			// Jokaista päivän tuntia kohden tehdään oma laatikko "hour slot"
			for (const hour of allHours) {
				const hourInterpretations = dayInterpretations[hour] || [];
				// Base all users as free users. Array is keyd by userId's
				const freeInterpretators = UsersIdKeyd();
				// Check if within this hour users have interpretations. Reduce them from the free and base them in here.
				const reservedInterpretators = hourInterpretations.reduce((result, interpretation) => {
					if (interpretation.interpreterDetail && interpretation.interpreterDetail.id) {
						if (!result.includes(interpretation.interpreterDetail.id)) {
							delete freeInterpretators[interpretation.interpreterDetail.id];
							result.push(interpretation.interpreterDetail.id);
						}
					}
					return result;
				}, [] as string[]);
				// Count free users from the resulting free users object
				const countOfFree = Object.keys(freeInterpretators).length;
				// Determine color for the block. Over 1/4 reserved turns it to yellow. 0 free makes it to red. Othervice it will be green.
				const BlockClassName = countOfFree === 0 ? classes.BlockRed : reservedInterpretators.length >= ((countOfFree + reservedInterpretators.length) / 4) ? classes.BlockYellow : classes.BlockGreen;
				// Split elemnents into off and on hours. Off hour blocks are hidden by default and can be toggled via button and programatically "setShowEarlyAndLateHours(!showEarlyAndLateHours)"
				if ((parseFloat(hour) > 17 || parseFloat(hour) < 8) && showEarlyAndLateHours) {
					dayEl.push(
						<div key={hour + day} className={BlockClassName} onClick={() => {
							showDialog(hourInterpretations, day, hour, dayInterpretations);
						}}>
							<div className={classes.HourTitle}>{dateFns.format(day, "d.M")}<br /></div>
							<div className={classes.HourTitle}>{hour}:00 - {parseFloat(hour) + 1}:00<br /></div>
							<div className={classes.IntrepreterDetails}>
								<span>Free Interpreters: {countOfFree}</span> <br />
								<span>Reserved Interpreters: {reservedInterpretators.length}</span> <br />
							</div>
						</div>
					);
				} else if (parseFloat(hour) <= 17 && parseFloat(hour) >= 8) {
					dayEl.push(
						<div key={hour + day} className={BlockClassName} onClick={() => {
							showDialog(hourInterpretations, day, hour, dayInterpretations);
						}}>
							<div className={classes.HourTitle}>{dateFns.format(day, "d.M")}<br /></div>
							<div className={classes.HourTitle}>{hour}:00 - {parseFloat(hour) + 1}:00<br /></div>
							<div className={classes.IntrepreterDetails}>
								<span>Free Interpreters: {countOfFree}</span> <br />
								<span>Reserved Interpreters: {reservedInterpretators.length}</span> <br />
							</div>
						</div>
					);
				}

			}
			// Push the ready day column to returning elements
			columnElements.push(
				<div key={dayKey} className={classes.FlexColumn}>
					<div className={classes.ColumnHeader}>
						<p>{dateFns.format(day, "EEEE dd.MM")}</p>
					</div>
					{dayEl}
				</div>
			);
		}
		return columnElements;
	};

	// Handle opening the hourly dialog
	const showDialog = (interpretations: IInterpretation[], date: Date, time: string, daysIntrepretations: { [key: string]: IInterpretation[] }) => {
		const titleStr: string = t(ETranslation.CALENDAR_MODAL_TITLE) + " "
			+ (dateFns.isToday(new Date(date)) ? "Today" : dateFns.format(date, "dd.MM"))
			+ " klo " + time + ":00 - " + (parseFloat(time) + 1) + ":00";
		setModal({
			title: titleStr,
			content: <ReservationDetailsModal
				interpretations={interpretations}
				users={usersInSearch} hour={time}
				daysIntrepretations={daysIntrepretations}
				selectedDay={dateFns.format(date, "yyyy-MM-dd")}
			/>,
			isOpen: true,
			size: EModalSize.FULL,
		});
	};
	return (
		Intrepretations && (
			<div>
				<button onClick={() => { setShowEarlyAndLateHours(!showEarlyAndLateHours) }}>Show off hours</button>
				<div className={classes.Container}>
					{Columns()}
				</div>
			</div>

		)
	)
}

export default CalendarList;