import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { searchInterpreters } from "../../../services/userServices";
import { ETranslation } from "../../../translations/translation-keys";
import Button from "../../ui/Button/Button";
import { IInputField, EInputType } from "../../ui/Input/Input";
import { getInputData } from "../../ui/Input/input-utils";
import InputGroup from "../../ui/InputGroup/InputGroup";
import { IUser } from "../../../classes/User";


interface IProps {
	item: IUserFavouriteInterpreter;
	onUpdate: (item: IUserFavouriteInterpreter, movement: string) => void;
	onDelete: () => void;
}

export interface IUserFavouriteInterpreter {
	id: string;
	priority: number;
	user: IUser | null;
}

const EFavInterpreterInputs = {
	id: "id",
	priority: "priority",
	user: "user",
}


const UserFavouriteInterpreter: React.FC<IProps> = ({ item, onUpdate, onDelete }) => {
	const { t } = useTranslation()
	const [inputs, setInputs] = useState<IInputField>({
		[EFavInterpreterInputs.id]: {
			type: EInputType.text,
			label: "",
			value: item.id,
		},
		[EFavInterpreterInputs.priority]: {
			type: EInputType.text,
			label: "",
			value: item.priority,
		},
		[EFavInterpreterInputs.user]: {
			type: EInputType.reactSelectSearch,
			label: "",
			value: { label: `${item.user?.firstName ?? ""} ${item.user?.lastName ?? ""}`, value: item.user?.id ?? "" },
		},
	});

	const createInput = useCreateInput(inputs, setInputs);

	useEffect(() => {
		setInputs({
			...inputs,
			[EFavInterpreterInputs.priority]: {
				type: EInputType.text,
				label: "",
				value: item.priority,
			},
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item.priority])

	useEffect(() => {
		const data = getInputData<IUserFavouriteInterpreter>(inputs);
		onUpdate(data, "tulkki");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs])

	const changePriority = (movement: string) => {
		const data = getInputData<IUserFavouriteInterpreter>(inputs);
		movement === "down" ? data.priority++ : data.priority--
		onUpdate(data, movement)
	}

	return <>
		<InputGroup>
			{createInput(EFavInterpreterInputs.user, {
				fetchOptions: searchInterpreters,
			})}
			<div style={{ justifyContent: "flex-start", alignItems: "center", fontSize: "30px" }}>
				<div onClick={() => changePriority("up")}><FontAwesomeIcon icon={faAngleUp} /></div>
				<div onClick={()=>changePriority("down")}><FontAwesomeIcon icon={faAngleDown} /></div>
		</div>
		<Button onClick={onDelete}>{t(ETranslation.UI_DELETE)}</Button>
	</InputGroup >
	</>
}

export default UserFavouriteInterpreter;