import { useEffect, useState } from "react"
import { IOption } from "../components/ui/Input/Input";


export const useMultiOptionLabel = (items?: IOption[], values?: string[]) => {
  const [labels, setLabels] = useState<string[] | null>(null);

  useEffect(() => {
    if (!values || !items) return;
    setLabels(items.filter(item => values.includes(item.value)).map(item => item.label || ''));
  }, [items, values]);

  return labels;
}