import { ISite } from "../../interfaces/ISite";
import { NetvisorSettings } from "../../interfaces/INetvisorSettings";
import { useDispatch, useSelector } from "react-redux";
import IAppState from "../../interfaces/store/IAppState";
import { useEffect, useState } from "react";
import { getNetvisorSettings, getNetvisorSettingsClear, saveNetvisorSettingsClear } from "../../store/actions/netvisorSettingsActions";


export const useNetvisorSettings = (site: ISite) => {
	const dispatch = useDispatch();

	const {
		netvisorSettingsGetLoading: loading,
		netvisorSettings: stateSettings,
		netvisorSettingsGetDone: getDone,
		netvisorSettingsGetError: error,
	} = useSelector((state: IAppState) => (state.netvisorSettings))

	const [netvisorSettings, setNetvisorSettings] = useState<NetvisorSettings>()

	useEffect(() => {
		if (!site.id || loading) return;
		if (getDone && stateSettings){
			setNetvisorSettings(new NetvisorSettings(stateSettings));
		}
		if (!loading && !stateSettings && !getDone && site.id !== undefined) {
			dispatch(getNetvisorSettings(site.id));
			return;
		}
		return (() => {
			dispatch(getNetvisorSettingsClear());
			dispatch(saveNetvisorSettingsClear());
		})
	}, [stateSettings, loading, getDone, dispatch, site])

	return { netvisorSettings, loading, getDone, error }
}