import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../components/ui/Container/Container";
import Heading from "../../../components/Heading/Heading";
import { useDispatch } from "react-redux";

import * as actions from "../../../store/actions";
import ReservedDateList from "../../../components/ReservedDate/ReservedDateList/ReservedDateList";
import { useConfirmModal } from "../../../hooks/useConfirmModal";
import { ETranslation } from "../../../translations/translation-keys";

const ReservedDatesListPage: React.FC = () => {
	const dispatch = useDispatch();

	const { t } = useTranslation();
  const openConfirmModal = useConfirmModal();

	useEffect(() => {
		dispatch(actions.findReservedDates());
	}, [dispatch]);

  const deleteHandler = async (id: string) => {
    const success = await openConfirmModal('Haluatko varmasti poistaa varatun ajankohdan?')
    if (success) {
      dispatch(actions.deleteReservedDate(id))
    }
  }

	return (
		<Container>
			<Heading>{t(ETranslation.MENU_RESERVED_DATES)}</Heading>
			<ReservedDateList
				onDelete={deleteHandler}
			/>
		</Container>
	);
};

export default ReservedDatesListPage;
