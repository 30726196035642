import React from "react";
import Table from "../ui/Table/Table";
import Spinner from "../ui/Spinner/Spinner";
import * as dateFns from 'date-fns';
import ICommission from "../../interfaces/ICommission";
import TableSortContext from "../ui/Table/TableSortContext";
import TableTh from "../ui/Table/TableTh/TableTh";
import { useTableSort } from "../ui/Table/useTableSort";

interface IProps {
	commissions: ICommission[] | null;
	loading: boolean;
	error: string | null | undefined;
	openCommission: (commission: ICommission) => void
}

const CommissionsTableList: React.FC<IProps> = ({ commissions, loading, error, openCommission }) => {

	const  {onSort, sort, items} = useTableSort<ICommission>({ data: commissions });

	return (
		<Table hover={true} >
			<thead>
				<TableSortContext.Provider value={{ onSort, sort }}>
					<tr>
						<TableTh sort="firstName|lastName">Nimi</TableTh>
						<TableTh sort="email">Email</TableTh>
						<TableTh sort="cvParseResults.probabilityToBeRecruited">Todennäköisyys %, että hakija tulee rekrytoiduksi</TableTh>
						<TableTh sort="createdDateTime" type="date">Luotu</TableTh>
						<TableTh sort="type">Status</TableTh>
						<TableTh sort="updatedDateTime" type="date">Päivitetty</TableTh>
					</tr>
				</TableSortContext.Provider>
			</thead>
			<tbody>
				{loading ? <Spinner /> :
					error ?
						<div> {error}</div> :
						items && items.map((commission, id) => {
							let dateCreated = "";
							let dateUpadted = "";
							try {
								dateCreated = dateFns.format(dateFns.parse(commission.createdDateTime, "dd.MM.yyyy HH:mm:ss", new Date()), "dd.MM.yyyy HH:mm");
								dateUpadted = dateFns.format(dateFns.parse(commission.updatedDateTime, "dd.MM.yyyy HH:mm:ss", new Date()), "dd.MM.yyyy HH:mm");
							} catch (error) {
								console.error(error);
							}
							return (
								<tr key={id} onClick={() => { openCommission(commission) }} >
									<td>{commission.firstName + " " + commission.lastName}</td>
									<td>{commission.email}</td>
									<td>{(commission.cvParseResults && typeof commission.cvParseResults !== "string") ? commission.cvParseResults.probabilityToBeRecruited : ""}</td>
									<td>{dateCreated}</td>
									<td>{commission.type}</td>
									<td>{dateUpadted}</td>
								</tr>
							)
						})
				}
			</tbody>
		</Table>
	)
}

export default CommissionsTableList;