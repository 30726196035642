import React, { useCallback, useContext } from 'react';

import ModalContext, { EModalSize } from '../ui/Modal/ModalContext';
import AttributeValuesModal from './AttributeValuesModal';
import { IAttribute } from '../../interfaces/IAttribute';
import { IAttributeValues } from '../../interfaces/IAttributeValues';




export const useAttributeValuesModal = () => {
  const { setModal, closeModal } = useContext(ModalContext);
  
  const handler = useCallback(async (attribute: IAttribute) => {
    return new Promise<IAttributeValues>((resolve) => {
      setModal({
        isOpen: true,
        size: EModalSize.MEDIUM,
        title: "Asetukset",
        content: <AttributeValuesModal attribute={attribute} onAccept={(result) => {
          resolve(result);
          closeModal();
        }} />,
      });
    })
  }, [setModal, closeModal]);

  return handler;
};
