import React, { useEffect, useState } from 'react';

import { useCreateInput } from '../../hooks/useCreateInput';
import { IUserShiftMonth } from '../../interfaces/IUserShiftMonth';
import { searchUsers } from '../../services/userServices';
import { USER_SHIFT_MONTH_OPTIONS, USER_SHIFT_YEAR_OPTIONS } from '../../shared/user-shifts-data';
import { ETranslation } from '../../translations/translation-keys';
import { EInputType, IInputField } from '../ui/Input/Input';
import { getInputData, validateInputs } from '../ui/Input/input-utils';
import InputGroup from '../ui/InputGroup/InputGroup';

enum EInputs {
	userId = "userId",
	year = "year",
	month = "month",
}

interface IProps {
	onChange: (userShiftMonth: IUserShiftMonth, isValid: boolean) => void;
	showValidation?: boolean;
}

const UserShiftsEdit: React.FC<IProps> = ({ onChange, showValidation }) => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.userId]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_USER,
			value: [],
			isClearable: true,
		},
		[EInputs.year]: {
			type: EInputType.reactSelect,
			label: "Vuosi",
			value: "",
			options: USER_SHIFT_YEAR_OPTIONS(new Date().getFullYear()),
			validation: {
				required: true,
			},
		},
		[EInputs.month]: {
			type: EInputType.reactSelect,
			label: "Kuukausi",
			value: "",
			options: USER_SHIFT_MONTH_OPTIONS,
			validation: {
				required: true,
			},
		},
	});

	useEffect(() => {
		const shift = getInputData<IUserShiftMonth>(inputs);
		const isValid = validateInputs(inputs);
		onChange(shift, isValid);
	}, [inputs, onChange]);

	const createInput = useCreateInput(inputs, setInputs, {
		showValidation,
	});

	return (
		<InputGroup>
			{createInput(EInputs.userId, {
				fetchOptions: searchUsers,
			})}
			{createInput(EInputs.year)}
			{createInput(EInputs.month)}
		</InputGroup>
	);
};

/*

<tr>
			<td>{formatDate(day.date)}</td>
			<td>
				{day.items.map((item) => (
					<div key={item.id}>
						{item.startTime} - {item.endTime}
					</div>
				))}
			</td>
			<td width={1}>
				<Button
					style={{ whiteSpace: "nowrap" }}
					color={EButtonColor.PRIMARY}
					size={EButtonSize.X_SMALL}
					onClick={editShiftHandler}
				>
					Muokkaa työvuoroa
				</Button>
			</td>
			<td width={1}>
				<Button onClick={() => onDelete(day.date)} size={EButtonSize.X_SMALL} color={EButtonColor.DANGER}>
					Poista
				</Button>
			</td>
		</tr>

		*/

export default UserShiftsEdit;

/*
const derivePossibleDates = (days: IUserShiftDate[], year: number, month: number) => {
	const usedDays = days.map((day) => day.date);
	const lastDayOfMonth = dateFns.lastDayOfMonth(new Date(year, month - 1, 1)).getDate();
	const possibleDates = [];
	for (let i = 1; i <= lastDayOfMonth; i++) {
		const dateObj = new Date(year, month, i);
		const date = dateFns.format(dateObj, "yyyy-MM-dd");
		if (!usedDays.includes(date)) {
			possibleDates.push(date);
		}
	}
	return possibleDates;
};
*/
