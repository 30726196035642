import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { IUserShiftDateItem } from "../../interfaces/IUserShiftDateItem";
import UserShiftDateItemEdit from "./UserShiftDateItemEdit";
import { v4 as uuidV4 } from "uuid";
import Button, { EButtonColor, EButtonSize } from "../ui/Button/Button";
import { EWorkingHoursDateEventType } from "../../interfaces/IWorkingHoursDate";

interface IProps {
	items: IUserShiftDateItem[];
	showValidation: boolean;
}

export interface IUserShiftDateItemsHandle {
	getItems: () => IUserShiftDateItem[];
	isValid: () => boolean;
}

interface IValidation {
	[key: string]: boolean;
}

const UserShiftDateItems: React.ForwardRefRenderFunction<IUserShiftDateItemsHandle, IProps> = (
	{ items, showValidation },
	ref
) => {
	const [isValid, setIsValid] = useState<IValidation>({});
	const [editItems, setEditItems] = useState<IUserShiftDateItem[]>(items);

	const { addHandler, updateHandler, removeHandler } = useHandlers(setEditItems, setIsValid);

	useEffect(() => {
		addHandler();
	}, [addHandler]);

	useImperativeHandle(ref, () => ({
		getItems: () => {
			return [...editItems];
		},
		isValid: () => {
			return Object.keys(isValid).every((key) => isValid[key]);
		},
	}));

	return (
		<>
			{editItems.map((item, i) => (
				<UserShiftDateItemEdit
					key={item.id}
					index={i}
					onChange={updateHandler}
					item={item}
					showValidation={showValidation}
					onRemove={removeHandler}
				/>
			))}
			<Button onClick={addHandler} color={EButtonColor.PRIMARY} size={EButtonSize.SMALL}>
				Lisää aika
			</Button>
		</>
	);
};

const useHandlers = (
	setEditItems: React.Dispatch<React.SetStateAction<IUserShiftDateItem[]>>,
	setIsValid: React.Dispatch<React.SetStateAction<IValidation>>
) => {
	const updateIsValid = useCallback(
		(id: string, value: boolean) => {
			setIsValid((prevIsValid) => ({
				...prevIsValid,
				[id]: value,
			}));
		},
		[setIsValid]
	);

	const updateHandler = useCallback(
		(item: IUserShiftDateItem, isValid: boolean, index?: number) => {
			updateIsValid(item.id, isValid);
			setEditItems((editItems) => {
				const newItems = [...editItems];
				newItems[index!] = item;
				return newItems;
			});
		},
		[updateIsValid, setEditItems]
	);

	const addHandler = useCallback(() => {
		const id = uuidV4();
		updateIsValid(id, false);
		setEditItems((editItems) => [
			...editItems,
			{ id, type: EWorkingHoursDateEventType.WORK, startTime: "", endTime: "", hours: "", description: "", interpretationId: "", salaryPerHour: 0 },
		]);
	}, [updateIsValid, setEditItems]);

	const removeHandler = useCallback(
		(id: string) => {
			setIsValid((prevIsValid) => {
				const newIsValid = { ...prevIsValid };
				delete newIsValid[id];
				return newIsValid;
			});
			setEditItems((editItems) => editItems.filter((item) => item.id !== id));
		},
		[setIsValid, setEditItems]
	);

	return { addHandler, updateHandler, removeHandler };
};

export default forwardRef(UserShiftDateItems);
