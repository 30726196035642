import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ITranslation } from "../../interfaces/ITranslation";
import IAppState from "../../interfaces/store/IAppState";
import { ETranslation } from "../../translations/translation-keys";
import Button, { EButtonColor, EButtonSize } from "../ui/Button/Button";
import Table from "../ui/Table/Table";
import ILanguageState from "../../interfaces/store/ILanguageState";
import { useTranslationContext } from "./translation-context";
import * as actions from '../../store/actions';

interface IProps {
	translations: ITranslation[];
	onDelete?: (id: string, langCode: string) => void;
	onEdit?: (language: ITranslation) => void;
}

const TranslationsTable: React.FC<IProps> = ({
	translations,
	onDelete,
	onEdit,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { languages } = useSelector<IAppState, ILanguageState>(state => state.language);

	useEffect(() => {
		if (!languages) {
			dispatch(actions.listLanguages());
		}
	}, [languages, dispatch]);

	const { isUpdating, isDeleting, actionId } = useTranslationContext();

	if (!translations || !translations.length) return null;
	return (
		<Table>
			<thead>
				<tr>
					<th>{t(ETranslation.LANGUAGE_LANGUAGE_CODE)}</th>
					<th>{t(ETranslation.LANGUAGE_TRANSLATION)}</th>
					{onDelete && <th></th>}
				</tr>
			</thead>
			<tbody>
				{translations.map((translation) => (
					<tr key={translation.languageCode}>
						<td>
							{`${
								languages?.find(
									(l) =>
										l.languageCode === translation.languageCode
								)?.name ?? ""
							} (${translation.languageCode})`}
						</td>
						<td>{translation.name}</td>
						{(onEdit || onDelete) && (
							<td width="1px" style={{ whiteSpace: "nowrap" }}>
								<div style={{ display: "flex", gap: "0.5rem" }}>
									{onEdit && (
										<Button
											color={EButtonColor.PRIMARY}
											size={EButtonSize.SMALL}
											loading={
												isUpdating &&
												actionId === translation.languageCode
											}
											onClick={() => onEdit(translation)}
										>
											{t(
												ETranslation.LANGUAGE_EDIT_TRANSLATION
											)}
										</Button>
									)}
									{onDelete && (
										<Button
											color={EButtonColor.DANGER}
											size={EButtonSize.SMALL}
											loading={
												isDeleting &&
												actionId === translation.id
											}
											onClick={() =>
												onDelete(translation.id, translation.languageCode)
											}
										>
											{t(ETranslation.COMMON_DELETE)}
										</Button>
									)}
								</div>
							</td>
						)}
					</tr>
				))}
			</tbody>
		</Table>
	);
};

export default TranslationsTable;
