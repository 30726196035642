import React from "react";
import { IInterpretationEmails } from "../../../../interfaces/IInterpretation";
import Table from "../../../ui/Table/Table";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../../translations/translation-keys";


interface Iprops {
	mails: IInterpretationEmails[];
}

const InterpretationEmails: React.FC<Iprops> = ({ mails }) => {

	const { t } = useTranslation();
	return <Table>
		<thead>
			<tr>
				<th>{t(ETranslation.COMMON_EMAIL_SENT)}</th>
				<th>{t(ETranslation.COMMON_EMAIL_SENDER)}</th>
				<th>{t(ETranslation.COMMON_EMAIL_RECIPIENT)}</th>
				<th>{t(ETranslation.COMMON_EMAIL_TITLE)}</th>
				<th>{t(ETranslation.COMMON_EMAIL_CONTENT)}</th>
			</tr>
		</thead>
		<tbody>
			{mails.map((mail, key) => {
				return <tr key={key}>
					<td>
						{mail.created}
					</td>
					<td>
						{mail.from}
					</td>
					<td>
						{mail.to}
					</td>
					<td>
						{mail.subject}
					</td>
					<td>
						{mail.content}
					</td>
				</tr>
			})}
		</tbody>
	</Table>
}

export default InterpretationEmails;