import { EInfoContactRequestSubject, EInfoContactRequestStatus } from './../interfaces/IInfoContactRequest';
import { IOption } from "../components/ui/Input/Input";
import { ETranslation } from '../translations/translation-keys';
import { TFunction } from 'i18next';


const municipalities = [
  "Asikkala", "Hartola", "Heinola", "Hollola", "Hyvinkää", "Iitti", 
  "Järvenpää", "Kärkölä", "Lahti", "Myrskylä", "Mäntsälä", "Nurmijärvi", 
  "Orimattila", "Padasjoki", "Pukkila", "Sysmä", "Tuusula", "Muu"
];

export const INFO_CONTACT_REQUEST_MUNICIPALITIES: IOption[] = municipalities.map(m => ({ value: m, label: m }));

export const INFO_CONTACT_REQUEST_SUBJECTS: IOption[] = [
  { value: EInfoContactRequestSubject.IMMIGRATION, labelTranslation: ETranslation.DATA_CONTACT_REQUEST_IMMIGRATION },
  { value: EInfoContactRequestSubject.PERMIT_MIGRI, labelTranslation: ETranslation.DATA_CONTACT_REQUEST_PERMIT_MIGRI },
  { value: EInfoContactRequestSubject.CLARIFICATION_AND_FORMS, labelTranslation: ETranslation.DATA_CONTACT_REQUEST_CLARIFICATION_AND_FORMS },
  { value: EInfoContactRequestSubject.STUDYING, labelTranslation: ETranslation.DATA_CONTACT_REQUEST_STUDYING },
  { value: EInfoContactRequestSubject.WORK, labelTranslation: ETranslation.DATA_CONTACT_REQUEST_WORK },
  { value: EInfoContactRequestSubject.OTHER, labelTranslation: ETranslation.COMMON_OTHER},
];

export const getInfoContactRequestSubjectString = (t: TFunction, subject: EInfoContactRequestSubject, subjectOther?: string): string => {
  if(subject === EInfoContactRequestSubject.OTHER) return subjectOther || "";
  return t(INFO_CONTACT_REQUEST_SUBJECTS.find(item => item.value === subject)?.labelTranslation || "");
}

export const INFO_CONTACT_REQUEST_STATUSES: IOption[] = [
  { value: EInfoContactRequestStatus.NEW, labelTranslation: ETranslation.COMMON_NEW},
  { value: EInfoContactRequestStatus.IN_PROGRESS, labelTranslation: ETranslation.COMMON_IN_PROGRESS},
  { value: EInfoContactRequestStatus.COMPLETED, labelTranslation: ETranslation.COMMON_COMPLETE},
  { value: EInfoContactRequestStatus.CANCELED, labelTranslation: ETranslation.DATA_CONTACT_REQUEST_CANCELED }
]

export const getInfoContactRequestStatusString = (
  t: TFunction, status: EInfoContactRequestStatus 
): string => t(INFO_CONTACT_REQUEST_STATUSES.find(item => item.value === status)?.labelTranslation || "");