import React, { useCallback, useEffect, useState } from "react";
import { IFee, IFeeRow } from "../../store/actions/feeActions";
import { EInputType, IInputField } from "../ui/Input/Input";
import { useCreateInput } from "../../hooks/useCreateInput";
import FeeRowItemEdit from "./FeeRowItemEdit";
import classes from "./feeStyles.module.scss";
import Button from "../ui/Button/Button";
import { getInputData } from "../ui/Input/input-utils";
import * as dateFns from "date-fns";
import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";

interface IProps {
	fee: IFee;
	onUpdate: (fee: IFee) => void;
}

enum EInputs {
	id = "id",
	feeDate = "feeDate",
}

const FeeItemEdit: React.FC<IProps> = ({ fee, onUpdate }) => {

	const { t } = useTranslation()
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.id]: {
			type: EInputType.text,
			label: "id",
			value: fee.id,
		},
		[EInputs.feeDate]: {
			type: EInputType.datepicker,
			labelTranslation: ETranslation.PAYMENT_DATE,
			dateAsString: true,
			value: fee.feeDate ? dateFns.parse(
				fee.feeDate,
				"dd.MM.yyyy",
				new Date()
			) : null,
		},
	});

	const addRow = () => {
		let feeRowsToAlter: IFeeRow[];
		if (fee.feeRows) {
			feeRowsToAlter = [...fee.feeRows];
		} else {
			feeRowsToAlter = [];
		}
		feeRowsToAlter.push({
			// Solely for the purpose of key and removing existing tags.
			id: new Date().getTime().toString(),
			created: "",
			updated: "",
			type: "ACCEPTANCE_FEE",
			feeVatP: 0,
			feeWithoutVat: 0,
			amount: 0,
			unit: "PCS",
			currency: "EUR",
			descriptionText: "",
			adminNotesText: "",
			user: null,
			paymentMethod: "EEZY"
		});
		onUpdate({ ...fee, feeRows: feeRowsToAlter });
	}

	useEffect(() => {
		const data = getInputData<IFee>(inputs);
		onUpdate({ ...fee, feeDate: data.feeDate });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs])

	const createInput = useCreateInput(inputs, setInputs);

	const onFeeRowUpdate = useCallback((rowItem) => {
		const feeToAlter = { ...fee, feeRows: [...fee.feeRows.map((item) => item.id !== rowItem.id ? item : rowItem)] }
		if (feeToAlter !== fee) {
			onUpdate(feeToAlter);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fee])

	return (
		<>
			<div className={classes.dateContainer}>
				{createInput(EInputs.feeDate)}
			</div>
			<div className={classes.feeRowsControllers}>
				<Button onClick={addRow}>{t(ETranslation.PAYMENT_ROW)}</Button>
			</div>
			<div>
				{fee.feeRows.map((row, id) => {
					return <FeeRowItemEdit key={row.id} feeRow={row} onUpdate={onFeeRowUpdate} onDelete={(rowItem) => {
						onUpdate({ ...fee, feeRows: [...fee.feeRows.filter((item) => item.id !== rowItem.id)] });
					}} />
				})}
			</div>
		</>
	)
}

export default FeeItemEdit;