import { Dispatch } from 'redux';

import { customFetch, customFetchWithResponse, EFetchMethod } from '../../custom-fetch';
import { ILogItem } from '../../interfaces/ILogItem';
import EActionTypes from '../../interfaces/store/EActionTypes';
import ILogItemAction from '../../interfaces/store/ILogItemAction';
import { IPaginationOptions } from '../../hooks/usePaginationNew';

type TAction = ILogItemAction;

export interface ILogItemSearchOptions {
	createdDateStart?: string;
	createdDateStartTime?: string;
	createdDateEnd?: string;
	createdDateEndTime?: string;
	user?: string;
}

const findLogItemsStart = (): TAction => {
	return {
		type: EActionTypes.LOG_ITEM_FIND_START,
	};
};

const findLogItemsSuccess = (logItems: ILogItem[], paginationCursor?: string): TAction => {
	return {
		type: EActionTypes.LOG_ITEM_FIND_SUCCESS,
		paginationCursor: paginationCursor ?? null,
		logItems,
	};
};

const findLogItemsFail = (error: string): TAction => {
	return {
		type: EActionTypes.LOG_ITEM_FIND_FAIL,
		error,
	};
};

export const findLogItemsClear = (): TAction => {
	return {
		type: EActionTypes.LOG_ITEM_FIND_CLEAR,
	};
};

export const findEntityLogItems = (searchParams: IPaginationOptions, signal: AbortSignal) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(findLogItemsStart());
			customFetchWithResponse("/logitems/list/entity", EFetchMethod.POST, JSON.stringify(searchParams), signal).then((res) => {
				const paginationCursor = res.headers.get("Pagination-Cursor") ?? "";
				res.json().then((logItems) => {
					dispatch(findLogItemsSuccess(logItems, paginationCursor));
				});
			});
		} catch (error) {
			dispatch(findLogItemsFail((error as Error).message));
		}
	}
}


export const findLogItems = (searchParams: ILogItemSearchOptions) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(findLogItemsStart());
			const logItems = await customFetch<ILogItem[]>("/logitems/list", EFetchMethod.POST, JSON.stringify(searchParams));
			dispatch(findLogItemsSuccess(logItems));
		} catch (error) {
			dispatch(findLogItemsFail((error as Error).message));
		}
	};
};

const getLogItemStart = (id: string): TAction => {
	return {
		type: EActionTypes.LOG_ITEM_GET_START,
		id
	};
};

const getLogItemSuccess = (logItem: ILogItem): TAction => {
	return {
		type: EActionTypes.LOG_ITEM_GET_SUCCESS,
		logItem,
	};
};

const getLogItemFail = (error: string): TAction => {
	return {
		type: EActionTypes.LOG_ITEM_GET_FAIL,
		error,
	};
};

export const getLogItemClear = (): TAction => {
	return {
		type: EActionTypes.LOG_ITEM_GET_CLEAR,
	};
};

export const getLogItem = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getLogItemStart(id));
			const logItem = await customFetch<ILogItem>(`/logitems/get?id=${id}`);
			dispatch(getLogItemSuccess(logItem));
		} catch (error) {
			dispatch(getLogItemFail((error as Error).message));
		}
	};
};

const deleteLogItemStart = (): TAction => {
	return {
		type: EActionTypes.LOG_ITEM_DELETE_START,
	};
};

const deleteLogItemSuccess = (id: string): TAction => {
	return {
		type: EActionTypes.LOG_ITEM_DELETE_SUCCESS,
		id,
	};
};

const deleteLogItemFail = (error: string): TAction => {
	return {
		type: EActionTypes.LOG_ITEM_DELETE_FAIL,
		error,
	};
};

export const deleteLogItemClear = (): TAction => {
	return {
		type: EActionTypes.LOG_ITEM_DELETE_CLEAR,
	};
};

export const deleteLogItem = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(deleteLogItemStart());
			await customFetch<boolean>(`/logitems/delete?id=${id}`, EFetchMethod.DELETE);
			dispatch(deleteLogItemSuccess(id));
		} catch (error) {
			dispatch(deleteLogItemFail((error as Error).message));
		}
	};
};

const saveOrUpdateLogItemStart = (): TAction => {
	return {
		type: EActionTypes.LOG_ITEM_SAVE_OR_UPDATE_START,
	};
};

const saveOrUpdateLogItemSuccess = (): TAction => {
	return {
		type: EActionTypes.LOG_ITEM_SAVE_OR_UPDATE_SUCCESS,
	};
};

const saveOrUpdateLogItemFail = (error: string): TAction => {
	return {
		type: EActionTypes.LOG_ITEM_SAVE_OR_UPDATE_FAIL,
		error,
	};
};

export const saveOrUpdateLogItemClear = (): TAction => {
	return {
		type: EActionTypes.LOG_ITEM_SAVE_OR_UPDATE_CLEAR
	}
}

export const saveLogItem = (logItem: ILogItem) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(saveOrUpdateLogItemStart());
			await customFetch<ILogItem>("/logitems/add", EFetchMethod.POST, JSON.stringify(logItem));
			dispatch(saveOrUpdateLogItemSuccess());
		} catch (error) {
			dispatch(saveOrUpdateLogItemFail((error as Error).message));
		}
	};
};

export const updateLogItem = (logItem: ILogItem) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(saveOrUpdateLogItemStart());
			await customFetch<ILogItem>("/logitems/update", EFetchMethod.PUT, JSON.stringify(logItem));
			dispatch(saveOrUpdateLogItemSuccess());
		} catch (error) {
			dispatch(saveOrUpdateLogItemFail((error as Error).message));
		}
	};
};


