import EActionTypes from '../../interfaces/store/EActionTypes';
import ISiteAction from '../../interfaces/store/ISiteAction';
import ISiteState from '../../interfaces/store/ISiteState';
import { ETranslation } from '../../translations/translation-keys';

type ReducerAction = ISiteAction;
type ReducerState = ISiteState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  site: null,
  siteLoading: false,
  siteError: null,

  sites: null,
  sitesLoading: false,
  sitesError: null,

  siteSaveOrUpdateOk: false,
  siteSaveOrUpdateError: null,
  siteSaveOrUpdateLoading: false,

  siteDeleteError: null,
  siteDeleteLoading: false,
  siteDeleteOk: false,

};

const findSitesStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, sitesError: null, sitesLoading: true };
};

const findSitesSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    sites: action.sites || null,
    sitesError: null,
    sitesLoading: false,
  };
};

const findSitesFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, sites: null, sitesLoading: false, sitesError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getSiteStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, siteError: null, siteLoading: true };
};

const getSiteSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    site: action.site || null,
    siteError: null,
    siteLoading: false,
  };
};

const getSiteFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, site: null, siteLoading: false, siteError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};

const getSiteClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, site: null, siteLoading: false, siteError: null};
};

const deleteSiteStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, siteDeleteError: null, siteDeleteLoading: true, siteDeleteOk: false };
};

const deleteSiteSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    siteDeleteError: null,
    siteDeleteLoading: false,
    siteDeleteOk: true
  };
};

const deleteSiteFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, siteDeleteLoading: false, siteDeleteError: action.error as string, siteDeleteOk: false };
};

const deleteSiteClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, siteDeleteError: null, siteDeleteLoading: false, siteDeleteOk: false };
};

const saveOrUpdateSiteStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, siteSaveOrUpdateError: null, siteSaveOrUpdateLoading: true };
};

const saveOrUpdateSiteSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    sites: null,
    siteSaveOrUpdateError: null,
    siteSaveOrUpdateLoading: false,
    siteSaveOrUpdateOk: true
  };
};

const saveOrUpdateSiteFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, siteSaveOrUpdateLoading: false, siteSaveOrUpdateError: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const saveOrUpdateSiteClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, siteSaveOrUpdateLoading: false, siteSaveOrUpdateError: null, siteSaveOrUpdateOk: false }
}


const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.SITE_FIND_START:
      return findSitesStart(state, action);
    case EActionTypes.SITE_FIND_SUCCESS:
      return findSitesSuccess(state, action);
    case EActionTypes.SITE_FIND_FAIL:
      return findSitesFail(state, action);
    case EActionTypes.SITE_GET_START:
      return getSiteStart(state, action);
    case EActionTypes.SITE_GET_SUCCESS:
      return getSiteSuccess(state, action);
    case EActionTypes.SITE_GET_FAIL:
      return getSiteFail(state, action);
    case EActionTypes.SITE_GET_CLEAR:
      return getSiteClear(state, action);
    case EActionTypes.SITE_DELETE_START:
      return deleteSiteStart(state, action);
    case EActionTypes.SITE_DELETE_SUCCESS:
      return deleteSiteSuccess(state, action);
    case EActionTypes.SITE_DELETE_FAIL:
      return deleteSiteFail(state, action);
    case EActionTypes.SITE_DELETE_CLEAR:
      return deleteSiteClear(state, action);
    case EActionTypes.SITE_SAVE_OR_UPDATE_START:
      return saveOrUpdateSiteStart(state, action);
    case EActionTypes.SITE_SAVE_OR_UPDATE_SUCCESS:
      return saveOrUpdateSiteSuccess(state, action);
    case EActionTypes.SITE_SAVE_OR_UPDATE_FAIL:
      return saveOrUpdateSiteFail(state, action);
    case EActionTypes.SITE_SAVE_OR_UPDATE_CLEAR:
      return saveOrUpdateSiteClear(state, action)
    default:
      return state;
  }
};

export default reducer;
