import React, { useCallback, useEffect, useState } from "react";
import Container from "../../components/ui/Container/Container";
import Heading from "../../components/Heading/Heading";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IFee, getFee, saveOrAddFee, feeSaveClear, deleteFee, feeDeleteClear } from "../../store/actions/feeActions";
import IAppState from "../../interfaces/store/IAppState";
import Spinner from "../../components/ui/Spinner/Spinner";
import FeeItemEdit from "../../components/Fee/FeeItemEdit";
import EditButtons from "../../components/ui/EditButtons/EditButtons";
import { ERoute, Routes } from "../../classes/Routes";
import * as dateFns from "date-fns";
import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";

interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

const FeeEditPage: React.FC<IProps> = ({ match, history }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch();
	const { id } = match.params;
	const isAdd = id === "add";
	const { currentUser: user } = useSelector((state: IAppState) => ({
		currentUser: state.auth.user
	}));

	const [selectedFee, setSelectedFee] = useState<IFee>()

	useEffect(() => {
		if (isAdd && user) {
			const initialFeeItem: IFee = {
				id: "",
				created: dateFns.format(new Date(), "dd.MM.yyyy"),
				feeDate: dateFns.format(new Date(), "dd.MM.yyyy"),
				updated: dateFns.format(new Date(), "dd.MM.yyyy"),
				users: [],
				organizations: [],
				site: user?.site ?? "",
				feeRows: [],
			}
			setSelectedFee(initialFeeItem);
			return;
		}
		dispatch(getFee(id));
	}, [id, isAdd, user, dispatch])

	const {
		fee,
		error,
		feeLoading,
		feeSaveLoading,
		feeSaveSuccess,
		feeDeleteLoading,
		feeDeleteError,
		feeDeleteSuccess
	} = useSelector((state: IAppState) => state.fees);

	useEffect(() => {
		if (!fee) { return }
		setSelectedFee(fee);
	}, [fee])

	useEffect(() => {
		if (!feeSaveSuccess) { return }
		dispatch(feeSaveClear());
		history.push(Routes.withParams(ERoute.FEES_LIST, {}));
	}, [feeSaveSuccess, history, dispatch])

	const onFeeUpdate = useCallback((fee: IFee) => {
		setSelectedFee(fee);
	}, [])

	const saveOrUpdate = () => {
		if (!selectedFee) { return }
		const toSave = { ...selectedFee };
		if (isAdd) { toSave.id = null }
		dispatch(saveOrAddFee(toSave, isAdd))
	}

	return (
		<Container>
			<Heading>{t(ETranslation.PAYMENT)} </Heading>
			{error ?
				<span>{error ?? "Virhe maksun latauksessa"}</span>
				:
				feeLoading || feeSaveLoading ?
					<Spinner />
					: selectedFee &&
					<FeeItemEdit fee={selectedFee} onUpdate={onFeeUpdate} />
			}
			<EditButtons
				isAdd={isAdd}
				onSave={saveOrUpdate}
				onDelete={()=>{ (selectedFee && selectedFee.id) ? dispatch(deleteFee(selectedFee.id)): void(null)}}
				deleteError={feeDeleteError}
				deleteLoading={feeDeleteLoading}
				deleteOk={feeDeleteSuccess}
				onDeleteDone={()=>{dispatch(feeDeleteClear()); history.push(Routes.withParams(ERoute.FEES_LIST, {}))}}
				onCancel={() => { dispatch(feeSaveClear()); history.push(Routes.withParams(ERoute.FEES_LIST, {})); }}
			/>
		</Container>
	)
}

export default FeeEditPage;