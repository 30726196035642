import { useState } from "react";
import { IUser } from "../../../classes/User";
import { ETranslation } from "../../../translations/translation-keys";
import { IInputField, EInputType, RoleSelections } from "../../ui/Input/Input";
import { useUser } from "../../../hooks/useUser";
import { INTERPRETER_FEE_TYPE_ALL } from "../../../shared/interpretation-data";
import { LOCATION_OPTIONS } from "../../../shared/location-data";

export const useUserEditInputs = (user: IUser) => {

	const EInputs = {
		id: "id",
		name: "name",
		fullName: "fullName",
		email: "email",
		userName: "userName",
		status: "status",
		gender: "gender",
		phoneNumber: "phoneNumber",
		role: "role",
		isYoupretAdmin: "isYoupretAdmin",
		site: "site",
		firstName: "firstName",
		lastName: "lastName",
		allowCMS: "allowCMS",
		allowReport: "allowReport",
		allowSummaries: "allowSummaries",
		allowMessage: "allowMessage",
		allowBank: "allowBank",
		allowLogs: "allowLogs",
		allowInstant: "allowInstant",
		allowBooking: "allowBooking",
		allowFees: "allowFees",
		allowEezy: "allowEezy",
		allowOnSite: "allowOnSite",
		allowVideo: "allowVideo",
		allowFavoriteInterpreters: "allowFavoriteInterpreters",
		showOrganizationInterpretations: "showOrganizationInterpretations",
		isFavorite: "isFavorite",
		description: "description",
		organizations: "organizations",
		userGroupIds: "userGroupIds",
		identity: "identity",
		showOrganizationGroupInterpretations: "showOrganizationGroupInterpretations",
		paymentMethodType: "paymentMethodType",
		lastSeenDate: "lastSeenDate",
		allowMachine: "allowMachine",
		createdDate: "createdDate",
		streetName: "streetName",
		locations: "locations",
		zip: "zip",
		city: "city",
		privatePerson: "privatePerson",
		allowInternetCall: "allowInternetCall",
		manageOrganizationUsers: "manageOrganizationUsers",
		phoneNumberConfirmed: "phoneNumberConfirmed",
		autoPayment: "autoPayment",
		allowMarketing: "allowMarketing",
		allowAutomaticOfferBookings: "allowAutomaticOfferBookings",
		allowMultiDeviceSimultaneousUse: "allowMultiDeviceSimultaneousUse",
		allowPhoneCall: "allowPhoneCall",
		sendEmailBookingConfirmed: "sendEmailBookingConfirmed",
		allowFastLane: "allowFastLane",
		showPayments: "showPayments",
		showTestLanguages: "showTestLanguages",
		allowLogin: "allowLogin",
		tags: "tags",
		country: "country",
		blockedUsers: "blockedUsers",
		netvisorDimensionName: "netvisorDimensionName",
		netvisorProductIdentifier: "netvisorProductIdentifier",
		netvisorProductName: "netvisorProductName",
		feeType: "feeType",
		allowJobOffers: "allowJobOffers",
		contractNo: "contractNo",
		securityCleared: "securityCleared",
		socialSecurityNumber: "socialSecurityNumber",
		iban: "iban",
		swift: "swift",
		educationDescription: "educationDescription",
		password: "password",
		passwordAgain: "passwordAgain",
		workExperienceDescription: "workExperienceDescription",
		altPhoneNumber: "altPhoneNumber",
		emailCC: "emailCC",
		emailBCC: "emailBCC",
		interpreterFeeVatP: "interpreterFeeVatP",
		interpreterFeeWithoutVatPerMinuteForBooking: "interpreterFeeWithoutVatPerMinuteForBooking",
		interpreterFeeWithoutVatPerMinuteForInstant: "interpreterFeeWithoutVatPerMinuteForInstant",
		interpreterFeeWithoutVatPerMinuteForOnSite: "interpreterFeeWithoutVatPerMinuteForOnSite",
		hideInterpreterFees: "hideInterpreterFees",
		isInterpreterTrained: "isInterpreterTrained",
		eezySalaryPaymentRule: "eezySalaryPaymentRule",
		allowWorkingHoursDate: "allowWorkingHoursDate",
		allowWorkingHoursDateEdit: "allowWorkingHoursDateEdit",
		interpretationTypes: "interpretationTypes",
		reference: "reference",
		billingReference: "billingReference",
		userLevel: "userLevel",
		isLegalInterpreter: "isLegalInterpreter",
	}

	const { USER_STATUS_ALL } = useUser();

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.id]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_FIRST_NAME,
			value: user?.id,
		},
		[EInputs.firstName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_FIRST_NAME,
			value: user?.firstName ?? "",
			validation: {
				required: true,
			}
		},
		[EInputs.lastName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_LAST_NAME,
			value: user?.lastName ?? "",
			validation: {
				required: true,
			}
		},
		[EInputs.interpretationTypes]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.USER_ALLOWED_TYPES,
			value: user?.interpretationTypes ?? [],
			multiple: true,
		},
		[EInputs.email]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_EMAIL,
			value: user?.email ?? "",
			validation: {
				required: true,
			}
		},
		[EInputs.emailCC]: {
			type: EInputType.text,
			labelTranslation: ETranslation.USER_COPY_OF_EMAIL,
			value: user.emailCC,
		},
		[EInputs.password]: {
			type: EInputType.password,
			labelTranslation: ETranslation.USER_PASSWORD,
			value: "******",
		},
		[EInputs.passwordAgain]: {
			type: EInputType.password,
			labelTranslation: ETranslation.USER_PASSWORD_AGAIN,
			value: "******",
		},
		[EInputs.emailBCC]: {
			type: EInputType.text,
			labelTranslation: ETranslation.USER_EMAIL_BCC,
			value: user.emailBCC,
		},
		[EInputs.userLevel]: {
			type: EInputType.number,
			labelTranslation: ETranslation.USER_USER_LEVEL,
			value: user.userLevel,
		},
		[EInputs.userName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.USER_USERNAME,
			value: user.userName,
		},
		[EInputs.phoneNumber]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_PHONENUMBER,
			value: user.phoneNumber,
		},
		[EInputs.altPhoneNumber]: {
			type: EInputType.text,
			labelTranslation: ETranslation.USER_ALTERNATE_PHONENUMBER,
			value: user.altPhoneNumber,
		},
		[EInputs.role]: {
			type: EInputType.reactSelect,
			options: RoleSelections(),
			labelTranslation: ETranslation.COMMON_ROLE,
			value: user.role,
		},
		[EInputs.locations]: {
			type: EInputType.reactSelect,
			options: LOCATION_OPTIONS,
			labelTranslation: ETranslation.USER_LOCATION,
			value: user.locations,
			multiple: true,
		},
		[EInputs.country]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_COUNTRY,
			value: user.country,
		},
		[EInputs.organizations]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_ORGANIZATION,
			multiple: true,
			value: user.organizations?.map((org) => {
				return {
					value: org.id,
					label: org.name,
				};
			}) || [],
		},
		[EInputs.userGroupIds]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.USER_GROUPS_LIST_TITLE,
			value: user.userGroupIds ?? [],
			multiple: true,
		},
		[EInputs.description]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.USER_ADDITIONAL_DETAILS,
			value: user.description,
		},
		[EInputs.reference]: {
			type: EInputType.text,
			labelTranslation: ETranslation.USER_REFERENCE,
			value: user.reference,
		},
		[EInputs.billingReference]: {
			type: EInputType.text,
			labelTranslation: ETranslation.USER_BILLING_REFERENCE,
			value: user.billingReference,
		},
		[EInputs.streetName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_ADDRESS,
			value: user.streetName,
		},
		[EInputs.zip]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_ZIP,
			value: user.zip,
		},
		[EInputs.city]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_LOCATION,
			value: user.city,
		},
		[EInputs.tags]: {
			type: EInputType.reactSelect,
			multiple: true,
			labelTranslation: ETranslation.COMMON_TAGS,
			value: user.tags ? user.tags.map((tag) => { return { value: tag.id, label: tag.name } }) : [],
		},
		[EInputs.allowFavoriteInterpreters]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_FAVORITE_INTERPRETER,
			value: user.allowFavoriteInterpreters as boolean,
		},
		[EInputs.allowCMS]: {
			type: EInputType.boolean,
			label: "Allow CMS",
			value: user.allowCMS as boolean,
		},
		[EInputs.allowWorkingHoursDate]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_WORKING_HOURS_DATE,
			value: user.allowWorkingHoursDate as boolean,
		},
		[EInputs.allowWorkingHoursDateEdit]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_WORKING_HOURS_DATE_EDIT,
			value: user.allowWorkingHoursDateEdit as boolean,
		},
		[EInputs.allowFastLane]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_FASTLANE,
			value: user.allowFastLane as boolean,
		},
		[EInputs.allowInternetCall]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_VOIP,
			value: user.allowInternetCall as boolean,
		},
		[EInputs.allowPhoneCall]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_PHONE_CALLS,
			value: user.allowPhoneCall as boolean,
		},
		[EInputs.securityCleared]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.COMMON_SECURITY_CLEARED,
			value: user.securityCleared as boolean,
		},
		[EInputs.sendEmailBookingConfirmed]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_EMAIL_WHEN_RESERVATION_CONFIRMED,
			value: user.sendEmailBookingConfirmed as boolean,
		},
		[EInputs.phoneNumberConfirmed]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_PHONE_CONFIRMED,
			value: user.phoneNumberConfirmed as boolean,
		},
		[EInputs.allowBooking]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_BOOKING,
			value: user.allowBooking as boolean,
		},
		[EInputs.showTestLanguages]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_DISPLAY_TEST_LANGUAGES,
			value: user.showTestLanguages as boolean,
		},
		[EInputs.feeType]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.USER_BILLING_METHOD,
			options: INTERPRETER_FEE_TYPE_ALL,
			value: user.feeType,
		},

		[EInputs.gender]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.COMMON_GENDER,
			options: [
				{
					value: "UNKNOWN",
					labelTranslation: ETranslation.COMMON_UNKOWN,
				},
				{
					value: "MALE",
					labelTranslation: ETranslation.COMMON_GENDER_MALE,
				},
				{
					value: "FEMALE",
					labelTranslation: ETranslation.COMMON_GENDER_FEMALE,
				},
			],
			value: user.gender,
		},
		[EInputs.paymentMethodType]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.COMMON_PAYMENT_METHOD,
			options: [
				{
					value: "INVOICE",
					labelTranslation: ETranslation.USER_PAYMENT_BY_INVOICE,
				},
				{
					value: "STRIPE",
					labelTranslation: ETranslation.USER_PAYMENT_STRIPE,
				},
				{
					value: "NO_CHARGE",
					labelTranslation: ETranslation.USER_PAYMENT_NO_CHARGE,
				},
				{
					value: "ZETTLE",
					labelTranslation: ETranslation.USER_PAYMENT_ZETTLE,
				},
			],
			value: user.paymentMethodType,
		},
		[EInputs.status]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.USER_STATUS_OF_INTERPRETER,
			options: USER_STATUS_ALL,
			value: user.status,
		},
		[EInputs.contractNo]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.USER_CONTRACT,
			options: [
				{
					value: "NO",
					labelTranslation: ETranslation.USER_CONTRACT_NOT_MADE,
				},
				{
					value: "SEND",
					labelTranslation: ETranslation.USER_CONTRACT_SEND,
				},
				{
					value: "COMPLETED",
					labelTranslation: ETranslation.USER_CONTRACT_COMPLETED,
				},
			],
			value: user.contractNo,
		},
		[EInputs.allowVideo]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_VIDEO,
			value: user.allowVideo as boolean,
		},
		[EInputs.autoPayment]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_AUTOMATIC_PAYMENT,
			value: user.autoPayment as boolean,
		},
		[EInputs.allowLogs]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_LOGS,
			value: user.allowLogs as boolean,
		},
		[EInputs.showPayments]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_SHOW_PAYMENT,
			value: user.showPayments as boolean,
		},
		[EInputs.manageOrganizationUsers]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_MANAGE_ORGANIZATION_USERS,
			value: user.manageOrganizationUsers as boolean,
		},
		[EInputs.allowFees]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_ADMIN_FEES,
			value: user.allowFees as boolean,
		},
		[EInputs.allowEezy]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_ADMIN_EEZY_TRUSTER,
			value: user.allowEezy as boolean,
		},
		[EInputs.showOrganizationInterpretations]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_SHOW_ORGANIZATION_INTERPRETATIONS,
			value: user.showOrganizationInterpretations as boolean,
		},
		[EInputs.allowAutomaticOfferBookings]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_AUTOMATIC_OFFER_BOOKINGS,
			value: user.allowAutomaticOfferBookings as boolean,
		},
		[EInputs.allowJobOffers]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_JOB_OFFERS,
			value: user.allowJobOffers as boolean,
		},
		[EInputs.allowInstant]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_INSTANT,
			value: user.allowInstant as boolean,
		},
		[EInputs.allowMarketing]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_MARKETING,
			value: user.allowMarketing as boolean,
		},
		[EInputs.site]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_SITE,
			value: user.site ? { value: user.site?.id, label: user.site?.name } : null,
		},
		[EInputs.allowLogin]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_LOGIN,
			value: user.allowLogin as boolean,
		},
		[EInputs.privatePerson]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_PRIVATE_USER,
			value: user.privatePerson as boolean,
		},
		[EInputs.allowOnSite]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_ON_SITE,
			value: user.allowOnSite as boolean,
		},
		[EInputs.allowMachine]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_MACHINE,
			value: user.allowMachine as boolean,
		},
		[EInputs.allowMultiDeviceSimultaneousUse]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_MULTIPLE_DEVICE,
			value: user.allowMultiDeviceSimultaneousUse as boolean,
		},
		[EInputs.allowReport]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_REPORTING,
			value: user.allowReport as boolean,
		},
		[EInputs.allowSummaries]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_ALLOW_SUMMARIES,
			value: user.allowSummaries as boolean,
		},
		[EInputs.showOrganizationGroupInterpretations]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_SHOW_ALL_GROUP_INTERPRETATIONS,
			value: user.showOrganizationGroupInterpretations as boolean,
		},
		[EInputs.blockedUsers]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_BLOCKED_INTERPRETERS,
			multiple: true,
			value: user.blockedUsers?.map((user) => { return { value: user.id, label: user.firstName + " " + user.lastName } }),
		},
		[EInputs.netvisorDimensionName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.USER_NETVISOR_DIMENSION_NAME,
			value: user.netvisorDimensionName,
		},
		[EInputs.netvisorProductIdentifier]: {
			type: EInputType.text,
			labelTranslation: ETranslation.USER_NETVISOR_PRODUCT_IDENTIFIER,
			value: user.netvisorProductIdentifier,
		},
		[EInputs.socialSecurityNumber]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_SOCIAL_SECURITY_NUMBER,
			value: user.socialSecurityNumber,
		},
		[EInputs.netvisorProductName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.USER_NETVISOR_PRODUCT_NAME,
			value: user.netvisorProductName,
		},
		[EInputs.iban]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_IBAN,
			value: user.iban,
		},
		[EInputs.swift]: {
			type: EInputType.text,
			labelTranslation: ETranslation.USER_SWIFT,
			value: user.swift,
		},
		[EInputs.educationDescription]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMISSIONS_EDUCATION_EXPERIENCE,
			value: user.educationDescription,
		},
		[EInputs.workExperienceDescription]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMISSIONS_WORK_EXPERIENCE,
			value: user.workExperienceDescription,
		},
		[EInputs.interpreterFeeVatP]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_VAT,
			value: user.interpreterFeeVatP,
		},
		[EInputs.interpreterFeeWithoutVatPerMinuteForBooking]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_BOOKING_WITHOUT_VAT,
			value: user.interpreterFeeWithoutVatPerMinuteForBooking,
		},
		[EInputs.interpreterFeeWithoutVatPerMinuteForInstant]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_INSTANT_WITHOUT_VAT,
			value: user.interpreterFeeWithoutVatPerMinuteForInstant,
		},
		[EInputs.interpreterFeeWithoutVatPerMinuteForOnSite]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_ON_SITE_WITHOUT_VAT,
			value: user.interpreterFeeWithoutVatPerMinuteForOnSite,
		},
		[EInputs.hideInterpreterFees]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_HIDE_INTERPRETER_FEE,
			value: user.hideInterpreterFees as boolean,
		},
		[EInputs.isInterpreterTrained]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_TRAINED_INTERPRETER,
			value: user.isInterpreterTrained as boolean,
		},
		[EInputs.eezySalaryPaymentRule]: {
			type: EInputType.radio,
			labelTranslation: ETranslation.USER_SALARY_PAYMENT_RULE,
			options: [
				{
					value: "IMMEDIATELY",
					labelTranslation: ETranslation.USER_SALARY_IMMEDIATELY,
				},
				{
					value: "FIRST_DAY_OF_MONTH",
					labelTranslation: ETranslation.USER_SALARY_FIRST_DAY_OF_THE_MONTH,
				},
				{
					value: "MIDDLE_OF_MONTH",
					labelTranslation: ETranslation.USER_SALARY_MIDDLE_OF_MONTH,
				},
				{
					value: "LAST_DAY_OF_MONTH",
					labelTranslation: ETranslation.USER_SALARY_LAST_DAY_OF_THE_MONTH,
				},
			],
			value: user.eezySalaryPaymentRule,
		},
		[EInputs.isLegalInterpreter]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.USER_LEGAL_INTERPRETER_EDIT_COPY,
			value: user.isLegalInterpreter ?? false,
		}
	});

	return { inputs, setInputs, EInputs }
}