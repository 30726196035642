import React from "react";
import { IInterpretation } from "../../../interfaces/IInterpretation";
import classes from "./InterpretationsList.module.scss";
import { useInterpretation } from "../../../hooks/useInterpretation";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import Star from "../../ui/Star/Star";
import { ETranslation } from "../../../translations/translation-keys";
import { IUser } from "../../../classes/User";
import { EInterpretationStatus } from "../../../shared/interpretation-data";
import { getTermOfCancellTypeString } from "../../../shared/price-season-data";
import InterpretationOfferedUsers from "../InterpretationDetails/InterpretationOfferedList";
import InterpretationActions from "../InterpretationDetails/InterpretationActions/InterpretationActions";
import { ESitePrefix } from "../../../interfaces/ISite";
import { useLanguageName } from "../../../hooks/useLanguageName";

interface IProps {
	interpretation: IInterpretation;
	user: IUser;
}

export const starOptions = [{ value: "1" }, { value: "2" }, { value: "3" }, { value: "4" }, { value: "5" }];

const InterpretationInfoBox: React.FC<IProps> = ({ interpretation, user }) => {
	const { t } = useTranslation();
	const { getConnectionDetailString, getInterpretationMailTypeString, getFeeTypeString } = useInterpretation();
	const fromLanguage = useLanguageName(interpretation.fromLanguage);
	const toLanguage = useLanguageName(interpretation.toLanguage);

	const openUserInformation = (user: IUser | undefined) => {
		if (!user) return;
		const url = `/users/edit/${user.id}`;
		window.open(url, "_blank", "noopener, noreferrer");
	}

	const sitePrefix = user.site?.prefix;
	return (
		<>
			<div className={classes.infoContainer}>
				{user.isYoupretAdmin &&
					<>
						<div className={classes.infoItem}>
							<h3>{t(ETranslation.INTERPRETATION_INFO_CONTACT)}</h3>
							<span>{t(ETranslation.COMMON_CONNECTION_DETAIL)}<b>{interpretation.connectionDetail?.connectionType ? getConnectionDetailString(t, interpretation.connectionDetail.connectionType) : ""}</b></span>
							<span>{t(ETranslation.COMMON_LANGUAGE)}: <b>{interpretation.connectionDetail?.language}</b></span>
							{(interpretation.adminLog1 && interpretation.adminLog1.length > 1) && <span style={{ color: "red" }}><b>{t(ETranslation.REDUCER_ERROR)}: </b> {interpretation.adminLog1}</span>}
							{
								(interpretation.status === EInterpretationStatus.CUSTOMER_CANCEL && user.isYoupretAdmin)
								&&
								<>
									<span><b style={{ color: "red" }}>Asiakas perui: </b>{interpretation.customerCancelDateTime}</span>
									<span>{interpretation.termsOfCancellationTypeOnSite ?
										<><b>Peruutusehto: </b>{getTermOfCancellTypeString(t, interpretation.termsOfCancellationTypeOnSite)}</> :
										interpretation.interpreterFeeInfoText
									}</span>
								</>
							}
						</div>
					</>
				}
				<div className={classes.infoItem}>
					<h3>{t(ETranslation.COMMON_INTERPRETER)}</h3>
					<span className={classes.clickableUserName} onClick={user.role === "ADMIN" ? () => openUserInformation(interpretation.interpreter) : void (null)}><b>{interpretation.interpreter?.firstName} {interpretation.interpreter?.lastName}</b></span>
					<span><FontAwesomeIcon icon={faPhone} /> {interpretation.interpreter?.phoneNumber}</span>
					<span><FontAwesomeIcon icon={faEnvelope} />  {interpretation.interpreter?.email}</span>
					{interpretation.interpreter?.organizations?.map((organization, key) => {
						return <span key={key}><FontAwesomeIcon icon={faBuilding} /> {organization.name} {organization.organizationGroup? " | " + organization.organizationGroup.name : ""}</span>
					})}
				</div>
				<div className={classes.infoItem}>
					<h3>{t(ETranslation.COMMON_CUSTOMER)}</h3>
					<span className={classes.clickableUserName} onClick={(user.role === "ADMIN" && interpretation.customer !== undefined) ? () => openUserInformation(interpretation.customer) : void (null)}><b>{interpretation.customer?.firstName} {interpretation.customer?.lastName}</b></span>
					<span><FontAwesomeIcon icon={faPhone} /> {interpretation.customer?.phoneNumber}</span>
					<span><FontAwesomeIcon icon={faEnvelope} /> {interpretation.customer?.email}</span>
					{interpretation.customer?.organizations?.map((organization, key) => {
						return <span key={key}><FontAwesomeIcon icon={faBuilding} /> {organization.name} {organization.organizationGroup? " | " + organization.organizationGroup.name : ""}</span>
					})}
				</div>
				{sitePrefix && [ESitePrefix.johannarekry, ESitePrefix.valitysklinikka].includes(sitePrefix) &&
					<div className={classes.infoItem}>
						<h3>{t(ETranslation.USER_LOCATION)}</h3>
						<span>{t(ETranslation.COMMON_ADDRESS)} : <b>{interpretation.locationDetail.address}</b></span>
						<span>{t(ETranslation.COMMON_LOCATION)} : <b>{interpretation.locationDetail.city}</b></span>
						<span>{t(ETranslation.COMMON_ZIP)}: <b>{interpretation.locationDetail.zip}</b></span>
						<span>{t(ETranslation.INTERPRETATION_ON_SITE_INFO)}: <b>{interpretation.locationDetail.info}</b></span>
					</div>
				}
				<div className={classes.infoItem}>
					<h3>{t(ETranslation.COMMON_INFORMATION)}</h3>
					<span>{t(ETranslation.COMMON_START_DATE)} : <b>{interpretation.startDateTime}</b></span>
					<span>{t(ETranslation.COMMON_END_DATE)} : <b>{interpretation.endDateTime}</b></span>
					<span>{t(ETranslation.COMMON_DURATION_IN_MINUTES)}: <b>{interpretation.durationInMinutes}</b></span>
					<span>{t(ETranslation.INTERPRETATION_INFO_RESERVATION_IN_MIN)}: <b>{interpretation.bookingInMinutes}</b></span>
					{user.isYoupretAdmin && <span>{t(ETranslation.INTERPRETATION_INFO_INVOICE)}: <b>{interpretation.billableDurationInMinutes ?? ""}</b></span>}
				</div>
				{user.isYoupretAdmin &&
					<>
						<div className={classes.infoItem}>
							<h3>{t(ETranslation.COMMON_PAYMENTS)}</h3>
							<span>{t(ETranslation.INTERPRETATION_INFO_INTERPRETER_INVOICE)}: <b>{interpretation.interpreterFeeWithoutVatPerMinute}€</b></span>
							<span>{t(ETranslation.INTERPRETATION_INTERPRETER_PAYMENT)}: <b>{interpretation.interpreterFeeWithoutVat}({interpretation.interpreterFee})€</b></span>
							<span>{t(ETranslation.INTERPRETATION_CUSTOMER_PAYMENT)}: <b>{interpretation.priceWithoutVatPerMinute}€</b></span>
							<span>{t(ETranslation.INTERPRETATION_INFO_CUSTOMER_INVOICE)}: <b>{interpretation.priceWithoutVat}({interpretation.price})€</b></span>
							<span>{t(ETranslation.INTERPRETATION_INTERPRETERS_PAYMENT_TYPE)}: <b>{interpretation.feeType ? getFeeTypeString(t, interpretation.feeType) : ""}</b></span>
						</div>
						<div className={classes.infoItem}>
							<InterpretationActions interpretation={interpretation} model={"LIST"} />
						</div>
					</>
				}
			</div>
			<div className={classes.infoContainer}>
				{user.isYoupretAdmin &&
					<div className={[classes.infoItem, classes.mailCont].join(" ")}>
						<h3>{t(ETranslation.COMMON_EMAIL)} </h3>
						{interpretation.emailMessages?.map((message, key) => {
							return <React.Fragment key={key}>
								<span><b>{getInterpretationMailTypeString(t, message.type)}</b> {message.created}</span>
								<span className={classes.emailSeparatorSpan}><b>{message.to}</b></span>
							</React.Fragment>
						})}
					</div>
				}
				{user.role === "ADMIN" &&
					<div className={classes.infoItem}>
						<h3>{t(ETranslation.INTERPRETATION_CANDIDATES)}</h3>
						{interpretation.interpretationUserRows && <InterpretationOfferedUsers users={interpretation.interpretationUserRows} interpretationId={interpretation.id} />}
					</div>
				}
				<div className={classes.infoItem}>
					<h3>{t(ETranslation.INTERPRETATION_INVOICE_DETAILS)}</h3>
					<span>{t(ETranslation.COMMON_COST_CENTERS)}: </span><span><b>{interpretation.costCenter}</b></span>
					<span>{t(ETranslation.COMMON_CUSTOMER_REFERENCE)}:</span><span><b>{interpretation.customerReference}</b></span>
					<span>{t(ETranslation.COMMON_CUSTOMER_REFERENCE_2)}:</span><span><b>{interpretation.customerReference2}</b></span>
				</div>
				{user.isYoupretAdmin &&
					<>
						<div className={classes.infoItem}>
							<h3>{t(ETranslation.COMMON_INTERPRETATION_INFORMATION)}</h3>
							<span>{t(ETranslation.COMMON_FROM_LANGUAGE)}: <b>{fromLanguage.languageName}</b></span>
							<span>{t(ETranslation.COMMON_TO_LANGUAGE)}: <b>{toLanguage.languageName}</b></span>
							<span>{t(ETranslation.COMMON_TAGS)}:
								<b>{interpretation.gender ? interpretation.gender === "MALE" ? "Mies" : "Nainen" : ""}</b>
							</span>
						</div>
					</>
				}
				<div className={classes.infoItem}>
					{user.isYoupretAdmin &&
						<>
							<h3>{t(ETranslation.COMMON_ADMIN_NOTES)}</h3>
							<span>{interpretation.adminNotesText}</span>
						</>
					}
					<h3>{t(ETranslation.COMMON_INFO_TO_INTERPRETER)}:</h3>
					<span>{interpretation.interpreterInfoText}</span>
				</div>
				<div className={classes.infoItem}>
					<h3>{t(ETranslation.INTERPRETATION_RATINGS)}</h3>
					<div className={classes.reviewSection}>
						<span><b>{t(ETranslation.COMMON_REVIEW_FROM_CUSTOMER)}:</b></span>
						{interpretation.interpretationReviews?.map((review, key) => {
							if (review.userRole === "CUSTOMER") {
								return <React.Fragment key={key}>
									<span><Star disabled={true} value={review.rating?.toString()} onChange={() => { }} options={starOptions} /></span>
									<span>{review.reviewText}</span>
								</React.Fragment>
							}

						})}
					</div>
					<div className={classes.reviewSection}>
						<span><b>{t(ETranslation.COMMON_REVIEW_FROM_INTERPRETER)}:</b></span>
						{interpretation.interpretationReviews?.map((review, key) => {
							if (review.userRole === "INTERPRETER") {
								return <React.Fragment key={key}>
									<span><Star disabled={true} value={review.rating?.toString()} onChange={() => { }} options={starOptions} /></span>
									<span>{review.reviewText}</span>
								</React.Fragment>
							}
						})}
					</div>
				</div>
			</div>
		</>
	);
};

export default InterpretationInfoBox;


