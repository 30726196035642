import React from 'react';

import classes from './ProgressBar.module.scss';

interface IProps {
  progress: number;
}

const ProgressBar:React.FC<IProps> = ({ progress }) => {


  return (
    <div className={classes.Container} >
      <div className={classes.Progress} style={{ width: `${progress}%`}}></div>
    </div>
  )

}


export default ProgressBar;