import { Dispatch } from 'redux';

import { customFetch, EFetchMethod } from '../../custom-fetch';
import { IUserShiftMonth } from '../../interfaces/IUserShiftMonth';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IUserShiftAction from '../../interfaces/store/IUserShiftAction';
import { IUserShiftDate } from '../../interfaces/IUserShiftDate';

type TAction = IUserShiftAction;

const findUserShiftMonthsStart = (): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_FIND_SUCCESS,
  };
};

const findUserShiftMonthsSuccess = (userShifts: IUserShiftMonth[]): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_FIND_SUCCESS,
    userShifts,
  };
};

const findUserShiftMonthsFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_FIND_FAIL,
    error,
  };
};

export const findUserShiftMonths = (year: number, month: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(findUserShiftMonthsStart());
      const userShifts = await customFetch<IUserShiftMonth[]>(`/usershifts/list?year=${year}&month=${month}`);
      dispatch(findUserShiftMonthsSuccess(userShifts));
    } catch (error) {
      dispatch(findUserShiftMonthsFail((error as Error).message));
    }
  };
};

const getUserShiftMonthStart = (): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_GET_START,
  };
};

const getUserShiftMonthSuccess = (userShift: IUserShiftMonth): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_GET_SUCCESS,
    userShift,
  };
};

const getUserShiftMonthFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_GET_FAIL,
    error,
  };
};

export const getUserShiftMonthClear = (): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_GET_CLEAR,
  };
};

export const getUserShiftMonth = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getUserShiftMonthStart());
      const priceSeason = await customFetch<IUserShiftMonth>(`/usershifts/get?id=${id}`);
      dispatch(getUserShiftMonthSuccess(priceSeason));
    } catch (error) {
      dispatch(getUserShiftMonthFail((error as Error).message));
    }
  };
};

const deleteUserShiftMonthStart = (): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_DELETE_START,
  };
};

const deleteUserShiftMonthSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_DELETE_SUCCESS,
    id,
  };
};

const deleteUserShiftMonthFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_DELETE_FAIL,
    error,
  };
};

export const deleteUserShiftMonthClear = (): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_DELETE_CLEAR,
  };
};

export const deleteUserShiftMonth = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deleteUserShiftMonthStart());
      await customFetch<boolean>(`/usershifts/delete?id=${id}`, EFetchMethod.DELETE);
      dispatch(deleteUserShiftMonthSuccess(id));
    } catch (error) {
      dispatch(deleteUserShiftMonthFail((error as Error).message));
    }
  };
};

const saveUserShiftMonthStart = (): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_SAVE_START,
  };
};

const saveUserShiftMonthSuccess = (userShift: IUserShiftMonth): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_SAVE_SUCCESS,
    userShift
  };
};

const saveUserShiftMonthFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_SAVE_FAIL,
    error,
  };
};

export const saveUserShiftMonth = (userShift: IUserShiftMonth) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveUserShiftMonthStart());
      userShift = await customFetch<IUserShiftMonth>("/usershifts/add", EFetchMethod.POST, JSON.stringify(userShift));
      dispatch(saveUserShiftMonthSuccess(userShift));
      return userShift;
    } catch (error) {
      dispatch(saveUserShiftMonthFail((error as Error).message));
    }
  };
};

const updateUserShiftMonthStart = (): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_UPDATE_START,
  };
};

const updateUserShiftMonthSuccess = (userShift: IUserShiftMonth): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_UPDATE_SUCCESS,
    userShift
  };
};

const updateUserShiftMonthFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_UPDATE_FAIL,
    error,
  };
};

export const updateUserShiftMonth = (userShift: Partial<IUserShiftMonth>) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateUserShiftMonthStart());
      const updatedUserShift = await customFetch<IUserShiftMonth>("/usershifts/update", EFetchMethod.PUT, JSON.stringify(userShift));
      dispatch(updateUserShiftMonthSuccess(updatedUserShift));
    } catch (error) {
      dispatch(updateUserShiftMonthFail((error as Error).message));
    }
  };
};

export const saveOrUpdateUserShiftMonthClear = (): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_SAVE_OR_UPDATE_CLEAR
  }
}


const saveUserShiftDatesStart = (): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_DATES_SAVE_START,
  };
};

const saveUserShiftDatesSuccess = (userShift: IUserShiftMonth): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_DATES_SAVE_SUCCESS,
    userShift
  };
};

const saveUserShiftDatesFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_DATES_SAVE_FAIL,
    error,
  };
};

export const saveUserShiftDates = (userShiftMonthId: string, dates: IUserShiftDate[]) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveUserShiftDatesStart());
      const userShift = await customFetch<IUserShiftMonth>("/usershifts/dates/add", EFetchMethod.POST, JSON.stringify({
        id: userShiftMonthId,
        dates
      }));
      dispatch(saveUserShiftDatesSuccess(userShift));
    } catch (error) {
      dispatch(saveUserShiftDatesFail((error as Error).message));
    }
  };
};

export const saveUserShiftDatesClear = (): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_DATES_SAVE_CLEAR
  }
}


const deleteUserShiftDatesStart = (): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_DATES_DELETE_START,
  };
};

const deleteUserShiftDatesSuccess = (userShift: IUserShiftMonth): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_DATES_DELETE_SUCCESS,
    userShift
  };
};

const deleteUserShiftDatesFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_SHIFT_DATES_DELETE_FAIL,
    error,
  };
};

export const deleteUserShiftDates = (monthId: string, date: string | "ALL", itemId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deleteUserShiftDatesStart());
      const userShift = await customFetch<IUserShiftMonth>(`/usershifts/dates/delete?monthId=${monthId}&date=${date}&itemId=${itemId}`, EFetchMethod.DELETE);
      dispatch(deleteUserShiftDatesSuccess(userShift));
    } catch (error) {
      dispatch(deleteUserShiftDatesFail((error as Error).message));
    }
  };
};
