import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInterpretation } from "../../../../../hooks/useInterpretation";
import { EInterpretationType } from "../../../../../shared/interpretation-data";
import classes from "./SummaryItem.module.scss";

interface IProps {
  children: ReactNode;
  dateString?: string;
  currentIndex: number;
  currentAlternativeIndex: number;
  showAlternativeOnly: boolean;
  isAlternative: boolean;
  index: number;
  alternativeIndex: number;
  interpretationType: string | null;
  setCurrentIndex: (index: number, altIndex: number) => void;
  setAlternativeOnly: (value: boolean) => void;
}

const SummaryItem: React.FC<IProps> = ({
  children,
  dateString,
  interpretationType,
 //showAlternativeOnly,
  isAlternative,
  //currentAlternativeIndex,
  //currentIndex,
  alternativeIndex,
  index,
  setCurrentIndex,
  setAlternativeOnly
}) => {
  const [showExtended, setShowExtended] = useState(true);
  const { t } = useTranslation();
  const { getInterpretationTypeString } = useInterpretation();
  const classNames = [classes.Container];
  const classNamesContainerSmall = [classes.ContainerSmall];
  const arrowClasses = [classes.Arrow];
  const boxContainerClasses = [classes.Box];
  const mainContainerClasses = [classes.MainContainer];
  

  // useEffect(() => {
  //   if(isAlternative && (alternativeIndex === currentAlternativeIndex) && (index === currentIndex) && showAlternativeOnly ) {
  //     setShowExtended(true)
  //   }else if (isAlternative) {
  //     setShowExtended(false)
  //   }
  // }, [alternativeIndex, currentAlternativeIndex, currentIndex, index, isAlternative, showAlternativeOnly])
  

  // useEffect(() => {
  //   if(!isAlternative && index === currentIndex && !showAlternativeOnly) {
  //     setShowExtended(true)
  //   }else if (!isAlternative) {
  //     setShowExtended(false)
  //   }
  // }, [currentIndex, index, isAlternative, showAlternativeOnly])

  if(isAlternative) {
    arrowClasses.push(classes.ArrowAlternative)
    mainContainerClasses.push(classes.MainContainerAlternative)
    classNames.push(classes.InputsContainerAlternative);
    classNamesContainerSmall.push(classes.AlternativeContainer);
    boxContainerClasses.push(classes.BoxAlternative);
  }

  if (showExtended) {
    classNames.push(classes.OpenContainer)
  } else {
    classNames.push(classes.CloseContainer)
  }

  const closeOrOpen = () => {
    if (showExtended) {
      setShowExtended(false);
      classNames.push(classes.CloseContainer)
    } else {
      if(isAlternative) {
        setCurrentIndex(index, alternativeIndex ? alternativeIndex : 0);
        setAlternativeOnly(true)
      } else if (!isAlternative) {
        setCurrentIndex(index, alternativeIndex ? alternativeIndex : 0);
        setAlternativeOnly(false)
      }
      setShowExtended(true);
      classNames.push(classes.OpenContainer)
    }
  };

  return (
  <>
    {interpretationType && 
      <>
      <div className={mainContainerClasses.join(" ")}>
      <div className={classNamesContainerSmall.join(" ")}>
          <div className={arrowClasses.join(" ")} onClick={closeOrOpen}>
            {showExtended ? (
              <FontAwesomeIcon icon={faAngleUp} />
            ) : (
              <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon>
            )}
          </div>
          <div className={boxContainerClasses.join(" ")}>{ isAlternative ? (alternativeIndex + 1) + " Vaihtoehto " : (index + 1) + " " + getInterpretationTypeString(t, interpretationType as EInterpretationType)} {dateString && dateString}</div>
        </div>
        <div className={classNames.join(" ")}>{children}</div>
        </div>
        </>
        
    }
  </>
  );
};

export default SummaryItem;
