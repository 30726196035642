import React from "react";
import {
	EReservedDateType,
	IReservedDate,
} from "../../../../interfaces/IReservedDate";
import {
	formatDate,
	formatDateTime,
	formatTime,
} from "../../../../utils/date-utils";
import { useSingleOptionLabel } from "../../../../hooks/useSingleOptionLabel";
import {
	RESERVED_DATE_OPTIONS,
	RESERVED_DATE_TYPES,
} from "../../../../shared/reserved-date-data";
import { useMultiOptionLabel } from "../../../../hooks/useMultiOptionLabel";

interface IProps {
	reservedDate: IReservedDate;
}

const ReservedDateUserListItem: React.FC<IProps> = ({ reservedDate }) => {

	const type = useSingleOptionLabel(RESERVED_DATE_TYPES, reservedDate.type);
	const options = useMultiOptionLabel(
		RESERVED_DATE_OPTIONS,
		reservedDate.options
	);

	return (
		<tr key={reservedDate.id}>
			<td>{type}</td>
			<td>
				{reservedDate.type === EReservedDateType.TIMERANGE ? (
					<>
						{formatDateTime(reservedDate.startDate)} -{" "}
						{formatDateTime(reservedDate.endDate)}
					</>
				) : reservedDate.type === EReservedDateType.DAILY ? (
					<>
						{formatDate(reservedDate.startDate)} -{" "}
						{formatDate(reservedDate.endDate)}{" "}
						{formatTime(reservedDate.startTime)} -{" "}
						{formatTime(reservedDate.endTime)}
					</>
				) : null}
			</td>
			<td>{options?.join(", ")}</td>
			<td style={{ whiteSpace: "pre-wrap" }}>
				{reservedDate.description}
			</td>
		</tr>
	);
};

export default ReservedDateUserListItem;
