import { STATUSES } from "../../../store/actions/summariesActions";
import { ETranslation } from "../../../translations/translation-keys";
import { EInputType } from "../../ui/Input/Input";
import * as dateFns from 'date-fns';

export enum EInputs {
	id = "id",
	startDate = "startDate",
	endDate = "endDate",
	status = "status",
	organization = "organization",
	user = "user",
}

const currentYear = dateFns.getYear(new Date());
const startOfYear = dateFns.startOfYear(new Date(currentYear, 0, 1));
const endOfYear = dateFns.endOfYear(new Date(currentYear, 11, 31));

const defaultCreatedStartDate = dateFns.format(startOfYear, "yyyy-MM-dd");
const defaultCreatedEndDate = dateFns.format(endOfYear, "yyyy-MM-dd");

export const initialInputs = {
	[EInputs.startDate]: {
		type: EInputType.datepicker,
		value: defaultCreatedStartDate,
	},
	[EInputs.endDate]: {
		type: EInputType.datepicker,
		value: defaultCreatedEndDate,
	},
	[EInputs.status]: {
		type: EInputType.reactSelect,
		isClearable: true,
		labelTranslation: ETranslation.COMMON_STATUS,
		value: [],
		multiple: true,
		options: STATUSES,
	},
	[EInputs.user]: {
		type: EInputType.reactSelectSearch,
		labelTranslation: ETranslation.COMMON_USER,
		value: null,
	},
	[EInputs.organization]: {
		type: EInputType.reactSelectSearch,
		isClearable: true,
		labelTranslation: ETranslation.COMMON_ORGANIZATIONS,
		value: null,
		multiple: false,
	},
}