import React from "react";
import { IUser } from "../../../classes/User";
import * as dateFns from 'date-fns';
import Container from "../../../components/ui/Container/Container";
import { IInterpretation } from "../../../interfaces/IInterpretation";
import Heading from "../../../components/Heading/Heading";
import classes from "./calendar.module.scss";
import Table from "../../../components/ui/Table/Table";
import manLogo from "../../../assets/images/man.svg";
import womanLogo from "../../../assets/images/woman.svg";
import greenStatus from "../../../assets/images/Green.svg";
import redStatus from "../../../assets/images/Red.svg";
import yellowStatus from "../../../assets/images/Yellow.svg";
import { adminUiUrl, serverUrl } from "../../../config";

interface IHourDetailProps {
	hour: string;
	users: IUser[] | null;
	interpretations: IInterpretation[];
	daysIntrepretations: { [key: string]: IInterpretation[] };
	selectedDay: string;
}

interface IModalUser {
	Interpretations?: IInterpretation[]
	user: IUser;
}

// Look for a reservation for given uid within the day.
const getNextReservedTimeFrame = (uid: string, startHour: number, Intrepretations: { [key: string]: IInterpretation[] }): string => {
	let nextReserved = "No reservations onwards that day";
	let found = false;
	// Loop from the starting hour and go forward untill 24:00
	while (startHour < 24 && !found) {
		// Double check that the hour is not empty
		if (Intrepretations[startHour] && Intrepretations[startHour].length > 0) {
			//Loop trough the interpretations of that hour
			for (const intrepretation of Intrepretations[startHour]) {
				// Not always interpretation has interpreter details. Double check
				const InterpreterId = intrepretation.interpreterDetail ? intrepretation.interpreterDetail.id : ""
				if (InterpreterId === uid) {
					// Found the next reservation, return it and break the loop
					nextReserved = dateFns.format(new Date(intrepretation.startDateUTC), "H:mm") + " -> " + dateFns.format(dateFns.addMinutes(new Date(intrepretation.startDateUTC), intrepretation.durationInMinutes), "H:mm")
					found = true;
				}
			}
		}
		startHour++;
	}
	return nextReserved;
}

const ReservationDetailsModal: React.FC<IHourDetailProps> = ({ hour, users, interpretations, daysIntrepretations, selectedDay }) => {

	const dataLayout = () => {
		const reservedUsers: JSX.Element[] = [];
		const freeUsers: JSX.Element[] = [];
		// Sort users by their last names (request from aspa)
		const sortedUsers = users?.sort((a, b) => a.lastName.localeCompare(b.lastName))
		// Foreach user within the search parameters create a row
		sortedUsers?.map((user, id) => {
			const UID = user.id
			// We want to bundle the user and their interpretation, so we loop over given interpretations for this hour slot and pick the one's that belong to each user
			const modalUser: IModalUser = { user: user };
			let interpretationId: string;
			// Save earliest and latest
			let reservedFrom: number | null = null;
			let reservedTo: number | null = null;
			interpretations.map((interpretation) => {
				if (interpretation.interpreterDetail && interpretation.interpreterDetail.id === UID) {
					let unixStartTs = dateFns.getUnixTime(new Date(interpretation.startDateUTC));
					let unixEndTs = dateFns.getUnixTime(dateFns.addMinutes(new Date(interpretation.startDateUTC), interpretation.durationInMinutes));
					interpretationId = interpretation.id;

					modalUser.Interpretations ? modalUser.Interpretations.push(interpretation) : modalUser.Interpretations = [interpretation];
					// TODO(JOONAS) This currently takes in consideration if the user has multiple reservations within the hour and shows "all of the"
					// Where as 11:00 -> 11:15 and 11:45->11:55 are shown as 11:00 -> 11:55
					if (!reservedFrom || !reservedTo) {
						reservedFrom = unixStartTs
						reservedTo = unixEndTs;
					} else {
						if (reservedFrom > unixStartTs) {
							reservedFrom = unixStartTs;
						}
						if (reservedTo < unixEndTs) {
							reservedTo = unixEndTs;
						}
					}
				}
				return null;
			})
			if (modalUser.Interpretations && modalUser.Interpretations.length > 0) {
				// If user has interpretations, we will push element to reservedUsers 
				reservedUsers.push(
					<tr key={id}>
						<td onClick={() => {
							window.open(serverUrl +"/console/calendar?interperter=" + JSON.stringify([{value: modalUser.user.id, label: modalUser.user.firstName + " " + modalUser.user.lastName}]))?.focus();
						}}>{modalUser.user.firstName} {modalUser.user.lastName}</td>
						<td>{modalUser.user.email}</td>
						<td>{modalUser.user.phoneNumber}</td>
						<td className={classes.imageCol}>{modalUser.user.gender && modalUser.user.gender === "MALE" ? <img alt="" src={manLogo} className={classes.svgImage} /> : <img alt="" className={classes.svgImage} src={womanLogo} />}</td>
						<td className={classes.linkColumn} onClick={()=>{
							window.open(adminUiUrl + "/interpretations/" + interpretationId)?.focus();
						}}>{dateFns.format(dateFns.fromUnixTime(reservedFrom ? reservedFrom : 0), "H:mm")}{" -> "}{dateFns.format(dateFns.fromUnixTime(reservedTo ? reservedTo : 0), "H:mm")}<br /></td>
						<td className={classes.imageCol}>{modalUser.user.status && modalUser.user.status === "ONLINE_AVAILABLE" ? <img alt="" className={classes.svgImage} src={greenStatus} /> : modalUser.user.status === "ONLINE_RESERVED" ? <img alt="" className={classes.svgImage} src={redStatus} /> : <img alt="" className={classes.svgImage} src={yellowStatus} />}</td>
					</tr>
				);
			} else {
				// If the user doesn't have, we know they are free so into freeUsers they go
				freeUsers.push(
					<tr key={id}>
						<td onClick={() => {
							window.open(serverUrl +"/console/calendar?interperter=" + JSON.stringify([{value: modalUser.user.id, label: modalUser.user.firstName + " " + modalUser.user.lastName}]))?.focus();
						}}>{modalUser.user.firstName} {modalUser.user.lastName}</td>
						<td>{modalUser.user.email}</td>
						<td>{modalUser.user.phoneNumber}</td>
						<td className={classes.imageCol}>{modalUser.user.gender && modalUser.user.gender === "MALE" ? <img alt="" src={manLogo} className={classes.svgImage} /> : <img alt="" className={classes.svgImage} src={womanLogo} />}</td>
						<td>{getNextReservedTimeFrame(modalUser.user.id, parseFloat(hour), daysIntrepretations)}</td>
						<td className={classes.imageCol}>{modalUser.user.status && modalUser.user.status === "ONLINE_AVAILABLE" ? <img alt="" className={classes.svgImage} src={greenStatus} /> : modalUser.user.status === "ONLINE_RESERVED" ? <img alt="" className={classes.svgImage} src={redStatus} /> : <img alt="" className={classes.svgImage} src={yellowStatus} />}</td>
						<td><button className={classes.ReserveButton} onClick={() => {
							window.open(adminUiUrl + "/interpretations/add?interpreter=" + modalUser.user.id + "&date=" + selectedDay + "T" + (hour.toString().length < 2 ? "0"+ hour: hour) + ":00", "_blank")?.focus();
						}}>Varaamaan</button></td>
					</tr>
				);
			}
		});
		// Finally return the modal content parsed
		return (
			<Container>
				<Heading className={classes.ModalTitle}>
					Vapaat Tulkit
				</Heading>
				<Table hover={true} className={classes.TableStyle}>
					<thead>
						<tr>
							<th>Nimi</th>
							<th>Sähköposti</th>
							<th>Numero</th>
							<th>Sukupuoli</th>
							<th>Seuraava varaus</th>
							<th>Tulkin tila</th>
							<th>Varaa</th>
						</tr>
					</thead>
					<tbody>
						{freeUsers}
					</tbody>
				</Table>
				<Heading className={classes.ModalTitle}>
					Varatut Tulkit
				</Heading>
				<Table hover={true} className={classes.TableStyle}>
					<thead>
						<tr>
							<th>Nimi</th>
							<th>Sähköposti</th>
							<th>Numero</th>
							<th>Sukupuoli</th>
							<th>Varattuna</th>
							<th>Tulkin tila</th>
						</tr>
					</thead>
					<tbody>
						{reservedUsers}
					</tbody>
				</Table>
			</Container>
		)

	}

	return (
		<>
			{dataLayout()}
		</>
	);
}

export default ReservationDetailsModal;