import { useCallback } from 'react';
import { useState } from 'react';

import { ISalaryInfo } from '../../interfaces/ISalaryInfo';
import { useSalaryInfoEditModal } from './useSalaryInfoEditModal';


export const useSalaryInfoAttach = () => {
	const openEditModal = useSalaryInfoEditModal();
	const [salaryInfos, setSalaryInfos] = useState<ISalaryInfo[]>([]);

	const addHandler = useCallback(async () => {
		const salaryInfo = await openEditModal()
		if (salaryInfo) {
			setSalaryInfos((salaryInfos) => [...salaryInfos, salaryInfo]);
		}
	}, [openEditModal]);

	const deleteHandler = (id: string) => {
		setSalaryInfos((salaryInfos) => {
			const newSalaryInfos = [...salaryInfos];
			const index = newSalaryInfos.findIndex(item => item.id === id);
			newSalaryInfos.splice(index, 1);
			return newSalaryInfos;
		});
	};

	const editHandler = useCallback(async (salaryInfo: ISalaryInfo) => {
		const newSalaryInfo = await openEditModal(salaryInfo);
		if (salaryInfo) {
			setSalaryInfos((salaryInfos) => {
				const newSalaryInfos = [...salaryInfos];
				const index = newSalaryInfos.findIndex(item => item.id === newSalaryInfo.id);
				newSalaryInfos[index] = newSalaryInfo;
				return newSalaryInfos;
			});
		}
	}, [openEditModal]);

	return {
		salaryInfos,
		setSalaryInfos,
		salaryInfosDeleteHandler: deleteHandler,
		salaryInfosEditHandler: editHandler,
		salaryInfosAddHandler: addHandler,
	};
};
