import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { ERoute } from "../../../classes/Routes";
import Alert from "../../../components/ui/Alert/Alert";
import Container from "../../../components/ui/Container/Container";
import Spinner from "../../../components/ui/Spinner/Spinner";
import { IOrganization } from "../../../interfaces/IOrganization";
import IAppState from "../../../interfaces/store/IAppState";
import * as actions from "../../../store/actions";
import Heading from "../../../components/Heading/Heading";
import { ETranslation } from "../../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import Button, { EButtonColor } from "../../../components/ui/Button/Button";
import { createNewOrganizationInNetvisor } from "../../../store/actions/organizationActions";
import OrganizationEdit from "../../../components/Organization/OrganizationEdit";

interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

const OrganizationEditPage: React.FC<IProps> = ({ match, history }) => {
	const dispatch = useDispatch();

	const { id } = match.params;
	const isAdd = id === "add";

	const { t } = useTranslation();

	const { error, loading, saveOrUpdateOk, organization } = useSelector(
		(state: IAppState) => ({
			error: state.organization.organizationError,
			loading: state.organization.organizationLoading,
			saveOrUpdateOk: state.organization.organizationSaveOrUpdateOk,
			organization: state.organization.organization,
		})
	);

	useEffect(() => {
			dispatch(actions.getOrganization(id));
	}, [id, dispatch]);

	useEffect(() => {
		if (saveOrUpdateOk) {
			history.push(ERoute.ORGANIZATIONS_LIST);
		}
		return () => {
			dispatch(actions.saveOrUpdateOrganizationClear());
			dispatch(actions.getOrganizationClear());
			dispatch(actions.deleteOrganizationClear());
		};
	}, [dispatch, saveOrUpdateOk, history]);


	const updateHandler = useCallback((organizationData: IOrganization) => {
		if (!organizationData) return
		if (isAdd) {
			dispatch(actions.saveOrganization(organizationData));
		} else {
			organizationData.id = id; // ehkä turha
			dispatch(
				actions.updateOrganization(organizationData)
			);
		}
	}, [dispatch, id, isAdd]);

	const navigateToList = () => {
		history.push(ERoute.ORGANIZATIONS_LIST)
	}

	const moveToNetvisor = () => {
		if (isAdd || !organization) return;
		dispatch(createNewOrganizationInNetvisor(organization))
	}

	return (
		<Container>
			{error && <Alert>{error}</Alert>}
			<Heading>
				{t(organization?.name ?? "")}
			</Heading>

			{organization ?
				<OrganizationEdit
					saveOrganization={updateHandler}
					organization={organization}
					navigateToList={navigateToList}
				/> : loading ? <Spinner centerAbsolute /> : null
			}
			{!isAdd && (
				<Button color={EButtonColor.WHITE} style={{ marginLeft: "0.5rem" }} onClick={moveToNetvisor}>{t(ETranslation.COMMON_MOVE_TO_NETVISOR)}</Button>
			)}
		</Container>
	);
};

export default OrganizationEditPage;
