import { useCallback, useState } from 'react';


export const useCustomDropzoneUploading = (): [ boolean, (id: string, isUploading: boolean) => void ] => {
  const [uploadIds, setUploadIds] = useState<string[]>([]);

  const setUploading = useCallback((id: string, isUploading: boolean): void => {
    setUploadIds(uploadIds => {
      const newIds = [...uploadIds];
      const indexOf = uploadIds.indexOf(id); 
      if(isUploading && indexOf === -1) {
        newIds.push(id);
      } else if(!isUploading && indexOf !== -1) {
        newIds.splice(indexOf, 1);
      }
      return newIds;
    })
  }, []);

  const isUploading = uploadIds.length > 0;


  return [isUploading, setUploading];
};
