import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import IAppState from "../../../interfaces/store/IAppState";
import * as actions from "../../../store/actions";
import { RouteComponentProps } from "react-router-dom";
import { ERoute, Routes } from "../../../classes/Routes";
import Container from "../../../components/ui/Container/Container";
import Heading from "../../../components/Heading/Heading";
import Button from "../../../components/ui/Button/Button";
import { ETranslation } from "../../../translations/translation-keys";
import LanguageCodeList from "../../../components/LanguageCode/LanguageCodeList/LanguageCodeList";

const LanguageCodeListPage: React.FC<RouteComponentProps> = ({ history }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const { items, loading, error } = useSelector((state: IAppState) => ({
		items: state.language.languageCodes,
		loading: state.language.languageCodesLoading,
		error: state.language.languageCodesError,
	}));

	useEffect(() => {
		dispatch(actions.findLanguageCodes());
	}, [dispatch]);

	const addHandler = () => {
		openHandler("add");
	};

	const openHandler = (id?: string) => {
		if (!id) return;
		history.push(Routes.withParams(ERoute.LANGUAGE_CODE_EDIT, { id }));
	};

	return (
		<Container>
			<Heading>{t(ETranslation.PAGES_LANGUAGE_CODES_LIST_TITLE)}</Heading>
			<Button onClick={addHandler}>
				{t(ETranslation.PAGES_LANGUAGE_CODES_LIST_ADD_NEW)}
			</Button>
			<LanguageCodeList
				languageCodes={items}
				loading={loading}
				error={error}
				onClick={openHandler}
			/>
		</Container>
	);
};

export default LanguageCodeListPage;
