import * as dateFns from "date-fns";
import { i18n } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSingleOptionLabel } from "../../hooks/useSingleOptionLabel";
import { IWorkingHoursDateEvent } from "../../interfaces/IWorkingHoursDate";
import { WORKING_HOURS_EVENT_TYPE_OPTIONS } from "../../shared/working-hours-data";
import { isWorkingHoursEventHoursType } from "../../utils/working-hours-utils";
import Table from "../ui/Table/Table";
import Button, { EButtonSize } from "../ui/Button/Button";
import { useHistory } from "react-router-dom";
import { ERoute, Routes } from "../../classes/Routes";
import SiteOnlyContent from "../ui/SiteOnlyContent/SiteOnlyContent";
import { ESitePrefix } from "../../interfaces/ISite";

interface IProps {
	workingHourEvents: IWorkingHoursDateEvent[];
}

interface IMapObject {
	[key: string]: IWorkingHoursDateEvent[];
}

const WorkingHoursEventsList: React.FC<IProps> = ({ workingHourEvents }) => {
	const { i18n } = useTranslation();
	const [mapObject, setMapObject] = useState<IMapObject>({});

	useEffect(() => {
		if (workingHourEvents) {
			setMapObject(
				workingHourEvents.reduce<IMapObject>((mapObject, event) => {
					const dateISO = dateFns.formatISO(new Date(event.date), { representation: "date" });
					const arr = mapObject[dateISO] || [];
					arr.push(event);
					mapObject[dateISO] = arr;
					return mapObject;
				}, {})
			);
		}
	}, [workingHourEvents]);

	if (workingHourEvents.length === 0) return <p>Ei merkintöjä.</p>;

	return (
		<Table>
			<thead>
				<tr>
					<th style={{ width: "1px" }}>Päivä</th>
					<th style={{ width: "1px" }}>Pvm</th>
					<th style={{ width: "1px" }}>Käyttäjä</th>
					<th style={{ width: "1px" }}>Tyyppi</th>
					<th style={{ width: "1px" }}>Alkuaika</th>
					<th style={{ width: "1px" }}>Loppuaika</th>
					<th style={{ width: "1px" }}>Tuntia</th>
					<th style={{ width: "1px", whiteSpace: "nowrap" }}>Tauko (min)</th>
					<th style={{ width: "1px" }}>Kilometrit</th>
					<SiteOnlyContent sites={[ESitePrefix.valitysklinikka]}>
						<th style={{ width: "1px" }}>Tuntipalkka</th>
					</SiteOnlyContent>
					<th style={{ width: "1px" }}>Projekti</th>
					<th>Lisätiedot</th>
					<th style={{ width: 1 }}></th>
				</tr>
			</thead>
			<tbody>
				{Object.keys(mapObject).map((date, i) =>
					mapObject[date].map((event) => (
						<WorkingHourEventListItem
							key={event.id}
							date={date}
							event={event}
							i18n={i18n}
							showBackground={i % 2 === 0}
						/>
					))
				)}
			</tbody>
		</Table>
	);
};

interface IWorkingHoursListItemProps {
	event: IWorkingHoursDateEvent;
	date: string;
	i18n: i18n;
	showBackground: boolean;
}

const WorkingHourEventListItem: React.FC<IWorkingHoursListItemProps> = ({ event, date, i18n, showBackground }) => {
	const history = useHistory();
	const dateObj = new Date(date);
	const startTimeObj = new Date(event.startTime);
	const endTimeObj = new Date(event.endTime);

	const userId = event.details?.userId ?? "";
	const userName = event.details?.userName ?? "";

	const startTimeValid = dateFns.isValid(startTimeObj);
	const endTimeValid = dateFns.isValid(endTimeObj);
	const isHoursType = isWorkingHoursEventHoursType(event.type);

	const typeLabel = useSingleOptionLabel(WORKING_HOURS_EVENT_TYPE_OPTIONS, event.type || "");

	const editHandler = () => {
		history.push(Routes.withParams(ERoute.WORKING_HOURS_EDIT_PAGE, { date, userId }));
	};

	return (
		<tr style={{ background: showBackground ? "#EEE" : "" }}>
			<td>{dateObj.toLocaleDateString(i18n.language, { weekday: "long" })}</td>
			<td>{dateObj.toLocaleDateString(i18n.language)}</td>
			<td style={{ whiteSpace: "nowrap" }}>{userName}</td>
			<td style={{ whiteSpace: "nowrap" }}>{typeLabel}</td>
			<td>
				{startTimeValid && !isHoursType
					? startTimeObj.toLocaleTimeString(i18n.language, { hour: "2-digit", minute: "2-digit" })
					: ""}
			</td>
			<td>
				{endTimeValid && !isHoursType
					? endTimeObj.toLocaleTimeString(i18n.language, { hour: "2-digit", minute: "2-digit" })
					: ""}
			</td>
			<td style={{ textAlign: "center" }}>
				{isHoursType
					? event.hours
					: startTimeValid && endTimeValid
					? (dateFns.differenceInMinutes(endTimeObj, startTimeObj) / 60).toFixed(2)
					: ""}
			</td>
			<td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
				{event.isBreakIncluded ? event.breakMinutes : "0"}
			</td>
			<td style={{ textAlign: "center" }}>{event.travelAmount}</td>
			<SiteOnlyContent sites={[ESitePrefix.valitysklinikka]}>
				<td style={{ textAlign: "center" }}>{event.details?.salaryPerHour?.toFixed(2)}</td>
			</SiteOnlyContent>
			<td style={{ whiteSpace: "nowrap" }}>{event.project?.name}</td>
			<td>{event.description}</td>
			<td>
				<Button onClick={editHandler} size={EButtonSize.X_SMALL}>
					Muokkaa
				</Button>
			</td>
		</tr>
	);
};

export default WorkingHoursEventsList;
