import EActionTypes from '../../interfaces/store/EActionTypes';
import ILogItemAction from '../../interfaces/store/ILogItemAction';
import ILogItemState from '../../interfaces/store/ILogItemState';
import { ETranslation } from '../../translations/translation-keys';

type ReducerAction = ILogItemAction;
type ReducerState = ILogItemState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  logItem: null,
  logItemLoading: false,
  logItemError: null,

  logItems: null,
  logItemsLoading: false,
  logItemsError: null,
  hasMoreLogItems: false,
  paginationCursor: null,

  logItemSaveOrUpdateOk: false,
  logItemSaveOrUpdateError: null,
  logItemSaveOrUpdateLoading: false,

  logItemDeleteError: null,
  logItemDeleteLoading: false,
  logItemDeleteOk: false,

};

const findLogItemsStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, logItemsError: null, logItemsLoading: true };
};

const findLogItemsClear: ReducerSignature = (state, action): ReducerState =>{
	return { ...state, logItemsError: null, logItemsLoading: false, logItems: null, hasMoreLogItems: false}
}

const findLogItemsSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    logItems: action.logItems ? [...(state.logItems ?? []), ...(action.logItems ?? [])] : null,
	paginationCursor: action.paginationCursor ?? null,
	hasMoreLogItems: !(action.logItems?.length === 0),
    logItemsError: null,
    logItemsLoading: false,
  };
};

const findLogItemsFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, logItems: null, logItemsLoading: false, logItemsError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getLogItemStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, logItemError: null, logItemLoading: true };
};

const getLogItemSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    logItem: action.logItem || null,
    logItemError: null,
    logItemLoading: false,
  };
};

const getLogItemFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, logItem: null, logItemLoading: false, logItemError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};

const getLogItemClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, logItem: null, logItemLoading: false, logItemError: null};
};

const deleteLogItemStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, logItemDeleteError: null, logItemDeleteLoading: true, logItemDeleteOk: false };
};

const deleteLogItemSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    logItemDeleteError: null,
    logItemDeleteLoading: false,
    logItemDeleteOk: true
  };
};

const deleteLogItemFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, logItemDeleteLoading: false, logItemDeleteError: action.error as string, logItemDeleteOk: false };
};

const deleteLogItemClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, logItemDeleteError: null, logItemDeleteLoading: false, logItemDeleteOk: false };
};

const saveOrUpdateLogItemStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, logItemSaveOrUpdateError: null, logItemSaveOrUpdateLoading: true };
};

const saveOrUpdateLogItemSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    logItems: null,
    logItemSaveOrUpdateError: null,
    logItemSaveOrUpdateLoading: false,
    logItemSaveOrUpdateOk: true
  };
};

const saveOrUpdateLogItemFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, logItemSaveOrUpdateLoading: false, logItemSaveOrUpdateError: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const saveOrUpdateLogItemClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, logItemSaveOrUpdateLoading: false, logItemSaveOrUpdateError: null, logItemSaveOrUpdateOk: false }
}


const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.LOG_ITEM_FIND_START:
      return findLogItemsStart(state, action);
    case EActionTypes.LOG_ITEM_FIND_SUCCESS:
      return findLogItemsSuccess(state, action);
    case EActionTypes.LOG_ITEM_FIND_FAIL:
      return findLogItemsFail(state, action);
	case EActionTypes.LOG_ITEM_FIND_CLEAR:
      return findLogItemsClear(state, action);
    case EActionTypes.LOG_ITEM_GET_START:
      return getLogItemStart(state, action);
    case EActionTypes.LOG_ITEM_GET_SUCCESS:
      return getLogItemSuccess(state, action);
    case EActionTypes.LOG_ITEM_GET_FAIL:
      return getLogItemFail(state, action);
    case EActionTypes.LOG_ITEM_GET_CLEAR:
      return getLogItemClear(state, action);
    case EActionTypes.LOG_ITEM_DELETE_START:
      return deleteLogItemStart(state, action);
    case EActionTypes.LOG_ITEM_DELETE_SUCCESS:
      return deleteLogItemSuccess(state, action);
    case EActionTypes.LOG_ITEM_DELETE_FAIL:
      return deleteLogItemFail(state, action);
    case EActionTypes.LOG_ITEM_DELETE_CLEAR:
      return deleteLogItemClear(state, action);
    case EActionTypes.LOG_ITEM_SAVE_OR_UPDATE_START:
      return saveOrUpdateLogItemStart(state, action);
    case EActionTypes.LOG_ITEM_SAVE_OR_UPDATE_SUCCESS:
      return saveOrUpdateLogItemSuccess(state, action);
    case EActionTypes.LOG_ITEM_SAVE_OR_UPDATE_FAIL:
      return saveOrUpdateLogItemFail(state, action);
    case EActionTypes.LOG_ITEM_SAVE_OR_UPDATE_CLEAR:
      return saveOrUpdateLogItemClear(state, action)
    default:
      return state;
  }
};

export default reducer;
