import { faCheck, faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ILocationDetail } from "../../interfaces/IInterpretation";
import { ETranslation } from "../../translations/translation-keys";
import Button, { EButtonColor } from "../ui/Button/Button";
import Input, { EInputType, TInputValue } from "../ui/Input/Input";
import classes from "./LocationDetailEdit.module.scss";
import Spinner from "../ui/Spinner/Spinner";
import { useSelector } from "react-redux";
import IAppState from "../../interfaces/store/IAppState";

interface IProps {
  onEdit: (value: ILocationDetail) => void;
  initialOpen?: boolean;
  labelTranslation: ETranslation;
  initialAddress: string;
  initialZip: string;
  initialCity: string;
  initialInfo: string;
  displayValue?: TInputValue;
  loading?: boolean;
}

const LocationDetailEdit: React.FC<IProps> = ({
  onEdit,
  labelTranslation,
  initialAddress,
  initialZip,
  initialCity,
  initialInfo,
  displayValue,
  loading,
  initialOpen
}) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(initialOpen??false);
  const [address, setAddress] = useState(initialAddress);
  const [zip, setZip] = useState(initialZip);
  const [city, setCity] = useState(initialCity);
  const [info, setInfo] = useState(initialInfo);
  const authUser = useSelector((state: IAppState) => state.auth.user);

  const toggleEdit = useCallback(() => {
    setAddress(initialAddress);
    setZip(initialZip);
    setCity(initialCity);
    setInfo(initialInfo);
    setEditMode((mode) => !mode);
  }, [initialAddress, initialZip, initialCity, initialInfo]);

  const editHandler = useCallback(() => {
    const value = { address, zip, city, info };
    onEdit(value);
    setEditMode((mode) => !mode);
  }, [onEdit, address, zip, city, info]);

  return (
    <div>
      <p>
        <b>{t(labelTranslation)}</b>{" "}
        {authUser?.role === "ADMIN" && !editMode && (
          <FontAwesomeIcon
            icon={faEdit}
            onClick={() => setEditMode((mode) => !mode)}
            style={{ cursor: "pointer" }}
          />
        )}
      </p>
      {loading ? (
        <Spinner />
      ) : editMode ? (
        <div className={classes.InputContainer}>
          <Input
            type={EInputType.text}
            onChange={(newValue) => setAddress(newValue as string)}
            inputName={"address"}
            value={address}
            labelTranslation={ETranslation.COMMON_ADDRESS}
          />
          <Input
            type={EInputType.text}
            onChange={(newValue) => setZip(newValue as string)}
            inputName={"zip"}
            value={zip}
            labelTranslation={ETranslation.COMMON_ZIP}
          />
          <Input
            type={EInputType.text}
            onChange={(newValue) => setCity(newValue as string)}
            inputName={"city"}
            value={city}
            labelTranslation={ETranslation.COMMON_LOCATION}
          />
          <Input
            type={EInputType.text}
            onChange={(newValue) => setInfo(newValue as string)}
            inputName={"info"}
            value={info}
            labelTranslation={ETranslation.INTERPRETATION_ON_SITE_INFO}
          />
          <Button icon={faCheck} onClick={editHandler} />
          <Button
            icon={faTimes}
            color={EButtonColor.DANGER}
            onClick={toggleEdit}
          />
        </div>
      ) : (
        <p>{displayValue}</p>
      )}
    </div>
  );
};

export default LocationDetailEdit;
