import React, { useEffect, useState } from 'react';
import DropdownButton, { IDropdownButtonOption } from '../../ui/DropdownButton/DropdownButton';
import { useConfirmModal } from '../../../hooks/useConfirmModal';
import { IInterpretation } from '../../../interfaces/IInterpretation';
import { useSelector } from 'react-redux';
import IAppState from '../../../interfaces/store/IAppState';
import IInterpretationState from '../../../interfaces/store/IInterpretationState';
import Alert, { EAlertColor } from '../../ui/Alert/Alert';
import { useInterpretationSendConfirmationEmailsModal } from '../../../hooks/useInterpretationSendConfirmationEmailsModal';
import { ETranslation } from '../../../translations/translation-keys';
import { useTranslation } from 'react-i18next';



interface IProps {
  interpretations: IInterpretation[] | null;
  onCreateOrderNumbers: (ids: string[]) => void;
  onSendConfirmationEmails: (ids: string[], emails: string[]) => void;
}


const InterpretationListActions: React.FC<IProps> = ({ interpretations, onCreateOrderNumbers, onSendConfirmationEmails }) => {
  const { t } = useTranslation()
  const [showMessage, setShowMessage] = useState(false);

  const openConfirmModal = useConfirmModal();
  const openSendConfirmationEmailsModal = useInterpretationSendConfirmationEmailsModal();

  const { actionOk, actionError, actionMessage, actionLoading } = useSelector<IAppState, IInterpretationState>(state => state.interpretation);

  useEffect(() => {
    if (actionOk) {
      setShowMessage(true);
    }
  }, [actionOk]);

  const getIds = () => {
    return interpretations!.map(interpretation => interpretation.id);
  }

  const createOrderNumbersHandler = async () => {
    const ids = getIds();

    const success = await openConfirmModal(`Haluatko varmasti luoda tilausnumerot ${ids.length} tulkkaukselle?`);
    if (success) {
      onCreateOrderNumbers(ids);
    }
  }


  const sendConfirmationEmailsHandler = async () => {
    const ids = getIds();
    const emails = await openSendConfirmationEmailsModal();
    if (emails && emails.length > 0) {
      onSendConfirmationEmails(ids, emails);
    }
  }

  const options: IDropdownButtonOption[] = [
    { text:  t(ETranslation.INTERPRETATION_CREATE_ORDER_NUMBER), onClick: createOrderNumbersHandler },
    { text:  t(ETranslation.INTERPRETATION_SEND_ORDER_CONFIRMATION), onClick: sendConfirmationEmailsHandler },
  ]

  if (!interpretations || interpretations.length === 0) {
    return null;
  }

  return (
    <div>
      <DropdownButton options={options} loading={actionLoading}>{t(ETranslation.COMMON_FUNCTIONS)}</DropdownButton>
      {actionError && <Alert>{actionError}</Alert>}
      {actionMessage && showMessage && <Alert color={EAlertColor.SUCCESS} onClose={() => setShowMessage(false)}>{actionMessage}</Alert> }
    </div>
  )
}

export default InterpretationListActions;