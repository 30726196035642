import { Dispatch } from "redux";
import EActionTypes from "../../interfaces/store/EActionTypes";
import ISummaryAction from "../../interfaces/store/ISummaryAction";
import { EFetchMethod, customFetch } from "../../custom-fetch";
import { IUser } from "../../classes/User";
import { IOption } from "../../components/ui/Input/Input";
import { ETranslation } from "../../translations/translation-keys";


export interface ITotals {
	customerPrice: number;
	customerPriceVat: number;
	customerPriceWithVat: number;
	durationInMinutes: number;
	durationInMinutesBooking: number;
	durationInMinutesInstant: number;
	durationInMinutesOnSite: number;
	extraFee: number;
	extraFeeVat: number;
	extraFeeWithVat: number;
	interpretationsCount: number;
	interpreterPrice: number;
	interpreterPriceVat: number;
	interpreterPriceWithVat: number;
	numberOfOffersAccepted: number;
	ratingAverage: number;
	serviceCommissionPrice: number;
	serviceCommissionPriceVat: number;
	serviceCommissionPriceWithVat: number;
	vatPercent: number;

}
export interface ISummaryRow {
	id: number;
	description: string;
	descriptionText: string;
	title: string;
	value: string;
}
export interface ISummaryRowsTotal {
	id: number;
	description: string;
	title: string;
	value: string;
}
export interface IOrganizationYoupret{
	businessId: number;
	city: string;
	name: string;
	streetName: string;
	zip: number;
}
export interface ISummary {
	id: string;
	status: string;
	page: number;
	adminNotes: string;
	paymentDateISO: string;
	paymentDate: string;
	site: string;
	message: string;
	items: string;
	startDate: string;
	endDate: string;
	created: string;
	user: IUser;
	totals: ITotals;
	ratingAverage: number;
	summaryRows: ISummaryRow[];
	summaryRowsTotal: ISummaryRowsTotal;
	organizationYoupret: IOrganizationYoupret;
}

export interface ISummarySearch {
	startDate?: string,
	endDate?: string,
	created?: string,
	page: number,
	status?: string,
	user?: string,
	organization?: string,
	organizations?: string[],
}
export const STATUSES: IOption[] = [
	{ value: "PUBLISHED", labelTranslation: ETranslation.COMMON_PUBLISHED },
	{ value: "DRAFT", labelTranslation: ETranslation.COMMON_DRAFT },
]

type TAction = ISummaryAction;


	const getSummariesStart = (): TAction => {
		return {
			type: EActionTypes.SUMMARIES_GET_START
		};
	};

	const getSummariesSuccess = (summaries: ISummary[], hasNext: boolean, start: number): TAction => {
		return {
			type: EActionTypes.SUMMARIES_GET_SUCCESS,
			hasNext,
			start,
			summaries
		};
	};

	const getSummariesError = (error: string): TAction => {
		return {
			type: EActionTypes.SUMMARIES_GET_ERROR,
			error
		};
	};

	export const getSummariesClear = (): TAction => {
		return {
			type: EActionTypes.SUMMARIES_GET_CLEAR,
		};
	};

	export const getSummaries = () => {

		const body = {
			page: 1,
		}
		return async (dispatch: Dispatch) => {
			dispatch(getSummariesStart())
			try {
				customFetch<{ items: ISummary[], hasNext: boolean, start: number }>("/summary/list", EFetchMethod.POST, JSON.stringify(body)).then((response) => {
					dispatch(getSummariesSuccess(response.items, response.hasNext, response.start))
				}).catch((err) => {
					dispatch(getSummariesError(err))
				})
			} catch (error) {
				dispatch(getSummariesError((error as Error).message));
			}

		}
	};


	const getSummaryStart = (id: string): TAction => {
		return {
			type: EActionTypes.SUMMARY_GET_START,
			id
		};
	};

	const getSummarySuccess = (summary: ISummary): TAction => {
		return {
			type: EActionTypes.SUMMARY_GET_SUCCESS,
			summary,
		};
	};

	const getSummaryFail = (error: string): TAction => {
		return {
			type: EActionTypes.SUMMARY_GET_ERROR,
			error,
		};
	};

	export const getSummaryClear = (): TAction => {
		return {
			type: EActionTypes.SUMMARY_GET_CLEAR,
		};
	};

	export const getSummary = (id: string) => {

		return async (dispatch: Dispatch) => {
			try {
				dispatch(getSummaryStart(id));
				const summary = await customFetch<ISummary>(`/summary/get?id=${id}`);
				dispatch(getSummarySuccess(summary));
			} catch (error) {
				dispatch(getSummaryFail((error as Error).message));
			}
		};
	};

	const updateSummaryStart = (): TAction => {
		return {
			type: EActionTypes.SUMMARY_UPDATE_START,
		};
	};

	const updateSummarySuccess = (): TAction => {
		return {
			type: EActionTypes.SUMMARY_UPDATE_SUCCESS,
		};
	};

	const updateSummaryFail = (error: string): TAction => {
		return {
			type: EActionTypes.SUMMARY_UPDATE_ERROR
			,
			error,
		};
	};

	export const updateSummaryClear = (): TAction => {
		return {
			type: EActionTypes.SUMMARY_UPDATE_CLEAR
		}
	}
	export const updateSummary = (summary: ISummary | null) => {

			const body = {
				id: summary?.id,
				paymentDateISO: summary?.paymentDateISO,
				paymentDate: summary?.paymentDate,
				message: summary?.message,
				adminNotes: summary?.adminNotes
			}
			return async (dispatch: Dispatch) => {
			try {
				dispatch(updateSummaryStart());
				await customFetch<ISummary>(`/summary/update?id=${summary?.id}`, EFetchMethod.POST, JSON.stringify(body))

				dispatch(updateSummarySuccess());

			} catch (error) {
				dispatch(updateSummaryFail((error as Error).message));
			}
		};
	};

	export const updateSummaryStatusClear = (): TAction => {
		return {
			type: EActionTypes.SUMMARY_UPDATE_CLEAR
		}
	}

	export const updateSummaryStatus = (ids: string[], status: string) => {

			const body = {
				status: status,
				items: ids
			}
			return async (dispatch: Dispatch) => {
			try {
				dispatch(updateSummaryStart());
				await customFetch<ISummary>(`/summary/updatestatus`, EFetchMethod.POST, JSON.stringify(body))
				dispatch(updateSummarySuccess());

			} catch (error) {
				dispatch(updateSummaryFail((error as Error).message));
			}
		};
	};

	export const updateSummaryAdminNotes = (adminNotes: string, id: string) => {

		const body = {
			id: id,
			adminNotes: adminNotes,
		}
			return async (dispatch: Dispatch) => {
			try {
				dispatch(updateSummaryStart());
				await customFetch<ISummary>(`/summary/updateadminnotes`, EFetchMethod.POST, JSON.stringify(body))
				dispatch(updateSummarySuccess());

			} catch (error) {
				dispatch(updateSummaryFail((error as Error).message));
			}
		};
	};

	export const searchSummaries = (data: ISummarySearch) => {
		const body = {
			startDate: data.startDate,
			endDate: data.endDate,
			status:	(data.status && data.status?.length > 0) ? data.status : null,
			organization: data.organization,
			user: data.user,
			page: data.page,
		};

		return async (dispatch: Dispatch) => {
		dispatch(getSummariesStart());
		try {
			customFetch<{ items: ISummary[], hasNext: boolean, start: number }>("/summary/list", EFetchMethod.POST, JSON.stringify(body))
			.then((response) => {

				dispatch(getSummariesSuccess(response.items, response.hasNext, response.start));
			})
			.catch((error) => {
				dispatch(getSummariesError((error as Error).message));
			});
		} catch (error) {
			dispatch(getSummariesError((error as Error).message));
		}
		};
	};

	const deleteSummaryStart = (): TAction => {
		return {
		type: EActionTypes.SUMMARY_DELETE_START,
		};
	};

	const deleteSummarySuccess = (ids: string[]): TAction => {
		return {
		type: EActionTypes.SUMMARY_DELETE_SUCCESS,
		ids,
		};
	};

	const deleteSummaryFail = (error: string): TAction => {
		return {
		type: EActionTypes.SUMMARY_DELETE_FAIL,
		error,
		};
	};

	export const deleteSummary = (ids: string[]) => {
		const body = {
			items: ids
		}
			return async (dispatch: Dispatch) => {

			try {
				dispatch(deleteSummaryStart());
				await customFetch<boolean>(`/summary/delete`, EFetchMethod.POST, JSON.stringify(body))
				dispatch(deleteSummarySuccess(ids));
			} catch (error) {
				dispatch(deleteSummaryFail((error as Error).message));
			}
		};
	};