import { Dispatch } from 'redux';

import EActionTypes from '../../interfaces/store/EActionTypes';
import IAuthAction from '../../interfaces/store/IAuthAction';
import { ETranslation } from '../../translations/translation-keys';
import { IUser, User } from './../../classes/User';
import { EFetchMethod, customFetch, customFetchWithResponse } from './../../custom-fetch';
import { ISite } from '../../interfaces/ISite';

type TAction = IAuthAction;

const getUserStart = (): TAction => {
	return {
		type: EActionTypes.AUTH_GET_USER_START
	};
};

const getUserSuccess = (user: User): TAction => {
	return {
		type: EActionTypes.AUTH_GET_USER_SUCCESS,
		user,
	};
};

interface ILoginResult {
	accessToken: string;
	email: string;
	language: string;
	redirectUrl: string;
	status: string;
	tzString: string;
	user: IUser;
	site?: string;
}

const userLoginStart = (): TAction => {
	return {
		type: EActionTypes.AUTH_LOGIN_START,
	}
};

const userLoginSuccess = (redirect: string, user: User): TAction => {
	return {
		type: EActionTypes.AUTH_LOGIN_SUCCESS,
		redirectUrl: redirect,
		user: user,
	}
};
const userLoginFail = (error: string): TAction => {
	return {
		type: EActionTypes.AUTH_LOGIN_FAIL,
		loginError: error,
	}
};

// WIP
export const userLogin = (username: string, password: string) =>{
	return async (dispatch: Dispatch) =>{
		try {
			dispatch(userLoginStart());
			const data = {email: username, password: password, accessToken: undefined}
			customFetch<ILoginResult>("/auth/email", EFetchMethod.POST, JSON.stringify(data)).then((result) =>{
				dispatch(userLoginSuccess(result.redirectUrl, new User(result.user)));
			}).catch((err) =>{
				dispatch(userLoginFail(err.message as string));
			})
		} catch(err) {
			console.error(err);
		}
	}
}

const getUserFail = (error: string): TAction => {
	return {
		type: EActionTypes.AUTH_GET_USER_FAIL,
		error
	}
};

const getSiteStart = (): TAction =>{
	return {
		type: EActionTypes.AUTH_GET_SITE_START
	}
}

const getSiteSuccess = (site: ISite): TAction =>{
	return {
		type: EActionTypes.AUTH_GET_SITE_SUCCESS,
		site
	}
}

const getSiteClear = (): TAction =>{
	return {
		type: EActionTypes.AUTH_GET_SITE_CLEAR,
	}
}

const getSiteFail = (siteError: string): TAction =>{
	return {
		type: EActionTypes.AUTH_GET_SITE_FAIL,
		siteError
	}
}

export const clearCurrentUserSite = () =>{
	return async (dispatch: Dispatch) =>{
		dispatch(getSiteClear())
	}
}

export const getSiteAndSetAsCurrent = (siteId: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getSiteStart())
			customFetchWithResponse('/sites/get?id='+ siteId, EFetchMethod.GET).then((result) =>{
				result.json().then((site) =>{
					if (site && site.id) {
						dispatch(getSiteSuccess(site));
					} else {
						dispatch(getSiteFail(ETranslation.AUTH_ACTIONS_USER_NOT_IDENTIFIED));
					}
				})
			}).catch((err: string)=>{
				dispatch(getSiteFail(err));
			})
		} catch (error) {
			dispatch(getSiteFail((error as Error).message));
		}

	};
};


export const getAuthUser = () => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(getUserStart())
			const user = await customFetch<IUser>('/users/currentuser');

			if (user && user.id) {
				dispatch(getUserSuccess(new User(user)));
			} else {
				dispatch(getUserFail(ETranslation.AUTH_ACTIONS_USER_NOT_IDENTIFIED));
			}
		} catch (error) {
			dispatch(getUserFail((error as Error).message));
		}

	};
};

export const getAuthLogout = () => {
	return async (dispatch: Dispatch) => {
		try {
			const result = await customFetch('/users/logout');

			if (result && result === "NO_AUTH") {
				window.location.href = "/login";
			} else {
				if (result === "logout") {
					//var language = $.cookie("youpretLang");
					var cookies = document.cookie.split(";");
					for (var i = 0; i < cookies.length; i++) {
						var equals = cookies[i].indexOf("=");
						var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
						name = name.trim();
						if (name !== "youpretLang") {
							document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
						}
					}
					document.location.href = "/fi/";

				}
			}


		} catch (error) {
			console.error(error);
		}

	}
}