import { EFetchMethod, customFetch } from "../custom-fetch";
import { IOrganization } from "../interfaces/IOrganization";
import { createOptions } from "../utils/option-utils";


export const searchOrganizations = async (term: string, signal: AbortSignal) => {
	const organizations = await customFetch<IOrganization[] | string>(
		"/organizations/search?q=" + term,
		EFetchMethod.GET,
		undefined,
		signal
	);
	if (organizations === "NO_RESULTS") return [];
	return createOptions(organizations as IOrganization[]);
};