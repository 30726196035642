import { Dispatch } from 'redux';

import { customFetch, EFetchMethod } from '../../custom-fetch';
import { INewsItem } from '../../interfaces/INewsItem';
import EActionTypes from '../../interfaces/store/EActionTypes';
import INewsItemAction from '../../interfaces/store/INewsItemAction';


type NAction = INewsItemAction;

const findNewsItemsStart = (): NAction => {
  return {
    type: EActionTypes.NEWS_ITEM_FIND_START,
  };
};

const findNewsItemsSuccess = (newsItems: INewsItem[]): NAction => {
  return {
    type: EActionTypes.NEWS_ITEM_FIND_SUCCESS,
    newsItems,
  };
};

const findNewsItemsFail = (error: string): NAction => {
  return {
    type: EActionTypes.NEWS_ITEM_FIND_FAIL,
    error,
  };
};

export const findNewsItems = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(findNewsItemsStart());
      const newsItems = await customFetch<INewsItem[]>("/newsitems/list");
      dispatch(findNewsItemsSuccess(newsItems));
    } catch (error) {
      dispatch(findNewsItemsFail((error as Error).message));
    }
  };
};

const getNewsItemStart = (id: string): NAction => {
  return {
    type: EActionTypes.NEWS_ITEM_GET_START,
    id
  };
};

const getNewsItemSuccess = (newsItem: INewsItem): NAction => {
  return {
    type: EActionTypes.NEWS_ITEM_GET_SUCCESS,
    newsItem,
  };
};

const getNewsItemFail = (error: string): NAction => {
  return {
    type: EActionTypes.NEWS_ITEM_GET_FAIL,
    error,
  };
};

export const getNewsItemClear = (): NAction => {
  return {
    type: EActionTypes.NEWS_ITEM_GET_CLEAR,
  };
};

export const getNewsItem = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getNewsItemStart(id));
      const newsItem = await customFetch<INewsItem>(`/newsitems/get?id=${id}`);
      dispatch(getNewsItemSuccess(newsItem));
    } catch (error) {
      dispatch(getNewsItemFail((error as Error).message));
    }
  };
};

const deleteNewsItemStart = (): NAction => {
  return {
    type: EActionTypes.NEWS_ITEM_DELETE_START,
  };
};

const deleteNewsItemSuccess = (id: string): NAction => {
  return {
    type: EActionTypes.NEWS_ITEM_DELETE_SUCCESS,
    id,
  };
};

const deleteNewsItemFail = (error: string): NAction => {
  return {
    type: EActionTypes.NEWS_ITEM_DELETE_FAIL,
    error,
  };
};

export const deleteNewsItemClear = (): NAction => {
  return {
    type: EActionTypes.NEWS_ITEM_DELETE_CLEAR,
  };
};

export const deleteNewsItem = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deleteNewsItemStart());
      await customFetch<boolean>(`/newsitems/delete?id=${id}`, EFetchMethod.DELETE);
      dispatch(deleteNewsItemSuccess(id));
    } catch (error) {
      dispatch(deleteNewsItemFail((error as Error).message));
    }
  };
};

const saveOrUpdateNewsItemStart = (): NAction => {
  return {
    type: EActionTypes.NEWS_ITEM_SAVE_OR_UPDATE_START,
  };
};

const saveOrUpdateNewsItemSuccess = (): NAction => {
  return {
    type: EActionTypes.NEWS_ITEM_SAVE_OR_UPDATE_SUCCESS,
  };
};

const saveOrUpdateNewsItemFail = (error: string): NAction => {
  return {
    type: EActionTypes.NEWS_ITEM_SAVE_OR_UPDATE_FAIL,
    error,
  };
};

export const saveOrUpdateNewsItemClear = (): NAction => {
  return {
    type: EActionTypes.NEWS_ITEM_SAVE_OR_UPDATE_CLEAR
  }
}

const newsItemSaveReviewStart = (): NAction => {
	return {
	  type: EActionTypes.NEWS_ITEM_REVIEW_SAVE_START,
	};
  };
  
  const newsItemSaveReviewSuccess = (): NAction => {
	return {
	  type: EActionTypes.NEWS_ITEM_REVIEW_SAVE_SUCCESS,
	};
  };
  
  const newsItemSaveReviewFail = (error: string): NAction => {
	return {
	  type: EActionTypes.NEWS_ITEM_REVIEW_SAVE_FAIL,
	  error,
	};
  };
  
  export const newsItemSaveReviewClear = (): NAction => {
	return {
	  type: EActionTypes.NEWS_ITEM_REVIEW_SAVE_CLEAR,
	}
  };

export const newsItemSaveReview = (interpretationID: String, customer: boolean) => {
return async (dispatch: Dispatch) => {
	try {
	dispatch(newsItemSaveReviewStart());
	await customFetch<INewsItem>(`/newsitems/addreview?id=${interpretationID}&iscustomer=${customer}`,EFetchMethod.POST)
	dispatch(newsItemSaveReviewSuccess());
	} catch (error) {
	dispatch(newsItemSaveReviewFail((error as Error).message));
	}
  };
};

export const saveNewsItem = (newsItem: Partial<INewsItem>) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveOrUpdateNewsItemStart());
      await customFetch<INewsItem>(`/newsitems/add`, EFetchMethod.POST, JSON.stringify(newsItem));
      dispatch(saveOrUpdateNewsItemSuccess());
    } catch (error) {
      dispatch(saveOrUpdateNewsItemFail((error as Error).message));
    }
  };
};

export const updateNewsItem = (newsItem: INewsItem) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveOrUpdateNewsItemStart());
      await customFetch<INewsItem>("/newsitems/update", EFetchMethod.PUT, JSON.stringify(newsItem));
      dispatch(saveOrUpdateNewsItemSuccess());
    } catch (error) {
      dispatch(saveOrUpdateNewsItemFail((error as Error).message));
    }
  };
};