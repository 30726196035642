import { customFetch, EFetchMethod } from '../custom-fetch';
import { IOrganizationGroup } from '../interfaces/IOrganizationGroup';
import { createOptions } from '../utils/option-utils';

export const searchOrganizationGroups = async (
	term: string,
	signal: AbortSignal
) => {
	const organizationGroups = await customFetch<IOrganizationGroup[] | string>(
		"/organizationgroups/search?q=" + term,
		EFetchMethod.GET,
		undefined,
		signal
	);
	if (organizationGroups === "NO_RESULTS") {
		return [];
	}
	return createOptions(organizationGroups as IOrganizationGroup[]);
};


export const getOrganizationGroupOptions = async (
	ids: string[]
) => {
	const organizationGroups = await Promise.all(ids.map(id => {
		return customFetch<IOrganizationGroup>("/organizationgroups/get?id=" + id);
	}))
	return createOptions(organizationGroups as IOrganizationGroup[]);
};
