import React from "react";
import { ITag } from "../../store/actions/tagsActions";
import Spinner from "../../components/ui/Spinner/Spinner";
import Table from "../../components/ui/Table/Table";

interface IProps {
	tags: ITag[] | null;
	loading: boolean;
	error: string | null;
	openTag: (tag: ITag) => void;
}

const TagsList: React.FC<IProps> = ({ tags, loading, error, openTag }) => {
	return (
		loading ? <Spinner /> :
			error ?
				<tr> {error}</tr> :
				<Table hover={true} >
					<thead>
						<tr>
							<th>Nimi</th>
							<th>Description</th>
						</tr>
					</thead>

					<tbody>
						{tags && tags.map((tag, id) => {
							return (
								<tr key={id} onClick={() => { openTag(tag) }} >
									<td>{tag.name}</td>
									<td>{tag.description}</td>
								</tr>
							)
						})
						}
					</tbody>
				</Table>
	)
}

export default TagsList;