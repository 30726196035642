import { MouseEvent } from "react";
import { useHistory } from "react-router-dom"

export const useCustomHistory = () => {
  const history = useHistory();


  return {
    push: (ev: MouseEvent, to: string) => {
      if (ev.ctrlKey) {
        window.open(to);
        return;
      }
      history.push(to);
    }
  }
}