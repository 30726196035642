import { Dispatch } from 'redux';

import { customFetch } from '../../custom-fetch';
import EActionTypes from '../../interfaces/store/EActionTypes';
import ITwilioAction from '../../interfaces/store/ITwilioAction';

type TAction = ITwilioAction;

const getConversationTokenStart = (): TAction => {
    return {
        type: EActionTypes.TWILIO_GET_CONVERSATION_TOKEN_START
    };
};

const getConversationTokenSuccess = (conversationToken: string): TAction => {
    return {
        type: EActionTypes.TWILIO_GET_CONVERSATION_TOKEN_SUCCESS,
        conversationToken,
    };
};

const getConversationTokenFail = (error: string): TAction => {
    return {
        type: EActionTypes.TWILIO_GET_CONVERSATION_TOKEN_FAIL,
        error
    }
};


export const getConversationToken = () => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(getConversationTokenStart())
            const conversationToken = await customFetch<string>('/twilio/conversations/token');
            dispatch(getConversationTokenSuccess(conversationToken));
        } catch (error) {
            dispatch(getConversationTokenFail((error as Error).message));
        }
        
    };
};
