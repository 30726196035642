import React, { useEffect, useState } from "react";

import classes from "./interpretationDetails.module.scss"
import { IInterpretationUserRows } from "../../../interfaces/IInterpretation";
import InterpreterStatus from "../InterpreterStatus/InterpreterStatus";
import { faCheck, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { ETranslation } from "../../../translations/translation-keys";
import { INTERPRETER_OFFER_STATUS_SELECT } from "../../../shared/interpretation-data";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { getInputData } from "../../ui/Input/input-utils";
import { useDispatch, useSelector } from "react-redux";
import { getInterpretation, updateInterpretationProperty } from "../../../store/actions";
import IAppState from "../../../interfaces/store/IAppState";
import Spinner from "../../ui/Spinner/Spinner";
import InputGroup from "../../ui/InputGroup/InputGroup";

interface IListProps {
	users: IInterpretationUserRows[];
	editable?: boolean;
	interpretationId: string;
}

const InterpretationOfferedUsers: React.FC<IListProps> = ({ users, editable, interpretationId }) => {
	return <>
		{users.map((user, key) => {
			if (user.type === "INTERPRETER_CANDIDATE" || user.type === "INTERPRETER") {
				return <InterpretationOfferRow row={user} editable={editable} key={key} interpretationId={interpretationId} />
			} else {
				return null;
			}
		})}
	</>
}

interface IOfferRowProps {
	row: IInterpretationUserRows;
	editable?: boolean;
	interpretationId: string;
}

enum EInputs {
	offerStatus = "offerStatus",
}

const InterpretationOfferRow: React.FC<IOfferRowProps> = ({ row, editable, interpretationId }) => {

	const [editMode, setEditMode] = useState(false);

	//INTERPRETER_OFFER_STATUS_SELECT

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.offerStatus]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_STATUS,
			value: row.offerStatus,
			options: INTERPRETER_OFFER_STATUS_SELECT(),
			multiple: false,
			isClearable: false,
		},
	});

	const dispatch = useDispatch();

	const { updateLoading } = useSelector((state: IAppState) => ({
		updateLoading: state.interpretation.updateLoading,
	}));

	const [updatedOrDeleted, setuod] = useState(false);

	const [load, setLoad] = useState(false);
	useEffect(() => {
		if(!updatedOrDeleted) return;
		if (!load && !updateLoading) return;
		if (!load && updateLoading) {setLoad(true); return}
		if (load && !updateLoading) {setEditMode(false);dispatch(getInterpretation(interpretationId));setLoad(false); return;}
		// prevent inf loop. TODO, usePrevious state to compare these and cleanup
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateLoading]);

	const update = () => {
		setuod(true);
		const data = getInputData<{ offerStatus: string }>(inputs);
		dispatch(updateInterpretationProperty(interpretationId, "offerStatus_" + row.user.id, data.offerStatus))
	}

	const deleteRow = () => {
		setuod(true);
		dispatch(updateInterpretationProperty(interpretationId, "deleteInterpretationUserRow", row.id))
	}

	const createInput = useCreateInput(inputs, setInputs);

	return <span className={classes.userRowContainer} >
		{
			editMode ?
				<InputGroup>
					{createInput(EInputs.offerStatus)}
					{load ? <Spinner />
						:
						<FontAwesomeIcon
							icon={faCheck}
							onClick={update}
							style={{ cursor: "pointer", marginLeft: "20px", marginRight: "40px", marginTop: "25px", fontSize: "30px", top: "5px" }}
						/>

					}
				</InputGroup>
				:
				<InterpreterStatus status={row.offerStatus} />
		}
		<span className={classes.clickableUserName}>
			<a href={`/users/edit/${row.user.id}`} target="_blank" rel="noopener noreferrer">
				<b>
					{`${row.user.firstName} ${row.user.lastName} ${row.user.phoneNumber}`}
				</b>
			</a>
			{load ? <Spinner />
				:
				editable && <> <FontAwesomeIcon
					icon={faEdit}
					onClick={() => setEditMode((editMode) => !editMode)}
					style={{ cursor: "pointer", marginLeft: "20px", top: "5px", color: "black" }}
				/>
					<FontAwesomeIcon
						icon={faTrash}
						onClick={() => deleteRow()}
						style={{ cursor: "pointer", marginLeft: "20px", top: "5px", color: "black" }}
					/></>
			}
		</span>

	</span >
}

export default InterpretationOfferedUsers;