import React from 'react';

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'

import BlotFormatter from 'quill-blot-formatter';

Quill.register("modules/blotFormatter", BlotFormatter);

interface IProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const modules = {
  blotFormatter: {},
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image'],
    ['clean']
  ],
}

const WysiwygEditor:React.FC<IProps> = ({ value, onChange, disabled }) => {
  return (
    <ReactQuill theme="snow" value={value} onChange={onChange} modules={modules} />
  )
}

export default WysiwygEditor;