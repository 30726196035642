import React, { useContext, useEffect, useState } from "react";

import SendModal from "../SendModal/SendModal";
import Button from "../../ui/Button/Button";
import ModalContext, { EModalSize } from "../../ui/Modal/ModalContext";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";

interface IProps {
  onSend?: () => void;
  onSendClose?: () => void;
  onSendDone?: () => void;
  sendText?: string;
  sendLoading?: boolean;
  sendError?: string | null;
  sendOk?: boolean;
}

const SendButton: React.FC<IProps> = ({
  onSend,
  onSendClose,
  onSendDone,
  sendText,
  sendLoading,
  sendError,
  sendOk,
}) => {
  const { setModal, closeModal } = useContext(ModalContext);
  const [showSend, setShowSend] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (sendOk) {
      setTimeout(() => {
        closeModal();
        if (onSendDone) {
          onSendDone();
        }
      }, 1);
    }
  }, [sendOk, closeModal, onSendDone]);

  useEffect(() => {
    if (showSend) {
      setModal({
        isOpen: showSend,
        title: t(ETranslation.TEXTMESSAGE_CONFIRM_SEND),
        size: EModalSize.SMALL,
        loading: sendLoading,
        content: (
          <SendModal
            sendText={sendText}
            onSend={onSend}
            error={sendError}
          />
        ),
        onModalClose: () => {
          setShowSend(false);
          if (onSendClose) {
            onSendClose();
          }
        },
      });
    }
    // eslint-disable-next-line
  }, [sendLoading, sendError, showSend, sendText, setModal]);

  const sendHandler = () => {
    setShowSend(true);
  };

  return (
    <>
      {onSend && (
        <Button
          onClick={sendHandler}
          style={{ marginLeft: ".5rem" }}
        >
          {t(ETranslation.COMMON_SEND)}
        </Button>
      )}
    </>
  );
};

export default SendButton;