import { TFunction } from 'i18next';
import { ETranslation } from '../translations/translation-keys';
import { IOption } from './../components/ui/Input/Input';


/** TODO Tämän tiedoston tekstit järkeviksi */

export enum EPriceSeasonRuleChargeType {
  PRICE_PER_MINUTE = 'PRICE_PER_MINUTE',
  PRICE_PER_INTERPRETATION = 'PRICE_PER_INTERPRETATION',
  PRICE_P_PER_INTERPRETATION = 'PRICE_P_PER_INTERPRETATION',
  DISCOUNT_PER_INTERPRETATION = 'DISCOUNT_PER_INTERPRETATION',
  DISCOUNT_P_PER_INTERPRETATION = 'DISCOUNT_P_PER_INTERPRETATION',
}

export const PRICE_SEASON_RULE_CHARGE_TYPES: IOption[] = [
  { value: EPriceSeasonRuleChargeType.PRICE_PER_MINUTE, labelTranslation: ETranslation.DATA_PRICE_SEASON_PRICE_PER_MINUTE},
  { value: EPriceSeasonRuleChargeType.PRICE_PER_INTERPRETATION, labelTranslation: ETranslation.DATA_PRICE_SEASON_PRICE_PER_INTERPRETATION},
  { value: EPriceSeasonRuleChargeType.PRICE_P_PER_INTERPRETATION, labelTranslation: ETranslation.DATA_PRICE_SEASON_PRICE_P_PER_INTERPRETATION },
  { value: EPriceSeasonRuleChargeType.DISCOUNT_PER_INTERPRETATION, labelTranslation: ETranslation.DATA_PRICE_SEASON_DISCOUNT_PER_INTERPRETATION},
  { value: EPriceSeasonRuleChargeType.DISCOUNT_P_PER_INTERPRETATION, labelTranslation: ETranslation.DATA_PRICE_SEASON_DISCOUNT_P_PER_INTERPRETATION},
]

export enum EPriceSeasonRuleTermsOfCancel {
  BEFORE_START_48 = 'BEFORE_START_48',
  BEFORE_START_24 = 'BEFORE_START_24',
  BEFORE_START_12 = 'BEFORE_START_12',
  BEFORE_START_6 = 'BEFORE_START_6',
  PREVIOUS_BUSINESS_DAY_AT_17 = 'PREVIOUS_BUSINESS_DAY_AT_17',
  PREVIOUS_DAY_AT_15_30 = 'PREVIOUS_DAY_AT_15_30',
  PREVIOUS_DAY_AT_16 = 'PREVIOUS_DAY_AT_16',
  PREVIOUS_DAY_AT_12 = 'PREVIOUS_DAY_AT_12',
}

export const PRICE_SEASON_RULE_TERMS_OF_CANCEL: IOption[] = [
  { value: EPriceSeasonRuleTermsOfCancel.BEFORE_START_48, labelTranslation: ETranslation.DATA_PRICE_SEASON_BEFORE_START_48},
  { value: EPriceSeasonRuleTermsOfCancel.BEFORE_START_24, labelTranslation: ETranslation.DATA_PRICE_SEASON_BEFORE_START_24},
  { value: EPriceSeasonRuleTermsOfCancel.BEFORE_START_12, labelTranslation: ETranslation.DATA_PRICE_SEASON_BEFORE_START_12},
  { value: EPriceSeasonRuleTermsOfCancel.BEFORE_START_6, labelTranslation: ETranslation.DATA_PRICE_SEASON_BEFORE_START_6},
  { value: EPriceSeasonRuleTermsOfCancel.PREVIOUS_BUSINESS_DAY_AT_17, labelTranslation: ETranslation.DATA_PRICE_SEASON_PREVIOUS_BUSINESS_DAY_AT_17},
  { value: EPriceSeasonRuleTermsOfCancel.PREVIOUS_DAY_AT_15_30, labelTranslation: ETranslation.DATA_PRICE_SEASON_PREVIOUS_DAY_AT_15_30},
  { value: EPriceSeasonRuleTermsOfCancel.PREVIOUS_DAY_AT_16, labelTranslation: ETranslation.DATA_PRICE_SEASON_PREVIOUS_DAY_AT_16},
  { value: EPriceSeasonRuleTermsOfCancel.PREVIOUS_DAY_AT_12, labelTranslation: ETranslation.DATA_PRICE_SEASON_PREVIOUS_DAY_AT_12},
]

export enum EPriceSeasonRuleTag {
  SECURITY_CLEARED = 'SECURITY_CLEARED',
  VIDEO = 'VIDEO',
}

export const PRICE_SEASON_RULE_TAGS: IOption[] = [
  { value: EPriceSeasonRuleTag.SECURITY_CLEARED, labelTranslation: ETranslation.COMMON_SECURITY_CLEARED},
  { value: EPriceSeasonRuleTag.VIDEO, labelTranslation: ETranslation.DATA_PRICE_SEASON_VIDEO},
]

export enum EPriceSeasonRuleDay {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  PUBLIC_HOLIDAY = 'PUBLIC_HOLIDAY'
}

export const PRICE_SEASON_RULE_DAYS: IOption[] = [
  { value: EPriceSeasonRuleDay.MONDAY, labelTranslation: ETranslation.COMMON_MONDAY},
  { value: EPriceSeasonRuleDay.TUESDAY, labelTranslation: ETranslation.COMMON_TUESDAY},
  { value: EPriceSeasonRuleDay.WEDNESDAY, labelTranslation: ETranslation.COMMON_WEDNESDAY},
  { value: EPriceSeasonRuleDay.THURSDAY, labelTranslation: ETranslation.COMMON_THURSDAY},
  { value: EPriceSeasonRuleDay.FRIDAY, labelTranslation: ETranslation.COMMON_FRIDAY},
  { value: EPriceSeasonRuleDay.SATURDAY, labelTranslation: ETranslation.COMMON_SATURDAY},
  { value: EPriceSeasonRuleDay.SUNDAY, labelTranslation: ETranslation.COMMON_SUNDAY},
  { value: EPriceSeasonRuleDay.PUBLIC_HOLIDAY, labelTranslation: ETranslation.DATA_PRICE_SEASON_PUBLIC_HOLIDAY},
]

export const getPriceSeasonRuleTypeString = (
  t: TFunction, type: EPriceSeasonRuleChargeType 
): string => t(PRICE_SEASON_RULE_CHARGE_TYPES.find(item => item.value === type)?.labelTranslation || "");

export const getTermOfCancellTypeString = (
	t: TFunction, type: EPriceSeasonRuleTermsOfCancel 
  ): string => t(PRICE_SEASON_RULE_TERMS_OF_CANCEL.find(item => item.value === type)?.labelTranslation || "");

