import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ILanguageCode } from "../../../interfaces/ILanguageCode";
import { ETranslation } from "../../../translations/translation-keys";
import Alert from "../../ui/Alert/Alert";
import Input, { EInputType, TInputValue } from "../../ui/Input/Input";
import Spinner from "../../ui/Spinner/Spinner";
import Table from "../../ui/Table/Table";

interface IProps {
	languageCodes: ILanguageCode[] | null;
	loading: boolean;
	error: string | null;
	onClick: (id: string) => void;
}

const LanguageCodeList: React.FC<IProps> = ({
	languageCodes,
	loading,
	error,
	onClick,
}) => {
	const [filter, setFilter] = useState("");
	const { t } = useTranslation();
	if (error) {
		return <Alert>{error}</Alert>;
	}

	if (!languageCodes || loading) {
		return <Spinner />;
	}

	if (languageCodes.length === 0) {
		return <p>{t(ETranslation.LANGUAGE_CODE_NO_LANGUAGE_CODES)}</p>;
	}

	const itemFilter = (item: ILanguageCode): boolean => {
		return (
			filter.length === 0 ||
			item.name?.toLowerCase().indexOf(filter.toLowerCase()) !== -1
		);
	};

	return (
		<>
			<Input
				containerStyles={{ margin: "1rem 0" }}
				type={EInputType.text}
				inputName="filter"
				value={filter}
				onChange={(value: TInputValue) => setFilter(value as string)}
				placeholderTranslation={ETranslation.COMMON_FILTER_BY_NAME}
			/>

			<Table hover={true}>
				<thead>
					<tr>
						<th>{t(ETranslation.COMMON_NAME)}</th>
						<th>{t(ETranslation.PAGES_LANGUAGE_CODE_EDIT_TITLE)}</th>
					</tr>
				</thead>
				<tbody>
					{languageCodes.filter(itemFilter).map((languageCode) => (
						<tr key={languageCode.id} onClick={() => onClick(languageCode.id)}>
							<td>{languageCode.name}</td>
							<td>{languageCode.languageCode}</td>
						</tr>
					))}
				</tbody>
			</Table>
		</>
	);
};

export default LanguageCodeList;
