import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useCreateInput } from "../../hooks/useCreateInput";
import { ITranslation } from "../../interfaces/ITranslation";
import IAppState from "../../interfaces/store/IAppState";
import ILanguageState from "../../interfaces/store/ILanguageState";
import IModalState from "../../interfaces/store/IModalState";
import { ETranslation } from "../../translations/translation-keys";
import Alert from "../ui/Alert/Alert";
import Button, { EButtonColor } from "../ui/Button/Button";
import { EInputType, IInputField, IOption } from "../ui/Input/Input";
import { getInputData, updateInputHandler, validateInputs } from "../ui/Input/input-utils";
import InputGroup from "../ui/InputGroup/InputGroup";
import ModalBody from "../ui/Modal/ModalBody/ModalBody";
import ModalContext from "../ui/Modal/ModalContext";
import ModalFooter from "../ui/Modal/ModalFooter/ModalFooter";
import TranslationsTable from "./TranslationsTable";

enum EInputs {
	languageCode = "languageCode",
	name = "name",
}

interface IProps {
	translations: ITranslation[];
	onSave: (translation: ITranslation) => void;
	onDelete: (id: string, langCode: string) => void;
	onEdit: (translation: ITranslation) => void;
}

const TranslationsManageModal: React.FC<IProps> = ({ translations, onSave, onDelete, onEdit }) => {
	const { closeModal } = useContext(ModalContext);
	const { t } = useTranslation();
	const [languageOptions, setLanguageOptions] = useState<IOption[]>([]);
	const [isValid, setIsValid] = useState(false);

	const { languages, languagesLoading } = useSelector<IAppState, ILanguageState>((state) => state.language);

	const { updating, updatingError, updatingOk, deleting, deletingError, deletingOk } = useSelector<
		IAppState,
		IModalState
	>((state) => state.modal);

	useEffect(() => {
		if (languages) {
			const alreadyAdded = translations.map((lang) => lang.languageCode);
			const languageOptions = [...languages]
				.filter((languageCode) => !alreadyAdded.includes(languageCode.languageCode))
				.map((language) => ({
					value: language.languageCode,
					label: `${language.name} (${language.languageCode})`,
				}));
			setLanguageOptions(languageOptions);
		}
	}, [translations, languages, updatingOk, deletingOk]);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.languageCode]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.LANGUAGE_LANGUAGE_CODE,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.name]: {
			type: EInputType.text,
			labelTranslation: ETranslation.LANGUAGE_TRANSLATION,
			value: "",
			validation: {
				required: true,
			},
		},
	});

	useEffect(() => {
		const isValid = validateInputs(inputs);
		setIsValid(isValid);
	}, [inputs]);

	const createInput = useCreateInput(inputs, setInputs);

	const addHandler = useCallback(() => {
		const newTranslation = getInputData<ITranslation>(inputs);
		updateInputHandler(EInputs.languageCode, "", setInputs);
		updateInputHandler(EInputs.name, "", setInputs);
		onSave(newTranslation);
	}, [onSave, inputs]);

	return (
		<>
			<ModalBody>
				{deletingError && <Alert>{deletingError}</Alert>}
				{updatingError && <Alert>{updatingError}</Alert>}
				<InputGroup
					style={{
						alignItems: "end",
					}}
				>
					{createInput(EInputs.languageCode, {
						options: languageOptions,
						isLoading: deleting || updating || languagesLoading,
					})}
					{createInput(EInputs.name)}
					<Button onClick={addHandler} loading={updating} disabled={!isValid}>
						{t(ETranslation.COMMON_ADD_NEW)}
					</Button>
				</InputGroup>
				<TranslationsTable translations={translations} onEdit={onEdit} onDelete={onDelete} />
			</ModalBody>
			<ModalFooter>
				<Button onClick={closeModal} color={EButtonColor.DEFAULT}>
					{t(ETranslation.COMMON_CLOSE)}
				</Button>
			</ModalFooter>
		</>
	);
};

export default TranslationsManageModal;
