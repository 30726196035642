import React, { useState } from "react";
import { EInputType, IInputField } from "../../../ui/Input/Input";
import { ETranslation } from "../../../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import { useCreateInput } from "../../../../hooks/useCreateInput";
import { getInputData } from "../../../ui/Input/input-utils";
import EditButtons from "../../../ui/EditButtons/EditButtons";

interface Iprops {
	emails: string | null;
	onConfirm: (emails: string, body: string) => void;
	defaultBody: string;
	cancelHandler: () => void;
} 

const EInputs = {
	emails: "emails",
	body: "body",
}

interface IFeedBackSurvey {
	emails: string,
	body: string,
}

const InterpretationFeedbackSurvey: React.FC<Iprops> = ({ emails, defaultBody, onConfirm, cancelHandler }) => {

	const { t } = useTranslation()
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.emails]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_EMAIL_RECIPIENT,
			value: emails,
		}, 
		[EInputs.body]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.INTREPRETATION_FEEDBACK_SURVEY,
			value: defaultBody,
		},
	});
	const createInput = useCreateInput(inputs, setInputs);

	const onSubmit = () => {
		const data = getInputData<IFeedBackSurvey>(inputs);
		onConfirm(data.emails, data.body);
	}

	const onCancel = () => {
		cancelHandler();
	}

	return <>
		<div style={{marginBottom: "10px", color:"grey"}}>{t(ETranslation.INTREPRETATION_FEEDBACK_RECIPENTS)}</div>
		{createInput(EInputs.emails)}
		{createInput(EInputs.body, {rows:15})}
		<EditButtons onCancel={onCancel} onSend={onSubmit} isAdd={false} />
	</>
}

export default InterpretationFeedbackSurvey;