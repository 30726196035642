import EActionTypes from "../../interfaces/store/EActionTypes";
import ISummaryState from "../../interfaces/store/ISummaryState";
import ISummaryAction from "../../interfaces/store/ISummaryAction";
import { ETranslation } from "../../translations/translation-keys";


type ReducerState = ISummaryState;
type ReducerSignature = (state: ReducerState, action: ISummaryAction) => ReducerState;

const initialState: ReducerState = {
	summary: null,
	search: null,
	loading: false,
	error: null,
	summaries: null,
	summariesLoading: false,
	updateOk: false,
	updateLoading: false,
	hasNext: false,
	updateError: null,
};

const getSummaryStart: ReducerSignature = (state): ReducerState => {
	return { ...state, error: null, loading: true, summary: null };
};

const getSummarySuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		summary: action.summary || null,
		error: null,
		loading: false,
	};
};

const getSummaryFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, summary: null, loading: false, error: action.error ?? null };
};

const getSummaryClear: ReducerSignature = (state): ReducerState => {
	return { ...state, summary: null, loading: false, error: null };
}

const getSummariesStart: ReducerSignature = (state): ReducerState => {
	return { ...state, error: null, loading: true };
};

const getSummariesSuccess: ReducerSignature = (state, action): ReducerState => {
	const summaries = state.summaries ? action.summaries ? [...state.summaries, ...action.summaries] : [...state.summaries] : action.summaries 
	return {
		...state,
		summaries: summaries || null,
		error: null,
		hasNext: action.hasNext ?? false,
		loading: false,
	};
};
const getSummariesError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, summaries: null, loading: false, error: action.error ?? null };
};

const getSummariesClear: ReducerSignature = (state): ReducerState => {
	return { ...state, summaries: null, loading: false, error: null, hasNext: false };
}
const getupdateSummaryStart: ReducerSignature = (state): ReducerState => {
	return { ...state, updateError: null, updateLoading: true, updateOk: false };
}
const getupdateSummarySuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		updateError: null,
		updateLoading: false,
		updateOk: true
	};
}
const getupdateSummaryFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, updateLoading: false, updateError: action.error || ETranslation.REDUCER_SAVE_ERROR };
}
const getupdateSummaryClear: ReducerSignature = (state): ReducerState => {
	return { ...state, updateLoading: false, updateError: null, updateOk: false }
}
const getdeleteSummaryStart: ReducerSignature = (state): ReducerState => {
	return { ...state, error: null, updateLoading: true, updateOk: false }
}
const getdeleteSummarySuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state, summaries: action.summaries || null, error: null, updateLoading: false, updateOk: true
	};
}
const getdeleteSummaryFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, summaries: null, loading: false, error: action.error ?? null };
}
const reducer = (state: ReducerState = initialState, action: ISummaryAction): ReducerState => {
	switch (action.type) {
		case EActionTypes.SUMMARY_GET_START:
			return getSummaryStart(state, action);
		case EActionTypes.SUMMARY_GET_SUCCESS:
			return getSummarySuccess(state, action);
		case EActionTypes.SUMMARY_GET_ERROR:
			return getSummaryFail(state, action);
		case EActionTypes.SUMMARY_GET_CLEAR:
			return getSummaryClear(state, action);
		case EActionTypes.SUMMARIES_GET_START:
			return getSummariesStart(state, action);
		case EActionTypes.SUMMARIES_GET_SUCCESS:
			return getSummariesSuccess(state, action);
		case EActionTypes.SUMMARIES_GET_ERROR:
			return getSummariesError(state, action);
		case EActionTypes.SUMMARIES_GET_CLEAR:
			return getSummariesClear(state, action);
		case EActionTypes.SUMMARY_UPDATE_START:
			return getupdateSummaryStart(state, action)
		case EActionTypes.SUMMARY_UPDATE_SUCCESS:
			return getupdateSummarySuccess(state, action)
		case EActionTypes.SUMMARY_UPDATE_ERROR:
			return getupdateSummaryFail(state, action)
		case EActionTypes.SUMMARY_UPDATE_CLEAR:
			return getupdateSummaryClear(state, action)
		case EActionTypes.SUMMARY_DELETE_START:
			return getdeleteSummaryStart(state, action)
		case EActionTypes.SUMMARY_DELETE_SUCCESS:
			return getdeleteSummarySuccess(state, action)
		case EActionTypes.SUMMARY_DELETE_FAIL:
			return getdeleteSummaryFail(state, action)
		default:
			return state;
	}
};

export default reducer;