import React, { ReactNode, CSSProperties } from "react";
import classes from "./InputGroupOrder.module.scss";

interface IProps {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
}

const InputGroupOrder: React.FC<IProps> = ({ children, style, className }) => {
  const classNames = [classes.Container];
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(" ")} style={style}>
      {children}
    </div>
  );
};

export default InputGroupOrder;
