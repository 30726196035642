import React, { ReactNode } from 'react';

import classes from './HeadingBox.module.scss';

interface IProps {
  children: ReactNode;
  tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  className?: string;
}

const HeadingBox: React.FC<IProps> = ({ children, tag: Tag = "h1", className }) => {
  const classNames = [classes.Container];
  if(className) {
    classNames.push(className);
  }
  return <div className={classNames.join(' ')}><Tag>{children}</Tag></div>;
};

export default HeadingBox;
