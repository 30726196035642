import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateInput } from '../../hooks/useCreateInput';
import { searchOrganizations } from '../../services/searchServices';
import { searchInterpreters } from '../../services/userServices';
import { clearParameter, setParameter } from '../../services/useSearchInputCache';
import { ESearchStorageKey } from '../../shared/log-items-data';
import { ETranslation } from '../../translations/translation-keys';
import Button, { EButtonColor } from '../ui/Button/Button';
import { EInputType, IInputField } from '../ui/Input/Input';
import { getInputData } from '../ui/Input/input-utils';
import InputGroup from '../ui/InputGroup/InputGroup';

interface IProps {
	loading: boolean;
	searchHandler: (search: IFeeSearchParams) => void;
}

enum EInputs {
	endDate = "endDate",
	startDate = "startDate",
	user = "user",
	organization = "organization",
}

export interface IFeeSearchParams {
	endDate?: string;
	startDate?: string;
	user?: string;
	organization?: string;
	paginationCursor?: string;
	page: number;
}

const FeeSearchInputs: React.FC<IProps> = ({ loading, searchHandler }) => {

	const { t } = useTranslation()

	useEffect(() => {
		if (inputs) {
			const inputFieldNames = Object.keys(inputs);

			const updatedInputs = { ...inputs };

			inputFieldNames.forEach((fieldName) => {
				const savedItem = localStorage.getItem(ESearchStorageKey.FEES_SEARCH);
				if (savedItem) {
					var parsedObject = JSON.parse(savedItem);
					var fieldObject = parsedObject[fieldName];
					const newValue = fieldObject.value;
					updatedInputs[fieldName] = {
						...updatedInputs[fieldName],
						value: newValue,
					};
				} else {

				}
			});
			setInputs(updatedInputs);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.endDate]: {
			type: EInputType.datepicker,
			dateAsString: true,
			labelTranslation: ETranslation.COMMON_END_DATE,
			value: null,
		},
		[EInputs.startDate]: {
			type: EInputType.datepicker,
			dateAsString: true,
			labelTranslation: ETranslation.COMMON_START_DATE,
			value: null,
		},
		[EInputs.user]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_USER,
			value: null,
		},
		[EInputs.organization]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_ORGANIZATION,
			value: null,
		},
	});


	const createInput = useCreateInput(inputs, setInputs);

	const searchCb = () => {
		const data = getInputData<IFeeSearchParams>(inputs);
		searchHandler(data);
		setParameter(ESearchStorageKey.FEES_SEARCH, inputs)
	}

	const clearInputs = () => {

		clearParameter(ESearchStorageKey.FEES_SEARCH)
	}

	return (
		<InputGroup>
			{createInput(EInputs.startDate)}
			{createInput(EInputs.endDate)}
			{createInput(EInputs.user, {
				fetchOptions: searchInterpreters
			})}
			{createInput(EInputs.organization, {
				fetchOptions: searchOrganizations
			})}
			<Button disabled={loading} onClick={searchCb}>{t(ETranslation.COMMON_SEARCH)}</Button>
			<Button color={EButtonColor.DEFAULT} onClick={clearInputs}>{t(ETranslation.UI_CLEAR)}</Button>
		</InputGroup>
	)
}

export default FeeSearchInputs;