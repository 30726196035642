import React from "react";
import Alert from "../../ui/Alert/Alert";
import Spinner from "../../ui/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";
import Table from "../../ui/Table/Table";

import { useSelector } from "react-redux";
import IAppState from "../../../interfaces/store/IAppState";
import IReservedDateState from "../../../interfaces/store/IReservedDateState";
import ReservedDateUserListItem from "./ReservedDateUserListItem/ReservedDateUserListItem";

interface IProps {
}

const ReservedDateUserList: React.FC<IProps> = () => {
	const {
		reservedDates,
		reservedDatesError,
		reservedDatesLoading,
	} = useSelector<IAppState, IReservedDateState>(
		(state) => state.reservedDate
	);

	const { t } = useTranslation();

	if (reservedDatesError) {
		return <Alert>{reservedDatesError}</Alert>;
	}

	if (!reservedDates || reservedDatesLoading) {
		return <Spinner />;
	}

	if (reservedDates.length === 0) {
		return <p>{t(ETranslation.RESERVED_DATE_NO_RESULTS)}</p>;
	}

	return (
		<>
			<Table>
				<thead>
					<tr>
						<th>{t(ETranslation.RESERVED_DATE_TYPE)}</th>
						<th>{t(ETranslation.RESERVED_DATE_DATE)}</th>
						<th>{t(ETranslation.RESERVED_DATE_OPTIONS)}</th>
						<th>{t(ETranslation.RESERVED_DATE_DESCRIPTION)}</th>
					</tr>
				</thead>
				<tbody>
					{reservedDates
						.map((reservedDate) => (
							<ReservedDateUserListItem
								key={reservedDate.id}
								reservedDate={reservedDate}
							/>
						))}
				</tbody>
			</Table>
		</>
	);
};

export default ReservedDateUserList;
