import EActionTypes from '../../interfaces/store/EActionTypes';
import ISendGridEmailAction from '../../interfaces/store/ISendGridEmailAction';
import ISendGridEmailState from '../../interfaces/store/ISendGridEmailState';
import { ETranslation } from '../../translations/translation-keys';

type ReducerAction = ISendGridEmailAction;
type ReducerState = ISendGridEmailState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {

  sendgridemail: null,
  sendgridemailLoading: false,
  sendgridemailError: null,

  sendgridemails: null,
  sendgridemailsLoading: false,
  sendgridemailsError: null,

};

const findSendGridEmailsStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, sendgridemailsError: null, sendgridemailsLoading: true };
};

const findSendGridEmailsSuccess : ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    sendgridemails: action.sendGridEmails || null,
    sendgridemailsError: null,
    sendgridemailsLoading: false,
  };
};

const findSendGridEmailsFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, sendgridemails: null, sendgridemailsLoading: false, sendgridemailsError: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};




const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.SENDGRIDEMAILS_FIND_START:
      return findSendGridEmailsStart(state, action);
    case EActionTypes.SENDGRIDEMAILS_FIND_SUCCESS:
      return findSendGridEmailsSuccess (state, action);
    case EActionTypes.SENDGRIDEMAILS_FIND_FAIL:
      return findSendGridEmailsFail(state, action);
    case EActionTypes.SITE_GET_START:
    default:
      return state;
  }
};

export default reducer;
