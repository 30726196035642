import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute } from '../../classes/Routes';
import CategoryEdit from '../../components/Categories/CategoryEdit/CategoryEdit';
import Heading from '../../components/Heading/Heading';
import Alert from '../../components/ui/Alert/Alert';
import Container from '../../components/ui/Container/Container';
import EditButtons from '../../components/ui/EditButtons/EditButtons';
import Spinner from '../../components/ui/Spinner/Spinner';
import { ICategory } from '../../interfaces/ICategory';
import IAppState from '../../interfaces/store/IAppState';
import ICategoryState from '../../interfaces/store/ICategoryState';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';

interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const CategoryEditPage: React.FC<IProps> = ({ history, match }) => {
	const [showValidation, setShowValidation] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [editCategory, setEditCategory] = useState<ICategory | null>(null);

	const dispatch = useDispatch();

	const { id } = match.params;
	const isAdd = id === "add";

	const { t } = useTranslation();

	useEffect(() => {
		if (!isAdd) {
			dispatch(actions.getCategory(id));
		}
	}, [isAdd, id, dispatch]);

	const { error, loading, saveOrUpdateOk, category, deleting, deletingError, deletingOk } = useSelector<
		IAppState,
		ICategoryState
	>((state) => state.category);

	useEffect(() => {
		if (saveOrUpdateOk) {
			history.push(ERoute.CATEGORIES_LIST);
		}
		return () => {
			dispatch(actions.saveOrUpdateCategoryClear());
			dispatch(actions.getCategoryClear());
		};
	}, [dispatch, saveOrUpdateOk, history]);

	const updateHandler = useCallback((category: ICategory, isValid: boolean) => {
		setEditCategory(category);
		setIsValid(isValid);
	}, []);

	const submitHandler = () => {
		if (editCategory) {
			if (isAdd) {
				dispatch(actions.saveCategory(editCategory));
			} else {
				editCategory.id = id;
				dispatch(actions.updateCategory(editCategory));
			}
		}
	};

	const deleteHandler = () => {
		if (category) {
			dispatch(actions.deleteCategory(category.id));
		}
	};

  return (
		<Container>
			{error && <Alert>{error}</Alert>}
			<Heading>{t(ETranslation.CATEGORY_EDIT_CATEGORY)}</Heading>
			{loading ? (
				<Spinner />
			) : (
				<>
					<CategoryEdit
						onChange={updateHandler}
						category={category}
						loading={loading}
						showValidation={showValidation}
					/>
					{error && <Alert>{error}</Alert>}
					<EditButtons
						onSave={isValid ? submitHandler : () => setShowValidation(true)}
						disabled={showValidation && !isValid}
						loading={loading}
						onCancel={() => history.goBack()}
						onDelete={deleteHandler}
						deleteText={t(ETranslation.CATEGORY_DELETE_CATEGORY)}
						onDeleteClose={() => dispatch(actions.deleteCategoryClear())}
						onDeleteDone={() => history.push(ERoute.CATEGORIES_LIST)}
						deleteError={deletingError}
						deleteLoading={deleting}
						deleteOk={deletingOk}
						isAdd={isAdd}
					/>
				</>
			)}
		</Container>
	);
};

export default CategoryEditPage;
