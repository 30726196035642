import React from 'react';
import { useTranslation } from 'react-i18next';
import { IEezyTrusterPayment } from '../../../interfaces/IEezyTruster';
import classes from "./EezyTrusterEvent.module.scss";
import Button from '../../ui/Button/Button';
import { EEezyTrusterPaymentStatus } from '../../../shared/eezy-truster-data';
import { ETranslation } from '../../../translations/translation-keys';
import { useSelector } from 'react-redux';
import IAppState from '../../../interfaces/store/IAppState';

interface IProps {
	payment: IEezyTrusterPayment;
	addManagementTotal: () => void;
	addCustomTotal: () => void;
}

const EezyTrusterInfoHeader: React.FC<IProps> = ({ payment, addManagementTotal, addCustomTotal }) => {
	const { t } = useTranslation()
	
	const { addManagementTotalLoading } = useSelector((state: IAppState) => ({
		addManagementTotalLoading: state.eezyTruster.managementPaymentLoading,
	}));

	return (
		<div className={classes.paymentHeader}>
			<h2>{t(ETranslation.EEZY_TRUSTER_INFORMATION)}</h2>
			{(payment.status !== EEezyTrusterPaymentStatus.SENDING && payment.status !== EEezyTrusterPaymentStatus.SENT) &&
				<>
					{!payment.managementTotal && <Button className={classes.sendButton} loading={addManagementTotalLoading} onClick={addManagementTotal}>Lisää hallintokulu</Button>}
					<Button className={classes.sendButton} onClick={addCustomTotal}>Lisää tuote</Button>
				</>
			}
		</div>
	);
};

export default EezyTrusterInfoHeader;
