import React from 'react';
import { ISort, TSortType } from './useTableSort';


const TableSortContext = React.createContext<{
  onSort: (key: string, type: TSortType, format?: string) => void;
  sort: ISort;
}>({
  onSort: () => {},
  sort: { key: null, reverse: false, type: "string" }
});

export default TableSortContext;