import { useSelector } from 'react-redux';

import { Client as ConversationsClient, ConnectionState, Conversation, JSONObject, Message } from "@twilio/conversations"
import { useState, useEffect, useCallback } from "react";
import IAppState from '../interfaces/store/IAppState';
import IAuthState from '../interfaces/store/IAuthState';
import { User } from '../classes/User';

export interface IChatParticipant {
  id: string;
  name: string;
  sid: string;
}

const useTwilioChat = () => {

  const { user } = useSelector<IAppState, IAuthState>(state => state.auth);
  
  const [client, setClient] = useState<ConversationsClient>();
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  

  const { participant, otherParticipants, participantsLoading } = useChatParticipants(conversation, user);
  const { messages, messagesLoading, messageAddedListener } = useMessages(conversation, participant);

  useEffect(() => {
    if (client) {
      return () => {
        client.shutdown();
      }
    }
  }, [client]);


  const initConversation = useCallback(async (token: string, conversationName: string) => {
    // const client = await ConversationsClient.
    const client = new ConversationsClient(token);
    client.addListener('connectionStateChanged', connectionStateListener);
    // client.addListener('tokenAboutToExpire', tokenAboutToExpireListener);
    setClient(client);
    
    const conversation = await client.getConversationByUniqueName(conversationName);  
    conversation.addListener('messageAdded', messageAddedListener)
    setConversation(conversation);
  }, [messageAddedListener])

  const connectionStateListener = (state: ConnectionState) => {
    setIsConnected(state === "connected");
    setIsConnecting(state === "connecting");
  }

  const sendMessage = async (message: string) => {
    if (conversation) {
      const unsentMessage = conversation.prepareMessage().setBody(message.trim()).build();
      await unsentMessage.send();
    }
  }



  return { 
    initConversation, isConnected, isConnecting, sendMessage, 
    messages, messagesLoading, 
    participant, otherParticipants, participantsLoading 
  };
}


const useMessages = (conversation: Conversation | null, participant: IChatParticipant | null) => {

  const [messages, setMessages] = useState<Message[]>([]);
  const [messagesLoading, setMessagesLoading] = useState(true);
  const [latestMessage, setLatestMessage] = useState<Message | null>(null);
  const [hasNewMessages, setHasNewMessages] = useState(false);

  useNewMessageTitleInterval(hasNewMessages, setHasNewMessages);

  useEffect(() => {
    if (conversation) {
      conversation.getMessages().then(paginator => {
        setMessages(paginator.items);
        setMessagesLoading(false);
      });
    }
  }, [conversation]);

  useEffect(() => {
    if (latestMessage && participant) {
      setHasNewMessages(participant?.sid !== latestMessage.participantSid);
    }
  }, [latestMessage, participant]);

  const messageAddedListener = useCallback((message: Message) => {
    setMessages(messages => [...messages, message]);
    setLatestMessage(message);
  }, []);



  return { messages, messagesLoading, messageAddedListener };
}

const useNewMessageTitleInterval = (hasNewMessages: boolean, setHasNewMessages: React.Dispatch<React.SetStateAction<boolean>>) => {
  useEffect(() => {
    if (hasNewMessages) {
      if (document.hidden){
        const previousTitle = document.title;
        const newMessageTitle = "Uusi viesti";
        const interval = setInterval(() => {
          document.title = document.title === previousTitle ? newMessageTitle : previousTitle;
        }, 1000);
  
        window.addEventListener('focus', () => {
          clearInterval(interval);
          document.title = previousTitle;
          setHasNewMessages(false);
        }); 
  
        return () => {
          clearInterval(interval);
        }
      } else {
        setHasNewMessages(false)
      }
    }
  }, [hasNewMessages, setHasNewMessages]);
}


const useChatParticipants = (conversation: Conversation | null, authUser: User | null) => {

  const [participant, setParticipant] = useState<IChatParticipant | null>(null);
  const [otherParticipants, setOtherParticipants] = useState<IChatParticipant[] | null>(null);
  const [participantsLoading, setParticipantsLoading] = useState(true);

  useEffect(() => {
    if (conversation && authUser) {
      conversation.getParticipants().then(participants => {
        const otherParticipants: IChatParticipant[] = [];
        participants.forEach(p => {
          const attributes = p.attributes as JSONObject;
          const chatParticipant: IChatParticipant = {
            id: attributes.id as string,
            name: attributes.name as string,
            sid: p.sid
          }
          if (authUser.id === chatParticipant.id) {
            setParticipant(chatParticipant);
          } else {
            otherParticipants.push(chatParticipant);
          }
        });
        setOtherParticipants(otherParticipants);
        setParticipantsLoading(false);
      });
    }
  }, [conversation, authUser]);


  return { participant, otherParticipants, participantsLoading };

}


export default useTwilioChat