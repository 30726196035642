import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { ERoute, Routes } from "../../../classes/Routes";
import Container from "../../../components/ui/Container/Container";
import IAppState from "../../../interfaces/store/IAppState";
import * as actions from "../../../store/actions";
import LogItemList from "../../../components/LogItem/LogItemList/LogItemList";
import Heading from "../../../components/Heading/Heading";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";
import LogItemsSearch from "../../../components/LogItem/LogItemsSearch/LogItemsSearch";
import { User } from "../../../classes/User";

interface IProps extends RouteComponentProps {}

const LogItemsListPage: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { logItems, loading, error, user } = useSelector((state: IAppState) => ({
    logItems: state.logItem.logItems,
    loading: state.logItem.logItemsLoading,
    error: state.logItem.logItemsError,
    user: state.auth.user
  }));

  const openHandler = (id?: string) => {
    if (!id) return;
    history.push(Routes.withParams(ERoute.LOG_ITEM_EDIT, { id }));
  };

  const searchHandler = useCallback(
    (search) => {
      dispatch(actions.findLogItems(search));
    },
    [dispatch]
  );

  return (
    <Container>
      <Heading>{t(ETranslation.MENU_LOG_ITEMS)}</Heading>
      <LogItemsSearch onSearch={searchHandler} />
      <LogItemList
        logItems={logItems}
        loading={loading}
        error={error}
        onClick={openHandler}
        user={user as User}
      />
    </Container>
  );
};

export default LogItemsListPage;
