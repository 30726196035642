import { TFunction } from "i18next";
import { IOption } from "../components/ui/Input/Input";
import { ETranslation } from "../translations/translation-keys";

export enum EPaymentStatus {
	paid = "paid",
	unpaid = "unpaid",
	no_payment_required = "no_payment_required",
}

const PAYMENT_STATUS: IOption[] = [
	{
		value: EPaymentStatus.paid,
		labelTranslation: ETranslation.PAYMENT_STATUS_PAID,
	},
	{
		value: EPaymentStatus.unpaid,
		labelTranslation: ETranslation.PAYMENT_STATUS_UNPAID,
	},
	{
		value: EPaymentStatus.no_payment_required,
		labelTranslation: ETranslation.PAYMENT_STATUS_NO_PAYMENT_REQUIRED,
	},
];

export const getPaymentStatusString = (
	t: TFunction,
	status: EPaymentStatus
): string =>
	t(
		PAYMENT_STATUS.find((item) => item.value === status)
			?.labelTranslation || ""
	);
