import React from "react";
import { useState } from "react";
import { useCreateInput } from "../../../../hooks/useCreateInput";
import { ETranslation } from "../../../../translations/translation-keys";
import Button from "../../../ui/Button/Button";
import { IInputField, EInputType } from "../../../ui/Input/Input";
import { getInputData } from "../../../ui/Input/input-utils";
import { ICustomTotalAdd } from "../EezyTrusterInfoPaymentRows";

interface ICustomTotalProps {
	saveCustomTotal: (customTotal: ICustomTotalAdd) => void;
	cancelNewCustomTotal: () => void;
	customTotalLoading: boolean;
}

enum EInputs {
	productName = "productName",
	quantity = "quantity",
	quantityUnitPrice = "quantityUnitPrice",
	fromServer = "fromServer",
};

export const AddCustomPayment: React.FC<ICustomTotalProps> = ({ saveCustomTotal, cancelNewCustomTotal, customTotalLoading }) => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.productName]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_PRODUCT_NAME,
			value: "",
			disabled: customTotalLoading,
			placeholder: "Tuotteen nimi"
		},
		[EInputs.quantity]: {
			type: EInputType.number,
			labelTranslation: ETranslation.COMMON_PAYMENT_AMMOUNT,
			placeholder: "Määrä (kpl)",
			disabled: customTotalLoading,
			value: "",
		},
		[EInputs.quantityUnitPrice]: {
			type: EInputType.number,
			placeholder: "Yksikkö hinta (€/kpl)",
			labelTranslation: ETranslation.COMMON_PRICE_OF_ONE,
			disabled: customTotalLoading,
			value: "",
		},
		[EInputs.fromServer]: {
			type: EInputType.boolean,
			placeholder: "",
			value: false,
		},
	});

	const saveNewCustomTotal = () => {
		const data = getInputData<ICustomTotalAdd>(inputs);
		saveCustomTotal(data);
	}
	const createInput = useCreateInput(inputs, setInputs);

	return <tr >
		<td>
			{createInput(EInputs.productName)}
		</td>
		<td></td>
		<td>
			{createInput(EInputs.quantity)}
		</td>
		<td >
			{createInput(EInputs.quantityUnitPrice)}
		</td>
		<td></td>
		<td></td>
		<td></td>
		<td>
			{
				<>
					<Button loading={customTotalLoading} onClick={saveNewCustomTotal} >Tallenna</Button>
					<Button loading={customTotalLoading} onClick={cancelNewCustomTotal}>Peruuta</Button>
				</>
			}
		</td>
		<td></td>
	</tr>
}