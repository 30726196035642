import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERoute } from '../../../classes/Routes';
import Heading from '../../../components/Heading/Heading';
import LanguageCode from '../../../components/LanguageCode/LanguageCode';
import Alert from '../../../components/ui/Alert/Alert';
import Container from '../../../components/ui/Container/Container';
import EditButtons from '../../../components/ui/EditButtons/EditButtons';
import Spinner from '../../../components/ui/Spinner/Spinner';
import { ILanguageCode } from '../../../interfaces/ILanguageCode';
import IAppState from '../../../interfaces/store/IAppState';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';

interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const LanguageCodeEditPage: React.FC<IProps> = ({ match, history }) => {
	const [editLanguageCode, setEditLanguageCode] =
		useState<ILanguageCode | null>(null);
	const [isValid, setIsValid] = useState(false);
	const [showValidation, setShowValidation] = useState(false);

	const dispatch = useDispatch();

	const { id } = match.params;
	const isAdd = id === "add";

	const { t } = useTranslation();

	useEffect(() => {
		if (!isAdd) {
			dispatch(actions.getLanguageCode(id));
		}
	}, [isAdd, id, dispatch]);

	const {
		error,
		loading,
		saveOrUpdateOk,
		languageCode,
		deleteError,
		deleteLoading,
		deleteOk,
	} = useSelector((state: IAppState) => ({
		error:
			state.language.languageCodeError ||
			state.language.languageCodeSaveOrUpdateError,
		loading:
			state.language.languageCodeLoading ||
			state.language.languageCodeSaveOrUpdateLoading,
		saveOrUpdateOk: state.language.languageCodeSaveOrUpdateOk,
		languageCode: state.language.languageCode,
		deleteLoading: state.language.languageCodeDeleteLoading,
		deleteError: state.language.languageCodeDeleteError,
		deleteOk: state.language.languageCodeDeleteOk,
	}));

	useEffect(() => {
		if (saveOrUpdateOk) {
			history.push(ERoute.LANGUAGE_CODES_LIST);
		}
		return () => {
			dispatch(actions.saveOrUpdateLanguageCodeClear());
			dispatch(actions.getLanguageCodeClear());
			dispatch(actions.deleteLanguageCodeClear());
		};
	}, [dispatch, saveOrUpdateOk, history]);

	const updateHandler = useCallback(
		(languageCode: ILanguageCode, isValid: boolean) => {
			setEditLanguageCode(languageCode);
			setIsValid(isValid);
		},
		[]
	);

	const submitHandler = () => {
		if (!editLanguageCode) return;

		if (isAdd) {
			dispatch(actions.saveLanguageCode(editLanguageCode));
		} else {
			editLanguageCode.id = id;
			dispatch(actions.updateLanguageCode(editLanguageCode));
		}
	};

	const deleteHandler = () => {
		dispatch(actions.deleteLanguageCode(id));
	};

	return (
		<Container>
			{error && <Alert>{error}</Alert>}
			{loading && <Spinner centerAbsolute />}
			<Heading>{t(ETranslation.PAGES_LANGUAGE_CODE_EDIT_TITLE)}</Heading>
			{/*
				<Alert color={EAlertColor.INFO}>
					{t(ETranslation.PAGES_LANGUAGE_CODE_EDIT_INFO)}
				</Alert>
			*/}
			<LanguageCode
				onChange={updateHandler}
				loading={loading}
				languageCode={languageCode}
				showValidation={showValidation}
			/>
			<EditButtons
				onSave={isValid ? submitHandler : () => setShowValidation(true)}
				disabled={showValidation && !isValid}
				loading={loading}
				onCancel={() => history.goBack()}
				deleteText={t(
					ETranslation.PAGES_LANGUAGE_CODE_EDIT_DELETE_CONFIRM
				)}
				onDeleteClose={() =>
					dispatch(actions.deleteLanguageCodeClear())
				}
				onDeleteDone={() => history.push(ERoute.LANGUAGE_CODES_LIST)}
				deleteError={deleteError}
				deleteLoading={deleteLoading}
				deleteOk={deleteOk}
				isAdd={isAdd}
				onDelete={deleteHandler}
			/>
		</Container>
	);
};

export default LanguageCodeEditPage;
