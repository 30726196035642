import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";

import classes from "./Modal.module.scss";
import ModalContext, { defaultModal, EModalSize, IModal } from "./ModalContext";
import Backdrop from "../Backdrop/Backdrop";
import Spinner from "../Spinner/Spinner";

interface IProps {
  modal: IModal;
}

const Modal: React.FC<IProps> = ({ modal }) => {
  const { setModal } = useContext(ModalContext);

  const containerClassNames = [classes.Container];

  if (modal.isOpen) {
    containerClassNames.push(classes.ContainerOpen);
  }

  switch (modal.size) {
    case EModalSize.FULL:
      containerClassNames.push(classes.ContainerFull);
      break;
    case EModalSize.MEDIUM:
      containerClassNames.push(classes.ContainerMedium);
      break;
    case EModalSize.SMALL:
      containerClassNames.push(classes.ContainerSmall);
      break;
    case EModalSize.EXTRASMALL:
      containerClassNames.push(classes.ContainerExtraSmall);
      break;
    default:
  }

  const closeModal = () => {
    if (modal.disabled) return;
    setModal(defaultModal);
    if (modal.onModalClose) modal.onModalClose();
  };

  const contentClassNames = [classes.Content];
  if(modal.noScroll) {
    contentClassNames.push(classes.NoScroll);
  }
  return (
    <React.Fragment>
      <Backdrop onClick={closeModal} isOpen={modal.isOpen} />
      <div className={containerClassNames.join(" ")} onClick={closeModal}>
        <div
          className={contentClassNames.join(' ')}
          onClick={(event) => event.stopPropagation()}
        >
          <header className={classes.Header}>
            <h2 className={classes.Title}>{modal.title}</h2>
            {!modal.disabled && (
              <span className={classes.CloseModal} onClick={closeModal}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            )}
          </header>
          {modal.loading ? <Spinner /> : modal.content}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Modal;
