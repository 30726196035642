import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { ERoute, Routes } from "../../../classes/Routes";
import Button from "../../../components/ui/Button/Button";
import Container from "../../../components/ui/Container/Container";
import IAppState from "../../../interfaces/store/IAppState";
import * as actions from "../../../store/actions";
import PriceSeasonRuleList from "../../../components/PriceSeason/PriceSeasonRule/PriceSeasonRuleList/PriceSeasonRuleList";
import Heading from "../../../components/Heading/Heading";
import { ETranslation } from "../../../translations/translation-keys";
import { useTranslation } from "react-i18next";

interface IProps extends RouteComponentProps {}

const PriceSeasonRulesListPage: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { priceSeasonRules, loading, error } = useSelector(
    (state: IAppState) => ({
      priceSeasonRules: state.priceSeason.priceSeasonRules,
      loading: state.priceSeason.priceSeasonRulesLoading,
      error: state.priceSeason.priceSeasonRulesError,
    })
  );

  useEffect(() => {
    if (!priceSeasonRules) {
      dispatch(actions.findPriceSeasonRules());
    }
  }, [dispatch, priceSeasonRules]);

  const addHandler = () => {
    openHandler("add");
  };

  const openHandler = (id?: string) => {
    if (!id) return;
    history.push(Routes.withParams(ERoute.PRICE_SEASON_RULE_EDIT, { id }));
  };

  return (
    <Container>
      <Heading>{t(ETranslation.PAGES_PRICE_RULES_LIST_TITLE)}</Heading>
      <Button onClick={addHandler}>{t(ETranslation.PAGES_PRICE_RULES_LIST_ADD_NEW)}</Button>
      <PriceSeasonRuleList
        priceSeasonRules={priceSeasonRules}
        loading={loading}
        error={error}
        onClick={openHandler}
        showFilter
      />
    </Container>
  );
};

export default PriceSeasonRulesListPage;
