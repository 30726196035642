import { EUserRole, IUser } from "../classes/User";
import { EPriceSeasonRuleTermsOfCancel } from "../shared/price-season-data";
import { ITag } from "../store/actions/tagsActions";
import { IOptionable } from "../utils/option-utils";
import { IOrganizationGroup } from "./IOrganizationGroup";
import { ISalaryInfo } from "./ISalaryInfo";
import { ISite } from "./ISite";

export interface IOrganizationInputData {
	id: string;
	name: string;
	type?: EUserRole.CUSTOMER | EUserRole.INTERPRETER;
	organizationGroup: IOrganizationGroup;
	description?: string;
	businessId?: string;
	industryCode?: number;
	industryName?: string;
	www?: string;
	phoneNumber?: string;
	email?: string;
	bookingEmail?: string;
	tags?: ITag[];
	vatRegistered?: string;
	organizationDurations?: IOrganizationDuration[];
	defaultOrganizationDuration?: number;
	defaultInterpretationType?: string;
	customerApprovalRequired: boolean;
	costCenterMandatory: boolean;
	customerReferenceHidden: boolean;
	customerReferenceMandatory: boolean;
	customerReferenceMandatoryInReview: boolean;
	customerReference2Mandatory: boolean;
	customerReference2MandatoryInReview: boolean;
	onlySecurityCleared: boolean;
	allowAttachments: boolean;
	allowAutoReminders: boolean;
	showLanguageAvailability: boolean;
	allowInterpreterCallToCustomer?: boolean;
	allowCustomerCallToInterpreter?: boolean;
	allowContactCallToInterpreter?: boolean;
	allowInterpreterCallToContact?: boolean;
	allowVideoConference?: boolean;
	allowMaskedNumber?: boolean;
	allowConnectionTypeSelectForInstant?: boolean;
	streetName?: string;
	zip?: string;
	city?: string;
	country?: string;
	priceWithoutVatPerMinuteForInstant?: string;
	minBillingPeriodInMinutesForInstant?: string;
	priceWithoutVatPerMinuteForInstantExtended?: string;
	priceWithoutVatPerMinuteForBooking?: string;
	minBillingPeriodInMinutesForBooking?: string;
	priceWithoutVatPerMinuteForBookingExtended?: string;
	priceWithoutVatPerMinuteForOnSite?: string;
	minBillingPeriodInMinutesForOnSite?: string;
	priceWithoutVatPerMinuteForOnSiteExtended?: string;
	termsOfCancelTypeBooking?: string;
	termsOfCancellationTypeOnSite?: EPriceSeasonRuleTermsOfCancel;
	priceVatP?: string;
	customerReferenceTitle: string;
	customerReferenceInfoText: string;
	customerReference2Title: string;
	customerReference2InfoText: string;
	customerNotesTitle: string;
	customerNotesInfoText: string;
	customerNotesMessageTitle: string;
	customerNotesMessageInfoText?: string;
	billingChargeWithoutVat: string;
	reference: string;
	billingReference: string;
	billingPaymentTerm: string
	interpreterFeeWithoutVatPerMinuteForInstant: string
	interpreterFeeWithoutVatPerMinuteForBooking: string
	interpreterFeeWithoutVatPerMinuteForOnSite: string
	interpreterFeeVatP: string
	blockedUsers?: IUser[]
	costCenter: ICostCenter;
	organizationCostCenters: ICostCenter[];
	netvisorInvoicingCustomerIdentifier: string
	eInvoiceAddress: string
	eInvoiceOperatorId: string;
}


export interface IOrganization extends IOptionable {
	id: string;
	name: string; //
	number?: number;
	type?: EUserRole.CUSTOMER | EUserRole.INTERPRETER;
	organizationGroup?: IOrganizationGroup; //
	description?: string;
	businessId?: string; //
	industryCode?: number;
	industryName?: string;
	www?: string;
	phoneNumber?: string; //
	email?: string; //
	bookingEmail?: string; //
	tags?: ITag[];
	site?: ISite;
	vatRegistered?: string;
	organizationDurations?: IOrganizationDuration[]; //
	defaultOrganizationDuration?: number; //
	defaultInterpretationType?: string; //
	customerApprovalRequired: boolean;
	costCenterMandatory: boolean; //
	customerReferenceHidden: boolean; //
	customerReferenceMandatory: boolean; //
	customerReferenceMandatoryInReview: boolean; //
	customerReference2Mandatory: boolean; //
	customerReference2MandatoryInReview: boolean; //
	onlySecurityCleared: boolean;
	allowAttachments: boolean;
	allowAutoReminders: boolean;
	showLanguageAvailability: boolean;
	allowInterpreterCallToCustomer?: boolean; //
	allowCustomerCallToInterpreter?: boolean; //
	allowContactCallToInterpreter?: boolean; //
	allowInterpreterCallToContact?: boolean; //
	allowVideoConference?: boolean; //
	allowMaskedNumber?: boolean; //
	allowConnectionTypeSelectForInstant?: boolean; //
	streetName?: string; //
	zip?: string; //
	city?: string; //
	country?: string;
	priceWithoutVatPerMinuteForInstant?: string;
	minBillingPeriodInMinutesForInstant?: string;
	priceWithoutVatPerMinuteForInstantExtended?: string;
	priceWithoutVatPerMinuteForBooking?: string;
	minBillingPeriodInMinutesForBooking?: string;
	priceWithoutVatPerMinuteForBookingExtended?: string;
	priceWithoutVatPerMinuteForOnSite?: string;
	minBillingPeriodInMinutesForOnSite?: string;
	priceWithoutVatPerMinuteForOnSiteExtended?: string;
	termsOfCancelTypeBooking?: string;
	priceVatP?: string;
	customerReferenceTitle: string;  //
	customerReferenceInfoText: string; //
	customerReference2Title: string; //
	customerReference2InfoText: string; //
	customerNotesTitle: string; //
	customerNotesInfoText: string; //
	customerNotesMessageTitle: string; //
	customerNotesMessageInfoText?: string; //
	billingChargeWithoutVat: string;
	reference: string;
	billingReference: string;
	billingPaymentTerm: string
	interpreterFeeWithoutVatPerMinuteForInstant: string
	interpreterFeeWithoutVatPerMinuteForBooking: string
	interpreterFeeWithoutVatPerMinuteForOnSite: string
	interpreterFeeVatP: string
	blockedUsers?: IUser[]
	costCenters: ICostCenter[]; //
	netvisorInvoicingCustomerIdentifier: string //
	eInvoiceAddress: string //
	eInvoiceOperatorId: string //
	salaryInfos: ISalaryInfo[];
}

export const EmptyOrganization: IOrganization = {
	id: "add",
	name: "",
	billingPaymentTerm: "DAYS_14",
	type: EUserRole.CUSTOMER,
	customerApprovalRequired: false,
	costCenterMandatory: false,
	salaryInfos: [],
	customerReferenceHidden: false,
	customerReferenceMandatory: false,
	customerReferenceMandatoryInReview: false,
	customerReference2Mandatory: false,
	customerReference2MandatoryInReview: false,
	onlySecurityCleared: false,
	allowAttachments: false,
	allowAutoReminders: false,
	showLanguageAvailability: false,
	customerReferenceTitle: "",
	customerReferenceInfoText: "",
	customerReference2Title: "",
	customerReference2InfoText: "",
	customerNotesTitle: "",
	customerNotesInfoText: "",
	customerNotesMessageTitle: "",
	billingChargeWithoutVat: "",
	reference: "",
	billingReference: "",
	interpreterFeeWithoutVatPerMinuteForInstant: "",
	interpreterFeeWithoutVatPerMinuteForBooking: "",
	interpreterFeeWithoutVatPerMinuteForOnSite: "",
	interpreterFeeVatP: "",
	costCenters: [],
	netvisorInvoicingCustomerIdentifier: "",
	eInvoiceAddress: "",
	eInvoiceOperatorId: "",
}

export interface IOrganizationDuration {
	id: string;
	titleFi: string;
	titleEn: string;
	durationInMinutes: number;
}

export interface ICostCenter extends IOptionable {
	id: string
	created?: Date
	updated?: Date
	name: string
	descriptionText: string
	organizationForBilling?: IOrganization;
}