import React from 'react';
import { IEezyTrusterEvent, IEezyTrusterPayment } from '../../../interfaces/IEezyTruster';
import classes from "./EezyTrusterEvent.module.scss";
import Button, { EButtonColor } from '../../ui/Button/Button';
import { EEezyTrusterPaymentStatus } from '../../../shared/eezy-truster-data';
import { useDispatch, useSelector } from 'react-redux';
import { removeEezyTrusterPayment, sendEezyTrusterPayment } from '../../../store/actions/eezyTrusterActions';
import IAppState from '../../../interfaces/store/IAppState';
import EezyTrusterEventStatus from '../EezyStatusBadge/EezyTrusterEventStatus';

interface IProps {
	payment: IEezyTrusterPayment;
	index: number;
	type: IEezyTrusterEvent["type"];
}

const EezyTrusterPaymentHeader: React.FC<IProps> = ({ payment, index, type }) => {
	const dispatch = useDispatch();

	const { removePaymentLoading, sendPaymenLoading } = useSelector((state: IAppState) => ({
		removePaymentLoading: state.eezyTruster.removePaymentLoading,
		sendPaymenLoading: state.eezyTruster.sendPaymentLoading,
	}));

	const getSalesOrder = () => {
		// TODO
		// 14.5 seems obsolete, needs more checking. Leave this here as a reminder, delete later.
	}

	const sendEezyPayment = () => {
		dispatch(sendEezyTrusterPayment(payment.id));
	}

	const deleteEezyPayment = () => {
		dispatch(removeEezyTrusterPayment(payment.id, index));
	}

	return (
		<div className={classes.paymentHeader}>
			<h2><a href={`/users/edit/${payment.user.id}`} target="_blank" rel="noopener noreferrer">{payment.user.firstName} {payment.user.lastName}</a></h2>
			<span><EezyTrusterEventStatus status={payment.status} /></span>
			<span>{payment.eezySalesOrderId}</span>
			{(payment.status === EEezyTrusterPaymentStatus.SENT && type === "EEZY") &&
				<Button
					color={EButtonColor.SUCCESS}
					onClick={getSalesOrder}>
					Tarkista
				</Button>
			}
			{(payment.status !== EEezyTrusterPaymentStatus.SENT && payment.status !== EEezyTrusterPaymentStatus.SENDING) &&
				<>
					<Button
						color={EButtonColor.SUCCESS}
						onClick={sendEezyPayment}
						loading={sendPaymenLoading}>
						Lähetä
					</Button>
					<Button
						color={EButtonColor.DANGER}
						onClick={deleteEezyPayment}
						loading={removePaymentLoading}>
						Poista
					</Button>
				</>
			}
		</div>
	);
};

export default EezyTrusterPaymentHeader;
