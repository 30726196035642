import EActionTypes from "../../interfaces/store/EActionTypes";
import ITagState from "../../interfaces/store/ITagState";
import ITagAction from "../../interfaces/store/ITagAction";


type ReducerState = ITagState;
type ReducerSignature = (state: ReducerState, action: ITagAction) => ReducerState;

const initialState: ReducerState = {
	tag: null,
	loading: false,
	error: null,
	tags: null,
	tagsLoading: false,
	tagLoading: false,
	tagSaveError: null,
	tagSaveLoading: false,
	tagSaveSuccess: false,
};

const getTagStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: true, tag: null };
};

const saveTagStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, tagSaveLoading: true };
};

const getTagSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		tag: action.tag || null,
		error: null,
		loading: false,
	};
};

const saveTagSuccessfull: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		tagSaveLoading:false,
		tagSaveError: null,
		tagSaveSuccess: true,
		error: null,
		loading: false,
	};
};

const getTagFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, tag: null, loading: false, error: action.error ?? null };
};

const getTagClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, tag: null, loading: false, error: null };
}

const saveTagError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, loading: false, tagSaveError: action.error ?? null, tagSaveSuccess: false, tagSaveLoading: false };
}

const saveTagClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, loading: false, tagSaveError: null, tagSaveSuccess: false, tagSaveLoading: false };
}

const getTagsStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: true, tags: null };
};

const getTagsSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		tags: action.tags || null,
		error: null,
		loading: false,
	};
};

const getTagsFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, tags: null, loading: false, error: action.error ?? null };
};

const getTagsClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, tags: null, loading: false, error: null };
}

const reducer = (state: ReducerState = initialState, action: ITagAction): ReducerState => {
	switch (action.type) {
		case EActionTypes.TAGS_GET_START:
			return getTagsStart(state, action);
		case EActionTypes.TAGS_GET_SUCCESS:
			return getTagsSuccess(state, action);
		case EActionTypes.TAGS_GET_ERROR:
			return getTagsFail(state, action);
		case EActionTypes.TAGS_GET_CLEAR:
			return getTagsClear(state, action);
		case EActionTypes.TAG_GET_START:
			return getTagStart(state, action);
		case EActionTypes.TAG_GET_SUCCESS:
			return getTagSuccess(state, action);
		case EActionTypes.TAG_GET_ERROR:
			return getTagFail(state, action);
		case EActionTypes.TAG_GET_CLEAR:
			return getTagClear(state, action);
		case EActionTypes.TAG_SAVE_START:
			return saveTagStart(state, action);
		case EActionTypes.TAG_SAVE_SUCCESS:
			return saveTagSuccessfull(state, action);
		case EActionTypes.TAG_SAVE_ERROR:
			return saveTagError(state, action);
		case EActionTypes.TAG_SAVE_CLEAR:
			return saveTagClear(state, action);
		default:
			return state;
	}
};
/*

*/

export default reducer;
