import React, { useState } from "react";

import {
  IPriceSeasonRule,
  IPriceSeasonRuleSettings,
} from "../../../../interfaces/IPriceSeasonRule";
import Alert from "../../../ui/Alert/Alert";
import Spinner from "../../../ui/Spinner/Spinner";
import Table from "../../../ui/Table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import Input, { EInputType, TInputValue } from "../../../ui/Input/Input";
import { PRICE_SEASON_RULE_TAGS, PRICE_SEASON_RULE_DAYS, getPriceSeasonRuleTypeString } from "../../../../shared/price-season-data";
import ActiveBadge from "../../../ui/ActiveBadge/ActiveBadge";
import { useSelector } from "react-redux";
import IAppState from "../../../../interfaces/store/IAppState";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../../translations/translation-keys";

interface IProps {
  priceSeasonRules: IPriceSeasonRule[] | null;
  loading: boolean;
  error: string | null;
  onClick?: (id: string, index: number) => void;
  onDelete?: (id: string, index: number) => void;
  showFilter?: boolean;
  showDetails?: boolean;
  movePriceSeasonRule?: (id: string, index: number, way: string) => void;
}

const PriceSeasonRuleList: React.FC<IProps> = ({
  priceSeasonRules,
  loading,
  error,
  onClick,
  onDelete,
  showFilter,
  showDetails,
  movePriceSeasonRule
}) => {
  const [filter, setFilter] = useState("");
  const { t } = useTranslation();
  const { languages } = useSelector((state: IAppState) => ({
    languages: state.common.languages
  }))

  if (error) {
    return <Alert>{error}</Alert>;
  }

  if (!priceSeasonRules || loading) {
    return <Spinner />;
  }

  if (priceSeasonRules.length === 0) {
    return <p>{t(ETranslation.PRICE_SEASON_NO_PRICE_RULES)}</p>;
  }

  const deleteHandler = (
    event: React.MouseEvent<HTMLSpanElement>,
    id: string,
    index: number
  ) => {
    event.stopPropagation();
    onDelete && onDelete(id, index);
  };

  const movementHandler = (
	  event: React.MouseEvent<HTMLSpanElement>,
    id: string,
    index: number,
	  way: string
  ) => {
    event.stopPropagation();
    movePriceSeasonRule && movePriceSeasonRule(id, index, way)
  }

  const filterPriceSeasonRule = (item: IPriceSeasonRule): boolean => {
    return (
      filter.length === 0 ||
      item.name?.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  };

  const renderSettings = (settings?: IPriceSeasonRuleSettings) => {
    if (!settings) return;
    const settingsArray = [];
    settingsArray.push(`${settings.charge || 0} € / %`);
    settingsArray.push(`${settings.minBillingPeriodInMinutes || "-"} min`);
    settingsArray.push(`${settings.minRoundingPeriodInMinutes || "-"} min`);
    settingsArray.push(`${settings.durationMinInMinutes || "-"} min`);
    settingsArray.push(`${settings.durationMaxInMinutes || "-"} min`);
    settingsArray.push(`${settings.minutesBeforeStart || "-"} min`);

    return settingsArray.join(" | ");
  };

  const renderLanguages = (itemLanguages?: string[]) => {
    if(!itemLanguages || itemLanguages.length === 0) return null;
    if(!languages) return null;
    return itemLanguages.map(itemLanguage => languages?.find(language => language.id === itemLanguage)?.nameFi).join(', ');
  }

  return (
    <>
      {showFilter && (
        <Input
          containerStyles={{ margin: "1rem 0" }}
          type={EInputType.text}
          inputName="filter"
          value={filter}
          onChange={(value: TInputValue) => setFilter(value as string)}
          placeholderTranslation={ETranslation.COMMON_FILTER_BY_NAME}
        />
      )}

      <Table hover={onClick ? true : false}>
        <thead>
          <tr>
            <th>{t(ETranslation.COMMON_NAME)}</th>
            <th>{t(ETranslation.COMMON_TYPE)}</th>
            {showDetails && (
              <>
                <th>{t(ETranslation.PRICE_SEASON_PRICES)}</th>
                <th>{t(ETranslation.COMMON_FROM_LANGUAGE)}</th>
                <th>{t(ETranslation.COMMON_TO_LANGUAGE)}</th>
                <th>{t(ETranslation.PRICE_SEASON_TAGS_DAYRULES)}</th>
                <th>{t(ETranslation.COMMON_VAT)}</th>
              </>
            )}
            <th>{t(ETranslation.PRICE_SEASON_PERIOD)}</th>
            <th style={{ width: "1px" }}>{t(ETranslation.PRICE_SEASON_ACTIVE)}</th>
            {onDelete && <th style={{ width: "1px" }}></th>}
          </tr>
        </thead>
        <tbody>
          {priceSeasonRules
            .filter(filterPriceSeasonRule)
            .map((priceSeasonRule, index) => (
              <tr
                key={`${priceSeasonRule.id}-${index}`}
                onClick={
                  onClick ? () => onClick(priceSeasonRule.id, index) : undefined
                }
              >
                <td>{priceSeasonRule.name}</td>
                <td>
                  {getPriceSeasonRuleTypeString(t, priceSeasonRule.chargeType)}
                </td>
                {showDetails && (
                  <>
                    <td>
                      <div style={{ display: 'flex'}}>
                        <div>
                        {t(ETranslation.COMMON_INSTANT)} <br />
                        {t(ETranslation.PRICE_SEASON_PERIOD_2)} <br />
                        {t(ETranslation.COMMON_ATTENDANCE)}
                        </div>
                        <div>
                          {priceSeasonRule.settings.map((setting, i) => {
                            return (
                              <React.Fragment key={i}>
                                {i !== 0 && <br/> }
                                {renderSettings(setting)}
                              </React.Fragment>
                            )
                          })}
                        </div>
                      </div>
                    </td>
                    <td>
                      {renderLanguages(priceSeasonRule.fromLanguages)}
                    </td>
                    <td>
                      {renderLanguages(priceSeasonRule.toLanguages)}
                    </td>
                    <td>
                      {priceSeasonRule.tags?.map(priceSeasonRuleTag => PRICE_SEASON_RULE_TAGS.find(tag => tag.value === priceSeasonRuleTag)?.label).join(', ')}<br/>
                      {priceSeasonRule.days?.map(priceSeasonRuleDay => PRICE_SEASON_RULE_DAYS.find(day => day.value === priceSeasonRuleDay)?.label).join(', ')}
                    </td>
                    <td>
                      {priceSeasonRule.priceVatP || '0'}%
                    </td>
                  </>
                )}

                <td>
                  {priceSeasonRule.startTime}{" "}
                  {(priceSeasonRule.startTime || priceSeasonRule.endTime) &&
                    "-"}{" "}
                  {priceSeasonRule.endTime}
                </td>
                <td>
                  <ActiveBadge active={priceSeasonRule.active} />
                </td>
                {onDelete && (
                  <td>
                    <span
                      onClick={(event) =>
                        deleteHandler(event, priceSeasonRule.id, index)
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                  </td>
                )}
                {movePriceSeasonRule &&
                  <>
                    <td onClick={(event) => { if (index > 0) movementHandler(event, priceSeasonRule.id, index, "up"); }}>
                      <FontAwesomeIcon icon={faCaretUp} />
                    </td>
                    <td onClick={(event) => { if (index + 1 < priceSeasonRules.length) movementHandler(event, priceSeasonRule.id, index, "down"); }}>
                      <FontAwesomeIcon icon={faCaretDown} />
                    </td>
                  </>
                }

              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default PriceSeasonRuleList;
