import EActionTypes from "../../interfaces/store/EActionTypes";
import IFeeAction from "../../interfaces/store/IFeeAction";
import IFeeState from "../../interfaces/store/IFeeState";

type ReducerState = IFeeState;
type ReducerSignature = (state: ReducerState, action: IFeeAction) => ReducerState;


const initialState: ReducerState = {
	fee: null,
	fees: null,
	loading: false,
	error: null,
	feeLoading: false,
	feeSaveError: null,
	feeSaveLoading: false,
	feeSaveSuccess: false,
	paginationCursor: null,
	hasMoreFees: false,
	feeDeleteError: null,
	feeDeleteLoading: false,
	feeDeleteSuccess: false,
};

const feesGetStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: true };
};

const feesGetSuccess: ReducerSignature = (state, action): ReducerState => {
	const fees = state.fees ? action.fees ? [...state.fees, ...action.fees] : null : action.fees ? action.fees : null;
	return { ...state, error: null, loading: false, fees: fees, hasMoreFees: action.hasMoreFees ?? false, paginationCursor: action.paginationCursor ?? "" };
};

const feesGetClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: false, fees: null, paginationCursor: null };
};

const feeGetStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, feeLoading: true, };
} 

const feeGetSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, feeLoading: false, fee: action.fee ?? null };
}

const feeGetError:  ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: action.error ?? "Jokin meni pieleen", feeLoading: false, fee: null };
}

const feeSaveStart:  ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, feeSaveLoading: true, };
}

const feeSaveError:  ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: action.error ?? "Jokin meni pieleen", feeSaveLoading: false};
}

const feeSaveSuccess:  ReducerSignature = (state, action): ReducerState => {
	const feesToAdd = action.newFee ? [action.newFee, ...state.fees ?? [], ] : null;
	return { ...state, error: null, feeSaveLoading: false, feeSaveSuccess: true, fees: feesToAdd ?? state.fees };

}

const feeSaveClear:  ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, feeSaveLoading: false, fee: null, feeSaveSuccess: false };
}

const feeDeleteStart:  ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, feeDeleteLoading: true, feeDeleteSuccess: false };
}

const feeDeleteError:  ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, feeDeleteLoading: false, feeDeleteError: action.error ?? "" };
}

const feeDeleteSuccess:  ReducerSignature = (state, action): ReducerState => {
	const fees = state.fees?.filter((fee) => fee.id !== action.deletedFee) ?? [];
	return { ...state, error: null, feeDeleteLoading: false, feeDeleteError: null, feeDeleteSuccess: true, fees };
}

const feeDeleteClear:  ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, feeDeleteLoading: false, feeDeleteError: null, feeDeleteSuccess: false };
}

const reducer = (state: ReducerState = initialState, action: IFeeAction): ReducerState => {
	switch (action.type) {
		case EActionTypes.FEES_GET_START:
			return feesGetStart(state, action);
		case EActionTypes.FEES_GET_SUCCESS:
			return feesGetSuccess(state, action);
		case EActionTypes.FEES_GET_CLEAR:
			return feesGetClear(state, action);
		case EActionTypes.FEE_GET_SUCCESS:
			return feeGetSuccess(state, action);
		case EActionTypes.FEE_GET_START:
			return feeGetStart(state, action);
		case EActionTypes.FEE_GET_ERROR:
			return feeGetError(state, action);
		case EActionTypes.FEE_SAVE_START:
			return feeSaveStart(state, action);
		case EActionTypes.FEE_SAVE_SUCCESS:
			return feeSaveSuccess(state, action);
		case EActionTypes.FEE_SAVE_ERROR:
			return feeSaveError(state, action);
		case EActionTypes.FEE_SAVE_CLEAR:
			return feeSaveClear(state, action);
		case EActionTypes.FEE_DELETE_START:
			return feeDeleteStart(state, action);
		case EActionTypes.FEE_DELETE_SUCCESS:
			return feeDeleteSuccess(state, action);
		case EActionTypes.FEE_DELETE_ERROR:
			return feeDeleteError(state, action);
		case EActionTypes.FEE_DELETE_CLEAR:
			return feeDeleteClear(state, action);
		default:
			return state;
	}
};

export default reducer;
